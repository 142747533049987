import { PoolInfo } from '../types';
import { TokenInfo } from '../types';

export interface removeLiquidityState {
  inProcess: boolean;
  poolInfo: PoolInfo;
  firstTokenAmount: number;
  secondTokenAmount: number;
  firstToken: TokenInfo;
  secondToken: TokenInfo;
  currentPoolTokenAmount: number;
  removePart?: number;
  removeToken0?: number;
  removeToken1?: number;
}

export enum removeLiquidityEnum {
  SET_RIN_PROCESS_LIQUIDITY = 'SET_IN_PROCESS_LIQUIDITY_REMOVE',
  SET_RLIQUIDITY_POOL_INFO = 'SET_LIQUIDITY_POOL_INFO_REMOVE',
  SET_RFIRST_TOKEN_AMOUNT = 'SET_FIRST_TOKEN_AMOUNT',
  SET_RSECOND_TOKEN_AMOUNT = 'SET_SECOND_TOKEN_AMOUNT',
  SET_RFIRST_TOKEN = 'SET_FIRST_TOKEN_INFO',
  SET_RSECOND_TOKEN = 'SET_SECOND_TOKEN_INFO',
  SET_CURRENT_RLT_AMOUNT = 'SET_CURRENT_RLT_AMOUNT',
  SET_CURRENT_REMOVE_PARTE = 'SET_CURRENT_REMOVE_PARTE',
  SET_REMOVE_TOKENS_AMOUNT = 'SET_REMOVE_TOKEN_AMOUNT',
}

export interface setRemoveTokenAmount {
  type: removeLiquidityEnum.SET_REMOVE_TOKENS_AMOUNT;
  payload: {
    token0: number;
    token1: number;
  };
}

export interface setCurrentRemoveParte {
  type: removeLiquidityEnum.SET_CURRENT_REMOVE_PARTE;
  payload: number;
}

export interface setCurrentRLTAmount {
  type: removeLiquidityEnum.SET_CURRENT_RLT_AMOUNT;
  payload: number;
}

export interface setRSecondTokenInfo {
  type: removeLiquidityEnum.SET_RSECOND_TOKEN;
  payload: TokenInfo;
}

export interface setRFistTokenInfo {
  type: removeLiquidityEnum.SET_RFIRST_TOKEN;
  payload: TokenInfo;
}

export interface setRLiquidityInProcessAction {
  type: removeLiquidityEnum.SET_RIN_PROCESS_LIQUIDITY;
  payload: boolean;
}

export interface setRLiquidityPoolInfo {
  type: removeLiquidityEnum.SET_RLIQUIDITY_POOL_INFO;
  payload: PoolInfo;
}

export interface setRFirstTokenAmount {
  type: removeLiquidityEnum.SET_RFIRST_TOKEN_AMOUNT;
  payload: number;
}

export interface setRSecondTokenAmount {
  type: removeLiquidityEnum.SET_RSECOND_TOKEN_AMOUNT;
  payload: number;
}

export type removeLiquidityCurrentState = removeLiquidityState;
export type removeLiquidityAction =
  | setRLiquidityInProcessAction
  | setRLiquidityPoolInfo
  | setRFirstTokenAmount
  | setRSecondTokenAmount
  | setRSecondTokenInfo
  | setRFistTokenInfo
  | setCurrentRemoveParte
  | setCurrentRLTAmount
  | setRemoveTokenAmount;
