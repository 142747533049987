import useWalletSelector from 'hooks/useWalletSelector';
import React, { FunctionComponent } from 'react';
import LevelPairSelect from '../LevelPairSelect/LevelPairSelect';
import ProgramInfo from './ProgramInfo';
import ProgramProcess from './ProgramProcess';
import RangeProgramSlider from './RangeProgramSlider/RangeProgramSlider';

const RangeProgram: FunctionComponent = () => {
  const { farmingInfo, teamLevelsRevenue, choosenFarmingPoolId } = useWalletSelector( state => state.AccountReducer )
  const { farmingList } = useWalletSelector( state => state.farmingReducer )
  const programInfo = [
    {level: 1, turnover: '5', title: "Shrimp", priceIn: "$1"},
    {level: 2, turnover: '50', title: "Crab", priceIn: "$10"},
    {level: 3, turnover: '250', title: "Fish", priceIn: "$100"},
    {level: 4, turnover: '500', title: "Dolphin", priceIn: "$500"},
    {level: 5, turnover: '2500', title: "Shark", priceIn: "$1 000"},
    {level: 6, turnover: '5000', title: "Whale", priceIn: "$10 000"},
  ]

  const calculatePseudoLevel = () => {
    let revenueCount = 0
    for (let i = 0; i < teamLevelsRevenue.length; i++) {
      if(!!teamLevelsRevenue[i]) revenueCount++
    }
    return revenueCount
  }

  return (
    <div className="">
      <div className="flex justify-center mt-12 md:justify-start">
        <div className="flex items-center md:ml-8">
						<LevelPairSelect />
            <h3 className="ml-4 text-base sm:text-3xl text-description">
            Rank
          </h3>
        </div>
      </div>
    <div className="flex flex-col px-2 mt-10 xl:flex-row sm:px-8 gap-6">
      <div className="w-full xl:w-2/5 ">
         <ProgramProcess
          token0={farmingList.filter((el: any) => el.poolId === choosenFarmingPoolId)[0]?.token0}
          token1={farmingList.filter((el: any) => el.poolId === choosenFarmingPoolId)[0]?.token1}
          withdrawedLevels={farmingInfo.withdrawedLevels}
          currentAccountLevel={calculatePseudoLevel()}
          firstTokenTurnover={ farmingInfo.firstTokenTurnover }
          secondTokenTurnover={ farmingInfo.secondTokenTurnover }
          currentRangLevel={farmingInfo.currentRangLevel}
          title={'Shrimp'}
        />
      </div>
      <div className="flex-wrap justify-between hidden sm:flex xl:w-3/5 gap-y-4">
        {programInfo.map(el => {
          return <ProgramInfo
                    activeTurnover={
                      (farmingInfo.firstTokenTurnover >= +el.turnover)
                      && (farmingInfo.secondTokenTurnover >= +el.turnover)
                    }
                    activeLevel={(farmingInfo.currentAccountLevel >= el.level)
                      && (farmingInfo.currentRangLevel >= el.level || farmingInfo.currentRangLevel + 1 === el.level)}
                    activeWithdraw={farmingInfo.withdrawedLevels >= el.level }
                    nextLevel={farmingInfo.currentRangLevel + 1 === el.level}
                    active={farmingInfo.currentRangLevel >= el.level}
                    level={el.level}
                    turnover={el.turnover}
                    title={el.title}
                    priceIn={el.priceIn}
                  />
        })}
      </div>
      <RangeProgramSlider
        farmingInfo={farmingInfo}
        personalSwap={farmingInfo.firstTokenTurnover}
        currentAccountLevel={farmingInfo.currentAccountLevel}
        currentRangLevel={farmingInfo.currentRangLevel}
        withdrawedLevels={farmingInfo.withdrawedLevels}
      />
    </div>
    </div>
  );
};

export default RangeProgram;
