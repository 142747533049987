import {useEffect, useState} from 'react'
import Layout from "components/Layout";
import { FunctionComponent } from "react";
import PoolAmount from "./components/PoolAmount";
import PoolDetails from "./components/PoolDetails";
import PoolHeader from "./components/PoolHeader";
import PoolWhitelist from "./components/PoolWhitelist/PoolWhitelist";
import {useHistory} from 'react-router'
import {useDispatch} from 'react-redux'
import { launchpoolActionCreator } from 'store/reducers/launchpool/action-creator';
import useWalletSelector from 'hooks/useWalletSelector'
import PoolWinners from './components/PoolWinners/PoolWinners';

const LaunchpoolInfo: FunctionComponent = () => {

const history = useHistory()
const dispatch = useDispatch()
const { activePool, pools  } = useWalletSelector(state => state.launchpoolReducer);

    useEffect(() => {
        const currentPoolId = +history.location.pathname.split('/')[2]
        if (pools.length === 0) {
            history.push('/launchpool/')
        } else dispatch(launchpoolActionCreator.setActivePool(currentPoolId))

    }, [])


    return(
        <Layout >
        {activePool &&
            <div className='w-full mx-auto lg:w-3/4'>
                <PoolWinners activePool={activePool} />
                <PoolHeader />
                <PoolAmount />
                <PoolWhitelist activePool={activePool}/>
                <PoolDetails activePool={activePool} />
            </div>
        }
        </Layout>
    )
}

export default LaunchpoolInfo
