import {
  removeLiquidityAction,
  removeLiquidityCurrentState,
  removeLiquidityEnum,
} from './types';

const initialState: removeLiquidityCurrentState = {
  inProcess: false,
  poolInfo: {
    totalSupply: 0,
    liquidity: 0,
    firstDepositTokenInfo: {},
    secondDepositTokenInfo: {},
    lpPriceInUsd: 0,
  },
  firstTokenAmount: 0,
  secondTokenAmount: 0,
  firstToken: {},
  secondToken: {},
  currentPoolTokenAmount: 0,
  removePart: 50,
  removeToken0: 0,
  removeToken1: 0,
};

export default function removeLiquidityReducer(
  state = initialState,
  action: removeLiquidityAction,
) {
  switch (action.type) {
    case removeLiquidityEnum.SET_RIN_PROCESS_LIQUIDITY:
      return { ...state, inProcess: action.payload };
    case removeLiquidityEnum.SET_RLIQUIDITY_POOL_INFO:
      return { ...state, poolInfo: action.payload };
    case removeLiquidityEnum.SET_RFIRST_TOKEN_AMOUNT:
      return { ...state, firstTokenAmount: action.payload };
    case removeLiquidityEnum.SET_RSECOND_TOKEN_AMOUNT:
      return { ...state, secondTokenAmount: action.payload };
    case removeLiquidityEnum.SET_CURRENT_RLT_AMOUNT:
      return { ...state, currentPoolTokenAmount: action.payload };
    case removeLiquidityEnum.SET_CURRENT_REMOVE_PARTE:
      return { ...state, removePart: action.payload };
    case removeLiquidityEnum.SET_REMOVE_TOKENS_AMOUNT:
      return {
        ...state,
        removeToken0: action.payload.token0,
        removeToken1: action.payload.token1,
      };
    default:
      return state;
  }
}
