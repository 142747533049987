import { AccountsState, AccountAction, AccountsStateEmun } from './types';

const initialState: AccountsState = {
  accounts: [], levels: [0, 0, 0, 0, 0, 0],
  farmingInfo: {
    teamFarmingByLevel: [0, 0, 0, 0, 0, 0],
    teamFarmingTurnover: [0, 0, 0, 0, 0, 0],
    totalRankWithdrawed: [0, 0, 0, 0, 0, 0],
    totalTeamFarming: 0,
    personalLiquidityFarming: 0,
    teamFarmingProfit: 0,
    currentAccountLevel: 0,
    currentRangLevel: 0,
    withdrawedLevels: 0,
    firstTokenTurnover: 0,
    secondTokenTurnover: 0,
  },
  boostInfo: {
    boostActiveLevel: 0,
    boostFeeTokenAddress: [],
    boostFeeTokenPrice: []
  },
  creating: false,
  createAccount: false,
  accountCreated: false,
  currentAccount: 0,
  upliner: 0,
  transactionError: false,
  teamLiquidity: 0,
  teamSwap: 0,
  personalSwap: 0,
  totalTeamRevenue: 0,
  personalLiquidity: 0,
  teamFetching: false,
  farmingPartners: 0,
  choosenFarmingPoolId: 0,
  withdrawedRevenue: [],
  teamLevelsRevenue: [],
  teamPoolRevenue: [],
  levelsNoRevenue: false,
  boostLevelChosen: 0,
  totalFarmingWithdrawed: 0,
  canUserWithdrawLaunchpool: false,
};

export default function AccountReducer(
  state = initialState,
  action: AccountAction,
) {
  switch (action.type) {
    case AccountsStateEmun.SET_ACCOUNTS:
      return { ...state, accounts: action.payload };
    case AccountsStateEmun.SET_LEVELS:
      return { ...state, levels: action.payload };
    case AccountsStateEmun.SET_CREATE_POPUP:
      return { ...state, createAccount: action.payload };
    case AccountsStateEmun.SET_CREATING:
      return { ...state, creating: action.payload };
    case AccountsStateEmun.CREATE_FINISH_NOTIFICATION:
      return { ...state, accountCreated: action.payload };
    case AccountsStateEmun.SET_CURRENT_ACCOUNT:
      return { ...state, currentAccount: action.payload };
    case AccountsStateEmun.SET_UPLINER:
      return { ...state, upliner: action.payload };
    case AccountsStateEmun.SET_TRANSACTION_ERROR:
      return { ...state, transactionError: action.payload };
    case AccountsStateEmun.SET_PERSONAL_LIQUIDITY:
      return { ...state, personalLiquidity: action.payload }
    case AccountsStateEmun.SET_PERSONAL_SWAP:
      return { ...state, personalSwap: action.payload }
    case AccountsStateEmun.SET_TEAM_LIQUIDITY:
      return { ...state, teamLiquidity: action.payload }
    case AccountsStateEmun.SET_TEAM_SWAP:
      return { ...state, teamSwap: action.payload }
    case AccountsStateEmun.RESET_ACCOUNT_DATA:
      return { ...state, teamSwap: 0, teamLiquidity: 0, personalSwap: 0, personalLiquidity: 0 }
    case AccountsStateEmun.SET_TEAM_FETCHING:
      return { ...state, teamFetching: action.payload }
    case AccountsStateEmun.SET_TEAM_LEVELS_REVENUE:
      return {
        ...state,
        teamLevelsRevenue: action.payload
      }
    case AccountsStateEmun.SET_TEAM_POOL_REVENUE:
      return { ...state, teamPoolRevenue: action.payload }
    case AccountsStateEmun.SET_PERSONAL_LIQUIDITY_FARMING:
      return { ...state, personalLiquidityFarming: action.payload }
    case AccountsStateEmun.SET_CURRENT_ACCOUNT_LEVEL:
      return { ...state, currentAccountLevel: action.payload }
    case AccountsStateEmun.SET_CURRENT_RANG_LEVEL:
      return { ...state, currentRangLevel: action.payload }
    case AccountsStateEmun.SET_TEAM_FARMING_BY_LEVEL:
      return { ...state, teamFarmingByLevel: action.payload }
    case AccountsStateEmun.SET_WITHDRAWED_LEVELS:
      return { ...state, withdrawedLevels: action.payload }
    case AccountsStateEmun.SET_TOTAL_TEAM_FARMING:
      return { ...state, totalTeamFarming: action.payload }
    case AccountsStateEmun.SET_TOTAL_TEAM_REVENUE:
      return { ...state, totalTeamRevenue: action.payload }
    case AccountsStateEmun.SET_WITHDRAWED_REVENUE:
      return { ...state, withdrawedRevenue: action.payload }
    case AccountsStateEmun.SET_CHOOSEN_FARMING_POOL_ID:
      return { ...state, choosenFarmingPoolId: action.payload }
    case AccountsStateEmun.SET_ALL_FARMING_INFO:
      return { ...state, farmingInfo: action.payload }
    case AccountsStateEmun.SET_TOTAL_FARMING_WITHDRAWED:
      return { ...state, totalFarmingWithdrawed: action.payload }
    case AccountsStateEmun.SET_LEVELS_NO_REVENUE:
      return { ...state, levelsNoRevenue: action.payload }
    case AccountsStateEmun.SET_BOOST_LEVEL_CHOSEN:
      return { ...state, boostLevelChosen: action.payload }
    case AccountsStateEmun.SET_BOOST_ACTIVE_LEVEL:
      return { ...state, boostInfo: { ...state.boostInfo, boostActiveLevel: action.payload } }
    case AccountsStateEmun.SET_CAN_USERWITHDRAW_POOL:
      return { ...state, canUserWithdrawLaunchpool: action.payload }
    default:
      return state;
  }
}
