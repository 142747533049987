import React, { FunctionComponent } from 'react';
import Modal from 'components/Modal';
import SuccessIcon from './assets/success.svg';
import useWalletSelector from 'hooks/useWalletSelector';
import { useDispatch } from 'react-redux';
import { swapActionCreator } from 'store/reducers/swap/action-creators';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';

const WalletWarning: FunctionComponent = () => {
  const { swapState } = useWalletSelector(state => state.swapReducer);
  const dispatch = useDispatch();

  const handlCloseModal = () => {
    dispatch(swapActionCreator.setCurrentSwapState('initial'));
  };
  
  return (
    <>
      {swapState === 'walletWarning' && (
        <Modal closeModal={handlCloseModal}>
          <div className="flex flex-col items-center">
            <span className="text-title text-2xl mt-10 mb-10">Your wallet isn't connected <br /> Please click the button below</span>
            <ConnectWalletButton onClick={() => dispatch(swapActionCreator.setCurrentSwapState('initial'))} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default WalletWarning;
