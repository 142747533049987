type functionParamsType = {
    [key: string]: string[] 
}
export const functionsParams:functionParamsType = {
 'getLPStorage()': [`tuple(string name,
                          uint[] variables,
                          uint[] whiteListAmounts,
                          uint[] tokenPrices,
                          address[] buyTokens,
                          address[] whiteListTokens,
                          address[] addressVariables,
                          bool[] boolVariables)`],
 'getLPAllData()': [`tuple(string projectName,
                           string projectImage,
                           address checkAddress,
                           address idoFactory,
                           address tokenIn,
                           address tokenOut,
                           address nftAddress,
                           address wtrx,
                           uint256 minIn,
                           uint256 maxIn,
                           uint256 start,
                           uint256 freeze,
                           uint256 end,
                           uint256 tokenOutShare,
                           uint256 tokenOutRandom,
                           uint256 tokenUsersOutShare,
                           uint256 tokenUsersOutRandom,
                           uint256 uplinerPercent,
                           uint256 winners,
                           uint256 totalStakeShare,
                           uint256 totalStakeRandom,
                           uint256 randomShareBoth,
                           uint256 poolCap,
                           uint256 UserIdRandom,
                           uint256 UserIdShare,
                           uint256[] resultWinners,
                           bool active)`],
  'getTokenPriceInUSD()':['uint256'],
  'calculatePartRandom()':['uint256'],
  'calculatePart()':['uint256'],
  'UplinersTransfer()':['uint256'],
  'PoolId()':['uint256'],
  'PoolById()':['address'],
  'UsersRandom()':['tuple(uint userId,uint amountStake,uint amountWithdrawed)'],
  'UsersShare()':['tuple(uint userId,uint amountStake,uint amountWithdrawed)'],
  'getAllIDOs()':['address[]'],
	'whiteListLock()': ['uint256'],
	'whiteListLockHistory()': ['uint256'],
	'tokenIdBuyTokenAmounts()': ['uint256'],
	'tokenIdSaleAmount()': ['uint256'],
	'tokenIdRefAmount()': ['uint256'],
	'checkIDOActive()': ['bool'],
  'feeTrx()': ['bool'],
  'subscribeTrx()': ['bool'],
  'tokenFeeAddress()': ['address'],
  'subscribeTokenFeeAddress()': ['address'],
  'oneAddressPrice()': ['uint256'],
  'subscribeDays()': ['uint256'],
  'subscribePrice()': ['uint256']
}

export const getParams = (functionName:string) => {
  return functionsParams[functionName] 

} 
