import { AppDispatch, getRootState } from "store";
import { farmingAction, farmingEnum, farmingInfo } from "./types";
import FarmingFactoryContract from "contracts/FarmingFactoryContract.json";
import FarmingContract from "contracts/FarmingContract.json";
import Price from "contracts/Price.json";
import Config from "config";
import { ApplicationAcionCreator } from "../application/action-creator";
import sleep from "utils/sleep";
import initTronstack from "utils/tronWeb/initTronstack";
import decodeParams from "utils/parseResult";
import { accountActionCreator } from "../account/action-creators";
import parseResult from "utils/parseResult";
import { getTokenInfoByAddress } from "utils/getTokenInfo";
import handleTokenInfo from "utils/handleTokenInfo";
import { getTokensByAddress } from "api/api";
import { getTransactionResult } from "utils/transaction/getTransactionResult";

export const farmingActionCreator = {
    setFarmingList: (list: any[]): farmingAction => ({
        type: farmingEnum.SET_FARMING_LIST,
        payload: list,
    }),
    setFarmingState: (state: string): farmingAction => ({
        type: farmingEnum.SET_FARMING_STATE,
        payload: state,
    }),
    setFarmingLpTokens: (lpTokens: number): farmingAction => ({
        type: farmingEnum.SET_FARMING_LP_TOKENS,
        payload: lpTokens,
    }),
    setLpTokenAddress: (lpToken: string): farmingAction => ({
        type: farmingEnum.SET_LP_TOKEN_ADDRESS,
        payload: lpToken,
    }),
    setFarmingTokens: (firstToken: string, secondToken: string) => ({
        type: farmingEnum.SET_FARMING_TOKENS,
        payload: { firstToken, secondToken },
    }),
    setStakeInput: (inputAmount: string) => ({
        type: farmingEnum.SET_STAKE_INPUT,
        payload: inputAmount,
    }),
    resetFarmingStakeInfo: () => ({
        type: farmingEnum.RESET_FARMING_STAKE_INFO,
    }),
    showStakedOnly: () => ({
        type: farmingEnum.SHOW_STAKED_ONLY,
    }),
    setSortOption: (option: string) => ({
        type: farmingEnum.SET_SORT_OPTION,
        payload: option,
    }),
    setStakedTokens: (lpTokens: number) => ({
        type: farmingEnum.SET_STAKED_TOKENS,
        payload: lpTokens,
    }),
    setUnstakeInput: (lpTokens: string) => ({
        type: farmingEnum.SET_UNSTAKE_TOKENS,
        payload: lpTokens,
    }),
    setCalculatorState: (info: {
        lpToken?: string;
        firstTokenAddress?: string;
        secondTokenAddress?: string;
        usdInput?: number;
        lpInput?: number;
        tokenOutput?: number;
        lpAmount?: number;
    }): farmingAction => ({
        type: farmingEnum.SET_CALCULATOR_STATE,
        payload: info,
    }),
    setWithdrawAmount: (withdrawAmount: number) => ({
        type: farmingEnum.SET_WITHDRAW_AMOUNT,
        payload: withdrawAmount,
    }),
    setCurrentBlock: (currrentBlock: number): farmingAction => ({
        type: farmingEnum.SET_CURRENT_BLOCK,
        payload: currrentBlock,
    }),
    detectFarmingLpToken:
        (type?: string) => async (dispatch: AppDispatch, store: getRootState) => {
            if (type === "calculator") {
                const firstTokenAddress =
                    store().farmingReducer.calculatorState.firstTokenAddress;
                const secondTokenAddress =
                    store().farmingReducer.calculatorState.secondTokenAddress;
                const liquidityPairs = store().liquidityReducer.liquidityPairs;
                // debugger
                let lpTokenAmount = liquidityPairs.filter(
                    (el) =>
                        (el.token0Info.address === firstTokenAddress &&
                            el.token1Info.address === secondTokenAddress) ||
                        (el.token0Info.address === secondTokenAddress &&
                            el.token1Info.address === firstTokenAddress)
                );
                if (lpTokenAmount.length !== 0) {
                    dispatch(
                        farmingActionCreator.setCalculatorState({
                            lpAmount: +lpTokenAmount[0].liquidity,
                        })
                    );
                }
            } else {
                const firstTokenAddress =
                    store().farmingReducer.farmingStakeInfo.firstTokenAddress;
                const secondTokenAddress =
                    store().farmingReducer.farmingStakeInfo.secondTokenAddress;
                const liquidityPairs = store().liquidityReducer.liquidityPairs;
                let lpTokenAmount = liquidityPairs.filter(
                    (el) =>
                        (el.token0Info.address === firstTokenAddress &&
                            el.token1Info.address === secondTokenAddress) ||
                        (el.token0Info.address === secondTokenAddress &&
                            el.token1Info.address === firstTokenAddress)
                );
                if (lpTokenAmount.length !== 0) {
                    dispatch(
                        farmingActionCreator.setFarmingLpTokens(+lpTokenAmount[0].liquidity)
                    );
                }
            }
        },
    getFarmingPairs: () => async (dispatch: AppDispatch, store: getRootState) => {
        // const tronWeb = await initTronstack()
        const tronWeb = await (window as any).tronWeb
        const exonUsdPrice = store().applicationReducer.exonUsdPrice;
        if (!tronWeb) return;
        let farmingFactoryContract;
        try {
            farmingFactoryContract = await tronWeb.contract(
                FarmingFactoryContract.abi,
                Config().FARMING_FACTORY_CONTRACT
            );
        } catch (e) {
            console.log(e)
            return
        }
        const farmingContract = await tronWeb.contract(
            FarmingContract.abi,
            Config().FARMING_CONTRACT
        );
        const PriceContract = await tronWeb.contract(
            Price.abi,
            Config().ORACLE_LIQUIDITY_AND_SWAP
        );
        let poolId: any = 0;
        try {
            poolId = await farmingFactoryContract.poolId().call();
            poolId = poolId.toNumber();
        } catch (e) {
            return
        }


        let poolArray = [];

        let totalLpStaked = 0;

        for (let i = 1; i <= poolId; i++) {
            let poolCollection;
            try {
                poolCollection = await farmingFactoryContract
                    .poolCollectionList(i)
                    .call();
            } catch (error: any) {
                console.log(error);
                return;
            }

            let balanceOf = await tronWeb.transactionBuilder.triggerConstantContract(
                tronWeb.address.fromHex(poolCollection.lpToken),
                "balanceOf(address)",
                {},
                [{ type: "address", value: Config().FARMING_CONTRACT }]
            );

            let balanceInt = await decodeParams(
                ["uint256"],
                "0x" + balanceOf.constant_result[0]
            );

            let trxTotalSupply =
                await tronWeb.transactionBuilder.triggerConstantContract(
                    tronWeb.address.fromHex(poolCollection.lpToken),
                    "totalSupply()",
                    {},
                    []
                );
            let totalSupply = await parseResult(
                ["uint256"],
                "0x" + trxTotalSupply.constant_result[0]
            );
            let reservesTrx =
                await tronWeb.transactionBuilder.triggerConstantContract(
                    tronWeb.address.fromHex(poolCollection.lpToken),
                    "getReserves()",
                    {},
                    []
                );
            let reserves = await parseResult(
                ["uint256", "uint256"],
                "0x" + reservesTrx.constant_result[0]
            );
            totalSupply = await handleTokenInfo(
                '',
                'fromSun',
                Number(BigInt(totalSupply[0]))
            )
            let reserve0 = await handleTokenInfo(
                tronWeb.address.fromHex(poolCollection.token0),
                "fromSun",
                Number(BigInt(reserves[0]))
            );
            let reserve1 = await handleTokenInfo(
                tronWeb.address.fromHex(poolCollection.token1),
                "fromSun",
                Number(BigInt(reserves[1]))
            );
            let token0Price;
            try {
                token0Price = await PriceContract.getTokenPriceInUSD(
                    tronWeb.address.fromHex(poolCollection.token0)
                ).call();
                token0Price = tronWeb.fromSun(token0Price.toNumber())
            } catch (error) {
                console.log(error);
            }

            let token1Price;
            try {
                token1Price = await PriceContract.getTokenPriceInUSD(
                    tronWeb.address.fromHex(poolCollection.token1)
                ).call();
                token1Price = tronWeb.fromSun(token1Price.toNumber())

            } catch (error) {
                console.log(error);
            }
            let lpStaked;
            let personalEarn;
            if (store().AccountReducer.currentAccount) {
                try {
                    lpStaked = await farmingContract
                        .getUserLiquidity(store().AccountReducer.currentAccount, i)
                        .call();
                } catch (error: any) {
                    console.log(error);
                }

                try {
                    personalEarn = await farmingContract
                        .pendingExonFarming(i, store().AccountReducer.currentAccount)
                        .call();
                } catch (error: any) {
                    console.log(error);
                }
                if (personalEarn?._hex) {
                    personalEarn = BigInt(personalEarn?._hex);
                    personalEarn = +(+tronWeb.fromSun(personalEarn)).toFixed(6);
                }
            }
            let poolType;
            try {
                poolType = await farmingFactoryContract
                    .poolType(poolCollection.poolType.toNumber())
                    .call();
            } catch (error) {
                console.log(error);
            }

            let tokenOutInfo;

            try {
                tokenOutInfo = await getTokensByAddress(
                    tronWeb.address.fromHex(poolCollection.tokenOut)
                );
            } catch (error) {
                console.log(error);
                return;
            }

            let tokenPerBlock;

            try {
                tokenPerBlock = await +tronWeb.fromSun(
                    await farmingFactoryContract.getTokenPerBlock(i).call()
                );
            } catch (error) {
                console.log(error);
                return;
            }

            let usdPerLp = (reserve0 * token0Price + reserve1 * token1Price) / totalSupply

            let totalExonRevenueInUsd =
                (31536000 / Config().SECONDS_PER_RECEIVE) *
                (exonUsdPrice * tokenPerBlock);
            let lpPriceInUsd =
                (reserve0 * token0Price + reserve1 * token1Price) / totalSupply;
            let totalLiquidityInUsd =
                +tronWeb.fromSun(balanceInt[0].toNumber()) * lpPriceInUsd;

            let totalApr = +(
                (totalExonRevenueInUsd / (totalLiquidityInUsd || 1)) *
                100
            ).toFixed(2);
            let personalApr = +(
                (totalExonRevenueInUsd / totalLiquidityInUsd) *
                100
            ).toFixed(2);

            totalLpStaked +=
                (+tronWeb.fromSun(lpStaked?.toNumber()) *
                    (reserve0 * token0Price + reserve1 * token1Price)) /
                totalSupply;

            poolArray.push({
                poolId: i,
                // accTokenPerShare: poolCollection.accTokenPerShare.toNumber(),
                active: poolCollection.active,
                lastRewardBlock: poolCollection.lastRewardBlock.toNumber(),
                lpToken: tronWeb.address.fromHex(poolCollection.lpToken),
                poolType: poolType,
                pairTokens: `${getTokenInfoByAddress(tronWeb.address.fromHex(poolCollection.token0))
                    .name
                    }-${getTokenInfoByAddress(tronWeb.address.fromHex(poolCollection.token1))
                        .name
                    }`,
                token0: tronWeb.address.fromHex(poolCollection.token0),
                token1: tronWeb.address.fromHex(poolCollection.token1),
                tokenOut: tronWeb.address.fromHex(poolCollection.tokenOut),
                tokenOutInfo: tokenOutInfo.data.trc20_tokens.map(
                    (el: { contract_address: any; symbol: any; icon_url: any }) => {
                        return {
                            address: el.contract_address || null,
                            name: el.symbol || null,
                            icon: el.icon_url || null,
                        };
                    }
                ),
                tokenPerBlock: tokenPerBlock,
                lpStaked: +tronWeb.fromSun(lpStaked?.toNumber()) || 0,
                personalEarn: personalEarn || 0,
                totalLiquidity: +tronWeb.fromSun(balanceInt[0].toNumber()),
                apr: +tronWeb.fromSun(lpStaked?.toNumber()) ? personalApr : totalApr,
                usdPerLp: usdPerLp,
            });
        }
        dispatch(
            accountActionCreator.setPersonaLiquidityInFarming(
                +totalLpStaked.toFixed(2)
            )
        );
        dispatch(farmingActionCreator.setFarmingList(poolArray));
    },
    depositTokensToFarming:
        () => async (dispatch: AppDispatch, store: getRootState) => {
            const currentPool = await store().removeLiquidityReducer.poolInfo;
            const tronWeb = store().WalletReducer.tronWeb;
            if (!tronWeb) return;
            dispatch(farmingActionCreator.setFarmingState("depositSteps"));
            dispatch(ApplicationAcionCreator.setTransactionSteps([`Approve LP token`]));
            const farmingContract = tronWeb.contract(
                FarmingContract.abi,
                Config().FARMING_CONTRACT
            );

            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Approve LP token`,
                    true,
                    false
                )
            );

            var approveTransaction;
            try {
                approveTransaction =
                    await tronWeb.transactionBuilder.triggerSmartContract(
                        store().farmingReducer.farmingStakeInfo.lpToken,
                        "approve(address,uint256)",
                        {},
                        [
                            { type: "address", value: Config().FARMING_CONTRACT },
                            {
                                type: "uint256",
                                value: +tronWeb.toSun(
                                    store().farmingReducer.farmingStakeInfo.stakeInput
                                ),
                            },
                        ],
                        tronWeb.defaultAddress.base58
                    );
            } catch (error) {
                console.log(error);
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Stake LP",
                        "Error",
                        "",
                        `${error}`
                    )
                );
                return;
            }

            let signedTransaction;
            try {
                signedTransaction = await tronWeb.trx.sign(
                    approveTransaction.transaction
                );
            } catch (error: any) {
                console.log(error);
                dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                dispatch(farmingActionCreator.setFarmingState("initial"));
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Stake LP",
                        "Error",
                        "",
                        `${error}`
                    )
                );
                return;
            }

            const broadcast = await tronWeb.trx.sendRawTransaction(signedTransaction);

            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Approve LP token`,
                    false,
                    true
                )
            );
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Signature confirming`,
                    true,
                    false
                )
            );
            let farmingDeposit;
            try {
                farmingDeposit = await farmingContract
                    .deposit(
                        store().farmingReducer.farmingStakeInfo.lpToken,
                        tronWeb.toSun(store().farmingReducer.farmingStakeInfo.stakeInput),
                        store().AccountReducer.currentAccount
                    )
                    .send({ feeLimit: 300000000 });
            } catch (error: any) {
                console.log(error);
                dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                dispatch(farmingActionCreator.setFarmingState("initial"));
                return;
            }
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Signature confirming`,
                    false,
                    true
                )
            );
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Finished`,
                    true,
                    false
                )
            );

            var blockNumber;
            var unconfirmTransaction;
            do {
                await sleep(2000);
                unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(
                    farmingDeposit
                );
            } while (Object.keys(unconfirmTransaction).length === 0);

            do {
                await sleep(2000);
                blockNumber = await tronWeb.trx.getCurrentBlock();
                // console.log('test result swap', blockNumber, unconfirmTransaction);
            } while (
                blockNumber.block_header.raw_data.number -
                unconfirmTransaction.blockNumber >
                Config().CONFIRMATIONS
            );

            if (unconfirmTransaction?.receipt?.result !== "SUCCESS") {
                dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Stake LP",
                        "Error",
                        farmingDeposit,
                        `An error occured during staking LP`
                    )
                );
                dispatch(farmingActionCreator.setFarmingState("initial"));
                return;
            }

            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "Stake LP",
                    "Success",
                    farmingDeposit,
                    "Success staked LP to farming"
                )
            );
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Finished`,
                    false,
                    true
                )
            );
        },
    getWithdraw:
        (lpToken: string, lpAmount: number) =>
            async (dispatch: AppDispatch, store: getRootState) => {
                const tronWeb = store().WalletReducer.tronWeb;
                if (!tronWeb) return;
                dispatch(ApplicationAcionCreator.setTransactionSteps());
                const farmingContract = tronWeb.contract(
                    FarmingContract.abi,
                    Config().FARMING_CONTRACT
                );
                dispatch(farmingActionCreator.setFarmingState("withdrawSteps"));
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Signature confirming`,
                        true,
                        false
                    )
                );
                let withdraw;
                try {
                    withdraw = await farmingContract
                        .withdraw(lpToken, store().AccountReducer.currentAccount, lpAmount)
                        .send({ feeLimit: 250000000 });
                } catch (error: any) {
                    console.log(error);
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            true,
                            true,
                            "Claim",
                            "Error",
                            withdraw,
                            `${error.message}`
                        )
                    );
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(farmingActionCreator.setFarmingState("initial"));
                    return;
                }
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Signature confirming`,
                        false,
                        true
                    )
                );
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Finished`,
                        true,
                        false
                    )
                );

                var blockNumber;
                var unconfirmTransaction;
                do {
                    await sleep(2000);
                    unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(
                        withdraw
                    );
                } while (Object.keys(unconfirmTransaction).length === 0);

                do {
                    await sleep(2000);
                    blockNumber = await tronWeb.trx.getCurrentBlock();
                    // console.log('test result swap', blockNumber, unconfirmTransaction);
                } while (
                    blockNumber.block_header.raw_data.number -
                    unconfirmTransaction.blockNumber >
                    Config().CONFIRMATIONS
                );

                if (unconfirmTransaction?.receipt?.result !== "SUCCESS") {
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            true,
                            true,
                            "Claim",
                            "Error",
                            withdraw,
                            `An error occured during claim operation`
                        )
                    );
                    dispatch(farmingActionCreator.setFarmingState("initial"));
                    return;
                }

                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Withdraw",
                        "Success",
                        withdraw,
                        "Success staked LP to farming"
                    )
                );

                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Finished`,
                        false,
                        true
                    )
                );
                dispatch(ApplicationAcionCreator.setTransactionStatus(false));
            },
    unstakeLpTokens: () => async (dispatch: AppDispatch, store: getRootState) => {
        const tronWeb = store().WalletReducer.tronWeb;
        if (!tronWeb) return;
        dispatch(ApplicationAcionCreator.setTransactionStatus(true));
        dispatch(ApplicationAcionCreator.setTransactionSteps());
        const farmingContract = tronWeb.contract(
            FarmingContract.abi,
            Config().FARMING_CONTRACT
        );
        dispatch(farmingActionCreator.setFarmingState("unstakeSteps"));
        dispatch(
            ApplicationAcionCreator.setTransactionStepStatus(
                `Signature confirming`,
                true,
                false
            )
        );

        let farmingUnstake;
        try {
            debugger
            farmingUnstake = await farmingContract
                .withdraw(
                    store().farmingReducer.farmingStakeInfo.lpToken,
                    store().AccountReducer.currentAccount,
                    tronWeb.toSun(+store().farmingReducer.farmingStakeInfo.unstakeInput)
                )
                .send({feeLimit: 250000000});
        } catch (error: any) {
            console.log(error);
            dispatch(ApplicationAcionCreator.setTransactionStatus(false));
            dispatch(farmingActionCreator.setFarmingState("initial"));
            return;
        }
        dispatch(
            ApplicationAcionCreator.setTransactionStepStatus(
                `Signature confirming`,
                false,
                true
            )
        );

        dispatch(
            ApplicationAcionCreator.setTransactionStepStatus(`Finished`, true, false)
        );

        var blockNumber;
        var unconfirmTransaction;
        do {
            await sleep(2000);
            unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(
                farmingUnstake
            );
        } while (Object.keys(unconfirmTransaction).length === 0);

        do {
            await sleep(2000);
            blockNumber = await tronWeb.trx.getCurrentBlock();
            // console.log('test result swap', blockNumber, unconfirmTransaction);
        } while (
            blockNumber.block_header.raw_data.number -
            unconfirmTransaction.blockNumber >
            Config().CONFIRMATIONS
        );
        if (unconfirmTransaction?.receipt?.result !== "SUCCESS") {
            dispatch(ApplicationAcionCreator.setTransactionStatus(false));
            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "Unstake LP",
                    "Error",
                    farmingUnstake,
                    `An error occured during LP unstake`
                )
            );
            dispatch(farmingActionCreator.setFarmingState("initial"));
            return;
        }

        dispatch(
            ApplicationAcionCreator.setNotificationStatus(
                true,
                true,
                "Unstake LP",
                "Success",
                farmingUnstake,
                "Success unstaked LP from farming"
            )
        );

        dispatch(
            ApplicationAcionCreator.setTransactionStepStatus(`Finished`, false, true)
        );
        dispatch(ApplicationAcionCreator.setTransactionStatus(false));
    },
    getRankWithdraw:
        () => async (dispatch: AppDispatch, store: getRootState) => {
            const tronWeb = store().WalletReducer.tronWeb;
            const choosenFarmingPoolId = store().AccountReducer.choosenFarmingPoolId
            if (!tronWeb) return;
            dispatch(ApplicationAcionCreator.setTransactionSteps());
            const farmingContract = tronWeb.contract(
                FarmingContract.abi,
                Config().FARMING_CONTRACT
            );
            const farmingFactoryContract = tronWeb.contract(
                FarmingFactoryContract.abi,
                Config().FARMING_FACTORY_CONTRACT
            );
            dispatch(farmingActionCreator.setFarmingState("withdrawSteps"));
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Signature confirming`,
                    true,
                    false
                )
            );

            let withdraw

            try {
                withdraw = await farmingFactoryContract
                    .tokenWithdrawRank(store().AccountReducer.currentAccount, choosenFarmingPoolId)
                    .send();
            } catch (error: any) {
                console.log(error);
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Claim",
                        "Error",
                        withdraw,
                        `${error.message}`
                    )
                );
                dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                dispatch(farmingActionCreator.setFarmingState("initial"));
                return;
            }

            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Signature confirming`,
                    false,
                    true
                )
            );

            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Finished`,
                    true,
                    false
                )
            );

            var blockNumber;
            var unconfirmTransaction;
            do {
                await sleep(2000);
                unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(
                    withdraw
                );
            } while (Object.keys(unconfirmTransaction).length === 0);

            do {
                await sleep(2000);
                blockNumber = await tronWeb.trx.getCurrentBlock();
                // console.log('test result swap', blockNumber, unconfirmTransaction);
            } while (
                blockNumber.block_header.raw_data.number -
                unconfirmTransaction.blockNumber >
                Config().CONFIRMATIONS
            );

            if (unconfirmTransaction?.receipt?.result !== "SUCCESS") {
                dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Claim",
                        "Error",
                        withdraw,
                        `An error occured during claim operation`
                    )
                );
                dispatch(farmingActionCreator.setFarmingState("initial"));
                return;
            }

            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "Claim",
                    "Success",
                    withdraw,
                    `Success rank reward claimed`
                )
            );

            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Finished`,
                    false,
                    true
                )
            );
        },
    getTeamWithdrawByPool:
        (poolArray: number[]) => async (dispatch: AppDispatch, store: getRootState) => {
            const tronWeb = store().WalletReducer.tronWeb;
            const farmingList = store().farmingReducer.farmingList
            poolArray = poolArray.sort((a, b) => a - b)
            if (!tronWeb) return;

            const withdrawPools: any[] = []

            for (let i = 0; i < farmingList.length; i++) {
                for (let j = 1; j <= poolArray.length; j++) {
                    if (farmingList[i].poolId === poolArray[j - 1]) withdrawPools.push(farmingList[i])
                }
            }

            dispatch(ApplicationAcionCreator.setTransactionSteps(
                withdrawPools.map(el => {
                    return `Signature pair ${el.pairTokens}`
                })
            ));
            const farmingFactoryContract = tronWeb.contract(
                FarmingFactoryContract.abi,
                Config().FARMING_FACTORY_CONTRACT
            );
            dispatch(farmingActionCreator.setFarmingState("withdrawSteps"));

            const teamWithdrawCurrentPool = async (poolId: number, pair: string, iteration: number, last?: boolean) => {
                let withdraw;
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Signature pair ${pair}`,
                        true,
                        false
                    )
                );
                try {
                    withdraw = await farmingFactoryContract
                        .tokenWithdrawTeamProfit(store().AccountReducer.currentAccount, poolId)
                        .send();
                } catch (error: any) {
                    console.log(error);
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            true,
                            true,
                            "Claim",
                            "Error",
                            withdraw,
                            `${error.message}`
                        )
                    );
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(farmingActionCreator.setFarmingState("initial"));
                    return;
                }
                if (last) {

                    dispatch(
                        ApplicationAcionCreator.setTransactionStepStatus(
                            `Signature pair ${pair}`,
                            false,
                            true
                        )
                    );

                    dispatch(
                        ApplicationAcionCreator.setTransactionStepStatus(
                            `Finished`,
                            true,
                            false
                        )
                    );
                }


                if (await getTransactionResult(withdraw, tronWeb)) {
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            true,
                            true,
                            "Claim",
                            "Error",
                            withdraw,
                            `An error occured during claim operation`
                        )
                    );
                    dispatch(farmingActionCreator.setFarmingState("initial"));
                    return;
                }
                last
                    && dispatch(
                        ApplicationAcionCreator.setTransactionStepStatus(
                            `Finished`,
                            false,
                            true
                        )
                    )
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Claim",
                        "Success",
                        withdraw,
                        `Success claimed ${pair} pair`
                    )
                );
                !last && dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Signature pair ${pair}`,
                        false,
                        true
                    )
                );

                if (withdrawPools[iteration] && !last) {
                    await teamWithdrawCurrentPool(
                        withdrawPools[iteration].poolId,
                        withdrawPools[iteration].pairTokens,
                        iteration + 1,
                        withdrawPools.length === iteration + 1)
                }
            }
            await teamWithdrawCurrentPool(withdrawPools[0].poolId, withdrawPools[0].pairTokens, 1, withdrawPools.length === 1)

            dispatch(ApplicationAcionCreator.setTransactionStatus(false));
        },
    // getTeamFarmingTurnover:
    //   () => async (dispatch: AppDispatch, store: getRootState) => {
    //     const tronWeb = store().WalletReducer.tronWeb
    //     if(!tronWeb) return
    //     const farmingFactoryContract = tronWeb.contract(
    //         FarmingFactoryContract.abi,
    //       Config().FARMING_FACTORY_CONTRACT
    //     )
    //     let levelProfit = []

    //     for (let i = 1; i <= 6; i++) {
    //       let currentLevelProfit
    //       try {
    //         currentLevelProfit = await farmingFactoryContract.getTeamTurnoverByLevel(
    //           store().AccountReducer.currentAccount,
    //           i
    //         ).call()
    //       } catch (error: any) {
    //         console.log(error);
    //       }
    //       levelProfit.push(currentLevelProfit || 0)
    //     }
    //     dispatch(accountActionCreator.setteamLevelsRevenue(levelProfit))
    //   },
    getPersonalLiquidityInFarming:
        () => async (dispatch: AppDispatch, store: getRootState) => {
            const tronWeb = store().WalletReducer.tronWeb;
            const choosenFarmingPoolId = store().AccountReducer.choosenFarmingPoolId
            if (!tronWeb) return;
            if (!choosenFarmingPoolId) return
            const farmingFactoryContract = tronWeb.contract(
                FarmingFactoryContract.abi,
                Config().FARMING_FACTORY_CONTRACT
            );

            let personalLiqudity;
            try {
                personalLiqudity = await farmingFactoryContract
                    .getPersonalLiquidityInFarmingUSD(
                        store().AccountReducer.currentAccount,
                        choosenFarmingPoolId
                    )
                    .call();
            } catch (error: any) {
                console.log(error);
            }
            if (personalLiqudity) {
                personalLiqudity = +tronWeb.fromSun(personalLiqudity / 1000000);
                personalLiqudity = +personalLiqudity.toFixed(2);
            } else return;
        },
    setLpInputByUsd:
        (seconds: number) => async (dispatch: AppDispatch, store: getRootState) => {
            let usdInput = store().farmingReducer.calculatorState.usdInput;
            let lpToken = store().farmingReducer.calculatorState.lpToken;
            let farmingList = store().farmingReducer.farmingList;
            let exonUsdPrice = store().applicationReducer.exonUsdPrice;

            const getInfoByLp = (type: string) => {
                return farmingList.filter((el) => el.lpToken === lpToken)[0][type];
            };
            // let totalExonRevenueInUsd =
            //   (31536000 / Config().SECONDS_PER_RECEIVE) *
            //   (exonUsdPrice * tokenPerBlock);
            // let lpPriceInUsd = (reserve0 * token0Price + reserve1 * token1Price) / totalSupply;
            // let totalLiquidityInUsd = +tronWeb.fromSun(balanceInt[0].toNumber()) * lpPriceInUsd;


            let lpInput = +(usdInput / getInfoByLp("usdPerLp")).toFixed(6);
            let receiveAmount = seconds / Config().SECONDS_PER_RECEIVE;
            let poolShare = lpInput / (getInfoByLp("totalLiquidity") + lpInput);
            let tokenOutput = +(
                (receiveAmount * (getInfoByLp("tokenPerBlock") * poolShare)) /
                +exonUsdPrice
            ).toFixed(6);

            dispatch(
                farmingActionCreator.setCalculatorState({ lpInput, tokenOutput })
            );
        },
    setUsdInputByLp:
        (seconds: number) => async (dispatch: AppDispatch, store: getRootState) => {
            let lpInput = store().farmingReducer.calculatorState.lpInput;
            let lpToken = store().farmingReducer.calculatorState.lpToken;
            let farmingList = store().farmingReducer.farmingList;
            let exonUsdPrice = store().applicationReducer.exonUsdPrice;

            const getInfoByLp = (type: string) => {
                return farmingList.filter((el) => el.lpToken === lpToken)[0][type];
            };

            let usdInput = +(lpInput * getInfoByLp("usdPerLp")).toFixed(6);
            let receiveAmount = seconds / Config().SECONDS_PER_RECEIVE;
            let poolShare = lpInput / (getInfoByLp("totalLiquidity") + lpInput);
            let tokenOutput = +(
                (receiveAmount * (getInfoByLp("tokenPerBlock") * poolShare)) /
                +exonUsdPrice
            ).toFixed(6);

            dispatch(
                farmingActionCreator.setCalculatorState({ usdInput, tokenOutput })
            );
        },
    setInputsByOutput:
        (seconds: number) => async (dispatch: AppDispatch, store: getRootState) => {
            let tokenOutput = store().farmingReducer.calculatorState.tokenOutput;
            let lpToken = store().farmingReducer.calculatorState.lpToken;
            let farmingList = store().farmingReducer.farmingList;
            let exonUsdPrice = store().applicationReducer.exonUsdPrice;

            const getInfoByLp = (type: string) => {
                return farmingList.filter((el) => el.lpToken === lpToken)[0][type];
            };
            let receiveAmount = seconds / Config().SECONDS_PER_RECEIVE;

            let lpInput = +(
                ((tokenOutput / +exonUsdPrice) * getInfoByLp("totalLiquidity")) /
                (receiveAmount * getInfoByLp("tokenPerBlock") -
                    tokenOutput / +exonUsdPrice)
            ).toFixed(6);
            let usdInput = +(lpInput * getInfoByLp("usdPerLp")).toFixed(6);

            dispatch(farmingActionCreator.setCalculatorState({ lpInput, usdInput }));
        },
    getCurrentBlock: () => async (dispatch: AppDispatch, store: getRootState) => {
        const tronWeb = store().WalletReducer.tronWeb;
        let currentBlock;

        try {
            currentBlock = await tronWeb.trx.getCurrentBlock();
            currentBlock = currentBlock.block_header.raw_data.number;
        } catch (error) {
            console.log(error);
            return;
        }
        dispatch(farmingActionCreator.setCurrentBlock(currentBlock));
    },
};
