import axios from 'axios';
import Config from 'config';

declare module 'axios' {
  export interface AxiosRequestConfig {
    crossDomain?: boolean;
  }
}

export const api = axios.create({
  baseURL: Config().TRONGRID,
  responseType: 'json',
});

export const getEventInformation = (transaction: string, eventName: string) =>
  api.get(`transactions/${transaction}/events?${eventName}`, {
    crossDomain: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

export const getAnalyticsInformation = () => 
  api.get(`${Config().SERVER}info`, {
    crossDomain: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

 export const getTransactions = (page: number, action: string, firstTokenAddress: string, secondTokenAddress: string) => 
   api.post(`${Config().SERVER}transactions`, { page, action, firstTokenAddress, secondTokenAddress }, {
    crossDomain: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  export const getPairs = (page: number) => 
    api.post(`${Config().SERVER}pairs`, {page}, {
      crossDomain: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

  export const getPair = (page: number) => 
  api.post(`${Config().SERVER}pairs`, {page}, {
    crossDomain: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  export const getPairInfoByAddress = (firstTokenAddress: string, secondTokenAddress: string) => 
  api.post(`${Config().SERVER}pair`, {firstTokenAddress, secondTokenAddress}, {
    crossDomain: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  export const getFooterInfo = () => 
  api.get(`${Config().SERVER}footer`, {
    crossDomain: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  export const getCandleGraphic = (firstTokenAddress: string, secondTokenAddress: string, timeStamp: number) => 
  api.post(`${Config().SERVER}candle`, {firstTokenAddress, secondTokenAddress, timeStamp}, {
    crossDomain: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  export const getTRXtoUSDTprice = () =>
    api.get('https://min-api.cryptocompare.com/data/price?fsym=TRX&tsyms=USD', {
      crossDomain: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

  export const getAccountTransactions = (accountAddress: string) => {
  let result = api.get(`${Config().TRONGRID}v1/accounts/${accountAddress}/transactions?limit=200`, {
      crossDomain: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
  })
  console.log('API RESULT',result)
  return result
  }

  export const getTokensByAddress = (tokenAddress: string) => 
    api.get(`${Config().TRONLIST + tokenAddress}&showAll=1`, {
      crossDomain: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

  export const getLPTokenByAddress = (address: string) => 
    api.get(`${Config().EXONCENTER_CONTENT}lp-tokens?address=${address}`, {
      crossDomain: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
  })

  export const getPoolInfo = () => 
    api.get(`${Config().EXONCENTER_CONTENT}pools/`, {
      crossDomain: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
  })

  export const getLaunchPadPoolInfo = () => 
    api.get(`${Config().EXONCENTER_CONTENT}pool-idos/`, {
      crossDomain: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
  })

  export const getPoolSocialMedia = () => 
    api.get(`${Config().EXONCENTER_CONTENT}social-types/`, {
      crossDomain: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
  })

  export const getAddressTransactions = (address: string, start: number) =>
    api.get(`${Config().TRONLIST_TRANSACTIONS}&start=${start}&address=${address}`, {
      crossDomain: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
