import { PoolInfo, TokenInfo } from '../types';
import { removeLiquidityAction, removeLiquidityEnum } from './types';
import { AppDispatch, getRootState } from 'store';
import ExonswapV2LiquidityRouter from 'contracts/ExonswapV2LiquidityRouter.json';
import Config from 'config';
import { ApplicationAcionCreator } from '../application/action-creator';
import { LiquidityActionCreator } from '../liquidity/action-creator';
import parseResult from 'utils/parseResult';
import sleep from 'utils/sleep';
import handleTokenInfo from 'utils/handleTokenInfo';

export const removeLiquidityActionCreator = {
  setInProcess: (inProcess: boolean) => ({
    type: removeLiquidityEnum.SET_RIN_PROCESS_LIQUIDITY,
    payload: inProcess,
  }),
  setCurrentRemoveParte: (currentRemoveParte: number) => ({
    type: removeLiquidityEnum.SET_CURRENT_REMOVE_PARTE,
    payload: currentRemoveParte,
  }),
  setPoolInfo: (poolInfo: PoolInfo) => ({
    type: removeLiquidityEnum.SET_RLIQUIDITY_POOL_INFO,
    payload: poolInfo,
  }),
  setFirstTokenAmount: (firstTokenAmount: number) => ({
    type: removeLiquidityEnum.SET_RFIRST_TOKEN_AMOUNT,
    payload: firstTokenAmount,
  }),
  setSecondTokenAmount: (secondTokenAmount: number) => ({
    type: removeLiquidityEnum.SET_RSECOND_TOKEN_AMOUNT,
    payload: secondTokenAmount,
  }),
  setRFirstTokenInfo: (rFistTokenInfo: TokenInfo) => ({
    type: removeLiquidityEnum.SET_RFIRST_TOKEN,
    payload: rFistTokenInfo,
  }),
  setRSecondTokenInfo: (rSecondTokenInfo: TokenInfo) => ({
    type: removeLiquidityEnum.SET_RSECOND_TOKEN,
    payload: rSecondTokenInfo,
  }),
  setCurrentRLTAmount: (RLTAmount: number): removeLiquidityAction => ({
    type: removeLiquidityEnum.SET_CURRENT_RLT_AMOUNT,
    payload: RLTAmount,
  }),
  setRemoveTokensAmount: (RTAmount: {
    token0: number;
    token1: number;
  }): removeLiquidityAction => ({
    type: removeLiquidityEnum.SET_REMOVE_TOKENS_AMOUNT,
    payload: RTAmount,
  }),
  getRemoveTokensLiquidity:
    () => async (_dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await store().WalletReducer.tronWeb;
      const currentPool = await store().removeLiquidityReducer.poolInfo;
      const lpTokenAmount = await store().removeLiquidityReducer
        .currentPoolTokenAmount;
      const tokenId = store().AccountReducer.currentAccount;
      const token0 = store().removeLiquidityReducer.poolInfo.firstDepositTokenInfo.address
      const token1 = store().removeLiquidityReducer.poolInfo.secondDepositTokenInfo.address
      var removeLiquidityAmounts =
        await tronWeb.transactionBuilder.triggerConstantContract(
          currentPool.poolAddress,
          'removeAmount(uint256,uint256)',
          {},
          [
            {
              type: 'uint256',
              value: tronWeb.toSun(
                ((currentPool.liquidity / 100) * lpTokenAmount).toFixed(6),
              ),
            },
            {
              type: 'uint256',
              value: tokenId,
            },
          ],
        );
      var removeAmounts = await parseResult(
        ['uint256', 'uint256'],
        '0x' + removeLiquidityAmounts.constant_result[0],
      );

      let firstTokenAmountFromSun = await handleTokenInfo(tronWeb.address.fromHex(token0), 'fromSun', removeAmounts[0])
      let secondTokenAmountFromSun = await handleTokenInfo(tronWeb.address.fromHex(token1), 'fromSun', removeAmounts[1])

      _dispatch(
        removeLiquidityActionCreator.setRemoveTokensAmount({
          token0: firstTokenAmountFromSun.toFixed(6),
          token1: secondTokenAmountFromSun.toFixed(6),
        }),
      );
      // console.log('token1', tronWeb.fromSun(removeAmounts[0].toNumber()));
      // console.log('token2', tronWeb.fromSun(removeAmounts[1].toNumber()));
    },
  removeLiquidity:
    () => async (_dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await store().WalletReducer.tronWeb;
      const currentPool = await store().removeLiquidityReducer.poolInfo;
      const lpTokenAmount = await store().removeLiquidityReducer
        .currentPoolTokenAmount;
      const routerContract = await tronWeb.contract(
        ExonswapV2LiquidityRouter.abi,
        Config().ROUTER_CONTRACT,
      );
      const deadline =
        store().swapReducer.swapSetting.deadline * 60 * 1000 +
        Math.floor(Date.now());
      const tokenId = store().AccountReducer.currentAccount;
      const options = {
        feeLimit: 10000000,
        callValue: 0,
      };
      _dispatch(ApplicationAcionCreator.setTransactionSteps([`Approve remove LP tokens`]))
      _dispatch(ApplicationAcionCreator.setTransactionStepStatus(`Approve remove LP tokens`, true, false))
      var approveTransaction =
        await tronWeb.transactionBuilder.triggerSmartContract(
          currentPool.poolAddress,
          'approve(address,uint256)',
          {},
          [
            { type: 'address', value: Config().ROUTER_CONTRACT },
            {
              type: 'uint256',
              value: tronWeb.toSun(
                ((currentPool.liquidity / 100) * lpTokenAmount).toFixed(6),
              ),
            },
          ],
          tronWeb.defaultAddress.base58,
        ); 
       
      let signedTransaction
      try {
        signedTransaction = await tronWeb.trx.sign(
          approveTransaction.transaction,
        );
      } catch (error: any) {
        console.log(error);
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false))
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Remove',
            'Error',
            '',
            `${error.message}`,
          ),
        );
        _dispatch(
          LiquidityActionCreator.setLiquidityState('initial'),
        );
        return
      }
          
      let broadcast = await tronWeb.trx.sendRawTransaction(signedTransaction);
          
      _dispatch(ApplicationAcionCreator.setTransactionStepStatus(`Approve remove LP tokens`, false, true))
      //await sleep(4000)
      _dispatch(ApplicationAcionCreator.setTransactionStepStatus(`Signature confirming`, true, false))
      let trxRemoveLiquidityTRX
      try {
        trxRemoveLiquidityTRX = await routerContract
        .removeLiquidity(
          currentPool.firstDepositTokenInfo?.address,
          currentPool.secondDepositTokenInfo?.address,
          parseInt(
            tronWeb.toSun(
              ((currentPool.liquidity / 100) * lpTokenAmount).toFixed(6),
            ),
          ).toString(),
          '0',
          '0',
          tronWeb.defaultAddress.base58,
          deadline.toString(),
          tokenId.toString(),
        )
        .send({ feeLimit: 200000000, callValue: 0 });  
      } catch (error: any) {
        console.log(error);
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false))
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Remove',
            'Error',
            trxRemoveLiquidityTRX,
            `${error.message}`,
          ),
        );
        _dispatch(
          LiquidityActionCreator.setLiquidityState('initial'),
        );
        return
      } 
      _dispatch(ApplicationAcionCreator.setTransactionStepStatus(`Signature confirming`, false, true))
      _dispatch(ApplicationAcionCreator.setTransactionStepStatus(`Finished`, true, false))
      
      let removeLiquidityResult;
      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          false,
          true,
          'Remove',
          'Processing',
          trxRemoveLiquidityTRX,
          'Removing liquidity',
        ),
      );

      var blockNumber;
      var unconfirmTransaction;
      do {
        await sleep(2000);
        unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(trxRemoveLiquidityTRX);
      } while (Object.keys(unconfirmTransaction).length === 0)

      do {
        await sleep(2000);
        blockNumber = await tronWeb.trx.getCurrentBlock();
        // console.log('test result swap', blockNumber, unconfirmTransaction);
      } while ((blockNumber.block_header.raw_data.number - unconfirmTransaction.blockNumber) > Config().CONFIRMATIONS);

      if(unconfirmTransaction?.receipt?.result !== 'SUCCESS'){
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false))
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Remove',
            'Error',
            trxRemoveLiquidityTRX,
            `An error occured during liquidity remove`,
          ),
        );
        _dispatch(
          LiquidityActionCreator.setLiquidityState('initial'),
        );
        return
      }

      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'Remove',
          'Success',
          trxRemoveLiquidityTRX,
          'Success removed liquidity',
        ),
      );
      _dispatch(ApplicationAcionCreator.setTransactionStepStatus(`Finished`, false, true))
    },
  removeTrxLiquidity:
    () => async (_dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await store().WalletReducer.tronWeb;
      const currentPool = await store().removeLiquidityReducer.poolInfo;
      const lpTokenAmount = await store().removeLiquidityReducer
        .currentPoolTokenAmount;
      const routerContract = await tronWeb.contract(
        ExonswapV2LiquidityRouter.abi,
        Config().ROUTER_CONTRACT,
      );
      const deadline =
        store().swapReducer.swapSetting.deadline * 60 * 1000 +
        Math.floor(Date.now());
      const tokenId = store().AccountReducer.currentAccount;
      const options = {
        feeLimit: 1000000000,
        callValue: 0,
      };
      _dispatch(ApplicationAcionCreator.setTransactionSteps([`Approve remove LP tokens`]))
      _dispatch(ApplicationAcionCreator.setTransactionStepStatus(`Approve remove LP tokens`, true, false))
      var approveTransaction =
        await tronWeb.transactionBuilder.triggerSmartContract(
          currentPool.poolAddress,
          'approve(address,uint256)',
          options,
          [
            { type: 'address', value: Config().ROUTER_CONTRACT },
            {
              type: 'uint256',
              value: tronWeb.toSun(
                ((currentPool.liquidity / 100) * lpTokenAmount).toFixed(6),
              ),
            },
          ],
          tronWeb.defaultAddress.base58,
        );
      
     let signedTransaction
      try {
        signedTransaction = await tronWeb.trx.sign(
          approveTransaction.transaction,
        );
      } catch (error: any) {
        console.log(error);
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false))
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Remove',
            'Error',
            '',
            `${error.message}`,
          ),
        );
        _dispatch(
          LiquidityActionCreator.setLiquidityState('initial'),
        );
        return
      }

      const broadcast = await tronWeb.trx.sendRawTransaction(signedTransaction);
      _dispatch(ApplicationAcionCreator.setTransactionStepStatus(`Approve remove LP tokens`, false, true))
      _dispatch(ApplicationAcionCreator.setTransactionStepStatus(`Signature confirming`, true, false))
      var tokenAddress;
      if (currentPool.firstDepositTokenInfo?.icon === 'trx') {
        tokenAddress = currentPool.secondDepositTokenInfo?.address;
      } else {
        tokenAddress = currentPool.firstDepositTokenInfo?.address;
      }
      let trxRemoveLiquidity
      try {
        trxRemoveLiquidity = await routerContract
        .removeLiquidityTRX(
          tokenAddress,
          parseInt(
            tronWeb.toSun(
            ((currentPool.liquidity / 100) * lpTokenAmount).toFixed(6)
            ),
          ).toString(),
          '0',
          '0',
          tronWeb.defaultAddress.base58,
          deadline.toString(),
          tokenId.toString(),
        )
        .send({ feeLimit: 200000000, callValue: 0 });
      } catch (error: any) {
        console.log(error);
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false))
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Liquidity',
            'Error',
            trxRemoveLiquidity,
            `${error.message}`,
          ),
        );
        _dispatch(
          LiquidityActionCreator.setLiquidityState('initial'),
        );
        return
      }
      
      _dispatch(ApplicationAcionCreator.setTransactionStepStatus(`Signature confirming`, false, true))
      _dispatch(ApplicationAcionCreator.setTransactionStepStatus(`Finished`, true, false))
      let removeLiquidityResult;
      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          false,
          true,
          'Liquidity',
          'Processing',
          trxRemoveLiquidity,
          `Remove liquidity `,
        ),
      );

      var blockNumber;
      var unconfirmTransaction;
      do {
        await sleep(2000);
        unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(trxRemoveLiquidity);
      } while (Object.keys(unconfirmTransaction).length === 0)

      do {
        await sleep(2000);
        blockNumber = await tronWeb.trx.getCurrentBlock();
        // console.log('test result swap', blockNumber, unconfirmTransaction);
      } while ((blockNumber.block_header.raw_data.number - unconfirmTransaction.blockNumber) > Config().CONFIRMATIONS);

      if(unconfirmTransaction?.receipt?.result !== 'SUCCESS'){
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false))
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Remove',
            'Error',
            trxRemoveLiquidity,
            `An error occured during liquidity remove`,
          ),
        );
        _dispatch(
          LiquidityActionCreator.setLiquidityState('initial'),
        );
        return
      }

      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'Remove',
          'Success',
          trxRemoveLiquidity,
          'Success removed liquidity',
        ),
      );
      _dispatch(ApplicationAcionCreator.setTransactionStepStatus(`Finished`, false, true))
    },
};
