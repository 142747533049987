import { AppDispatch, getRootState } from 'store';
import {
  feeTypes,
  multisendAction,
  multisendStateEnum,
  subscribeInfo,
  subscribePlans,
  tokenInfo,
} from './types';
import ExonMultisendContract from 'contracts/ExonMultisendContract.json';
import ITRC20 from 'contracts/ITRC20.json';
import config from 'config';
import { ApplicationAcionCreator } from '../application/action-creator';
import { getTransactionResult } from 'utils/transaction/getTransactionResult';
import { getTokensByAddress } from 'api/api';
import initTronstack from 'utils/tronWeb/initTronstack';
import sleep from 'utils/sleep';
import { getTransactionBuilder } from 'utils/getTransactionBuilder';
import { sendTransactionHanlder } from 'utils/transaction/sendTransactionHandler';
export const multisendActionCreator = {
  setSubscribePlans: (plans: subscribePlans[]): multisendAction => ({
    type: multisendStateEnum.SET_SUBSCRIBE_PLANS,
    payload: plans,
  }),
  setSendAddresses: (sendAdresses: string[]) => ({
    type: multisendStateEnum.SET_SEND_ADDRESSES,
    payload: sendAdresses,
  }),
  setChoosenSubscription: (choosenSubscription: number): multisendAction => ({
    type: multisendStateEnum.SET_CHOOSEN_SUBSCRIPTION,
    payload: choosenSubscription,
  }),
  setTokenAddress: (tokenInfo: tokenInfo): multisendAction => ({
    type: multisendStateEnum.SET_TOKEN_ADDRESS,
    payload: tokenInfo,
  }),
  setAmountPerAddress: (amountPerAddress: number) => ({
    type: multisendStateEnum.SET_AMOUNT_PER_ADDRESS,
    payload: amountPerAddress,
  }),
  setSubscribePeriod: (info: subscribeInfo | null): multisendAction => ({
    type: multisendStateEnum.SET_SUBSCRIBE_PERIOD,
    payload: info,
  }),
  setOneAddressPrice: (price: number): multisendAction => ({
    type: multisendStateEnum.SET_ONE_ADDRESS_PRICE,
    payload: price,
  }),
  setMultisendState: (state: string): multisendAction => ({
    type: multisendStateEnum.SET_MULTISEND_STATE,
    payload: state,
  }),
  setWhitelisted: (whitelisted: boolean): multisendAction => ({
    type: multisendStateEnum.SET_WHITELISTED,
    payload: whitelisted,
  }),
  setFeeTypes: (feeTypes: feeTypes): multisendAction => ({
    type: multisendStateEnum.SET_FEE_TYPES,
    payload: feeTypes,
  }),
  getFeeTypes: () => async (dispatch: AppDispatch, store: getRootState) => {
    const tronWeb = await initTronstack();
    if (!tronWeb) return;

    const MultisendContract = await tronWeb.contract(
      ExonMultisendContract.abi,
      config().MULTISEND_CONTRACT,
    );


    let sendFeeTrx;

    const getSendFeeTrx = async () => {
      sendFeeTrx = await getTransactionBuilder(
        config().MULTISEND_CONTRACT,
        'feeTrx()',
        [],
      );
      sendFeeTrx = sendFeeTrx[0]
      return sendFeeTrx
    }

    do { sendFeeTrx = await getSendFeeTrx() }
    while (sendFeeTrx === undefined)

    const getSubscribeFeeTrx = async () => {
      subscribeFeeTrx = await getTransactionBuilder(
        config().MULTISEND_CONTRACT,
        'subscribeTrx()',
        [],
      );
      subscribeFeeTrx = subscribeFeeTrx[0]
      return subscribeFeeTrx
    }

    let subscribeFeeTrx;

    do { subscribeFeeTrx = await getSubscribeFeeTrx() }
    while (subscribeFeeTrx === undefined)

    const getTokenFeeAddress = async () => {

      sendTokenFeeAddress = await getTransactionBuilder(
        config().MULTISEND_CONTRACT,
        'tokenFeeAddress()',
        [],
      );
      // await MultisendContract.tokenFeeAddress().call()
      sendTokenFeeAddress = tronWeb.address.fromHex(sendTokenFeeAddress[0]);
      return sendTokenFeeAddress
    }

    let sendTokenFeeAddress;

    do { sendTokenFeeAddress = await getTokenFeeAddress() }
    while (!sendTokenFeeAddress)

    const getSubscribeTokenFeeAddress = async () => {
      subscribeTokenFeeAddress = await getTransactionBuilder(
        config().MULTISEND_CONTRACT,
        'subscribeTokenFeeAddress()',
        [],
      );
      // await MultisendContract.subscribeTokenFeeAddress().call()
      subscribeTokenFeeAddress = tronWeb.address.fromHex(
        subscribeTokenFeeAddress[0],
      );
      return subscribeTokenFeeAddress
    }

    let subscribeTokenFeeAddress

    do { subscribeTokenFeeAddress = await getSubscribeTokenFeeAddress() }
    while (!subscribeTokenFeeAddress)


    let sendTokenInfo;
    try {
      sendTokenInfo = await getTokensByAddress(sendTokenFeeAddress);
    } catch (error) {
      console.log(error);
    }
    sendTokenInfo = {
      address: sendTokenFeeAddress,
      name: sendTokenInfo?.data.trc20_tokens[0]?.symbol || 'unknown',
      decimals: sendTokenInfo?.data.trc20_tokens[0]?.decimals || 6,
      icon: sendTokenInfo?.data.trc20_tokens[0]?.icon_url || '',
    };

    let subscribeTokenInfo;
    try {
      subscribeTokenInfo = await getTokensByAddress(subscribeTokenFeeAddress);
    } catch (error) {
      console.log(error);
    }
    subscribeTokenInfo = {
      address: subscribeTokenFeeAddress,
      name: subscribeTokenInfo?.data.trc20_tokens[0]?.symbol || 'unknown',
      decimals: subscribeTokenInfo?.data.trc20_tokens[0]?.decimals || 6,
      icon: subscribeTokenInfo?.data.trc20_tokens[0]?.icon_url || '',
    };
    dispatch(
      multisendActionCreator.setFeeTypes({
        sendFee: {
          isTrx: sendFeeTrx,
          tokenAddress: sendTokenInfo,
        },
        subscribeFee: {
          isTrx: subscribeFeeTrx,
          tokenAddress: subscribeTokenInfo,
        },
      }),
    );
  },
  setTokenAddressInfo:
    (address: string) => async (dispatch: AppDispatch, store: getRootState) => {
      if (!address.length || address.length !== 34) {
        dispatch(
          multisendActionCreator.setTokenAddress({
            name: '',
            address: address,
            icon: '',
            decimals: 6,
          }),
        );
        return;
      }
      let tokenInfo;
      try {
        tokenInfo = await getTokensByAddress(address);
        console.log(tokenInfo);
      } catch (error) {
        console.log(error);
      }
      if (!tokenInfo?.data.trc20_tokens.length) {
        dispatch(
          multisendActionCreator.setTokenAddress({
            name: 'unknown',
            address: address,
            icon: '',
            decimals: 6,
          }),
        );
        return;
      }
      tokenInfo = {
        address: address,
        name: tokenInfo?.data.trc20_tokens[0].symbol,
        decimals: tokenInfo?.data.trc20_tokens[0].decimals,
        icon: tokenInfo?.data.trc20_tokens[0].icon_url,
      };
      dispatch(multisendActionCreator.setTokenAddress(tokenInfo));
    },
  getSubscriptionPlans:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await initTronstack();

      if (!tronWeb) return;

      let oneAddressPrice;
      try {
        oneAddressPrice = await getTransactionBuilder(
          config().MULTISEND_CONTRACT,
          'oneAddressPrice()',
          []
        )

        // await MultisendContract.oneAddressPrice().call();
        oneAddressPrice = oneAddressPrice[0].toNumber();
        oneAddressPrice = +tronWeb.fromSun(oneAddressPrice);
      } catch (error) {
        console.log(error);
      }

      const getSubscriptionTime = async (i: number) => {
        let time
        time = await getTransactionBuilder(
          config().MULTISEND_CONTRACT,
          'subscribeDays()',
          [i]
        )
        time = time[0].toNumber();
        return time
      }

      const getSubscriptionPrice = async (i: number) => {
        let price

        price = await getTransactionBuilder(
          config().MULTISEND_CONTRACT,
          'subscribePrice()',
          [i]
        )
        price = price[0].toNumber();
        price = +tronWeb.fromSun(price);

        return price
      }

      let subscriptionPlans: any = [];

      const getSubscribeItem = async (i: number) => {
        let price;
        let time;

        do { time = await getSubscriptionTime(i) }
        while (!time)

        do { price = await getSubscriptionPrice(i) }
        while (!price)

        subscriptionPlans.push({ price, time });
        if (i === 2) return
        await getSubscribeItem(i + 1)
      }

      await getSubscribeItem(0)

      dispatch(multisendActionCreator.setOneAddressPrice(oneAddressPrice));
      dispatch(multisendActionCreator.setSubscribePlans(subscriptionPlans));
    },
  getSubscribePeriod:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = store().WalletReducer.tronWeb;
      const address = store().WalletReducer.address;

      if (!tronWeb) return;
      const MultisendContract = await tronWeb.contract(
        ExonMultisendContract.abi,
        config().MULTISEND_CONTRACT,
      );

      let subscribePeriod;
      let subscribeId;
      let isWhitelisted;
      await sleep(1000);
      try {
        subscribePeriod = await MultisendContract.UsersSubscribe(
          address,
        ).call();
        subscribePeriod = subscribePeriod.toNumber();
        console.log(subscribePeriod);
      } catch (error) {
        console.log(error);
      }

      try {
        subscribeId = await MultisendContract.UsersSubscribePosition(
          address,
        ).call();
        subscribeId = subscribeId.toNumber();
      } catch (error) {
        console.log(error);
      }

      try {
        isWhitelisted = await MultisendContract.UsersWhitelist(address).call();
      } catch (error) {
        console.log(error);
      }

      dispatch(multisendActionCreator.setWhitelisted(isWhitelisted));

      if (subscribePeriod * 1000 > Date.now()) {
        dispatch(multisendActionCreator.setChoosenSubscription(subscribeId));
        dispatch(
          multisendActionCreator.setSubscribePeriod({
            period: subscribePeriod,
            id: subscribeId,
          }),
        );
      } else {
        dispatch(multisendActionCreator.setSubscribePeriod(null));
      }
    },
  getSubscribe:
    (planId: number) => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = store().WalletReducer.tronWeb;
      const subscribePlans = store().multisendReducer.subscribePlans;
      const feeTypes = store().multisendReducer.feeTypes;
      if (!tronWeb) return;

      const MultisendContract = await tronWeb.contract(
        ExonMultisendContract.abi,
        config().MULTISEND_CONTRACT,
      );
      dispatch(multisendActionCreator.setMultisendState('subscribe'));

      if (!feeTypes.subscribeFee.isTrx) {
        const TokenContract = await tronWeb.contract(
          ITRC20.abi,
          feeTypes.sendFee.tokenAddress.address,
        );

        var approveTransaction;
        try {
          approveTransaction = await TokenContract.approve(
            config().MULTISEND_CONTRACT,
            tronWeb.toSun(subscribePlans[planId].price),
          ).send();
        } catch (error) {
          console.log(error);
          dispatch(
            ApplicationAcionCreator.setNotificationStatus(
              true,
              true,
              'Multisend',
              'Error',
              '',
              `${error}`,
            ),
          );
          return;
        }

        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            false,
            true,
            'Multisend',
            'Approve',
            approveTransaction,
            `Approve your fee token`,
          ),
        );

        if (await getTransactionResult(approveTransaction, tronWeb)) {
          dispatch(
            ApplicationAcionCreator.setNotificationStatus(
              true,
              true,
              'Multisend',
              'Error',
              approveTransaction,
              `An error occured during fee token approve`,
            ),
          );
          return;
        }
      }

      let sendAmount = feeTypes.subscribeFee.isTrx
        ? subscribePlans[planId].price * 10 ** 6
        : 0;

      let getSubscribe;
      try {
        if (feeTypes.subscribeFee.isTrx) {
          getSubscribe = await MultisendContract.usersSubscribeFeeTrx(
            planId,
          ).send({ callValue: sendAmount });
        } else {
          getSubscribe = await MultisendContract.usersSubscribeFeeTokens(
            planId,
          ).send({ callValue: sendAmount });
        }
      } catch (error) {
        console.log(error);
      }

      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          false,
          true,
          'Multisend',
          'Processing',
          getSubscribe,
          `Your subscription is on the way!`,
        ),
      );

      if (await getTransactionResult(getSubscribe, tronWeb)) {
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Multisend',
            'Error',
            getSubscribe,
            `An error occured during your subscription`,
          ),
        );
        return;
      }

      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'Multisend',
          'Success',
          getSubscribe,
          `Congratulations your subscription is valid to ${new Date(
            Date.now() + subscribePlans[planId].time * 86400 * 1000,
          ).toDateString()}`,
        ),
      );
      dispatch(multisendActionCreator.setMultisendState('initial'));
    },
  sendTokensToUsers:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = store().WalletReducer.tronWeb;
      const sendAddresses = store().multisendReducer.sendAddresses;
      const amountPerAddress = store().multisendReducer.amountPerAddress;
      const oneAddressPrice = store().multisendReducer.oneAddressPrice;
      const subscribePeriod = store().multisendReducer.subscribePeriod;
      const tokenAddress = store().multisendReducer.tokenAddress;
      const whitelisted = store().multisendReducer.whitelisted;
      const feeTypes = store().multisendReducer.feeTypes;
      if (!tronWeb) return;

      const MultisendContract = await tronWeb.contract(
        ExonMultisendContract.abi,
        config().MULTISEND_CONTRACT,
      );

      let newSendAddresses: string[] = [];

      for (let i = 0; i < sendAddresses.length; i++) {
        newSendAddresses[i] = sendAddresses[i];
      }

      let feeFree =
        (subscribePeriod && subscribePeriod.period * 1000 > Date.now()) ||
        whitelisted;

      const amountToSend = feeFree
        ? 0
        : feeTypes.sendFee.isTrx
          ? tronWeb.toSun(sendAddresses.length * oneAddressPrice)
          : 0;

      let sendTokens;

      let sameToken =
        feeTypes.sendFee.tokenAddress.address === tokenAddress.address;

      dispatch(
        ApplicationAcionCreator.setTransactionSteps(
          !feeFree && !feeTypes.sendFee.isTrx && !sameToken
            ? ['Approve fee token', 'Approve send token']
            : ['Approve send token'],
        ),
      );
      dispatch(multisendActionCreator.setMultisendState('multisendSteps'));

      const TokenContract = await tronWeb.contract(
        ITRC20.abi,
        feeTypes.sendFee.tokenAddress.address,
      );

      const sendTokenContract = await tronWeb.contract(
        ITRC20.abi,
        tokenAddress.address,
      );

      if (!feeFree && !feeTypes.sendFee.isTrx && !sameToken) {
        var approveTransaction;
        const approveFeeToken = async () => {
          approveTransaction = await TokenContract.approve(
            config().MULTISEND_CONTRACT,
            BigInt(sendAddresses.length * oneAddressPrice * 10 ** 6).toString(),
          ).send();
          return approveTransaction
        }
        try {
          do { approveTransaction = await approveFeeToken() }
          while (approveTransaction === undefined)
        } catch (error) {
          console.log(error);
          dispatch(
            ApplicationAcionCreator.setNotificationStatus(
              true,
              true,
              'Multisend',
              'Error',
              '',
              `${error}`,
            ),
          );
          dispatch(multisendActionCreator.setMultisendState('initial'));
          return;
        }

        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            false,
            true,
            'Multisend',
            'Approve',
            approveTransaction,
            `Approve your fee token`,
          ),
        );
        dispatch(
          ApplicationAcionCreator.setTransactionStepStatus(
            `Approve fee token`,
            true,
            false,
          ),
        );

        if (await getTransactionResult(approveTransaction, tronWeb)) {
          dispatch(
            ApplicationAcionCreator.setNotificationStatus(
              true,
              true,
              'Multisend',
              'Error',
              approveTransaction,
              `An error occured during fee token approve`,
            ),
          );
          dispatch(multisendActionCreator.setMultisendState('initial'));
          return;
        }

        dispatch(
          ApplicationAcionCreator.setTransactionStepStatus(
            `Approve fee token`,
            false,
            true,
          ),
        );
      }

      var approveTransaction;
      const approveSendToken = async () => {
        let approveTransaction
        approveTransaction = await sendTokenContract.approve(
          config().MULTISEND_CONTRACT,
          BigInt(
            sameToken && !feeFree && !feeTypes.sendFee.isTrx
              ? (oneAddressPrice * sendAddresses.length +
                sendAddresses.length * amountPerAddress) *
              10 ** tokenAddress.decimals
              : sendAddresses.length *
              amountPerAddress *
              10 ** tokenAddress.decimals,
          ).toString(),
        )
          .send();
        return approveTransaction
      }


      try {
        approveTransaction = await sendTransactionHanlder(() => approveSendToken())
      } catch (error: any) {
        console.log(error);
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Multisend',
            'Error',
            '',
            `${error}`,
          ),
        );
        dispatch(multisendActionCreator.setMultisendState('initial'));
        return;
      }

      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          false,
          true,
          'Multisend',
          'Approve',
          sendTokens,
          `Approve your send token for sending`,
        ),
      );
      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Approve send token`,
          true,
          false,
        ),
      );

      if (await getTransactionResult(approveTransaction, tronWeb)) {
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Multisend',
            'Error',
            sendTokens,
            `An error occured during send token approve`,
          ),
        );
        dispatch(multisendActionCreator.setMultisendState('initial'));
        return;
      }

      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Approve send token`,
          false,
          true,
        ),
      );
      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Signature confirming`,
          true,
          false,
        ),
      );
      const sendTokensTransaction = async () => {
        let sendTokens
        if (feeTypes.sendFee.isTrx) {
          sendTokens = await MultisendContract.sendToUsersFeeTrx(
            newSendAddresses,
            BigInt(amountPerAddress * 10 ** tokenAddress.decimals).toString(),
            tokenAddress.address,
          ).send({ callValue: amountToSend, feeLimit: 5000000000 });
        } else {
          sendTokens = await MultisendContract.sendToUsersFeeTokens(
            newSendAddresses,
            BigInt(amountPerAddress * 10 ** tokenAddress.decimals).toString(),
            tokenAddress.address,
          ).send({ callValue: amountToSend, feeLimit: 5000000000 });
        }
        return sendTokens
      }
      try {
        sendTokens = await sendTransactionHanlder(() => sendTokensTransaction())
      } catch (error: any) {
        console.log('SEND ERROR: ', error.message);
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            false,
            true,
            'Multisend',
            'Error',
            sendTokens,
            `An error occured during sending tokens to ${sendAddresses.length} users`,
          ),
        );
        dispatch(multisendActionCreator.setMultisendState('initial'));
        return;
      }

      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          false,
          true,
          'Multisend',
          'Processing',
          sendTokens,
          `Sending tokens to ${sendAddresses.length} users`,
        ),
      );

      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Signature confirming`,
          false,
          true,
        ),
      );

      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Finished`,
          true,
          false,
        ),
      );

      if (await getTransactionResult(sendTokens, tronWeb)) {
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Multisend',
            'Error',
            sendTokens,
            `An error occured during sending tokens`,
          ),
        );
        dispatch(multisendActionCreator.setMultisendState('initial'));
        return;
      }

      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Finished`,
          false,
          true,
        ),
      );

      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'Multisend',
          'Success',
          sendTokens,
          `Congratulations! ${sendAddresses.length} users got their tokens`,
        ),
      );
    },
  sendTokensToUsersTrx:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = store().WalletReducer.tronWeb;
      const sendAddresses = store().multisendReducer.sendAddresses;
      const amountPerAddress = store().multisendReducer.amountPerAddress;
      const oneAddressPrice = store().multisendReducer.oneAddressPrice;
      const subscribePeriod = store().multisendReducer.subscribePeriod;
      const tokenAddress = store().multisendReducer.tokenAddress;
      const whitelisted = store().multisendReducer.whitelisted;
      const feeTypes = store().multisendReducer.feeTypes;
      if (!tronWeb) return;

      const MultisendContract = await tronWeb.contract(
        ExonMultisendContract.abi,
        config().MULTISEND_CONTRACT,
      );
      let newSendAddresses: string[] = [];

      for (let i = 0; i < sendAddresses.length; i++) {
        newSendAddresses[i] = sendAddresses[i];
      }

      let feeFree =
        (subscribePeriod && subscribePeriod.period * 1000 > Date.now()) ||
        whitelisted;

      const amountToSend = () => {
        if (feeFree) {
          return tronWeb.toSun(sendAddresses.length * amountPerAddress);
        } else {
          if (feeTypes.sendFee.isTrx) {
            return tronWeb.toSun(
              sendAddresses.length * oneAddressPrice +
              sendAddresses.length * amountPerAddress,
            );
          } else {
            return tronWeb.toSun(sendAddresses.length * amountPerAddress);
          }
        }
      };

      dispatch(
        ApplicationAcionCreator.setTransactionSteps(
          !feeFree && !feeTypes.sendFee.isTrx
            ? ['Approve fee token']
            : undefined,
        ),
      );
      dispatch(multisendActionCreator.setMultisendState('multisendStepsTrx'));

      if (!feeFree && !feeTypes.sendFee.isTrx) {
        const TokenContract = await tronWeb.contract(
          ITRC20.abi,
          feeTypes.sendFee.tokenAddress.address,
        );

        var approveTransaction;
        const approveTokenTransaction = async () => {
          approveTransaction = await TokenContract.approve(
            config().MULTISEND_CONTRACT,
            BigInt(sendAddresses.length * oneAddressPrice * 10 ** 6).toString(),
          ).send();

          return approveTransaction
        }
        try {
          approveTransaction = await sendTransactionHanlder(() => approveTokenTransaction())
        } catch (error) {
          console.log(error);
          dispatch(
            ApplicationAcionCreator.setNotificationStatus(
              true,
              true,
              'Multisend',
              'Error',
              '',
              `${error}`,
            ),
          );
          dispatch(multisendActionCreator.setMultisendState('initial'));
          return;
        }

        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            false,
            true,
            'Multisend',
            'Approve',
            approveTransaction,
            `Approve your fee token`,
          ),
        );
        dispatch(
          ApplicationAcionCreator.setTransactionStepStatus(
            `Approve fee token`,
            true,
            false,
          ),
        );

        if (await getTransactionResult(approveTransaction, tronWeb)) {
          dispatch(
            ApplicationAcionCreator.setNotificationStatus(
              true,
              true,
              'Multisend',
              'Error',
              approveTransaction,
              `An error occured during token approve`,
            ),
          );
          dispatch(multisendActionCreator.setMultisendState('initial'));
          return;
        }

        dispatch(
          ApplicationAcionCreator.setTransactionStepStatus(
            `Approve fee token`,
            false,
            true,
          ),
        );
      }

      let sendTokens;

      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Signature confirming`,
          true,
          false,
        ),
      );

      const sendTokensTransaction = async () => {
        let sendTokens

        if (feeTypes.sendFee.isTrx) {
          sendTokens = await MultisendContract.sendToUsersTrxFeeTrx(
            newSendAddresses,
            BigInt(amountPerAddress * 10 ** tokenAddress.decimals).toString(),
          ).send({ callValue: amountToSend(), feeLimit: 5000000000 });
        } else {
          sendTokens = await MultisendContract.sendToUsersTrxFeeTokens(
            newSendAddresses,
            BigInt(amountPerAddress * 10 ** tokenAddress.decimals).toString(),
          ).send({ callValue: amountToSend(), feeLimit: 5000000000 });
        }

        return sendTokens
      }
      try {
        sendTokens = await sendTransactionHanlder(() => sendTokensTransaction())
      } catch (error) {
        console.log(error);
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            false,
            true,
            'Multisend',
            'Error',
            sendTokens,
            `An error occured during sending TRX to ${sendAddresses.length} users`,
          ),
        );
        dispatch(multisendActionCreator.setMultisendState('initial'));
        return;
      }

      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          false,
          true,
          'Multisend',
          'Processing',
          sendTokens,
          `Sending TRX to ${sendAddresses.length} users`,
        ),
      );

      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Signature confirming`,
          false,
          true,
        ),
      );

      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Finished`,
          true,
          false,
        ),
      );

      if (await getTransactionResult(sendTokens, tronWeb)) {
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Multisend',
            'Error',
            sendTokens,
            `An error occured during sending TRX`,
          ),
        );
        dispatch(multisendActionCreator.setMultisendState('initial'));
        return;
      }

      dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Finished`,
          false,
          true,
        ),
      );

      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          'Multisend',
          'Success',
          sendTokens,
          `Congratulations! ${sendAddresses.length} users got their TRX`,
        ),
      );
    },
};
