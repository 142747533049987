import useWalletSelector from 'hooks/useWalletSelector';
import ProposalCard from 'pages/Voting/components/ProposalCard';
import React, { FunctionComponent } from 'react';

type Props = {
  name: string;
  classname: string;
  onClick: any;
  disabled?: boolean;
};

const ButtonProcess: FunctionComponent<Props> = ({
  name,
  classname,
  onClick,
  disabled
}) => {
  const { notification } = useWalletSelector(state => state.applicationReducer);
  return (
    <button
      type="button"
      className={classname}
      onClick={onClick}
      disabled={notification.isShown || disabled}
      style={(notification.isShown || disabled ) ? { opacity: '0.5' } : {}}
    >
      {name}
    </button>
  );
};

export default ButtonProcess;
