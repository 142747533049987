import React, { useState, useEffect } from 'react';
import Button from 'components/Button';
import ButtonSwap from 'components/Common/Buttons/ButtonSwap';
import ButtonSelectEmpty from 'components/Common/Buttons/ButtonSwapEmpty';
import { SelectToken } from 'pages/Launchpad/components/LevelPairSelect/SelectToken';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { launchpadActionCreator } from 'store/reducers/launchpad/action-creator';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';
import useWalletSelector from 'hooks/useWalletSelector';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import { UserInfoByTokens } from 'store/reducers/launchpad/types';

type Props = {
  whiteListTokens: string[];
  selectedToken: string;
  tokenAmount: number;
  whitelistedToken: UserInfoByTokens;
  currentAccount: number;
  userDataIsFetched: boolean;
  active: boolean;
};

const PoolWhitelist: FunctionComponent<Props> = ({
  whiteListTokens,
  selectedToken,
  tokenAmount,
  whitelistedToken,
  currentAccount,
  userDataIsFetched,
  active,
}) => {
  const dispatch = useDispatch();
  const { isWallet } = useWalletSelector(state => state.WalletReducer);
  const [jointState, setJoinState] = useState(true);

  const setTokenWhitelist = (tokenAddress: string) => {
    dispatch(launchpadActionCreator.setSelectedTokenWhitelist(tokenAddress));
  };

  const joinIDOHandle = () => {
    setJoinState(false);
    dispatch(launchpadActionCreator.joinWhitelistIdo());
  };

  useEffect(() => {
    if (userDataIsFetched === true) {
      setJoinState(true);
    }
  }, [userDataIsFetched]);

  const selectedTokenInfo = (token: string) => {
    let tokenName = getTokenInfoByAddress(token).name;
    return `${tokenAmount} ${tokenName}`;
  };

  return (
    <div className="px-5 py-8 text-center mb-14 bg-cardBg rounded-4xl">
      <div className="flex items-center justify-between mb-5">
        <h3 className="text-2xl font-medium">Whitelist</h3>
        <div className="px-5 py-3 text-xl font-medium rounded-xl bg-lightBlackO">
          {!!whitelistedToken.amount ? 'Eligable' : 'Not eligable'}
        </div>
      </div>
      {whitelistedToken.amount ? (
        <div className="text-xl text-left text-yellow">
          You have been already whitelisted {whitelistedToken.amount}{' '}
          {getTokenInfoByAddress(whitelistedToken.token).name}
        </div>
      ) : (
        <>
          <div className="flex items-center mb-8 gap-4">
            <span className='text-sm sm:text-lg'> Choose token for whitelist </span>
            <SelectToken
              selectedToken={selectedToken}
              tokenSelectList={whiteListTokens}
              setToken={setTokenWhitelist}
            />
          </div>
          {selectedToken && (
            <p className="mt-4 mb-8 text-left">
              You need to have{' '}
              <span className="text-yellow">
                {selectedTokenInfo(selectedToken)}
              </span>
            </p>
          )}
          {isWallet ? (
            <Button
              onClick={joinIDOHandle}
              disabled={
                selectedToken === '' ||
                !!whitelistedToken.amount ||
                !currentAccount ||
                !userDataIsFetched ||
                !active ||
                !jointState
              }
            >
              Join IDO
            </Button>
          ) : (
            <ConnectWalletButton />
          )}
        </>
      )}
    </div>
  );
};

export default PoolWhitelist;
