import React, { FunctionComponent } from 'react';
// import isMobile from 'react-device-detect';

type Props = {
  title: string;
  isActive?: boolean;
};

const TabItem: FunctionComponent<Props> = ({ title, isActive }) => {
  return (
    <>
      <li
        className={`py-2 px-6 lg:py-6 sm:px-10 lg:px-10 text-center text-sm sm:text-xl 
rounded-t-3xl cursor-pointer ${isActive
            ? 'bg-yellow text-black'
            : 'text-description hover:text-yellow hover:text-opacity-60 hover:underline'
          } `}
      >
        {title}
      </li>
    </>
  );
};

export default TabItem;
