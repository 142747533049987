import Block from 'components/Block';
import React, { FunctionComponent } from 'react';
import { isTablet } from 'react-device-detect';
import { formatNumber } from 'utils/formatNumber';

type Props = {
  dotColor: string;
  title: string;
  personalSwap?: number;
  personalLiquidity?: number;
  teamSwap?: number
  teamLiquidity?: number
};

const Turnover: FunctionComponent<Props> = ({ dotColor, title, personalSwap, personalLiquidity, teamSwap, teamLiquidity }) => {
  return (
    <Block className="mt-5 order-2">
      <h2 className="flex items-center sm:text-xl lg:text-2xl">
        <div className={`w-3 h-3 rounded-full mr-5 ${dotColor}`}></div>
        <span className='text-description'>{title}</span>
      </h2>
      <div
        className="flex flex-col xl:ml-8 mt-7"
        style={isTablet ? { flexDirection: 'column' } : {}}
      >
        <div className="xl:mr-12 flex  flex-col lg:flex-row items-center">
          <p className="text-xl sm:text-base xl:text-lg text-description">Liquidity:</p>
          <p className="text-base sm:text-xs xl:text-2xl  ml-4 font-semibold text-lightGrey">
            $ {formatNumber(personalLiquidity || teamLiquidity || '0')}
          </p>
        </div>
        <div className="mt-5 flex flex-col lg:flex-row items-center">
          <p className="text-xl sm:text-base xl:text-lg text-description">Swap turnover:</p>
          <p className="text-base sm:text-xs xl:text-2xl ml-4 font-semibold text-lightGrey">
            $ {formatNumber(personalSwap || teamSwap || '0')}
          </p>
        </div>
      </div>
    </Block>
  );
};

export default Turnover;
