import React, { FunctionComponent, useState } from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Arrow } from 'components/Icons';
import Block from 'components/Block';
import { useDispatch } from 'react-redux';
import { WalletConnectActionCreator } from 'store/reducers/wallet/action-creators';
import { useHistory } from 'react-router';

SwiperCore.use([Pagination]);

const PossibilitiesSlider: FunctionComponent = () => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const dispatch = useDispatch();
  const history = useHistory();
  const connectWallet = (e: any) => {
    dispatch(WalletConnectActionCreator.connectWallet());
    history.push('/account');
  };

  return (
    <div className="relative flex items-center sm:hidden gap-4">
      <div
        className="hidden -mt-2 lg:block"
        onClick={() => swiper?.slidePrev()}
      >
        <Arrow className="w-8 h-8" />
      </div>
      <Swiper
        className="relative w-full mt-7 "
        loop
        autoHeight
        pagination={{
          clickable: true,
          el: '.swiper-pagination',
          type: 'bullets',
        }}
        slidesPerView={1}
        spaceBetween={20}
        onSwiper={swiper => setSwiper(swiper)}
      >
        <SwiperSlide>
          <Block small className="flex flex-col justify-between flex-1 h-60">
            <p className="text-xs text-description">Your business in one NFT account</p>
            <div className="mt-3 text-xl font-semibold text-lightYellow">
              Free collection
            </div>
            <button
              onClick={e => connectWallet(e)}
              className="w-full px-4 py-2 mt-8 text-xl tracking-wide text-black rounded-lg bg-yellow"
            >
              Get your NFT
            </button>
          </Block>
        </SwiperSlide>
        <SwiperSlide>
          <Block small className="flex flex-col justify-between flex-1 h-60">
            <p className="text-xs text-description">
              Higher income for liquidity providers
            </p>
            <div className="mt-3 text-xl font-semibold text-lightYellow">
              100%
            </div>
            <button onClick={() => history.push('/swap')} className="w-full px-4 py-2 mt-8 text-xl tracking-wide text-black rounded-lg bg-yellow">
              Try swap
            </button>
          </Block>
        </SwiperSlide>
        <SwiperSlide>
          <Block small className="flex flex-col justify-between flex-1 h-60 filter brightness-50">
            <p className="text-xs text-description">Stake LP tokens and earn up to</p>
            <div className="mt-3 text-xl font-semibold text-lightYellow">
              10 000%
            </div>
            <button className="w-full px-4 py-2 mt-8 text-xl tracking-wide text-black rounded-lg bg-yellow">
              Start farming
            </button>
          </Block>
        </SwiperSlide>
        <SwiperSlide>
          <Block small className="flex flex-col justify-between flex-1 h-60 filter brightness-50">
            <p className="text-xs text-description">
              Be a part of a platform via voting
            </p>
            <div className="mt-3 text-xl font-semibold text-lightYellow">
              Vote for features
            </div>
            <button className="w-full px-4 py-2 mt-8 text-xl tracking-wide text-black rounded-lg bg-yellow">
              Start voting
            </button>
          </Block>
        </SwiperSlide>
        <SwiperSlide>
          <Block small className="flex flex-col justify-between h-60">
            <div>
              <p className="text-xs text-description">
                Personal Liquidity in DEX. By distributing a personal liquidity
                link, you can increase the number of trades in previously
                blocked assets and earn more on commissions.
              </p>
              <div className="mt-3 text-xl font-semibold text-lightYellow">
                Gain your profit now
              </div>
            </div>
            <button onClick={() => history.push('/swap/liquidity')} className="w-full px-4 py-2 mt-2 text-xl tracking-wide text-black rounded-lg bg-yellow">
              Add liquidity
            </button>
          </Block>
        </SwiperSlide>
        <SwiperSlide>
      <Block
        small
				className="flex flex-col items-center justify-between flex-1 lg:flex-row h-60"
      >
        <div className="pr-10">
					<p className='text-xs text-description'>
            Exon Pool is innovative decentralized system. Fast income with the only condition, you need to have tokens to take part in pool.
					</p>
					<div className="mt-3 text-xl font-semibold text-lightYellow">
						Get first users for your project
					</div>
        </div>
				<button onClick={() => history.push('/launchpool')} className="w-full py-2 mt-8 text-xl tracking-wide text-black rounded-lg bg-yellow whitespace-nowrap">
					Go to launchpool
				</button>
      </Block>
        </SwiperSlide>
        <SwiperSlide>
      <Block
        small
				className="flex flex-col items-center justify-between flex-1 lg:flex-row h-60"
      >
        <div className="pr-10">
					<p className='text-xs text-description'>
						Exon IDO is unique launchpad where you can maximize your profit by participating in the initial DEX offerings
					</p>
					<div className="mt-3 text-xl font-semibold text-lightYellow">
						Make perfect investment in a new project
					</div>
        </div>
				<button onClick={() => history.push('/launchpad')} className="w-full py-2 mt-8 text-xl tracking-wide text-black rounded-lg bg-yellow whitespace-nowrap">
					Go to launchpad
				</button>
      </Block>
        </SwiperSlide>
        <div className="mt-8 swiper-pagination"></div>
      </Swiper>
      <div
        className="hidden -mt-2 lg:block"
        onClick={() => swiper?.slideNext(10)}
      >
        <Arrow className="w-8 h-8 transform -rotate-180" />
      </div>
    </div>
  );
};

export default PossibilitiesSlider;
