import Block from 'components/Block';
import React, { FunctionComponent } from 'react';
import { coinIcons } from 'components/CoinIcons';

type Props = {
  coinOneIcon: string;
  coinTwoIcon: string;
  coinOneName: string;
  coinTwoName: string;
  liquidity: string;
  apy: string;
};

const PairCard: FunctionComponent<Props> = ({
  coinOneIcon,
  coinTwoIcon,
  coinOneName,
  coinTwoName,
  liquidity,
  apy,
}) => {
  return (
    <Block small light className="sm:flex-1 lg:w-1/3 px-6">
      <div className="flex justify-between items-center">
        <div className="flex">
          <img
            src={coinIcons[coinOneIcon]}
            alt="Coin one"
            style={{
              width: '55px',
              zIndex: 1,
              borderRadius: '50%',
            }}
          />
          <img
            src={coinIcons[coinTwoIcon]}
            alt="Coin two"
            style={{ width: '55px', marginLeft: '-20px' }}
          />
        </div>
        <div className="inline-flex px-4 py-2 text-yellow bg-yellow bg-opacity-15 border uppercase border-yellow rounded-lg">
          Top
        </div>
      </div>

      <div className="mt-2">
        <div className="text-title text-2xl font-semibold">
          {coinOneName} {'-'} {coinTwoName}
        </div>
        <p className="text-description">Liquidity {liquidity}</p>
      </div>

      <div className="flex justify-between items-center mt-8">
        <div className="text-2xl text-lightYellow font-semibold">{apy} APY</div>
        <button className="bg-description bg-opacity-40 py-2 px-6 text-2xl rounded-lg text-black">
          Farm
        </button>
      </div>
    </Block>
  );
};

export default PairCard;
