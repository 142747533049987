import { coinIcons } from 'components/CoinIcons';
import { PoolStatus } from 'pages/Launchpad/components/FeaturedPools/components/FeatPoolItem/PoolStatus';
import { FunctionComponent, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import '../../../FeaturedPools/FeaturedPools.module.css';
import { ReactComponent as Usdt } from './assets/ustd.svg';
import { formatNumber } from 'utils/formatNumber';
import s from '../../../FeaturedPools/FeaturedPools.module.css';
import { SecondsContext } from 'App';
import { TokenInfo } from 'store/reducers/types';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';
import Tooltip from 'components/Tooltip';

export interface props {
  active: boolean;
  poolName: string;
  poolImg: string;
  raise: number;
  minAllocation: number;
  maxAllocation: number;
  access: string;
  start: number;
  end: number;
  total: number;
  totalIDOAmount: number;
  poolId: number;
  saleTokenInfo: TokenInfo;
  buyTokens: string[];
  tokenPrices: number[];
  whiteListTokens: string[];
  whiteListAmounts: number[];
}

const PoolItem: FunctionComponent<props> = props => {
  const history = useHistory();

  const [priceHover, setPriceHover] = useState(false);
  const [whitelistHover, setWhitelistHover] = useState(false);
  const timeFormatter = (seconds: number) => {
    seconds = Math.floor(seconds);
    let hours;
    let minutes: any = Math.floor(seconds / 60);
    let secs: any = seconds % 60;
    if (secs < 10) {
      secs = '0' + secs;
    }
    if (minutes >= 60) {
      hours = Math.floor(minutes / 60);
      minutes %= 60;
    }
    if (hours && minutes < 10) {
      minutes = '0' + minutes;
    }

    return `${hours ? hours + ':' : ''}${minutes}:${secs}`;
  };

  const checkPoolStatus = () => {
    let currentTimestamp: number = Date.now() / 1000;
    if (props.start > currentTimestamp) {
      return timeFormatter(props.start - currentTimestamp);
    } else {
      if (props.active) {
        return 'Active';
      } else {
        return 'Closed';
      }
    }
  };

  const calculatePoolPercent = () => {
    let percent;
    percent = (props.totalIDOAmount / props.total) * 100;
    percent = percent >= 100 ? 100 : percent;
    return percent.toFixed(2);
  };

  const goToPool = () => {
    if (priceHover || whitelistHover) return;
    else history.push(`/launchpad/${props.poolId}`);
  };

  return (
    <div
      onClick={() => goToPool()}
      className={`relative w-full px-6 py-7 cursor-pointer sm:px-8 sm:py-8 ${s.slider__card_hover} bg-cardBg rounded-4xl`}
    >
      <div
        className={`flex ${props.poolImg ? 'justify-between' : 'justify-end'}`}
      >
        <img className="w-16 h-16" src={props.poolImg} />
        <PoolStatus type="" status={checkPoolStatus()} />
      </div>
      <h1 className="mt-4 mb-2 text-2xl font-medium text-lightGrey md:text-3xl ">
        {props.poolName}
      </h1>
      <div className="flex">
        Token prices
        <div
          onMouseOver={() => setPriceHover(true)}
          onMouseOut={() => setPriceHover(false)}
          className="font-semibold"
        >
          <Tooltip
            id={`launchpad-price-${props.poolId}`}
            place="top"
            yellow={true}
          >
            {props.buyTokens.map((el: string, index: number) => {
              return (
                <p className="whitespace-nowrap">
                  1 {props.saleTokenInfo.symbol} ={' '}
                  {1 / props.tokenPrices[index]}{' '}
                  {getTokenInfoByAddress(el).name}
                </p>
              );
            })}
          </Tooltip>
        </div>
      </div>
      <div className="mt-6">
        <small className="text-base font-normal md:text-lg text-description">
          Total raise
        </small>
        <div className="flex mb-5 align-center md:mb-6">
          <span className="text-2xl font-medium text-yellow md:text-2xl">
            {formatNumber(props.raise)} {props.saleTokenInfo.symbol}
          </span>
        </div>
        <div className="my-6">
          <div className="flex justify-between">
            <span className="text-base sm:text-lg">Progress</span>
          </div>
				 <span className="block text-sm sm:hidden">{calculatePoolPercent()}%</span>
          <div className="relative mt-2">
            <div
              style={{ boxShadow: '0 0 1px 2px #BBBBBB' }}
              className="h-2 border rounded-xl bg-darkGray border-lightBlack"
            ></div>
            <div
              style={{ width: calculatePoolPercent() + '%' }}
              className="absolute top-0 h-2 bg-yellow rounded-xl"
            ></div>
          </div>
          <div className="justify-between mt-3 text-sm font-semibold sm:flex sm:text-xs">
             <span className="hidden sm:block">{calculatePoolPercent()}%</span>
             <div className="flex justify-start sm:justify-around">
              <span>
                {formatNumber(props.totalIDOAmount)}{' '}
                {props.saleTokenInfo.symbol}
              </span>
              <span className="mx-2">/</span>
              <span>
                {formatNumber(props.total)} {props.saleTokenInfo.symbol}
              </span>
             </div>
          </div>
        </div>
        <div className="flex flex-col gap-5 sm:gap-0 md:justify-between md:flex-row">
          <div className="flex flex-col">
            <small className="mb-1 text-base font-normal md:text-lg md:mb-0 text-description">
              Min
            </small>
            <span className="text-2xl font-medium text-yellow md:text-2xl">
              {props.minAllocation} {props.saleTokenInfo.symbol}
            </span>
          </div>
          <div className="flex flex-col">
            <small className="mb-1 text-base font-normal md:text-lg md:mb-0 text-description">
              Max
            </small>
            <span className="flex text-2xl font-medium text-yellow md">
              {props.maxAllocation !== props.total
                ? `${props.maxAllocation} ${props.saleTokenInfo.symbol}`
                : 'No limit'}
            </span>
          </div>
          <div className="flex flex-col ">
            <small className="mb-1 text-base font-normal md:text-lg md:mb-0 text-description">
              Access
            </small>
            <div
              onMouseOver={() => setWhitelistHover(true)}
              onMouseOut={() => setWhitelistHover(false)}
              className="relative text-2xl font-medium text-yellow md:text-2xl"
            >
              <div className="flex items-center">
                <span>Token list</span>

                <Tooltip
                  id={`launchpad-tokens-${props.poolId}`}
                  place="top"
                  yellow={true}
                >
                  {props.whiteListTokens.map((el: string, index: number) => {
                    return (
                      <p className="text-base whitespace-nowrap">
                        {formatNumber(props.whiteListAmounts[index])}{' '}
                        {getTokenInfoByAddress(el).name}
                      </p>
                    );
                  })}
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolItem;
