import React, { FC, useState } from 'react';
import CloseIcon from './assets/close.svg';
import ButtonSelect from './Buttons/ButtonSelectCoin';
import ButtonSelectCoinList from './Buttons/ButtonSelectCointList';
import useWalletSelector from 'hooks/useWalletSelector';
import { useDispatch } from 'react-redux';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import { swapActionCreator } from 'store/reducers/swap/action-creators';
import configData from 'config';
import s from './SelectToken.module.css'
import { SearchTokenList } from 'store/reducers/application/types';
import {ReactComponent as Settings} from '../../assets/settings.svg'
import logoUndefined from '../../components/CoinIcons/assets/logoundefined.png'


const SelectToken: FC = () => {
  const dispatch = useDispatch();
  const { selected } = useWalletSelector(
    state => state.applicationReducer,
  );
  const tokensList = configData().TOKENS_DATA;
  const [importToken, setImportToken] = useState<null | SearchTokenList>(null)
  const [manageTokens, setManageTokens] = useState(false)
  const baseList = configData().TOKEN_BASE;
  const { searchTokenList } = useWalletSelector(state => state.applicationReducer)
  const [searchText, setSearchText] = useState('')
  const [searchByName, setSearchByName] = useState<string | null>('') 
  const searchRegExp = new RegExp(`${searchByName}`, 'g')

  const importFilteredList = JSON.parse(localStorage.getItem('tokenList') || '[]')
    .filter((el: { address: string; }) => searchText?.length === 34 ? el.address === searchText : true)
    .filter((el: { name: string; }) => searchByName ? el.name.match(searchRegExp) : true)

  const tokensFilteredList = tokensList
  .filter(el => searchText?.length === 34 ? el.address === searchText : true)
  .filter(el => searchByName ? el.name.match(searchRegExp) : true)

  const handlClosePopup = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(ApplicationAcionCreator.setSelectTokensModal(false));
  };

  const handleSearch = (e: any) => {
    let newText = e.target.value?.toString()
    newText = newText.match(/[a-z]|[A-Z]|[0-9]+/g)?.join('') || ''
    console.log(newText);
    setSearchText(newText)
    if(e.target.value.length === 34) {
      console.log(configData().TOKENS_DATA.filter(el => el.address === e.target.value));
      setSearchByName(null)
      if(!!configData().TOKENS_DATA.filter(el => el.address === e.target.value).length) return
      if(!!JSON.parse(localStorage.getItem('tokenList') || '[]').filter((el: { address: any; }) => el.address === e.target.value).length) return
      dispatch(ApplicationAcionCreator.searchTokenByAddress(e.target.value))
    }else if(!e.target.value){
      setSearchByName(null)
    }else {
      setSearchByName(newText?.toString().toUpperCase())
      dispatch(ApplicationAcionCreator.setSearchTokenList([]))
    }
  }

  const handlTokenSelectList = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    var result = e.currentTarget.id.split('_')[1];
    if (selected === 1) {
      dispatch(ApplicationAcionCreator.setFirstToken(tokensList.filter(el => el.address === result)[0]));
      dispatch(swapActionCreator.setSwapTokenBalances());
    }
    if (selected === 2) {
      dispatch(ApplicationAcionCreator.setSecondToken(tokensList.filter(el => el.address === result)[0]));
      dispatch(swapActionCreator.setSwapTokenBalances());
      dispatch(swapActionCreator.setSecondTokenPrice());
    }
    dispatch(ApplicationAcionCreator.setSelectTokensModal(false));
  };

  const handlTokenSelectBase = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    var result = parseInt(e.currentTarget.id.split('_')[1]);
    dispatch(swapActionCreator.getTokensReserve())
    dispatch(swapActionCreator.setSwapTokenBalances());
    if (selected === 1) {
      dispatch(ApplicationAcionCreator.setFirstToken(baseList[result]));
      dispatch(swapActionCreator.setSwapTokenBalances());
    }
    if (selected === 2) {
      dispatch(ApplicationAcionCreator.setSecondToken(baseList[result]));
      dispatch(swapActionCreator.setSwapTokenBalances());
    }
    dispatch(ApplicationAcionCreator.setSelectTokensModal(false));
  };

  const handleImportedTokenSelect = (e: any) => {
    var result = e.currentTarget.id.split('_')[1];
    if (selected === 1) {
      dispatch(ApplicationAcionCreator.setFirstToken(JSON.parse(localStorage.getItem('tokenList') || '[]').filter((el: { address: any; }) => el.address === result)[0]));
      dispatch(swapActionCreator.setSwapTokenBalances());
    }
    if (selected === 2) {
      dispatch(ApplicationAcionCreator.setSecondToken(JSON.parse(localStorage.getItem('tokenList') || '[]').filter((el: { address: any; }) => el.address === result)[0]));
      dispatch(swapActionCreator.setSwapTokenBalances());
      dispatch(swapActionCreator.setSecondTokenPrice());
    }
    dispatch(ApplicationAcionCreator.setSelectTokensModal(false));
  }

  
  
  return (
    <>
        <div
          style={{zIndex: 10000}}
          className="fixed z-10 inset-0 -y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"

        >
         
          <div className={`flex ${manageTokens ? 'items-center' : 'items-end' } justify-center z-0 min-h-screen pt-4 
          px-4 pb-20 text-center sm:block sm:p-0 overflow-y-scroll`}>
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div
              className="inline-block align-bottom bg-cardBg 
                 rounded-3xl text-left  shadow-xl w-full  
                 transform transition-all sm:my-7 
                 sm:align-middle max-h-screen overflow-y-auto
                 sm:max-w-sm sm:w-full"
            >
              {manageTokens 
              ? <>
                <div className="flex items-center justify-between mt-2">
                    <div onClick={() => setManageTokens(false)} className="text-xl relative leading-tight ml-2 mb-3 cursor-pointer">{`<-`}</div>
                    <div>
                      <h3
                        className="text-lg leading-6 font-medium text-description h-22"
                        id="modal-title"
                      >
                        Manage Tokens
                      </h3>
                    </div>
                    <div onClick={e => handlClosePopup(e)}>
                      <img
                        src={CloseIcon}
                        alt="close"
                        className="relative leading-tight w-6 mr-2 mb-3 cursor-pointer"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-3 p-6 mt-4">
                      {JSON.parse(localStorage.getItem('tokenList') || '[]').map((value: { icon: string; name: string; symbol: string | undefined; address: string | undefined; }, key: React.Key | null | undefined) => {
                      return (
                        <div
                          id={`list_${value.address}`}
                          key={key}
                          className='w-full'
                        >
                          <ButtonSelectCoinList address={value.address} icon={value.icon} dispatch={dispatch} name={value.name} manage />
                        </div>
                      );
                    })}
                  </div>
              </>
              : importToken 
              ? <>
                  <div className="flex items-center justify-between mt-2">
                    <div onClick={() => setImportToken(null)} className="text-xl relative leading-tight ml-2 mb-3 cursor-pointer">{`<-`}</div>
                    <div>
                      <h3
                        className="text-lg leading-6 font-medium text-description h-22"
                        id="modal-title"
                      >
                        Import {importToken.name}
                      </h3>
                    </div>
                    <div onClick={e => handlClosePopup(e)}>
                      <img
                        src={CloseIcon}
                        alt="close"
                        className="relative leading-tight w-6 mr-2 mb-3 cursor-pointer"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center w-full text-center p-5">
                    <div className="flex justify-center items-center self-center mb-2 w-10 h-10 rounded-full text-2xl bg-yellow text-black font-bold">!</div>
                    <div className="">This token doesn't appear on the active token list(s). Make sure this is the token that you want to trade.</div>
                  </div>
                  <div className="flex text-center justify-center my-5 px-4 items-center">
                    <img src={importToken.icon} className='w-20 h-20' />
                    <div className="flex flex-col text-left ml-4">
                      <span className=' text-2xl font-medium'>{importToken.name}</span>
                      <a className='text-xs text-yellow' target='_blank' href={configData().TRONSCAN + importToken.address}>{importToken.address}</a>
                    </div>
                  </div>
                  <div onClick={() => {
                    const currentSearchToken = {name: importToken.name, address: importToken.address, icon: importToken.icon, decimals: importToken.decimals}
                    const localTokens = [...JSON.parse(localStorage.getItem('tokenList') || '[]'), currentSearchToken]
                    dispatch(ApplicationAcionCreator.setSearchTokenList([]))
                    localStorage.setItem('tokenList', JSON.stringify(localTokens))
                    setSearchText('')
                    setImportToken(null)
                  }} className="cursor-pointer my-4 self-center rounded-4xl mx-4 py-2 bg-yellow text-black text-xl text-center font-medium">Import</div>
                </>
              : <>
              <div className="flex items-center justify-between mt-2">
                <div>
                  <h3
                    className="text-lg leading-6 font-medium text-description h-22 ml-11"
                    id="modal-title"
                  >
                    Select a token
                  </h3>
                </div>
                <div onClick={e => handlClosePopup(e)}>
                  <img
                    src={CloseIcon}
                    alt="close"
                    className="relative leading-tight w-6 mr-2 mb-3 cursor-pointer"
                  />
                </div>
              </div>
              <div className="mt-4 px-11">
                <input
                  type="text"
                  value={searchText}
                  onChange={e => handleSearch(e)}
                  className="w-full leading-9 rounded-t-lg bg-input px-4 outline-none"
                  placeholder="Enter asset name or address"
                  style={{
                    minHeight: '40px',
                    borderBottom: '1px solid yellow',
                  }}
                />
              </div>
              <div className="flex flex-col px-11 mt-4 ">
                <div className="text-description">Common bases</div>
                <div className="flex flex-wrap gap-3 mt-1">
                  {baseList.map((value, key) => {
                    return (
                      <div
                        id={`base_${key}`}
                        onClick={e => handlTokenSelectBase(e)}
                        key={key}
                        style={{width: '90px'}}
                      >
                        <ButtonSelect icon={value.icon} name={value.name} />
                      </div>
                    );
                  })}
                </div>
              </div>
              {!!JSON.parse(localStorage.getItem('tokenList') || '[]').length && <div className="flex flex-col px-11 mt-4 ">
                  <div className="flex items-center justify-between">
                    <div className="text-description">Imported</div>
                    <Settings onClick={() => setManageTokens(true)} className='w-5 h-5 fill-description cursor-pointer' />
                  </div>
                  <div className="flex flex-wrap gap-3 mt-1">
                    { importFilteredList.map((value: { icon: string; name: string; symbol: string | undefined; address: string | undefined; }, key: React.Key | null | undefined) => {
                      return (
                        <div
                          id={`list_${value.address}`}
                          onClick={e => handleImportedTokenSelect(e)}
                          key={key}
                          style={{width: '90px'}}
                        >
                          <ButtonSelect regExp={searchRegExp} icon={value.icon} name={value.name} imported />
                        </div>
                      );
                    })}
                    {!importFilteredList.length && <span className='text-xs text-gray-500'>Nothing found</span>}
                  </div>
                </div>}
              {( !searchTokenList.length || !!JSON.parse(localStorage.getItem('tokenList') || '[]').filter((el: { address: any; }) => el.address === searchText).length)
                ? <div className={`${s.tokens} flex flex-col justify-center p-11`}>
                  <div className="text-description">Token name</div>
                  <div className="overflow-x-auto flex flex-col gap-2 mt-1 max-h-72">
                    {tokensFilteredList.map((value, key) => {
                        return (
                          <div
                            id={`list_${value.address}`}
                            onClick={e => handlTokenSelectList(e)}
                            key={key}
                            className="w-full"
                          >
                            <ButtonSelectCoinList
                              regExp={searchRegExp}
                              icon={value.icon}
                              name={value.name}
                            />
                          </div>
                        );
                      })}
                    {!tokensFilteredList.length && <span className='text-xs text-gray-500'>Nothing found</span>}
                  </div>
              </div>
              : <div className={`${s.tokens} flex flex-col justify-center p-11`}>
              <div className="text-description">Search result</div>
              <div className="overflow-x-auto flex flex-col gap-2 max-h-72">
                {!!configData().TOKENS_DATA.filter(el => el.address === searchText).length 
                  ? configData().TOKENS_DATA.filter(el => el.address === searchText).map((value, key) => {
                    return (
                        <div
                          id={`list_${value.address}`}
                          onClick={e => handlTokenSelectList(e)} 
                          key={key}
                          className="w-full"
                        >
                        <ButtonSelectCoinList
                          name={value.name}
                          icon={value.icon}
                        />
                      </div>
                    );
                  })
                  : searchTokenList.map((value, key) => {
                  return (
                    <div
                      id={`list_${value.address}`}
                      // onClick={e => !!JSON.parse(localStorage.getItem('tokenList') || '[]').filter((el: { address: string; }) => el.address === value.address).length && handleImportedTokenSelect(e)}
                      key={key}
                      className="w-full"
                    >
                      <ButtonSelectCoinList
                        search
                        icon={value.icon}
                        name={value.name}
                        address={value.address}
                        decimals={value.decimals}
                        setSearchText={setSearchText}
                        dispatch={dispatch}
                        setImportToken={setImportToken}
                      />
                    </div>
                  );
                })}
              </div>
            </div>}
            </>}
            </div>
          </div>
          </div>
    </>
  );
};

export default SelectToken;
