import React, { FunctionComponent, useContext, useEffect } from 'react';
import Modal from 'components/Modal';
import SuccessIcon from './assets/success.svg';
import useWalletSelector from 'hooks/useWalletSelector';
import { useDispatch } from 'react-redux';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';
import { coinIcons } from 'components/CoinIcons';
import { SecondsContext } from 'App';

const LiquiditySuccess: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { liquidityState } = useWalletSelector(state => state.liquidityReducer);
  const { poolInfo } = useWalletSelector(state => state.addLiquidityReducer);
  const { firstTokenAmount, secondTokenAmount } = useWalletSelector(
    state => state.swapReducer,
  );
  const { firstToken, secondToken } = useWalletSelector(
    state => state.applicationReducer,
  );

  const handlCloseModal = () => {
    dispatch(LiquidityActionCreator.setLiquidityState('initial'));
  };
  
  return (
    <>
      {liquidityState === 'addLiquiditySuccess' && (
        <Modal closeModal={handlCloseModal}>
          <div className="flex flex-col items-center">
            <img src={SuccessIcon} className="w-20 h-20" alt="Success" />
            <h2 className="text-title text-center text-4xl mt-5 sm:mt-10">
              Pool was created successfully
            </h2>
            <div className="w-full text-description mt-4 sm:mt-8 flex flex-col items-center">
              <div className='flex items-center justify-center'>
                  <div className="w-2/4 flex items-center justify-center">
                  <img className="w-8 h-8 mx-2" src={coinIcons[firstToken.icon!]} alt="" />
                  <span className="text-lightYellow text-3xl mx-2">
                    {firstTokenAmount}
                  </span>
                  <span className='text-2xl'>{firstToken.name}</span>
                </div>
              </div>
              <span className="text-yellow text-opacity-50 text-4xl">+</span>
              <div className="flex items-center justify-center">
                <div className="w-2/4 flex items-center justify-center">
                  <img className="w-8 h-8 mx-2" src={coinIcons[secondToken.icon!]} alt="" />
                  <span className="text-lightYellow text-3xl mx-2">
                    {secondTokenAmount}
                  </span>
                  <span className='text-2xl'>{secondToken.name}</span>
                </div>
              </div>
            </div>
            <p className="text-center text-description mt-10">
              Transaction is being comfirmed on-chain. Add liquidity was
              successful and is confirmed.
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default LiquiditySuccess;
