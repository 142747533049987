export const Navigation = [
  {
    text: 'Home',
    icon: 'Home',
    path: '/',
  },
  {
    text: 'Exon NFT',
    icon: 'Account',
    path: '/exonnft',
  },
  {
    text: 'Exon Swap',
    icon: 'Swap',
    path: '/swap',
  },
  {
    text: 'Exon Farming',
    icon: 'Farming',
    path: '/farming',
  },
  {
    text: 'Exon Vote',
    icon: 'Vote',
    path: '/voting',
  },
  {
    text: 'Exon IDO',
    icon: 'Ido',
    path: '/launchpad',
  },
  {
    text: 'Exon Pool',
    icon: 'Launchpool',
    path: '/launchpool',
  },
	{
		text: 'Multisend',
		icon: 'Multisend',
		path: '/multisend'
	},
  {
    text: 'Analytics',
    icon: 'Analytics',
    path: '/analytics',
  },
  {
    text: 'Audit',
    icon: 'Audit',
    path: 'https://exoncenter.com/static/audit.pdf',
  },
  {
    text: 'Blog',
    icon: 'Blog',
    path: 'https://exoncenter.com/blog',
  },
  {
    text: 'Faq',
    icon: 'Faq',
    path: 'https://exoncenter.com/faq',
  },
];
