import sleep from 'utils/sleep';

export const sendTransactionHanlder = async (sendTransaction: any) => {
  let transactionLog;

  const getErrorMatch = (message: string) => {
    return message.match(/403/) || message.match(/Network Error/);
  };

  try {
    transactionLog = await sendTransaction();
  } catch (error: any) {
    if (getErrorMatch(error.message)) {
      await sleep(1000);
      await sendTransactionHanlder(sendTransaction);
    } else throw new Error(error);
  }
  return transactionLog;
};
