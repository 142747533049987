import { FunctionComponent, useEffect, useState } from 'react';
import { ReactComponent as Copy } from '../../../../../../assets/copy.svg';
import copyTextLib from 'copy-to-clipboard';
import { ReactComponent as Logo } from '../../../Pool/components/PoolItem/assets/poolLogo.svg';
import { ReactComponent as Tron } from '../../../FeaturedPools//components/FeatPoolItem/assets/tron.svg';
import { PoolStatus } from 'pages/Launchpad/components/FeaturedPools/components/FeatPoolItem/PoolStatus';
import useWalletSelector from 'hooks/useWalletSelector';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';
import { useHistory } from 'react-router';
import { coinIcons } from 'components/CoinIcons';
import Config from 'config/index';

const PoolHeader: FunctionComponent = () => {
  const [copy, setCopy] = useState(false);
  const { activePool } = useWalletSelector(state => state.launchpoolReducer);
  const date = new Date(activePool?.start! * 1000)?.toISOString();

  const copyText = (textToCopy: string) => {
    copyTextLib(textToCopy);
  };
  const copyToClipBoard = () => {
    const textToCopy = activePool?.tokenOut;
    copyText(textToCopy || '');
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };

  const timeFormatter = (seconds: number) => {
    seconds = Math.floor(seconds);
    let hours;
    let minutes: any = Math.floor(seconds / 60);
    let secs: any = seconds % 60;
    if (secs < 10) {
      secs = '0' + secs;
    }
    if (minutes >= 60) {
      hours = Math.floor(minutes / 60);
      minutes %= 60;
    }
    if (hours && minutes < 10) {
      minutes = '0' + minutes;
    }

    return `${hours ? hours + ':' : ''}${minutes}:${secs}`;
  };

  const checkPoolStatus = () => {
    let poolType;
    if (activePool?.randomShareBoth == 0) {
      poolType = 'random';
    } else {
      poolType = 'share';
    }
    let currentTimestamp: number = Date.now() / 1000;

    if (!!activePool?.poolCap) {
      if (activePool?.start > currentTimestamp) {
        return timeFormatter(activePool?.start - currentTimestamp);
      } else if (activePool?.poolCap <= activePool?.totalStakeRandom) {
        return 'Closed';
      } else if (activePool?.poolCap <= activePool?.totalStakeShare) {
        return 'Closed';
      } else if (activePool?.start < currentTimestamp) {
        if (
          poolType == 'random' &&
          activePool?.poolCap > activePool?.totalStakeRandom
        ) {
          return 'Active';
        } else if (
          poolType == 'share' &&
          activePool?.poolCap > activePool?.totalStakeShare
        ) {
          return 'Active';
        } else {
          return '';
        }
      } else return 'Loading';
    } else {
      if (activePool?.start! > currentTimestamp) {
        return timeFormatter(activePool?.start! - currentTimestamp);
      } else if (
        activePool?.start! < currentTimestamp &&
        currentTimestamp < activePool?.freeze!
      ) {
        return 'Active';
      } else if (
        activePool?.freeze! < currentTimestamp &&
        currentTimestamp < activePool?.end!
      ) {
        return 'Freeze';
      } else if (activePool?.end! < currentTimestamp) {
        return 'Closed';
      } else return 'Loading';
    }
  };

  return (
    <div className="px-5 pt-8 bg-cardBg pb-7 md:px-10 md:py-6 rounded-4xl">
      <div className="flex justify-between mb-4">
        <div className="flex items-center">
          <img
            className="w-12 h-12 rounded-full"
            src={
              coinIcons[
                getTokenInfoByAddress(activePool?.tokenOut!)?.name.toLowerCase()
              ]
            }
          />
          <span className="flex items-center ml-5 text-3xl font-medium sm:text-4xl text-title">
            {getTokenInfoByAddress(activePool?.tokenOut!)?.name}
          </span>
        </div>
        <PoolStatus status={checkPoolStatus()} type="" />
      </div>
      <div className="flex items-center gap-4">
        <a
          href={Config().TRONSCAN + `/#/contract/${activePool?.tokenOut!}`}
          target="_blank"
          className="text-xl font-semibold break-all cursor-pointer sm:text-4xl text-yellow"
        >
          {activePool?.tokenOut!.substring(0, 6) +
            '...' +
            activePool?.tokenOut!.substring(28, 34)}
        </a>
        <div className="relative">
          <Copy className="cursor-pointer" onClick={() => copyToClipBoard()} />
          <div id="tooltip" className={copy ? `tooltip active` : `tooltip`}>
            Copied
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start mt-4 md:items-center md:flex-row">
        <span className="text-base font-medium sm:text-xl text-title">
          Published {date.replace('T', ' ').split('.')[0]}
        </span>
      </div>
    </div>
  );
};

export default PoolHeader;
