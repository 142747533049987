import React, { FunctionComponent, useEffect, useState } from 'react';
import useWalletSelector from 'hooks/useWalletSelector';
import { coinIcons } from 'components/CoinIcons';
import Modal from 'components/Modal';
import { useDispatch } from 'react-redux';
import { swapActionCreator } from 'store/reducers/swap/action-creators';
import ButtonProcess from 'components/Button/ButtonProcess';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';

const SwapConfirm: FunctionComponent = () => {
  const { firstToken, secondToken } = useWalletSelector(
    state => state.applicationReducer,
  );
  const {
    firstTokenAmount,
    secondTokenAmount,
    swapState,
    priceImpact,
    firstTokenBalance,
    swapFee
  } = useWalletSelector(state => state.swapReducer);
  const { notification } = useWalletSelector(state => state.applicationReducer);
  const dispatch = useDispatch();
  const [maximumSoldTip, setMaximumSoldTip] = useState(false)
  const [providerTip, setProviderTip] = useState(false)
  const [priceTip, setPriceTip] = useState(false)

  const handlConfirm = () => {
    dispatch(swapActionCreator.setCurrentSwapState('steps'));
    dispatch(ApplicationAcionCreator.setTransactionStatus(true))
    if (firstToken.icon === 'trx') {
      dispatch(swapActionCreator.swapExactTRXForTokens());
    } else if (secondToken.icon === 'trx') {
      dispatch(swapActionCreator.swapExactTokensForTRX());
    } else {
      dispatch(swapActionCreator.swapTokensToTokens());
    }
    // dispatch(swapActionCreator.setCurrentSwapState('swapSuccess'));
  };

  const handlCloseModal = () => {
    dispatch(swapActionCreator.setCurrentSwapState('initial'));
    dispatch(ApplicationAcionCreator.setTransactionStatus(false))
  };

  useEffect(() => {
    if(swapState === 'swapConfirm') dispatch(swapActionCreator.setTokenFee())
  }, [swapState])

  return (
    <>
      {swapState === 'swapConfirm' && (
        <Modal closeModal={handlCloseModal}>
          <div className="relative text-title text-center text-2xl sm:text-4xl">
            Swap
          </div>
          <div className="mt-2 sm:mt-5">
            {/* from */}
            <div className="flex items-center gap-2 justify-center">
              <div>
                <img
                  src={coinIcons[firstToken.icon ? firstToken.icon : '']}
                  alt="icon"
                  className="w-8 h-8"
                />
              </div>
              <div className="text-lightYellow text-2xl sm:text-3xl">
                {firstTokenAmount}
              </div>
              <div
                className="text-description font-light text-xl sm:text-2xl "
                style={{ minWidth: '3rem' }}
              >
                {firstToken.name}
              </div>
            </div>
            {/* arrow */}
            <span className="inline-block relative left-1/2 transform rotate-90 -translate-x-1/2 text-yellow">{`->`}</span>
            {/* to */}
            <div className="flex justify-center items-center gap-2">
              <div>
                <img
                  src={coinIcons[secondToken.icon ? secondToken.icon : '']}
                  alt="icon"
                  className="w-8 h-8"
                />
              </div>
              <div className="text-lightYellow text-2xl sm:text-3xl">
                {secondTokenAmount}
              </div>
              <div
                className="text-description font-light text-xl sm:text-2xl"
                style={{ minWidth: '3rem' }}
              >
                {secondToken.name}
              </div>
            </div>
            {/* text */}
            <div className="flex flex-col gap-4 mt-2 sm:mt-8">
              <div className="flex flex-col sm:flex-row justify-between">
                <div className="text-title text-base sm:text-xl">Price:</div>
                <div className="text-title text-base sm:text-xl">
                  <div>
                    1 {firstToken.name} ={' '}
                    {(secondTokenAmount / firstTokenAmount).toFixed(4)}{' '}
                    {secondToken.name}
                  </div>
                  <div>
                    1 {secondToken.name} ={' '}
                    {(firstTokenAmount / secondTokenAmount).toFixed(4)}{' '}
                    {firstToken.name}
                  </div>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row justify-between relative ">
                <div className="flex items-center text-title text-base sm:text-xl">
                  Maximum Sold
                  <span
                    onMouseOut={() => setMaximumSoldTip(false)}
                    onMouseOver={() => setMaximumSoldTip(true)}
                    className="ml-2 inline-flex justify-center items-center w-5 h-5 rounded-full text-sm cursor-pointer"
                    style={{ backgroundColor: '#5F5F5F', color: '#171612' }}
                  >
                    ?
                  </span>
                </div>
                <div className="text-title text-base sm:text-xl">
                  {firstTokenAmount} {firstToken.name}
                </div>
                {maximumSoldTip && <div style={{backdropFilter: 'blur(20px)', transform: 'translate(0%, -100%)'}} className="absolute l-0 t-0 w-full sm:w-1/2 text-xs bg-transYellow py-2 px-4 rounded-2xl">Your transaction will revert if there is a large, unfavourite price movement before it is confirmed.</div>}
              </div>

              <div className="flex flex-col sm:flex-row relative justify-between">
                <div className="text-title text-base sm:text-xl">
                  Price Impact
                  <span
                    onMouseOut={() => setPriceTip(false)}
                    onMouseOver={() => setPriceTip(true)}
                    className="ml-2 inline-flex justify-center items-center w-5 h-5 rounded-full text-sm cursor-pointer"
                    style={{ backgroundColor: '#5F5F5F', color: '#171612' }}
                  >
                    ?
                  </span>
                </div>
                <div className="text-title text-base sm:text-xl">
                  {priceImpact < 0.01
                    ? `< 0.01%`
                    : `${priceImpact.toFixed(2)}%`}
                </div>
                {priceTip && <div style={{backdropFilter: 'blur(20px)', transform: 'translate(0%, -100%)'}} className="absolute l-0 t-0 w-full sm:w-1/2 text-xs bg-transYellow py-2 px-4 rounded-2xl">The difference between the market price and estimated price due to trade size.</div>}
              </div>

              <div className="flex flex-col sm:flex-row justify-between relative">
                <div className="text-title text-base sm:text-xl ">
                  Swap Provider Fee
                  <span
                    onMouseOut={() => setProviderTip(false)}
                    onMouseOver={() => setProviderTip(true)}
                    className="ml-2 inline-flex justify-center items-center w-5 h-5 rounded-full text-sm cursor-pointer"
                    style={{ backgroundColor: '#5F5F5F', color: '#171612' }}
                  >
                    ?
                  </span>
                </div>
                <div className="text-title text-base sm:text-xl">
                  {(firstTokenAmount * swapFee).toFixed(6)} {firstToken.name}
                </div>
                {providerTip && <div style={{backdropFilter: 'blur(20px)', transform: 'translate(0%, -100%)'}} className="absolute l-0 t-0 w-full sm:w-1/2 text-xs bg-transYellow py-2 px-4 rounded-2xl">You need to pay 0.1% of each transaction as fees</div>}
              </div>

              <div className="text-description text-xs sm:text-base mt-1 sm:mt-2">
                Input is estimated. You will sell at most {firstTokenBalance}{' '}
                {firstToken.name} or the transaction will revert. Please make
                sure you have enough energy and bandwith, or the transaction may
                fall.{' '}
              </div>
            </div>
          </div>
          <div className="sm:mb-3 mt-6 sm:mt-4 text-center">
            {/* <button
              type="button"
              className="w-full inline-flex justify-center 
                                        rounded-md border border-transparent shadow-sm px-6 py-2 
                                        bg-yellow text-base font-medium text-mainBlack
                                        hover:bg-black-700 focus:outline-none focus:ring-2 
                                        focus:ring-offset-2 
                                        focus:ring-red-500 
                                        sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handlConfirm}
            >
              Confirm swap
            </button> */}
            <ButtonProcess
              name="Confirm swap"
              classname="w-full inline-flex justify-center 
                                        rounded-md border border-transparent shadow-sm px-6 py-2 
                                        bg-yellow text-base font-medium text-mainBlack
                                        hover:bg-black-700 focus:outline-none focus:ring-2 
                                        focus:ring-offset-2 
                                        focus:ring-red-500 
                                        sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handlConfirm}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default SwapConfirm;
