import {FunctionComponent} from "react"

export type Props = {
    type: string;
    status: string;
}

export const PoolStatus:FunctionComponent<Props> = ({type, status}) => {
    return (
        <div className='flex items-center'>
            <span style={{maxWidth:'120px'}} className='px-4 sm:px-8 py-2 
                             text-base 
                             font-medium 
                             leading-none border text-poolGrey sm:base bg-poolBg border-poolGrey rounded-xl'>{status}</span>
        </div>
    )
}

