import React, { FunctionComponent } from 'react';
import Modal from 'components/Modal';
import { coinIcons } from 'components/CoinIcons';
import { useDispatch } from 'react-redux';
import useWalletSelector from 'hooks/useWalletSelector';
import { removeLiquidityActionCreator } from 'store/reducers/liquidityRemove/action-creator';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';
import ButtonProcess from 'components/Button/ButtonProcess';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import logoUndefined from '../../../../../../../../../../components/CoinIcons/assets/logoundefined.png'
import { formatNumber } from 'utils/formatNumber';

const RemoveLiquidityConfirm: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { liquidityState } = useWalletSelector(state => state.liquidityReducer);
  const { tronWeb } = useWalletSelector(state => state.WalletReducer);
  const { poolInfo, currentPoolTokenAmount, removeToken0, removeToken1 } = useWalletSelector(
    state => state.removeLiquidityReducer,
  );
  const handlRemoveLiquidityConfirm = () => {
    dispatch(LiquidityActionCreator.setLiquidityState('rmSteps'))
    dispatch(ApplicationAcionCreator.setTransactionStatus(true))
    if (
      poolInfo.firstDepositTokenInfo.icon === 'trx' ||
      poolInfo.secondDepositTokenInfo.icon === 'trx'
    ) {
      dispatch(removeLiquidityActionCreator.removeTrxLiquidity());
    } else {
      dispatch(removeLiquidityActionCreator.removeLiquidity());
    }
    // dispatch(removeLiquidityActionCreator.removeLiquidity());
  };
  const handlCloseModal = () => {
    dispatch(LiquidityActionCreator.setLiquidityState('initial'));
  };
// liquidityState === 'removeLiquidityConfirm'
  return (
    <>
      {liquidityState === 'removeLiquidityConfirm' && (
        <Modal closeModal={handlCloseModal}>
          <div className="flex flex-col items-center">
            <h2 className="text-title text-2xl sm:text-4xl">You will remove</h2>
            <div className="flex items-center text-description mt-10">
              <img
                src={coinIcons[poolInfo.firstDepositTokenInfo.icon!] || poolInfo.firstDepositTokenInfo.icon || logoUndefined}
                alt="Coin one"
                className="w-8 h-8 z-1 mr-2"
              />
              <div className="text-lightYellow text-3xl">{formatNumber(removeToken0 || '')}</div>
              <div className="text-2xl ml-2">
                {poolInfo.firstDepositTokenInfo.symbol}
              </div>
            </div>
            <div className="text-yellow text-opacity-50 text-4xl font-light">
              +
            </div>
            <div className="flex items-center text-description mt-1">
              <img
                src={coinIcons[poolInfo.secondDepositTokenInfo.icon!] || poolInfo.secondDepositTokenInfo.icon || logoUndefined}
                alt="Coin one"
                className="w-8 h-8 z-1 mr-2"
              />
              <div className="text-lightYellow text-3xl">{formatNumber(removeToken1 || '')}</div>
              <div className="text-2xl ml-2">
                {poolInfo.secondDepositTokenInfo.symbol}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row w-full items-start sm:items-center justify-between mt-10">
              <div className="text-xl">Burn LP token</div>
              <div className="flex items-center">
                <img
                  src={coinIcons[poolInfo.firstDepositTokenInfo.icon!] || poolInfo.firstDepositTokenInfo.icon || logoUndefined}
                  alt="Coin one"
                  className="w-8 h-8 z-1"
                />
                <img
                  src={coinIcons[poolInfo.secondDepositTokenInfo.icon!] || poolInfo.secondDepositTokenInfo.icon || logoUndefined}
                  alt="Coin two"
                  className="w-8 h-8 -ml-2"
                />
                <div className="text-xl">
                  <span className="px-2">
                    {formatNumber(((poolInfo.liquidity / 100) * currentPoolTokenAmount).toFixed(6))}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="flex flex-col sm:flex-row w-full sm:w-96 justify-between mt-10">
              <div className="text-xl">Price</div>
              <div>
                <div>1 USDT = 100500 EXON</div>
                <div>1 EXON = 100500 USDT</div>
              </div>
            </div> */}
            <div className="mt-4 text-center">
              {/* <button
                type="button"
                onClick={handlRemoveLiquidityConfirm}
                className="w-full inline-flex justify-center rounded-md border border-transparent 
                           shadow-sm px-6 py-2 bg-yellow text-base font-medium text-mainBlack 
                           hover:bg-black-700 focus:outline-none focus:ring-2 ocus:ring-offset-2 
                           focus:ring-red-500 sm:w-auto sm:text-xl"
              >
                Confirm remove
              </button> */}
              <ButtonProcess 
                name="Confirm remove"
                classname="w-full inline-flex justify-center rounded-md border border-transparent 
                shadow-sm px-6 py-2 bg-yellow text-base font-medium text-mainBlack 
                hover:bg-black-700 focus:outline-none focus:ring-2 ocus:ring-offset-2 
                focus:ring-red-500 sm:w-auto sm:text-xl"
                onClick={handlRemoveLiquidityConfirm}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default RemoveLiquidityConfirm;
