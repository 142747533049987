import Button from "components/Button";
import { coinIcons } from "components/CoinIcons";
import Modal from "components/Modal"
import useWalletSelector from "hooks/useWalletSelector";
import { useEffect, useState } from "react";
import { Range } from 'react-range';
import { useDispatch } from "react-redux";
import { ApplicationAcionCreator } from "store/reducers/application/action-creator";
import { farmingActionCreator } from "store/reducers/farmingAdd/action-creator";

export default () => {
    const { farmingState } = useWalletSelector( state => state.farmingReducer )
    const dispatch = useDispatch()

    const handleWithdraw = () => {
      dispatch(farmingActionCreator.setFarmingState('withdrawSteps'));
      // dispatch(farmingActionCreator.getWithdraw())
      dispatch(ApplicationAcionCreator.setTransactionStatus(true))
    }

    return(
      <>
      { farmingState === 'withdrawn' &&
          <Modal closeModal={() => dispatch(farmingActionCreator.setFarmingState('initial'))}>
              <h1 className='text-center text-2xl sm:text-4xl text-title'>Confirm claim</h1>
              <p className='text-title text-base sm:text-xl mt-10' >You will receive:</p>
              <p className='flex mt-2 items-center'>
                <img src={coinIcons['exon']} alt="" />
                <span className='ml-2 text-lightYellow text-2xl sm:text-3xl'>100500</span>
                <span className='ml-2 text-description text-2xl sm:text-3xl'>EXON</span>
              </p>
              <div className="flex justify-center mt-10">
                <Button onClick={handleWithdraw}>Withdraw</Button>
              </div>
          </Modal>
        }
      </>
    )
}