import Modal from 'components/Modal';
import useWalletSelector from 'hooks/useWalletSelector';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';
import Claim from './components/Claim';
import Stake from './components/Stake';

const StakeAndClaim = () => {
  const [state, setState] = useState('stake');
  const { farmingState, farmingStakeInfo } = useWalletSelector(
    state => state.farmingReducer,
  );
  const { liquidityPairs } = useWalletSelector(state => state.liquidityReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      farmingStakeInfo.firstTokenAddress &&
      farmingStakeInfo.secondTokenAddress &&
      liquidityPairs.length !== 0
    ) {
      dispatch(farmingActionCreator.detectFarmingLpToken());
    }
  }, [
    farmingStakeInfo.firstTokenAddress,
    farmingStakeInfo.secondTokenAddress,
    liquidityPairs,
  ]);

  useEffect(() => {
    if (farmingState === 'initial') setState('deposit');
  }, [farmingState]);

  return (
    <>
      {farmingState === 'deposit' && (
        <Modal
          closeModal={() => {
            dispatch(farmingActionCreator.resetFarmingStakeInfo());
            dispatch(farmingActionCreator.setFarmingState('initial'));
          }}
        >
          <div className="flex w-full flex-row justify-between items-center">
            <div
              className="flex justify-center w-full sm:mx-20 h-6 border-b"
              style={{ borderColor: '#7A7A7A' }}
            >
              <div
                id="swap"
                className={`inline-block border-b-1 mx-5 cursor-pointer
                  ${
                    state === 'deposit'
                      ? 'text-yellow border-b-thin border-yellow'
                      : ''
                  }
                `}
                onClick={event =>
                  setState(event.currentTarget.innerHTML.toLowerCase())
                }
              >
                Deposit
              </div>
              <div
                id="pool"
                className={`inline-block border-b-1 mx-5 cursor-pointer
                  ${
                    state === 'withdraw'
                      ? 'text-yellow border-b-thin border-yellow'
                      : ''
                  }
                `}
                onClick={event =>
                  setState(event.currentTarget.innerHTML.toLowerCase())
                }
              >
                Withdraw
              </div>
            </div>
          </div>
          {state === 'deposit' ? <Stake /> : <Claim />}
        </Modal>
      )}
    </>
  );
};

export default StakeAndClaim;
