import { LevelsEnergyAction, LevelsEnergyState, LevelsEnergyStateEnum } from "./types";

const initialState: LevelsEnergyState = {
  levelsEnergyState: 'initial',
  levelsPrice: [0, 0, 0, 0, 0],
  levelsBuyLimits: [0, 0, 0, 0, 0],
  levelsLimitCount: [0, 0, 0, 0, 0],
  totalSalesAmount: 0,
  chosenPacket: 0,
  actualClaimAmount: 0,
  userInfo: {
    amount: 0,
    packet: 0,
    referrals: 0,
    referralsAmount: 0,
    availableAmount: 0,
    buy: false
  }
}

export default function LevelsEnergyReducer(
  state = initialState,
  action: LevelsEnergyAction
) {
  switch (action.type) {
    case LevelsEnergyStateEnum.SET_LEVELS_PRICE:
      return { ...state, levelsPrice: action.payload }
    case LevelsEnergyStateEnum.SET_LEVELS_BUY_LIMITS:
      return { ...state, levelsBuyLimits: action.payload }
    case LevelsEnergyStateEnum.SET_LEVELS_LIMIT_COUNT:
      return { ...state, levelsLimitCount: action.payload }
    case LevelsEnergyStateEnum.SET_LEVELS_USER_INFO:
      return { ...state, userInfo: action.payload }
    case LevelsEnergyStateEnum.SET_LEVELS_CHOSEN_PACKET:
      return { ...state, chosenPacket: action.payload }
    case LevelsEnergyStateEnum.SET_LEVELS_ENERGY_STATE:
      return { ...state, levelsEnergyState: action.payload }
    case LevelsEnergyStateEnum.SET_ACTUAL_CLAIM_AMOUNT:
      return { ...state, actualClaimAmount: action.payload }
    default:
      return state
  }
}
