import Button from 'components/Button';
import ButtonSwap from 'components/Common/Buttons/ButtonSwap';
import ButtonSelectEmpty from 'components/Common/Buttons/ButtonSwapEmpty';
import { Input } from 'components/Input/Input';
import { SelectToken } from 'pages/Launchpad/components/LevelPairSelect/SelectToken';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { launchpadActionCreator } from 'store/reducers/launchpad/action-creator';
import { TokenInfo } from 'store/reducers/types';
import useWalletSelector from 'hooks/useWalletSelector';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import {
  LaunchpadJoinDetails,
  UserInfoByTokens,
} from 'store/reducers/launchpad/types';
import {getTokenInfoByAddress} from 'utils/getTokenInfo';
import {formatNumber} from 'utils/formatNumber';

type Props = {
  saleTokenInfo: TokenInfo;
  buyTokens: string[];
  tokenPrices: number[];
  buyDetails: LaunchpadJoinDetails;
  setBuyToken: any;
  handleBuyTokenInputIn: any;
  handleBuyTokenInputOut: any;
  buyTokensIdo: any;
  minAllocation: number;
  maxAllocation: number;
  total: number;
  totalIDOAmount: number;
  whitelistedToken: UserInfoByTokens;
	isWallet: boolean;
	active: boolean;
	setBuyMaxTokens: any;
  amountIn:string;
  amountOut:string;
};

const PoolBuy: FunctionComponent<Props> = ({
  saleTokenInfo,
  buyTokensIdo,
  buyTokens,
  buyDetails,
  setBuyToken,
  handleBuyTokenInputIn,
  handleBuyTokenInputOut,
  minAllocation,
  maxAllocation,
  total,
  totalIDOAmount,
  whitelistedToken,
	isWallet,
	active,
	setBuyMaxTokens,
  amountIn,
  amountOut
}) => {

  const [isTouched, setIsTouched] = useState(false);

  const [inputGreater, setIsGreater] = useState(false);
  const [inputLess, setIsLess] = useState(false);
	const	[inputBalanceGreater, setIsBalanceGreater] = useState(false)

  useEffect(() => {
    if (isTouched) {
			if(+Number(total - totalIDOAmount).toFixed(saleTokenInfo.decimal) < minAllocation && +Number(total - totalIDOAmount).toFixed(saleTokenInfo.decimal) === buyDetails.amountOut ){
				setIsLess(false)
			}else {
				if (buyDetails.amountOut < minAllocation || !buyDetails.amountOut) setIsLess(true);
				else setIsLess(false);
			}

			if(maxAllocation !== total){
				if(buyDetails.amountOut > maxAllocation) setIsGreater(true)
				else setIsGreater(false)
			}

			if (buyDetails.amountOut > +Number(total - totalIDOAmount).toFixed(saleTokenInfo.decimal)) setIsGreater(true);
			else setIsGreater(false);


			if(buyDetails.amountIn > buyDetails.tokenInBalance) setIsBalanceGreater(true)
			else setIsBalanceGreater(false)
    }
  }, [buyDetails.amountOut, buyDetails.amountIn, isTouched]);

  return (
    <div className="w-full px-5 py-8 mx-auto mb-12 text-center sm:p-8 sm:w-3/4 mmx:w-1/2 bg-cardBg rounded-4xl">
      <h1 className="mb-10 text-2xl font-medium text-center">Buy Token</h1>
      <div className="mb-8 text-left">
        <span>From</span>
        <div className="relative flex items-start justify-between mb-16 gap-4">
          <div className="relative w-9/12">
					<div className='w-full'>

            <Input
              onFocus={() => setIsTouched(true)}
              wrongInput={inputGreater || inputLess}
              className="w-full"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                buyDetails.selectedToken !== '' && handleBuyTokenInputIn(e)
              }
              type="number"
              id="amountOut"
              value={amountIn}
              placeholder={'Input amount'}
            />
						<div
							onClick={e => buyDetails.selectedToken && setBuyMaxTokens() }
							className="absolute top-0 right-0 flex items-center w-auto h-10 px-3 text-sm uppercase rounded-tr-lg cursor-pointer bg-yellow bg-opacity-15 text-yellow"
						>
							Max
						</div>
					</div>
						<div className='flex flex-col text-right'>
							<div className="text-sm text-red whitespace-nowrap ">
								{inputGreater
									? 'Value is greater than token available'
									: inputLess
									? 'Value is less than minimal allocation'
									: inputBalanceGreater 
									? 'Value is greater than your balance'
									: ''}
							</div>
							<span className='text-sm text-description'>
								Balance: {formatNumber(buyDetails.tokenInBalance)} {getTokenInfoByAddress(buyDetails.selectedToken)?.name}
							</span>
						</div>
          </div>
					<div>
						<SelectToken
							tokenSelectList={buyTokens}
							selectedToken={buyDetails.selectedToken}
							setToken={setBuyToken}
							disabled={!isWallet}
						/>
					</div>
          {/* <span className='absolute text-xs -bottom-4 left-4 text-description'>~$10</span> */}
        </div>
      </div>
      <div className="mb-8 text-left">
        <span>To</span>
        <div className="relative flex items-start justify-between gap-4">
          <div className="relative w-9/12">
						<Input
							wrongInput={inputGreater || inputLess}
							onFocus={() => setIsTouched(true)}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								buyDetails.selectedToken !== '' && handleBuyTokenInputOut(e)
							}
							id="amountOut"
							className="w-full"
							value={amountOut}
							placeholder={'Input amount'}
						/>
						<div className='text-right'>
							<span className='text-sm text-description' >Balance: {formatNumber(buyDetails.tokenOutBalance)} {saleTokenInfo.symbol}</span>
						</div>
          </div>
					<div>
						<SelectToken
							tokenSelectList={buyTokens}
							selectedToken={saleTokenInfo.address}
							selectedTokenIcon={saleTokenInfo.icon}
							selectedTokenName={saleTokenInfo.symbol}
							setToken={setBuyToken}
							disabled={true}
						/>
					</div>
        </div>
      </div>
      {isWallet ? (
        <Button
          onClick={buyTokensIdo}
          disabled={
            buyDetails.selectedToken === '' ||
            inputLess  ||
            inputGreater ||
						inputBalanceGreater ||
            !whitelistedToken.amount ||
						!active
          }
        >
          Buy
        </Button>
      ) : (
        <ConnectWalletButton />
      )}
    </div>
  );
};

export default PoolBuy;
