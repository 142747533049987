import { FunctionComponent, LegacyRef } from "react";
import { AnalyticsInfo } from "store/reducers/graphic/types";

type Props = {
    analyticsInfo: AnalyticsInfo,
    liquidityRef: any,
    volumeRef: any,
};

export const Charts: FunctionComponent<Props> = ({analyticsInfo, liquidityRef, volumeRef}) => {

    return(
        <div className="mt-4 lg:mt-16 mb-24 flex flex-col md:flex-row justify-between gap-x-0 gap-y-4 sm:gap-y-0 sm:gap-x-4 xl:gap-x-7">
            <div className="py-8 xl:py-12 px-4 xl:px-8 rounded-4xl h-3/5 w-full md:w-1/3 bg-cardBg text-description text-xl md:text-xs lg:text-base xl:text-2xl">
                <div className="flex items-center mb-5">
                    <span>EXON price:</span>
                    <span className='ml-2'>$ {analyticsInfo.trxPrice}</span>
                </div>
                <div className="flex items-center mb-5">
                    <span className='whitespace-nowrap'>Transactions <span className='text-xs mr-1'>(24h)</span>:</span>
                    <span className='ml-2'>{analyticsInfo.transactions}</span>
                </div>
                <div className="flex items-center mb-5">
                    <span>Pairs:</span>
                    <span className='ml-2'>{analyticsInfo.pairs}</span>
                </div>
                <div className="flex items-center">
                    <span>New pairs <span className='text-xs mr-1'>(24h)</span>:</span>
                    <span className='ml-2'>{analyticsInfo.newPairs}</span>
                </div>
            </div>
            <div style={{height: window.innerWidth < 768 ? '236px' : ''}} ref={liquidityRef} className="rounded-4xl w-full md:w-1/3 bg-cardBg p-6">
                <div className="flex items-center justify-between text-white mb-6">
                    <span className='text-xl md:text-xs lg:text-base xl:text-2xl font-medium' >Liquidity</span>
                    <span className='text-xl md:text-xs lg:text-base font-normal' >$1 782 304 761</span>
                </div>
            </div>
            <div  style={{height: window.innerWidth < 768 ? '236px' : ''}} ref={volumeRef} className="rounded-4xl w-full md:w-1/3 bg-cardBg p-6">
                <div className="flex items-center justify-between text-white mb-6">
                    <span className='text-xl md:text-xs lg:text-base xl:text-2xl font-medium' >Volume</span>
                    <span className='text-xl md:text-xs lg:text-base font-normal' >$1 782 304 761</span>
                </div>
            </div>
        </div>
    )
}