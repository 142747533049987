import React, { FunctionComponent, useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { Arrow, ArrowsIcon } from 'components/Icons';
import Tooltip from 'components/Tooltip';
import Radio from './components/Radio';
import Checkbox from './components/Checkbox';
import Button from 'components/Button';
import useWalletSelector from 'hooks/useWalletSelector';
import { useDispatch } from 'react-redux';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';
import { addLiquidityActionCreator } from 'store/reducers/liquidityAdd/action-creator';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';
import Config from 'config'
import { useHistory } from 'react-router';

const Calculator: FunctionComponent = () => {
  const { farmingState, farmingStakeInfo, farmingList, calculatorState } = useWalletSelector( state => state.farmingReducer )
  const { exonUsdPrice } = useWalletSelector( state => state.applicationReducer )
  const [stakedFor, setStakedFor] = useState('1w');
  const [isCompounding, setIsCompounding] = useState(false);
  const [exonReceiveAmount, setExonReceiveAmount] = useState(0)
  const [isMore, setIsMore] = useState(false);
  const [currentUsdInput, setCurrentUsdInput] = useState(true)
  const [prevHandle, setPrevHandle] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if(farmingState !== 'calculator') setIsMore(false)
    dispatch(farmingActionCreator.detectFarmingLpToken('calculator'))
  }, [farmingState])

  useEffect(() => {
    if(farmingState !== 'calculator') return
    prevHandle === 'usd' 
      ? handleUsdInput(calculatorState.usdInput)
      : prevHandle === 'lp'
        ? handleLpInput(calculatorState.lpInput)
        : handleOutput(calculatorState.tokenOutput)
  }, [stakedFor]) 

  const handleAddPool = () => {
      // dispatch(LiquidityActionCreator.setLiquidityState('add'));
      dispatch(LiquidityActionCreator.setLiquidityMainState('add'));
      dispatch(addLiquidityActionCreator.getTokensReserves());
      dispatch(
        ApplicationAcionCreator.setFirstToken({
          address: calculatorState.firstTokenAddress,
          icon: getTokenInfoByAddress(calculatorState.firstTokenAddress).icon,
          symbol: getTokenInfoByAddress(calculatorState.firstTokenAddress).name,
          name: getTokenInfoByAddress(calculatorState.firstTokenAddress).name,
        }),
      );
      dispatch(
        ApplicationAcionCreator.setSecondToken({
          address: calculatorState.secondTokenAddress,
          icon: getTokenInfoByAddress(calculatorState.secondTokenAddress).icon,
          symbol: getTokenInfoByAddress(calculatorState.secondTokenAddress).name,
          name: getTokenInfoByAddress(calculatorState.secondTokenAddress).name,
        }),
      );
      dispatch(farmingActionCreator.setFarmingState('initial'))
    history.push('/swap/liquidty')
  }

  const periodToSeconds = (period: string) => {
    switch (period) {
      case '1d':
        return 86400
      case '1w':
        return 604800
      case '1m':
        return 2592000
      case '1q':
        return 7776000
      case '1y': 
        return 31536000
      default: return 1
    }
  }

  const getInfoByLp = (type: string) => {
    return farmingList.filter( el => el.lpToken === calculatorState.lpToken )[0][type]
  }

  // useEffect(() => {
  //   if( farmingState !== 'calculator' ) return
  //   let seconds = periodToSeconds(stakedFor)
  //   let receiveAmount = seconds / Config().SECONDS_PER_RECEIVE
  //   let tokenReceive = receiveAmount * getInfoByLp('tokenPerBlock') * (getInfoByLp('lpStaked') / getInfoByLp('totalLiquidty'))
  //   setExonReceiveAmount(+tokenReceive.toFixed(6))
  // }, [stakedFor])

  const handleUsdInput = (usdInput: number) => {
    dispatch(farmingActionCreator.setCalculatorState({usdInput}))
    dispatch(farmingActionCreator.setLpInputByUsd(periodToSeconds(stakedFor)))
    setPrevHandle('usd')
  } 

  const handleLpInput = (lpInput: number) => {
    dispatch(farmingActionCreator.setCalculatorState({lpInput}))
    dispatch(farmingActionCreator.setUsdInputByLp(periodToSeconds(stakedFor)))
    setPrevHandle('lp')
  } 

  const handleOutput = (tokenOutput: number) => {
    dispatch(farmingActionCreator.setCalculatorState({tokenOutput}))
    dispatch(farmingActionCreator.setInputsByOutput(periodToSeconds(stakedFor)))
    setPrevHandle('exon')
  } 
  


  return (
    <>
      {farmingState === 'calculator' && (
        <Modal closeModal={() => {
          dispatch(farmingActionCreator.setCalculatorState({
            lpToken: '',
            secondTokenAddress: '',
            firstTokenAddress: '',
            usdInput: 0,
            lpInput: 0,
            tokenOutput: 0,
            lpAmount: 0,
          }))
          dispatch(farmingActionCreator.setFarmingState('initial'))
        }} >
          <div className="text-left sm:text-center text-2xl">
            Revenue calculator
          </div>
          <div className="mt-4">{getTokenInfoByAddress(calculatorState.firstTokenAddress).name}-{getTokenInfoByAddress(calculatorState.secondTokenAddress).name} LP staked</div>
          <div className="flex flex-wrap items-start mt-2">
            <div className="flex-1">
              <div className="">
                <div className="bg-input  flex justify-between border-b border-yellow rounded-t-lg pl-2">
                  <div className="mr-2 text-description w-10/12">
                    <div className="flex items-center justify-end py-2 ">
                      <input 
                        value={(currentUsdInput ? calculatorState.usdInput : calculatorState.lpInput) || '0'}
                        onChange={e => currentUsdInput ? handleUsdInput(+e.target.value) : handleLpInput(+e.target.value)}
                        autoFocus 
                        className='text-yellow bg-transparent text-right mr-2 outline-none w-full' 
                        type="text" />
                      <span className='whitespace-nowrap'>{currentUsdInput ? 'USD' : `${getTokenInfoByAddress(calculatorState.firstTokenAddress).name}-${getTokenInfoByAddress(calculatorState.secondTokenAddress).name} LP`}</span>
                    </div>
                    <div className="flex items-center justify-end py-2">
                      <span className='text-right mr-2'>{!currentUsdInput ? calculatorState.usdInput : calculatorState.lpInput}</span>
                      <span>{!currentUsdInput ? 'USD' : `${getTokenInfoByAddress(calculatorState.firstTokenAddress).name}-${getTokenInfoByAddress(calculatorState.secondTokenAddress).name} LP`}</span>
                    </div>
                  </div>
                  <div onClick={() => setCurrentUsdInput(!currentUsdInput)} className="flex items-center justify-center bg-darkGray cursor-pointer rounded-tr-lg text-yellow w-2/12 ">
                    <ArrowsIcon className="w-5 transform rotate-90" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap sm:flex-nowrap sm:justify-between mt-5">
            <div
              onClick={() => handleUsdInput(100)}
              className="px-2 sm:px-5 py-2 rounded-xl cursor-pointer text-yellow text-sm"
              style={{ backgroundColor: '#201F1B' }}
            >
              $100
            </div>
            <div
              onClick={() => handleUsdInput(1000)}
              className="px-3 sm:px-5 py-2 rounded-xl cursor-pointer text-yellow text-sm"
              style={{ backgroundColor: '#201F1B' }}
            >
              $1000
            </div>
            <div className="flex items-center">
              <div
                onClick={() => currentUsdInput ? handleUsdInput(+(calculatorState.lpAmount * getInfoByLp('usdPerLp')).toFixed(6)) : handleLpInput(calculatorState.lpAmount) }
                className={`px-3 sm:px-5 py-2 rounded-xl cursor-pointer uppercase text-yellow text-sm`}
                style={{ backgroundColor: '#201F1B' }}
              >
                My balance
              </div>
              <Tooltip id="balance" place="bottom">
                Tooltip
              </Tooltip>
            </div>
          </div>
          <div className="mt-4 text-xl text-title">Staked for</div>
          <div className="inline-flex w-full justify-around bg-darkGray p-1 rounded-xl mt-2">
            <Radio state={stakedFor} setState={setStakedFor}>
              1D
            </Radio>
            <Radio state={stakedFor} setState={setStakedFor}>
              1W
            </Radio>
            <Radio state={stakedFor} setState={setStakedFor}>
              1M
            </Radio>
            <Radio state={stakedFor} setState={setStakedFor}>
              1Q
            </Radio>
            <Radio state={stakedFor} setState={setStakedFor}>
              1Y
            </Radio>
          </div>
          <div className="mt-4 text-xl text-title">
            Revenue at current rates
          </div>
          <div className=" relative w-full mt-2">
            <span className='' style={{position: 'absolute', left: '0', top: '50%', transform: 'translate(60%, -55%)'}}>$</span>
            <input
              type="number"
              onChange={e => handleOutput(+e.target.value)}
              value={calculatorState.tokenOutput || '0'}
              className="w-full text-title leading-9 rounded-t-lg bg-input bg-opacity-50 pl-5 pr-4 py-0.5 border-b border-yellow focus:outline-none focus:bg-opacity-100"
            />
            <span className='text-description' style={{position: 'absolute', right: '0', top: '50%', transform: 'translate(-10%, -55%)'}}>~{(calculatorState.tokenOutput / exonUsdPrice).toFixed(6)} EXON</span>
          </div>
          <div className="flex justify-center mt-5">
            <button
              className="text-yellow uppercase text-xl flex items-center tracking-widest"
              onClick={() => setIsMore(!isMore)}
            >
              More
              <Arrow
                className={`ml-2 transform w-4 h-4 ${
                  isMore ? 'rotate-90' : '-rotate-90'
                }`}
              />
            </button>
          </div>
          {isMore && (
            <div className="mt-5">
              <div className="flex flex-col gap-2">
                <div className="flex w-full items-center justify-between">
                  <div className="text-sm text-title">APR (incL LP reward)</div>
                  <div className="text-sm">41%</div>
                </div>
                <div className="flex w-full items-center justify-between">
                  <div className="text-sm text-title">
                    Base APR (EXON yield only)
                  </div>
                  <div className="text-sm">37.70%</div>
                </div>
                <div className="flex w-full items-center justify-between">
                  <div className="text-sm text-title">
                    APY (1x daily compound)
                  </div>
                  <div className="text-sm">45.76%</div>
                </div>
              </div>
              <ul className="text-description flex flex-col text-sm gap-3 mt-4">
                <li className="relative pl-3">
                  <div className="w-1 h-1 bg-description rounded-full absolute left-0 top-2.5 "></div>
                  Calculated based on current rates.
                </li>
                <li className="relative pl-3">
                  <div className="w-1 h-1 bg-description rounded-full absolute left-0 top-2.5"></div>
                  LP rewards: 0.17% trading fees, distributed proportionally
                  among LP token holders.
                </li>
                <li className="relative pl-3">
                  <div className="w-1 h-1 bg-description rounded-full absolute left-0 top-2.5"></div>
                  All figures are estimates provided for your convenience only,
                  and by no means represent guaranteed returns.
                </li>
              </ul>
              <div className="flex justify-center mt-5">
                <Button onClick={() => handleAddPool()} wide>
                  Get {getTokenInfoByAddress(calculatorState.firstTokenAddress).name}-{getTokenInfoByAddress(calculatorState.secondTokenAddress).name} LP
                </Button>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default Calculator;
