import React, { useState } from 'react';
import Modal from 'components/Modal';
import { FunctionComponent } from 'react';
import useWalletSelector from 'hooks/useWalletSelector';
import { useDispatch } from 'react-redux';
import { swapActionCreator } from 'store/reducers/swap/action-creators';

const SwapSettings: FunctionComponent = () => {
  const { swapSettingModal } = useWalletSelector(state => state.swapReducer);
  const [slippage, setSlippage] = useState(0.1);
  const [deadline, setDeadline] = useState(30);
  const dispatch = useDispatch();
  const [slippageTip, setSlippageTip] = useState(false)
  const [deadlineTip, setDeadlineTip] = useState(false)

  const handlCloseModal = () => {
    dispatch(swapActionCreator.setSwapSettingModal(!swapSettingModal));
    dispatch(
      swapActionCreator.setSwapSetting({ slippage: slippage / 100, deadline }),
    );
  };

  const handlClickSplipage = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setSlippage(parseFloat(e.currentTarget.id.split('_')[1]));
  };

  return (
    <>
      {swapSettingModal && (
        <Modal closeModal={handlCloseModal}>
          <div className="text-title text-2xl sm:text-4xl">
            Transaction settings
          </div>
          <div className="mt-10 relative">
            <span className="text-title text-xl sm:text-2xl">
              Slippage tolerance
            </span>
            <span
              onMouseOver={() => setSlippageTip(true)}
              onMouseOut={() => setSlippageTip(false)}
              className="ml-2 inline-flex justify-center items-center w-5 h-5 rounded-full text-sm cursor-pointer"
              style={{ backgroundColor: '#5F5F5F', color: '#171612' }}
            >
              ?
            </span>
            {slippageTip && <div style={{backdropFilter: 'blur(20px)', transform: 'translate(0%, -175%)'}} className="absolute l-0 t-0 w-full sm:w-1/2 text-xs bg-transYellow py-2 px-4 rounded-2xl">Acceptable slippage percent of swap</div>}
          </div>
          <div className="flex flex-col sm:flex-row gap-5 mt-5">
            <div className="flex gap-5">
              <div
                className="px-3 sm:px-5 py-3 rounded-xl cursor-pointer text-yellow text-xl"
                style={{ backgroundColor: '#201F1B' }}
                id="slippage_0.1"
                onClick={e => handlClickSplipage(e)}
              >
                0.1%
              </div>
              <div
                className="px-3 sm:px-5 py-3 rounded-xl cursor-pointer text-yellow text-xl"
                style={{ backgroundColor: '#201F1B' }}
                id="slippage_0.5"
                onClick={e => handlClickSplipage(e)}
              >
                0.5%
              </div>
              <div
                className="px-3 sm:px-5 py-3 rounded-xl cursor-pointer text-yellow text-xl"
                style={{ backgroundColor: '#201F1B' }}
                id="slippage_1.0"
                onClick={e => handlClickSplipage(e)}
              >
                1%
              </div>
            </div>
            <div className="flex items-center gap-2 text-description">
              <input
                type="number"
                value={slippage}
                onChange={e => setSlippage(parseFloat(e.currentTarget.value))}
                className="w-full leading-9 rounded-t-lg bg-input bg-opacity-50  px-4 py-0.5 border-b border-yellow focus:outline-none focus:bg-opacity-100"
              />
              %
            </div>
          </div>
          <div className="mt-10 relative">
            <span className="text-title text-xl sm:text-2xl">
              Transaction deadline
            </span>
            <span
              onMouseOver={() => setDeadlineTip(true)}
              onMouseOut={() => setDeadlineTip(false)}
              className="ml-2 inline-flex justify-center items-center w-5 h-5 rounded-full text-sm cursor-pointer"
              style={{ backgroundColor: '#5F5F5F', color: '#171612' }}
            >
              ?
            </span>
            {deadlineTip && <div style={{backdropFilter: 'blur(20px)', transform: 'translate(0%, -200%)'}} className="absolute l-0 t-0 w-full sm:w-1/2 text-xs bg-transYellow py-2 px-4 rounded-2xl">Transaction waiting time</div>}
          </div>
          <div className="flex items-center gap-2 mt-5 text-description">
            <input
              type="number"
              value={deadline}
              onChange={e => setDeadline(parseInt(e.currentTarget.value))}
              className="w-2/3 leading-9 rounded-t-lg bg-input bg-opacity-50  px-4 py-0.5 border-b border-yellow focus:outline-none focus:bg-opacity-100"
            />
            Minutes
          </div>
          {/* <div className="mt-10">
            <span className="text-title text-xl sm:text-2xl">
              Transaction deadline
            </span>
            <div className="flex justify-between mt-5">
              <div>
                <span className="text-xl text-title">Expert mode</span>
                <span
                  className="ml-2 inline-flex justify-center items-center w-5 h-5 rounded-full text-sm"
                  style={{ backgroundColor: '#5F5F5F', color: '#171612' }}
                >
                  ?
                </span>
              </div>
              <div className="inline-flex items-center justify-center">
                <label
                  htmlFor="toggle"
                  className="flex items-center cursor-pointer"
                >
                  <div className="relative">
                    <input type="checkbox" id="toggle" className="sr-only" />
                    <div className="block bg-darkGray w-14 h-8 rounded-full"></div>
                    <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                  </div>
                </label>
              </div>
            </div>
            <div className="flex justify-between mt-2">
              <div>
                <span className="text-xl text-title">No difficult routes</span>
                <span
                  className="ml-2 inline-flex justify-center items-center w-5 h-5 rounded-full text-sm"
                  style={{ backgroundColor: '#5F5F5F', color: '#171612' }}
                >
                  ?
                </span>
              </div>
              <div className="inline-flex items-center justify-center">
                <label
                  htmlFor="toggle"
                  className="flex items-center cursor-pointer"
                >
                  <div className="relative">
                    <input type="checkbox" id="toggle" className="sr-only" />
                    <div className="block bg-darkGray w-14 h-8 rounded-full"></div>
                    <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                  </div>
                </label>
              </div>
            </div>
          </div> */}
        </Modal>
      )}
    </>
  );
};

export default SwapSettings;
