import React, { FC, useEffect, useState } from 'react';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import { coinIcons } from '../../CoinIcons';
import logoUndefined from '../../../components/CoinIcons/assets/logoundefined.png'
import useWalletSelector from 'hooks/useWalletSelector';

export type props = {
  icon: string;
  name: string;
  address?: string;
  search?: boolean;
  setSearchText?: any;
  imported?: boolean;
  manage?: boolean;
  dispatch?: any;
  setImportToken?: any;
  regExp?: any;
  decimals?: number;
};

const ButtonSelectCoinList: FC<props> = ({ icon, name, search, decimals, address, setSearchText, imported, dispatch, manage, setImportToken, regExp }) => {

  const [copied, setCopied] = useState(false)

  const { liquidityPairs } = useWalletSelector( state => state.liquidityReducer )

  const isTokenUsed = !!liquidityPairs.filter(el => el.token0Info.address === address || el.token1Info.address === address).length
  
  const copyAddress = (address: string) => {
    navigator.clipboard.writeText(address).then(e => console.log(e))
    setCopied(true)
}

  useEffect(() => {
    if(copied) {
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  }, [copied])

  const nameRegExp = name.match(regExp)

  const removeImportedToken = () => {
    dispatch(ApplicationAcionCreator.setFirstToken({}));
    dispatch(ApplicationAcionCreator.setSecondToken({}));
    let newImports = JSON.parse(localStorage.getItem('tokenList') || '[]')
    newImports = newImports.filter((el: { address: string | undefined; }) => el.address !== address)
    localStorage.setItem('tokenList', JSON.stringify(newImports))
  }

  const highlightedText = () => {
    let match = 0
    let newName = name
    // console.log(nameRegExp);
    
    nameRegExp?.map(el => {
      newName = newName.replace(el, '*')
    })
    let words = newName.split('').map(el => {
      if(el === '*' && nameRegExp) {
        return <span style={{color: 'black', background: 'yellow'}}>{nameRegExp[match]}</span>
      }
      else return <span>{el}</span>
    })

    return words
  }

  return (
    <button
      className={`inline-flex
                rounded-xl border border-transparent shadow-sm px-4 
                py-2 bg-button text-title font-medium 
                ${!search && !manage && 'hover:bg-black cursor-pointer'} cursor-default focus:outline-none 
                sm:w-full sm:text-sm justify-between w-full`}
    >
      <span className="flex justify-between items-center w-full">
        <div className="flex items-center">
          <img src={(search || imported || manage) ? (icon || logoUndefined) : coinIcons[icon]} alt="coin" className="w-6" />
          <div className="px-4">{highlightedText()}</div>
        </div>
        {search && <div onClick={() => {
          setImportToken({name, icon, address, decimals})
        }} className="item-end text-black bg-yellow cursor-pointer rounded-lg px-4 py-1">Import</div>}
        {manage && <div className='flex item-end gap-2'>
          <div onClick={() => copyAddress(address || '')} className={`px-4 py-1 w-20 rounded-lg ease-in-out duration-1000 cursor-pointer text-black ${copied ? 'bg-green' : 'bg-yellow '}`}>{copied ? 'Copied' : 'Copy'}</div>
          <div onClick={() => !isTokenUsed && removeImportedToken()} className={` ${!isTokenUsed && 'bg-red cursor-pointer'} rounded-lg px-4 py-1`}>{isTokenUsed ? 'In use' : 'Delete'}</div>
        </div>}
      </span>
    </button>
  );
};

export default ButtonSelectCoinList;
