import React, { FunctionComponent } from 'react';

type Props = {
  target?: string;
  url?: string;
  className?: string;
};

const IconWrapper: FunctionComponent<Props> = ({
  target,
  url,
  className,
  children,
}) => {
  return (
    <a
      target={target}
      href={url}
      className={`flex justify-center items-center  bg-lightBlack rounded-full hover:bg-yellow hover:fill-lightBlack  cursor-pointer ${className}`}
    >
      {children}
    </a>
  );
};

export default IconWrapper;
