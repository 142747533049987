import { FunctionComponent, useState } from 'react';
import TronWeb from 'tronweb'
import Button from 'components/Button';
import useWalletSelector from 'hooks/useWalletSelector';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import copyTextLib from 'copy-to-clipboard';
import { ReactComponent as Copy } from '../../../../../../assets/copy.svg';
import {
  LaunchpadInfo,
  LaunchPoolPoolInfo,
  UserInfoByTokens,
} from 'store/reducers/launchpad/types';
import MarkdownIt from 'markdown-it';
import {
  Facebook,
  Instagram,
  Telegram,
  Twitter,
  Youtube,
  Github,
} from 'components/Icons';
import IconWrapper from 'components/Icons/IconWrapper';
import s from 'components/Footer/Footer.module.css';
import { TokenInfo } from 'store/reducers/types';
import { getTokensByAddress } from 'api/api';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';
import 'styles/tooltip.css';
import Config from 'config'
import { formatNumber } from "utils/formatNumber";

interface IPoolDetails {
  activeIDO: LaunchpadInfo;
  handleClaim: any;
  claimState: boolean;
}

const PoolDetails: FunctionComponent<IPoolDetails> = ({
  activeIDO,
  handleClaim,
  claimState
}) => {
  const [currTab, setCurrTab] = useState<string>('details');

  const { isWallet } = useWalletSelector(state => state.WalletReducer);

  const checkForWithdraw = () => {
    return !(!activeIDO.active && !!activeIDO.userInfo.whitelistedToken.amount);
  };
  return (
    <>
      <div
        style={{ marginBottom: '-1px' }}
        className="px-5 rounded-t-4xl sm:px-12 pt-7 sm:pt-10 pb-7 md:pb-12 bg-cardBg "
      >
        <div className="flex justify-between mb-2 sm:justify-around align-center">
          <div
            onClick={() => setCurrTab('details')}
            className="relative mx-0 text-center cursor-pointer lg:text-left md:mx-8"
          >
            <span
              className={`font-medium ${currTab === 'details' ? 'text-yellow' : 'text-title'
                } text-lg md:text-3xl `}
            >
              Pool details
            </span>
            {currTab === 'details' && (
              <hr
                className="relative top-2"
                style={{
                  borderRadius: '10px 10px 0 0',
                  background: '#FDD807',
                  border: 'none',
                  width: '100%',
                  height: '2px',
                }}
              />
            )}
          </div>
          <div
            onClick={() => setCurrTab('about')}
            className="relative mx-0 text-center cursor-pointer lg:text-left md:mx-8"
          >
            <span
              className={`font-medium ${currTab === 'about' ? 'text-yellow' : 'text-title'
                } text-lg md:text-3xl `}
            >
              About the project
            </span>
            {currTab === 'about' && (
              <hr
                className="relative top-2"
                style={{
                  borderRadius: '10px 10px 0 0',
                  background: '#FDD807',
                  border: 'none',
                  width: '100%',
                  height: '2px',
                }}
              />
            )}
          </div>
          <div
            onClick={() => setCurrTab('allocation')}
            className="relative mx-0 text-center cursor-pointer lg:text-left md:mx-8"
          >
            <span
              className={`font-medium ${currTab === 'allocation' ? 'text-yellow' : 'text-title'
                } text-lg md:text-3xl `}
            >
              Your allocations
            </span>
            {currTab === 'allocation' && (
              <hr
                className="relative top-2"
                style={{
                  borderRadius: '10px 10px 0 0',
                  background: '#FDD807',
                  border: 'none',
                  width: '100%',
                  height: '2px',
                }}
              />
            )}
          </div>
        </div>
        <hr
          style={{
            background: '#7A7A7A',
            border: 'none',
            width: '100%',
            height: '0.3px',
          }}
        />
      </div>

      <div className="rounded-b-4xl bg-cardBg ">
        {currTab === 'details' ? (
          <Details
            start={activeIDO.start}
            minAllocation={activeIDO.minAllocation}
            maxAllocation={activeIDO.maxAllocation}
            WhitelistedAmount={activeIDO.whiteListAmounts}
            WhitelistedToken={activeIDO.whiteListTokens}
            saleTokenInfo={activeIDO.saleTokenInfo}
            total={activeIDO.total}
          />
        ) : currTab === 'about' ? (
          <About poolDetailsTab={activeIDO.poolDescriptionInfo} />
        ) : (
          <Allocation
            total={activeIDO.userInfo.saleTokenAmount}
            boughtTokens={activeIDO.userInfo.boughtTokens}
            saleTokenInfo={activeIDO.saleTokenInfo}
            referalAmount={activeIDO.userInfo.referalAmount}
            whitelistHistory={activeIDO.userInfo?.whitelistedTokenHistory}
            buyTokens={activeIDO.buyTokens}
            tokenPrices={activeIDO.tokenPrices}
          />
        )}

        <div className="pb-8 text-center">
          {isWallet ? (
            <Button
              className="w-11/12 sm:w-min whitespace-nowrap"
              onClick={handleClaim}
              disabled={checkForWithdraw() || !claimState}
            /* checkForWithdraw() || !claimState */
            >
              Claim
            </Button>
          ) : (
            <ConnectWalletButton />
          )}
        </div>
      </div>
    </>
  );
};

type DetailProps = {
  start: number;
  minAllocation: number;
  maxAllocation: number;
  WhitelistedAmount: number[];
  WhitelistedToken: string[];
  saleTokenInfo: TokenInfo;
  total: number;
};

const Details: FunctionComponent<DetailProps> = ({
  start,
  minAllocation,
  maxAllocation,
  WhitelistedAmount,
  WhitelistedToken,
  saleTokenInfo,
  total
}) => {

  const [copy, setCopy] = useState(false);

  const copyText = (textToCopy: string) => {
    copyTextLib(textToCopy);
  };
  let address = saleTokenInfo.address;
  const copyToClipBoard = () => {
    const textToCopy = address || '';
    copyText(textToCopy);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };

  return (
    <div className="rounded-b-4xl bg-cardBg ">
      <h1 className="px-4 pb-5 text-2xl font-medium text-title md:px-12 md:pb-7">
        IDO Details
      </h1>
      <div className="w-full px-4 bg-lightBlackO py-7 md:pt-7 md:pb-10 md:px-12">
        <h1 className="text-2xl font-medium text-title">IDO information</h1>
        <div className="text-xl font-medium pt-7">
          <div className="flex flex-col mb-5 md:flex-row md:justify-between item-center md:mb-7">
            <span className="text-description">Min. allocation</span>
            <span className="text-title">
              {minAllocation || 'No minimum'}{' '}
              {minAllocation && saleTokenInfo.symbol}
            </span>
          </div>

          <div className="flex flex-col mb-5 md:flex-row md:justify-between item-center md:mb-7">
            <span className="text-description">Max. allocation</span>
            <span className="text-title">
              {maxAllocation !== total ? maxAllocation : 'No maximum'}{' '}
              {maxAllocation !== total ? saleTokenInfo.symbol : ''}
            </span>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between item-center">
            <span className="text-description">Whitelist access</span>
            <div className="flex flex-col">
              {WhitelistedAmount.map((value, key) => {
                return <span className="text-left text-title md:text-right">
                  {`${value} ${getTokenInfoByAddress(WhitelistedToken[key]).name}`}
                </span>
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full px-4 pt-7 md:pt-7 md:px-12">
        <h1 className="text-2xl font-medium text-title">Token information</h1>
        <div className="text-xl font-medium pt-7">
          <div className="flex flex-col md:flex-row md:justify-between item-center mb-7">
            <span className="text-description">Name</span>
            <span className="text-title">{saleTokenInfo.name}</span>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between item-center mb-7">
            <span className="text-description">Symbol</span>
            <span className="text-title">{saleTokenInfo.symbol}</span>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between item-center mb-7">
            <span className="text-description">Decimals</span>
            <span className="text-title">{saleTokenInfo.decimal}</span>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between item-center mb-7">
            <span className="text-description">Address</span>

            <div className="flex">
              <span
                className="mr-2 text-right cursor-pointer text-yellow sm:text-xl xl:text-2xl xl:mr-5 sm:w-30 md:w-52 lg:w-auto"
              ><a
                target="_blank"
                rel="noreferrer"
                href={`${Config().TRONSCAN}/#/token20/${saleTokenInfo.address}`}>

                  {saleTokenInfo.address!.substring(0, 6) +
                    '...' +
                    saleTokenInfo.address!.substring(28, 34)}
                </a>
              </span>
              <div className="relative"
                onClick={(e) => {
                  e.preventDefault()
                  copyToClipBoard()
                }}
              >
                <Copy className="cursor-pointer" onClick={() => copyToClipBoard()} />
                <div id="tooltip" className={copy ? `tooltip active` : `tooltip`}>
                  Copied
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between item-center mb-7">
            <span className="text-description">Total supply</span>
            <span className="text-title">{formatNumber(TronWeb.fromSun(saleTokenInfo.totalSupply))}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

type SocialProps = {
  name: string;
  link: string;
};

const SocialIcon: FunctionComponent<SocialProps> = ({ name, link }) => {
  const defineIcon = () => {
    switch (name) {
      case 'github':
        return <Github className="w-5" />;
      case 'telegram':
        return <Telegram className="w-5" />;
      case 'instagram':
        return <Instagram className="w-5" />;
      case 'facebook':
        return <Facebook className="w-5" />;
      case 'youtube':
        return <Youtube className="w-5" />;
      case 'twitter':
        return <Twitter className="w-5" />;
      default:
        return;
    }
  };

  return (
    <IconWrapper
      target="_blank"
      url={link}
      className={`${s.footer__icon} w-10 h-10`}
    >
      {defineIcon()}
    </IconWrapper>
  );
};

interface IAbout {
  poolDetailsTab: LaunchPoolPoolInfo | undefined;
}

const About: FunctionComponent<IAbout> = ({ poolDetailsTab }) => {
  const md = new MarkdownIt();
  const descriptionHtml = md.render(poolDetailsTab?.poolDescription!);

  const setSocialMedia = (socialMedia: any) => {
    if (socialMedia.length > 3) {
      return (
        <div className="flex flex-col w-full sm:flex-row justify-left gap-4 sm:mb-2">
          <div className="flex items-center justify-left sm:justify-center gap-4">
            {socialMedia.map((el: { name: string; link: string }) => (
              <SocialIcon name={el.name} link={el.link} />
            ))}
          </div>
        </div>
      );
    } else
      return (
        <div className="flex flex-col w-full sm:flex-row justify-left gap-4 sm:mb-2">
          <div className="flex items-center justify-left sm:justify-center gap-4">
            {socialMedia.map(
              (el: { name: string; link: string }, index: number) =>
                index < 3 ? <SocialIcon name={el.name} link={el.link} /> : null,
            )}
          </div>
          <div className="flex items-center justify-left sm:justify-center gap-4">
            {socialMedia.map(
              (el: { name: string; link: string }, index: number) =>
                index >= 3 ? (
                  <SocialIcon name={el.name} link={el.link} />
                ) : null,
            )}
          </div>
        </div>
      );
  };
  return (
    <div className="px-4 rounded-b-4xl bg-cardBg sm:px-12">
      <h1 className="mb-5 text-2xl font-medium text-title sm:text-4xl">
        {poolDetailsTab?.poolName}
      </h1>
      <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
      <div className="flex flex-col justify-between gap-2 mt-7 sm:mt-14 sm:justify-start mb-7 sm:mb-10">
        {!!poolDetailsTab?.websiteLink && (
          <div className="">
            <div className="mb-7">
              <div className="mb-2 text-base font-normal sm:text-xl text-title">
                Website
              </div>
              <a
                target="_blank"
                href={poolDetailsTab?.websiteLink}
                className="text-2xl font-semibold leading-4 text-yellow"
              >
                {poolDetailsTab?.websiteLink}
              </a>
            </div>
          </div>
        )}

        {!!poolDetailsTab?.socialMedia.length && (
          <div>
            <div className="mb-2 text-base font-normal sm:text-xl text-title">
              Social media
            </div>
            {setSocialMedia(poolDetailsTab?.socialMedia)}
          </div>
        )}
      </div>
    </div>
  );
};

type AllocationProps = {
  total: number;
  boughtTokens: UserInfoByTokens[];
  referalAmount: number;
  saleTokenInfo: TokenInfo;
  whitelistHistory?: UserInfoByTokens;
  buyTokens: string[];
  tokenPrices: number[];
};

const Allocation: FunctionComponent<AllocationProps> = ({
  total,
  boughtTokens,
  referalAmount,
  whitelistHistory,
  saleTokenInfo,
  buyTokens,
  tokenPrices,
}) => {
  return (
    <div className="w-full px-4 pb-10 bg-cardBg rounded-b-4xl sm:px-10">
      {whitelistHistory &&
        <div className="flex items-center justify-between">
          <h1 className="text-xl font-medium sm:text-2xl text-title">
            Whitelist access
          </h1>
          <h1 className="text-xl text-yellow">
            {whitelistHistory?.amount} {getTokenInfoByAddress(whitelistHistory.token)?.name}
          </h1>
        </div>
      }
      <div className="flex items-center justify-between pt-7">
        <h1 className="text-xl font-medium sm:text-2xl text-title">
          Your allocations
        </h1>
        <h1 className="text-xl text-yellow">
          {total} {saleTokenInfo.symbol}
        </h1>
      </div>
      {boughtTokens.map(el => {
        return (
          <AllocationItem
            tokenAddressIn={el.token}
            tokenNameIn={getTokenInfoByAddress(el.token).name}
            tokenNameOut={saleTokenInfo.symbol || ''}
            amount={el.amount}
            referalAmount={referalAmount}
            buyTokens={buyTokens}
            tokenPrices={tokenPrices}
          />
        );
      })}
      <div className="flex flex-row justify-between mt-5 item-center sm:mt-7">
        <span className="mb-1 text-xl sm:text-2xl text-description">Referral bonus</span>
        <span className="text-xl text-title">
          {referalAmount} {saleTokenInfo?.symbol}
        </span>
      </div>
    </div>
  );
};

type AllocationItemProps = {
  tokenAddressIn: string;
  tokenNameIn: string;
  tokenNameOut: string;
  amount: number;
  referalAmount: number;
  buyTokens: string[];
  tokenPrices: number[];
};

const AllocationItem: FunctionComponent<AllocationItemProps> = ({
  tokenAddressIn,
  tokenNameIn,
  tokenNameOut,
  amount,
  referalAmount,
  buyTokens,
  tokenPrices,
}) => {
  const defineBoughtTokensAmount = () => {
    let boughtToken = buyTokens.map((el, index) => {
      return el === tokenAddressIn ? tokenPrices[index] * amount : null;
    });
    boughtToken.filter(el => !!el);
    return boughtToken || 0;
  };

  return (
    <div className="text-xl font-medium pt-7">
      <div className="flex flex-col justify-between mb-5 sm:flex-row item-center sm:mb-7">
        <span className="mb-1 text-description">Action</span>
        <span className="text-title">
          {tokenNameIn} -&gt; {tokenNameOut}{' '}
        </span>
      </div>

      <div className="flex flex-col justify-between mb-5 sm:flex-row item-center sm:mb-7">
        <span className="mb-1 text-description">Contribution</span>
        <span className="text-title">
          {amount} {tokenNameIn}
        </span>
      </div>

      {/*<div className="flex flex-col justify-between mb-5 sm:flex-row item-center sm:mb-7">*/}
      {/*<span className='mb-1 text-description'>Allocation date</span>*/}
      {/*<span className='text-title'>08.10.21</span>*/}
      {/*</div>*/}

      <div className="flex flex-col justify-between mb-5 sm:flex-row item-center sm:mb-7">
        <span className="mb-1 text-description">Total tokens</span>
        <span className="text-title">
          {defineBoughtTokensAmount()} {tokenNameOut}
        </span>
      </div>

    </div>
  );
};

export default PoolDetails;
