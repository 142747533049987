import { farmingCurrentState, farmingAction, farmingEnum } from './types';

export const initialState: farmingCurrentState = {
  stakedOnly: false,
  option: 'ALL',
  search: '',
  farmingState: 'initial',
  currentBlock: 0,
  farmingStakeInfo: {
    firstTokenAddress: '',
    secondTokenAddress: '',
    lpToken: '',
    lpTokensAmount: 0,
    stakedLpTokens: 0,
    stakeInput: '0',
    unstakeInput: '0',
    availableUnstake: 0,
    claimAvailable: 0,
    withdrawAmount: 0,
  },
  calculatorState: {
    lpToken: '',
    secondTokenAddress: '',
    firstTokenAddress: '',
    usdInput: 0,
    lpInput: 0,
    tokenOutput: 0,
    lpAmount: 0,
  },
  farmingList: []
};

export default function farmingReducer(
  state = initialState,
  action: farmingAction,
) {
  switch (action.type) {
    case farmingEnum.SET_FARMING_LIST:
      return { ...state, farmingList: action.payload };
    case farmingEnum.SET_FARMING_STATE:
      return { ...state, farmingState: action.payload };
    case farmingEnum.SET_STAKE_INPUT:
      return { 
        ...state, 
        farmingStakeInfo: {
          ...state.farmingStakeInfo,
          stakeInput: action.payload
        }
      }
    case farmingEnum.SET_FARMING_TOKENS:
      return { 
        ...state,
        farmingStakeInfo: {
          ...state.farmingStakeInfo,
          firstTokenAddress: action.payload.firstToken,
          secondTokenAddress: action.payload.secondToken,
        }
      }
    case farmingEnum.SET_FARMING_LP_TOKENS:
      return { ...state, 
              farmingStakeInfo: {
                ...state.farmingStakeInfo,
                lpTokensAmount: action.payload
              }  };
    case farmingEnum.SHOW_STAKED_ONLY:
      return {
        ...state,
        stakedOnly: !state.stakedOnly
      }
    case farmingEnum.RESET_FARMING_STAKE_INFO:
      return {
        ...state,
        farmingStakeInfo: {
          firstTokenAddress: '',
          secondTokenAddress: '',
          lpTokensAmount: 0,
          stakedLpTokens: 0,
          stakeInput: '0',
          unstakeInput: '0',
          availableUnstake: 0,
          claimAvailable: 0,
          withdrawAmount: 0,
        }
      }
    case farmingEnum.SET_SORT_OPTION:
      return { ...state, option: action.payload }
    case farmingEnum.SET_STAKED_TOKENS:
      return {
        ...state,
        farmingStakeInfo: {
          ...state.farmingStakeInfo,
          stakedLpTokens: action.payload
        }
      }
    case farmingEnum.SET_UNSTAKE_TOKENS:
      return {
        ...state,
        farmingStakeInfo: {
          ...state.farmingStakeInfo,
          unstakeInput: action.payload
        }
      }
    case farmingEnum.SET_LP_TOKEN_ADDRESS:
      return {
        ...state, 
        farmingStakeInfo: {...state.farmingStakeInfo, lpToken: action.payload}
      }
    case farmingEnum.SET_CALCULATOR_STATE:
      let newCalculatorState = Object.assign(state.calculatorState, action.payload)
      return{
        ...state,
        calculatorState: newCalculatorState
      }
    case farmingEnum.SET_WITHDRAW_AMOUNT:
      return{
        ...state,
        farmingStakeInfo: {
          ...state.farmingStakeInfo, withdrawAmount: action.payload
        }
      }
    case farmingEnum.SET_CURRENT_BLOCK:
      return {
        ...state,
        currentBlock: action.payload
      }
    default:
      return state;
  }
}
