import React from 'react';
import { useDispatch } from 'react-redux';
import useWalletSelector from 'hooks/useWalletSelector';
import { accountActionCreator } from 'store/reducers/account/action-creators';
import { WalletConnectActionCreator } from 'store/reducers/wallet/action-creators';

export default function TransactionError() {
  const dispatch = useDispatch();
  const { transactionError } = useWalletSelector(state => state.AccountReducer);

  const handlCancelCreateAccount = () => {
    console.log('Cancel');
    dispatch(accountActionCreator.setTransactionErrorPopup(false));
    dispatch(WalletConnectActionCreator.setWallet(false));
  };

  return (
    <>
      {transactionError && (
        <div
          style={{zIndex: 1000}}
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-cardBg rounded-4xl text-left overflow-hidden shadow-xl transform transition-all sm:my-7 sm:align-middle sm:max-w-sm sm:w-full">
              <div className="bg-cardBg px-4 pt-5 pb-4 sm:p-8 sm:pb-4">
                <div className="sm:flex sm:items-start flex-col">
                  <div className="mt-3 text-center sm:mt-0  sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-title"
                      id="modal-title"
                    >
                      Transaction is not "SUCCESS"
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-description">
                        Please check you balance
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center mb-4">
                <button
                  type="button"
                  className="w-full inline-flex justify-center 
                rounded-md border border-transparent shadow-sm px-4 
                py-2 bg-yellow text-base font-medium text-black
                focus:outline-none 
                focus:ring-2 focus:ring-offset-2 
                focus:ring-black-500 sm:ml-3 
                sm:w-auto sm:text-sm"
                  onClick={handlCancelCreateAccount}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
