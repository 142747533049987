import { TokenInfo } from '../types';

export type notificationType = {
  endProcess: boolean;
  isShown: boolean;
  status?: string;
  process?: string;
  description?: string | null;
  currentTransaction?: string | null;
};

export interface ApplicationState {
  firstToken: TokenInfo;
  secondToken: TokenInfo;
  openSelect: boolean;
  selected?: 1 | 2 | 0;
  transactionState: 'start' | 'inProcess' | 'finished' | 'notActive';
  trxUsdPrice: number;
  exonUsdPrice: number;
  notification: notificationType;
  liquidityAmount: number;
  volumeAmount: number;
  isTransaction: boolean;
  transactionSteps: TransactionSteps[] | null;
  isTronwebPresent: boolean;
  isTronlinkLogged: boolean;
  searchTokenList: SearchTokenList[]
}


export type TransactionSteps = {
  process: string;
  fetching: boolean;
  done: boolean;
}

export type SearchTokenList = {
  address: string,
  icon: string,
  name: string,
  decimals: number
}

export enum ApplicationStateEnum {
  SET_FIRST_TOKEN_APP = 'SET_FIRST_TOKEN_APP',
  SET_SECOND_TOKEN_APP = 'SET_SECOND_TOKEN_APP',
  SET_OPEN_SELECT_TOKEN = 'SET_OPEN_SELECT_TOKEN',
  SET_SELECTED_NUMBER = 'SET_SELECTED_NUMBER',
  SET_TRANSACTION_STATE = 'APPLICATION_SET_TRANSACTION_STATE',
  SET_TRX_USD_PRICE = 'SET_TRX_USD_PRICE',
  SET_EXON_USD_PRICE = 'SET_EXON_USD_PRICE',
  SET_NOTIFICATION_STATUS = 'SET_NOTIFICATION_STATUS',
  SET_FOOTER_LIQUIDITY = 'SET_FOOTER_LIQUIDITY',
  SET_FOOTER_VOLUME = 'SET_FOOTER_VOLUME',
  SET_TRANSACTION_STATUS = 'SET_TRANSACTION_STATUS',
  SET_SWAPPED_TOKENS = 'SET_SWAPPED_TOKENS',
  SET_TRANSACTION_STEPS = 'SET_TRANSACTION_STEPS',
  SET_TRANSACTION_STEP_STATUS = 'SET_TRANSACTION_STEP_STATUS',
  SET_IS_TRONWEB_PRESENT = 'SET_IS_TRONWEB_PRESENT',
  SET_IS_TRONLINK_LOGGED = 'SET_IS_TRONLINK_LOGGED',
  SET_SEARCH_TOKEN_LIST = 'SET_SEARCH_TOKEN_LIST'
}

export interface setTrxUsdPrice {
  type: ApplicationStateEnum.SET_TRX_USD_PRICE;
  payload: number;
}

export interface setExonUsdPrice {
  type: ApplicationStateEnum.SET_EXON_USD_PRICE;
  payload: number;
}

export interface setTransactionState {
  type: ApplicationStateEnum.SET_TRANSACTION_STATE;
  payload: 'start' | 'inProcess' | 'finished' | 'notActive';
}

export interface setSelectedNumber {
  type: ApplicationStateEnum.SET_SELECTED_NUMBER;
  payload: 1 | 2 | 0;
}

export interface setFirstTokenAction {
  type: ApplicationStateEnum.SET_FIRST_TOKEN_APP;
  payload: TokenInfo;
}

export interface setSecondTokenAction {
  type: ApplicationStateEnum.SET_SECOND_TOKEN_APP;
  payload: TokenInfo;
}

export interface setOpenSelectToken {
  type: ApplicationStateEnum.SET_OPEN_SELECT_TOKEN;
  payload: boolean;
}

export interface setNotificationStatus {
  type: ApplicationStateEnum.SET_NOTIFICATION_STATUS;
  payload: notificationType;
}

export interface setFooterLiquidity {
  type: ApplicationStateEnum.SET_FOOTER_LIQUIDITY;
  payload: number
}

export interface setFooterVolume {
  type: ApplicationStateEnum.SET_FOOTER_VOLUME;
  payload: number
}

export interface setTransactionStatus {
  type: ApplicationStateEnum.SET_TRANSACTION_STATUS;
  payload: boolean;
}
export interface setSwappedTokens {
  type: ApplicationStateEnum.SET_SWAPPED_TOKENS;
  payload: {
    firstTokenInfo: TokenInfo;
    secondTokenInfo: TokenInfo;
  };
}

export interface setTransactionStep {
  type: ApplicationStateEnum.SET_TRANSACTION_STEPS;
  payload: string[] | undefined;
}

export interface setTransactionStepStatus {
  type: ApplicationStateEnum.SET_TRANSACTION_STEP_STATUS;
  payload: {process: string, fetching: boolean, done: boolean}
}

export interface setIsTronwebPresent {
  type: ApplicationStateEnum.SET_IS_TRONWEB_PRESENT;
  payload: boolean
}

export interface setIsTronlinkLogged {
  type: ApplicationStateEnum.SET_IS_TRONLINK_LOGGED;
  payload: boolean
}

export interface setSearchTokenList {
  type: ApplicationStateEnum.SET_SEARCH_TOKEN_LIST;
  payload: SearchTokenList[]
}

export type ApplicationCurrentState = ApplicationState;
export type ApplicationAction =
  | setFirstTokenAction
  | setSecondTokenAction
  | setOpenSelectToken
  | setSelectedNumber
  | setTrxUsdPrice
  | setNotificationStatus
  | setFooterVolume
  | setFooterLiquidity
  | setTransactionStatus
  | setSwappedTokens
  | setTransactionStep
  | setTransactionStepStatus
  | setExonUsdPrice
  | setIsTronwebPresent
  | setIsTronlinkLogged
  | setSearchTokenList;
