import React, { FunctionComponent } from 'react';
import ReactTooltip, { Place, Type } from 'react-tooltip';
import './react-tooltip.css'

type Props = {
  id: string;
  place: Place;
  yellow?: boolean;
};

//        type={tooltipType ? tooltipType: "dark"} 
const Tooltip: FunctionComponent<Props> = ({ id, place, children , yellow}) => {
  const divClass = !yellow ? `ml-2 inline-flex justify-center items-center w-5 h-5 rounded-full text-sm relative cursor-pointer`:
`ml-2 inline-flex justify-center items-center w-5 h-5 rounded-full text-sm relative cursor-pointer yellow-tooltip-container yellow-tooltip-container`
  return (
    <div
      data-tip
      data-for={id}
      className={divClass}
      style={{ backgroundColor: '#5F5F5F', color: '#171612' }}
    >
     ?
      <ReactTooltip className="yellow-tooltip" id={id}
        arrowColor={yellow?"#fdd805":""}
        place={place} effect="solid">
        <span>{children}</span>
      </ReactTooltip>
    </div>
  );
};
export default Tooltip;
