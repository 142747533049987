import {
  addLiquidityAction,
  addLiquidityCurrentState,
  addLiquidityEnum,
} from './types';

const initialState: addLiquidityCurrentState = {
  inProcess: false,
  poolInfo: {
    totalSupply: 0,
    liquidity: 0,
    lpPriceInUsd: 0,
    firstDepositTokenInfo: {},
    secondDepositTokenInfo: {},
    personalLiquidityToken0: 0,
    personalLiquidityToken1: 0,
  },
  firstTokenAmount: 0,
  secondTokenAmount: 0,
  currentPoolTokens: 0,
  firstTokenReserve: 0,
  secondTokenReserve: 0,
};

export default function addLiquidityReducer(
  state = initialState,
  action: addLiquidityAction,
) {
  switch (action.type) {
    case addLiquidityEnum.SET_IN_PROCESS_LIQUIDITY:
      return { ...state, inProcess: action.payload };
    case addLiquidityEnum.SET_LIQUIDITY_POOL_INFO:
      return { ...state, poolInfo: action.payload };
    case addLiquidityEnum.SET_FIRST_TOKEN_AMOUNT:
      return { ...state, firstTokenAmount: action.payload };
    case addLiquidityEnum.SET_SECOND_TOKEN_AMOUNT:
      return { ...state, secondTokenAmount: action.payload };
    case addLiquidityEnum.SET_CONFIRM_SUPPLY:
      return { ...state, confirmSupply: action.payload };
    case addLiquidityEnum.SET_CURRENT_PLT:
      return { ...state, currentPoolTokens: action.payload };
    case addLiquidityEnum.SET_FIRST_TOKEN_RESERVE:
      return { ...state, firstTokenReserve: action.payload };
    case addLiquidityEnum.SET_SECOND_TOKEN_RESERVE:
      return { ...state, secondTokenReserve: action.payload };
    default:
      return state;
  }
}
