import ConnectWalletButton from "components/Button/ConnectWalletButton"
import { coinIcons } from "components/CoinIcons"
import useWalletSelector from "hooks/useWalletSelector"
import { FunctionComponent } from "react"
import { useDispatch } from "react-redux"
import { ApplicationAcionCreator } from "store/reducers/application/action-creator"
import { LiquidityActionCreator } from "store/reducers/liquidity/action-creator"
import { addLiquidityActionCreator } from "store/reducers/liquidityAdd/action-creator"
import { getTokenInfoByName } from "utils/getTokenInfo"

export type Props = {
    price: {
        firstToken: number,
        secondToken: number,
        trx: number
    },
    history: any
}

export const TradingPairInfo: FunctionComponent<Props> = ({price, history}) => {
    const dispatch = useDispatch()
    const { isWallet } = useWalletSelector(state => state.WalletReducer)

    const handleAddSwapExactPool = (e: any) => {
        // dispatch(LiquidityActionCreator.setLiquidityState('add'));
        dispatch(LiquidityActionCreator.setLiquidityMainState('add'));
        dispatch(addLiquidityActionCreator.getTokensReserves());
        
        console.log(getTokenInfoByName(history.location.pathname.split('/')[2].split('-')[0].toUpperCase()));
        

        dispatch(
            ApplicationAcionCreator.setFirstToken({
            address: getTokenInfoByName(history.location.pathname.split('/')[2].split('-')[0].toUpperCase()).address,
            icon: getTokenInfoByName(history.location.pathname.split('/')[2].split('-')[0].toUpperCase()).icon,
            symbol: history.location.pathname.split('/')[2].split('-')[0].toUpperCase(),
            name: history.location.pathname.split('/')[2].split('-')[0].toUpperCase(),
          }),
        );
        dispatch(
          ApplicationAcionCreator.setSecondToken({
            address: getTokenInfoByName(history.location.pathname.split('/')[2].split('-')[1].toUpperCase()).address,
            icon: getTokenInfoByName(history.location.pathname.split('/')[2].split('-')[1].toUpperCase()).icon,
            symbol: history.location.pathname.split('/')[2].split('-')[1].toUpperCase(),
            name: history.location.pathname.split('/')[2].split('-')[1].toUpperCase(),
        }),
        );
        e.target.id === 'swap' ? history.push('/swap') : history.push('/swap/liquidity')
    }

    return(
        <div className="bg-cardBg w-full mb-4 rounded-3xl py-4 px-8 ">
            <div className="flex sm:flex-row flex-col items-center justify-between">
                <div className="flex sm:mb-0 mb-4 items-center text-title text-xs lg:text-base xl:text-2xl font-medium">
                    <img className='lg:w-8 w-4 lg:h-8 h-4 z-10' src={coinIcons[history.location.pathname.split('/')[2].split('-')[0].toLowerCase()]} alt="" />
                    <img className='lg:w-8 w-4 lg:h-8 h-4 -ml-2 lg:-ml-4' src={coinIcons[history.location.pathname.split('/')[2].split('-')[1].toLowerCase()]} alt="" />
                    <span className='ml-2'>{history.location.pathname.split('/')[2].split('-')[0].toUpperCase()}</span>
                    <span>-</span>
                    <span>{history.location.pathname.split('/')[2].split('-')[1].toUpperCase()}</span>
                    <span className='ml-2'>Trading pair</span>
                </div>
                { isWallet ?
                <div className="flex items-center">
                    <a id='liquidity' onClick={e => handleAddSwapExactPool(e)} className='no-underline text-black bg-button rounded-lg px-4 py-1 xl:py-3 cursor-pointer'>+ Liquidity</a>
                    <a id='swap' onClick={e => handleAddSwapExactPool(e)} className='no-underline text-black bg-yellow rounded-lg ml-7 px-4 py-1 xl:py-3 cursor-pointer' >Trade</a>
                </div>
                : <ConnectWalletButton />}
            </div>
            <div className="flex sm:jut lg:flex-row flex-col justify-between text-xs xl:text-base text-description font-normal mt-4">
                <div className="flex sm:justify-start justify-center items-center mb-5 lg:mb-0">
                    <img className='w-3 lg:w-7 h-3 lg:h-7' src={coinIcons[history.location.pathname.split('/')[2].split('-')[0].toLowerCase()]} alt="" />
                    <span className='ml-2'>1 {history.location.pathname.split('/')[2].split('-')[0].toUpperCase()}</span>
                    <span>=</span>
                    <span>{price.firstToken} USDT</span>
                </div>
                <div className="flex sm:justify-start justify-center items-center">
                    <img className='w-3 lg:w-7 h-3 lg:h-7' src={coinIcons[history.location.pathname.split('/')[2].split('-')[1].toLowerCase()]} alt="" />
                    <span className='ml-2'>1 {history.location.pathname.split('/')[2].split('-')[1].toUpperCase()}</span>
                    <span>=</span>
                    <span>{price.secondToken} USDT</span>
                </div>
            </div>
        </div>
    )
}