export type farmingType = {
  types?: 'core' | 'comunity' | 'special';
};

export type farmingInfo = {
  address?: string;
  arp?: number;
  earn?: number;
  type?: farmingType;
};

export interface farmingState {
  // stakeProcess: boolean;
  // lpTokenAmount: number;
  // info: farmingInfo;
  // withdrawAmount: number;
  stakedOnly: boolean,
  option: string,
  search: string,
  currentBlock: number,
  farmingList: farmingList[];
  // farmingStakeInfo: farmingStakeInfo;
  calculatorState: calculatorState;
  farmingStakeInfo: any;
  farmingState: string;
}

export interface farmingStakeInfo {
  firstTokenAddress: string;
  secondTokenAddress: string;
  lpTokensAmount: number;
  stakedLpTokens: number;
  stakeInput: string;
  unstakeInput: string;
  availableUnstake: number;
  claimAvailable: number;
  withdrawAmount: number;
}

export interface calculatorState {
  lpToken: string;
  firstTokenAddress: string;
  secondTokenAddress: string;
  usdInput: number;
  lpInput: number;
  tokenOutput: number;
  lpAmount: number;
}

export interface calculatorInfo {
  lpToken?: string;
  firstTokenAddress?: string;
  secondTokenAddress?: string;
  usdInput?: number;
  lpInput?: number;
  tokenOutput?: number;
  lpAmount?: number;
}

export type farmingList = {
  poolId: number
  accTokenPerShare: number
  active: boolean
  lastRewardBlock: number
  lpToken: string
  poolType: number
  token0: string
  token1: string
  tokenOut: string
  tokenPerBlock: number
  lpStaked: number | string
  personalEarn: number | string
  totalLiquidity: number
  apr: number
}

export enum farmingEnum {
  SET_FARMING_LIST = 'SET_FARMING_LIST',
  SET_FARMING_STATE = 'SET_FARMING_STATE',
  SET_FARMING_LP_TOKENS = 'SET_FARMING_LP_TOKENS',
  SET_FARMING_TOKENS = 'SET_FARMING_TOKENS',
  SET_STAKE_INPUT = 'SET_STAKE_INPUT',
  RESET_FARMING_STAKE_INFO = 'RESET_FARMING_STAKE_INFO',
  SHOW_STAKED_ONLY = 'SHOW_STAKED_ONLY',
  SET_SORT_OPTION = 'SET_SORT_OPTION',
  SET_LP_TOKEN_ADDRESS = 'SET_LP_TOKEN_ADDRESS',
  SET_STAKED_TOKENS = 'SET_STAKED_TOKENS',
  SET_UNSTAKE_TOKENS = 'SET_UNSTAKE_TOKENS',
  SET_CALCULATOR_STATE = 'SET_CALCULATOR_STATE',
  SET_WITHDRAW_AMOUNT = 'SET_WITHDRAW_AMOUNT',
  SET_CURRENT_BLOCK = 'SET_CURRENT_BLOCK'
}

export interface setFarmingListAction {
  type: farmingEnum.SET_FARMING_LIST;
  payload: any[];
}

export interface setFarmingState {
  type: farmingEnum.SET_FARMING_STATE;
  payload: string;
}

export interface setFarmingLpTokens {
  type: farmingEnum.SET_FARMING_LP_TOKENS;
  payload: number;
}

export interface setStakeInput {
  type: farmingEnum.SET_STAKE_INPUT;
  payload: string;
}

export interface resetFarmingStakeInfo {
  type: farmingEnum.RESET_FARMING_STAKE_INFO;
}

export interface setFarmingTokens {
  type: farmingEnum.SET_FARMING_TOKENS;
  payload: {firstToken: string, secondToken: string}
}

export interface showStakedOnly {
  type: farmingEnum.SHOW_STAKED_ONLY;
}

export interface setSortOption {
  type: farmingEnum.SET_SORT_OPTION;
  payload: string
}

export interface setLpTokenAddress {
  type: farmingEnum.SET_LP_TOKEN_ADDRESS;
  payload: string
}

export interface setStakedTokens {
  type: farmingEnum.SET_STAKED_TOKENS;
  payload: number
}

export interface setUnstakeInput {
  type: farmingEnum.SET_UNSTAKE_TOKENS;
  payload: string
}

export interface setCalculatorState {
  type: farmingEnum.SET_CALCULATOR_STATE;
  payload: calculatorInfo
}

export interface setWithdrawAmount {
  type: farmingEnum.SET_WITHDRAW_AMOUNT;
  payload: number
}

export interface setCurrentBlock {
  type: farmingEnum.SET_CURRENT_BLOCK;
  payload: number
}

export type farmingCurrentState = farmingState;
export type farmingAction =
  | setFarmingListAction
  | setFarmingState
  | setFarmingLpTokens
  | setFarmingTokens
  | setStakeInput
  | resetFarmingStakeInfo
  | showStakedOnly
  | setSortOption
  | setLpTokenAddress
  | setStakedTokens
  | setUnstakeInput
  | setCalculatorState
  | setWithdrawAmount
  | setCurrentBlock;
