import { TokenInfo } from '../types';
import { ApplicationAction, ApplicationStateEnum, SearchTokenList } from './types';
import { AppDispatch, getRootState } from 'store';
import Config from 'config';
import ITRX20 from '../../../contracts/ITRC20.json';
import Oracle from 'contracts/Oracle.json';
import { getFooterInfo, getTokensByAddress, getTRXtoUSDTprice } from 'api/api';
import Price from 'contracts/Price.json'
import { getTokenInfoByName } from 'utils/getTokenInfo'
import initTronstack from 'utils/tronWeb/initTronstack';

export const ApplicationAcionCreator = {
  setFirstToken: (firstToken: TokenInfo) => ({
    type: ApplicationStateEnum.SET_FIRST_TOKEN_APP,
    payload: firstToken,
  }),
  setSecondToken: (secondToken: TokenInfo) => ({
    type: ApplicationStateEnum.SET_SECOND_TOKEN_APP,
    payload: secondToken,
  }),
  setSwapTokens: (firstTokenInfo: TokenInfo, secondTokenInfo: TokenInfo): ApplicationAction => ({
    type: ApplicationStateEnum.SET_SWAPPED_TOKENS,
    payload: {
      firstTokenInfo,
      secondTokenInfo
    },
  }),
  setSelectTokensModal: (openSelectTokenModal: boolean) => ({
    type: ApplicationStateEnum.SET_OPEN_SELECT_TOKEN,
    payload: openSelectTokenModal,
  }),
  setSelectedNumber: (selectedNumber: 1 | 2 | 0) => ({
    type: ApplicationStateEnum.SET_SELECTED_NUMBER,
    payload: selectedNumber,
  }),
  setTransactionState: (
    transactionState: 'start' | 'inProcess' | 'finished' | 'notActive',
  ) => ({
    type: ApplicationStateEnum.SET_TRANSACTION_STATE,
    payload: transactionState,
  }),
  setTrxUsdPrice: (trxUsdPrice: number): ApplicationAction => ({
    type: ApplicationStateEnum.SET_TRX_USD_PRICE,
    payload: trxUsdPrice,
  }),
  setExonUsdPrice: (exonUsdPrice: number): ApplicationAction => ({
    type: ApplicationStateEnum.SET_EXON_USD_PRICE,
    payload: exonUsdPrice,
  }),
  setFooterVolume: ( volumeAmount: number  ): ApplicationAction => ({
    type: ApplicationStateEnum.SET_FOOTER_VOLUME,
    payload: volumeAmount
  }),
  setFooterLiquidity: ( liquidityAmount: number  ): ApplicationAction => ({
    type: ApplicationStateEnum.SET_FOOTER_LIQUIDITY,
    payload: liquidityAmount
  }),
  setTransactionSteps: (stepEl?: string[]): ApplicationAction => ({
    type: ApplicationStateEnum.SET_TRANSACTION_STEPS,
    payload: stepEl
  }),
  setTransactionStepStatus: (process: string, fetching: boolean, done: boolean): ApplicationAction => ({
    type: ApplicationStateEnum.SET_TRANSACTION_STEP_STATUS,
    payload: {process, fetching, done}
  }),
  setIsTronwebPresent: (isPresent: boolean): ApplicationAction => ({
    type: ApplicationStateEnum.SET_IS_TRONWEB_PRESENT,
    payload: isPresent
  }),
  setIsTronlinkLogged: (isLogged: boolean): ApplicationAction => ({
    type: ApplicationStateEnum.SET_IS_TRONLINK_LOGGED,
    payload: isLogged
  }),
  setSearchTokenList: (tokenList: SearchTokenList[]): ApplicationAction => ({
    type: ApplicationStateEnum.SET_SEARCH_TOKEN_LIST,
    payload: tokenList
  }),
  // getTrxUsdPrice: () => async (dispatch: AppDispatch, store: getRootState) => {
  //   let response 
  //   try {
  //     response = await getTRXtoUSDTprice()
  //   } catch (error: any) {
  //     console.log(error.message);
  //   }
  //   dispatch(ApplicationAcionCreator.setTrxUsdPrice(response?.data.USD));
  // },
  setTransactionStatus: (isTransaction: boolean): ApplicationAction => {
    return {
      type: ApplicationStateEnum.SET_TRANSACTION_STATUS,
      payload: isTransaction
    }
  },
  setNotificationStatus: (
    endProcess: boolean,
    isShown: boolean,
    status?: string,
    process?: string,
    currentTransaction?: string,
    description?: string,
  ): ApplicationAction => {
    return {
      type: ApplicationStateEnum.SET_NOTIFICATION_STATUS,
      payload: {
        endProcess,
        isShown,
        status,
        process,
        currentTransaction,
        description,
      },
    };
  },
  getFooterInfo: () => async (dispatch: AppDispatch, store: getRootState) => {
    const tronWeb = await initTronstack();
    let response = await getFooterInfo()
    let volumeAmount = 0
    
    
    if(tronWeb === null || tronWeb === undefined) return
    const PriceContract = await tronWeb.contract(
      Price.abi,  
      Config().ORACLE_LIQUIDITY_AND_SWAP
    )
    if(response.data){
      for (let i = 0; i < response.data.length; i++) {
        let currTokenPrice = await PriceContract.getTokenPriceInUSD(response.data[i].address).call()
        currTokenPrice = tronWeb.fromSun(currTokenPrice.toNumber())
        let currTokenAmount = tronWeb.fromSun(response.data[i].value)
        volumeAmount += currTokenPrice * parseFloat(currTokenAmount)
      }
      dispatch(ApplicationAcionCreator.setFooterVolume(volumeAmount))
    }
    let trxPrice
    try {
      trxPrice = await PriceContract.getTokenPriceInUSD(getTokenInfoByName('TRX').address).call()
    } catch (error: any) {
      console.log(error);
    }
    if(trxPrice === undefined) return
    trxPrice = tronWeb.fromSun(trxPrice.toNumber())
    trxPrice = +parseFloat(trxPrice).toFixed(2)
    dispatch(ApplicationAcionCreator.setTrxUsdPrice(trxPrice));
    
    let exonPrice
    try {
      exonPrice = await PriceContract.getTokenPriceInUSD(getTokenInfoByName('EXON').address).call()
    } catch (error: any) {
      console.log(error);
    }
    if (exonPrice === undefined) return
    exonPrice = tronWeb.fromSun(exonPrice.toNumber())
    dispatch(ApplicationAcionCreator.setExonUsdPrice(exonPrice));
  },
  searchTokenByAddress:
      (address: string) => async (dispatch: AppDispatch, store: getRootState) => {
        const tronWeb = store().WalletReducer.tronWeb
        let hexAddress
        let tokenList
        let result
        if(!tronWeb) return

        try {
          hexAddress = tronWeb.address.toHex(address).replace('41', '0x')
        } catch (error) {
          console.log(error);
          return 
        }

        if(!hexAddress.match(/^(0x)?[0-9a-f]{40}$/)) return

        try {
          result = await getTokensByAddress(address)
        } catch (error) {
          console.log(error);
          return
        }
      
        tokenList = result.data.trc20_tokens
					.filter((el: { name: string; }) => el.name !== 'EXON').map((el: { contract_address: any; symbol: any; icon_url: any; }) => {
          return {
            address: el.contract_address,
            name: el.symbol,
            decimals: 6,
            icon: el.icon_url
          }
        })

        for (let i = 0; i < tokenList.length; i++) {
          const tokenContract = await tronWeb.contract(
            ITRX20.abi,
            tokenList[i].address,
          );
          let decimal
          try {
            decimal = await tokenContract.decimals().call()
          } catch (error) {
            console.log(error);
            decimal = 6
          }
          tokenList[i].decimals = decimal
        }
        dispatch(ApplicationAcionCreator.setSearchTokenList(tokenList))
    }
};
