import { FunctionComponent, useState } from 'react';
import Config from 'config/index';
import { ReactComponent as Logo } from '../../../Pool/components/PoolItem/assets/poolLogo.svg';
import { ReactComponent as Tron } from '../../../FeaturedPools//components/FeatPoolItem/assets/tron.svg';
import { PoolStatus } from 'pages/Launchpad/components/FeaturedPools/components/FeatPoolItem/PoolStatus';
import { TokenInfo } from 'store/reducers/types';

type Props = {
  saleTokenInfo: TokenInfo;
  name: string;
  image: string;
  start: number;
  active: boolean;
};

const PoolHeader: FunctionComponent<Props> = ({
  saleTokenInfo,
  name,
  image,
  start,
  active,
}) => {
  const date = new Date(start * 1000)?.toISOString();
const timeFormatter = (seconds: number) => {
    seconds = Math.floor(seconds)
    let hours
    let minutes:any = Math.floor(seconds/60)
    let secs:any = seconds%60
    if(secs < 10){
        secs = '0' + secs
    }
    if(minutes >= 60){
        hours = Math.floor(minutes/60)
        minutes %= 60
    }
    if(hours && minutes < 10){
        minutes = '0' + minutes
    }

    return `${hours ? (hours + ':') : '' }${minutes}:${secs}`
}


const checkPoolStatus = () => {

    let currentTimestamp: number = Date.now() / 1000
    if (start > currentTimestamp){
        return timeFormatter(start - currentTimestamp)
    } else {
        if (active) {
            return 'Active'
          } else {
              return 'Closed'
            }
      }
}

  return (
    <div className="px-5 pt-8 bg-cardBg pb-7 md:px-10 md:py-6 rounded-4xl">
      <div className="flex justify-between mb-4">
        <div className="flex items-center">
          <img src={saleTokenInfo?.icon} className="w-8 h-8 rounded-4xl" />
          <span className="flex items-center ml-5 text-3xl font-medium sm:text-4xl text-title">
            {saleTokenInfo?.name}
          </span>
        </div>
        <PoolStatus status={checkPoolStatus()} type="" />
      </div>
      <div className="flex items-center gap-4">
        <a
          href={`${Config().TRONSCAN}/#/token20/${saleTokenInfo.address}`}
          target="_blank"
          className="text-xl font-semibold break-all cursor-pointer sm:text-4xl text-yellow"
        >
          {saleTokenInfo.address?.substring(0, 6) +
            '...' +
            saleTokenInfo.address?.substring(28, 34)}
        </a>
      </div>
      <div className="flex flex-col items-start mt-4 md:items-center md:flex-row">
        <span className="text-base font-medium sm:text-xl text-title">
          Published {date.replace('T', ' ').split('.')[0]}
        </span>
      </div>
    </div>
  );
};

export default PoolHeader;
