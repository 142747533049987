import { SidebarContext } from 'components/Layout/Layout';
import React, { FunctionComponent, useContext } from 'react';
import NavItem from './components/NavItem';
import { Navigation } from './Navigation';

const Nav: FunctionComponent = () => {
  const {setIsSidebarOpen } = useContext(SidebarContext);


  return (
    <div
      //  onClick={() => setIsSidebarOpen(false)}
       className="pt-16">
      <nav>
        {Navigation.slice(0, 9).map(item => (
          <NavItem item={item} />
        ))}
        <hr className="my-8 border-darkGray" />
        {Navigation.slice(9).map(item => (
          <NavItem item={item} external={true} />
        ))}
      </nav>
    </div>
  );
};

export default Nav;
