import React, { FunctionComponent } from 'react';
import HomepagePic from '../../assets/swap.png'
import IntroSlider from './components/IntroSlider';

const Intro: FunctionComponent = () => {
  return (
    <>
      <div className="w-full relative sm:mb-16">
        <div className="flex flex-col justify-center gap-3 sm:gap-6 text-center sm:text-left relative z-50">
          <div>
            <h1 className="text-4xl sm:text-6xl xl:text-7xl font-medium text-title">
            One platform <br /> for all DeFi 
            </h1>
          </div>
          <p className="text-description">
            Enjoy the great Exon features with the best <br /> cases in the endless possibilities of DeFi!
          </p>
        </div>
        <div style={{top: '-20%', right: window.innerWidth < 1200 ? '-800px' : '-50%'}} className="absolute pointer-events-none -z-10 hidden sm:block">
          <img
            src={HomepagePic}
            className=" w-6/12 select-none pointer-events-none"
            alt="partner Icon"
          />
        </div>
      </div>
      <IntroSlider />
    </>
  );
};

export default Intro;
