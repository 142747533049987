import { PoolInfo } from "../types";
import { addLiquidityAction, addLiquidityEnum } from "./types";
import { AppDispatch, getRootState } from "store";
import liquidityRouterABI from "contracts/ExonswapV2LiquidityRouter.json";
import swapRouterABI from "contracts/ExonswapV2SwapRouter.json";
import IERC20 from "contracts/ITRC20.json";
import Config from "../../../config";
import { ApplicationAcionCreator } from "../application/action-creator";
import { LiquidityActionCreator } from "../liquidity/action-creator";
import { breakStatement } from "@babel/types";
import sleep from "utils/sleep";
import { getConfirmationAmount } from "utils/transaction/getConfirmationAmount";
import handleTokenInfo from "utils/handleTokenInfo";
import initTronstack from "utils/tronWeb/initTronstack";

export const addLiquidityActionCreator = {
  setFirstTokenReserve: (firstReserve: number): addLiquidityAction => ({
    type: addLiquidityEnum.SET_FIRST_TOKEN_RESERVE,
    payload: firstReserve,
  }),
  setSecondTokenReserve: (secondReserve: number): addLiquidityAction => ({
    type: addLiquidityEnum.SET_SECOND_TOKEN_RESERVE,
    payload: secondReserve,
  }),
  setInProcess: (inProcess: boolean) => ({
    type: addLiquidityEnum.SET_IN_PROCESS_LIQUIDITY,
    payload: inProcess,
  }),
  setPoolInfo: (poolInfo: PoolInfo) => ({
    type: addLiquidityEnum.SET_LIQUIDITY_POOL_INFO,
    payload: poolInfo,
  }),
  setFirstTokenAmount: (firstTokenAmount: number): addLiquidityAction => ({
    type: addLiquidityEnum.SET_FIRST_TOKEN_AMOUNT,
    payload: firstTokenAmount,
  }),
  setSecondTokenAmount: (secondTokenAmount: number): addLiquidityAction => ({
    type: addLiquidityEnum.SET_SECOND_TOKEN_AMOUNT,
    payload: secondTokenAmount,
  }),
  setConfirmSupply: (confirmSupply: boolean) => ({
    type: addLiquidityEnum.SET_CONFIRM_SUPPLY,
    payload: confirmSupply,
  }),
  setCurrentPLT: (currentPLT: number) => ({
    type: addLiquidityEnum.SET_CURRENT_PLT,
    payload: currentPLT,
  }),
  addLiquidity: () => async (_dispatch: AppDispatch, store: getRootState) => {
    const tronWeb = await store().WalletReducer.tronWeb;
    var routerLiqudityContract = await tronWeb.contract(
      liquidityRouterABI.abi,
      Config().ROUTER_CONTRACT
    );
    const firstToken = store().applicationReducer.firstToken;
    const secondToken = store().applicationReducer.secondToken;
    const amountADesired = store().addLiquidityReducer.firstTokenAmount;
    const amountBDesired = store().addLiquidityReducer.secondTokenAmount;
    const swapSetting = store().swapReducer.swapSetting;
    const poolAddress = store().addLiquidityReducer.poolInfo.poolAddress;
    _dispatch(
      ApplicationAcionCreator.setTransactionSteps([
        `Approve ${firstToken.name}`,
        `Approve ${secondToken.name}`
      ])
    );
    if (firstToken.address !== undefined) {
      const FirstTokenContract = await tronWeb.contract(
        IERC20.abi,
        firstToken.address
      );

      let firstTokenApproveTrx;
      try {
        firstTokenApproveTrx = await FirstTokenContract.approve(
          Config().ROUTER_CONTRACT,
          BigInt(
            await handleTokenInfo(
              firstToken.address,
              "toSun",
              amountADesired,
              true
            )
          ).toString()
        ).send();
      } catch (error: any) {
        console.log(error);
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false));
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            "Approve",
            "Error",
            firstTokenApproveTrx,
            `${error.message}`
          )
        );
        _dispatch(LiquidityActionCreator.setLiquidityState("initial"));
        return;
      }
      _dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Approve ${firstToken.name}`,
          true,
          false
        )
      );
      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          false,
          true,
          "Approve",
          "Processing",
          firstTokenApproveTrx,
          `Approve ${firstToken.name} for add liquidity`
        )
      );
      var blockNumber;
      var unconfirmTransaction;
      do {
        await sleep(2000);
        unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(
          firstTokenApproveTrx
        );
      } while (Object.keys(unconfirmTransaction).length === 0);

      do {
        await sleep(2000);
        blockNumber = await tronWeb.trx.getCurrentBlock();
        // console.log('test result swap', blockNumber, unconfirmTransaction);
      } while (
        blockNumber.block_header.raw_data.number -
          unconfirmTransaction.blockNumber >
        Config().CONFIRMATIONS
      );

      if (unconfirmTransaction?.receipt?.result !== "SUCCESS") {
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false));
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            "Approve",
            "Error",
            firstTokenApproveTrx,
            `An error occured during approve`
          )
        );
        _dispatch(LiquidityActionCreator.setLiquidityState("initial"));
        return;
      }
      _dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Approve ${firstToken.name}`,
          false,
          true
        )
      );
      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          "Approve",
          "Success",
          firstTokenApproveTrx,
          `Approved ${firstToken.name} for add liquidity`
        )
      );
    }
    if (secondToken.address !== undefined) {
      const SecondTokenContract = await tronWeb.contract(
        IERC20.abi,
        secondToken.address
      );

      let secondTokenApproveTrx;
      try {
        secondTokenApproveTrx = await SecondTokenContract.approve(
          Config().ROUTER_CONTRACT,
          BigInt(
            await handleTokenInfo(
              secondToken.address,
              "toSun",
              amountBDesired,
              true
            )
          ).toString()
        ).send();
      } catch (error: any) {
        console.log(error);
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false));
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            "Approve",
            "Error",
            secondTokenApproveTrx,
            `${error.message}`
          )
        );
        _dispatch(LiquidityActionCreator.setLiquidityState("initial"));
        return;
      }
      _dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Approve ${secondToken.name}`,
          true,
          false
        )
      );
      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          false,
          true,
          "Approve",
          "Processing",
          secondTokenApproveTrx,
          `Approve ${secondToken.name} for add liquidity`
        )
      );
      do {
        await sleep(2000);
        unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(
          secondTokenApproveTrx
        );
      } while (Object.keys(unconfirmTransaction).length === 0);

      do {
        await sleep(2000);
        blockNumber = await tronWeb.trx.getCurrentBlock();
        // console.log('test result swap', blockNumber, unconfirmTransaction);
      } while (
        blockNumber.block_header.raw_data.number -
          unconfirmTransaction.blockNumber >
        Config().CONFIRMATIONS
      );

      if (unconfirmTransaction?.receipt?.result !== "SUCCESS") {
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false));
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            "Approve",
            "Error",
            secondTokenApproveTrx,
            `An error occured during approve`
          )
        );
        _dispatch(LiquidityActionCreator.setLiquidityState("initial"));
        return;
      }
      _dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Approve ${secondToken.name}`,
          false,
          true
        )
      );
      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          "Approve",
          "Success",
          secondTokenApproveTrx,
          `Approved ${secondToken.name} for add liquidity`
        )
      );
    }
    // const slippage = store().swapReducer.swapSetting.slippage;
    const tokenId = store().AccountReducer.currentAccount;
    const deadline = swapSetting.deadline * 60 * 1000 + Math.floor(Date.now());

    var firstAmountSun = await handleTokenInfo(
      firstToken.address,
      "toSun",
      amountADesired
    );
    var secondAmountSun = await handleTokenInfo(
      secondToken.address,
      "toSun",
      amountBDesired
    );
    let resultAddLiquidityTrx;
    _dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Signature confirming`,
        true,
        false
      )
    );
    try {
      resultAddLiquidityTrx = await routerLiqudityContract
        .addLiquidity(
          firstToken.address,
          secondToken.address,
          BigInt(
            await handleTokenInfo(
              firstToken.address,
              "toSun",
              amountADesired,
              true
            )
          ).toString(),
          BigInt(
            await handleTokenInfo(
              secondToken.address,
              "toSun",
              amountBDesired,
              true
            )
          ).toString(),
          parseInt(
            (firstAmountSun - firstAmountSun * swapSetting.slippage).toString()
          ).toString(),
          parseInt(
            (
              secondAmountSun -
              secondAmountSun * swapSetting.slippage
            ).toString()
          ).toString(),
          tronWeb.defaultAddress.base58,
          deadline.toString(),
          tokenId.toString()
        )
        .send({ feeLimit: 2000000000, callValue: "0" });
    } catch (error: any) {
      console.log(error);
      _dispatch(ApplicationAcionCreator.setTransactionStatus(false));
      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          "Liquidity",
          "Error",
          resultAddLiquidityTrx,
          `${error.message}`
        )
      );
      _dispatch(LiquidityActionCreator.setLiquidityState("initial"));
      return;
    }
    _dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Signature confirming`,
        false,
        true
      )
    );
    _dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(`Finished`, true, false)
    );
    _dispatch(
      ApplicationAcionCreator.setNotificationStatus(
        false,
        true,
        "Liquidity",
        "Processing",
        resultAddLiquidityTrx,
        `Add liquidity ${firstToken.name} and ${secondToken.name}`
      )
    );

    var blockNumber;
    var unconfirmTransaction;
    do {
      await sleep(2000);
      unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(
        resultAddLiquidityTrx
      );
    } while (Object.keys(unconfirmTransaction).length === 0);

    do {
      await sleep(2000);
      blockNumber = await tronWeb.trx.getCurrentBlock();
      // console.log('test result swap', blockNumber, unconfirmTransaction);
    } while (
      blockNumber.block_header.raw_data.number -
        unconfirmTransaction.blockNumber >
      Config().CONFIRMATIONS
    );

    if (unconfirmTransaction?.receipt?.result !== "SUCCESS") {
      _dispatch(ApplicationAcionCreator.setTransactionStatus(false));
      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          "Liquidity",
          "Error",
          resultAddLiquidityTrx,
          `An error occured during liquidity add`
        )
      );
      _dispatch(LiquidityActionCreator.setLiquidityState("initial"));
      return;
    }

    _dispatch(
      ApplicationAcionCreator.setNotificationStatus(
        true,
        true,
        "Liquidity",
        "Success",
        resultAddLiquidityTrx,
        `Add liquidity ${firstToken.name} - ${secondToken.name}`
      )
    );
    _dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(`Finished`, false, true)
    );
  },
  addTrxLiquidity:
    () => async (_dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await store().WalletReducer.tronWeb;
      const firstToken = store().applicationReducer.firstToken;
      const secondToken = store().applicationReducer.secondToken;
      const amountADesired = store().addLiquidityReducer.firstTokenAmount;
      const amountBDesired = store().addLiquidityReducer.secondTokenAmount;
      var currentToken, trxAmount, tokenAmount, trxTokenAddress;
      if (firstToken.icon === "trx") {
        currentToken = secondToken;
        tokenAmount = amountBDesired;
        trxAmount = amountADesired;
        trxTokenAddress = firstToken.address
        _dispatch(
          ApplicationAcionCreator.setTransactionSteps([
            `Approve ${currentToken.name}`
          ])
        );
      } else {
        currentToken = firstToken;
        tokenAmount = amountADesired;
        trxAmount = amountBDesired;
        trxTokenAddress = secondToken.address
        _dispatch(
          ApplicationAcionCreator.setTransactionSteps([
            `Approve ${currentToken.name}`
          ])
        );
      }
      const TokenContract = await tronWeb.contract(
        IERC20.abi,
        currentToken.address
      );
      let secondTokenApproveTrx;
      // approve token for add liquidity
      let approveTokenAmount = await handleTokenInfo(currentToken.address, "toSun", tokenAmount)
      try {
        secondTokenApproveTrx = await TokenContract.approve(
          Config().ROUTER_CONTRACT,
          approveTokenAmount.toString()
        ).send();
      } catch (error: any) {
        console.log(error);
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false));
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            "Approve",
            "Error",
            secondTokenApproveTrx,
            `${error.message}`
          )
        );
        _dispatch(LiquidityActionCreator.setLiquidityState("initial"));
        return;
      }
      let resultTokenApprove;
      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          false,
          true,
          "Approve",
          "Processing",
          secondTokenApproveTrx,
          `Approve ${currentToken.name} for add liquidity`
        )
      );
      _dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Approve ${currentToken.name}`,
          true,
          false
        )
      );
      var blockNumber;
      var unconfirmTransaction;
      do {
        await sleep(2000);
        unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(
          secondTokenApproveTrx
        );
      } while (Object.keys(unconfirmTransaction).length === 0);

      do {
        await sleep(2000);
        blockNumber = await tronWeb.trx.getCurrentBlock();
        // console.log('test result swap', blockNumber, unconfirmTransaction);
      } while (
        blockNumber.block_header.raw_data.number -
          unconfirmTransaction.blockNumber >
        Config().CONFIRMATIONS
      );

      if (unconfirmTransaction?.receipt?.result !== "SUCCESS") {
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false));
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            "Approve",
            "Error",
            secondTokenApproveTrx,
            `An error occured during approve liquidity`
          )
        );
        _dispatch(LiquidityActionCreator.setLiquidityState("initial"));
        return;
      }

      _dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Approve ${currentToken.name}`,
          false,
          true
        )
      );
      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          "Approved",
          "Success",
          secondTokenApproveTrx,
          `Approved ${currentToken.name} for add liquidity`
        )
      );

      var routerLiqudityContract = await tronWeb.contract(
        liquidityRouterABI.abi,
        Config().ROUTER_CONTRACT
      );
      _dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Signature confirming`,
          true,
          false
        )
      );

      const tokenId = store().AccountReducer.currentAccount;
      const swapSetting = store().swapReducer.swapSetting;
      const deadline =
        swapSetting.deadline * 60 * 1000 + Math.floor(Date.now());
      var tokenAmountSun = await handleTokenInfo(
        currentToken.address,
        "toSun",
        tokenAmount
      );
      var trxAmountSun = tronWeb.toSun(
        parseFloat(trxAmount.toString()).toFixed(6)
      );
      let slipageAmount = await handleTokenInfo(trxTokenAddress, 'toSun', (trxAmount - trxAmount * swapSetting.slippage).toFixed(6))
      let amountIn = await handleTokenInfo( currentToken.address, "toSun", tokenAmount)
      let resultTrxAddLiquidityTrx;
      try {
        resultTrxAddLiquidityTrx = await routerLiqudityContract
          .addLiquidityTRX(
            currentToken.address,
            amountIn.toString(),
            slipageAmount.toString(),
            parseInt(
              (trxAmountSun - trxAmountSun * swapSetting.slippage).toString()
            ).toString(),
            tronWeb.defaultAddress.base58,
            deadline.toString(),
            tokenId.toString()
          )
          .send({ feeLimit: 2000000000, callValue: tronWeb.toSun(trxAmount) });
      } catch (error: any) {
        console.log(error);
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false));
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            "Liquidity",
            "Error",
            resultTrxAddLiquidityTrx,
            `${error.message}`
          )
        );
        _dispatch(LiquidityActionCreator.setLiquidityState("initial"));
        return;
      }
      _dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Signature confirming`,
          false,
          true
        )
      );
      _dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Finished`,
          true,
          false
        )
      );
      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          false,
          true,
          "Liquidity",
          "Processing",
          resultTrxAddLiquidityTrx,
          `Add liquidity ${firstToken.name} - ${secondToken.name}`
        )
      );
      var blockNumber;
      var unconfirmTransaction;
      do {
        await sleep(2000);
        unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(
          resultTrxAddLiquidityTrx
        );
      } while (Object.keys(unconfirmTransaction).length === 0);

      do {
        await sleep(2000);
        blockNumber = await tronWeb.trx.getCurrentBlock();
        // console.log('test result swap', blockNumber, unconfirmTransaction);
      } while (
        blockNumber.block_header.raw_data.number -
          unconfirmTransaction.blockNumber >
        Config().CONFIRMATIONS
      );

      if (unconfirmTransaction?.receipt?.result !== "SUCCESS") {
        _dispatch(ApplicationAcionCreator.setTransactionStatus(false));
        _dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            "Liquidity",
            "Error",
            resultTrxAddLiquidityTrx,
            `An error occured during liquidity add`
          )
        );
        _dispatch(LiquidityActionCreator.setLiquidityState("initial"));
        return;
      }

      _dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          "Liquidity",
          "Success",
          resultTrxAddLiquidityTrx,
          `Success added liquidity ${firstToken.name} - ${secondToken.name}`
        )
      );
      _dispatch(
        ApplicationAcionCreator.setTransactionStepStatus(
          `Finished`,
          false,
          true
        )
      );
    },
  setSecondTokenPrice:
    (type?: string) => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await (window as any).tronWeb;
      const firstTokenInfo = store().applicationReducer.firstToken;
      const secondTokenInfo = store().applicationReducer.secondToken;
      const secondTokenBalance = store().swapReducer.secondTokenBalance;
      if (tronWeb != null) {
        const routeSwapContract = await tronWeb.contract(
          swapRouterABI.abi,
          Config().ROUTER_CONTRACT
        );
        if (
          firstTokenInfo.address !== undefined &&
          secondTokenInfo.address !== undefined
        ) {
          const tokenList = [firstTokenInfo.address, secondTokenInfo.address];
          try {
            var reserves = await routeSwapContract
              .getPairReserves(tokenList[0], tokenList[1])
              .call();
          } catch (e) {
            console.log(e);
          }
          let firstTokenReserve, secondTokenReserve;
          if (reserves !== undefined) {
            firstTokenReserve = await handleTokenInfo(
              firstTokenInfo.address,
              "fromSun",
              BigInt(reserves[0])
            );
            secondTokenReserve = await handleTokenInfo(
              secondTokenInfo.address,
              "fromSun",
              BigInt(reserves[1])
            );
          } else {
            firstTokenReserve = 0;
            secondTokenReserve = 0;
          }
          dispatch(
            addLiquidityActionCreator.setFirstTokenReserve(firstTokenReserve)
          );
          dispatch(
            addLiquidityActionCreator.setSecondTokenReserve(secondTokenReserve)
          );
        }
      }

      const firstTokenAmount = store().addLiquidityReducer.firstTokenAmount;
      const firstTokenReserve = await store().addLiquidityReducer
        .firstTokenReserve;
      const secondTokenReserve = await store().addLiquidityReducer
        .secondTokenReserve;
      if (
        firstTokenAmount !== undefined &&
        firstTokenReserve > 0 &&
        secondTokenReserve > 0
      ) {
        var secondTokenPrice: any;
        var secondTokenNumber: number | string;
        secondTokenPrice =
          (secondTokenReserve * firstTokenAmount) / firstTokenReserve;
        dispatch(
          addLiquidityActionCreator.setSecondTokenAmount(
            parseFloat(secondTokenPrice.toFixed(6))
          )
        );
        if (
          type! === "max" &&
          secondTokenPrice.toFixed(6) > +secondTokenBalance
        ) {
          dispatch(
            addLiquidityActionCreator.setSecondTokenAmount(secondTokenBalance)
          );
        }
      } else if (
        firstTokenReserve === 0 &&
        secondTokenReserve === 0 &&
        type === "max"
      ) {
        dispatch(
          addLiquidityActionCreator.setSecondTokenAmount(secondTokenBalance)
        );
      } else {
        dispatch(addLiquidityActionCreator.setSecondTokenAmount(0));
      }
    },
  setFirstTokenPrice:
    (type?: string) => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await (window as any).tronWeb;
      const firstTokenInfo = store().applicationReducer.firstToken;
      const secondTokenInfo = store().applicationReducer.secondToken;
      const firstTokenBalance = store().swapReducer.firstTokenBalance;
      if (tronWeb != null) {
        const routeSwapContract = await tronWeb.contract(
          swapRouterABI.abi,
          Config().ROUTER_CONTRACT
        );
        if (
          firstTokenInfo.address !== undefined &&
          secondTokenInfo.address !== undefined
        ) {
          const tokenList = [firstTokenInfo.address, secondTokenInfo.address];
          try {
            var reserves = await routeSwapContract
              .getPairReserves(tokenList[0], tokenList[1])
              .call();
          } catch (e) {
            console.log(e);
          }
          let firstTokenReserve, secondTokenReserve;
          if (reserves !== undefined) {
            firstTokenReserve = await handleTokenInfo(
              firstTokenInfo.address,
              "fromSun",
              BigInt(reserves[0])
            );
            secondTokenReserve = await handleTokenInfo(
              secondTokenInfo.address,
              "fromSun",
              BigInt(reserves[1])
            );
          } else {
            firstTokenReserve = 0;
            secondTokenReserve = 0;
          }
          dispatch(
            addLiquidityActionCreator.setFirstTokenReserve(firstTokenReserve)
          );
          dispatch(
            addLiquidityActionCreator.setSecondTokenReserve(secondTokenReserve)
          );
        }
      }
      const secondTokenAmount = store().addLiquidityReducer.secondTokenAmount;
      const firstTokenReserve = await store().addLiquidityReducer
        .firstTokenReserve;
      const secondTokenReserve = await store().addLiquidityReducer
        .secondTokenReserve;
      if (
        secondTokenAmount !== undefined &&
        firstTokenReserve > 0 &&
        secondTokenReserve > 0
      ) {
        var firstTokenPrice;
        var firstTokenNumber: number | string;
        firstTokenPrice =
          (firstTokenReserve * secondTokenAmount) / secondTokenReserve;

        dispatch(
          addLiquidityActionCreator.setFirstTokenAmount(
            parseFloat(firstTokenPrice.toFixed(6))
          )
        );
      } else if (
        firstTokenReserve === 0 &&
        secondTokenReserve === 0 &&
        type === "max"
      ) {
        dispatch(
          addLiquidityActionCreator.setFirstTokenAmount(firstTokenBalance)
        );
      } else {
        dispatch(addLiquidityActionCreator.setFirstTokenAmount(0));
      }
    },
  getTokensReserves:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await store().WalletReducer.tronWeb;
      const firstTokenInfo = store().applicationReducer.firstToken;
      const secondTokenInfo = store().applicationReducer.secondToken;
      if (tronWeb != null) {
        const routeSwapContract = await tronWeb.contract(
          swapRouterABI.abi,
          Config().ROUTER_CONTRACT
        );
        if (
          firstTokenInfo.address !== undefined &&
          secondTokenInfo.address !== undefined
        ) {
          const tokenList = [firstTokenInfo.address, secondTokenInfo.address];
          try {
            var reserves = await routeSwapContract
              .getPairReserves(tokenList[0], tokenList[1])
              .call();
          } catch (e) {
            console.log(e);
          }
          if (reserves !== undefined) {
            const firstTokenReserve = await handleTokenInfo(
              firstTokenInfo.address,
              "fromSun",
              reserves[0]
            );
            const secondTokenReserve = await handleTokenInfo(
              secondTokenInfo.address,
              "fromSun",
              reserves[1]
            );
            dispatch(
              addLiquidityActionCreator.setFirstTokenReserve(firstTokenReserve)
            );
            dispatch(
              addLiquidityActionCreator.setSecondTokenReserve(
                secondTokenReserve
              )
            );
          }
        }
      }
    },
};
