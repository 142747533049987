import { FunctionComponent, useState } from "react";
import SwiperCore, { Pagination } from 'swiper'; import { Swiper ,SwiperSlide } from "swiper/react";
import FeatPoolItem from "./components/FeatPoolItem/FeatPoolItem";
import {Arrow} from '../../../../components/Icons'
import s from './FeaturedPools.module.css'
import { LaunchpoolInfo } from "store/reducers/launchpool/types";
import PoolItem from "../Pool/components/PoolItem";

SwiperCore.use([Pagination])

type Props = {
    pools: LaunchpoolInfo[];
}


const FeaturedPools: FunctionComponent<Props> = ({pools}) => {
    const [swiper, setSwiper] = useState<SwiperCore>();

    return( <div className={`relative container  ${s.swiper__block} `}>
            <div className="flex lg:gap-x-7">
                <Swiper
                    className={`${s.swiper} w-full mt-7 relative`}
                    loop
                    pagination={{
                    clickable: true,
                    el: '.pool-pagination',
                    type: 'bullets',
                    }}
                    breakpoints={{
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    1024: {
                        slidesPerView: 2,
                    },
                    // when window width is >= 768px
                    1500: {
                        slidesPerView: 3,
                    },
                    }}
                    spaceBetween={60}
                    onSwiper={swiper => setSwiper(swiper)}
                >
                    {pools.map(slide => (
                    <SwiperSlide
                        className={`relative flex items-center ${s.feat__pool} justify-center overflow-hidden cursor-pointer bg-cardBg md:overflow-visible`}
                        children={<PoolItem {...slide} />}
                    />
                    ))}

                    <div className="flex justify-center mt-2 lg:justify-between">
                        <div
                            className="hidden -mt-2 transform lg:block"
                            onClick={() => swiper?.slidePrev()}
                        >
                        <Arrow className="w-8 h-8 cursor-pointer"/>
                        </div>
                        <div className="">
                            <div className="pool-pagination"></div>
                        </div>
                        <div
                            className="hidden -mt-2 lg:block"
                            onClick={() => swiper?.slideNext()}
                        >
                        <Arrow className="w-8 h-8 cursor-pointer transform rotate-180" />
                        </div>
                    </div>
            </Swiper>
            </div>
        </div>
    )
}

export default FeaturedPools
