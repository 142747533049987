import { getAnalyticsInformation, getCandleGraphic, getPairInfoByAddress, getPairs, getTransactions } from "api/api";
import { BarData, LineData, WhitespaceData } from "lightweight-charts";
import { AppDispatch, RootState } from "store";
import { AnalyticsInfo, CandleChart, GraphicAction, GraphicEnum, PagePairInfo, PairInfo, TransactionElement } from "./types";

export const graphicActionCreator = {
  setLiquidityGraphic: (data: LineData[]): GraphicAction => ({
    type: GraphicEnum.GRAPHIC_SET_LIQUIDITY,
    payload: data,
  }),
  setVolumeGraphic: (data: LineData[]): GraphicAction => ({
    type: GraphicEnum.GRAPHIC_SET_VOLUME,
    payload: data,
  }),
  setCandleGraphic: (data: any): GraphicAction => ({
    type: GraphicEnum.GRAPHIC_SET_CANDLE,
    payload: data,
  }),
  setAnalyticsInfo: (data: AnalyticsInfo): GraphicAction => ({
    type: GraphicEnum.GRAPHIC_SET_ANALYTICS_INFO,
    payload: data
  }),
  setTransactions: (data: {
    transactions: TransactionElement[]
    pageCount: number,
    currentPage: number
  }): GraphicAction => ({
    type: GraphicEnum.GRAPHIC_SET_TRANSACTIONS,
    payload: data
  }),
  setPairsInfo: (data: PairInfo[]): GraphicAction => ({
    type: GraphicEnum.GRAPHIC_SET_PAIRS,
    payload: data
  }),
  setPairsPagesCount: (data: number): GraphicAction => ({
    type: GraphicEnum.GRAPHIC_SET_PAIRS_PAGES_COUNT,
    payload: data
  }),
  setPairPageInfo: (data: PagePairInfo): GraphicAction => ({
    type: GraphicEnum.GRAPHIC_SET_PAIR_PAGE_INFO,
    payload: data
  }),
  resetAllGraphics: (): GraphicAction => ({
    type: GraphicEnum.GRAPHIC_RESET_ALL_GRAPHICS
  }),
  getGraphicsData: () => async (dispatch: AppDispatch , state: RootState) => {
    
    let response 
    try {
      response = await getAnalyticsInformation()
    } catch (error: any) {
      console.log(error);
      return
    }
    dispatch(graphicActionCreator.setLiquidityGraphic(response.data.liquidity))
    dispatch(graphicActionCreator.setVolumeGraphic(response.data.volumes))
    dispatch(graphicActionCreator.setAnalyticsInfo(response.data.info))
  },
  getTransactionsData: (page: number, action: string, firstTokenAddress: string, secondTokenAddress: string) => async (dispatch: AppDispatch, state: RootState) => {
    let response
    try {
      response = await getTransactions(page-1, action, firstTokenAddress, secondTokenAddress)
    } catch (error: any) {
      console.log(error);
      return
    }
    dispatch(graphicActionCreator.setTransactions(response.data))
  },
  getPairs: (page: number) => async (dispatch: AppDispatch, state: RootState) => {
    let response 
    try {
      response = await getPairs(page)   
    } catch (error: any) {
      console.log(error);
      return
    }  
    dispatch(graphicActionCreator.setPairsPagesCount(response.data.pageCount))
    dispatch(graphicActionCreator.setPairsInfo(response.data.pairs))
  },
  getPairInfoByAddress: (firstTokenAddress: string, secondTokenAddress: string) => async (dispatch: AppDispatch, state: RootState) => {
    let response 
    try {
      response = await getPairInfoByAddress(firstTokenAddress, secondTokenAddress)      
    } catch (error: any) {
      console.log(error);
      return
    }
    dispatch(graphicActionCreator.resetAllGraphics())
    dispatch(graphicActionCreator.setPairPageInfo(response.data.pairInfo))
  },
  getCandleGraphic: (firstTokenAddress: string, secondTokenAddress: string, timeStamp: number) => async (dispatch: AppDispatch, state: RootState) => {
    let response 
    try {
      response = await getCandleGraphic(firstTokenAddress, secondTokenAddress, Math.floor(timeStamp/1000))
    } catch (error: any) {
      console.log(error);
      return
    }
    // dispatch(graphicActionCreator.setCandleGraphic(response.data.data))
  },
  getLiquidityGraphic: (firstTokenAddress: string, secondTokenAddress: string) => async (dispatch: AppDispatch, state: RootState) => {
    let response = [
      { time: '2019-04-11', value: 80.01 },
      { time: '2019-04-12', value: 96.63 },
      { time: '2019-04-13', value: 76.64 },
      { time: '2019-04-14', value: 81.89 },
      { time: '2019-04-15', value: 74.43 },
      { time: '2019-04-16', value: 80.01 },
      { time: '2019-04-17', value: 96.63 },
      { time: '2019-04-18', value: 76.64 },
      { time: '2019-04-19', value: 81.89 },
      { time: '2019-04-20', value: 74.43 },
    ]
    dispatch(graphicActionCreator.setLiquidityGraphic(response))
  },
  getVolumeGraphic: (firstTokenAddress: string, secondTokenAddress: string) => async (dispatch: AppDispatch, state: RootState) => {
    let response = [
      { time: '2019-04-11', value: 80.01 },
      { time: '2019-04-12', value: 96.63 },
      { time: '2019-04-13', value: 76.64 },
      { time: '2019-04-14', value: 81.89 },
      { time: '2019-04-15', value: 74.43 },
      { time: '2019-04-16', value: 80.01 },
      { time: '2019-04-17', value: 96.63 },
      { time: '2019-04-18', value: 76.64 },
      { time: '2019-04-19', value: 81.89 },
      { time: '2019-04-20', value: 74.43 },
    ]
    dispatch(graphicActionCreator.setVolumeGraphic(response))
  }
}
