import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Arrow } from 'components/Icons';
import cover1 from 'pages/HomePage/assets/cover1.png';
import cover2 from 'pages/HomePage/assets/cover2.png';
import cover3 from 'pages/HomePage/assets/cover3.png';
import cover4 from 'pages/HomePage/assets/cover4.png';
import cover5 from 'pages/HomePage/assets/cover5.png';
import cover6 from 'pages/HomePage/assets/cover6.png';
import { SecondsContext } from 'App';
import { useDispatch } from 'react-redux';
import { swapActionCreator } from 'store/reducers/swap/action-creators';
import useWalletSelector from 'hooks/useWalletSelector';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import { getTokenInfoByAddress, getTokenInfoByName } from 'utils/getTokenInfo'; import { coinIcons } from 'components/CoinIcons';
import { useHistory } from 'react-router-dom';
import s from './IntroSlider.module.css'

SwiperCore.use([Pagination]);

const IntroSlider: FunctionComponent = () => {
	const [swiper, setSwiper] = useState<SwiperCore>();
	const slides = [
	{token0: 'exon', token1: 'usdt'},
	{token0: 'trx', token1: 'usdt'},
	{token0: 'exon', token1: 'trx'},
	{token0: 'nft', token1: 'trx'},
	{token0: 'btc', token1: 'usdt'},
	{token0: 'jst', token1: 'trx'},
	{token0: 'ht', token1: 'usdt'},
	{token0: 'usdt', token1: 'usdc'},
	'exchange1',
	'exchange2',
	cover4,
	cover5,
	cover6
	];
	const dispatch = useDispatch()
		const { isWallet } = useWalletSelector(state => state.WalletReducer)
		const [isTouched, setIsTouched] = useState(false)
		const { seconds } = useContext(SecondsContext)
		const history = useHistory()

		// useEffect(() => {
		//   if(!isTouched && (seconds % 4 === 0)) swiper?.slideNext()
		// }, [seconds])
		return (
				<div className="relative flex items-center gap-4">
				<div
				className="hidden -mt-2 lg:block"
				onClick={() => {
				setIsTouched(true)
				swiper?.slidePrev()
				}}
				>
				<Arrow className="w-8 h-8" />
				</div>
				<Swiper
				onTouchEnd={ () => setIsTouched(true)}
				className="relative w-full mt-7 "
				loop
				pagination={{
clickable: true,
el: '.swiper-pagination',
type: 'bullets',
}}
breakpoints={{
	// when window width is >= 640px
640: {
slidesPerView: 1,
		 },
			 // when window width is >= 768px
768: {
slidesPerView: 2,
		 },
		 // when window width is >= 768px
1024: {
slidesPerView: 4,
			},
}}
spaceBetween={20}
onSwiper={swiper => setSwiper(swiper)}
>
{slides.map(slide => (
			<SwiperSlide
			className="relative flex items-center justify-center overflow-hidden bg-cardBg rounded-3xl"
			style={{ minHeight: 100, height: 'unset' }}
			>
			{slide === 'exchange2' 
			? <Exchange2 dispatch={dispatch} isWallet={isWallet} />
			: slide === 'exchange1'
			? <Exchange1 dispatch={dispatch} isWallet={isWallet} />
			: typeof(slide) !== 'string' 
			? <Trade history={history} token0={slide.token0} token1={slide.token1} />
			: <img
			src={slide}
			alt="Slide"
			style={{
			// height: '100vh',
			//position: 'absolute',
height: '100%',
width: '100%',
}}
/>}
</SwiperSlide>
))}
<div className="mt-5 swiper-pagination"></div>
</Swiper>
<div
className="hidden -mt-2 lg:block"
onClick={() => {
	setIsTouched(true)
		swiper?.slideNext()}
}
>
<Arrow className="w-8 h-8 transform -rotate-180" />
</div>
</div>
);
};

export default IntroSlider;

type Props = {
	isWallet?: boolean,
	dispatch?: any,
	token0?: string,
	token1?: string,
	history?: any
}

const Exchange2: FunctionComponent<Props> = ({isWallet, dispatch}) => {

	return (
			<div style={{background: 'linear-gradient(135deg,#fdd80750 20%, transparent 100%)'}}  className="flex flex-col items-center justify-center w-full h-full px-2 text-center py-9">
			<h1 className='mb-8 text-base font-medium'>CHANGE EXR2 TO EXON</h1>
			{isWallet 
			? <button onClick={() => dispatch(swapActionCreator.swapExr2toExon())} className='px-4 py-2 text-base font-normal text-black rounded-lg outline-none bg-yellow'>Exchange</button>
			: <ConnectWalletButton />}
			</div>
			)
}

const Exchange1: FunctionComponent<Props> = ({isWallet, dispatch}) => {

	return (
			<div style={{background: 'linear-gradient(135deg,#fdd80750 20%, transparent 100%)'}}  className="flex flex-col items-center justify-center w-full h-full px-2 text-center py-9">
			<h1 className='mb-8 text-base font-medium'>CHANGE EXR1 TO EXON</h1>
			{isWallet 
			? <button onClick={() => dispatch(swapActionCreator.swapExr1toExon())} className='px-4 py-2 text-base font-normal text-black rounded-lg outline-none bg-yellow'>Exchange</button>
			: <ConnectWalletButton />}
			</div>
			)
}

const Trade: FunctionComponent<Props> = ({token0, token1, history}) => {
	return (
			<div style={{background: 'linear-gradient(135deg,#fdd80750 20%, transparent 100%)'}}  className="relative flex flex-col items-center justify-center w-full h-full px-2 text-center py-9">
			<div className="opacity-20">
			<img
			className={` ${s.pair__img} ${s.pair__left}  w-64 h-64 absolute z-10`}
			src={coinIcons[getTokenInfoByName(token0 || 'exon')?.icon]}
			alt=""
			/>
			<img
			className={` ${s.pair__img} ${s.pair__right} w-64 h-64 absolute`}
			src={coinIcons[getTokenInfoByName(token1 || 'usdt')?.icon]}
			alt=""
			/>
			</div>
			<div className="z-20">
			<h1 className='mb-6 text-xl font-bold'>{getTokenInfoByName(token0 || 'exon')?.name}-{getTokenInfoByName(token1 || 'usdt')?.name}</h1>
			<button onClick={() => history.push(`/swap/${token0}-${token1}`)} className='px-4 py-2 text-base font-normal text-black rounded-lg outline-none bg-yellow'>Trade</button>
			</div>

			</div>
			)
}
