import {
  LiquidityAction,
  LiquidityEnum,
  liquidityMainStateType,
  LiquidityPairsType,
  liquidityStatesType,
} from "./types";
import { AppDispatch, getRootState } from "store";
// import IExonswapV2Pair from 'contracts/IExonswapV2Pair.json';
import IExonswapV2Factory from "contracts/ExonswapV2Factory.json";
import ExonswapV2SwapRouter from "contracts/ExonswapV2SwapRouter.json";
import ITRC20 from "contracts/ITRC20.json";
import Price from "contracts/Price.json";
import Config from "config";
import parseResult from "utils/parseResult";
import { getTokenInfoByAddress } from "utils/getTokenInfo";
import sleep from "utils/sleep";
import { ApplicationAcionCreator } from "../application/action-creator";
import initTronstack from "utils/tronWeb/initTronstack";
import { getTokensByAddress } from "api/api";
import handleTokenInfo from "utils/handleTokenInfo";
// import { TokenInfo } from '../types';

export const LiquidityActionCreator = {
  setLiquidityState: (currentState: liquidityStatesType): LiquidityAction => ({
    type: LiquidityEnum.SET_LIQUIDITY_STATE,
    payload: currentState, }),
  setLiquidityMainState: (
    currentMainState: liquidityMainStateType
  ): LiquidityAction => ({
    type: LiquidityEnum.SET_LIQUIDITY_MAIN_STATE,
    payload: currentMainState,
  }),
  setLiquidityPairs: (currentPairs: LiquidityPairsType[]): LiquidityAction => ({
    type: LiquidityEnum.SET_LIQUIDITY_PAIRS,
    payload: currentPairs,
  }),
  setIsFetching: (loaded: boolean): LiquidityAction => ({
    type: LiquidityEnum.SET_IS_FETCHING,
    payload: loaded,
  }),
  setIsEmpty: (isEmpty: boolean): LiquidityAction => ({
    type: LiquidityEnum.SET_IS_EMPTY,
    payload: isEmpty,
  }),
  resetLiquidityPairs: (): LiquidityAction => ({
    type: LiquidityEnum.RESET_LIQUIDITY_PAIRS,
  }),
  checkLiquidity: () => async (dispatch: AppDispatch, store: getRootState) => {
    const tronWeb = await store().WalletReducer.tronWeb;
    const factoryContract = tronWeb.contract(
      IExonswapV2Factory.abi,
      Config().FACTORY_CONTRACT
    );
    const pair = await factoryContract.allPairsLength().call();
  },
  getPriceInUsd:
    (firstTokenAddress: string, secondTokenAddress: string) =>
    async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await store().WalletReducer.tronWeb;
      if (tronWeb !== null) {
        const routeSwapContract = await tronWeb.contract(
          ExonswapV2SwapRouter.abi,
          Config().ROUTER_CONTRACT
        );
      }
    },
  getFooterLiquidity:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await initTronstack()
      const tronstack = await initTronstack();
      let totalLiquidity = 0;
      let currentAddress;
      let token0Trx;
      let token0;
      let token1Trx;
      let token1;
      let reservesTrx;
      let reserves;
      if (tronstack) {
        const priceContract = await tronstack.contract(
          Price.abi,
          Config().ORACLE_LIQUIDITY_AND_SWAP
        );
        const factoryContract = await tronstack.contract(
          IExonswapV2Factory.abi,
          Config().FACTORY_CONTRACT
        );
        const pairLength = await factoryContract.allPairsLength().call();
        for (var i = pairLength.toNumber() - 1; i >= 0; i--) {
          await sleep(1000);
          currentAddress = await factoryContract.allPairs(i).call();
          token0Trx =
            await tronstack.transactionBuilder.triggerConstantContract(
              currentAddress,
              "token0()",
              {},
              []
            );
          token0 = await parseResult(
            ["address"],
            "0x" + token0Trx.constant_result[0]
          );
          token1Trx =
            await tronstack.transactionBuilder.triggerConstantContract(
              currentAddress,
              "token1()",
              {},
              []
            );
          token1 = await parseResult(
            ["address"],
            "0x" + token1Trx.constant_result[0]
          );
          reservesTrx =
            await tronstack.transactionBuilder.triggerConstantContract(
              currentAddress,
              "getReserves()",
              {},
              []
            );
          reserves = await parseResult(
            ["uint256", "uint256"],
            "0x" + reservesTrx.constant_result[0]
          );
          if (token0 && token1) {
            let firstTokenPrice = await priceContract
              .getTokenPriceInUSD(tronstack.address.fromHex(token0[0]))
              .call();
            firstTokenPrice = await handleTokenInfo(tronstack.address.fromHex(token0[0]), 'fromSun', BigInt(firstTokenPrice))
            let secondTokenPrice = await priceContract
              .getTokenPriceInUSD(tronstack.address.fromHex(token1[0]))
              .call();
            secondTokenPrice = await handleTokenInfo(tronstack.address.fromHex(token1[0]), 'fromSun', BigInt(secondTokenPrice))
            let firstTokenAmount =
              (await firstTokenPrice) *
              (await handleTokenInfo(
                tronstack.address.fromHex(token0[0]),
                "fromSun",
                BigInt(reserves[0])
              ));
            let secondTokenAmount =
              secondTokenPrice *
              (await handleTokenInfo(
                tronstack.address.fromHex(token1[0]),
                "fromSun",
                BigInt(reserves[1])
              ));

            totalLiquidity += firstTokenAmount + secondTokenAmount;
          }
        }
        dispatch(ApplicationAcionCreator.setFooterLiquidity(totalLiquidity));
      }
    },
  getCurrentLiquidity:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const currentTokenId =  await store().AccountReducer.currentAccount;
        //await store().AccountReducer.currentAccount;
      if (!currentTokenId) return;
      dispatch(LiquidityActionCreator.setIsFetching(true));
      const tronWeb = store().WalletReducer.tronWeb;
      // console.log('POLUCHAJU LIQUIDNOST');
      const tronstack = await initTronstack();
      if (tronWeb !== null) {
        const factoryContract = tronstack.contract(
          IExonswapV2Factory.abi,
          Config().FACTORY_CONTRACT
          );
          const swapContract = tronstack.contract(
            ExonswapV2SwapRouter.abi,
            Config().ROUTER_CONTRACT
            );
            const PriceContract = await tronstack.contract(
              Price.abi,
              Config().ORACLE_LIQUIDITY_AND_SWAP
              );
        const pairLength = await factoryContract.allPairsLength().call();
        var pairInfo = [];
        var currentAddress;
        var balanceLP;
        var personalLiquidityToken0;
        var personalLiquidityToken1;
        var trxPairLiquidity;
        var trxPairPersonalLiquidity0;
        var trxPairPersonalLiquidity1;
        var trxTotalSupply;
        let totalSupply;
        var token0Trx;
        var token1Trx;
        var token1: any[];
        var token0: any[];
        var reservesTrx;
        var reserves;
        // var tokenInfo: TokenInfo;
        for (var i = pairLength.toNumber() - 1; i >= 0; i--) {
          await sleep(1000);
          currentAddress = await factoryContract.allPairs(i).call();

          trxPairPersonalLiquidity0 =
            await tronstack.transactionBuilder.triggerConstantContract(
              currentAddress,
              "personalLiquidity(uint256,uint256)",
              {},
              [
                { type: "uint256", value: currentTokenId },
                { type: "uint256", value: 0 },
              ]
            );
          personalLiquidityToken0 = await parseResult(
            ["uint256"],
            "0x" + trxPairPersonalLiquidity0.constant_result[0]
          );
          //  console.log(
          //   'PERSONAL LIQUIDITY TOKEN0',
          //   getTokenInfoByAddress(currentAddress),
          //   personalLiquidityToken0[0].toNumber(),
          // );
          trxPairPersonalLiquidity1 =
            await tronstack.transactionBuilder.triggerConstantContract(
              currentAddress,
              "personalLiquidity(uint256,uint256)",
              {},
              [
                { type: "uint256", value: currentTokenId },
                { type: "uint256", value: 1 },
              ]
            );
          personalLiquidityToken1 = await parseResult(
            ["uint256"],
            "0x" + trxPairPersonalLiquidity1.constant_result[0]
          );
          // console.log(
          //  'PERSONAL LIQUIDITY TOKEN1',
          //  getTokenInfoByAddress(currentAddress),
          //  personalLiquidityToken1[0].toNumber(),
          //);
          // pair liquidity
          trxPairLiquidity =
            await tronstack.transactionBuilder.triggerConstantContract(
              currentAddress,
              "nftBalanceOf(uint256)",
              {},
              [{ type: "uint256", value: currentTokenId }]
            );
          balanceLP = await parseResult(
            ["uint256"],
            "0x" + trxPairLiquidity.constant_result[0]
          );
          trxTotalSupply =
            await tronstack.transactionBuilder.triggerConstantContract(
              currentAddress,
              "totalSupply()",
              {},
              []
            );
          balanceLP = await parseResult(
            ["uint256"],
            "0x" + trxPairLiquidity.constant_result[0]
          );
          totalSupply = await parseResult(
            ["uint256"],
            "0x" + trxTotalSupply.constant_result[0]
          );
          token0Trx =
            await tronstack.transactionBuilder.triggerConstantContract(
              currentAddress,
              "token0()",
              {},
              []
            );
          token0 = await parseResult(
            ["address"],
            "0x" + token0Trx.constant_result[0]
          );
          token1Trx =
            await tronstack.transactionBuilder.triggerConstantContract(
              currentAddress,
              "token1()",
              {},
              []
            );
          token1 = await parseResult(
            ["address"],
            "0x" + token1Trx.constant_result[0]
          );
          reservesTrx =
            await tronstack.transactionBuilder.triggerConstantContract(
              currentAddress,
              "getReserves()",
              {},
              []
            );
          reserves = await parseResult(
            ["uint256", "uint256"],
            "0x" + reservesTrx.constant_result[0]
          );
          let token0Price;
          try {
            token0Price = await PriceContract.getTokenPriceInUSD(
              tronWeb.address.fromHex(token0)[0]
            ).call();
            token0Price = tronWeb.fromSun(token0Price.toNumber())
          } catch (error) {
            console.log(error);
          }

          let token1Price;
          try {
            token1Price = await PriceContract.getTokenPriceInUSD(
              tronWeb.address.fromHex(token1)[0]
            ).call();
            token1Price = tronWeb.fromSun(token1Price.toNumber())

          } catch (error) {
            console.log(error);
          }
          // get reserve0 & reserve1
          // address of token0 and token1, balanceOf(currentAddress) = balance token0, balance token1
          // (balanceOf(currentAddress)[0] - personalLiquidity0 - reserve0(1215)) * (liqudity / totalSupply)
          const FirstTokenContract = tronstack.contract(ITRC20.abi, token0[0]);
          const SecondTokenContract = tronstack.contract(ITRC20.abi, token1[0]);
          let firstBalance;
          try {
            firstBalance = await FirstTokenContract.balanceOf(
              currentAddress
            ).call();
          } catch (error) {
            console.log(error);
            firstBalance = 0;
          }
          firstBalance = await handleTokenInfo(
            tronWeb.address.fromHex(token0[0]),
            "fromSun",
            firstBalance
          );

          let secondBalance;
          try {
            secondBalance = await SecondTokenContract.balanceOf(
              currentAddress
            ).call();
          } catch (error) {
            console.log(error);
            secondBalance = 0;
          }
          secondBalance = await handleTokenInfo(
            tronWeb.address.fromHex(token1[0]),
            "fromSun",
            secondBalance
          );
          // let firstTotalFee = (firstBalance - tronWeb.fromSun(personalLiquidityToken0[0].toNumber()) - tronWeb.fromSun(reserves[0].toNumber()) ) * (tronWeb.fromSun(balanceLP[0].toNumber()) / tronWeb.fromSun(totalSupply[0].toNumber()))
          if (!!BigInt(balanceLP[0])) {
            // console.log(
            //   "current pair: " + i,
            //   "all pairs: " + pairLength.toNumber(),
            //   "isLp: " + !!balanceLP[0].toNumber()
            // );

            if (
              !getTokenInfoByAddress(tronWeb.address.fromHex(token0[0])) &&
              !JSON.parse(localStorage.getItem("tokenList") || "[]").filter(
                (el: {
                  address: { address: string; icon: string; name: string };
                }) => el.address === tronWeb.address.fromHex(token0[0])
              ).length
            ) {
              let result;
              try {
                result = await getTokensByAddress(
                  tronWeb.address.fromHex(token0[0])
                );
              } catch (error) {
                console.log(error);
                return;
              }
              let newTokenList = [
                ...JSON.parse(localStorage.getItem("tokenList") || "[]"),
                ...result.data.trc20_tokens.map(
                  (el: {
                    contract_address: any;
                    symbol: any;
                    icon_url: any;
                    decimals: any;
                  }) => {
                    return {
                      address: el.contract_address,
                      name: el.symbol,
                      icon: el.icon_url,
                      decimals: 6,
                    };
                  }
                ),
              ];
              for (let i = 0; i < newTokenList.length; i++) {
                const tokenContract = await tronWeb.contract(
                  ITRC20.abi,
                  newTokenList[i].address
                );
                let decimal;
                try {
                  decimal = await tokenContract.decimals().call();
                } catch (error) {
                  console.log(error);
                  decimal = 6;
                }
                newTokenList[i].decimals = decimal;
              }
              localStorage.setItem("tokenList", JSON.stringify(newTokenList));
            }

            if (
              !getTokenInfoByAddress(tronWeb.address.fromHex(token1[0])) &&
              !JSON.parse(localStorage.getItem("tokenList") || "[]").filter(
                (el: {
                  address: { address: string; icon: string; name: string };
                }) => el.address === tronWeb.address.fromHex(token1[0])
              ).length
            ) {
              let result;
              try {
                result = await getTokensByAddress(
                  tronWeb.address.fromHex(token1[0])
                );
              } catch (error) {
                console.log(error);
                return;
              }
              let newTokenList = [
                ...JSON.parse(localStorage.getItem("tokenList") || "[]"),
                ...result.data.trc20_tokens.map(
                  (el: {
                    contract_address: any;
                    symbol: any;
                    icon_url: any;
                    decimals: any;
                  }) => {
                    return {
                      address: el.contract_address,
                      name: el.symbol,
                      icon: el.icon_url,
                      decimals: 6,
                    };
                  }
                ),
              ];
              for (let i = 0; i < newTokenList.length; i++) {
                const tokenContract = await tronWeb.contract(
                  ITRC20.abi,
                  newTokenList[i].address
                );
                let decimal;
                try {
                  decimal = await tokenContract.decimals().call();
                } catch (error) {
                  console.log(error);
                  decimal = 6;
                }
                newTokenList[i].decimals = decimal;
              }
              localStorage.setItem("tokenList", JSON.stringify(newTokenList));
            }
            let firstTokenReserveFromSun = await handleTokenInfo(
              tronWeb.address.fromHex(token0[0]),
              "fromSun",
              reserves[0]
            )
            let secondTokenReserveFromSun = await handleTokenInfo(
              tronWeb.address.fromHex(token0[0]),
              "fromSun",
              reserves[1]
            )
            totalSupply = await handleTokenInfo(
              '',
              'fromSun',
              Number(BigInt(totalSupply[0]))
            )
            let usdPerLp = (firstTokenReserveFromSun * token0Price + secondTokenReserveFromSun * token1Price) / totalSupply
            pairInfo.push({
              address: tronWeb.address.fromHex(currentAddress),
              personalLiquidityToken0: tronWeb.fromSun(
                personalLiquidityToken0[0].toNumber()
              ),
              personalLiquidityToken1: tronWeb.fromSun(
                personalLiquidityToken1[0].toNumber()
              ),
              liquidity: await handleTokenInfo('', 'fromSun', BigInt(balanceLP[0])),
              totalSupply: totalSupply,
              token0: token0[0],
              token1: token1[0],
              firstTokenBalance: firstBalance,
              secondTokenBalance: secondBalance,
              token0Amount: await handleTokenInfo(
                tronWeb.address.fromHex(token0[0]),
                "fromSun",
                reserves[0]
              ),
              token1Amount: await handleTokenInfo(
                tronWeb.address.fromHex(token1[0]),
                "fromSun",
                reserves[1]
              ),
              token0Info:
                getTokenInfoByAddress(tronWeb.address.fromHex(token0[0])) ||
                JSON.parse(localStorage.getItem("tokenList") || "[]").filter(
                  (el: {
                    address: { address: string; icon: string; name: string };
                  }) => el.address === tronWeb.address.fromHex(token0[0])
                )[0],
              token1Info:
                getTokenInfoByAddress(tronWeb.address.fromHex(token1[0])) ||
                JSON.parse(localStorage.getItem("tokenList") || "[]").filter(
                  (el: {
                    address: { address: string; icon: string; name: string };
                  }) => el.address === tronWeb.address.fromHex(token1[0])
                )[0],
              usdPerLp: +usdPerLp.toFixed(18)
            });
          }
          if (i === 0) {
            dispatch(LiquidityActionCreator.setIsFetching(false));
            if (pairInfo.length === 0) {
              dispatch(LiquidityActionCreator.setIsEmpty(true));
            } else dispatch(LiquidityActionCreator.setIsEmpty(false));
          }
        }
        dispatch(LiquidityActionCreator.setLiquidityPairs(pairInfo));
      }
    },
};
