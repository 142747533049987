import React, { FunctionComponent, useEffect, useState } from 'react';
import useWalletSelector from 'hooks/useWalletSelector';
import { coinIcons } from 'components/CoinIcons';
import Modal from 'components/Modal';
import { useDispatch } from 'react-redux';
import { getTokenInfoByAddress } from "utils/getTokenInfo";
import ButtonProcess from 'components/Button/ButtonProcess';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import { launchpadActionCreator } from 'store/reducers/launchpad/action-creator';

const ConfirmBuy: FunctionComponent = () => {
  const dispatch = useDispatch()

  const { launchpadState } = useWalletSelector(state => state.launchpadReducer)
  const {activeIdo, buyDetails } = useWalletSelector(state => state.launchpadReducer)

  const handlConfirm = () => {
        dispatch(launchpadActionCreator.setLaunchpadState('initial'))
        dispatch(launchpadActionCreator.buyIdoTokens())
  };

  const handlCloseModal = () => {
    dispatch(launchpadActionCreator.setLaunchpadState('initial'))
    dispatch(ApplicationAcionCreator.setTransactionStatus(false))
  };


  return (
    <>
      {launchpadState === 'confirmBuy' && (
        <Modal closeModal={handlCloseModal}>
          <div className="relative text-title text-center text-2xl sm:text-4xl">
            Buy IDO tokens 
          </div>
          <div className="mt-2 sm:mt-5">
            {/* from */}
            <div className="flex items-center gap-2 justify-center">
              <div>
                <img
                  src={coinIcons[getTokenInfoByAddress(buyDetails.selectedToken).icon]}
                  alt="icon"
                  className="w-8 h-8"
                />
              </div>
              <div className="text-lightYellow text-2xl sm:text-3xl">
                      {buyDetails.amountIn}
              </div>
              <div
                className="text-description font-light text-xl sm:text-2xl "
                style={{ minWidth: '3rem' }}
              >
               {getTokenInfoByAddress(buyDetails.selectedToken).name}
              </div>
            </div>
            {/* arrow */}
            <span className="inline-block relative left-1/2 transform rotate-90 -translate-x-1/2 text-yellow">{`->`}</span>
            {/* to */}
            <div className="flex justify-center items-center gap-2">
              <div>
                <img
                  src={activeIdo.saleTokenInfo.icon}
                  alt="icon"
                  className="w-8 h-8"
                />
              </div>
              <div className="text-lightYellow text-2xl sm:text-3xl">
                {buyDetails.amountOut}
              </div>
              <div
                className="text-description font-light text-xl sm:text-2xl"
                style={{ minWidth: '3rem' }}
              >
                {activeIdo.saleTokenInfo.symbol}
              </div>
            </div>
            {/* text */}
            <div className="flex flex-col gap-4 mt-2 sm:mt-8">
              <div className="flex flex-col sm:flex-row gap-2 justify-center">
                <div className="text-title text-base sm:text-xl">Price:</div>
                <div className="text-title text-base sm:text-xl">
                  <div>
                    1 {getTokenInfoByAddress(buyDetails.selectedToken).name} ={' '}
                    {(buyDetails.amountOut/ buyDetails.amountIn).toFixed(4)}{' '}
                    {activeIdo.saleTokenInfo.symbol}
                  </div>
                  <div>
                    1 {activeIdo.saleTokenInfo.symbol} ={' '}
                    {(buyDetails.amountIn/ buyDetails.amountOut).toFixed(4)}{' '}
                    {getTokenInfoByAddress(buyDetails.selectedToken).name}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:mb-3 mt-6 sm:mt-4 text-center">
            <ButtonProcess
              name="Confirm"
              classname="w-full inline-flex justify-center 
                                        rounded-md border border-transparent shadow-sm px-6 py-2 
                                        bg-yellow text-base font-medium text-mainBlack
                                        hover:bg-black-700 focus:outline-none focus:ring-2 
                                        focus:ring-offset-2 
                                        focus:ring-red-500 
                                        sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handlConfirm}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ConfirmBuy;
