import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';
import { launchpoolActionCreator } from 'store/reducers/launchpool/action-creator';

type Props = {
  type: string;
}

const Checkbox: FunctionComponent<Props> = ({type}) => {
  const dispatch = useDispatch()

  const defineFilterFor = (type: string) => {
    switch (type) {
      case 'farming':
        return dispatch(farmingActionCreator.showStakedOnly())
      case 'launchpool':
        return dispatch(launchpoolActionCreator.showStakedOnly())
      default:
        return
    }
  }

  return (
    <div className="flex items-center mr-0">
      <span className="text-xl text-title mr-5 whitespace-nowrap">Staked only</span>
      <div className="inline-flex items-center justify-center">
        <label htmlFor="toggle" className="flex items-center cursor-pointer">
          <div className="relative">
            <input onChange={() => defineFilterFor(type)} type="checkbox" id="toggle" className="sr-only" />
            <div className="block bg-darkGray w-14 h-8 rounded-full"></div>
            <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
