import Info from './components/Info';
import Pool from './components/Pool';

import FeaturedPools from './components/FeaturedPools';
import Layout from 'components/Layout';
import {Redirect} from 'react-router';
import {useDispatch} from 'react-redux';
import {useEffect} from 'react';
import {launchpadActionCreator} from 'store/reducers/launchpad/action-creator';

export function Launchpad() {

	const dispatch = useDispatch()

  useEffect(() => {
    dispatch(launchpadActionCreator.getLaunchpadPools())
    console.log('trigger');
  }, [])

  return (
    <Layout>
      {/*<Redirect to={'/exonnft'} />*/} 
      <div className="container flex flex-col lg:mt-6">
       <Info />
        <Pool />
        {/*<h1 className='relative mb-10 text-4xl font-medium text-lightGrey ml-7'>Featured pools</h1>*/}
      </div>
      {/*<FeaturedPools />*/}
    </Layout>
  );
}
