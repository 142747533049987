import { AppDispatch, getRootState } from "store";
import { SwapAction, SwapSetting, swapState, SwapStateEmun } from "./types";
import { TokenInfo } from "../types";
import ITRX20 from "../../../contracts/ITRC20.json";
import ExonswapV2Factory from "../../../contracts/ExonswapV2Factory.json";
import ExonswapV2SwapRouter from "contracts/ExonswapV2SwapRouter.json";
import ExchangeTokensEXR2 from "contracts/ExchangeTokensEXR2.json";
import ExchangeTokensEXR1 from "contracts/ExchangeTokensEXR1.json";
import Config from "../../../config";
import { ApplicationAcionCreator } from "../application/action-creator";
import sleep from "utils/sleep";
import handleTokenInfo from "utils/handleTokenInfo";
import { getTransactionResult } from "utils/transaction/getTransactionResult";
import initTronstack from "utils/tronWeb/initTronstack";

export const swapActionCreator = {
    setSwapState: (proccessState: boolean) => ({
        type: SwapStateEmun.SET_PROCESS,
        payload: proccessState,
    }),
    setFirstTokenAmount: (firstTokenAmount: number): SwapAction => ({
        type: SwapStateEmun.SET_FIRST_TOKEN_AMOUNT,
        payload: firstTokenAmount,
    }),
    setSecondTokenAmount: (secondTokenAmount: number): SwapAction => ({
        type: SwapStateEmun.SET_SECOND_TOKEN_AMOUNT,
        payload: secondTokenAmount,
    }),
    setPriceFirstInSecond: (priceFInS: number) => ({
        type: SwapStateEmun.SET_PRICE_FIRST_IN_SECOND,
        payload: priceFInS,
    }),
    setPriceSecondInFirst: (priceSInF: number) => ({
        type: SwapStateEmun.SET_PRICE_SECOND_IN_FIRST,
        payload: priceSInF,
    }),
    setFirstTokenInfo: (firstTokenInfo: TokenInfo) => ({
        type: SwapStateEmun.SET_FIRST_TOKEN_INFO,
        payload: firstTokenInfo,
    }),
    setSecondTokenInfo: (secondTokenInfo: TokenInfo) => ({
        type: SwapStateEmun.SET_SECOND_TOKEN_INFO,
        payload: secondTokenInfo,
    }),
    setPriceImpact: (priceImpact: number): SwapAction => ({
        type: SwapStateEmun.SET_PRICE_IMPACT,
        payload: priceImpact,
    }),
    setPriceFee: (priceFee: number): SwapAction => ({
        type: SwapStateEmun.SET_SWAP_FEE,
        payload: priceFee,
    }),
    setConfirmSwap: (confirm: boolean) => ({
        type: SwapStateEmun.SET_CONFIRM_SWAP,
        payload: confirm,
    }),
    setFirstTokenApprove: (firstApprove: boolean) => ({
        type: SwapStateEmun.SET_FIST_TOKEN_APPROVE,
        payload: firstApprove,
    }),
    setSecondTokenApprove: (secondApprove: boolean) => ({
        type: SwapStateEmun.SET_SECOND_TOKEN_APPROVE,
        payload: secondApprove,
    }),
    setSwapSetting: (swapSetting: SwapSetting) => ({
        type: SwapStateEmun.SET_SWAP_SETTING,
        payload: swapSetting,
    }),
    setFirstTokenBalance: (firstTokenBalance: number): SwapAction => ({
        type: SwapStateEmun.SET_FIRST_TOKEN_BALANCE,
        payload: firstTokenBalance,
    }),
    setSecondTokenBalance: (secondTokenBalance: number): SwapAction => ({
        type: SwapStateEmun.SET_SECOND_TOKEN_BALANCE,
        payload: secondTokenBalance,
    }),
    setCurrentSwapState: (swapState: swapState): SwapAction => ({
        type: SwapStateEmun.SET_SWAP_STATE,
        payload: swapState,
    }),
    setSwapSettingModal: (swapSettingModalState: boolean): SwapAction => ({
        type: SwapStateEmun.SET_SWAP_SETTING_MODAL,
        payload: swapSettingModalState,
    }),
    setTokensReserve: (
        firstTokenReserve: number,
        secondTokenReserve: number
    ): SwapAction => ({
        type: SwapStateEmun.SET_TOKENS_RESERVE,
        payload: { firstTokenReserve, secondTokenReserve },
    }),
    setTokenFee: () => async (dispatch: AppDispatch, store: getRootState) => {
        //const tronWeb = await initTronstack()
        const tronWeb = (window as any).tronWeb;
        const firstTokenInfo = store().applicationReducer.firstToken;
        const secondTokenInfo = store().applicationReducer.secondToken;
        if (tronWeb) {
            const resultSwapFee = await tronWeb
                .contract(ExonswapV2Factory.abi, Config().FACTORY_CONTRACT)
                .getPairFee(firstTokenInfo.address, secondTokenInfo.address)
                .call();
            const swapFee = resultSwapFee.toNumber() / 1000;
            dispatch(swapActionCreator.setPriceFee(swapFee));
        }
    },
    setSwappedTokens: (
        firstTokenInfo: TokenInfo,
        secondTokenInfo: TokenInfo,
        firstTokenReserve: number,
        secondTokenReserve: number,
        firstTokenAmount: number,
        secondTokenAmount: number,
        firstTokenBalance: number,
        secondTokenBalance: number
    ): SwapAction => ({
        type: SwapStateEmun.SET_SWAPPED_TOKENS,
        payload: {
            firstTokenInfo,
            secondTokenInfo,
            firstTokenReserve,
            secondTokenReserve,
            firstTokenAmount,
            secondTokenAmount,
            firstTokenBalance,
            secondTokenBalance,
        },
    }),
    calculatePriceImpact:
        () => async (_dispatch: AppDispatch, store: getRootState) => {
            //const tronWeb = store().WalletReducer.tronWeb;
            const tronWeb = (window as any).tronWeb;
            // get  token 1 info
            const firstTokenInfo = store().applicationReducer.firstToken;
            const firstTokenAmount = store().swapReducer.firstTokenAmount;
            const secondTokenAmount = store().swapReducer.secondTokenAmount;
            const secondTokenInfo = store().applicationReducer.secondToken;
            if (tronWeb != null) {
                const routeSwapContract = await tronWeb.contract(
                    ExonswapV2SwapRouter.abi,
                    Config().ROUTER_CONTRACT
                );
                if (
                    firstTokenInfo.address !== undefined &&
                    secondTokenInfo.address !== undefined
                ) {
                    const tokenList = [firstTokenInfo.address, secondTokenInfo.address];
                    try {
                        var reserves = await routeSwapContract
                            .getPairReserves(tokenList[0], tokenList[1])
                            .call();
                    } catch (e) {
                        console.log(e);
                    }
                    var priceImpact;
                    if (reserves !== undefined) {
                        const reserve0InSun = reserves[0];
                        const reserve1InSun = reserves[1];
                        const prevPrice = reserve0InSun / reserve1InSun;
                        priceImpact =
                            ((firstTokenAmount / secondTokenAmount - prevPrice) / prevPrice) *
                            100;
                        // console.log('test impact', priceImpact)
                    } else {
                        priceImpact = 0;
                    }
                    _dispatch(swapActionCreator.setPriceImpact(priceImpact));
                }
            }
            _dispatch(swapActionCreator.setCurrentSwapState("swapConfirm"));
        },
    setSwapTokenBalances:
        () => async (dispatch: AppDispatch, store: getRootState) => {
            //const tronWeb = await initTronstack()
            const tronWeb = (window as any).tronWeb;
            const isWallet = store().WalletReducer.isWallet;
            // get  token 1 info
            if (!tronWeb) return;
            if (!isWallet) return;
            const firstTokenInfo = store().applicationReducer.firstToken;
            if (
                firstTokenInfo.name !== "TRX" &&
                firstTokenInfo !== undefined &&
                tronWeb != null &&
                Object.keys(firstTokenInfo).length > 0
            ) {
                const tokenContract = await tronWeb.contract(
                    ITRX20.abi,
                    firstTokenInfo.address
                );
                const balance = await tokenContract
                    .balanceOf(tronWeb.defaultAddress.base58)
                    .call();
                dispatch(
                    swapActionCreator.setFirstTokenBalance(
                        await handleTokenInfo(firstTokenInfo.address, "fromSun", balance)
                    )
                );
            } else if (firstTokenInfo.name === "TRX") {
                const trxBalance = await tronWeb.trx.getBalance(
                    tronWeb.defaultAddress.base58
                );
                dispatch(
                    swapActionCreator.setFirstTokenBalance(tronWeb.fromSun(trxBalance))
                );
            } else {
                dispatch(swapActionCreator.setFirstTokenBalance(0));
            }
            const secondTokenInfo = store().applicationReducer.secondToken;
            if (
                secondTokenInfo.name !== "TRX" &&
                secondTokenInfo.address !== undefined &&
                tronWeb != null &&
                Object.keys(secondTokenInfo).length > 0
            ) {
                const tokenContractSecond = await tronWeb.contract(
                    ITRX20.abi,
                    secondTokenInfo.address
                );
                const balanceSecond = await tokenContractSecond
                    .balanceOf(tronWeb.defaultAddress.base58)
                    .call();
                dispatch(
                    swapActionCreator.setSecondTokenBalance(
                        await handleTokenInfo(
                            secondTokenInfo.address,
                            "fromSun",
                            balanceSecond
                        )
                    )
                );
            } else if (secondTokenInfo.name === "TRX") {
                dispatch(
                    swapActionCreator.setSecondTokenBalance(
                        tronWeb.fromSun(
                            await tronWeb.trx.getBalance(tronWeb.defaultAddress.base58)
                        )
                    )
                );
            } else {
                dispatch(swapActionCreator.setSecondTokenBalance(0));
            }
        },
    setSecondTokenPrice:
        () => async (dispatch: AppDispatch, store: getRootState) => {
            //const tronWeb = await initTronstack()
            const tronWeb = (window as any).tronWeb;
            const firstTokenInfo = store().applicationReducer.firstToken;
            const firstTokenAmount = store().swapReducer.firstTokenAmount;
            const secondTokenInfo = store().applicationReducer.secondToken;
            if (tronWeb != null) {
                const routeSwapContract = await tronWeb.contract(
                    ExonswapV2SwapRouter.abi,
                    Config().ROUTER_CONTRACT
                );
                if (
                    firstTokenInfo.address !== undefined &&
                    secondTokenInfo.address !== undefined
                ) {
                    const tokenList = [firstTokenInfo.address, secondTokenInfo.address];

                    try {
                        var amountOut = await routeSwapContract
                            .getAmountsOut(
                                await handleTokenInfo(
                                    firstTokenInfo.address,
                                    "toSun",
                                    firstTokenAmount
                                ),
                                tokenList
                            )
                            .call();
                    } catch (e) {
                        console.log(e);
                    }
                    console.log("AMOUNT OUT: ", amountOut);

                    if (amountOut !== undefined) {
                        const secondTokenAmount = await handleTokenInfo(
                            secondTokenInfo.address,
                            "fromSun",
                            amountOut[1] === undefined ? amountOut.amounts[1] : amountOut[1]
                        );
                        dispatch(swapActionCreator.setSecondTokenAmount(secondTokenAmount));
                    } else {
                        dispatch(swapActionCreator.setSecondTokenAmount(0));
                    }
                }
            }
        },
    setFirstTokenPrice:
        () => async (dispatch: AppDispatch, store: getRootState) => {
            //const tronWeb = await initTronstack()
            const tronWeb = (window as any).tronWeb;
            const firstTokenInfo = store().applicationReducer.firstToken;
            const secondTokenAmount = store().swapReducer.secondTokenAmount;
            const secondTokenInfo = store().applicationReducer.secondToken;
            const firstTokenBalance = store().swapReducer.firstTokenBalance;
            if (tronWeb != null) {
                const routeSwapContract = await tronWeb.contract(
                    ExonswapV2SwapRouter.abi,
                    Config().ROUTER_CONTRACT
                );
                if (
                    firstTokenInfo.address !== undefined &&
                    secondTokenInfo.address !== undefined
                ) {
                    const tokenList = [firstTokenInfo.address, secondTokenInfo.address];
                    try {
                        var amountIn = await routeSwapContract
                            .getAmountsIn(
                                await handleTokenInfo(
                                    secondTokenInfo.address,
                                    "toSun",
                                    secondTokenAmount
                                ),
                                tokenList
                            )
                            .call();
                    } catch (e) {
                        console.log(e);
                    }
                    console.log("test: ");

                    if (amountIn !== undefined) {
                        const firstTokenAmount = await handleTokenInfo(
                            firstTokenInfo.address,
                            "fromSun",
                            amountIn.amounts === undefined
                                ? amountIn[0].toNumber()
                                : amountIn.amounts[0].toNumber()
                        );
                        let firstTokenInput;
                        firstTokenInput = firstTokenAmount;
                        dispatch(swapActionCreator.setFirstTokenAmount(firstTokenInput));
                    } else {
                        dispatch(swapActionCreator.setFirstTokenAmount(0));
                    }
                }
            }
        },
    swapTokensToTokens:
        () => async (dispatch: AppDispatch, store: getRootState) => {
            //const tronWeb = await initTronstack()
            const tronWeb = store().WalletReducer.tronWeb;
            const firstTokenInfo = store().applicationReducer.firstToken;
            const secondTokenInfo = store().applicationReducer.secondToken;
            const firstTokenAmount = store().swapReducer.firstTokenAmount;
            const secondTokenAmount = store().swapReducer.secondTokenAmount;
            const tokenId = store().AccountReducer.currentAccount;
            const swapSetting = store().swapReducer.swapSetting;
            dispatch(
                ApplicationAcionCreator.setTransactionSteps([
                    `Approve ${firstTokenInfo.name}`,
                ])
            );
            if (
                firstTokenInfo.address !== "" &&
                firstTokenInfo !== undefined &&
                tronWeb != null &&
                Object.keys(firstTokenInfo).length > 0
            ) {
                const tokenContract = await tronWeb.contract(
                    ITRX20.abi,
                    firstTokenInfo.address
                );
                var resultApproveTrx;
                let firstTokenApproveAmount = await handleTokenInfo(
                    firstTokenInfo.address,
                    "toSun",
                    firstTokenAmount
                );
                try {
                    resultApproveTrx = await tokenContract
                        .approve(
                            Config().ROUTER_CONTRACT,
                            firstTokenApproveAmount.toString()
                        )
                        .send({ feeLimit: 500000000 });
                } catch (error: any) {
                    console.log(error);
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            true,
                            true,
                            "Approve",
                            "Error",
                            resultApproveTrx,
                            `${error.message}`
                        )
                    );
                    dispatch(swapActionCreator.setCurrentSwapState("initial"));
                    return;
                }
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Approve ${firstTokenInfo.name}`,
                        true,
                        false
                    )
                );
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        false,
                        true,
                        "Approve",
                        "Processing",
                        resultApproveTrx,
                        `Approve ${firstTokenInfo.name} for swap`
                    )
                );
                let result;

                if (await getTransactionResult(resultApproveTrx, tronWeb)) {
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            false,
                            true,
                            "Approve",
                            "Error",
                            resultApproveTrx,
                            `An error occured during approve`
                        )
                    );
                    dispatch(swapActionCreator.setCurrentSwapState("initial"));
                    return;
                }
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Approve ${firstTokenInfo.name}`,
                        false,
                        true
                    )
                );
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Approve",
                        "Success",
                        resultApproveTrx,
                        `Approve ${secondTokenInfo.name} for swap`
                    )
                );
                var routerContract = await tronWeb.contract(
                    ExonswapV2SwapRouter.abi,
                    Config().ROUTER_CONTRACT
                );
                //dispatch(false (transaction, state (true or false), description))
                const deadline = swapSetting.deadline * 60 + Math.floor(Date.now());
                // tronWeb.toSun(
                //   secondTokenAmount - secondTokenAmount * swapSetting.slippage,
                // )

                var secondAmountSun = await handleTokenInfo(
                    secondTokenInfo.address,
                    "toSun",
                    secondTokenAmount
                );

                var resultSwap;
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Signature confirming`,
                        true,
                        false
                    )
                );
                await sleep(3000);

                let slipageAmount = await handleTokenInfo(
                    secondTokenInfo.address,
                    "toSun",
                    (
                        secondTokenAmount -
                        secondTokenAmount * swapSetting.slippage
                    ).toFixed(6)
                );
                let amountIn = await handleTokenInfo(
                    firstTokenInfo.address,
                    "toSun",
                    firstTokenAmount
                );

                try {
                    resultSwap = await routerContract
                        .swapExactTokensForTokens(
                            amountIn.toString(),
                            slipageAmount.toString(),
                            [firstTokenInfo.address, secondTokenInfo.address],
                            tronWeb.defaultAddress.base58,
                            deadline.toString(),
                            tokenId.toString()
                        )
                        .send({ feeLimit: 500000000 });
                } catch (error: any) {
                    console.log(error);
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            false,
                            true,
                            "Swap",
                            "Error",
                            resultApproveTrx,
                            `An error occured during swap`
                        )
                    );
                    dispatch(swapActionCreator.setCurrentSwapState("initial"));
                    return;
                }
                await sleep(4000);
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Signature confirming`,
                        false,
                        true
                    )
                );
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Finished`,
                        true,
                        false
                    )
                );

                let resultswap;
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        false,
                        true,
                        "Swap",
                        "Processing",
                        resultSwap,
                        `Swap ${firstTokenInfo.name} - ${secondTokenInfo.name}`
                    )
                );

                if (await getTransactionResult(resultSwap, tronWeb)) {
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            false,
                            true,
                            "Swap",
                            "Error",
                            resultApproveTrx,
                            `An error occured during swap`
                        )
                    );
                    dispatch(swapActionCreator.setCurrentSwapState("initial"));
                    return;
                }
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Swap",
                        "Success",
                        resultSwap,
                        `Success swap ${firstTokenInfo.name} - ${secondTokenInfo.name}`
                    )
                );
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Finished`,
                        false,
                        true
                    )
                );
            }
        },
    swapExactTRXForTokens:
        () => async (dispatch: AppDispatch, store: getRootState) => {
            // const tronWeb = await initTronstack()
            const tronWeb = await store().WalletReducer.tronWeb;
            const firstTokenInfo = store().applicationReducer.firstToken;
            const secondTokenInfo = store().applicationReducer.secondToken;
            const firstTokenAmount = store().swapReducer.firstTokenAmount;
            const secondTokenAmount = store().swapReducer.secondTokenAmount;
            const tokenId = store().AccountReducer.currentAccount;
            const swapSetting = store().swapReducer.swapSetting;
            dispatch(ApplicationAcionCreator.setTransactionSteps());
            if (
                secondTokenInfo.address !== "" &&
                secondTokenInfo !== undefined &&
                tronWeb != null &&
                Object.keys(secondTokenInfo).length > 0
            ) {
                var routerContract = await tronWeb.contract(
                    ExonswapV2SwapRouter.abi,
                    Config().ROUTER_CONTRACT
                );
                //dispatch(false (transaction, state (true or false), description))
                const deadline = swapSetting.deadline * 60 + Math.floor(Date.now());
                // swap TRX to token -----------------------------------------------------------------------
                var secondAmountSun = await handleTokenInfo(
                    secondTokenInfo.address,
                    "toSun",
                    secondTokenAmount
                );
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Signature confirming`,
                        true,
                        false
                    )
                );
                let resultSwap;
                try {
                    resultSwap = await routerContract
                        .swapExactTRXForTokens(
                            parseInt(
                                (
                                    secondAmountSun -
                                    secondAmountSun * swapSetting.slippage
                                ).toString()
                            ).toString(),
                            [firstTokenInfo.address, secondTokenInfo.address],
                            tronWeb.defaultAddress.base58,
                            deadline.toString(),
                            tokenId.toString()
                        )
                        .send({
                            feeLimit: 500000000,
                            callValue: await handleTokenInfo(
                                firstTokenInfo.address,
                                "toSun",
                                firstTokenAmount
                            ),
                        });
                } catch (error: any) {
                    console.log(error);
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            true,
                            true,
                            "Swap",
                            "Error",
                            resultSwap,
                            `${error.message}`
                        )
                    );
                    dispatch(swapActionCreator.setCurrentSwapState("initial"));
                    return;
                }
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Signature confirming`,
                        false,
                        true
                    )
                );
                let resultswap;
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        false,
                        true,
                        "Swap",
                        "Processing",
                        resultSwap,
                        `Swap TRX to ${secondTokenInfo.name}`
                    )
                );
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Finished`,
                        true,
                        false
                    )
                );

                if (await getTransactionResult(resultSwap, tronWeb)) {
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            true,
                            true,
                            "Swap",
                            "Error",
                            resultSwap,
                            `An error occured during swap, check out your balance`
                        )
                    );
                    dispatch(swapActionCreator.setCurrentSwapState("initial"));
                    return;
                }
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Swap",
                        "Success",
                        resultSwap,
                        `Success swap trx to ${secondTokenInfo.name}`
                    )
                );
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Finished`,
                        false,
                        true
                    )
                );
            }
        },
    swapExactTokensForTRX:
        () => async (dispatch: AppDispatch, store: getRootState) => {
            //const tronWeb = await initTronstack()
            const tronWeb = store().WalletReducer.tronWeb;
            const firstTokenInfo = store().applicationReducer.firstToken;
            const secondTokenInfo = store().applicationReducer.secondToken;
            const firstTokenAmount = store().swapReducer.firstTokenAmount;
            const secondTokenAmount = store().swapReducer.secondTokenAmount;
            const tokenId = store().AccountReducer.currentAccount;
            const swapSetting = store().swapReducer.swapSetting;
            dispatch(
                ApplicationAcionCreator.setTransactionSteps([
                    `Approve ${firstTokenInfo.name}`,
                ])
            );
            if (
                firstTokenInfo.address !== "" &&
                firstTokenInfo !== undefined &&
                tronWeb != null &&
                Object.keys(firstTokenInfo).length > 0
            ) {
                const tokenContract = await tronWeb.contract(
                    ITRX20.abi,
                    firstTokenInfo.address
                );

                var resultApproveTrx;
                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Approve ${firstTokenInfo.name}`,
                        true,
                        false
                    )
                );

                let firstTokenSun = await handleTokenInfo(
                    firstTokenInfo.address,
                    "toSun",
                    firstTokenAmount
                );

                try {
                    resultApproveTrx = await tokenContract
                        .approve(Config().ROUTER_CONTRACT, firstTokenSun.toString())
                        .send();
                } catch (error: any) {
                    console.log(error);
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            true,
                            true,
                            "Approve",
                            "Error",
                            resultApproveTrx,
                            `${error.message}`
                        )
                    );
                    dispatch(swapActionCreator.setCurrentSwapState("initial"));
                    return;
                }

                let result;
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        false,
                        true,
                        "Approve",
                        "Processing",
                        resultApproveTrx,
                        `Approve ${firstTokenInfo.name} for swap`
                    )
                );

                if (await getTransactionResult(resultApproveTrx, tronWeb)) {
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            true,
                            true,
                            "Approve",
                            "Error",
                            resultApproveTrx,
                            `An error occured during approve`
                        )
                    );
                    dispatch(swapActionCreator.setCurrentSwapState("initial"));
                    return;
                }

                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Approve",
                        "Success",
                        resultApproveTrx,
                        `Success approved ${firstTokenInfo.name} for swap`
                    )
                );
            }
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Approve ${firstTokenInfo.name}`,
                    false,
                    true
                )
            );
            var routerContract = await tronWeb.contract(
                ExonswapV2SwapRouter.abi,
                Config().ROUTER_CONTRACT
            );
            //dispatch(false (transaction, state (true or false), description))
            const deadline = swapSetting.deadline * 60 + Math.floor(Date.now());
            var secondAmountSun = await handleTokenInfo(
                secondTokenInfo.address,
                "toSun",
                secondTokenAmount
            );

            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Signature confirming`,
                    true,
                    false
                )
            );

            let slipageAmount = await handleTokenInfo(
                secondTokenInfo.address,
                "toSun",
                (secondTokenAmount - secondTokenAmount * swapSetting.slippage).toFixed(
                    6
                )
            );
            let amountIn = await handleTokenInfo(
                firstTokenInfo.address,
                "toSun",
                firstTokenAmount
            );
            var resultSwapTokenTrx;
            try {
                resultSwapTokenTrx = await routerContract
                    .swapExactTokensForTRX(
                        amountIn.toString(),
                        slipageAmount.toString(),
                        [firstTokenInfo.address, secondTokenInfo.address],
                        tronWeb.defaultAddress.base58,
                        deadline.toString(),
                        tokenId.toString()
                    )
                    .send({ feeLimit: 500000000 });
            } catch (error: any) {
                console.log(error);
                dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Swap",
                        "Error",
                        resultSwapTokenTrx,
                        `${error.message}`
                    )
                );
                dispatch(swapActionCreator.setCurrentSwapState("initial"));
                return;
            }
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Signature confirming`,
                    false,
                    true
                )
            );
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Finished`,
                    true,
                    false
                )
            );
            let resultswapTokenToTrx;
            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    false,
                    true,
                    "Swap",
                    "Processing",
                    resultSwapTokenTrx,
                    `Swap ${firstTokenInfo.name} to TRX`
                )
            );

            if (await getTransactionResult(resultSwapTokenTrx, tronWeb)) {
                dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                dispatch(
                    ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "Swap",
                        "Error",
                        resultSwapTokenTrx,
                        `An error occured during swap`
                    )
                );
                dispatch(swapActionCreator.setCurrentSwapState("initial"));
                return;
            }

            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "Swap",
                    "Success",
                    resultSwapTokenTrx,
                    `Success swap`
                )
            );
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Finished`,
                    false,
                    true
                )
            );
        },
    // ["TXg8L3spQ7PBB3rKNB8ezxDAuEeR9tPCys","TZDvMsoeJHwfuseHmob5Juy2AUVawh7Kcj"]
    getTokensReserve:
        () => async (_dispatch: AppDispatch, store: getRootState) => {
            //const tronWeb = await initTronstack()
            const tronWeb = (window as any).tronWeb;
            // get  token 1 info
            const firstTokenInfo = store().applicationReducer.firstToken;
            const secondTokenInfo = store().applicationReducer.secondToken;
            if (tronWeb != null) {
                const routeSwapContract = await tronWeb.contract(
                    ExonswapV2SwapRouter.abi,
                    Config().ROUTER_CONTRACT
                );
                if (
                    firstTokenInfo.address !== undefined &&
                    secondTokenInfo.address !== undefined
                ) {
                    const tokenList = [firstTokenInfo.address, secondTokenInfo.address];
                    try {
                        var reserves = await routeSwapContract
                            .getPairReserves(tokenList[0], tokenList[1])
                            .call();
                    } catch (e) {
                        reserves = [0, 0];
                        console.log(e);
                    }
                    if (reserves !== undefined) {
                        const reserve0InSun = Number(BigInt(reserves[0]));
                        const reserve1InSun = Number(BigInt(reserves[1]));
                        _dispatch(
                            swapActionCreator.setTokensReserve(
                                await handleTokenInfo(
                                    firstTokenInfo.address,
                                    "fromSun",
                                    reserve0InSun
                                ),
                                await handleTokenInfo(
                                    secondTokenInfo.address,
                                    "fromSun",
                                    reserve1InSun
                                )
                            )
                        );
                    }
                }
            }
        },
    swapExr2toExon: () => async (dispatch: AppDispatch, store: getRootState) => {
        const tronWeb = await store().WalletReducer.tronWeb;
        //const tronWeb = await initTronstack()
        if (tronWeb === null) return;
        const exchangeExr2Contract = await tronWeb.contract(
            ExchangeTokensEXR2.abi,
            Config().EXCHANGE_TOKENS_EXR2
        );
        let exchange: any;
        dispatch(
            ApplicationAcionCreator.setNotificationStatus(
                false,
                true,
                "Get EXR2",
                "Processing",
                "",
                `Please wait`
            )
        );
        try {
            exchange = await exchangeExr2Contract.exchangeTokensEXR2().send();
        } catch (error: any) {
            console.log(error);
            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "Get EXR2",
                    "Error",
                    exchange,
                    `${error.message}`
                )
            );
            return;
        }
        if (await getTransactionResult(exchange, tronWeb)) {
            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "Get EXR2",
                    "Error",
                    exchange,
                    `Unexpected error`
                )
            );
            return;
        }
        dispatch(
            ApplicationAcionCreator.setNotificationStatus(
                true,
                true,
                "Get EXR2",
                "Success",
                exchange,
                `EXR2 was received successfuly`
            )
        );
    },
    swapExr1toExon: () => async (dispatch: AppDispatch, store: getRootState) => {
        const tronWeb = await store().WalletReducer.tronWeb;
        //const tronWeb = await initTronstack()
        if (tronWeb === null) return;
        const exchangeExr1Contract = await tronWeb.contract(
            ExchangeTokensEXR1.abi,
            Config().EXCHANGE_TOKENS_EXR1
        );
        let exchange: any;
        dispatch(
            ApplicationAcionCreator.setNotificationStatus(
                false,
                true,
                "Get EXR1",
                "Processing",
                "",
                `Please wait`
            )
        );
        try {
            exchange = await exchangeExr1Contract.exchangeTokensEXR1().send();
        } catch (error: any) {
            console.log(error);
            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "Get EXR1",
                    "Error",
                    exchange,
                    `${error.message}`
                )
            );
            return;
        }
        if (await getTransactionResult(exchange, tronWeb)) {
            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "Get EXR1",
                    "Error",
                    exchange,
                    `Unexpected error`
                )
            );
            return;
        }
        dispatch(
            ApplicationAcionCreator.setNotificationStatus(
                true,
                true,
                "Get EXR1",
                "Success",
                exchange,
                `EXR1 was received successfuly`
            )
        );
    },
};
// address.match(/^(0x)?[0-9a-f]{40}$/)
