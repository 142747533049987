import React, { useContext } from 'react'
import ButtonProcess from 'components/Button/ButtonProcess';
import Modal from 'components/Modal';
import useWalletSelector from 'hooks/useWalletSelector';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { swapActionCreator } from 'store/reducers/swap/action-creators';
import Loading from 'components/Popups/assets/loader.gif';
import { ReactComponent as Done } from '../assets/done.svg';
import { coinIcons } from 'components/CoinIcons';
import { TransactionSteps } from 'store/reducers/application/types';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';
import logoUndefined from '../../../../../../../../components/CoinIcons/assets/logoundefined.png';
import { formatNumber } from 'utils/formatNumber';
import { multisendActionCreator } from 'store/reducers/multisend/action-creator';
import { LaunchpoolModalState } from 'store/reducers/launchpool/types';
import { LaunchpadModalState } from 'store/reducers/launchpad/types';
import { launchpoolActionCreator } from 'store/reducers/launchpool/action-creator';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';
import { launchpadActionCreator } from 'store/reducers/launchpad/action-creator';
import { LevelsEnergyActionCreator } from 'store/reducers/levelsEnergy/action-creators';
import { SecondsContext } from 'App';

const SwapSteps = () => {
  const { setSeconds } = useContext(SecondsContext)
  const { firstToken, secondToken } = useWalletSelector(
    state => state.applicationReducer,
  )
  const { firstTokenAmount, secondTokenAmount, swapState } = useWalletSelector(
    state => state.swapReducer,
  );
  const { liquidityState } = useWalletSelector(state => state.liquidityReducer);
  const { farmingState, farmingStakeInfo } = useWalletSelector(
    state => state.farmingReducer,
  );
  const { multisendState, sendAddresses, tokenAddress, amountPerAddress } =
    useWalletSelector(state => state.multisendReducer);
  const { launchpoolState } = useWalletSelector(
    state => state.launchpoolReducer,
  );
  const { levelsEnergyState, chosenPacket, levelsPrice, userInfo, actualClaimAmount } = useWalletSelector(state => state.LevelsEnergyReducer)
  const { launchpadState, activePoolId } = useWalletSelector(state => state.launchpadReducer);
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(swapActionCreator.setCurrentSwapState('initial'));
    dispatch(LiquidityActionCreator.setLiquidityState('initial'));
    dispatch(farmingActionCreator.setFarmingState('initial'));
    dispatch(multisendActionCreator.setMultisendState('initial'));
    dispatch(launchpoolActionCreator.setLaunchpoolState('initial'));
    if (currentStep === 'buyTokens' || currentStep === 'joinToIDO' || currentStep === 'claimWhitelistIDOTokens') {
      dispatch(launchpadActionCreator.getActivePool(activePoolId))
      dispatch(launchpadActionCreator.getBuyTokenBalances())
    }
    dispatch(launchpadActionCreator.setLaunchpadState('initial'));
    dispatch(LevelsEnergyActionCreator.setLevelsEnergyState('initial'));
    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
    setSeconds(0)
  };
  const { transactionSteps } = useWalletSelector(
    state => state.applicationReducer,
  );
  const { whitelistDetails, buyDetails, activeIdo } = useWalletSelector(
    state => state.launchpadReducer,
  );
  const { joinDetails, activePool } = useWalletSelector(
    state => state.launchpoolReducer,
  );
  const [phases, setPhases] = useState<TransactionSteps[] | null>(
    transactionSteps,
  );
  const { poolInfo, removeToken0, removeToken1 } = useWalletSelector(
    state => state.removeLiquidityReducer,
  );
  const defineSteps = (
    launchpadState: LaunchpadModalState,
    launchpoolState: LaunchpoolModalState,
    swapState: string,
    liquidityState: string,
    farmingState: string,
    multisendState: string,
    levelsEnergyState: string
  ) => {
    let currentStep
    if (swapState === 'steps') {
      currentStep = 'swap';
    } else if (liquidityState === 'steps') {
      currentStep = 'addLiquidity';
    } else if (liquidityState === 'rmSteps') {
      currentStep = 'rmLiquidity';
    } else if (farmingState === 'withdrawSteps') {
      currentStep = 'withdraw';
    } else if (farmingState === 'depositSteps') {
      currentStep = 'deposit';
    } else if (farmingState === 'unstakeSteps') {
      currentStep = 'unstake';
    } else if (multisendState === 'multisendSteps') {
      currentStep = 'multisend';
    } else if (multisendState === 'multisendStepsTrx') {
      currentStep = 'multisendTrx';
    } else if (launchpoolState === 'joinToPool') {
      currentStep = 'joinToPool';
    } else if (launchpoolState === 'claimTokens') {
      currentStep = 'claimTokens';
    } else if (launchpadState === 'joinToIDO') {
      currentStep = 'joinToIDO';
    } else if (launchpadState === 'claimWhitelistIDOTokens') {
      currentStep = 'claimWhitelistIDOTokens';
    } else if (launchpadState === 'buyTokens') {
      currentStep = 'buyTokens';
    } else if (levelsEnergyState === 'levelsEnergyWithdraw') {
      currentStep = 'levelsEnergyWithdraw';
    } else if (levelsEnergyState === 'buyEnergyPacket') {
      currentStep = 'buyEnergyPacket'
    }
    return currentStep;
  };

  useEffect(() => {
    if (transactionSteps?.length !== 0 && transactionSteps !== null) {
      setPhases(transactionSteps);
    }
    setCurrentStep(
      defineSteps(
        launchpadState,
        launchpoolState,
        swapState,
        liquidityState,
        farmingState,
        multisendState,
        levelsEnergyState
      ),
    );
  }, [
    swapState,
    liquidityState,
    farmingState,
    transactionSteps,
    launchpoolState,
    multisendState,
    launchpadState,
    levelsEnergyState
  ]);
  const [currentStep, setCurrentStep] = useState<string | null | undefined>(
    null,
  );
  const isMobile = window.innerWidth <= 640;

  let launchpoolLPTokens = activePool?.tokenInSymbol?.split('LP-')[1].split('/') || ['', '']
  launchpoolLPTokens = launchpoolLPTokens.map(el => el.toLowerCase())

  return (
    <>
      {currentStep && (
        <Modal closeModal={handleCloseModal}>
          <div className="relative text-2xl text-center sm:text-4xl text-title">
            {currentStep === 'swap'
              ? 'Swap'
              : currentStep === 'addLiquidity'
                ? 'Pool'
                : currentStep === 'withdraw' || currentStep === 'levelsEnergyWithdraw'
                  ? 'Claim'
                  : currentStep === 'deposit'
                    ? 'Deposit'
                    : currentStep === 'unstake'
                      ? 'Withdraw'
                      : currentStep === 'multisend'
                        ? 'Multisend'
                        : currentStep === 'multisendTrx'
                          ? 'Multisend'
                          : currentStep === 'joinToPool' || currentStep === 'joinToIDO'
                            ? 'Join'
                            : currentStep === 'buyTokens' || currentStep === 'buyEnergyPacket'
                              ? 'Buy'
                              : currentStep === 'claimTokens' ||
                                currentStep === 'claimWhitelistIDOTokens'
                                ? 'Claim tokens'
                                : 'Remove Pool'}
          </div>
          <div className="mt-8">
            {currentStep !== 'joinToIDO' &&
              currentStep !== 'withdraw' &&
              currentStep !== 'deposit' &&
              currentStep !== 'unstake' &&
              currentStep !== 'multisend' &&
              currentStep !== 'levelsEnergyWithdraw' &&
              currentStep !== 'buyEnergyPacket' &&
              currentStep !== 'multisendTrx' &&
              currentStep !== 'joinToPool' &&
              currentStep !== 'claimTokens' &&
              currentStep !== 'claimWhitelistIDOTokens' ? (
              <>
                {' '}
                <div
                  className={`flex items-center gap-2 justify-center ${currentStep === 'rmLiquidity' && 'mb-6'
                    } `}
                >
                  <img
                    src={
                      currentStep === 'rmLiquidity'
                        ? coinIcons[poolInfo.firstDepositTokenInfo.icon!] ||
                        poolInfo.firstDepositTokenInfo.icon ||
                        logoUndefined
                        : currentStep === 'buyTokens'
                          ? coinIcons[
                          getTokenInfoByAddress(buyDetails.selectedToken).icon
                          ]
                          : coinIcons[firstToken.icon || ''] ||
                          firstToken.icon ||
                          logoUndefined
                    }
                    alt="icon"
                    className="w-8 h-8"
                  />
                  <div className="text-3xl text-lightYellow">
                    {currentStep === 'rmLiquidity'
                      ? formatNumber(removeToken0 || '')
                      : currentStep === 'buyTokens'
                        ? buyDetails.amountIn
                        : formatNumber(firstTokenAmount)}
                  </div>
                  <div
                    className="text-xl font-light text-description "
                    style={{ minWidth: '3rem' }}
                  >
                    {currentStep === 'rmLiquidity'
                      ? poolInfo.firstDepositTokenInfo.symbol
                      : currentStep === 'buyTokens'
                        ? getTokenInfoByAddress(buyDetails.selectedToken).name
                        : firstToken.name}
                  </div>
                </div>
                {currentStep !== 'rmLiquidity' && (
                  <span className="flex justify-center text-yellow transform rotate-90">
                    {currentStep === 'addLiquidity' ? '+' : `->`}
                  </span>
                )}
                <div className="flex items-center justify-center gap-2">
                  <img
                    src={
                      currentStep === 'rmLiquidity'
                        ? coinIcons[poolInfo.secondDepositTokenInfo.icon!] ||
                        poolInfo.secondDepositTokenInfo.icon ||
                        logoUndefined
                        : currentStep === 'buyTokens'
                          ? activeIdo.saleTokenInfo.icon
                          : coinIcons[secondToken.icon || ''] ||
                          secondToken.icon ||
                          logoUndefined
                    }
                    alt="icon"
                    className="w-8 h-8"
                  />
                  <div className="text-3xl text-lightYellow">
                    {currentStep === 'rmLiquidity'
                      ? formatNumber(removeToken1 || '')
                      : currentStep === 'buyTokens'
                        ? buyDetails.amountOut
                        : formatNumber(secondTokenAmount)}
                  </div>
                  <div
                    className="text-xl font-light text-description "
                    style={{ minWidth: '3rem' }}
                  >
                    {currentStep === 'rmLiquidity'
                      ? poolInfo.secondDepositTokenInfo.symbol
                      : currentStep === 'buyTokens'
                        ? activeIdo.saleTokenInfo.symbol
                        : secondToken.name}
                  </div>
                </div>
              </>
            ) : currentStep === 'claimTokens' ||
              currentStep === 'claimWhitelistIDOTokens' ? (
              <div className="flex flex-col items-center justify-center gap-4">
                <div className="flex gap-2 items-center">
                  {(currentStep === 'claimTokens' && activePool?.tokenInSymbol)
                    ? <div className="flex mr-3">
                      <img
                        src={coinIcons[launchpoolLPTokens[0]]}
                        alt="Coin one"
                        className="w-8 h-8 z-0"
                      />
                      <img
                        src={coinIcons[launchpoolLPTokens[1]]}
                        alt="Coin two"
                        className="w-8 h-8 -ml-2"
                      />
                    </div>
                    : <img
                      src={
                        currentStep === 'claimTokens'
                          ? (coinIcons[
                            getTokenInfoByAddress(activePool?.tokenIn!)?.icon
                          ])
                          : coinIcons[
                          getTokenInfoByAddress(
                            activeIdo.userInfo.whitelistedToken.token,
                          )?.icon
                          ]
                      }
                      alt="icon"
                      className="w-8 h-8"
                    />}

                  <div className="text-3xl text-lightYellow">
                    {currentStep === 'claimTokens'
                      ? activePool?.userInfo.stakedValue
                      : activeIdo.userInfo.whitelistedToken.amount}
                  </div>
                  <div>
                    {currentStep === 'claimTokens'
                      ? (getTokenInfoByAddress(activePool?.tokenIn!)?.name || activePool?.tokenInSymbol)
                      : getTokenInfoByAddress(
                        activeIdo.userInfo.whitelistedToken.token,
                      )?.name}
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <img
                    src={
                      currentStep === 'claimTokens'
                        ? coinIcons[
                        getTokenInfoByAddress(activePool?.tokenOut!)?.icon
                        ]
                        : activeIdo.saleTokenInfo.icon
                    }
                    alt="icon"
                    className="w-8 h-8"
                  />
                  <div className="text-3xl text-lightYellow">
                    {currentStep === 'claimTokens'
                      ? activePool?.userInfo.reward
                      : activeIdo.userInfo.saleTokenAmount}
                  </div>
                  <div>
                    {currentStep === 'claimTokens'
                      ? getTokenInfoByAddress(activePool?.tokenOut!)?.name
                      : activeIdo.saleTokenInfo.symbol}
                  </div>
                </div>
              </div>
            ) : currentStep === 'multisend' ||
              currentStep === 'multisendTrx' ||
              currentStep === 'levelsEnergyWithdraw' ||
              currentStep === 'buyEnergyPacket' ? (
              <div className="flex items-center justify-center gap-2">
                {currentStep === 'multisend' ? (
                  <img src={tokenAddress.icon} alt="icon" className="w-8 h-8" />
                ) : (
                  <img src={coinIcons['trx']} alt="icon" className="w-8 h-8" />
                )}
                <div className="text-3xl text-lightYellow">
                  {currentStep === 'levelsEnergyWithdraw'
                    ? actualClaimAmount
                    : currentStep === 'buyEnergyPacket'
                      ? levelsPrice[chosenPacket]
                      : sendAddresses.length * amountPerAddress}
                </div>
                {currentStep === 'multisend' && (
                  <div
                    className="text-xl font-light text-description"
                    style={{ minWidth: '3rem' }}
                  >
                    {tokenAddress.name}
                  </div>
                )}
                <div
                  className="text-xl font-light text-description"
                  style={{ minWidth: '3rem' }}
                >
                  {currentStep === 'multisend' ? 'Tokens' : 'TRX'}
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center gap-2">
                {(currentStep === 'withdraw' ||
                  currentStep === 'joinToPool' ||
                  currentStep === 'joinToIDO')
                  && (currentStep === 'joinToPool' && activePool?.tokenInSymbol)
                  ? <div className="flex mr-3">
                    <img
                      src={coinIcons[launchpoolLPTokens[0]]}
                      alt="Coin one"
                      className="w-8 h-8 z-0"
                    />
                    <img
                      src={coinIcons[launchpoolLPTokens[1]]}
                      alt="Coin two"
                      className="w-8 h-8 -ml-2"
                    />
                  </div>
                  : (
                    <img
                      src={
                        coinIcons[
                        currentStep === 'withdraw'
                          ? 'exon'
                          : currentStep === 'joinToPool'
                            ? getTokenInfoByAddress(activePool?.tokenIn!)?.icon
                            : getTokenInfoByAddress(
                              whitelistDetails.selectedToken!,
                            )?.icon
                        ]
                      }
                      alt="icon"
                      className="w-8 h-8"
                    />
                  )}
                <div className="text-3xl text-lightYellow">
                  {currentStep === 'withdraw'
                    ? farmingStakeInfo.withdrawAmount
                    : currentStep === 'joinToPool'
                      ? joinDetails.amount
                      : currentStep === 'joinToIDO'
                        ? whitelistDetails.tokenAmount
                        : currentStep === 'unstake'
                          ? formatNumber(farmingStakeInfo.unstakeInput)
                          : formatNumber(farmingStakeInfo.stakeInput)}
                </div>
                <div
                  className="text-xl font-light text-description"
                  style={{ minWidth: '3rem' }}
                >
                  {currentStep === 'withdraw'
                    ? 'EXON'
                    : currentStep === 'joinToIDO'
                      ? getTokenInfoByAddress(whitelistDetails?.selectedToken!)
                        ?.name
                      : currentStep === 'joinToPool'
                        ? activePool?.tokenInSymbol
                          ? activePool.tokenInSymbol
                          : getTokenInfoByAddress(activePool?.tokenIn!)?.name
                        : 'LP tokens'}
                </div>
              </div>
            )}
            <div className="mb-5 mt-9 text-description">
              {`Please, follow the steps below to complete ${currentStep === 'swap'
                ? 'the swap'
                : currentStep === 'addLiquidity'
                  ? 'liquidity pool'
                  : currentStep === 'withdraw' || currentStep === 'levelsEnergyWithdraw'
                    ? 'claim'
                    : currentStep === 'deposit'
                      ? 'deposit'
                      : currentStep === 'unstake'
                        ? 'withdraw'
                        : currentStep === 'multisend'
                          ? 'multisend'
                          : currentStep === 'multisendTrx'
                            ? 'multisend'
                            : currentStep === 'buyEnergyPacket'
                              ? 'buy energy premium'
                              : currentStep === 'joinToPool' || currentStep === 'joinToIDO'
                                ? 'join to pool'
                                : currentStep === 'claimTokens' ||
                                  currentStep === 'claimWhitelistIDOTokens'
                                  ? 'claim reward'
                                  : currentStep === 'buyTokens'
                                    ? 'buy project tokens'
                                    : 'remove pool'
                }`}
            </div>
            <div className="">
              {phases !== null &&
                phases.map((el, index) => {
                  return (
                    <StepElement
                      text={el.process}
                      count={index + 1}
                      fetching={el.fetching}
                      done={el.done}
                      last={index === phases.length - 1}
                    />
                  );
                })}
            </div>
            {phases !== null && phases[phases.length - 1].done && (
              <div className="mt-4 text-center ">
                <div className="my-4 text-2xl text-green">
                  {`Your ${currentStep === 'swap'
                    ? 'swap'
                    : currentStep === 'addLiquidity'
                      ? 'pool'
                      : currentStep === 'withdraw' || currentStep === 'levelsEnergyWithdraw'
                        ? 'claim'
                        : currentStep === 'deposit'
                          ? 'deposit'
                          : currentStep === 'unstake'
                            ? 'LP unstake'
                            : currentStep === 'multisend'
                              ? 'multisend'
                              : currentStep === 'buyEnergyPacket'
                                ? 'buy energy premium'
                                : currentStep === 'multisendTrx'
                                  ? 'multisend'
                                  : currentStep === 'joinToPool' ||
                                    currentStep === 'joinToIDO'
                                    ? 'join to pool'
                                    : currentStep === 'claimTokens' ||
                                      currentStep === 'claimWhitelistIDOTokens'
                                      ? 'claim reward'
                                      : currentStep === 'buyTokens'
                                        ? 'project tokens buy'
                                        : 'pool remove'
                    } was successful`}
                </div>
                <button
                  disabled={false}
                  className="inline-flex justify-center w-full px-6 py-2 text-base 
																	 font-medium border border-transparent rounded-md shadow-sm bg-yellow 
																	 text-mainBlack hover:bg-black-700 focus:outline-none focus:ring-2 
																	 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

type Props = {
  text: string;
  done: boolean;
  fetching: boolean;
  last: boolean;
  count: number;
};

const StepElement: FunctionComponent<Props> = ({
  text,
  done,
  fetching,
  last,
  count,
}) => {
  return (
    <div className="relative mb-5 flex items-center justify-between">
      <div className="">
        <div className="flex items-center text-title text-base font-normal">
          <span
            className={`w-9 h-9 justify-center flex items-center bg-darkGray rounded-full border-2 relative ${done ? 'border-green' : 'border-transparent'
              }`}
          >
            {done ? <Done /> : count}
          </span>
          <span className="ml-5">{text}</span>
        </div>
        {!last && (
          <hr
            style={{
              width: '25px',
              transform: 'rotate(90deg) translate(50%, -500%)',
              border: 'none',
              height: '1px',
              backgroundColor: `${done ? '#84DA4A' : '#595958'}`,
            }}
          />
        )}
      </div>
      {fetching && (
        <img
          src={Loading}
          alt="Minting"
          style={{ width: '15%', position: 'absolute', right: 0 }}
        />
      )}
    </div>
  );
};

export default SwapSteps;
