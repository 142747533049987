import TronWeb from 'tronweb';
import Config from 'config'

const initTronstack = async () => {
  var trongid = new TronWeb({
    fullHost: Config().TRONGRID,
    privateKey: Config().PRIVATE_KEY,
  });
  return trongid;
};

export default initTronstack;
