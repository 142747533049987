import React, { FunctionComponent, useState } from 'react';
import TabItem from './components/TabItem/TabItem';
import LevelProgram from './components/LevelProgram';
import RangeProgram from './components/RangeProgram';
import PersonalLiquidity from './components/PersonalLiquidity';
import LevelsEnergyTab from './components/LevelsEnergyTab';

export interface itemsShow {
  liquidity: boolean;
  levelProgram: boolean;
  rangeProgram: boolean;
  levelsEnergy: boolean;
}

const initialState: itemsShow = {
  liquidity: false,
  levelProgram: false,
  rangeProgram: false,
  levelsEnergy: false
};

const Tabs: FunctionComponent = () => {
  const [item, setItem] = useState({ ...initialState, levelProgram: true });

  const handlItems = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setItem({ ...initialState, [e.currentTarget.id]: true });
  };

  return (
    <div className="bg-cardBg mt-12 rounded-4xl pb-8">
      <ul className="grid grid-cols-3 border-b sm:px-0">
        {/*
        <div id="liquidity" onClick={e => handlItems(e)}>
          <TabItem title="Personal liquidity" isActive={item.liquidity} />
        </div>
        */}
        <div id="levelProgram" onClick={e => handlItems(e)}>
          <TabItem title="Level program" isActive={item.levelProgram} />
        </div>
        <div id="rangeProgram" onClick={e => handlItems(e)}>
          <TabItem title="Rank program" isActive={item.rangeProgram} />
        </div>
        <div id="levelsEnergy" onClick={e => handlItems(e)}>
          <TabItem title="Energy premium" isActive={item.levelsEnergy} />
        </div>
      </ul>
      {item.levelProgram && <LevelProgram />}
      {/* item.liquidity && <PersonalLiquidity /> */}
      {item.rangeProgram && <RangeProgram />}
      {item.levelsEnergy && <LevelsEnergyTab />}
    </div>
  );
};

export default Tabs;
