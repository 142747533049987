import { FunctionComponent } from "react";

import launch from './assets/launch.png'

const Info: FunctionComponent = () => {
    return(
        <>
            <img src={launch} className='absolute block w-full pointer-events-none left-4 top-6 sm:top-0 filter brightness-50 lg:brightness-100 sm:w-1/2 lg:w-1/3' />
            <div className="self-end w-full sm:max-w-2xl ">
                <div className="relative z-20 text-center sm:text-right">
                    <h1 className='text-4xl font-bold lg:text-7xl text-title'>
                        Exon IDO
                    </h1>
                    <div className="w-full mt-4 text-base sm:float-right lg:float-none md:text-2xl sm:w-1/2 lg:w-full lg:text-base px-30 text-description">
                        Exclusive chance to become first in perspective projects investment
                    </div>
                </div>
            </div>
        </>
    )
}

export default Info
