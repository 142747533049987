import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { coinIcons } from 'components/CoinIcons';
import { Range } from 'react-range';
import { ArrowsIcon } from 'components/Icons';
import useWalletSelector from 'hooks/useWalletSelector';
import { useDispatch } from 'react-redux';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';
import { removeLiquidityActionCreator } from 'store/reducers/liquidityRemove/action-creator';
import ButtonProcess from 'components/Button/ButtonProcess';
import logoUndefined from '../../../../../../../../components/CoinIcons/assets/logoundefined.png'
import { formatNumber } from 'utils/formatNumber';

const RemoveLiquidity = () => {
  const [state, setState] = useState([50]);
  const { poolInfo } = useWalletSelector(state => state.removeLiquidityReducer);
  const { tronWeb } = useWalletSelector(state => state.WalletReducer)
  const { removePart } = useWalletSelector(
    state => state.removeLiquidityReducer,
  );
  const [removeLpAmount, setRemoveLpAmount] = useState('0')
  const [removeTip, setRemoveTip] = useState(false)
  const dispatch = useDispatch();
  const handlCloseModal = () => {
    dispatch(LiquidityActionCreator.setLiquidityState('initial'));
  };
  const handlRemoveLiquidity = () => {
    dispatch(removeLiquidityActionCreator.setCurrentRLTAmount(state[0]));
    dispatch(
      removeLiquidityActionCreator.setPoolInfo({
        ...poolInfo,
        liquidityAmount: state[0],
      }),
    );
    dispatch(removeLiquidityActionCreator.getRemoveTokensLiquidity());
    dispatch(
      LiquidityActionCreator.setLiquidityState('removeLiquidityConfirm'),
    );
    dispatch(removeLiquidityActionCreator.setCurrentRemoveParte(state[0]));
  };

  const handlRemoveLiquidityInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let stakeInput: number
    stakeInput = e.currentTarget.value === '' ? 0 : parseFloat(e.currentTarget.value);
    stakeInput = +stakeInput.toFixed(6)
    setState([((+stakeInput * 100) / poolInfo.liquidity) > 100 ? 100 : ((+stakeInput * 100) / poolInfo.liquidity)])
    setRemoveLpAmount(stakeInput.toString())
  }

  
  return (
    <>
        <Modal closeModal={handlCloseModal}>
          <div className="relative z-50 flex items-center justify-start text-2xl sm:justify-center text-title sm:text-4xl">
            Remove Liquidity
            <span
              onMouseOver={() => setRemoveTip(true)}
              onMouseOut={() => setRemoveTip(false)}
              className="inline-flex items-center justify-center w-5 h-5 ml-2 text-sm rounded-full"
              style={{ backgroundColor: '#5F5F5F', color: '#171612' }}
            >
              ?
            </span>
            {removeTip && <div style={{backdropFilter: 'blur(20px)', transform: 'translate(0%, 100%)'}} className="absolute w-full px-4 py-2 text-xs l-0 t-0 sm:w-1/2 bg-transYellow rounded-2xl">Get your money back by removing liquidity from pool</div>}
          </div>
          <div className="flex flex-col justify-between mt-4 sm:flex-row sm:mt-10">
            <div className="mb-4 ml-5 text-base sm:text-2xl text-title sm:mb-0">Your position</div>
            <div className="flex items-center">
              <img
                src={coinIcons[poolInfo.firstDepositTokenInfo.icon!] || poolInfo.firstDepositTokenInfo.icon || logoUndefined}
                alt="Coin one"
                className="z-10 w-8 h-8"
              />
              <img
                src={coinIcons[poolInfo.secondDepositTokenInfo.icon!] || poolInfo.secondDepositTokenInfo.icon || logoUndefined}
                alt="Coin two"
                className="w-8 h-8 -ml-2"
              />
              <div className="text-xl sm:ml-3 text-description">
                {poolInfo.firstDepositTokenInfo.symbol}/
                {poolInfo.secondDepositTokenInfo.symbol}
              </div>
            </div>
          </div>
          <div
            className="flex flex-col justify-between mt-3 sm:flex-row gap-2 sm:gap-8 rounded-2xl"
          >
            <div>
              <div className="text-lg sm:text-xl text-description">
                {poolInfo.firstDepositTokenInfo.symbol}
              </div>
              <div className="text-lg sm:text-1xl text-title">
                {formatNumber((
                  (poolInfo.liquidity / poolInfo.totalSupply) *
                  poolInfo.firstDepositTokenAmount!
                ).toFixed(6))}
              </div>
            </div>
            <div>
              <div className="text-lg sm:text-xl text-description">
                {poolInfo.secondDepositTokenInfo.symbol}
              </div>
              <div className="text-lg sm:text-1xl text-title">
                {formatNumber((
                  (poolInfo.liquidity / poolInfo.totalSupply) *
                  poolInfo.secondDepositTokenAmount!
                ).toFixed(4))}
              </div>
            </div>
            <div>
              <div className="text-lg sm:text-xl text-description">
                LP tokens
              </div>
              <div className="text-lg sm:text-1xl text-title">
                {formatNumber(poolInfo.liquidity)}
              </div>
            </div>
          </div>
          <div className="flex justify-between my-5">
            <div className="text-base text-title sm:text-2xl sm:ml-5">
              Remove amount
            </div>
            <div className="text-base text-description sm:text-xl">
              Advanced setting
            </div>
          </div>
          <div className="flex-1 mb-4">
          <div className="relative">
            <input
              autoFocus
              type="number"
              value={removeLpAmount}
              onChange={e => handlRemoveLiquidityInput(e)}
              className={` ${+removeLpAmount > poolInfo.liquidity ? 'border-red focus:border-red' : true ? 'border-green' : 'focus:border-yellow'}  w-full leading-9 rounded-t-lg bg-input bg-opacity-50 px-4 py-0.5 border-b focus:outline-none focus:bg-opacity-100`}
            />
            <div className="text-description absolute top-2.5 right-2 text-sm">
            {/* {getTokenInfoByAddress(farmingStakeInfo.firstTokenAddress).name.toUpperCase()}-{getTokenInfoByAddress(farmingStakeInfo.secondTokenAddress).name.toUpperCase()} LP */}
            </div>
            <div className="text-sm whitespace-nowrap ">
              ~ {formatNumber((+removeLpAmount * (poolInfo.lpPriceInUsd || 1)).toFixed(2))} $
            </div>
            <div className="text-sm text-red whitespace-nowrap">
              { +removeLpAmount > poolInfo.liquidity
                ? 'Value is greater than your balance' 
                : ''}
            </div>
          </div>
        </div>
          <Range
            step={1}
            min={0}
            max={100}
            values={state}
            onChange={values => {
              setRemoveLpAmount(((poolInfo.liquidity / 100) * values[0]).toString())
              setState(values)
            }}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                className="w-full p-1 rounded-lg bg-yellow"
                style={{
                  ...props.style,
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                className="border-4 rounded-lg border-darkGray focus:outline-none"
                style={{
                  ...props.style,
                }}
              >
                <div className="border w-0.5 h-5 bg-lightBlack border-lightBlack"></div>
              </div>
            )}
          />
          <div className="flex justify-center mt-5 gap-2 sm:mx-10 sm:mt-10">
            <div
              className="px-3 py-3 text-sm cursor-pointer sm:px-5 rounded-xl text-yellow sm:text-xl"
              style={{ backgroundColor: '#201F1B' }}
            >
              {`${state[0].toFixed(2)}%`}
            </div>
            <div
              className="px-3 py-3 text-sm cursor-pointer sm:px-5 rounded-xl text-yellow sm:text-xl"
              style={{ backgroundColor: '#201F1B' }}
              onClick={e => {
                setRemoveLpAmount(((poolInfo.liquidity / 100) * 25).toString())
                setState([25])
              }}
            >
              25%
            </div>
            <div
              className="px-3 py-3 text-sm cursor-pointer sm:px-5 rounded-xl text-yellow sm:text-xl"
              style={{ backgroundColor: '#201F1B' }}
              onClick={e => {
                setRemoveLpAmount(((poolInfo.liquidity / 100) * 50).toString())
                setState([50])
              }}
            >
              50%
            </div>
            <div
              className="px-3 py-3 text-sm cursor-pointer sm:px-5 rounded-xl text-yellow sm:text-xl"
              style={{ backgroundColor: '#201F1B' }}
              onClick={e => {
                setRemoveLpAmount(((poolInfo.liquidity / 100) * 75).toString())
                setState([75])
              }}
            >
              75%
            </div>
            <div
              className="px-3 py-3 text-sm cursor-pointer sm:px-5 rounded-xl text-yellow sm:text-xl"
              style={{ backgroundColor: '#201F1B' }}
              onClick={e => {
                setRemoveLpAmount(((poolInfo.liquidity / 100) * 100).toString())
                setState([100])
              }}
            >
              MAX
            </div>
          </div>
          <div className="flex items-center justify-between mt-5 sm:mt-10">
            <div>
            <div className="text-base sm:text-2xl text-title">
                {formatNumber(((poolInfo.liquidity / poolInfo.totalSupply) *
                  poolInfo.firstDepositTokenAmount! *
                  (state[0] / 100)).toFixed(4))}
              </div>
              <div className="flex items-center mt-2 text-xl text-description">
                <img
                  src={coinIcons[poolInfo.firstDepositTokenInfo.icon!] || poolInfo.firstDepositTokenInfo.icon || logoUndefined}
                  alt="Coin one"
                  className="z-10 w-4 h-4 mr-2 sm:w-8 sm:h-8"
                />
                <span className='text-base sm:text-xl' >{poolInfo.firstDepositTokenInfo.symbol}</span>
              </div>
            </div>
            <div>
              <ArrowsIcon />
            </div>
            <div>
              <div className="text-base sm:text-2xl text-title">
              {formatNumber(((poolInfo.liquidity / poolInfo.totalSupply) *
                  poolInfo.secondDepositTokenAmount! *
                  (state[0] / 100)).toFixed(4))}
              </div>
              <div className="flex items-center mt-2 text-xl text-description">
                <img
                  src={coinIcons[poolInfo.secondDepositTokenInfo.icon!] || poolInfo.secondDepositTokenInfo.icon || logoUndefined}
                  alt="Coin one"
                  className="w-4 h-4 mr-2 sm:w-8 sm:h-8 z-1"
                />
                <span className='text-base sm:text-xl' >{poolInfo.secondDepositTokenInfo.symbol}</span>
              </div>
            </div>
          </div>
          <div className="mt-4 text-center">
            <ButtonProcess 
              name="Remove"
              disabled={!state[0]}
              onClick={() => state[0] && handlRemoveLiquidity()}
              classname="inline-flex justify-center w-full px-6 py-2 text-base font-medium border border-transparent rounded-md shadow-sm bg-yellow text-mainBlack hover:bg-black-700 focus:outline-none focus:ring-2 ocus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-xl"
            />
          </div>
        </Modal>
    </>
  );
};

export default RemoveLiquidity;
