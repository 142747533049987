import React, { FunctionComponent } from 'react';
import { WalletConnectActionCreator } from 'store/reducers/wallet/action-creators';
import { useDispatch } from 'react-redux';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import { launchpoolActionCreator } from 'store/reducers/launchpool/action-creator';

interface Props{
  personal?: boolean
  onClick?: Function
}

const ConnectWalletButton: FunctionComponent<Props> = ({personal, onClick}) => {
  const dispatch = useDispatch();
  const connectWallet = () => {
    dispatch(WalletConnectActionCreator.connectWallet());
    return true
  };

  return (
    <button
      className={`bg-yellow bg-opacity-10 z-40 text-yellow uppercase text-sm sm:text-base ${personal && 'w-full'} px-6 py-2 rounded-lg border border-transparent hover:border-yellow border-opacity-60`}
      onClick={() => {
        connectWallet() && onClick && onClick()
      }}
    >
      Connect wallet
    </button>
  );
}

export default ConnectWalletButton
