import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { coinIcons } from 'components/CoinIcons';
import Tooltip from 'components/Tooltip';
import useWalletSelector from 'hooks/useWalletSelector';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';
import { Range } from 'react-range';
import { useDispatch } from 'react-redux';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';

const Claim = () => {
  const { farmingStakeInfo, farmingList } = useWalletSelector(state => state.farmingReducer)
  const [canBeUnstaked, setCanBeUnstaked] = useState(false)
  const [state, setState] = useState([0]);
  const dispatch = useDispatch()

  let lpPriceInUsd = farmingList.filter(el => el.lpToken === farmingStakeInfo.lpToken )[0].usdPerLp

  

  const handleUnstakeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let unstakeInput: number
    unstakeInput = e.currentTarget.value === '' ? 0 : parseFloat(e.currentTarget.value);
    unstakeInput = +unstakeInput.toFixed(6)
    dispatch(farmingActionCreator.setUnstakeInput(unstakeInput.toString()))
  }

  const handleRange = () => {
    let unstakeInput = (state[0] / 100) * +getInfoByLp('lpStaked')
    unstakeInput = +unstakeInput.toFixed(6)
    dispatch(farmingActionCreator.setUnstakeInput(unstakeInput.toString()))
  }

  const getInfoByLp = (type: string) => {
    return farmingList.filter( el => el.lpToken === farmingStakeInfo.lpToken )[0][type]
  }

  useEffect(() => {
    if(!+farmingStakeInfo.unstakeInput || +farmingStakeInfo.unstakeInput > +getInfoByLp('lpStaked') ) setCanBeUnstaked(false)
    else setCanBeUnstaked(true)
  }, [farmingStakeInfo.unstakeInput])

  useEffect(() => {
    handleRange()
  }, [state])

  return (
    <div>
      <div className="text-xl text-title mt-5">Withdraw amount</div>
      <div className="flex flex-wrap items-start gap-4 mt-5 mb-10">
        <div className="flex-1">
          <div className="relative">
            <input
              onChange={(e) => handleUnstakeInput(e)}
              type="number"
              value={farmingStakeInfo.unstakeInput}
              className={` ${+farmingStakeInfo.unstakeInput > +getInfoByLp('lpStaked') ? 'border-red focus:border-red' : canBeUnstaked ? 'border-green' : 'focus:border-yellow'}  w-full leading-9 rounded-t-lg bg-input bg-opacity-50 px-4 py-0.5 border-b focus:outline-none focus:bg-opacity-100`}
            />
            <div className="text-description absolute top-2.5 right-2 text-sm">
              {getTokenInfoByAddress(farmingStakeInfo.firstTokenAddress).name.toUpperCase()}-{getTokenInfoByAddress(farmingStakeInfo.secondTokenAddress).name.toUpperCase()} LP
            </div>
            <div className="text-sm whitespace-nowrap ">
              ~ {(farmingStakeInfo.unstakeInput * lpPriceInUsd).toFixed(2)} $
            </div>
            <div className="text-sm text-red whitespace-nowrap">
              { +farmingStakeInfo.unstakeInput > +getInfoByLp('lpStaked')
                ? 'Value is greater than your balance' 
                : ''}
            </div>
          </div>
        </div>
        {/* <div className="flex items-center justify-center bg-yellow bg-opacity-15 rounded-lg text-yellow px-4 py-2">
          Max
        </div> */}
      </div>
      <Range
            step={1}
            min={0}
            max={100}
            values={state}
            onChange={values => {
              setState(values)
            }}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                className="rounded-lg w-full p-1 bg-yellow"
                style={{
                  ...props.style,
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                className="border-4 border-darkGray rounded-lg focus:outline-none"
                style={{
                  ...props.style,
                }}
              >
                <div className="border w-0.5 h-5 bg-lightBlack border-lightBlack"></div>
              </div>
            )}
          />
          <div className="flex justify-center gap-2  sm:mt-5">
            <div
              className="px-3 py-3 rounded-xl cursor-pointer text-yellow text-sm sm:text-base"
              style={{ backgroundColor: '#201F1B' }}
            >
              {`${state}%`}
            </div>
            <div
              className="px-3 py-3 rounded-xl cursor-pointer text-yellow text-sm sm:text-base"
              style={{ backgroundColor: '#201F1B' }}
              onClick={e => setState([25])}
            >
              25%
            </div>
            <div
              className="px-3 py-3 rounded-xl cursor-pointer text-yellow text-sm sm:text-base"
              style={{ backgroundColor: '#201F1B' }}
              onClick={e => setState([50])}
            >
              50%
            </div>
            <div
              className="px-3 py-3 rounded-xl cursor-pointer text-yellow text-sm sm:text-base"
              style={{ backgroundColor: '#201F1B' }}
              onClick={e => setState([75])}
            >
              75%
            </div>
            <div
              className="px-3 py-3 rounded-xl cursor-pointer text-yellow text-sm sm:text-base"
              style={{ backgroundColor: '#201F1B' }}
              onClick={e => setState([100])}
            >
              MAX
            </div>
          </div>
      <div className="flex flex-col justify-between sm:flex-row sm:items-end sm:gap-20 mt-5">
        <div className="text-description text-base">Available to withdraw</div>
        <div className="text-lg">{getInfoByLp('lpStaked')} {getTokenInfoByAddress(farmingStakeInfo.firstTokenAddress).name.toUpperCase()}-{getTokenInfoByAddress(farmingStakeInfo.secondTokenAddress).name.toUpperCase()} LP</div>
      </div>
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mt-2">
        <div className="text-description text-base">Available to claim</div>
        <div className="flex items-center gap-5">
          <div className="text-lg">{getInfoByLp('personalEarn')} EXON</div>
          {/* <button disabled={!+getInfoByLp('personalEarn')} onClick={() => {
            dispatch(farmingActionCreator.setWithdrawAmount(getInfoByLp('personalEarn')))
            dispatch(farmingActionCreator.getWithdraw(farmingStakeInfo.lpToken, 0))
          }} className={`flex items-center justify-center ${ !!+getInfoByLp('personalEarn') ? 'bg-yellow bg-opacity-15' : 'bg-lightBlack cursor-default' } rounded-lg text-yellow px-3 py-1`}>
            Claim
          </button> */}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:items-end justify-between mt-2">
        <div className="text-description text-base">APR</div>
        <div className="text-lg">{getInfoByLp('apr')}%</div>
      </div>
      {/* <div className="text-xl mt-5">My mining rewards</div>
      <div className="flex flex-col sm:justify-between sm:flex-row sm:items-end mt-2">
        <div className="text-description text-base">Total undistributed</div>
        <div className="text-lg">{'<$0.001'}</div>
      </div>
      <div
        className="flex flex-col sm:flex-row gap-5 sm:gap-0 justify-between rounded-2xl p-3 mt-2"
        style={{ backgroundColor: '#4F4F4F' }}
      >
        <div>
          <div className="text-base text-title">Currency</div>
          <div className="flex mt-1">
            <img src={coinIcons['exon']} alt="EXON" className="w-5 mr-2" />
            EXON
          </div>
        </div>
        <div>
          <div className="text-base text-title">
            Upcoming
            <Tooltip id="upcoming" place="right">
              Tooltip
            </Tooltip>
          </div>
          <div className="flex mt-1">{'<$0.001'}</div>
        </div>
        <div>
          <div className="text-base text-title">
            Frozen
            <Tooltip id="upcoming" place="right">
              Tooltip
            </Tooltip>
          </div>
          <div className="flex mt-1">{'<$0.001'}</div>
        </div>
      </div> */}
      <div onClick={() => dispatch(farmingActionCreator.unstakeLpTokens())} className="flex justify-center mt-10">
        <Button disabled={!canBeUnstaked} >Withdraw</Button>
      </div>
    </div>
  );
};

export default Claim;
