import React, { FunctionComponent, useState } from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Arrow } from 'components/Icons';
import ProgramInfo from '../ProgramInfo';
import {FarmingInfo} from 'store/reducers/account/types';

SwiperCore.use([Pagination]);

interface Props {
  personalSwap: number;
  currentAccountLevel: number;
  currentRangLevel: number;
  withdrawedLevels: number;
  farmingInfo: FarmingInfo;
}

const RangeProgramSlider: FunctionComponent<Props> = ({personalSwap, currentAccountLevel, currentRangLevel, withdrawedLevels, farmingInfo}) => {
  const [swiper, setSwiper] = useState<SwiperCore>();

  const programInfo = [
    {level: 1, turnover: '5', title: "Shrimp", priceIn: "$1"},
    {level: 2, turnover: '50', title: "Crab", priceIn: "$10"},
    {level: 3, turnover: '250', title: "Fish", priceIn: "$100"},
    {level: 4, turnover: '500', title: "Dolphin", priceIn: "$500"},
    {level: 5, turnover: '2500', title: "Shark", priceIn: "$1 000"},
    {level: 6, turnover: '5000', title: "Whale", priceIn: "$10 000"},
  ]

  // console.log({personalSwap, currentAccountLevel, currentRangLevel, withdrawedLevels});


  return (
    <Swiper
      className="w-full sm:hidden"
      pagination={{
        clickable: true,
        el: '.swiper-pagination',
        type: 'bullets',
      }}
      spaceBetween={50}
      slidesPerView={1}
      onSwiper={swiper => setSwiper(swiper)}
    >
      {programInfo.map(el => {
          return <SwiperSlide key={el.level}>
                  <ProgramInfo
                    activeTurnover={
                      (farmingInfo.firstTokenTurnover >= +el.turnover)
                      && (farmingInfo.secondTokenTurnover >= +el.turnover)
                    }
                    activeLevel={(farmingInfo.currentAccountLevel >= el.level)
                      && (farmingInfo.currentRangLevel >= el.level || farmingInfo.currentRangLevel + 1 === el.level)}
                    activeWithdraw={farmingInfo.withdrawedLevels >= el.level }
                    nextLevel={farmingInfo.currentRangLevel + 1 === el.level}
                    active={farmingInfo.currentRangLevel >= el.level}
                    level={el.level}
                    turnover={el.turnover}
                    title={el.title}
                    priceIn={el.priceIn}

                  />
                </SwiperSlide>
        })}
      <div className="flex items-center justify-between mt-10">
        <div onClick={() => swiper?.slidePrev()}>
          <Arrow className="w-8 h-8" />
        </div>
        <div className="swiper-pagination"></div>
        <div onClick={() => swiper?.slideNext()}>
          <Arrow className="w-8 h-8 transform -rotate-180" />
        </div>
      </div>
    </Swiper>
  );
};

export default RangeProgramSlider;
