export interface multisendState  {
	sendAddresses: string[];
	amountPerAddress: number;
	tokenAddress: tokenInfo;
	subscribePlans: subscribePlans[];
	choosenSubscription: number;
	subscribePeriod: subscribeInfo | null;
	oneAddressPrice: number;
	multisendState: string;
	whitelisted: boolean;
	feeTypes: feeTypes;
}

export interface subscribePlans {
	time: number;
	price: number;
}

export interface feeTypes {
	sendFee: feeType;
	subscribeFee: feeType;
}

export interface feeType {
	isTrx: boolean;
	tokenAddress: tokenInfo;
}

export interface subscribeInfo {
	period: number;
	id: number;
}

export interface tokenInfo {
	name: string;
	icon: string;
	decimals: number;
	address: string;
}

export enum multisendStateEnum {
	SET_SUBSCRIBE_PLANS = 'SET_SUBSCRIBE_PLANS',
	SET_SEND_ADDRESSES = 'SET_SEND_ADDRESSES',
	SET_CHOOSEN_SUBSCRIPTION = 'SET_CHOOSEN_SUBSCRIPTION',
	SET_TOKEN_ADDRESS = 'SET_TOKEN_ADDRESS',
	SET_AMOUNT_PER_ADDRESS = 'SET_AMOUNT_PER_ADDRESS',
	SET_SUBSCRIBE_PERIOD = 'SET_SUBSCRIBE_PERIOD',
	SET_ONE_ADDRESS_PRICE = 'SET_ONE_ADDRESS_PRICE',
	SET_MULTISEND_STATE = 'SET_MULTISEND_STATE',
	SET_WHITELISTED = 'SET_WHITELISTED',
	SET_FEE_TYPES = 'SET_FEE_TYPES'
}

export interface setFeeTypes {
	type: multisendStateEnum.SET_FEE_TYPES;
	payload: feeTypes
}

export interface setMultisendState {
	type: multisendStateEnum.SET_MULTISEND_STATE;
	payload: string
}

export interface setWhitelisted {
	type: multisendStateEnum.SET_WHITELISTED;
	payload: boolean
}

export interface setOneAddressPrice {
	type: multisendStateEnum.SET_ONE_ADDRESS_PRICE;
	payload: number
}

export interface setSubscribePeriod {
	type: multisendStateEnum.SET_SUBSCRIBE_PERIOD;
	payload: subscribeInfo | null
}

export interface setSubscribePlans {
	type: multisendStateEnum.SET_SUBSCRIBE_PLANS;
	payload: subscribePlans[]
}

export interface setSendAddresses {
	type: multisendStateEnum.SET_SEND_ADDRESSES,
	payload: string[]
}

export interface setChoosenSubscription {
	type: multisendStateEnum.SET_CHOOSEN_SUBSCRIPTION,
	payload: number
}

export interface setTokenAddress {
	type: multisendStateEnum.SET_TOKEN_ADDRESS,
	payload: tokenInfo
}

export interface setAmountPerAddress {
	type: multisendStateEnum.SET_AMOUNT_PER_ADDRESS,
	payload: number
}

export type multisendCurrentState = multisendState;
export type multisendAction = 
	| setSubscribePlans
	| setSendAddresses
	| setChoosenSubscription
	| setTokenAddress
	| setAmountPerAddress
	| setSubscribePeriod
	| setOneAddressPrice
	| setMultisendState
	| setWhitelisted
	| setFeeTypes;
