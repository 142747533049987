import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
import reducers from './reducers';

const RootReducer = combineReducers(reducers);
export const store = createStore(RootReducer,  composeWithDevTools(applyMiddleware(thunk)));
export type RootState = ReturnType<typeof store.getState>;
export type ReduxState = ReturnType<typeof RootReducer>;
export type getRootState = typeof store.getState;
export type AppDispatch = typeof store.dispatch;
