import React, { FunctionComponent } from 'react';
import { coinIcons } from '../../CoinIcons';
import s from './ButtonSelectCoin.module.css'
import logoUndefined from '../../../components/CoinIcons/assets/logoundefined.png'

export interface props {
  icon: string;
  name: string;
  regExp?: any;
  imported?: boolean;
}

const ButtonSelect: FunctionComponent<props> = ({ icon, name, imported, regExp }) => {


  const nameRegExp = name.match(regExp)

  const highlightedText = () => {
    let match = 0
    let newName = name
    // console.log(nameRegExp);
    
    nameRegExp?.map(el => {
      newName = newName.replace(el, '*')
    })
    let words = newName.split('').map(el => {
      if(el === '*' && nameRegExp) {
        return <span style={{color: 'black', background: 'yellow'}}>{nameRegExp[match]}</span>
      }
      else return <span>{el}</span>
    })

    return words
  }


  return (
    <>
      <button
        style={{width: '90px'}}
        className="inline-flex
        rounded-xl border border-transparent shadow-sm px-2
        py-2 bg-button text-title font-medium 
         hover:bg-black focus:outline-none 
         focus:ring-2 focus:ring-offset-2 
         focus:ring-black-500 
         sm:w-auto sm:text-sm justify-between"
      >
        <span className="flex justify-center items-center w-18">
          <img src={imported ? (icon || logoUndefined) : coinIcons[icon]} alt="coin" className="w-4" />
          <div className="px-2">{highlightedText()}</div>
        </span>
      </button>
    </>
  );
};

export default ButtonSelect;
