import React, { FunctionComponent, useState } from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Arrow } from 'components/Icons';
import PairCard from '../PairCard';

SwiperCore.use([Pagination]);

const PairsSlider: FunctionComponent = () => {
  const [swiper, setSwiper] = useState<SwiperCore>();

  return (
    <div className="flex sm:hidden items-center relative gap-4">
      <div
        className="-mt-2 hidden lg:block"
        onClick={() => swiper?.slidePrev()}
      >
        <Arrow className="w-8 h-8" />
      </div>
      <Swiper
        className="w-full mt-5 relative "
        loop
        lazy
        autoHeight
        pagination={{
          clickable: true,
          el: '.swiper-pagination',
          type: 'bullets',
        }}
        slidesPerView={1}
        spaceBetween={20}
        onSwiper={swiper => {
          setSwiper(swiper);
        }}
        onInit={swiper => {
          setTimeout(() => {

            swiper.slideNext(10);
          }, 10000);
        }}
      >
        <SwiperSlide>
          <PairCard
            coinOneIcon="usdt"
            coinTwoIcon="exon"
            coinOneName="USDT"
            coinTwoName="EXON"
            liquidity="$1 000 000"
            apy="100%"
          />
        </SwiperSlide>
        <SwiperSlide>
          <PairCard
            coinOneIcon="trx"
            coinTwoIcon="exon"
            coinOneName="TRX"
            coinTwoName="EXON"
            liquidity="$1 000 000"
            apy="100%"
          />
        </SwiperSlide>
        <SwiperSlide>
          <PairCard
            coinOneIcon="trx"
            coinTwoIcon="usdt"
            coinOneName="TRX"
            coinTwoName="USDT"
            liquidity="$1 000 000"
            apy="100%"
          />
        </SwiperSlide>

        <div className="swiper-pagination mt-5"></div>
      </Swiper>
      <div
        className="-mt-2  hidden lg:block"
        onClick={() => swiper?.slideNext(10)}
      >
        <Arrow className="w-8 h-8 transform -rotate-180" />
      </div>
    </div>
  );
};

export default PairsSlider;
