import {SecondsContext} from "App"
import {useContext, useEffect, useState} from "react"

export default () => {

	const { seconds } = useContext(SecondsContext)

	const [choosenGuide, setChoosenGuide] = useState(0)
	const [isClicked, setIsClicked] = useState(false)
	const guideArr = [
		'Make sure your wallet is on Tron Mainnet',
		'Calculate fee in special block by inserting amount of addresses you want tokens to be sent and clicking Results',
		'Buy our subscription and we will not take any extra fee of your transactions',
		'Select the token you wish and enter its address into the "Token contract address" field',
		'If you want to send TRX instead of default trc20 tokens, just tick the Send TRX checkbox',
		'Enter amount of tokens you want to send to your addresers',
		'Insert addresses of users you want tokens to be sent or fill it by click upload CSV',
		'Click upload CSV and select your airdrop list (format must be address then balance separated by a comma and in the same column)',
		'Click Send (This will prompt a transactions in your wallet to approve amount you want to send.)',
		'Then Confirm primary transaction'
	]

	useEffect(() => {
		if(seconds%5 === 0 && !isClicked){
			setChoosenGuide(choosenGuide === 9 ? 0 : choosenGuide+1)
		}
	}, [seconds])

	return (
		<div className='w-full px-4 py-3 sm:px-8 xl:w-1/2 rounded-2xl sm:rounded-4xl sm:py-7 bg-cardBg'>
			<div className='text-lg flex items-center justify-center text-center font-normal h-44 rounded-4xl bg-buttonBlack p-7 text-title'>
				{guideArr[choosenGuide]}
			</div>
			<div className='flex flex-col flex-wrap mt-6 sm:mt-12'>
				<div className='flex justify-between mb-5'>
				{guideArr.slice(0, 5).map((el, index) => {
					return <div 
									className={` ${choosenGuide === index ? 'border-yellow' : 'bg-buttonBlack border-transparent' }  
															border-2 rounded-full flex cursor-pointer items-center justify-center w-8 h-8 sm:w-12 sm:h-12
															text-description font-bold text-base sm:text-xl  `} 
									onClick={() => {
										setIsClicked(true)
										setChoosenGuide(index)
									}} >
						<span>{index + 1}</span>
					</div>
				})}
				</div>
				<div className='flex justify-between'>
				{guideArr.slice(5).map((el, index) => {
					return <div 
									className={` ${choosenGuide === (index + 5) ? 'border-yellow' : 'bg-buttonBlack border-transparent' }  
															border-2 rounded-full flex cursor-pointer items-center justify-center w-8 h-8 sm:w-12 sm:h-12
															text-description font-bold text-base sm:text-xl  `} 
									onClick={() => {
										setIsClicked(true)
										setChoosenGuide(index + 5)
									}} >
						<span>{index + 6}</span>
					</div>
				})}
				</div>
			</div>
		</div>
	)

}
