import Block from 'components/Block';
import React, { FunctionComponent } from 'react';
import PossibilitiesSlider from './components/PossibilitiesSlider';
import { useDispatch } from 'react-redux';
import { WalletConnectActionCreator } from 'store/reducers/wallet/action-creators';
import { useHistory } from 'react-router';

const Possibilities: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const connectWallet = (e: any) => {
    dispatch(WalletConnectActionCreator.connectWallet());
    history.push('/exonnft');
  };

  return (
    
    <>
      <div className="flex-wrap hidden w-full mt-4 sm:flex lg:flex-nowrap gap-5">
        <div className="flex flex-wrap gap-5 sm:w-full lg:w-8/12">
          <div className="flex w-full gap-5">
            <Block small className="flex-1">
              <p className="text-description">
                Your business in one NFT account
              </p>
              <div className="mt-3 text-3xl font-semibold text-lightYellow">
                Free collection
              </div>
              <button
                onClick={e => connectWallet(e)}
                className="w-full py-2 mt-8 text-xl tracking-wide text-black rounded-lg bg-yellow"
              >
                Get your NFT
              </button>
            </Block>
            <Block small className="flex-1">
              <p className="text-description">
                Higher income for liquidity providers
              </p>
              <div className="mt-3 text-3xl font-semibold text-lightYellow">
                100%
              </div>
              <button onClick={() => history.push('/swap')} className="w-full py-2 mt-8 text-xl tracking-wide text-black rounded-lg bg-yellow">
                Try swap
              </button>
            </Block>
          </div>
          <div className="flex w-full gap-5">
            <Block small className="flex-1 filter brightness-50">
              <p className="text-description">Stake LP tokens and earn up to</p>
              <div className="mt-3 text-3xl font-semibold text-lightYellow">
                10 000%
              </div>
              <button className="w-full py-2 mt-8 text-xl tracking-wide text-black rounded-lg bg-yellow">
                Start farming
              </button>
            </Block>
            <Block small className="flex-1 filter brightness-50">
              <p className="text-description">
                Be a part of a platform via voting
              </p>
              <div className="mt-3 text-3xl font-semibold text-lightYellow">
                Vote for features
              </div>
              <button className="w-full py-2 mt-8 text-xl tracking-wide text-black rounded-lg bg-yellow">
                Start voting
              </button>
            </Block>
          </div>
        </div>
        <div className="flex sm:w-full lg:w-4/12">
          <Block small className="flex flex-col justify-between">
            <div>
              <p className="text-description">
                Personal Liquidity in DEX. By distributing a personal liquidity
                link, you can increase the number of trades in previously assets
                assets and earn more on commissions.
              </p>
              <div className="mt-3 text-3xl font-semibold text-lightYellow">
                Gain your profit now
              </div>
            </div>
            <button  onClick={() => history.push('/swap/liquidity')}  className="w-full py-2 mt-2 text-xl tracking-wide text-black rounded-lg bg-yellow">
              Add liquidity
            </button>
          </Block>
        </div>
      </div>
      <Block
        small
        className="flex-col items-center justify-between flex-1 hidden mt-8 sm:flex lg:flex-row"
      >
        <div className="pr-10">
            Exon Pool is innovative decentralized system. Fast income with the only condition, you need to have tokens to take part in pool.
        </div>
        <a href='/launchpool' className="px-4 py-2 text-xl tracking-wide text-black rounded-lg bg-yellow whitespace-nowrap">
          Go to launchpool
        </a>
      </Block>
      <Block
        small
        className="flex-col items-center justify-between flex-1 hidden mt-8 sm:flex lg:flex-row"
      >
        <div className="pr-10">
           Exon IDO is unique launchpad where you can maximize your profit by participating in the initial DEX offerings
        </div>
        <a href='/launchpad' className="px-4 py-2 text-xl tracking-wide text-black rounded-lg bg-yellow whitespace-nowrap">
          Go to launchpad
        </a>
      </Block>
      <PossibilitiesSlider />
    </>
  );
};

export default Possibilities;
