import Modal from "components/Modal"
import useWalletSelector from "hooks/useWalletSelector"
import { useState } from "react"
import { Range } from "react-range"
import s from './LevelBoost.module.css'
import { ReactComponent as Time } from './assets/time.svg'
import { ReactComponent as Lvlup } from './assets/lvlup.svg'
import { ReactComponent as Price } from './assets/price.svg'
import Button from "components/Button"
import {useDispatch} from "react-redux"
import {accountActionCreator} from "store/reducers/account/action-creators"

export default () => {

    const { farmingInfo } = useWalletSelector( state => state.AccountReducer )
    const [boostTime, setBoostTime] = useState([300])
    const dispatch = useDispatch()
    return  (
        <Modal closeModal={() => dispatch(accountActionCreator.setBoostLevelChosen(0))}>
            <h1 className="relative text-2xl text-center text-title sm:text-4xl" >Level boost</h1>
            <div className="relative mt-4 text-lg text-center text-title sm:text-xl">Get the most from your referals</div>
            <div className="justify-between my-8 gap-4">
                <div className="flex items-center justify-center text-3xl font-medium text-center">
                    <Lvlup className="w-8 h-8 mr-4" />
                    <span className="" >Level up {farmingInfo.currentAccountLevel}</span>
                    <span className={s.arrow} >{`-->`}</span>
                    <span className="text-yellow " >{farmingInfo.currentAccountLevel+1}</span>
                </div>
            </div>
            <div className="my-4">Choose your boost time</div>
            <Range
                step={300}
                min={300}
                max={900}
                values={boostTime}
                onChange={values => {
                setBoostTime(values)
                }}
                renderTrack={({ props, children }) => (
                <div
                    {...props}
                    className="w-full p-1 rounded-lg bg-yellow"
                    style={{
                    ...props.style,
                    }}
                >
                    {children}
                </div>
                )}
                renderThumb={({ props }) => (
                <div
                    {...props}
                    className="border-4 rounded-lg border-darkGray focus:outline-none"
                    style={{
                    ...props.style,
                    }}
                >
                    <div className="border w-0.5 h-5 bg-lightBlack border-lightBlack"></div>
                </div>
                )}
            />
            <div className="flex items-center justify-between mt-4">
            <div className="flex items-center text-2xl ">
                    <Time className="w-6 h-6 mr-2" />
                    <span className="mr-2 font-bold text-yellow">{boostTime[0] / 60}</span>
                    <span className="">minutes</span>
                </div>
                <div className="flex items-center text-2xl ">
                    <Price className="w-6 h-6 mr-2" />
                    <span>Price</span>
                    <span className="mx-2 font-bold text-yellow">1000</span>
                    <span className="">TRX</span>
                </div>
            </div>
            <div className="mt-8 text-center">
                <Button>Boost!</Button>
            </div>
        </Modal>
    )
}
