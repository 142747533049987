import Layout from 'components/Layout';
import React from 'react';
// import { Helmet } from 'react-helmet-async';
import Intro from './components/Intro';
import Pairs from './components/Pairs';
import Possibilities from './components/Possibilities';

export function HomePage() {
  return (
    <>
      {/* <Helmet>
        <title>Home Page</title>
        <meta
          name="description"
          content="A React Boilerplate application homepage"
        />
      </Helmet> */}
      <Layout>
        <div className="container">
          <Intro />
          <Possibilities />
          <Pairs />
        </div>
      </Layout>
    </>
  );
}
