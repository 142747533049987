import React from 'react';
import { Account } from 'pages/Account';
import { HomePage } from 'pages/HomePage';
import { Farming } from 'pages/Farming';
import { SwapPage } from 'pages/Swap';
import { Voting } from 'pages/Voting';
import { PairAnalytics } from 'pages/Analytics/Components/PairAnalytics';
import { Launchpad } from 'pages/Launchpad';
import { Analytics } from 'pages/Analytics';
import {Multisend} from 'pages/Multisend';
import PoolInfo from 'pages/Launchpad/components/PoolInfo';
import {Launchpool} from 'pages/Launchpool';
import LaunchpoolInfo from 'pages/Launchpool/components/PoolInfo';

export interface IRoute {
  path: string;
  component: React.ComponentType;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  ACCOUNT = '/exonnft',
  FARMING = '/farming',
  VOTING = '/voting',
  MULTISEND = '/multisend',
  SWAP = '/swap/:pool?/:pair?',
  ANALYTICS = '/analytics',
  PAIRANALYTICS = '/analytics/:id?',
  LAUNCHPAD = '/launchpad',
  LAUNCHPADITEM = '/launchpad/:id?',
  LAUNCHPOOL = '/launchpool',
  LAUNCHPOOLITEM = '/launchpool/:id?',
  REFERAL = '/:id',
}

export const publicRoutes: IRoute[] = [
  { path: RouteNames.HOME, exact: true, component: HomePage },
  { path: RouteNames.SWAP, exact: true, component: SwapPage },
  { path: RouteNames.VOTING, exact: true, component: Voting },
  { path: RouteNames.ANALYTICS, exact: true, component: Analytics },
  { path: RouteNames.PAIRANALYTICS, exact: true, component: PairAnalytics },
  { path: RouteNames.LAUNCHPAD, exact: true, component: Launchpad },
  { path: RouteNames.LAUNCHPADITEM, exact: true, component: PoolInfo },
  { path: RouteNames.LAUNCHPOOL, exact: true, component: Launchpool },
  { path: RouteNames.LAUNCHPOOLITEM, exact: true, component: LaunchpoolInfo },
  { path: RouteNames.MULTISEND, exact: true, component: Multisend },
  { path: RouteNames.REFERAL, exact: true, component: HomePage },
];

export const privateRoutes: IRoute[] = [
  { path: RouteNames.ACCOUNT, exact: true, component: Account },
  { path: RouteNames.FARMING, exact: true, component: Farming },
];
