import Rang1 from '../assets/rang1.svg';
import Rang2 from '../assets/rang2.svg';
import Rang3 from '../assets/rang3.svg';
import Rang4 from '../assets/rang4.svg';
import Rang5 from '../assets/rang5.svg';
import Rang6 from '../assets/rang6.svg';

export interface Ranges {
  [Rang1: string]: string;
  Rang2: string;
  Rang3: string;
  Rang4: string;
  Rang5: string;
  Rang6: string;
}
export const rangs: Ranges = { Rang1, Rang2, Rang3, Rang4, Rang5, Rang6 };
