import React, { FunctionComponent } from 'react';

type Props = {
  state: string;
  setState: Function;
};

const Radio: FunctionComponent<Props> = ({ state, setState, children }) => {
  return (
    <div
      className={`${
        state === children?.toString().toLowerCase()
          ? 'bg-yellow text-black'
          : ''
      } py-2 px-3 rounded-xl cursor-pointer text-sm font-bold`}
      onClick={event => setState(event.currentTarget.innerText.toLowerCase())}
    >
      {children}
    </div>
  );
};

export default Radio;
