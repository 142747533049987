import { FunctionComponent } from 'react';
import VotePic from '../../assets/vote.png'

const Intro: FunctionComponent = () => {
  return (
    <div className="w-full relative sm:mb-24">
      <div className="flex flex-col justify-center sm:gap-6 text-center sm:text-left relative z-50">
        <div>
          <h1 className="text-4xl sm:text-6xl xl:text-7xl font-medium text-title">
            Exon Vote
          </h1>
        </div>
        <p className="text-description mb-5 sm:mb-0">
          Be your own governance
        </p>
      </div>
      <div style={{top: '-50%', right: window.innerWidth < 1200 ? '-800px' : '-40%'}} className="absolute pointer-events-none -z-10 hidden sm:block">
        <img
          src={VotePic}
          className=" w-6/12 select-none pointer-events-none"
          alt="partner Icon"
        />
      </div>
    </div>
  );
};

export default Intro;
