import Filter from 'components/Filter'
import useWalletSelector from 'hooks/useWalletSelector'
import {FunctionComponent, useState} from 'react'
import { LaunchpoolInfo } from 'store/reducers/launchpool/types'
import PoolItem from './components/PoolItem'
import {ReactComponent as PoolLogo} from './components/PoolItem/assets/poolLogo.svg'

type Props = {
    pools: LaunchpoolInfo[];
}

const Pool: FunctionComponent<Props> = ({pools}) => {

    const [textFilter, setTextFilter] = useState('')
    const { stakedOnly } = useWalletSelector( state => state.launchpoolReducer )
    let poolArray = pools
        .filter(el => stakedOnly ? el.userInfo.stakedValue : true )

    return(
        <div className='mt-16 mb-20'>
            <div className="relative flex flex-col items-center w-full mb-10 lg:flex-row  lg:justify-between">
                <div className='flex flex-col items-center w-full mb-6 sm:flex-row lg:mb-0'>
                    <h1 className='mb-4 text-4xl font-medium text-lightGrey sm:mb-0 sm:ml-7'>Featured pools</h1>
                    <a
                        href={'https://forms.gle/Bt7nwjikyc6aEWcw7'}
                        target={'_blank'}
                        className="w-full ml-0 sm:w-min sm:ml-4">
                        <button
                            className='bg-yellow w-full sm:w-min text-black py-3.5 whitespace-nowrap px-9 text-xl leading-4 font-normal rounded-lg text-mainBlack'>
                                Create pool
                        </button>
                    </a>
                </div>
                <Filter textFilter={textFilter} setTextFilter={setTextFilter} type='launchpool' lpools />
            </div>
            <div className="flex justify-between grid lg:grid-cols-2 mmx:grid-cols-3 grid-cols-1 gap-16">
                {poolArray.map((el: any) => <PoolItem {...el} />)}
            </div>
        </div>
    )
}

export default Pool;
