import { coinIcons } from 'components/CoinIcons';
import Modal from 'components/Modal';
import React from 'react';
import { useDispatch } from 'react-redux';
import useWalletSelector from 'hooks/useWalletSelector';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';
import { addLiquidityActionCreator } from 'store/reducers/liquidityAdd/action-creator';
import ButtonProcess from 'components/Button/ButtonProcess';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import logoUndefined from '../../../../../../../../../../components/CoinIcons/assets/logoundefined.png'
import { formatNumber } from 'utils/formatNumber';

const ConfirmLiquidity = () => {
  const { liquidityState } = useWalletSelector(state => state.liquidityReducer);
  const { firstTokenAmount, secondTokenAmount } = useWalletSelector(
    state => state.addLiquidityReducer,
  );

  const { firstToken, secondToken, notification } = useWalletSelector(
    state => state.applicationReducer,
  );
  const dispatch = useDispatch();
  const handlCloseModal = () => {
    dispatch(LiquidityActionCreator.setLiquidityState('initial'));
  };

  const handlConfirmSupply = () => {
    dispatch(LiquidityActionCreator.setLiquidityState('steps'));
    dispatch(ApplicationAcionCreator.setTransactionStatus(true))
    if (firstToken.icon === 'trx' || secondToken.icon === 'trx') {
      dispatch(addLiquidityActionCreator.addTrxLiquidity());
    } else {
      dispatch(addLiquidityActionCreator.addLiquidity());
    }
  };

  return (
    <>
      {liquidityState === 'addLiquidityConfirm' && (
        <Modal closeModal={handlCloseModal}>
          <div className="text-center text-title text-2xl sm:text-4xl">
            Pool
          </div>
          <div className="mt-12">
            {/* from */}
            <div className="flex flex-col">
              <div className="flex items-center gap-2 justify-center">
                <div>
                  <img
                    src={coinIcons[firstToken.icon || ''] || firstToken.icon || logoUndefined}
                    alt="icon"
                    className="w-8 h-8"
                  />
                </div>
                <div className="text-lightYellow text-3xl">
                  {formatNumber(firstTokenAmount)}
                </div>
                <div
                  className="text-description flex font-light text-2xl relative"
                  style={{ minWidth: '3rem' }}
                >
                  {firstToken.name}
                </div>  
              </div>
              <div className="flex justify-center text-title my-2">+</div>
              <div className="flex items-center gap-2 justify-center">
                <div>
                    <img
                      src={coinIcons[secondToken.icon || ''] || secondToken.icon || logoUndefined}
                      alt="icon"
                      className="w-8 h-8"
                    />
                  </div>
                  <div className="text-lightYellow text-3xl">
                    {formatNumber(secondTokenAmount)}
                  </div>
                  <div
                    className="text-description font-light text-2xl relative "
                    style={{ minWidth: '3rem' }}
                  >
                    {secondToken.name}
                  </div>
              </div>
              
            </div>
            {/* text */}
            <div className="flex flex-col gap-6 sm:gap-2 mt-8">
              <div className="flex flex-col sm:flex-row justify-around">
                <div className="text-title text-base sm:text-xl">Price:</div>
                <div className="text-title text-base sm:text-xl">
                  <div>
                    1 {firstToken.name} ={' '}
                    {(secondTokenAmount / firstTokenAmount).toFixed(6)}{' '}
                    {secondToken.name}
                  </div>
                  <div>
                    1 {secondToken.name} ={' '}
                    {(firstTokenAmount / secondTokenAmount).toFixed(6)}{' '}
                    {firstToken.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-6 mt-4 text-center">
            <ButtonProcess
              name="Confirm supply"
              onClick={handlConfirmSupply}
              classname="w-full inline-flex justify-center 
                        rounded-md border border-transparent shadow-sm px-6 py-2 
                        bg-yellow text-base font-medium text-mainBlack
                        hover:bg-black-700 focus:outline-none focus:ring-2 
                        focus:ring-offset-2 
                        focus:ring-red-500 
                        sm:ml-3 sm:w-auto sm:text-sm"
            />
            {/* <button
              type="button"
              className="w-full inline-flex justify-center 
                        rounded-md border border-transparent shadow-sm px-6 py-2 
                        bg-yellow text-base font-medium text-mainBlack
                        hover:bg-black-700 focus:outline-none focus:ring-2 
                        focus:ring-offset-2 
                        focus:ring-red-500 
                        sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handlConfirmSupply}
              disabled={notification.isShown}
              style={notification.isShown ? { opacity: '0.5' } : {}}
            >
              Confirm supply
            </button> */}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ConfirmLiquidity;
