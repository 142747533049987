import React, { FunctionComponent } from 'react';
import Modal from 'components/Modal';
import { coinIcons } from 'components/CoinIcons';
import useWalletSelector from 'hooks/useWalletSelector';
import { useDispatch } from 'react-redux';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';
//import { removeLiquidityActionCreator } from 'store/reducers/liquidityRemove/action-creator';

const ReceiveLiquidityConfirm: FunctionComponent = () => {
  const { liquidityState } = useWalletSelector(state => state.liquidityReducer);
  const { poolInfo } = useWalletSelector(state => state.removeLiquidityReducer);
  const dispatch = useDispatch();
  const handlCloseModal = () => {
    dispatch(LiquidityActionCreator.setLiquidityState('initial'));
  };
  const handlConfirmSupply = () => {
    dispatch(
      LiquidityActionCreator.setLiquidityState('removeLiquidityConfirm'),
    );
  };
  return (
    <>
      {liquidityState === 'receiveConfirm' && (
        <Modal closeModal={handlCloseModal}>
          <div className="flex flex-col items-center">
            <div className="flex w-full justify-start sm:justify-center">
              <h2 className="text-title text-xl">You will receive</h2>
            </div>
            <div className="text-2xl sm:text-3xl text-lightYellow mt-7">
              {poolInfo.liquidity}
            </div>
            <div className="text-description">
              {poolInfo.firstDepositTokenInfo.symbol}/
              {poolInfo.secondDepositTokenInfo.symbol} pool tokens
            </div>
            <div className="text-description mt-7">
              Output is estimated. If the price changes by more than 0.5% your
              transaction will revert. Please make sure you have enough energy
              and bandwith, or the transaction may fail.
            </div>
            <div className="flex w-full flex-col sm:flex-row gap-2 sm:gap-0 justify-between mt-7">
              <div className="text-title text-xl">
                {poolInfo.firstDepositTokenInfo.symbol} deposited
              </div>
              <div className="flex items-center text-2xl">
                <img
                  src={coinIcons[poolInfo.firstDepositTokenInfo.icon!]}
                  alt="Coin one"
                  className="w-8 h-8 z-10 mr-2"
                />
                {poolInfo.firstDepositTokenAmount}
              </div>
            </div>
            <div className="flex w-full flex-col sm:flex-row gap-2 sm:gap-0 justify-between mt-7">
              <div className="text-title text-xl">
                {poolInfo.secondDepositTokenInfo.symbol} deposited
              </div>
              <div className="flex items-center text-2xl">
                <img
                  src={coinIcons[poolInfo.secondDepositTokenInfo.icon!]}
                  alt="Coin one"
                  className="w-8 h-8 z-10 mr-2"
                />
                {poolInfo.secondDepositTokenAmount}
              </div>
            </div>
            {/* <div className="flex w-full flex-col sm:flex-row gap-2 sm:gap-0 justify-between mt-7">
              <div className="text-xl text-title">Rates</div>
              <div className="text-xl">
                <div>1 USDT = 100500 EXON</div>
                <div>1 EXON = 100500 USDT</div>
              </div>
            </div> */}
          </div>
          <div className="flex w-full flex-col sm:flex-row gap-2 sm:gap-0 justify-between mt-7">
            <div className="text-xl text-title">Share of pool</div>
            <div className="text-xl">{'<0.01%'}</div>
          </div>
          <div className="mt-4 text-center">
            <button
              type="button"
              onClick={handlConfirmSupply}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-2 bg-yellow text-base font-medium text-mainBlack hover:bg-black-700 focus:outline-none focus:ring-2 ocus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-xl"
            >
              Confirm supply
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReceiveLiquidityConfirm;
