import initTronlink from './initTronlink';

const initContractTronlink = async (address: string, dispatch: any) => {
  const { tronWeb } = await initTronlink(dispatch);
  try {
    const contract = await tronWeb.contract().at(address);
    return contract;
  } catch (error) {
    console.log(error, address)
    // alert('You are not logged in!');
    return {
      contract: null,
    };
  }
};
export default initContractTronlink;
