import React from 'react';
import { Switch, Route } from 'react-router';
import { privateRoutes, publicRoutes } from 'routers';

export default function AppRouter() {
  const wallet = true;
  return wallet ? (
    <Switch>
      {privateRoutes.map(route => (
        <Route
          path={route.path}
          exact={route.exact}
          component={route.component}
          key={route.path}
        />
      ))}
      {publicRoutes.map(route => (
        <Route
          path={route.path}
          exact={route.exact}
          component={route.component}
          key={route.path}
        />
      ))}
    </Switch>
  ) : (
    <Switch>
      {publicRoutes.map(route => (
        <Route
          path={route.path}
          exact={route.exact}
          component={route.component}
          key={route.path}
        />
      ))}
    </Switch>
  );
}
