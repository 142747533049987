import Layout from "components/Layout"
import useWalletSelector from "hooks/useWalletSelector"
import SwapSteps from "pages/Swap/components/SwapContainer/components/SwapProcess/components/SwapSteps"
import {useEffect} from "react"
import {useDispatch} from "react-redux"
import {multisendActionCreator} from "store/reducers/multisend/action-creator"
import MultisendPic from './assets/multisend.png'
import FeeCalculator from "./components/FeeCalculator"
import Guide from "./components/Guide"
import MultisendWindow from "./components/MultisendWindow"
import Subscription from "./components/Subscription"
export const Multisend = () => {
	
	const {multisendState, choosenSubscription, subscribePlans, 
				 subscribePeriod, sendAddresses, amountPerAddress, 
				 tokenAddress, oneAddressPrice, feeTypes} = useWalletSelector( state => state.multisendReducer )
	const { currentAccount } = useWalletSelector( state => state.AccountReducer )
	const { isWallet } = useWalletSelector( state => state.WalletReducer )
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(multisendActionCreator.getSubscribePeriod())
		console.log('MULTISEND STATE: ', multisendState);
	}, [currentAccount, multisendState])

	useEffect(() => {
		dispatch(multisendActionCreator.getSubscriptionPlans())
        dispatch(multisendActionCreator.getFeeTypes())
	}, [])

	return (
		<>
			<Layout>
				<div className='container'>
					<div className="relative flex justify-end text-center sm:text-right gap-3 sm:gap-6">
						<div className="absolute top-0 left-0 pointer-events-none brightness-100 sm: brightness-50 sm:-top-3/4 -z-10 sm:block">
							<img
								src={MultisendPic}
								className="w-8/12 pointer-events-none select-none "
								alt="partner Icon"
							/>
						</div>
						<div className='z-10 w-full sm:w-1/2'>
							<div>
									<h1 className="text-4xl font-medium sm:text-6xl xl:text-7xl text-title">
										Exon Multisend
									</h1>
								</div>
								<p className="text-description">
									Multisend is a tool for distributing tokens and Tron to multiple addresses from the manual input or CSV file
								</p>
								</div>
						</div>
						
					<div className='relative flex flex-col w-full mx-auto mt-20 gap-y-14'>
						<div className='flex flex-col xl:flex-row gap-y-14 gap-x-8'>
							<Guide />
							<FeeCalculator feeTypes={feeTypes} oneAddressPrice={oneAddressPrice} />
						</div>
						<div className='flex flex-col xl:flex-row gap-y-14 gap-x-8'>
							<Subscription feeTypes={feeTypes} subscribePeriod={subscribePeriod} choosenSubscription={choosenSubscription} subscribePlans={subscribePlans} 
														isWallet={isWallet} dispatch={dispatch} />
							<MultisendWindow tokenAddress={tokenAddress} sendAddresses={sendAddresses} amountPerAddress={amountPerAddress} 
														isWallet={isWallet} dispatch={dispatch} />
						</div>
					</div>

				</div>
			</Layout>
		</>
	)

}
