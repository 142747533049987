import React from 'react';
import Modal from 'components/Modal';
import { coinIcons } from 'components/CoinIcons';
import Button from 'components/Button';

const FarmCardModal = () => {
  const isModal = false;
  return (
    <>
      {isModal && (
        <Modal>
          <div className="flex items-start">
            <div className="flex flex-col sm:flex-row">
              <div className="flex mr-5">
                <img
                  src={coinIcons['usdt']}
                  alt="Coin one"
                  className="w-12 z-10"
                />
                <img
                  src={coinIcons['exon']}
                  alt="Coin two"
                  className="w-12 -ml-2"
                />
              </div>
              <div className="sm:mr-24 mt-2 sm:mt-0">
                <div className="text-xl sm:text-2xl text-title">
                  USDT {'->'} EXON
                </div>
                <div className="flex items-center">
                  Earn:
                  <img
                    src={coinIcons['exon']}
                    alt="Exon"
                    className="w-5 ml-2"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center bg-yellow bg-opacity-15 rounded-lg text-yellow px-4 py-2">
              8.39%
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 sm:items-center justify-between mt-10">
            <div className="text-xl text-title">Total staked</div>
            <div className="text-2xl text-title font-semibold">
              $ 231 678 321
            </div>
          </div>
          <div className="mt-10">
            <div className="text-xl text-title">Rewards</div>
            <div className="text-xl text-description mt-2">
              Stake USDT-TRX LP token to earn
            </div>
            <div className="flex justify-between items-center mt-5">
              <div className="flex items-center gap-5">
                <img src={coinIcons['exon']} alt="Exon" className="w-8" />
                <div className="text-xl text-title">USDT {'->'} EXON</div>
              </div>
              <div className="text-xl text-lightYellow">8.39 %</div>
            </div>
          </div>
          <div
            className="px-5 py-7 sm:rounded-2xl mt-8 -mx-6 sm:mx-0"
            style={{ backgroundColor: '#4F4F4F' }}
          >
            <div className="text-xl text-title">My staking</div>
            <div className="text-3xl text-lightYellow mt-5">
              129.423 USDT_EXON LP
            </div>
            <div className="text-xl text-title mt-7">Mining rewards</div>
            <div className="flex justify-between items-center mt-5">
              <div className="text-3xl text-lightYellow">$ 0</div>
              <div className="text-xl text-yellow uppercase">details</div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-7 mt-10">
            <Button disabled>Get USDT-EXON LP</Button>
            <Button className="mt">Manage</Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default FarmCardModal;
