import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import FarmCard from './components/FarmCard';
import Calculator from './components/Calculator';
import StakeAndClaim from './components/StakeAndClaim';
import WaitingConfirmation from './components/WaitingConfirmation';
import Success from './components/Success';
import FarmCardModal from './components/FarmCardModal';
import FarmingPic from './assets/farming.png'
import { Redirect } from 'react-router';
import useWalletSelector from 'hooks/useWalletSelector';
import { useDispatch } from 'react-redux';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';
import Filter from 'components/Filter';

export function Farming() {
  const { farmingList, stakedOnly, option } = useWalletSelector( state => state.farmingReducer )
  const { transactionSteps } = useWalletSelector(state => state.applicationReducer)
  const [textFilter, setTextFilter] = useState()
  const dispatch = useDispatch()
  const textFilterRegExp = new RegExp(`${textFilter}`, 'g')
  let transactionDone = transactionSteps && transactionSteps[transactionSteps.length-1].done
  let farmList = farmingList
    .filter(el => stakedOnly ? el.lpStaked : true )
    .filter(el => option === 'ALL' ? true : el.poolType === option )
    .filter(el => textFilter ? el.pairTokens.match(textFilterRegExp) : true)
    useEffect(() => {
     dispatch(farmingActionCreator.getFarmingPairs())
    }, [transactionDone])

  return (
    <>
      <Layout>
			{/*<Redirect to='/exonnft' />*/}
        <div className="container">
          <div className="relative w-full sm:mb-24">
            <div className="relative z-50 flex flex-col justify-center text-center gap-3 sm:gap-6 sm:text-left">
              <div>
                <h1 className="text-4xl font-medium sm:text-6xl xl:text-7xl text-title">
                  Exon Farming
                </h1>
              </div>
              <p className="text-description">
                Gain more profit from your liquidity
              </p>
            </div>
            <div style={{top: '-20%', right: window.innerWidth < 1200 ? '-800px' : '-50%'}} className="absolute hidden pointer-events-none -z-10 sm:block">
              <img
                src={FarmingPic}
                className="w-6/12 pointer-events-none select-none "
                alt="partner Icon"
              />
          </div>
        </div>
          <Filter textFilter={textFilter || ''} setTextFilter={setTextFilter} type='farming' />

          <div className="relative pb-5 mt-5 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-6">
            {farmList.map(el => <FarmCard regExp={textFilterRegExp} textFilter={textFilter} {...el} />)}
          </div>
        </div>
        <Calculator />
        <StakeAndClaim />
        <WaitingConfirmation />
        <Success />
      </Layout>
      <FarmCardModal />
    </>
  );
}
