import {ReactComponent as ArrowUp} from '../../../../assets/arrowUp.svg'
import { Dispatch, FunctionComponent, useContext, useEffect, useState } from "react"
import { PaginationEl } from '../Pagination'
import { TransactionElement } from 'store/reducers/graphic/types'
import s from '../../Analytics.module.css'
import { graphicActionCreator } from 'store/reducers/graphic/action-creator'
import Config from 'config'
import { SecondsContext } from 'App'

type Props = {
    transactions: {
        transactions: TransactionElement[]
        pageCount: number,
        currentPage: number
    },
    dispatch: Dispatch<any>,
    firstTokenAddress: string,
    secondTokenAddress: string,
}

export const Transactions: FunctionComponent<Props> = ({ transactions, dispatch, firstTokenAddress, secondTokenAddress }) => {
    const [currentAction, setCurrentAction] = useState<string>('All')
    const { seconds } = useContext(SecondsContext)
    useEffect(() => {
        dispatch(graphicActionCreator.getTransactionsData(transactions.currentPage+1, currentAction, firstTokenAddress, secondTokenAddress ))
    }, [currentAction])

    const currAction = (action: string, firstToken: any, secondToken: any ) => {
        switch (action) {
            case 'Swap':
                return `Swap ${firstToken.name} for ${secondToken.name}`
            case 'Mint':
                return `Added ${firstToken.name} and ${secondToken.name}`
            case 'Burn':
                return `Removed ${firstToken.name} and ${secondToken.name}`
            default:
                return 'Ну ничего страшного'
        }
    }

    useEffect(() => {
        if(seconds === 0 && transactions.currentPage === 0){
            dispatch(graphicActionCreator.getTransactionsData(transactions.currentPage, currentAction, firstTokenAddress, secondTokenAddress ))
        }
    }, [seconds])
    
    return(
        <div className="my-12">
            <div className=" mb-7">
                <h1 className='text-4xl text-title font-medium'>Transactions</h1>
            </div>
            <div className=" bg-cardBg rounded-4xl pb-5 lg:pb-10">
                <div className="flex lg:block">
                    <div className="lg:hidden flex flex-col pl-5 pr-2">
                        <div className={`${s.transaction} relative py-3 `}>
                            <select onClick={e => setCurrentAction(e.currentTarget.value)} className='outline-none bg-black text-base text-description rounded-lg py-2 px-3 w-3/4' name="transactionType" id="">
                                <option value="All">All</option>
                                <option value="Swap">Swaps</option>
                                <option value="Mint">Adds</option>
                                <option value="Burn">Removes</option>
                            </select>
                            <ArrowUp style={{width: '17px', height: '10px', right: '30%', transform: 'translate(0, 150%) rotate(-180deg)' }} className='absolute lg:top-0 top-5' />
                        </div>
                        {transactions.transactions.map(el => <a className={`${s.table__content} font-normal cursor-pointer transition-all text-yellow outline-none whitespace-nowrap `} target='_blank' href={`${Config().TRONSCAN}/#/transaction/${el.trxId}`}>{currAction(el.action, el.firstToken, el.secondToken)}</a>)}
                    </div>
                    <div className="overflow-x-auto lg:overflow-hidden">
                        <table style={{ borderCollapse: 'collapse'}} className='w-full gap-8'>
                            <tr className={`text-base mxx:text-xl text-title font-medium`}>
                                <td className='pl-7 pt-7 pb-3 hidden lg:block'>
                                    <div className={`${s.transaction} relative`}>
                                        <select onClick={e => setCurrentAction(e.currentTarget.value)} className='outline-none bg-black text-base text-description rounded-lg py-2 px-3 w-3/4' name="transactionType" id="">
                                            <option value="All">All</option>
                                            <option value="Swap">Swaps</option>
                                            <option value="Mint">Adds</option>
                                            <option value="Burn">Removes</option>
                                        </select>
                                        <ArrowUp style={{width: '17px', height: '10px', right: '30%', transform: 'translate(0, 150%) rotate(-180deg)' }} className='absolute top-0' />
                                    </div>
                                    
                                </td>
                                <td className='whitespace-nowrap lg:pl-0 px-7'>Total value</td>
                                <td className='whitespace-nowrap lg:pl-0 px-7'>Token amount</td>
                                <td className='whitespace-nowrap lg:pl-0 px-7'>Token amount</td>
                                <td className='lg:pl-0 px-7'>Account</td>
                                <td className='py-5 lg:py-8  px-7 lg:pr-7'>Date</td>    
                            </tr>
                            {transactions.transactions.map(el => <TransactionEl 
                                                        currAction={currAction}
                                                        firstToken={el.firstToken} 
                                                        secondToken={el.secondToken} 
                                                        action={el.action} 
                                                        totalValue={el.totalValue} 
                                                        account={el.account} 
                                                        time={el.time}
                                                        trxId={el.trxId} />)}
                        </table>
                    </div>
                </div>
                
                
                <PaginationEl 
                    elAmount={transactions.pageCount || 1} 
                    currentPage={transactions.currentPage+1} 
                    dispatch={dispatch} 
                    type='transactions' 
                    currentAction={currentAction} 
                    firstTokenAddress={firstTokenAddress}
                    secondTokenAddress={secondTokenAddress} />
            </div>
        </div>
    )
} 

type ElProps = {
    firstToken: {
        amount: number,
        name: string
    },
    secondToken: {
        amount: number,
        name: string
    },
    action: string
    totalValue: number
    account: string
    time: number
    trxId: string
    currAction: Function
}

export const TransactionEl: FunctionComponent<ElProps> = ({ firstToken, secondToken, action, totalValue, account, time, trxId, currAction}) => {
    const date = new Date(time).toISOString()
    return(
        <tr className={`${s.table__data} whitespace-nowrap text-xl text-description  transition-all hover:bg-darkGray text-center lg:text-left`}>
            <td className='py-3 pl-7 hidden lg:block font-normal'><a className={`${s.table__links} cursor-pointer transition-all text-yellow outline-none whitespace-nowrap`} target='_blank' href={`${Config().TRONSCAN}/#/transaction/${trxId}`}>{currAction(action, firstToken, secondToken)}</a></td>
            <td className='py-3'>${totalValue}</td>
            <td className='py-3'>{firstToken.amount} {firstToken.name}</td>
            <td className='py-3'>{secondToken.amount} {secondToken.name}</td>
            <td className='py-3'>
                <a className={`${s.table__links} font-normal cursor-pointer transition-all text-yellow outline-none`} target='_blank' href={`${Config().TRONSCAN}/#/address/${account}`}>{account.substring(0, 4) + '...' + account.substring(30, 34)}</a>
            </td>
            <td className={`${s.table__links} px-7 whitespace-nowrap`}>{date.replace('T',' ').split('.')[0]}</td>
        </tr>
    )
}
