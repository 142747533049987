import Info from './components/Info';
import Pool from './components/Pool';

import FeaturedPools from './components/FeaturedPools';
import Layout from 'components/Layout';
import {Redirect} from 'react-router';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {launchpoolActionCreator} from 'store/reducers/launchpool/action-creator';
import useWalletSelector from 'hooks/useWalletSelector';

export function Launchpool() {

  const dispatch = useDispatch()
  const { pools } = useWalletSelector(state => state.launchpoolReducer)

  const defaultPools = pools.filter((el, index) => index < 4)
  const featuredPools = pools.filter((el, index) => index > 3)

  useEffect(() => {
    dispatch(launchpoolActionCreator.getLaunchpools())
    console.log('trigger');
  }, [])

  return (
    <Layout>
      <div className="container flex flex-col lg:mt-6">
        <Info />
        <Pool pools={defaultPools} />
      </div>
      <FeaturedPools pools={featuredPools} />
    </Layout>
  );
}
