import React, { FunctionComponent, useEffect } from 'react';
import AddLiquidity from './components/AddLiquidity';
import PoolInitial from './components/PoolInitial';
import { useDispatch } from 'react-redux';
import useWalletSelector from 'hooks/useWalletSelector';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';

const PoolProcess: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { isFetching } = useWalletSelector( state => state.liquidityReducer )
  const { liquidityState, liquidityMainState } = useWalletSelector(
    state => state.liquidityReducer,
  );
  return (
    <div>
      {liquidityMainState === 'initial' && <PoolInitial />}
      {liquidityMainState === 'createPool' || liquidityMainState === 'add' ? (
        <AddLiquidity />
      ) : (
        ''
      )}
    </div>
  );
};

export default PoolProcess;
