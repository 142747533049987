import { FunctionComponent, useState } from "react";
import {TokenInfo} from "store/reducers/types";
import {formatNumber} from "utils/formatNumber";
import {getTokenInfoByAddress} from "utils/getTokenInfo";
import Tooltip from "components/Tooltip"

interface IPoolAmounts  {
    totalIDOAmount: number;
    total: number;
    idoToken: string;
    buyTokens: string[];
    tokenPrices: number[];
		saleTokenInfo: TokenInfo;
    poolId: number;
  }

const PoolAmount: FunctionComponent<IPoolAmounts> = ({totalIDOAmount, 
                                                      total, 
                                                      idoToken, 
                                                      buyTokens, 
                                                      tokenPrices, 
                                                      saleTokenInfo,
                                                      poolId}) => {


			const getPercent = () => {
					let percent
					percent = totalIDOAmount/ total * 100
					percent = percent >= 100 ? 100 : percent
					return percent.toFixed(2)
			}

    return(
        <div className="my-4 p-7">
            <div className="">
                <div className='flex flex-col justify-between items-left sm:items-center gap-4 sm:gap-0 sm:flex-row'>
                    <span className='text-4xl'>IDO progress</span>
                    <span className='text-lg font-semibold '>
										<div	
											className='relative font-semibold text-description'>
											Token prices
            <Tooltip id={`launchpad-item-price-${poolId}`} place="top" yellow={true}>
												{buyTokens
													.map((el: string, index: number) => {
														return <p className="whitespace-nowrap">
																			1 {saleTokenInfo.symbol} = {1 / tokenPrices[index]} {getTokenInfoByAddress(el).name}
																		</p> })
													}
                          </Tooltip>
											</div>
                      </span>
                </div>
                </div>
                <div className="flex block mt-5 justify-left sm:hidden">
									<span className=''>{getPercent()}%</span>
								</div>
                <div className="relative mt-3">
                    <div style={{boxShadow: '0 0 1px 2px #BBBBBB'}} className="h-2 border rounded-xl bg-darkGray border-lightBlack"></div>
                    <div style={{width: getPercent()+'%'}} className="absolute top-0 h-2 bg-yellow rounded-xl"></div>
                </div>
                <div className="flex justify-between mt-3 text-sm sm:text-xl font-semibold">
                    <span className='hidden sm:block'>{getPercent()}%</span>
                    <div className="flex justify-around ">
                        <span>{formatNumber(totalIDOAmount)} {idoToken}</span>
                        <span className='mx-2'>/</span>
                        <span>{formatNumber(total)} {idoToken}</span>
                    </div>
                </div>
            </div>
    )
}

export default PoolAmount
