import React, { FunctionComponent } from 'react';

type Props = {
  light?: boolean;
  small?: boolean;
  className?: string;
  onClick?: any;
};

const Block: FunctionComponent<Props> = ({
  light,
  small,
  className,
  children,
  onClick
}) => {
  return (
    <div 
      onClick={onClick}
      className={`${light ? 'bg-lightBlackO' : 'bg-cardBg'} ${className} ${
        small ? 'p-4' : 'p-4 sm:p-5 xl:p-8'
      } rounded-3xl`}
    >
      {children}
    </div>
  );
};

export default Block;
