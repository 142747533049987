import { coinIcons } from 'components/CoinIcons';
import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';
import { addLiquidityActionCreator } from 'store/reducers/liquidityAdd/action-creator';
import { removeLiquidityActionCreator } from 'store/reducers/liquidityRemove/action-creator';
import { formatNumber } from 'utils/formatNumber';
import logoUndefined from '../../../../../../../../components/CoinIcons/assets/logoundefined.png'

type Props = {
  pool?: boolean;
  liquidity: number;
  token0Amount: number;
  token1Amount: number;
  token0: string;
  token1: string;
  lpAddress: string;
  totalSupply: number;
  token0Icon: string;
  token1Icon: string;
  token0Symbol: string;
  token1Symbol: string;
  personalLiquidityToken0?: number;
  personalLiquidityToken1?: number;
  firstTokenBalance: number;
  secondTokenBalance: number;
  lpPriceInUsd: number;
};

const LiquidityCard: FunctionComponent<Props> = ({
  pool,
  liquidity,
  token0Amount,
  token1Amount,
  lpAddress,
  token0,
  token1,
  totalSupply,
  token0Icon,
  token1Icon,
  token0Symbol,
  token1Symbol,
  personalLiquidityToken0,
  personalLiquidityToken1,
  firstTokenBalance,
  secondTokenBalance,
  lpPriceInUsd
}) => {
  const dispatch = useDispatch();

  const handlRemoveAddLiquidity = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (e.currentTarget.id === 'removeLiquidityButton') {
      dispatch(
        removeLiquidityActionCreator.setPoolInfo({
          poolAddress: lpAddress,
          firstDepositTokenInfo: {
            address: token0,
            icon: token0Icon,
            symbol: token0Symbol,
            name: token0Symbol,
          },
          secondDepositTokenInfo: {
            address: token1,
            icon: token1Icon,
            symbol: token1Symbol,
            name: token1Symbol,
          },
          firstDepositTokenAmount: token0Amount,
          secondDepositTokenAmount: token1Amount,
          lpPriceInUsd: lpPriceInUsd,
          totalSupply,
          liquidity,
        }),
      );
      dispatch(LiquidityActionCreator.setLiquidityState('remove'));
    } else {
      dispatch(
        addLiquidityActionCreator.setPoolInfo({
          poolAddress: lpAddress,
          totalSupply,
          liquidity,
          firstDepositTokenAmount: token0Amount,
          secondDepositTokenAmount: token1Amount,
          lpPriceInUsd: lpPriceInUsd,
          firstDepositTokenInfo: {
            address: token0,
            icon: token0Icon,
            symbol: token0Symbol,
            name: token0Symbol,
          },
          secondDepositTokenInfo: {
            address: token1,
            icon: token1Icon,
            symbol: token1Symbol,
            name: token1Symbol,
          },
        }),
      );
      dispatch(LiquidityActionCreator.setLiquidityState('add'));
      dispatch(LiquidityActionCreator.setLiquidityMainState('add'));
      dispatch(addLiquidityActionCreator.getTokensReserves());
      dispatch(
        ApplicationAcionCreator.setFirstToken({
          address: token0,
          icon: token0Icon,
          symbol: token0Symbol,
          name: token0Symbol,
        }),
      );
      dispatch(
        ApplicationAcionCreator.setSecondToken({
          address: token1,
          icon: token1Icon,
          symbol: token1Symbol,
          name: token1Symbol,
        }),
      );
    }
  };

  const history = useHistory()

  const getPartInPercent = () => {
    return ((liquidity / totalSupply) * 100).toFixed(2);
  };

  const getFirstTokenAmount = () => {
    return ((liquidity / totalSupply) * token0Amount).toFixed(4);
  };

  const getSecondTokenAmount = () => {
    return ((liquidity / totalSupply) * token1Amount).toFixed(4);
  };

  const getFirstTokenFee = () => {
    return ( (firstTokenBalance - personalLiquidityToken0! - token0Amount) * ( liquidity / totalSupply ) )
  }

  const getSecondTokenFee = () => {
    return ( (secondTokenBalance - personalLiquidityToken1! - token1Amount) * ( liquidity / totalSupply ) )
  }

  return (
    <div>
      <div
        className="flex items-center flex-col  sm:flex-row justify-between py-1 sm:py-3 sm:px-5 px-2 rounded-2xl bg-input"
      >
        <div className="flex items-center">
          <div className="flex mr-3">
            <img
              src={coinIcons[token0Icon] || token0Icon || logoUndefined}
              alt="Coin one"
              className="w-8 h-8 z-0"
            />
            <img
              src={coinIcons[token1Icon] || token1Icon || logoUndefined}
              alt="Coin two"
              className="w-8 h-8 -ml-2"
            />
          </div>
          <div>
            <div className="text-sm sm:text-xl text-description">
              {token0Symbol}/{token1Symbol}
            </div>
            <span className="text-description text-xs sm:text-base ">View pool info</span>
          </div>
        </div>
        <div className="flex items-center text-xs sm:text-base sm:mt-0 mt-4">
          <div
            id="removeLiquidityButton"
            className="uppercase text-description cursor-pointer"
            onClick={e => handlRemoveAddLiquidity(e)}
          >
            Remove
          </div>
          <hr
            className="h-6 border-l-thin mx-2"
            style={{ borderColor: '#7A7A7A' }}
          />
          <div
            id="addLiquidityButton"
            className="uppercase text-yellow cursor-pointer"
            onClick={e => {
              handlRemoveAddLiquidity(e)
              history.push('/swap/liquidity')
            }}
          >
            Add
          </div>
        </div>
      </div>
      <div className={`flex ${pool && 'flex-col'} justify-between text-description mt-6`}>
        <div className="text-xs sm:text-xl">
          Total liquidity amount {token0Symbol}/{token1Symbol}
        </div>
        <div className="text-xs sm:text-xl">
          {formatNumber(token0Amount)}/{formatNumber(token1Amount)}
        </div>
      </div>
      <div className={`flex ${pool && 'flex-col'} justify-between text-description mt-6`}>
        <div className="text-xs sm:text-xl">
          Personal liquidity amount {token0Symbol}/{token1Symbol}
        </div>
        <div className="text-xs sm:text-xl">
          {formatNumber(getFirstTokenAmount())}/{formatNumber(getSecondTokenAmount())}
        </div>
      </div>
      <div className={`flex ${pool && 'flex-col'} justify-between text-description mt-5`}>
        <div className="text-xs sm:text-xl">Pool tokens/Pool share</div>
        <div className="text-xs sm:text-xl">
          {formatNumber(liquidity)}/{getPartInPercent()}
        </div>
      </div>
      
        <div className={`flex ${pool && 'flex-col'} justify-between text-description mt-5`}>
          <div className="text-xs sm:text-xl">
            Personal Liquidity fee {token0Symbol}/{token1Symbol}
          </div>
          <div className="text-xs sm:text-xl">
            {formatNumber(personalLiquidityToken0 || '')}/
            {formatNumber(personalLiquidityToken1 || '')}
          </div>
        </div>
      {/* <div className={`flex ${pool && 'flex-col'} justify-between text-description mt-5`}>
        <div className="text-xs sm:text-xl">Total Liquidity fee {token0Symbol}/{token1Symbol}</div>
        <div className="text-xs sm:text-xl">
          {getFirstTokenFee()}/{getSecondTokenFee()}
        </div>
      </div> */}
      <hr className="border border-darkGray mt-8" />
    </div>
  );
};

export default LiquidityCard;
