import React, { createContext, useEffect, useState } from 'react';
import initExonNode from 'utils/tronWeb/initExonNode';
import AppRouter from 'components/AppRouter';
import './index.css';
import '../node_modules/swiper/swiper-bundle.css';
import parse from 'url-parse';
import Config from 'config';
import { useDispatch } from 'react-redux';
import useWalletSelector from 'hooks/useWalletSelector';
import { accountActionCreator } from 'store/reducers/account/action-creators';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';
import { swapActionCreator } from 'store/reducers/swap/action-creators';
import { useHistory } from 'react-router';
import { graphicActionCreator } from 'store/reducers/graphic/action-creator';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';
import { multisendActionCreator } from 'store/reducers/multisend/action-creator';
import { launchpoolActionCreator } from 'store/reducers/launchpool/action-creator';
import { launchpadActionCreator } from 'store/reducers/launchpad/action-creator'
import { LevelsEnergyActionCreator } from 'store/reducers/levelsEnergy/action-creators';
interface ContextProps {
  seconds: number;
  setSeconds: any;
}

function noop() { }

export const SecondsContext = createContext<ContextProps>({
  seconds: 0,
  setSeconds: noop
});

function App() {
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(0);
  const { accounts, creating, choosenFarmingPoolId, teamFetching, currentAccount, farmingInfo } = useWalletSelector(
    state => state.AccountReducer,
  );
  const { farmingList } = useWalletSelector(state => state.farmingReducer)
  const { isWallet } = useWalletSelector(state => state.WalletReducer);
  const { transactionSteps } = useWalletSelector(state => state.applicationReducer)
  const history = useHistory()
  const parsed = parse(window.location.href, true);
  const { tronWeb } = useWalletSelector(state => state.WalletReducer)
  if (parsed !== undefined) {
    const refNumber: string = parsed.pathname?.split('/')[1]!;
    const regNum = '[0-9]+$';
    var regNumCheck = new RegExp(regNum);
    if (refNumber.match(regNumCheck)) {
      window.localStorage.setItem('ref_id', refNumber);
    }
  }
  useEffect(() => {
    if (isWallet) {
      dispatch(LiquidityActionCreator.setIsEmpty(false))
      setSeconds(0)
    }
  }, [isWallet])

  useEffect(() => {
    if (tronWeb?.event === false) {
      window.location.reload()
    }
  }, [tronWeb?.event])

  useEffect(() => {
    dispatch(accountActionCreator.getTotalTeamRevenue())
    dispatch(accountActionCreator.getWithdrawedRevenue())
    dispatch(LiquidityActionCreator.getCurrentLiquidity())
  }, [currentAccount, farmingList])



  useEffect(() => {
    if (transactionSteps && transactionSteps[transactionSteps.length - 1].done) {
      dispatch(LiquidityActionCreator.setIsEmpty(false))
      setSeconds(0)
    }
  }, [transactionSteps])

  useEffect(() => {
    dispatch(accountActionCreator.getTeamRevenue())
    dispatch(accountActionCreator.getUserLevelNoRevenue())
  }, [choosenFarmingPoolId, farmingInfo.currentAccountLevel])

  const [secondsEvent, setSecondsEvent] = useState(0)
  const [lastBlock, setLastBlock] = useState(0)
  const [lastIDOBlock, setLastIDOBlock] = useState(0)

  useEffect(() => {

    const getEvents = async () => {
      const tronweb = await (window as any).tronweb
      try {
        var events = await tronweb.getEventResult(Config().LAUNCHPOOL_FACTORY_CONTRACT, { size: 1 })
        if (lastBlock < events[0].block && lastBlock != 0) {
          dispatch(launchpoolActionCreator.getLaunchpools())
          console.log(events, lastBlock)
        }

      } catch (e) {
        console.log(e)
      }
      if (events) {
        if (events.length > 0 && events[0] !== undefined) {
          setLastBlock(events[0].block)
        }
      }
    }

    const getIDOEvents = async () => {
      const tronWeb = await initExonNode();
      try {
        var events = await tronWeb.getEventResult(Config().LAUNCHPAD_FACTORY, { size: 1 })
        if (lastIDOBlock < events[0].block && lastIDOBlock != 0) {
          dispatch(launchpadActionCreator.getActivePool(parseInt(events[0].result._poolId)))
          //dispatch(launchpadActionCreator.getBuyTokenBalances())
          console.log(events, lastBlock)
        }

      } catch (e) {
        console.log(e)
      }
      if (events) {
        if (events.length > 0 && events[0] !== undefined) {
          setLastIDOBlock(events[0].block)
        }
      }
    }



    let interval: any = null;
    interval = setInterval(() => {
      // console.log('SECONDS', seconds);
      if (!secondsEvent) {
        setSecondsEvent(1);
      } else if (seconds > 5) {
        getIDOEvents()
        setSecondsEvent(0)
      } else {
        setSecondsEvent(seconds => seconds + 1);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [secondsEvent]);

  useEffect(() => {
    let interval: any = null;
    interval = setInterval(() => {
      // console.log('SECONDS', seconds);
      localStorage.setItem('tokenList', JSON.stringify(JSON.parse(localStorage.getItem('tokenList') || '[]').filter((el: { name: string; }) => el.name !== 'EXON')))
      if (!seconds) {
        //dispatch(farmingActionCreator.getFarmingPairs())
        dispatch(launchpoolActionCreator.getLaunchpools())
        dispatch(LevelsEnergyActionCreator.getLevelsBuyPrice())
        dispatch(LevelsEnergyActionCreator.getLevelsBuyLimits())
        dispatch(LevelsEnergyActionCreator.getLevelsLimitCount())
        //dispatch(launchpadActionCreator.getLaunchpadPools())
        if (isWallet) {
          dispatch(accountActionCreator.canUserWithdrawLaunchpool())
          !teamFetching && dispatch(accountActionCreator.getUplinersLiquidity());
          dispatch(multisendActionCreator.getSubscribePeriod())
          dispatch(accountActionCreator.getAccountLiquidityAndSwap());
          dispatch(farmingActionCreator.getPersonalLiquidityInFarming())
          dispatch(accountActionCreator.getAllFarmingInfoByPoolId())
          dispatch(accountActionCreator.getWithdrawedRevenue())
          dispatch(accountActionCreator.getTeamPoolRevenue())
          dispatch(LevelsEnergyActionCreator.getLevelsUserInfo())
        }
        dispatch(multisendActionCreator.getFeeTypes())
        dispatch(LiquidityActionCreator.getFooterLiquidity());
        dispatch(ApplicationAcionCreator.getFooterInfo());
        dispatch(graphicActionCreator.getGraphicsData());
        dispatch(swapActionCreator.setSwapTokenBalances());
        dispatch(LiquidityActionCreator.getCurrentLiquidity());
        setSeconds(1);
        if (creating) {
          dispatch(accountActionCreator.checkPresentAfterCreate());
        }
        if (accounts.length > 0) {
          dispatch(accountActionCreator.getLevels());
        }
      } else if (seconds > Config().HEARTBEAT) {
        setSeconds(0)
      } else {
        setSeconds(seconds => seconds + 1);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [seconds]);

  useEffect(() => {
    if (history.location.pathname === '/account') history.push('/exonnft')
  }, [history.location.pathname])

  return (
    <div>
      <SecondsContext.Provider value={{ seconds, setSeconds }}>
        <AppRouter />
      </SecondsContext.Provider>
    </div>
  );
}

export default App;
