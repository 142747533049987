import Modal from "components/Modal"
import { Exon } from 'components/Icons';
import { getTokenInfoByAddress } from "utils/getTokenInfo";
import { FunctionComponent, useEffect, useState } from "react";
import useWalletSelector from "hooks/useWalletSelector";
import { coinIcons } from "components/CoinIcons";
import Button from "components/Button";
import { farmingActionCreator } from "store/reducers/farmingAdd/action-creator";
import { useDispatch } from "react-redux";

type MainProps = {
    setTeamWithdrawChoose: any }
const WithdrawChoose:FunctionComponent<MainProps> = ({setTeamWithdrawChoose}) => {

    const { farmingList } = useWalletSelector( state => state.farmingReducer )
    const { teamLevelsRevenue, withdrawedRevenue, teamPoolRevenue } = useWalletSelector( state => state.AccountReducer )
    const dispatch = useDispatch()
    const [chosenPools, setChosenPools] = useState([])
    const [claimAmount, setClaimAmount] = useState(0)

    const availablePools = () => {
        let availablePools = []
        for (let i = 0; i <= farmingList.length; i++) {
            if(teamPoolRevenue[i]?.amount) 
                if((teamPoolRevenue[i].amount - +withdrawedRevenue[i]?.amount) > 0){
                availablePools
                    .push({...farmingList.filter(el => el.poolId === i+1)[0], claimAmount: (teamPoolRevenue[i].amount - +withdrawedRevenue[i]?.amount).toFixed(6)})
            }
        }
        return availablePools
    }

    useEffect(() => {
        let totalClaimAmount = 0
        for (let i = 0; i < chosenPools.length; i++) {
            let poolTeamRevenue = 0
            poolTeamRevenue += teamPoolRevenue[chosenPools[i] - 1].amount
            totalClaimAmount += poolTeamRevenue - (+withdrawedRevenue[chosenPools[i] - 1].amount)
        }
        setClaimAmount(+totalClaimAmount.toFixed(6))
    }, [chosenPools])


    return (
        <Modal closeModal={() => setTeamWithdrawChoose(false)} customModal>
            <h1 className="relative text-2xl text-center text-title sm:text-4xl" >Claim</h1>
            <div className="relative mt-4 text-base text-center text-title">Choose from which pools do you want to claim your profit</div>
            <div className="relative mt-8">total reward {claimAmount}</div>
            <div className="mt-4">
                {availablePools().map(el => <PoolCard poolId={el.poolId} token0={el.token0} token1={el.token1} claimAmount={el.claimAmount} setChosenPool={setChosenPools} chosenPools={chosenPools} />)}
            </div>
            <div className="w-full mt-8 text-center">
                <Button onClick={() => {
                setTeamWithdrawChoose(false)
                dispatch(farmingActionCreator.setWithdrawAmount(+claimAmount.toFixed(6)))
                dispatch(farmingActionCreator.getTeamWithdrawByPool(chosenPools))
            }} disabled={!chosenPools.length}>Claim</Button>
            </div>
        </Modal>
    )
}

type Props = {
    token0: string, 
    token1: string, 
    claimAmount: number, 
    setChosenPool: any,
    poolId: number
    chosenPools: number[]
}

const PoolCard:FunctionComponent<Props> = ({token0, token1, poolId, claimAmount, setChosenPool, chosenPools}) => {

    const isActive = () => {
        for (let i = 0; i < chosenPools.length; i++) {
            if(chosenPools[i] === poolId) return true
        }
    }
    
    return(
        <div onClick={() => isActive() ? setChosenPool(chosenPools.filter(el => el !== poolId)) : setChosenPool([...chosenPools, poolId])} className={`flex mt-4 items-center ${claimAmount && 'cursor-pointer hover:bg-black'} px-4 py-2 border transition-all border-transparent bg-button justify-between rounded-xl ${isActive() && 'border-yellow'}`}>
            <div className="flex items-center">
                <div className="flex items-center mr-2">
                    <img className="w-8 h-8 -mr-2" src={coinIcons[getTokenInfoByAddress(token0)?.icon]} alt="" />
                    <img className="w-8 h-8" src={coinIcons[getTokenInfoByAddress(token1)?.icon]} alt="" />
                </div>
                <div className="flex items-center text-lg font-medium">
                    <span>{getTokenInfoByAddress(token0)?.name}</span>
                    <span>/</span>
                    <span>{getTokenInfoByAddress(token1)?.name}</span>
                </div>
            </div>
            <div className="flex items-center font-medium text-lightYellow">
                <span>{claimAmount}</span>
                <Exon className="h-4 ml-1" />
            </div>
        </div>
    )
}

export default WithdrawChoose
