import React, { FunctionComponent, useEffect, useState } from 'react';
import ButtonSwap from 'components/Common/Buttons/ButtonSwap';
import ButtonSelectEmpty from 'components/Common/Buttons/ButtonSwapEmpty';
import { useDispatch } from 'react-redux';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import useWalletSelector from 'hooks/useWalletSelector';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';
import { addLiquidityActionCreator } from 'store/reducers/liquidityAdd/action-creator';
import ArrowUpIcon from 'assets/arrowUp.svg';
import ButtonProcess from 'components/Button/ButtonProcess';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import { useHistory } from 'react-router-dom';
import configData from 'config';
import {ReactComponent as Share} from '../../../../../../../../components/Icons/assets/share.svg'
import copyTextLib from 'copy-to-clipboard';
import s from '../Share.module.css'
import { formatNumber } from 'utils/formatNumber';

const AddLiquidity: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { firstToken, secondToken, notification } = useWalletSelector(
    state => state.applicationReducer,
  );
  const { isTransaction } = useWalletSelector( state => state.applicationReducer )
  const {isWallet} = useWalletSelector(state => state.WalletReducer)
  const history = useHistory()
  const tokensList = configData().TOKENS_DATA;
  const [copy, setCopy] = useState(false);
  const { liquidityState, liquidityPairs } = useWalletSelector(state => state.liquidityReducer);
  const {
    firstTokenAmount,
    secondTokenAmount,
    poolInfo,
    firstTokenReserve,
    secondTokenReserve,
  } = useWalletSelector(state => state.addLiquidityReducer);

  const { firstTokenBalance, secondTokenBalance } = useWalletSelector(
    state => state.swapReducer,
  );

  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [isFirstBalance, setIsFirstBalance] =  useState(false)
  const [isSecondBalance, setIsSecondBalance] =  useState(false)

  const poolDisable = firstTokenBalance && secondTokenBalance && first && second

  const handlFirstToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    var firstTokenCurrent =
      e.currentTarget.value === '' ? '0' : e.currentTarget.value;
    dispatch(
      addLiquidityActionCreator.setFirstTokenAmount(
        parseFloat(firstTokenCurrent),
      ),
    );
    console.log(firstTokenReserve, secondTokenReserve);
    
    if (firstTokenReserve !== 0 && secondTokenReserve !== 0) {
      dispatch(addLiquidityActionCreator.setSecondTokenPrice());
    }
  };

  const handlSecondToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    var secondTokenCurrent =
      e.currentTarget.value === '' ? '0' : e.currentTarget.value;
    dispatch(
      addLiquidityActionCreator.setSecondTokenAmount(
        parseFloat(secondTokenCurrent),
      ),
    );
    if (firstTokenReserve !== 0 && secondTokenReserve !== 0) {
      dispatch(addLiquidityActionCreator.setFirstTokenPrice());
    }
  };

  const handlMaxFirst = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(addLiquidityActionCreator.setFirstTokenAmount(firstTokenBalance));
    dispatch(addLiquidityActionCreator.setSecondTokenPrice('max'));
    dispatch(addLiquidityActionCreator.setFirstTokenPrice('max'))
  };
  
  useEffect(() => {
    let firstToken = history.location.pathname.split('/')[3]?.split('-')[0]?.toUpperCase()
    let secondToken = history.location.pathname.split('/')[3]?.split('-')[1]?.toUpperCase()

    if(!firstToken || !secondToken ) return
    dispatch(ApplicationAcionCreator.setFirstToken(tokensList.filter(el => el.name === firstToken)[0]));
    dispatch(ApplicationAcionCreator.setSecondToken(tokensList.filter(el => el.name === secondToken)[0]));

  }, [history.location.pathname])

  const selectFirstToken = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    // console.log(e.currentTarget.id);
    dispatch(addLiquidityActionCreator.setFirstTokenReserve(0));
    dispatch(addLiquidityActionCreator.setSecondTokenAmount(0));
    dispatch(addLiquidityActionCreator.setFirstTokenAmount(0));
    dispatch(addLiquidityActionCreator.setSecondTokenAmount(0));
    if (liquidityState !== 'add') {
      dispatch(ApplicationAcionCreator.setSelectTokensModal(true));
      dispatch(ApplicationAcionCreator.setSelectedNumber(1));
      dispatch(addLiquidityActionCreator.getTokensReserves());
    }
  };

  const selectSecondToken = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    dispatch(addLiquidityActionCreator.setFirstTokenReserve(0));
    dispatch(addLiquidityActionCreator.setSecondTokenAmount(0));
    dispatch(addLiquidityActionCreator.setFirstTokenAmount(0));
    dispatch(addLiquidityActionCreator.setSecondTokenAmount(0));
    if (liquidityState !== 'add') {
      dispatch(ApplicationAcionCreator.setSelectTokensModal(true));
      dispatch(ApplicationAcionCreator.setSelectedNumber(2));
      dispatch(addLiquidityActionCreator.getTokensReserves());
    }
  };

  const handlCreatePool = () => {
    if (firstTokenAmount !== 0 && secondTokenAmount !== 0) {
      dispatch(LiquidityActionCreator.setLiquidityState('addLiquidityConfirm'));
    }
  };

  const copyText = (textToCopy: string) => {
    copyTextLib(textToCopy);
  }

  const copyToClipBoard = (address: string) => {
    const textToCopy = address;
    copyText(textToCopy);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };

  const goToInitial = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(LiquidityActionCreator.setLiquidityState('initial'));
    dispatch(LiquidityActionCreator.setLiquidityMainState('initial'));
  };

  useEffect(() => {
    setIsFirstBalance(false)
    setIsSecondBalance(false)

    if (+firstTokenAmount <= +firstTokenBalance) {
      setIsFirstBalance(true)
    }
    if (+secondTokenAmount <= +secondTokenBalance) {
      setIsSecondBalance(true)
    }
    if(firstTokenAmount == 0) setFirst(false)
    if(secondTokenAmount == 0) setSecond(false)
    if(!firstTokenAmount || !secondTokenBalance) return
    
    setFirst(isFirstBalance)
    setSecond(isSecondBalance)
  }, [firstTokenAmount, secondTokenAmount, isFirstBalance, isSecondBalance, firstTokenReserve, secondTokenReserve])

  return (
    <div className="flex justify-center flex-col px-4 sm:px-7 py-5 relative">
      <div className="text-center text-2xl text-title mx-auto">
      <button 
            disabled={(!firstToken.name || !secondToken.name)}
            onClick={() => copyToClipBoard(`https://app.exoncenter.com/swap/liquidity/${firstToken.name?.toLowerCase()}-${secondToken.name?.toLowerCase()}`)} 
            style={{
              boxShadow: '-5px 5px 10px black'
            }}
            className={`${s.share} whitespace-nowrap px-2 py-2 
                        text-center flex items-center
                        left-0 hover:left-32
                        -top-12
                        transition-all absolute
                         rounded-br-2xl
                        overflow-hidden
                        ${copy ? 'bg-green' : 'bg-yellow'}
                        text-black ${(!firstToken.name || !secondToken.name) && 'opacity-50'}`}
          >
            <span className='mr-2 text-xs sm:text-sm font-bold w-16 sm:w-20'>{copy ? 'Copied' : 'Share pair'}</span>
            <Share className=' w-4 h-4 mr-1' />
          </button>
        <div className="flex items-center">
          <div
            onClick={e => goToInitial(e)}
            style={{
              borderRight: '3px solid #FDD807',
              borderTop: '3px solid #FDD807',
              width: '18px',
              height: '18px',
              transform: 'rotate(-133deg)',
              display: 'inline-block',
              cursor: 'pointer',
            }}
          />
            <div className="text-2xl">Add liquidity</div>
          </div>
        </div>
      <div className="flex justify-items-center flex-col mt-6">
        <div className="ml-4 text-xs text-description">From:</div>
        <div className="flex justify-center items-stretch gap-4 sm:gap-0">
          <div className="w-full">
            <div className="relative">
              <input
                disabled={isTransaction || (!firstToken.name || !secondToken.name )}
                type="number"
                value={firstTokenAmount.toString()}
                onChange={e => handlFirstToken(e)}
                onClick={() => dispatch(addLiquidityActionCreator.getTokensReserves())}
                className={`w-full leading-9 text-title rounded-t-lg bg-input bg-opacity-50 px-4 py-0.5 border-b ${first ? 'border-green focus:border-yellow' : ( !isFirstBalance  ? 'border-red focus:border-red' : 'focus:border-yellow' )} focus:outline-none focus:bg-opacity-100`}
              />
              <div
                  onClick={e => (firstToken.name && secondToken.name ) && +firstTokenBalance && handlMaxFirst(e)}
                  className="bg-yellow bg-opacity-15 h-full w-auto cursor-pointer flex items-center px-3 rounded-tr-lg uppercase text-sm text-yellow absolute top-0 right-0"
                >
                Max
              </div>
            </div>
            <div className="flex flex-col text-right mt-1">
            <div className="text-sm text-red whitespace-nowrap">
                  {(!isFirstBalance && +firstTokenAmount !== 0) 
                    ? isWallet && 'Value is greater than your balance' 
                    : ''}
                </div>
              <div className="text-sm text-description">
                Balance: {formatNumber(firstTokenBalance)} {firstToken.name}
              </div>
            </div>
          </div>
          <div
            className="self-start"
            onClick={e => !isTransaction && selectFirstToken(e)}
          >
            {Object.keys(firstToken).length === 0 ? (
              <ButtonSelectEmpty />
            ) : (
              <ButtonSwap
                icon={firstToken.icon || ''}
                name={firstToken.name || ''}
                address={firstToken.address || ''}
              />
            )}
          </div>
        </div>
        <div className="text-lightYellow text-6xl font-extralight mx-auto">
          +
        </div>
        <div className="ml-4 text-description text-xs">To:</div>
        <div className="flex justify-center items-stretch gap-4 sm:gap-0">
          <div className="w-full">
            <div className="relative">
              <input
                disabled={isTransaction || (!firstToken.name || !secondToken.name )}
                type="number"
                value={secondTokenAmount.toString()}
                onChange={e => handlSecondToken(e)}
                onClick={() => dispatch(addLiquidityActionCreator.getTokensReserves())}
                className={`w-full leading-9 text-title rounded-t-lg bg-input bg-opacity-50  px-4 py-0.5 border-b ${second ? 'border-green focus:border-yellow' : ( !isSecondBalance  ? 'border-red focus:border-red' : 'focus:border-yellow' )} focus:outline-none focus:bg-opacity-100`}
              />
              {/* <div
                  onClick={e => (firstToken.name && secondToken.name ) && +secondTokenBalance && handlMaxSecond(e)}
                  className="bg-yellow bg-opacity-15 h-full w-auto cursor-pointer flex items-center px-3 rounded-tr-lg uppercase text-sm text-yellow absolute top-0 right-0"
                >
                Max
              </div> */}
            </div>
            <div className="flex flex-col text-right mt-1">
            <div className="text-sm text-red whitespace-nowrap">
                  {(!isSecondBalance && +secondTokenAmount !== 0) 
                    ? isWallet && 'Value is greater than your balance' 
                    : ''}
                </div>
              <div className="text-sm text-description">
                Balance: {formatNumber(secondTokenBalance)} {secondToken.name}
              </div>
            </div>
          </div>
          <div
            className="self-start"
            onClick={e => !isTransaction && selectSecondToken(e)}
          >
            {Object.keys(secondToken).length === 0 ? (
              <ButtonSelectEmpty />
            ) : (
              <ButtonSwap
                icon={secondToken.icon || ''}
                name={secondToken.name || ''}
                address={secondToken.address || ''}
              />
            )}
          </div>
        </div>
        {liquidityState === 'add' ? (
          <>
            <div className="text-2xl text-title mt-10">
              Prices and pool share
            </div>
            <div
              className="flex justify-between py-3 px-5 rounded-2xl gap-4 text-lg text-title mt-5"
              style={{ backgroundColor: '#4F4F4F' }}
            >
              <div>
                <div>
                  {poolInfo.secondDepositTokenInfo.name} per{' '}
                  {poolInfo.firstDepositTokenInfo.name}
                </div>
                <div className='text-xs'>{formatNumber((secondTokenReserve / firstTokenReserve).toFixed(2))}</div>
              </div>
              <div>
                <div>
                  {poolInfo.firstDepositTokenInfo.name} per{' '}
                  {poolInfo.secondDepositTokenInfo.name}
                </div>
                <div className='text-xs'>{formatNumber((firstTokenReserve / secondTokenReserve).toFixed(2))}</div>
              </div>
              <div>
                <div>Share of pool</div>
                <div className='text-xs'>
                  {formatNumber((poolInfo.liquidity / poolInfo.totalSupply).toFixed(4))}
                </div>
              </div>
            </div>
            <div className="text-2xl text-title mt-10">Your position</div>
            <div
              className="flex justify-between py-3 px-5 rounded-2xl gap-4 text-lg text-title mt-5"
              style={{ backgroundColor: '#4F4F4F' }}
            >
              <div>
                <div>{poolInfo.firstDepositTokenInfo.name}</div>
                <div className='text-xs'>
                  {formatNumber((
                    (poolInfo.liquidity / poolInfo.totalSupply) *
                    firstTokenReserve
                  ).toFixed(4))}
                </div>
              </div>
              <div>
                <div>{poolInfo.secondDepositTokenInfo.name}</div>
                <div className='text-xs'>
                  {formatNumber((
                    (poolInfo.liquidity / poolInfo.totalSupply) *
                    secondTokenReserve
                  ).toFixed(4))}
                </div>
              </div>
              <div>
                <div>Pool tokens</div>
                <div className='text-xs'>{formatNumber(poolInfo.liquidity)}</div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}

        <div className="text-center mt-6">
          {/* <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-2 bg-yellow text-base font-medium text-mainBlack hover:bg-black-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handlCreatePool}
            disabled={notification.isShown}
            style={notification.isShown ? { opacity: '0.5' } : {}}
          >
            {liquidityState === 'createPool' ? 'Create pool' : 'Supply'}
          </button> */}

          {isWallet ? <ButtonProcess
            name={liquidityState === 'createPool' ? 'Create pool' : 'Supply'}
            onClick={handlCreatePool}
            classname={`${(!poolDisable || isTransaction) && 'cursor-default'} w-full inline-flex justify-center rounded-md 
                       border border-transparent shadow-sm px-6 py-2 bg-yellow 
                       text-base font-medium text-mainBlack hover:bg-black-700 
                       focus:outline-none focus:ring-2 focus:ring-offset-2 
                       focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`}
            disabled={!poolDisable || isTransaction}
          /> : <ConnectWalletButton />}
        </div>
      </div>
    </div>
  );
};

export default AddLiquidity;
