import { Arrow } from 'components/Icons';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';

const Sorting: FunctionComponent = () => {

  const dispatch = useDispatch()

  return (
    <div className="flex items-center ml-0 sm:ml-24">
      <span className="text-xl text-title mr-5">Sort by</span>
      <label className="relative block w-44">
        <Arrow
          className="absolute transform -rotate-90 top-1/2 -translate-y-1/2"
          style={{ right: 20 }}
        />
        <select onChange={e => dispatch(farmingActionCreator.setSortOption(e.target.value.toUpperCase()))} className="form-select block w-full mt-1 outline-none bg-cardBg rounded-lg py-2 px-4 appearance-none">
          <option>All</option>
          <option>Core</option>
          <option>Community</option>
          <option>Sponsored</option>
        </select>
      </label>
    </div>
  );
};

export default Sorting;
