import React from 'react';
import Loading from './assets/loader.gif';
import useWalletSelector from 'hooks/useWalletSelector';

export default function CreatingAccount() {
  const { creating } = useWalletSelector(state => state.AccountReducer);
  return (
    <>
      {creating && (
        <div
          style={{zIndex: 10000}}
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-cardBg rounded-4xl text-left overflow-hidden shadow-xl transform transition-all sm:my-7 sm:align-middle sm:max-w-sm sm:w-full">
              <div className="bg-cardBg px-4 pt-5 pb-4 sm:p-8 sm:pb-4">
                <div className="sm:flex sm:items-start flex-col">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-title"
                      id="modal-title"
                    >
                      Please wait...
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-description">
                        Your unique token is being created...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-lightBlack px-4 py-1 justify-center flex">
                <img src={Loading} alt="Minting" style={{ height: '100px' }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
