export interface LevelsEnergyState {
  levelsEnergyState: string,
  levelsPrice: number[],
  levelsBuyLimits: number[],
  levelsLimitCount: number[],
  actualClaimAmount: number,
  totalSalesAmount: number,
  chosenPacket: number,
  userInfo: LevelsEnergyUserInfo
}

export interface LevelsEnergyUserInfo {
  amount: number,
  packet: number,
  referrals: number,
  referralsAmount: number,
  availableAmount: number,
  buy: boolean
}

export enum LevelsEnergyStateEnum {
  SET_LEVELS_PRICE = 'SET_LEVELS_PRICE',
  SET_LEVELS_BUY_LIMITS = 'SET_LEVELS_BUY_LIMITS',
  SET_LEVELS_LIMIT_COUNT = 'SET_LEVELS_LIMIT_COUNT',
  SET_LEVELS_USER_INFO = 'SET_LEVELS_USER_INFO',
  SET_LEVELS_CHOSEN_PACKET = 'SET_LEVELS_CHOSEN_PACKET',
  SET_LEVELS_ENERGY_STATE = 'SET_LEVELS_ENERGY_STATE',
  SET_ACTUAL_CLAIM_AMOUNT = 'SET_ACTUAL_CLAIM_AMOUNT',
}

export interface setActualClaimAmount {
  type: LevelsEnergyStateEnum.SET_ACTUAL_CLAIM_AMOUNT,
  payload: number
}

export interface setLevelsEnergyState {
  type: LevelsEnergyStateEnum.SET_LEVELS_ENERGY_STATE,
  payload: string
}

export interface setLevelsChosenPacket {
  type: LevelsEnergyStateEnum.SET_LEVELS_CHOSEN_PACKET,
  payload: number
}

export interface setLevelsPrice {
  type: LevelsEnergyStateEnum.SET_LEVELS_PRICE,
  payload: number[]
}

export interface setLevelsBuyLimits {
  type: LevelsEnergyStateEnum.SET_LEVELS_BUY_LIMITS,
  payload: number[]
}

export interface setLevelsLimitCount {
  type: LevelsEnergyStateEnum.SET_LEVELS_LIMIT_COUNT,
  payload: number[]
}

export interface setLevelsUserInfo {
  type: LevelsEnergyStateEnum.SET_LEVELS_USER_INFO,
  payload: LevelsEnergyUserInfo
}

export type LevelsEnergyAction =
  | setLevelsPrice
  | setLevelsBuyLimits
  | setLevelsLimitCount
  | setLevelsUserInfo
  | setLevelsChosenPacket
  | setLevelsEnergyState
  | setActualClaimAmount
