import { SwapAction, SwapCurrentState, SwapStateEmun } from './types';

const initialState: SwapCurrentState = {
  firstTokenApprove: false,
  secondTokenApprove: false,
  inProcess: false,
  firstTokenInfo: {},
  secondTokenInfo: {},
  firstTokenBalance: 0,
  secondTokenBalance: 0,
  firstTokenAmount: 0,
  secondTokenAmount: 0,
  priceFirstInSecond: 0,
  priceSecondInFirst: 0,
  priceImpact: 0,
  swapFee: 0,
  swapSetting: { slippage: 0.1, deadline: 30 },
  swapSettingModal: false,
  swapConfirmModal: false,
  swapState: 'initial',
  firstTokenReserve: 0,
  secondTokenReserve: 0,
};

export default function swapReducer(state = initialState, action: SwapAction) {
  switch (action.type) {
    case SwapStateEmun.SET_PROCESS:
      return { ...state, inProcess: action.payload };
    case SwapStateEmun.SET_FIRST_TOKEN_INFO:
      return { ...state, firstTokenInfo: action.payload };
    case SwapStateEmun.SET_SECOND_TOKEN_INFO:
      return { ...state, secondTokenInfo: action.payload };
    case SwapStateEmun.SET_FIRST_TOKEN_AMOUNT:
      return { ...state, firstTokenAmount: action.payload };
    case SwapStateEmun.SET_SECOND_TOKEN_AMOUNT:
      return { ...state, secondTokenAmount: action.payload };
    case SwapStateEmun.SET_PRICE_FIRST_IN_SECOND:
      return { ...state, priceFirstInSecond: action.payload };
    case SwapStateEmun.SET_PRICE_SECOND_IN_FIRST:
      return { ...state, priceSecondInFirst: action.payload };
    case SwapStateEmun.SET_PRICE_IMPACT:
      return { ...state, priceImpact: action.payload };
    case SwapStateEmun.SET_SWAP_FEE:
      return { ...state, swapFee: action.payload };
    case SwapStateEmun.SET_CONFIRM_SWAP:
      return { ...state, confirmSwap: action.payload };
    case SwapStateEmun.SET_FIST_TOKEN_APPROVE:
      return { ...state, firstTokenApprove: action.payload };
    case SwapStateEmun.SET_SECOND_TOKEN_APPROVE:
      return { ...state, secondTokenApprove: action.payload };
    case SwapStateEmun.SET_SWAP_SETTING:
      return { ...state, swapSetting: action.payload };
    case SwapStateEmun.SET_FIRST_TOKEN_BALANCE:
      return { ...state, firstTokenBalance: action.payload };
    case SwapStateEmun.SET_SECOND_TOKEN_BALANCE:
      return { ...state, secondTokenBalance: action.payload };
    case SwapStateEmun.SET_SWAP_STATE:
      return { ...state, swapState: action.payload };
    case SwapStateEmun.SET_SWAP_SETTING_MODAL:
      return { ...state, swapSettingModal: action.payload };
    case SwapStateEmun.SET_TOKENS_RESERVE:
      return {
        ...state,
        firstTokenReserve: action.payload.firstTokenReserve,
        secondTokenReserve: action.payload.secondTokenReserve,
      };
    case SwapStateEmun.SET_SWAPPED_TOKENS:
      return {
        ...state,
        firstTokenInfo: action.payload.secondTokenInfo,
        secondTokenInfo: action.payload.firstTokenInfo,
        firstTokenReserve: action.payload.secondTokenReserve,
        secondTokenReserve: action.payload.firstTokenReserve,
        firstTokenAmount: action.payload.secondTokenAmount,
        secondTokenAmount: action.payload.firstTokenAmount,
        firstTokenBalance: action.payload.secondTokenBalance,
        secondTokenBalance: action.payload.firstTokenBalance,
      }
    default:
      return state;
  }
}
