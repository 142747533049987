import React, { FunctionComponent, useEffect, useState } from 'react';
import ButtonSwap from 'components/Common/Buttons/ButtonSwap';
import ButtonSelectEmpty from 'components/Common/Buttons/ButtonSwapEmpty';
// import SelectToken from 'components/Common/SelectToken';
import { useDispatch } from 'react-redux';
import useWalletSelector from 'hooks/useWalletSelector';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator'; import { swapActionCreator } from 'store/reducers/swap/action-creators'; import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import {ReactComponent as SwapArrow} from '../../assets/swapArrows.svg'
import { useHistory } from 'react-router-dom';
import configData from 'config';
import {ReactComponent as Share} from '../../../../../../components/Icons/assets/share.svg'
import s from '../PoolProcess/components/Share.module.css'
import copyTextLib from 'copy-to-clipboard';
import { formatNumber } from 'utils/formatNumber';
import ButtonSwapToken from 'components/Common/Buttons/ButtonSwapToken';

const SwapProcess: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { firstToken, secondToken } = useWalletSelector(
    state => state.applicationReducer,
  );
  const { isWallet } = useWalletSelector(state => state.WalletReducer);
  const tokensList = configData().TOKENS_DATA;
  const {
    firstTokenBalance, secondTokenAmount,
    firstTokenAmount,
    secondTokenBalance,
    firstTokenReserve,
    secondTokenReserve
  } = useWalletSelector(state => state.swapReducer);
  const { isTransaction } = useWalletSelector(state => state.applicationReducer)
  const [first, setFirst] = useState(false)
  const [second, setSecond] = useState(false)
  const [isFirstBalance, setIsFirstBalance] =  useState(false)
  const [isFirstReserve, setIsFirstReserve] =  useState(false)
  const [isSecondReserve, setIsSecondReserve] =  useState(false)
  const [copy, setCopy] = useState(false);
  const history = useHistory()

  const swapDisable = !firstTokenBalance || !first || !second
  const untradablePair = (( Object.keys(firstToken).length && Object.keys(secondToken).length ) ? (!firstTokenReserve && !secondTokenReserve) : null)

  const handlFirstToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    let firstTokenInput: number;
    firstTokenInput = e.currentTarget.value === '' ? 0 : parseFloat(e.currentTarget.value);
		console.log(firstTokenInput)
    // set first token in state
    // dispatch(swapActionCreator.getTokensReserve());
    dispatch(swapActionCreator.setFirstTokenAmount(firstTokenInput));
    dispatch(swapActionCreator.setSecondTokenPrice());
    
  };

  useEffect(() => {
    let firstToken = history.location.pathname.split('/')[2]?.split('-')[0]?.toUpperCase()
    let secondToken = history.location.pathname.split('/')[2]?.split('-')[1]?.toUpperCase()

    if(!firstToken || !secondToken ) return
    dispatch(ApplicationAcionCreator.setFirstToken(tokensList.filter(el => el.name === firstToken)[0]));
    dispatch(ApplicationAcionCreator.setSecondToken(tokensList.filter(el => el.name === secondToken)[0]));

  }, [history.location.pathname])

  const handlSecondToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    let secondTokenInput: number;
    secondTokenInput =
      e.currentTarget.value === '' ? 0 : parseFloat(e.currentTarget.value);
    // dispatch(swapActionCreator.getTokensReserve());
    secondTokenInput = parseFloat(secondTokenInput.toFixed(6));
    dispatch(swapActionCreator.setSecondTokenAmount(secondTokenInput));
    dispatch(swapActionCreator.setFirstTokenPrice());
  };

  const handlMaxFirst = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(swapActionCreator.setFirstTokenAmount(firstTokenBalance > firstTokenReserve ? firstTokenReserve : firstTokenBalance));
    dispatch(swapActionCreator.setSecondTokenPrice());
  };
  const handlMaxSecond = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(swapActionCreator.setSecondTokenAmount(secondTokenReserve));
    dispatch(swapActionCreator.setFirstTokenPrice());
  };

  const selectFirstToken = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    dispatch(ApplicationAcionCreator.setSelectTokensModal(true));
    dispatch(ApplicationAcionCreator.setSelectedNumber(1));
    dispatch(swapActionCreator.setFirstTokenAmount(0));
    dispatch(swapActionCreator.setSecondTokenAmount(0));
  };

  const selectSecondToken = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    dispatch(ApplicationAcionCreator.setSelectTokensModal(true));
    dispatch(ApplicationAcionCreator.setSelectedNumber(2));
    dispatch(swapActionCreator.setFirstTokenAmount(0));
    dispatch(swapActionCreator.setSecondTokenAmount(0));
  };

  const handlSwap = () => {
    dispatch(swapActionCreator.calculatePriceImpact());
  };

  const tokenPlaceSwap = () => {
    let firstTokenInput: number;
    firstTokenInput = secondTokenAmount
    // set first token in state
    // dispatch(swapActionCreator.getTokensReserve());
    if (+secondTokenAmount > +secondTokenReserve) {
      let reserveLimit = parseFloat(
        (secondTokenReserve - 0.1).toString(),
      ).toFixed(6);
      dispatch(
        swapActionCreator.setSecondTokenAmount(parseFloat(reserveLimit)),
      );
      dispatch(swapActionCreator.setFirstTokenPrice());
    }
    dispatch(ApplicationAcionCreator.setSwapTokens(firstToken, secondToken))
    dispatch(swapActionCreator.setSwappedTokens(
      firstToken,
      secondToken,
      firstTokenReserve,
      secondTokenReserve,
      firstTokenAmount,
      secondTokenAmount,
      firstTokenBalance,
      secondTokenBalance,
    ))
    dispatch(swapActionCreator.setFirstTokenAmount(firstTokenInput));
    dispatch(swapActionCreator.setSecondTokenPrice());
  }

  // console.log(firstToken.name, secondToken.name);
  
  const copyText = (textToCopy: string) => {
    copyTextLib(textToCopy);
  }

  const copyToClipBoard = (address: string) => {
    const textToCopy = address;
    copyText(textToCopy);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };
  

  useEffect(() => {
    // if(firstTokenAmount != 0) setFirst(true)
    // else setFirst(false)

    // if(secondTokenAmount != 0) setSecond(true)
    // else setSecond(false)
    setIsFirstBalance(false)
    setIsFirstReserve(false)
    setIsSecondReserve(false)

    if (+firstTokenAmount <= +firstTokenBalance) {
      setIsFirstBalance(true)
    }
    if (+firstTokenAmount <= +firstTokenReserve) {
      setIsFirstReserve(true)
    }
    if (+secondTokenAmount <= +secondTokenReserve) {
      setIsSecondReserve(true)
    }
    if(+firstTokenAmount === 0) setFirst(false)
    if(+secondTokenAmount === 0) setSecond(false)
    if(!firstTokenAmount || !secondTokenAmount) return
    
    setFirst(isFirstBalance && isFirstReserve)
    setSecond(isSecondReserve)
  }, [firstTokenAmount, secondTokenAmount, isFirstBalance, isFirstReserve, isSecondReserve, firstTokenReserve, secondTokenReserve,])

  useEffect(() => {
    dispatch(swapActionCreator.getTokensReserve())
  }, [firstTokenBalance, secondTokenBalance, firstToken, secondToken])
  
  return (
    <>
      <div className="relative flex flex-col justify-center px-4 py-5 sm:px-7">
        
        <div className="flex flex-col mt-2 justify-items-center">
        <button 
            disabled={(!firstToken.name || !secondToken.name)}
            onClick={() => copyToClipBoard(`https://app.exoncenter.com/swap/${firstToken.name?.toLowerCase()}-${secondToken.name?.toLowerCase()}`)} 
            style={{
              boxShadow: '-5px 5px 10px black'
            }}
            className={`${s.share} whitespace-nowrap px-2 py-2 
                        text-center flex items-center
                        left-0 hover:left-32
                        -top-12 
                        transition-all absolute rounded-br-2xl
                        overflow-hidden
                        ${copy ? 'bg-green' : 'bg-yellow'}
                        text-black ${(!firstToken.name || !secondToken.name) && 'opacity-50'}`}
          >
            <span className='w-16 mr-2 text-xs font-bold sm:text-sm sm:w-20'>{copy ? 'Copied' : 'Share pair'}</span>
            <Share className='w-4 h-4 mr-1' />
          </button>
          <div className="mx-auto text-2xl">Token swap</div>
          <div className="mt-6 ml-4 text-xs font-xs text-description">From:</div>
          <div className="flex justify-center mb-10 gap-4 sm:gap-0">
            <div className="w-full">
              <div className="relative">
                <input
                  disabled={isTransaction || (!firstToken.name || !secondToken.name )}
                  // onFocus={() => dispatch(swapActionCreator.getTokensReserve()) }
                  type="number"
                  value={firstTokenAmount.toString()}
                  onChange={e => handlFirstToken(e)}
                  className={`w-full leading-9 text-title rounded-t-lg bg-input bg-opacity-50  px-4 py-0.5 border-b ${first ? 'border-green focus:border-yellow' : ( (!isFirstBalance || !isFirstReserve) ? 'border-red focus:border-red' : 'focus:border-yellow' )} focus:outline-none focus:bg-opacity-100`}
                />
                <div
                  onClick={e => (firstToken.name && secondToken.name ) && +firstTokenBalance && handlMaxFirst(e)}
                  className="absolute top-0 right-0 flex items-center w-auto h-full px-3 text-sm uppercase rounded-tr-lg cursor-pointer bg-yellow bg-opacity-15 text-yellow"
                >
                  Max
                </div>
              </div>
              <div className="flex flex-col mt-1 text-right">
                <div className="text-sm text-red whitespace-nowrap">
                  {!isTransaction && ((!isFirstBalance && +firstTokenAmount !== 0) 
                    ? isWallet && 'Value is greater than your balance' 
                    : !isFirstReserve 
                      ? 'Value is greater than reserve'
                      : '')}
                </div>
                <div className="text-sm text-description">
                  Balance: {formatNumber(firstTokenBalance)} {firstToken.name}
                </div>
              </div>
            </div>
            <div className=''>
            <ButtonSwapToken
              onClick={(e: any) => !isTransaction && selectFirstToken(e)}
              tokenIcon={firstToken.icon}
              tokenName={firstToken.name}
              tokenAddress={firstToken.address}
              isToken={Object.keys(firstToken).length === 0 }
            />
              <div className="flex items-center">
          
        </div>
              <div onClick={() => !isTransaction && tokenPlaceSwap()} 
                   style={{transform: 'translate(-100%, 75%)'}} 
                   className={`absolute transition-all ${isTransaction ? 'cursor-default' : 'cursor-pointer '} top-32 -right-5 sm:right-6 px-3 py-2 rounded-lg`}
                   >
                <SwapArrow />
              </div>
            </div>
          </div>
          <div className="mt-2 ml-4 text-xs font-xs text-description">To:</div>
          <div className="flex justify-center gap-4 sm:gap-0">
            <div className="w-full">
              <div className="relative">
               <input
                  disabled={isTransaction || (!firstToken.name || !secondToken.name)}
                  // onFocus={() => dispatch(swapActionCreator.getTokensReserve()) }
                  type="number"
                  value={secondTokenAmount.toString()}
                  onChange={e => handlSecondToken(e)}
                  className={`w-full leading-9 rounded-t-lg text-title bg-input bg-opacity-50  px-4 py-0.5 border-b ${second ? 'border-green focus:border-yellow' : ( !isSecondReserve ? 'border-red focus:border-red' : 'focus:border-yellow' )} focus:outline-none focus:bg-opacity-100`}
                />
                {/* <div onClick={(e) => (firstToken.name && secondToken.name ) && +secondTokenReserve && handlMaxSecond(e)} className="absolute top-0 right-0 flex items-center w-auto h-full px-3 text-sm uppercase rounded-tr-lg cursor-pointer bg-yellow bg-opacity-15 text-yellow">
                  Max
                </div> */}
              </div>
              <div className="flex flex-col mt-1 text-right">
              <div className="text-sm text-red whitespace-nowrap">
                  {!isTransaction && ((!isSecondReserve && +firstTokenAmount !== 0) 
                      ? 'Value is greater than reserve'
                      : '')}
                </div>
                <div className="text-sm text-description">
                  Balance: {formatNumber(secondTokenBalance)} {secondToken.name}
                </div>
              </div>
            </div>
            <ButtonSwapToken
              onClick={(e: any) => !isTransaction && selectSecondToken(e)}
              tokenIcon={secondToken.icon}
              tokenName={secondToken.name}
              tokenAddress={secondToken.address}
              isToken={Object.keys(secondToken).length === 0 }
            />
          </div>
          <div className="mt-10 text-center">
            {isWallet ? (
              <>
                { untradablePair && !isTransaction
                && <div className='text-sm mbmb-4 text-red'>This pair is unavailable for now!</div>}
                <button
                  style={(!swapDisable && !isTransaction) ? {} : { opacity: '0.5' }}
                  type="button"
                  className={`${(swapDisable || isTransaction) && 'cursor-default'} w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-2 bg-yellow text-base font-medium text-mainBlack hover:bg-black-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm mb-5`}
                  onClick={handlSwap}
                  disabled={swapDisable || isTransaction}
                >
                  Swap
                </button>
              </>
            ) : (
              <ConnectWalletButton />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SwapProcess;
