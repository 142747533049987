import useWalletSelector from 'hooks/useWalletSelector';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { WalletConnectActionCreator } from 'store/reducers/wallet/action-creators';
import LogoutIcon from './assets/logout.svg';
import Back from 'assets/back.svg'
import { ReactComponent as Burger } from './assets/burger.svg';
import { SidebarContext } from 'components/Layout/Layout';
import copyTextLib from 'copy-to-clipboard';
import {useHistory} from 'react-router';

const NavBar = () => {
  const dispatch = useDispatch();
  const { isWallet, address } = useWalletSelector(state => state.WalletReducer);
  const { setIsSidebarOpen } = useContext(SidebarContext);
  const [copy, setCopy] = useState(false)
  const history = useHistory()
  const [isBackArrow, setIsBackArrow] = useState(false)

  useEffect(() => {
    console.log(!!history.location.pathname.split('/')[2]?.length);
  
    if (
      history.location.pathname.split(('/'))[1] !== 'swap' &&
      !!history.location.pathname.split('/')[2]?.length
    ) {
      setIsBackArrow(true)
    }else setIsBackArrow(false)
  }, [history.location.pathname])

  const connectWallet = (e: any) => {
    dispatch(WalletConnectActionCreator.connectWallet());
  };

  const handlLogout = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    window.location.reload()
    dispatch(WalletConnectActionCreator.setWallet(false));
  };


  const copyText = (textToCopy: string) => {
    copyTextLib(textToCopy);
  }

  const copyToClipBoard = (address: string) => {
    const textToCopy = address;
    copyText(textToCopy);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };

  return (
    <div className="container ">
			<div id='scroll_to_top'></div>
      <div className="flex items-center justify-between py-5 sm:justify-end">
        <Burger
          className="cursor-pointer sm:hidden"
          onClick={() => setIsSidebarOpen(true)}
        />
        <div className="flex items-center">
          {isBackArrow && (
            <div onClick={e => history.push('/' + history.location.pathname.split('/')[1])}>
              <img
                src={Back}
                alt="Back"
                className="mr-4 cursor-pointer"
                style={{ height: '40px' }}
              />
            </div>
          )}
          <button
            className={` ${copy ? 'bg-green text-green hover:border-green' : 'bg-yellow text-yellow hover:border-yellow' } transition-all ${ isWallet && 'w-40'  } bg-opacity-10  uppercase text-sm sm:text-base px-6 py-2 rounded-lg border border-transparent  border-opacity-60`}
            onClick={(e) => isWallet ? copyToClipBoard(address) : connectWallet(e)}
          >
            {!isWallet
              ? 'Connect wallet'
              : copy ? 'Copied' : address.substring(0, 4) + '...' + address.substring(30, 34)}
          </button>
          {isWallet && (
            <div onClick={e => handlLogout(e)}>
              <img
                src={LogoutIcon}
                alt="logout"
                className="ml-4 cursor-pointer"
                style={{ height: '40px' }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
