import React, { createContext, FunctionComponent, useState } from 'react';
import NavBar from '../NavBar'; import Sidebar from '../Sidebar';
import CreateAccount from '../Popups/CreateAccount';
import CreatingAccount from '../Popups/CreatingAccount';
import AccountCreated from '../Popups/AccountCreated';
import TransactionError from 'components/Popups/TransactionError';
import SelectToken from 'components/Common/SelectToken';
import Footer from 'components/Footer';
import { isMobile } from 'react-device-detect';
import NotCorrectChain from 'components/Popups/NotCorrectChain';
import 'styles/hideScroll.css';
import Notification from 'components/Notification';
import milkyWay from '../../assets/milkyway.jpg'
import SwapSteps from 'pages/Swap/components/SwapContainer/components/SwapProcess/components/SwapSteps';
import InstallTronweb from 'components/Popups/InstallTronweb';
import LoginTronlink from 'components/Popups/LogInTronlink';
import WithdrawModal from 'components/Popups/WithdrawModal';
import useWalletSelector from 'hooks/useWalletSelector';
import s from './Layout.module.css'

interface ContextProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: Function;
}

export const SidebarContext = createContext<ContextProps>({
  isSidebarOpen: true,
  setIsSidebarOpen: () => null,
});

const Layout: FunctionComponent = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(isMobile ? false : true);
  const isSmallScreen = window.innerHeight < 760;
  const [isSelectToken, setIsSelectToken] = useState(false)
  const { openSelect } = useWalletSelector(state => state.applicationReducer)

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, setIsSidebarOpen }}>
      <div
        className={`h-screen mb-auto ${
          isSidebarOpen ? 'overflow-hidden sm:overflow-visible' : null
        }`}
      >
        <Sidebar />
        <div
          className="hide-scroll"
          style={
            !isMobile
              ? isSidebarOpen && isSmallScreen
                ? { height: '809px' }
                : { height: '100vh' }
              : {}
          }
        >
          <div
            className="hide-scroll"
            style={!isMobile ? { height: 'inherit', overflow: 'auto' } : {}}
          >
            <main
              className={`h-full hide-scroll overflow-x-hidden overflow-auto`}
            >
              <div className={` px-4 sm:px-16 overflow-hidden relative z-50 min-h-full ${
                isSidebarOpen ? 'hidden sm:block sm:ml-60' : 'sm:ml-24'
              } pb-6 `}>
                <NavBar />
                <NotCorrectChain />
                <CreateAccount />
                <CreatingAccount />
                <AccountCreated />
                {openSelect && <SelectToken />}
                <Notification />
                <TransactionError />
                <SwapSteps />
                <InstallTronweb />
                <LoginTronlink />
                <WithdrawModal />
                {children}
              </div>
              <Footer
                className={`px-4  relative z-10 ${
                  isSidebarOpen ? 'hidden sm:block sm:ml-60' : 'sm:ml-24'
                }`}
              />
            </main>
            <div className={s.background__stars}>
              <div className={s.stars}></div>
              <div className={s.stars2}></div>
              <div className={s.stars3}></div>
            </div>
          </div>
        </div>
      </div>
    </SidebarContext.Provider>
  );
};

export default Layout;
