import Layout from 'components/Layout';
import { useEffect, useRef } from 'react';
import { ReactComponent as Search } from '../../components/Icons/assets/search.svg';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { useDispatch } from 'react-redux';
import { graphicActionCreator } from 'store/reducers/graphic/action-creator';
import useWalletSelector from 'hooks/useWalletSelector';
import { Redirect, useHistory } from 'react-router';
import WalletWarning from './Components/assets/WalletWarning';
import { Charts } from './Components/Charts';
import { TradingPairs } from './Components/TraidingPairs';
import { Transactions } from './Components/Transactions';
import s from './Analytics.module.css';
import AnalyticsPic from './assets/analytics.png';

declare module 'lightweight-charts' {
  export interface LayoutOptions {
    lineColor: string;
  }
  export interface CrosshairOptions {
    color: string;
  }
}

export const Analytics = () => {
  const liquidityRef = useRef<any>(null);
  const volumeRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { liquidityGraphic } = useWalletSelector(state => state.graphicReducer);
  const { volumeGraphic } = useWalletSelector(state => state.graphicReducer);
  const { tradingPairs } = useWalletSelector(state => state.graphicReducer);
  const { transactions } = useWalletSelector(state => state.graphicReducer);
  const { pairsPageCount } = useWalletSelector(state => state.graphicReducer);
  const { analyticsInfo } = useWalletSelector(state => state.graphicReducer);
  const { isWallet } = useWalletSelector(state => state.WalletReducer);

  const history = useHistory();

  useEffect(() => {
    dispatch(graphicActionCreator.getGraphicsData());
    dispatch(graphicActionCreator.getTransactionsData(1, 'All', '', ''));
  }, []);
  const initGraphic = (ref: any) => {
    const chart = createChart(ref.current, {
      width: ref.current.clientWidth - 60,
      height: ref.current.clientHeight - 110,
      rightPriceScale: {
        borderVisible: false,
      },
      timeScale: {
        borderVisible: false,
      },
      layout: {
        backgroundColor: 'transparent',
        lineColor: '#2B2B43',
        textColor: '#D9D9D9',
      },
      watermark: {
        color: '#FDD807',
      },
      crosshair: {
        color: '#758696',
        mode: CrosshairMode.Normal,
      },
      grid: {
        vertLines: {
          color: 'transparent',
        },
        horzLines: {
          color: '#dadada3e',
        },
      },
    });
    let areaSeries = chart.addAreaSeries({
      topColor: '#fdd807ca',
      bottomColor: 'rgba(253, 216, 7, 0.001)',
      lineColor: '#FDD807',
      lineWidth: 1,
    });
    ref.current.children[ref.current.children.length - 1].style.margin =
      '0 auto';
    areaSeries.setData(ref === liquidityRef ? liquidityGraphic : volumeGraphic);
    return areaSeries;
  };

  useEffect(() => {
    if (
      liquidityRef !== null &&
      liquidityRef.current?.children.length < 2 &&
      liquidityGraphic.length !== 0
    ) {
      initGraphic(liquidityRef);
    }
    if (
      volumeRef !== null &&
      volumeRef.current?.children.length < 2 &&
      volumeGraphic.length !== 0
    ) {
      initGraphic(volumeRef);
    }
  }, [liquidityRef, volumeRef, liquidityGraphic, volumeGraphic]);
  // <Redirect to='/exonnft' />
  return (
    <Layout>
      <Redirect to="/exonnft" />
      <div className={`${s} container`}>
        <div className="mb-4 lg:mb-16">
          <div className="relative w-full sm:mb-24">
            <div className="relative z-50 flex flex-col justify-center mb-8 text-center lg:mb-0 gap-3 sm:gap-6 sm:text-left">
              <div>
                <h1 className="text-4xl font-medium sm:text-6xl xl:text-7xl text-title">
                  Exon Analytics
                </h1>
              </div>
              <p className="text-description">All your DeFi data</p>
            </div>
            <div
              style={{
                top: '-50%',
                right: window.innerWidth < 1200 ? '-800px' : '-50%',
              }}
              className="absolute hidden pointer-events-none -z-10 sm:block"
            >
              <img
                src={AnalyticsPic}
                className="w-6/12 pointer-events-none select-none "
                alt="partner Icon"
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="relative w-full lg:w-1/2">
              <input
                className="w-full py-4 pr-32 text-base rounded-t-lg outline-none sm:text-xl text-description bg-input pl-7"
                type="text"
                placeholder="Enter asset name or address"
              />
              <Search
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  transform: 'translate(-75%, 60%)',
                }}
              />
            </div>
          </div>
        </div>
        <Charts
          analyticsInfo={analyticsInfo}
          liquidityRef={liquidityRef}
          volumeRef={volumeRef}
        />
        <TradingPairs
          isWallet={!!isWallet}
          pairsPageCount={pairsPageCount}
          history={history}
          dispatch={dispatch}
          tradingPairs={tradingPairs}
        />
        <Transactions
          transactions={transactions}
          dispatch={dispatch}
          firstTokenAddress=""
          secondTokenAddress=""
        />
      </div>
      <WalletWarning />
    </Layout>
  );
};
