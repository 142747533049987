import React, { FunctionComponent, useContext } from 'react';
import { LevelStateContext } from '../../LevelProgram';
import Level from './assets/level.png';
import { ReactComponent as Level0 } from './assets/level-0.svg';
import { ReactComponent as Info } from './assets/info.svg';
import { ReactComponent as Partners } from './assets/partners.svg';
import { ReactComponent as PersonalLiqudity } from './assets/personal-liquidity.svg';
import { ReactComponent as TeamTurnover } from './assets/team-turnover.svg';
import { ReactComponent as Farming } from './assets/farming.svg';
import s from './LevelProcess.module.css'
import { levels } from '../LevelInfo/levels';
import useWalletSelector from 'hooks/useWalletSelector';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import { accountActionCreator } from 'store/reducers/account/action-creators';
import { useDispatch } from 'react-redux';

type Props = {
  partners?: string;
  personalLiquidity: number;
  teamTurnover: number;
  currentLevel: number;
  currentLevelInfo: number;
};

const LevelProcess: FunctionComponent<Props> = ({
  partners,
  personalLiquidity,
  teamTurnover,
  currentLevel,
  currentLevelInfo
}) => {
  const { levelState } = useContext(LevelStateContext);
  const { isWallet } = useWalletSelector(state => state.WalletReducer)
  const { teamLevelsRevenue, choosenFarmingPoolId, levelsNoRevenue } = useWalletSelector( state => state.AccountReducer )
  const { exonUsdPrice } = useWalletSelector( state => state.applicationReducer )
  const { farmingList } = useWalletSelector( state => state.farmingReducer )
  const dispatch = useDispatch()

  const icon = levels[`Lvl${currentLevel}`];

  const currentLevelData = (level: number) => {
    switch (level) {
      case 0:
        return {
          liquidity: 10,
          turnover: 100,
          icon: '',
          percent: '0%',
					turnoverPercent: 1,
        }
        case 1:
          return {
            liquidity: 101,
            turnover: 1000,
            icon: '',
            percent: '8%',
						turnoverPercent: 0.8,
          }
        case 2:
          return {
            liquidity: 501,
            turnover: 5000,
            icon: '',
            percent: '5%',
						turnoverPercent: 0.6,
          }
        case 3:
          return {
            liquidity: 1001,
            turnover: 10000,
            icon: '',
            percent: '2%',
						turnoverPercent: 0.4,
          }
        case 4:
          return {
            liquidity: 5001,
            turnover: 25000,
            icon: '',
            percent: '2%',
						turnoverPercent: 0.2,
          }
        case 5:
          return {
            liquidity: 10001,
            turnover: 100000,
            icon: '',
            percent: '1%',
						turnoverPercent: 0.1,
          }
      default:
        return {
          liquidity: 0,
          turnover: 0,
          icon: '',
          percent: '0%',
					turnoverPercent: 1,
        }
    }
  }

  const turnoverFormula = () => {
    return teamTurnover / currentLevelData(currentLevelInfo).turnover
  }

  const liquidityFormula = () => {
    return (personalLiquidity * getUsdPerLp()) / currentLevelData(currentLevelInfo).liquidity
  }

  const getUsdPerLp = () => {
    return farmingList.filter(el => el.poolId === choosenFarmingPoolId)[0]?.usdPerLp
  }

  return (
    <div className="relative h-full pb-10 overflow-hidden bg-darkGray rounded-4xl">
      <img src={Level} alt="" className="w-full" />
      <div
        className="absolute inline-flex px-4 py-2 border rounded-lg text-yellow bg-yellow bg-opacity-15 border-yellow"
        style={{ top: 30, right: 30 }}
      >
        {currentLevelData(currentLevelInfo).percent}
      </div>
      <div className="px-4 sm:px-9 text-description">
        {/* <Level0 className="-mt-12" /> */}
        <img src={icon} className="-mt-12 -ml-2 sm:-mt-20" alt="" />
        <div className="flex items-center justify-between mt-8">
          <h4 className="sm:text-3xl">Level {currentLevel}</h4>
          {isWallet
            ? <button
                onClick={() => dispatch(accountActionCreator.updateCurrentLevel())}
                disabled={!levelsNoRevenue}
                className={`bg-transYellow ${ levelsNoRevenue && `${s.ready} cursor-pointer hover:border-yellow` } cursor-default border 
                            border-transparent rounded-xl py-2 px-4 text-yellow`}>
                  Update Level
              </button>
            : <ConnectWalletButton />}
        </div>
        {currentLevel !== 6 && <p className="mt-5 text-xl">To next level</p>}

        {levelState.condition ? (
          <div className="flex mt-5">
            <Info className="w-20 mr-3" />
            <p className="text-description tex-sm sm:text-xl">
              The Level program is a classic linear program of 6 levels, the
              profitability of which is calculated from the profits of your
              partners in the team, 6 levels deep. To make a profit from the
              affiliate program, you must provide a minimum liquidity of $10.
            </p>
          </div>
        ) : null}

        {levelState.stats ? (
          <>
          <div className="w-full">
            <div className="flex items-center mt-10">
              <div className="flex items-center justify-center w-12 h-12 mr-2 shadow-xl bg-lightBlack rounded-xl text-description">
                <Partners />
              </div>
              <div className='w-10/12'>
                <div className="flex justify-between">
                  <h5 className='text-base text-lightBlack'>Partners</h5>
                </div>
                <div className="text-lg text-lightYellow">
                  {partners}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="flex items-center mt-5">
              <div className={`flex justify-center items-center bg-lightBlack w-12 h-12 shadow-xl rounded-xl text-description mr-2`}>
                <Farming/>
              </div>
              <div className='w-10/12'>
                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h5 className='text-base text-lightBlack'>Current level team farming profit</h5>
                  <div className="">${
                        (teamLevelsRevenue[currentLevelInfo] / exonUsdPrice)
                          ? (teamLevelsRevenue[currentLevelInfo] / exonUsdPrice).toFixed(2)
                          : 0
                }</div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="flex items-center mt-5">
              <div className={`flex justify-center items-center ${liquidityFormula() >= 1 ? 'bg-green' : 'bg-lightBlack'}  w-12 h-12 shadow-xl rounded-xl text-description mr-2`}>
                <PersonalLiqudity className={`${liquidityFormula() >= 1 ? s.done : ''}`} />
              </div>
              <div className='w-10/12'>
                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h5 className='text-base text-lightBlack'>Personal liquidity in farming</h5>
                  <div className="">${
		    (personalLiquidity * getUsdPerLp()) ? (personalLiquidity * getUsdPerLp()).toFixed(2) : 0
		  } / ${currentLevelData(currentLevelInfo).liquidity}</div>
                </div>
                <div className="mt-1 text-lg text-lightYellow">
                    <div className="border rounded-lg p-0.5">
                      <div style={{ width: `${(liquidityFormula() >= 1 ? 1 : liquidityFormula()) * 100}%` }} className={`rounded-lg transition-all p-1 ${ liquidityFormula() >= 1 ? 'bg-green' : 'bg-yellow'}`}></div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="flex items-center mt-5">
              <div className={`flex justify-center items-center ${turnoverFormula() >= 1 ? 'bg-green' : 'bg-lightBlack'}  w-12 h-12 shadow-xl rounded-xl text-description mr-2`}>
                <TeamTurnover className={`${turnoverFormula() >= 1 ? s.done : ''}`} />
              </div>
              <div className='w-10/12'>
                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h5 className='text-base text-lightBlack'>Team farming liquidity</h5>
                  <div className="">${( teamTurnover * currentLevelData(currentLevelInfo).turnoverPercent ).toFixed(2)} / ${currentLevelData(currentLevelInfo).turnover}</div>
                </div>
                <div className="mt-1 text-lg text-lightYellow">
                    <div className="border rounded-lg p-0.5">
                      <div style={{ width: `${(turnoverFormula() >= 1 ? 1 : turnoverFormula() ) * 100}%` }} className={`rounded-lg transition-all p-1 ${ turnoverFormula() >= 1 ? 'bg-green' : 'bg-yellow'}`}></div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          
          </>
        ) : null}
      </div>
    </div>
  );
};

export default LevelProcess;
