import Lvl0 from './assets/lvl0.svg';
import Lvl1 from './assets/lvl1.svg';
import Lvl2 from './assets/lvl2.svg';
import Lvl3 from './assets/lvl3.svg';
import Lvl4 from './assets/lvl4.svg';
import Lvl5 from './assets/lvl5.svg';
import Lvl6 from './assets/lvl6.svg';

export interface Levels {
  Lvl0: string;
  [Lvl1: string]: string;
  Lvl2: string;
  Lvl3: string;
  Lvl4: string;
  Lvl5: string;
  Lvl6: string;
}
export const levels: Levels = { Lvl0, Lvl1, Lvl2, Lvl3, Lvl4, Lvl5, Lvl6 };
