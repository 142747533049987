import {LaunchpoolAction, LaunchpoolEnum, LaunchpoolState} from "./types";

export const InitialState: LaunchpoolState = {
  pools: [],
  activePoolId: 0,
  stakedOnly: false,
  joinDetails: {
    poolAddress: '',
    poolId: 0,
    poolType: 0,
    amount: 0
  },
  launchpoolState:'initial'
}


export default function launchpoolReducer(
  state = InitialState,
  action: LaunchpoolAction
) {
  switch (action.type) {
  	case LaunchpoolEnum.SET_LAUNCHPOOLS:
	  return {...state, pools: action.payload}
	case LaunchpoolEnum.SET_JOIN_DETAILS:
	  return {...state, joinDetails: {...state.joinDetails, ...action.payload}}
  case LaunchpoolEnum.SET_ACTIVE_POOL:
    return { ...state, activePool: action.payload.activePool, activePoolId: action.payload.activePoolId}
  case LaunchpoolEnum.SET_LAUNCHPOOL_STATE:
    return { ...state, launchpoolState: action.payload}
  case LaunchpoolEnum.SET_SHOW_STAKED_ONLY:
    return { ...state, stakedOnly: !state.stakedOnly }
  default:
    return state
  }
}
