import React, { FunctionComponent, useContext } from 'react';
import LanguageSwitcher from './components/LanguageSwitcher';
import Logo from './components/Logo';
import Nav from './components/Nav';
import { SidebarContext } from '../Layout/Layout';
import { Arrow } from '../Icons';
import { ReactComponent as Close } from './assets/close.svg';

const Sidebar: FunctionComponent = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);

  return (
    <div
      className={`flex-col items-center fixed overflow-y-auto min-h-full pb-1 top-0 z-10 pt-5  bg-lightBlack ${
        isSidebarOpen
          ? 'flex w-full sm:w-60 px-10'
          : 'hidden sm:flex items-center w-24'
      }`}
    >
      <Close
        className="sm:hidden absolute cursor-pointer top-3.5 right-4"
        onClick={() => setIsSidebarOpen(false)}
      />
      {/* <div
        className="absolute z-20 items-center justify-center hidden w-8 h-8 rounded-full cursor-pointer sm:flex bg-cardBg"
        style={{ right: '-15px' }}
        // onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <div className={`${!isSidebarOpen ? 'transform rotate-180' : null}`}>
          <Arrow />
        </div>
      </div> */}
      <Logo />
      <Nav />
    </div>
  );
};

export default Sidebar;
