import bnb from './assets/bnbCoin.svg';
import exon from './assets/exonCoin.svg';
import usdt from './assets/usdtCoin.svg';
import trx from './assets/trx.svg';
import usdc from './assets/usdc.svg';
import eth from './assets/eth.svg';
import btc from './assets/btc.svg';
import sun from './assets/sun.png';
import btt from './assets/btt.png';
import sunold from './assets/sunold.png';
import nft from './assets/nft.png';
import jst from './assets/jst.png';
import win from './assets/win.jpg';
import dice from './assets/dice.jpg';
import live from './assets/live.png';
import usdj from './assets/usdj.png';
import tusd from './assets/tusd.png';
import ltc from './assets/ltc.png';
import ht from './assets/ht.png';
import anac from './assets/anac.png'
import cc from './assets/cc.jpg'

export interface CoinIcons {
  [bnb: string]: string;
  exon: string;
  usdt: string;
  trx: string;
  usdc: string;
  eth: string;
  btc: string;
  sun: string;
  btt: string;
  sunold: string;
  nft: string;
  jst: string;
  win: string;
  dice: string;
  live: string;
  usdj: string;
  tusd: string;
  ltc: string;
  ht: string;
  anac: string;
  cc: string;
}

export const coinIcons: CoinIcons = {
  bnb,
  exon,
  usdt,
  trx,
  usdc,
  eth,
  btc,
  sun,
  btt,
  sunold,
  nft,
  jst,
  win,
  dice,
  live,
  usdj,
  tusd,
  ltc,
  ht,
  anac,
  cc
};
