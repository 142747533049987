import { AppDispatch, getRootState } from "store";
import initTronstack from "utils/tronWeb/initTronstack";
import { LevelsEnergyAction, LevelsEnergyStateEnum, LevelsEnergyUserInfo } from "./types";
import Config from 'config/index'
import LevelsEnergyContract from 'contracts/LevelsEnergyContract.json'
import { getTransactionResult } from "utils/transaction/getTransactionResult";
import { ApplicationAcionCreator } from "../application/action-creator";
import { sendTransactionHanlder } from "utils/transaction/sendTransactionHandler";
import sleep from "utils/sleep";

export const LevelsEnergyActionCreator = {
  setLevelsPrice: (levelsPrice: number[]): LevelsEnergyAction => ({
    type: LevelsEnergyStateEnum.SET_LEVELS_PRICE,
    payload: levelsPrice
  }),
  setLevelsBuyLimits: (buyLimits: number[]): LevelsEnergyAction => ({
    type: LevelsEnergyStateEnum.SET_LEVELS_BUY_LIMITS,
    payload: buyLimits
  }),
  setLevelsLimitCount: (levelsLimitCount: number[]): LevelsEnergyAction => ({
    type: LevelsEnergyStateEnum.SET_LEVELS_LIMIT_COUNT,
    payload: levelsLimitCount
  }),
  setLevelsUserUnfo: (userInfo: LevelsEnergyUserInfo): LevelsEnergyAction => ({
    type: LevelsEnergyStateEnum.SET_LEVELS_USER_INFO,
    payload: userInfo
  }),
  setLevelsChosenPacket: (chosenPacket: number): LevelsEnergyAction => ({
    type: LevelsEnergyStateEnum.SET_LEVELS_CHOSEN_PACKET,
    payload: chosenPacket
  }),
  setLevelsEnergyState: (state: string): LevelsEnergyAction => ({
    type: LevelsEnergyStateEnum.SET_LEVELS_ENERGY_STATE,
    payload: state
  }),
  setActualClaimAmount: (actualClaimAmount: number): LevelsEnergyAction => ({
    type: LevelsEnergyStateEnum.SET_ACTUAL_CLAIM_AMOUNT,
    payload: actualClaimAmount
  }),
  getLevelsBuyLimits: () => async (dispatch: AppDispatch, store: getRootState) => {

    const tronWeb = await initTronstack()

    if (!tronWeb) return

    const levelsEnergyContract = await tronWeb.contract(
      LevelsEnergyContract.abi,
      Config().LEVELS_ENERGY_CONTRACT
    )

    let buyLimits = []

    const callContract = async (level: number) => {
      let levelBuyLimit = await levelsEnergyContract.buyLimits(level).call()
      return levelBuyLimit
    }

    for (let i = 0; i < 5; i++) {

      let levelBuyLimit
      await sleep(1000)
      try {
        levelBuyLimit = await sendTransactionHanlder(() => callContract(i))
        levelBuyLimit = levelBuyLimit.toNumber()
      } catch (error) {
        console.log(error)
      }

      if (levelBuyLimit) buyLimits.push(levelBuyLimit)

    }

    dispatch(LevelsEnergyActionCreator.setLevelsBuyLimits(buyLimits))

  },
  getLevelsBuyPrice: () => async (dispatch: AppDispatch, store: getRootState) => {

    const tronWeb = await initTronstack()

    if (!tronWeb) return

    const levelsEnergyContract = await tronWeb.contract(
      LevelsEnergyContract.abi,
      Config().LEVELS_ENERGY_CONTRACT
    )

    let levelsPrice = []
    await sleep(1000)

    const callContract = async (level: number) => {
      let levelBuyPrice = await levelsEnergyContract.buyPrice(level).call()
      return levelBuyPrice
    }

    for (let i = 0; i < 5; i++) {

      let levelBuyPrice

      try {
        levelBuyPrice = await sendTransactionHanlder(() => callContract(i))
        levelBuyPrice = levelBuyPrice.toNumber()
      } catch (error) {
        console.log(error)
      }

      if (levelsPrice) levelsPrice.push(levelBuyPrice)

    }

    dispatch(LevelsEnergyActionCreator.setLevelsPrice(levelsPrice))

  },
  getLevelsLimitCount: () => async (dispatch: AppDispatch, store: getRootState) => {

    const tronWeb = await initTronstack()

    if (!tronWeb) return
    await sleep(1000)

    const levelsEnergyContract = await tronWeb.contract(
      LevelsEnergyContract.abi,
      Config().LEVELS_ENERGY_CONTRACT
    )

    let levelsLimitCount = []

    const callContract = async (level: number) => {
      const callContract = await levelsEnergyContract.limitCount(level).call()
      return callContract
    }

    for (let i = 0; i < 5; i++) {

      let levelLimit
      await sleep(1000)

      try {
        levelLimit = await sendTransactionHanlder(() => callContract(i))
        levelLimit = levelLimit.toNumber()
      } catch (error) {
        console.log(error)
      }

      if (levelLimit !== undefined) levelsLimitCount.push(levelLimit)

    }
    if (!!levelsLimitCount.length) dispatch(LevelsEnergyActionCreator.setLevelsLimitCount(levelsLimitCount))

  },
  getLevelsUserInfo: () => async (dispatch: AppDispatch, store: getRootState) => {
    await sleep(2000)
    const currentAccount = store().AccountReducer.currentAccount
    const tronWeb = await initTronstack()
    const levelsPrice = store().LevelsEnergyReducer.levelsPrice

    if (!tronWeb || !currentAccount) return

    const levelsEnergyContract = await tronWeb.contract(
      LevelsEnergyContract.abi,
      Config().LEVELS_ENERGY_CONTRACT
    )

    let userInfo: any


    const callContract = async (currentAccount: number) => {

      let userInfo = await levelsEnergyContract.UserUnfreeze(currentAccount).call()
      return userInfo

    }

    try {
      userInfo = await await levelsEnergyContract.UserUnfreeze(currentAccount).call()
    } catch (error) {
      console.log(error)
    }

    const defineCurrentPacket = (amount: number) => {
      let packet = levelsPrice.indexOf(amount)
      if (packet === -1) {
        packet = userInfo.packet.toNumber()
        return packet - 1
      } else return packet + 1
    }

    if (userInfo) {
      let amount = userInfo.amount.toNumber()
      amount = +tronWeb.fromSun(amount)

      let packet = userInfo.packet.toNumber()

      let referrals = userInfo.totalRefAmount.toNumber()

      let referralsAmount = userInfo.totalRefValue.toNumber()
      referralsAmount = +tronWeb.fromSun(referralsAmount)

      let availableAmount = userInfo.referals.toNumber()
      availableAmount = +tronWeb.fromSun(availableAmount)

      let buy = userInfo.buy

      let parsedUserInfo = {
        amount,
        packet,
        referrals,
        referralsAmount,
        availableAmount,
        buy
      }
      dispatch(LevelsEnergyActionCreator.setLevelsUserUnfo(parsedUserInfo))
    }
  },
  buyPacketForTrx: () => async (dispatch: AppDispatch, store: getRootState) => {
    const currentAccount = store().AccountReducer.currentAccount
    const chosenPacket = store().LevelsEnergyReducer.chosenPacket
    const levelsPrice = store().LevelsEnergyReducer.levelsPrice
    const tronWeb = store().WalletReducer.tronWeb

    if (!tronWeb) return

    dispatch(LevelsEnergyActionCreator.setLevelsEnergyState('buyEnergyPacket'))
    dispatch(ApplicationAcionCreator.setTransactionSteps());

    const levelsEnergyContract = await tronWeb.contract(
      LevelsEnergyContract.abi,
      Config().LEVELS_ENERGY_CONTRACT
    )

    let buyPacketTxn

    const trxToSend = +tronWeb.toSun(levelsPrice[chosenPacket])

    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Signature confirming`,
        true,
        false
      )
    );

    try {
      buyPacketTxn = await levelsEnergyContract.buyForTrx(currentAccount, chosenPacket).send({ callValue: trxToSend, feeLimit: 300000000 })
    } catch (error: any) {
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          "Energy premium",
          "Error",
          buyPacketTxn,
          error
        )
      );
      dispatch(LevelsEnergyActionCreator.setLevelsEnergyState('initial'))
      return
    }

    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Signature confirming`,
        false,
        true
      )
    );

    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Finished`,
        true,
        false
      )
    );

    if (await getTransactionResult(buyPacketTxn, tronWeb)) {
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          "Energy premium",
          "Error",
          buyPacketTxn,
          `An error occured during buying premium packet`
        )
      );
      dispatch(LevelsEnergyActionCreator.setLevelsEnergyState('initial'))
      return
    }

    dispatch(
      ApplicationAcionCreator.setNotificationStatus(
        true,
        true,
        "Energy premium",
        "Success",
        buyPacketTxn,
        `You successfuly bought premium packet`
      )
    );
    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Finished`,
        false,
        true
      )
    );

  },
  withdrawReferrals: () => async (dispatch: AppDispatch, store: getRootState) => {
    const currentAccount = store().AccountReducer.currentAccount
    const tronWeb = store().WalletReducer.tronWeb
    const userInfo = store().LevelsEnergyReducer.userInfo

    if (!tronWeb) return
    dispatch(LevelsEnergyActionCreator.setActualClaimAmount(userInfo.availableAmount))
    dispatch(LevelsEnergyActionCreator.setLevelsEnergyState('levelsEnergyWithdraw'))
    dispatch(ApplicationAcionCreator.setTransactionSteps());

    const levelsEnergyContract = await tronWeb.contract(
      LevelsEnergyContract.abi,
      Config().LEVELS_ENERGY_CONTRACT
    )

    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Signature confirming`,
        true,
        false
      )
    );

    let withdrawReferrals

    try {
      withdrawReferrals = await levelsEnergyContract.withdrawReferals(currentAccount).send()
    } catch (error: any) {
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          "Claim",
          "Error",
          withdrawReferrals,
          error
        )
      );
      dispatch(LevelsEnergyActionCreator.setLevelsEnergyState('initial'))
      return
    }

    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Signature confirming`,
        false,
        true
      )
    );

    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Finished`,
        true,
        false
      )
    );

    if (await getTransactionResult(withdrawReferrals, tronWeb)) {
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          true,
          true,
          "Claim",
          "Error",
          withdrawReferrals,
          `An error occured during claiming referrals revenue`
        )
      );
      dispatch(LevelsEnergyActionCreator.setLevelsEnergyState('initial'))
      return
    }
    dispatch(
      ApplicationAcionCreator.setNotificationStatus(
        true,
        true,
        "Claim",
        "Success",
        withdrawReferrals,
        `Successfuly claimed referrals revenue`
      )
    );
    dispatch(
      ApplicationAcionCreator.setTransactionStepStatus(
        `Finished`,
        false,
        true
      )
    );


  }

}
