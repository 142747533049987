import { SecondsContext } from 'App';
import useWalletSelector from 'hooks/useWalletSelector';
import { FunctionComponent, useContext, useMemo, useState } from 'react';
import { formatNumber } from 'utils/formatNumber';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';

const PoolAmount: FunctionComponent = () => {
  const { activePool } = useWalletSelector(state => state.launchpoolReducer);
  const seconds = useContext(SecondsContext);
  const [timePercents, setTimePercents] = useState(0);

  const calculateTimePercent = () => {
    let now = Date.now() / 1000;
    if (now <= activePool?.start!) return 0;
    else if (now <= activePool?.freeze!) {
      let startEnd = activePool?.freeze! - activePool?.start!;
      let nowEnd = activePool?.freeze! - now;
      let preFreezePercent = (1 - nowEnd / startEnd) * 100;
      return preFreezePercent >= 100 ? 100 : preFreezePercent.toFixed(2);
    } else if (now >= activePool?.freeze! && now < activePool?.end!) {
      let startEnd = activePool?.end! - activePool?.freeze!;
      let nowEnd = activePool?.end! - now;
      let postFreezePercent = (1 - nowEnd / startEnd) * 100;
      return postFreezePercent >= 100 ? 100 : postFreezePercent.toFixed(2);
    } else {
      return 100;
    }
  };

  const calculateCapPercent = () => {
    let percent;
    if (activePool?.randomShareBoth == 3) {
      percent = (activePool?.totalStakeRandom! / activePool?.poolCap!) * 100;
    } else {
      percent = (activePool?.totalStakeShare! / activePool?.poolCap!) * 100;
    }
    percent = percent >= 100 ? 100 : percent;
    return percent.toFixed(2);
  };
  const timeFormatter = (seconds: number) => {
    seconds = Math.floor(seconds);
    let hours;
    let minutes: any = Math.floor(seconds / 60);
    let secs: any = seconds % 60;
    if (secs < 10) {
      secs = '0' + secs;
    }
    if (minutes >= 60) {
      hours = Math.floor(minutes / 60);
      minutes %= 60;
    }
    if (hours && minutes < 10) {
      minutes = '0' + minutes;
    }

    return `${hours ? hours + ':' : ''}${minutes}:${secs}`;
  };

  const checkPoolStatus = () => {
    let currentTimestamp: number = Date.now() / 1000;
    if (
      activePool?.start! < currentTimestamp &&
      currentTimestamp < activePool?.freeze!
    ) {
      return 'Active';
    } else if (
      activePool?.freeze! < currentTimestamp &&
      currentTimestamp < activePool?.end!
    ) {
      return 'Freeze';
    } else if (activePool?.start! > currentTimestamp) {
      return timeFormatter(activePool?.start! - currentTimestamp);
    } else if (activePool?.end! < currentTimestamp) {
      return 'Closed';
    } else return 'Loading';
  };

  const memoizedPercent = useMemo(() => {
    setTimePercents(+calculateTimePercent());
  }, [seconds]);

  return (
    <div className="my-4 p-7">
      <div className="">
        <div className="flex flex-col items-baseline justify-between gap-4 sm:gap-0 sm:flex-row">
          <span className="text-4xl">Pool progress</span>
          <span className="text-lg font-semibold ">
            1 {getTokenInfoByAddress(activePool?.tokenOut!).name} ={' '}
            {activePool?.tokenPrice!} USDT{' '}
          </span>
        </div>

        <div className="my-6 sm:my-0">
          <div className="flex justify-between sm:mt-1 mt-2 text-sm font-semibold sm:text-sm">
            {!!+activePool?.poolCap! ? (
              <div className="flex justify-around sm:hidden">
                <span>
                  {formatNumber(
                    activePool?.totalStakeShare! +
                      activePool?.totalStakeRandom!,
                  )}
                  {activePool?.tokenInSymbol
                    ? activePool?.tokenInSymbol
                    : getTokenInfoByAddress(activePool?.tokenIn!).name}
                </span>
                <span className="mx-1 sm:mx-2">/</span>
                <span>
                  {formatNumber(activePool?.poolCap!)}{' '}
                  {activePool?.tokenInSymbol
                    ? activePool?.tokenInSymbol
                    : getTokenInfoByAddress(activePool?.tokenIn!).name}
                </span>
              </div>
            ) : (
              <div className="flex sm:hidden">
                <span>
                  {' '}
                  {checkPoolStatus() === 'Active'
                    ? timeFormatter(activePool?.freeze! - Date.now() / 1000)
                    : checkPoolStatus() === 'Freeze'
                    ? timeFormatter(activePool?.end! - Date.now() / 1000)
                    : 0}
                </span>
              </div>
            )}
          </div>
          <div className="relative mt-2">
            <div
              style={{ boxShadow: '0 0 1px 2px #BBBBBB' }}
              className="h-2 border rounded-xl bg-darkGray border-lightBlack"
            ></div>
            <div
              style={{
                width:
                  (!!+activePool?.poolCap!
                    ? calculateCapPercent()
                    : timePercents) + '%',
              }}
              className="absolute top-0 h-2 bg-yellow rounded-xl"
            ></div>
          </div>
          <div className="flex justify-between sm:mt-3 mt-2 text-sm font-semibold sm:text-sm">
            <span className="">
              {!!+activePool?.poolCap! ? calculateCapPercent() : timePercents}%
            </span>
            {!!+activePool?.poolCap! ? (
              <div className="hidden flex justify-around sm:block">
                <span>
                  {formatNumber(
                    activePool?.totalStakeShare! +
                      activePool?.totalStakeRandom!,
                  )}
                  {activePool?.tokenInSymbol
                    ? activePool?.tokenInSymbol
                    : getTokenInfoByAddress(activePool?.tokenIn!).name}
                </span>
                <span className="mx-1 sm:mx-2">/</span>
                <span>
                  {formatNumber(activePool?.poolCap!)}{' '}
                  {activePool?.tokenInSymbol
                    ? activePool?.tokenInSymbol
                    : getTokenInfoByAddress(activePool?.tokenIn!).name}
                </span>
              </div>
            ) : (
              <div className="flex hidden sm:block">
                <span>
                  {' '}
                  {checkPoolStatus() === 'Active'
                    ? timeFormatter(activePool?.freeze! - Date.now() / 1000)
                    : checkPoolStatus() === 'Freeze'
                    ? timeFormatter(activePool?.end! - Date.now() / 1000)
                    : 0}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolAmount;
