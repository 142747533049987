import { sendTransactionHanlder } from 'utils/transaction/sendTransactionHandler';
import initTronstack from './initTronstack';

const initContract = async (address: string) => {
  const tronWeb = await initTronstack();

  const creatingContract = async (address: string) => {
    const contract = await tronWeb.contract().at(address);
    return contract
  }

  try {
    const contract = await sendTransactionHanlder(() => creatingContract(address))
    return contract;
  } catch (error) {
    console.log(error);
    // alert('You are not logged in!');
    return {
      contract: null,
    };
  }
};
export default initContract;
