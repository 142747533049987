import Button from 'components/Button';
import useWalletSelector from 'hooks/useWalletSelector';
import { userInfo } from 'os';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  LaunchpoolInfo,
  LaunchPoolPoolInfo,
} from 'store/reducers/launchpool/types';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';
import {
  Facebook,
  Instagram,
  Telegram,
  Twitter,
  Youtube,
  Github,
} from 'components/Icons';
import IconWrapper from 'components/Icons/IconWrapper';
import s from 'components/Footer/Footer.module.css';

import { useDispatch } from 'react-redux';
import { launchpoolActionCreator } from 'store/reducers/launchpool/action-creator';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import MarkdownIt from 'markdown-it';
import { formatNumber } from 'utils/formatNumber';

type Props = {
  activePool: LaunchpoolInfo;
};

const PoolDetails: FunctionComponent<Props> = ({ activePool }) => {
  const [currTab, setCurrTab] = useState<string>('details');
  const { currentAccount } = useWalletSelector(state => state.AccountReducer);
  const { isWallet } = useWalletSelector(state => state.WalletReducer);

  return (
    <>
      <div
        style={{ marginBottom: '-1px' }}
        className="px-5 rounded-t-4xl sm:px-12 pt-7 sm:pt-10 pb-7 md:pb-12 bg-cardBg "
      >
        <div className="flex justify-between mb-2 gap-4 sm:justify-around align-center">
          <div
            onClick={() => setCurrTab('details')}
            className="relative mx-0 text-center cursor-pointer lg:text-left md:mx-8"
          >
            <span
              className={`font-medium ${
                currTab === 'details' ? 'text-yellow' : 'text-title'
              } text-lg md:text-3xl `}
            >
              Launchpool details
            </span>
            {currTab === 'details' && (
              <hr
                className="relative top-2"
                style={{
                  borderRadius: '10px 10px 0 0',
                  background: '#FDD807',
                  border: 'none',
                  width: '100%',
                  height: '2px',
                }}
              />
            )}
          </div>
          <div
            onClick={() => setCurrTab('about')}
            className="relative mx-0 text-center cursor-pointer lg:text-left md:mx-8"
          >
            <span
              className={`font-medium ${
                currTab === 'about' ? 'text-yellow' : 'text-title'
              } text-lg md:text-3xl `}
            >
              About the project
            </span>
            {currTab === 'about' && (
              <hr
                className="relative top-2"
                style={{
                  borderRadius: '10px 10px 0 0',
                  background: '#FDD807',
                  border: 'none',
                  width: '100%',
                  height: '2px',
                }}
              />
            )}
          </div>
        </div>
        <hr
          style={{
            background: '#7A7A7A',
            border: 'none',
            width: '100%',
            height: '0.3px',
          }}
        />
      </div>

      {currTab === 'details' ? (
        <Details
          end={activePool.end}
          poolId={activePool.poolId}
          currentAccount={currentAccount}
          personalReward={activePool.userInfo.reward}
          referralReward={activePool?.userInfo.referralReward}
          stakeAmount={activePool.userInfo.stakedValue}
          isWithdrawed={
            !!+activePool?.userInfo.shareWithdrawed ||
            !!+activePool?.userInfo.randomWithdrawed
          }
          totalStakeShare={activePool.totalStakeShare}
          totalStakeRandom={activePool.totalStakeRandom}
          poolCap={activePool.poolCap}
          tokenOut={activePool?.tokenOut!}
          tokenIn={activePool?.tokenIn!}
          tokenInSymbol={activePool?.tokenInSymbol}
          tokenOutShare={activePool?.tokenOutShare}
          uplinerPercent={activePool?.tokenOutShare}
          tokenOutRandom={activePool?.tokenOutRandom}
          tokenUsersOutShare={activePool?.tokenUsersOutShare}
          tokenUsersOutRandom={activePool?.tokenUsersOutRandom}
          isWallet={isWallet}
          participants={activePool?.participants}
          poolName={activePool?.projectName}
          reward={activePool.userInfo.reward}
          randomShareBoth={activePool.randomShareBoth}
        />
      ) : (
        <About poolInfo={activePool.poolInfo} />
      )}
    </>
  );
};

type DetailsProps = {
  poolId: number;
  tokenOutShare: number;
  uplinerPercent: number;
  currentAccount: number;
  personalReward: number;
  referralReward: number;
  tokenOut: string;
  tokenIn: string;
  tokenInSymbol: string | undefined;
  end: number;
  isWithdrawed: boolean;
  totalStakeShare: number;
  totalStakeRandom: number;
  poolCap: number;
  stakeAmount: number;
  tokenOutRandom: number;
  tokenUsersOutRandom: number;
  tokenUsersOutShare: number;
  isWallet: any;
  participants: number;
  poolName: string;
  reward: number;
  randomShareBoth: number;
};

const Details: FunctionComponent<DetailsProps> = ({
  totalStakeShare,
  tokenOutShare,
  uplinerPercent,
  tokenOutRandom,
  poolCap,
  isWithdrawed,
  end,
  poolId,
  currentAccount,
  personalReward,
  referralReward,
  tokenOut,
  tokenInSymbol,
  tokenIn,
  stakeAmount,
  randomShareBoth,
  tokenUsersOutShare,
  tokenUsersOutRandom,
  isWallet,
  participants,
  poolName,
  totalStakeRandom,
  reward,
}) => {
  const { address } = useWalletSelector(state => state.WalletReducer);
  const { canUserWithdrawLaunchpool } = useWalletSelector(
    state => state.AccountReducer,
  );

  const dispatch = useDispatch();

  const handlClaim = async () => {
    dispatch(launchpoolActionCreator.claimWithdraw());
    console.log('handlClaim');
  };
  const [isCheckByReward, setIsCheckByReward] = useState(false);
  const checkPoolStatus = () => {
    let currentTimestamp: number = Date.now() / 1000;
    if (poolCap === 0) {
      if (end < currentTimestamp) {
        return false;
      } else {
        return true;
      }
    } else {
      if (totalStakeShare + totalStakeRandom >= poolCap) {
        return false;
      } else {
        return true;
      }
    }
  };

  useEffect(() => {
    let poolType = randomShareBoth;
    setIsCheckByReward(poolType === 3 || poolType === 1);
  }, []);

  const definePoolReward = () => {
    let poolType = randomShareBoth;
    if (poolType === 0) {
      return formatNumber(tokenUsersOutRandom);
    } else if (poolType === 3) {
      return `${tokenUsersOutShare} + ${tokenUsersOutRandom}`;
    } else if (poolType === 1) {
      return formatNumber(tokenUsersOutShare);
    } else if (poolType === 2) {
      return formatNumber(tokenUsersOutShare + tokenUsersOutRandom);
    } else return `Share+Random`;
  };

  const checkForWithdraw = () => {
    if (poolId === 2) {
      if (currentAccount === 1114) return true;
      else if (
        checkPoolStatus() ||
        isWithdrawed ||
        !canUserWithdrawLaunchpool ||
        stakeAmount == 0
      )
        return true;
      else return false;
    } else {
      if (checkPoolStatus() || isWithdrawed || stakeAmount == 0) return true;
      else return false;
    }
  };
  console.log(
    checkPoolStatus(),
    isWithdrawed,
    !canUserWithdrawLaunchpool,
    stakeAmount == 0,
  );

  return (
    <div className="rounded-b-4xl bg-cardBg ">
      <div className="w-full px-4 pb-10 md:px-12">
        <div className="text-2xl font-medium ">
          <div className="flex flex-col md:flex-row md:justify-between item-center mb-7">
            <span className="text-description">Launchpool Details</span>
            <span className="text-title">{poolName}</span>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between item-center mb-7">
            <span className="text-description">Your ID</span>
            <span className="text-title">{currentAccount}</span>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between item-center mb-7">
            <span className="text-description">Pool prize</span>
            <span className="text-title">
              {definePoolReward()}{' '}
              {getTokenInfoByAddress(tokenOut).name}
            </span>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between item-center mb-7">
            <span className="text-description">Participants</span>
            <span className="text-title">{participants}</span>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between item-center mb-7">
            <span className="text-description">Join amount</span>
            <span className="text-title">
              {formatNumber(stakeAmount)}{' '}
              {tokenInSymbol
                ? tokenInSymbol
                : getTokenInfoByAddress(tokenIn).name}
            </span>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between item-center mb-7">
            <span className="text-description">Your reward</span>
            <span className="text-title">
              {!checkPoolStatus() ? personalReward : '0'}{' '}
              {getTokenInfoByAddress(tokenOut).name}
            </span>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between item-center mb-7">
            <span className="text-description">Reward referral</span>
            <span className="text-title">
              {!checkPoolStatus() ? referralReward : '0'}{' '}
              {getTokenInfoByAddress(tokenOut).name}
            </span>
          </div>

          <div className="text-center">
            {isWallet ? (
              <Button
                className="w-full sm:w-min whitespace-nowrap"
                onClick={handlClaim}
                disabled={checkForWithdraw()}
              >
                Claim
              </Button>
            ) : (
              <ConnectWalletButton />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type AboutProps = {
  poolInfo: LaunchPoolPoolInfo;
};

const About: FunctionComponent<AboutProps> = ({ poolInfo }) => {
  const md = new MarkdownIt();
  const descriptionHtml = md.render(poolInfo.poolDescription);

  const setSocialMedia = (socialMedia: any) => {
    if (socialMedia.length > 3) {
      return (
        <div className="flex flex-col w-full sm:flex-row justify-left gap-4 sm:mb-2">
          <div className="flex items-center justify-left sm:justify-center gap-4">
            {socialMedia.map((el: { name: string; link: string }) => (
              <SocialIcon name={el.name} link={el.link} />
            ))}
          </div>
        </div>
      );
    } else
      return (
        <div className="flex flex-col w-full sm:flex-row justify-left gap-4 sm:mb-2">
          <div className="flex items-center justify-left sm:justify-center gap-4">
            {socialMedia.map(
              (el: { name: string; link: string }, index: number) =>
                index < 3 ? <SocialIcon name={el.name} link={el.link} /> : null,
            )}
          </div>
          <div className="flex items-center justify-left sm:justify-center gap-4">
            {socialMedia.map(
              (el: { name: string; link: string }, index: number) =>
                index >= 3 ? (
                  <SocialIcon name={el.name} link={el.link} />
                ) : null,
            )}
          </div>
        </div>
      );
  };

  return (
    <div className="px-4 pb-8 rounded-b-4xl bg-cardBg sm:px-12">
      <h1 className="mb-5 text-2xl font-medium text-title sm:text-4xl">
        {poolInfo.poolName}
      </h1>
      <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
      <div className="flex flex-col justify-between gap-2 mt-7 sm:mt-14 sm:justify-start mb-7 sm:mb-10">
        {!!poolInfo.websiteLink && (
          <div className="">
            <div className="mb-7">
              <div className="mb-2 text-base font-normal sm:text-xl text-title">
                Website
              </div>
              <a
                target="_blank"
                href={poolInfo.websiteLink}
                className="text-2xl font-semibold leading-4 text-yellow"
              >
                {poolInfo.websiteLink}
              </a>
            </div>
          </div>
        )}

        {!!poolInfo.socialMedia.length && (
          <div>
            <div className="mb-2 text-base font-normal sm:text-xl text-title">
              Social media
            </div>
            {setSocialMedia(poolInfo.socialMedia)}
          </div>
        )}
      </div>
    </div>
  );
};

type SocialProps = {
  name: string;
  link: string;
};

const SocialIcon: FunctionComponent<SocialProps> = ({ name, link }) => {
  const defineIcon = () => {
    switch (name) {
      case 'github':
        return <Github className="w-5" />;
      case 'telegram':
        return <Telegram className="w-5" />;
      case 'instagram':
        return <Instagram className="w-5" />;
      case 'facebook':
        return <Facebook className="w-5" />;
      case 'youtube':
        return <Youtube className="w-5" />;
      case 'twitter':
        return <Twitter className="w-5" />;
      default:
        return;
    }
  };

  return (
    <IconWrapper
      target="_blank"
      url={link}
      className={`${s.footer__icon} w-10 h-10`}
    >
      {defineIcon()}
    </IconWrapper>
  );
};

export default PoolDetails;
