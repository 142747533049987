import { coinIcons } from 'components/CoinIcons';
import { PoolStatus } from 'pages/Launchpad/components/FeaturedPools/components/FeatPoolItem/PoolStatus';
import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import '../../../FeaturedPools/FeaturedPools.module.css';
import { ReactComponent as Usdt } from './assets/ustd.svg';
import s from '../../../FeaturedPools/FeaturedPools.module.css';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';
import { LaunchpoolInfo } from 'store/reducers/launchpool/types';
import { formatNumber } from 'utils/formatNumber';
import { SecondsContext } from 'App';

const PoolItem: FunctionComponent<LaunchpoolInfo> = props => {
  const history = useHistory();
  const seconds = useContext(SecondsContext);
  const [timePercents, setTimePercents] = useState(0);
  const timeFormatter = (seconds: number) => {
    seconds = Math.floor(seconds);
    let hours;
    let minutes: any = Math.floor(seconds / 60);
    let secs: any = seconds % 60;
    if (secs < 10) {
      secs = '0' + secs;
    }
    if (minutes >= 60) {
      hours = Math.floor(minutes / 60);
      minutes %= 60;
    }
    if (hours && minutes < 10) {
      minutes = '0' + minutes;
    }

    return `${hours ? hours + ':' : ''}${minutes}:${secs}`;
  };

  const calculateTimePercent = () => {
    let now = Date.now() / 1000;
    if (now <= props.start) return 0;
    else if (now <= props.freeze) {
      let startEnd = props.freeze - props.start;
      let nowEnd = props.freeze - now;
      let preFreezePercent = (1 - nowEnd / startEnd) * 100;
      return preFreezePercent >= 100 ? 100 : preFreezePercent.toFixed(2);
    } else if (now >= props.freeze && now < props.end) {
      let startEnd = props.end - props.freeze;
      let nowEnd = props.end - now;
      let postFreezePercent = (1 - nowEnd / startEnd) * 100;
      return postFreezePercent >= 100 ? 100 : postFreezePercent.toFixed(2);
    } else {
      return 100;
    }
  };

  const calculateCapPercent = () => {
    let percent;
    if (props.randomShareBoth == 3) {
      percent = (props.totalStakeRandom / props.poolCap) * 100;
    } else {
      percent = (props.totalStakeShare / props.poolCap) * 100;
    }
    percent = percent >= 100 ? 100 : percent;
    return percent.toFixed(2);
  };

  const checkPoolStatus = () => {
    let poolType;
    if (props.totalStakeShare == 0) {
      poolType = 'random';
    } else {
      poolType = 'share';
    }
    let currentTimestamp: number = Date.now() / 1000;

    if (!!props.poolCap) {
      if (props.start > currentTimestamp) {
        return timeFormatter(props.start - currentTimestamp);
      } else if (props.poolCap <= props.totalStakeRandom) {
        return 'Closed';
      } else if (props.poolCap <= props.totalStakeShare) {
        return 'Closed';
      } else if (props.start < currentTimestamp) {
        if (poolType == 'random' && props.poolCap > props.totalStakeRandom) {
          return 'Active';
        } else if (
          poolType == 'share' &&
          props.poolCap > props.totalStakeShare
        ) {
          return 'Active';
        } else {
          return '';
        }
      } else return 'Loading';
    } else {
      if (props.start > currentTimestamp) {
        return timeFormatter(props.start - currentTimestamp);
      } else if (
        props.start < currentTimestamp &&
        currentTimestamp < props.freeze
      ) {
        return 'Active';
      } else if (
        props.freeze < currentTimestamp &&
        currentTimestamp < props.end
      ) {
        return 'Freeze';
      } else if (props.end < currentTimestamp) {
        return 'Closed';
      } else return 'Loading';
    }
  };

  const definePoolType = () => {
    let poolType = props.randomShareBoth;
    if (poolType === 0) {
      return `Random (${props.winners} winners)`;
    } else if (poolType === 3) {
      return `Share+Random (${props.winners} winners)`;
    } else if (poolType === 1) {
      return 'Share';
    } else if (poolType === 2) {
      return 'Both';
    } else return `Share+Random`;
  };

    const definePoolReward = () => {
        let poolType = props.randomShareBoth
        if(poolType === 0) {
            return formatNumber(props.tokenUsersOutRandom)
        } else if(poolType === 3) {
            return `${formatNumber(props.tokenUsersOutShare)} + ${formatNumber(props.tokenUsersOutRandom)}`
        } else if(poolType === 1){
            return formatNumber(props.tokenUsersOutShare)
        }else if(poolType === 2){
            return formatNumber(props.tokenUsersOutShare+props.tokenUsersOutRandom)
        }else return `Share+Random`
    }

  const memoizedPercent = useMemo(() => {
    setTimePercents(+calculateTimePercent());
  }, [seconds]);

  return (
    <div
      onClick={() => history.push(`/launchpool/${props.poolId}`)}
      className={`relative w-full px-6 py-7 cursor-pointer sm:px-8 sm:py-8 ${s.slider__card_hover} bg-cardBg rounded-4xl`}
    >
      <div
        className={`flex ${
          getTokenInfoByAddress(props.tokenOut).icon
            ? 'justify-between'
            : 'justify-end'
        }`}
      >
        <img className="w-16 h-16 rounded-full" src={props.projectImage} />
        <PoolStatus type="" status={checkPoolStatus()} />
      </div>
      <h1 className="mt-4 mb-2 text-2xl font-medium text-lightGrey md:text-3xl ">
        {props.projectName || 'hello pool'}
      </h1>
      <span className="font-semibold text-description">
        1 {getTokenInfoByAddress(props.tokenOut).name} = {props.tokenPrice} USDT{' '}
      </span>
      <div className="mt-6">
        <small className="text-base font-normal md:text-lg text-description">
          Distribution type
        </small>
        <div className="flex mb-5 align-center md:mb-6">
          <span className="text-2xl font-medium text-yellow md:text-2xl">
            {definePoolType()}
          </span>
        </div>
        <small className="text-base font-normal md:text-lg text-description">
          Total reward
        </small>
        <div className="flex mb-5 align-center md:mb-6">
          <span className="text-2xl font-medium text-yellow md:text-2xl">
            {definePoolReward()}{' '}
            {getTokenInfoByAddress(props.tokenOut).name}
          </span>
        </div>
        <div className="my-6">
          <div className="flex justify-between">
            <span className="text-base sm:text-lg">Progress</span>
            <span className="text-base sm:text-lg">
              {props.poolCap === 0 ? 'TIME' : 'CAP'}
            </span>
          </div>
          <div className="relative mt-2">
            <div
              style={{ boxShadow: '0 0 1px 2px #BBBBBB' }}
              className="h-2 border rounded-xl bg-darkGray border-lightBlack"
            ></div>
            <div
              style={{
                width:
                  (!!+props.poolCap ? calculateCapPercent() : timePercents) +
                  '%',
              }}
              className="absolute top-0 h-2 bg-yellow rounded-xl"
            ></div>
          </div>
          <div className="flex justify-between mt-3 text-sm font-semibold sm:text-xs">
            <span className="">
              {!!+props.poolCap ? calculateCapPercent() : timePercents}%
            </span>
            {!!+props.poolCap ? (
              <div className="flex justify-around">
                <span>
                  {formatNumber(props.totalStakeShare + props.totalStakeRandom)}{' '}
                  {props.tokenInSymbol
                    ? props.tokenInSymbol
                    : getTokenInfoByAddress(props.tokenIn).name}
                </span>
                <span className="mx-1 sm:mx-2">/</span>
                <span>
                  {formatNumber(props.poolCap)}{' '}
                  {props.tokenInSymbol ? props.tokenInSymbol : getTokenInfoByAddress(props.tokenIn).name}
                </span>
              </div>
            ) : (
              <div className="flex">
                <span>
                  {' '}
                  {checkPoolStatus() === 'Active'
                    ? timeFormatter(props.freeze - Date.now() / 1000)
                    : checkPoolStatus() === 'Freeze'
                    ? timeFormatter(props.end - Date.now() / 1000)
                    : 0}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolItem;
