import { UTCTimestamp } from "lightweight-charts";
import { GraphicAction, GraphicEnum, GraphicState } from "./types";

export const initialState: GraphicState = {
  liquidityGraphic: [],
  volumeGraphic: [],
  candleGraphic:  [
  { time: 1556877600 as UTCTimestamp, open: 54.62, high: 55.50, low: 54.52, close: 54.90 },
	{ time: 1556881200 as UTCTimestamp , open: 55.08, high: 55.27, low: 54.61, close: 54.98 },
	{ time: 1556884800 as UTCTimestamp, open: 56.09, high: 57.47, low: 56.09, close: 57.21 },],
  transactions: {
    transactions: [],
    pageCount: 0,
    currentPage: 0
  },
  tradingPairs: [],
  analyticsInfo: {
    trxPrice: null,
    transactions: null,
    pairs: null,
    newPairs: null,
  },
  pairInfo: {
    pooledTokensAmount: {
      firstToken: 0,
      secondToken: 0
    },
    price: {
      firstToken: 0,
      secondToken: 0,
      trx: 0
    },
    totalLiquidity: {
      amount: 0,
      percents: 0
    },
    transactions: {
      amount: 0,
      percents: 0
    },
    volume: {
      amount: 0,
      percents: 0
    },
  },
  pairsPageCount: null,
}

export default function graphicReducer(
  state = initialState,
  action: GraphicAction
) {
  switch (action.type) {    
    case GraphicEnum.GRAPHIC_SET_LIQUIDITY:
      return {...state, liquidityGraphic: action.payload}
    case GraphicEnum.GRAPHIC_SET_VOLUME:
      return {...state, volumeGraphic: action.payload}
    case GraphicEnum.GRAPHIC_SET_CANDLE:
      return {...state, candleGraphic: action.payload}
    case GraphicEnum.GRAPHIC_SET_ANALYTICS_INFO:
      return {...state, analyticsInfo: action.payload}
    case GraphicEnum.GRAPHIC_SET_TRANSACTIONS:
      return {...state, transactions: action.payload}
    case GraphicEnum.GRAPHIC_SET_PAIRS:
      return {...state, tradingPairs: action.payload}
    case GraphicEnum.GRAPHIC_SET_PAIRS_PAGES_COUNT:
      return {...state, pairsPageCount: action.payload}
    case GraphicEnum.GRAPHIC_SET_PAIR_PAGE_INFO: 
      return { ...state, pairInfo: action.payload}
    case GraphicEnum.GRAPHIC_RESET_ALL_GRAPHICS: 
      return { 
        ...state, 
        candleGraphic: [],
        liquidityGraphic: [],
        volumeGraphic: [],
      }
    default:
      return state
  }
}