import { CloseIcon } from 'components/Icons';
import useWalletSelector from 'hooks/useWalletSelector';
import React, { FunctionComponent } from 'react';

type Props = {
  closeModal?: any;
	customModal?: boolean;
};

const Modal: FunctionComponent<Props> = ({ children, closeModal, customModal }) => {
  const { swapState } = useWalletSelector( state => state.swapReducer )
  const { liquidityState } = useWalletSelector( state => state.liquidityReducer )
  const { transactionSteps } = useWalletSelector( state => state.applicationReducer )
  const { farmingState } = useWalletSelector( state => state.farmingReducer )
  const { multisendState } = useWalletSelector( state => state.multisendReducer )
  const { launchpoolState } = useWalletSelector( state => state.launchpoolReducer )
  const { launchpadState } = useWalletSelector(state => state.launchpadReducer)
  const isMobile = window.innerWidth < 640
  
  return (
    <div
      style={{zIndex: 1000}}
      className="fixed  z-50 inset-0"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end z-10 justify-center min-h-screen
      pt-4 sm:px-4 pb-20 text-center sm:block">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={(!(swapState === 'steps' || launchpadState === 'joinToIDO' || liquidityState === 'steps' || multisendState === 'multisendSteps' || liquidityState === 'rmSteps' || farmingState === 'withdrawSteps' || launchpoolState === 'joinToPool' ||  farmingState === 'depositSteps' || farmingState === 'unstakeSteps') || (transactionSteps && transactionSteps[transactionSteps.length-1].done)) && closeModal}
        ></div>
        <div style={farmingState === 'calculator' ? {width: '28rem'} : {}} className={` ${isMobile && (customModal || liquidityState === 'remove' || swapState === 'swapConfirm') ? 'h-2/3' : 'max-h-modal'} w-11/12 inline-block align-top bg-cardBg 
             rounded-3xl text-left shadow-xl transform 
             transition-all sm:my-7 sm:align-middle sm:max-w-xl p-6 sm:px-12 sm:py-6 relative
             ${(customModal || swapState === 'steps' || launchpadState === 'joinToIDO' || multisendState === 'multisendSteps' || liquidityState === 'steps' || liquidityState === 'rmSteps' || farmingState === 'withdrawSteps' || farmingState === 'depositSteps' || launchpoolState === 'joinToPool' || farmingState === 'unstakeSteps') ? 'sm:w-auto' : 'sm:w-full'}  z-1000 sm:max-h-screen overflow-y-auto`}>
          {!(swapState === 'steps' || liquidityState === 'steps' || launchpoolState === 'joinToPool' || multisendState === 'multisendSteps' || liquidityState === 'rmSteps' || farmingState === 'withdrawSteps' || farmingState === 'depositSteps' || farmingState === 'unstakeSteps') && <div className="cursor-pointer z-50" onClick={closeModal}>
            <CloseIcon style={{width: '40px', height: '40px'}} className="absolute right-4 top-4 z-50" />
          </div>}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
