import {TokenInfo} from "../types";

export type LaunchpadState = {
  pools: LaunchpadInfo[];
  activePoolId: number;
  stakedOnly: boolean; 
  activeIdo: any;
  buyDetails: LaunchpadJoinDetails;
  whitelistDetails: WhitelistDetails;
  launchpadState: LaunchpadModalState;
}

export type LaunchpadInfo = {
	poolId: number;
	active: boolean;
	name: string;
	poolAddress: string;
	saleTokenInfo: TokenInfo;
  whiteListAmounts: number[];
  tokenPrices: number[];
  buyTokens: string[];
  whiteListTokens: string[];
  addressVariables: string[];
  boolVariables: BoolVariables;
	maxAllocation: number;
	minAllocation: number;
	total: number;
	refPercent: number;
	start: number;
	end: number;
	totalIDOAmount: number;
	userDataIsFetched?: boolean;
	userInfo: LaunchpadUserInfo;
	poolDescriptionInfo: LaunchPoolPoolInfo | undefined;
}

export type UserInfoByTokens = {
	token: string,
	amount: number
}

export type LaunchpadUserInfo = {
	whitelistedToken: UserInfoByTokens,
  whitelistedTokenHistory?: UserInfoByTokens,
	boughtTokens: UserInfoByTokens[],
	saleTokenAmount: number,
	referalAmount: number
}

export type BoolVariables = {
	withWithdraw: boolean;
	sameUser: boolean;
	byTime: boolean;
}

export type WhitelistDetails = {
  selectedToken: string;
  tokenAmount: number;
}

export type LaunchPoolPoolInfo = {
  poolTitle: string;
  poolName: string;
  poolDescription: string;
  websiteLink: string;
  image: string;
  socialMedia: LaunchpadSocialMedia[];
}

export type LaunchpadJoinDetails = {
  selectedToken: string;
  tokenPrice: number;
	tokenInBalance: number;
	tokenOutBalance: number;
  amountIn: number;
  amountOut: number;
  decimal: number;
}

export type LaunchpadSocialMedia = {
  name: string;
  link: string;
  PoolName: string;
  sequences: number;
}

export type LaunchpadModalState = 'confirmBuy'|'joinToIDO'|'claimWhitelistIDOTokens'|'initial'|'buyTokens'


export enum LaunchpadEnum  {
  SET_LAUNCHPAD_POOLS = 'SET_LAUNCHPAD_POOLS',
  SET_LAUNCHPAD_STATE = 'SET_LAUNCHPAD_STATE',
  SET_ACTIVE_IDO_POOL = 'SET_ACTIVE_IDO_POOL',
  SET_SELECTED_IDO_TOKEN_WHITELIST = 'SET_SELECTED_IDO_TOKEN_WHITELIST',
  SET_IDO_TOKEN_BUY_DETAILS = 'SET_IDO_TOKEN_BUY_DETAILS',
	SET_USER_DATA_IS_FETCHED = 'SET_USER_DATA_IS_FETCHED'
}

export type setUserDataIsFetched = {
	type: LaunchpadEnum.SET_USER_DATA_IS_FETCHED,
	payload: {userDataIsFetched: boolean, poolId: number}
}

export type setTokenBuyDetails = {
  type: LaunchpadEnum.SET_IDO_TOKEN_BUY_DETAILS,
  payload: {
    poolAddress?: string;
    selectedToken?: string;
    tokenPrice?: number;
    amountIn?: number;
    amountOut?: number;
    decimal?: number;
  }
}

export type setSelectedTokenWhitelist = {
  type: LaunchpadEnum.SET_SELECTED_IDO_TOKEN_WHITELIST,
  payload: string
}

export type setLaunchpadPools = {
    type: LaunchpadEnum.SET_LAUNCHPAD_POOLS;
    payload: LaunchpadInfo[];
}

export type setLaunchpadState = {
	type: LaunchpadEnum.SET_LAUNCHPAD_STATE;
	payload: LaunchpadModalState;
}

export type setActiveIdoPool = {
	type: LaunchpadEnum.SET_ACTIVE_IDO_POOL;
	payload: {activeIdo: LaunchpadInfo, activePoolId: number}
}

export type LaunchpadAction = 
    | setLaunchpadPools
    | setLaunchpadState
    | setActiveIdoPool
    | setSelectedTokenWhitelist
    | setTokenBuyDetails 
		| setUserDataIsFetched
