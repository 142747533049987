import {
  ApplicationCurrentState,
  ApplicationAction,
  ApplicationStateEnum,
} from './types';

export const initialState: ApplicationCurrentState = {
  firstToken: {},
  secondToken: {},
  openSelect: false,
  selected: 0,
  transactionState: 'notActive',
  trxUsdPrice: 0,
  exonUsdPrice: 0,
  notification: {
    isShown: false,
    endProcess: false,
    status: 'Pending',
    description: null,
    currentTransaction: null,
  },
  liquidityAmount: 0,
  volumeAmount: 0,
  isTransaction: false,
  transactionSteps: null,
  isTronwebPresent: true,
  isTronlinkLogged: true,
  searchTokenList: []
};

export default function applicationReducer(
  state = initialState,
  action: ApplicationAction,
): ApplicationCurrentState {
  switch (action.type) {
    case ApplicationStateEnum.SET_FIRST_TOKEN_APP:
      if (state.secondToken.name === action.payload.name) {
        return { ...state, firstToken: action.payload, secondToken: {} };
      } else {
        return { ...state, firstToken: action.payload };
      }
    case ApplicationStateEnum.SET_SECOND_TOKEN_APP:
      if (state.firstToken.name === action.payload.name) {
        return { ...state, secondToken: action.payload, firstToken: {} };
      } else {
        return { ...state, secondToken: action.payload };
      }
    case ApplicationStateEnum.SET_OPEN_SELECT_TOKEN:
      if (!action.payload) {
        return { ...state, openSelect: action.payload, selected: 0 };
      } else {
        return { ...state, openSelect: action.payload };
      }
    case ApplicationStateEnum.SET_SELECTED_NUMBER:
      return { ...state, selected: action.payload };
    case ApplicationStateEnum.SET_TRX_USD_PRICE:
      return { ...state, trxUsdPrice: action.payload };
    case ApplicationStateEnum.SET_EXON_USD_PRICE:
      return { ...state, exonUsdPrice: action.payload };
    case ApplicationStateEnum.SET_NOTIFICATION_STATUS:
      return { ...state, notification: { ...action.payload } };
    case ApplicationStateEnum.SET_FOOTER_LIQUIDITY:
      return { ...state, liquidityAmount: action.payload };
    case ApplicationStateEnum.SET_FOOTER_VOLUME:
      return { ...state, volumeAmount: action.payload };
    case ApplicationStateEnum.SET_TRANSACTION_STATUS:
      return { ...state, isTransaction: action.payload  };
    case ApplicationStateEnum.SET_SWAPPED_TOKENS:
      return { ...state, 
        firstToken: action.payload.secondTokenInfo, 
        secondToken: action.payload.firstTokenInfo, 
      };
    case ApplicationStateEnum.SET_TRANSACTION_STEPS:
      let steps: any = []
      action.payload?.map(el => el !== undefined && steps.push({process: el, fetching: false, done: false}))
      if(steps[0]?.process.match('Signature pair')) steps.push({process: 'Finished', fetching: false, done: false})
      else steps.push({process: 'Signature confirming', fetching: false, done: false}, {process: 'Finished', fetching: false, done: false})
      return {
        ...state,
        transactionSteps: steps
      }
    case ApplicationStateEnum.SET_TRANSACTION_STEP_STATUS:
      let newSteps: { fetching: boolean; done: boolean; process: string; }[] = []
      state.transactionSteps?.map(el => {
        return el.process === action.payload.process 
        ? newSteps.push({...el, fetching: action.payload.fetching, done: action.payload.done})
        : newSteps.push(el)
      })
      return{
        ...state,
        transactionSteps: newSteps  
      }
    case ApplicationStateEnum.SET_IS_TRONWEB_PRESENT:
      return {
        ...state,
        isTronwebPresent: action.payload
      }
    case ApplicationStateEnum.SET_IS_TRONLINK_LOGGED:
      return {
        ...state,
        isTronlinkLogged: action.payload
      }
    case ApplicationStateEnum.SET_SEARCH_TOKEN_LIST:
      return {
        ...state,
        searchTokenList: action.payload
      }
    default:
      return state;
  }
}
