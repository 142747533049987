import React, { FunctionComponent } from 'react';
import { SearchIcon } from 'components/Icons';


type Props = {
  setTextFilter: any,
  textFilter: string
}


const Search: FunctionComponent<Props> = ({setTextFilter, textFilter}) => {

  const textRestriction = (e: any) => {
    let newText = e.target.value.toUpperCase()
    newText = newText.match(/[A-Z-]/g)?.join('')
    console.log(newText);
    // console.log(newText.match(/[a-z][A-Z]/g));
    if(newText) setTextFilter(newText.toUpperCase())
    else setTextFilter('')
  }


  return (
    <div className='w-full lg:w-min'>
      <label
        className="flex justify-between w-full px-4 py-2 rounded-lg bg-cardBg sm:w-52 xl:w-96"
      >
        <input
          value={textFilter}
          onChange={e => textRestriction(e)}
          type="text"
          className="w-full bg-transparent outline-none text-description placeholder-current"
          placeholder="Search"
        />
        <span className="text-transparent">
          <SearchIcon />
        </span>
      </label>
    </div>
  );
};

export default Search;
