import React, { FunctionComponent, useEffect, useState } from 'react';
import Block from 'components/Block';
import { Exon } from 'components/Icons';
import useWalletSelector from 'hooks/useWalletSelector';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';
import { useDispatch } from 'react-redux';

type Props = {
  setTeamWithdrawChoose: any
}

const Withdraw: FunctionComponent<Props> = ({setTeamWithdrawChoose}) => {
  const { isWallet } = useWalletSelector(state => state.WalletReducer)
  const { farmingInfo, totalTeamRevenue, withdrawedRevenue } = useWalletSelector( state => state.AccountReducer )
  const isMobile = window.innerWidth < 640
  const dispatch = useDispatch()
  const [revenue , setRevenue] = useState(0)

  const getTotalWithdrawedAmount = () => {
    let totalWithdrawed = 0
    for (let i = 0; i < withdrawedRevenue.length; i++) {
      totalWithdrawed += +withdrawedRevenue[i].amount
    }
    return totalWithdrawed
  }

  useEffect(() => {
    if((totalTeamRevenue - getTotalWithdrawedAmount()) <= 0){
      setRevenue(0)
    }else setRevenue(+(totalTeamRevenue - getTotalWithdrawedAmount()).toFixed(6))
  }, [totalTeamRevenue, withdrawedRevenue])

  return (
    <Block
      className="order-1 p-6 mt-5 bg-lightBlackO sm:p-2 xl:p-3 rounded-3xl sm:order-2"
      small
    >
      <div className="flex flex-col items-center lg:flex-row">
        <div className="flex justify-between w-full sm:w-2/3">
          <div className="flex justify-between w-full mb-4 sm:ml-4 lg:flex-row sm:flex-col sm:text-center lg:text-left sm:w-3/4 sm:mb-0">
            <div className="flex flex-col">
              <div className="text-sm text-description">Available profit</div>
              <div className="flex items-center text-xl sm:justify-center lg:justify-start text-lightYellow">
                {revenue}
                <Exon className="h-4 ml-1" />
              </div>
            </div>
          </div>
        </div>
        {isWallet
          ? <button onClick={() => {
                setTeamWithdrawChoose(true)
              }} className="w-full h-10 py-2 mt-3 text-sm text-black rounded-lg bg-yellow sm:w-1/3 lg:px-2 lg:py-1 lg:mt-0">
              Claim
            </button>
          : <ConnectWalletButton personal={isMobile} />  }
      </div>
    </Block>
  );
};

export default Withdraw;
