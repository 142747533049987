import React, { FC, useEffect } from 'react';
import Button from 'components/Button';
import { Input } from 'components/Input/InputClear';
import { useState } from 'react';
import { LaunchpoolInfo } from 'store/reducers/launchpool/types';
import { useDispatch } from 'react-redux';
import { launchpoolActionCreator } from 'store/reducers/launchpool/action-creator';
import { getTokenInfoByAddress, getTokenInfoByName } from 'utils/getTokenInfo';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import useWalletSelector from 'hooks/useWalletSelector';

type PoolWhiteListProps = {
  activePool: LaunchpoolInfo;
};

const PoolWhitelist: FC<PoolWhiteListProps> = ({ activePool }) => {
  const { isWallet } = useWalletSelector(state => state.WalletReducer);
  const { currentAccount } = useWalletSelector(state => state.AccountReducer);

  const dispatch = useDispatch();
  const checkFreeze = () => {
    let currentTimestamp: number = Date.now() / 1000;
    if (!activePool.poolAccess) return true;
    if (
      activePool.poolCap === 0 &&
      activePool?.start! < currentTimestamp &&
      currentTimestamp < activePool?.freeze!
    ) {
      return false;
    } else if (activePool?.start > currentTimestamp) {
      return true;
    } else if (
      +activePool.poolCap >
      +activePool.totalStakeShare + +activePool.totalStakeRandom
    ) {
      return false;
    } else {
      return true;
    }
  };

  const [isIncorrectAmount, setIsIncorrectAmount] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [poolType, setPoolType] = useState(() => {
    if (activePool.randomShareBoth === 0 || activePool.randomShareBoth === 3) {
      return 0;
    } else {
      return 1;
    }
  });
  const joinToPool = () => {
    let amount;
    if (inputValue === '') {
      amount = activePool.maxIn;
    } else {
      amount = parseInt(inputValue);
    }
    dispatch(launchpoolActionCreator.setJoinDetails({ amount: +amount }));
    if (activePool.tokenIn === getTokenInfoByName('trx').address) {
      dispatch(launchpoolActionCreator.joinToPoolTrx(amount, poolType));
    } else {
      dispatch(launchpoolActionCreator.joinToPool(amount, poolType));
    }
    console.log('handleJoinToPool');
  };

  // const handleInput = (tokensAmount: React.ChangeEvent<HTMLInputElement>)=> {
  const handleInput = (tokensAmount: string) => {
    console.log('input tokens:', tokensAmount);
    setInputValue(tokensAmount);
  };

  const changeType = (newType: number) => {
    if (activePool.randomShareBoth == 2) {
      setPoolType(newType);
    }
  };

  const randomshare = `px-5 py-3 text-xl font-medium rounded-xl
                     ${
                       activePool.randomShareBoth == 2
                         ? 'bg-yellow text-mainBlack cursor-pointer'
                         : 'bg-lightBlackO'
                     }`;

  // <div className={randomshare} onClick={changeType}>
  //       {poolType == 0 ? 'Random' : 'Share'}
  //     </div>
  return (
    <div className="w-full px-5 pb-8 mx-auto text-center sm:px-10 sm:pb-8 mxx:w-2/3 mmx:w-1/2 mb-14 bg-cardBg rounded-4xl">
      {activePool.randomShareBoth === 2 && (
        <div
          style={{ marginBottom: '-1px' }}
          className="px-5 rounded-t-4xl sm:px-12 pt-7 sm:pt-10 pb-7 md:pb-12 bg-cardBg "
        >
          <div className="flex justify-between mb-2 gap-4 sm:justify-around align-center">
            <div
              onClick={() => changeType(0)}
              className="relative mx-0 text-center cursor-pointer lg:text-left md:mx-8"
            >
              <span
                className={`font-medium ${
                  poolType === 0 ? 'text-yellow' : 'text-title'
                } text-lg md:text-3xl `}
              >
                Random
              </span>
              {poolType === 0 && (
                <hr
                  className="relative top-2"
                  style={{
                    borderRadius: '10px 10px 0 0',
                    background: '#FDD807',
                    border: 'none',
                    width: '100%',
                    height: '2px',
                  }}
                />
              )}
            </div>
            <div
              onClick={() => changeType(1)}
              className="relative mx-0 text-center cursor-pointer lg:text-left md:mx-8"
            >
              <span
                className={`font-medium ${
                  poolType === 1 ? 'text-yellow' : 'text-title'
                } text-lg md:text-3xl `}
              >
                Share
              </span>
              {poolType === 1 && (
                <hr
                  className="relative top-2"
                  style={{
                    borderRadius: '10px 10px 0 0',
                    background: '#FDD807',
                    border: 'none',
                    width: '100%',
                    height: '2px',
                  }}
                />
              )}
            </div>
          </div>
          <hr
            style={{
              background: '#7A7A7A',
              border: 'none',
              width: '100%',
              height: '0.3px',
            }}
          />
        </div>
      )}
      <div
        className={`flex items-center ${
          activePool.randomShareBoth !== 2 && 'pt-8'
        } justify-between mb-5`}
      >
        <h3 className="text-2xl font-medium sm:text-4xl">Whitelist</h3>
        {activePool.userInfo.stakedValue ? (
          <div className="px-3 py-1 text-base font-medium rounded-xl bg-lightBlackO">
            Eligible
          </div>
        ) : (
          <div className="px-3 py-1 text-base font-medium rounded-xl bg-lightBlackO">
            Not eligible
          </div>
        )}
      </div>
      {activePool.minIn !== activePool.maxIn && !!poolType ? (
        <ShareWhitelist
          handleInput={handleInput}
          value={inputValue}
          min={activePool.minIn}
          max={activePool.maxIn}
          tokenInSymbol={activePool.tokenInSymbol}
          tokenInPrice={activePool.tokenInPrice}
          tokenIn={activePool.tokenIn}
          setIsIncorrectAmount={setIsIncorrectAmount}
          isIncorrectAmount={isIncorrectAmount}
        />
      ) : (
        <RandomWhitelist
          minIn={activePool.minIn}
          tokenInSymbol={activePool.tokenInSymbol}
          tokenIn={activePool.tokenIn}
        />
      )}
      <div>
        {!isWallet ? (
          <ConnectWalletButton />
        ) : (
          <Button
            className="w-full sm:w-min whitespace-nowrap"
            disabled={
              checkFreeze() ||
              activePool.userInfo.stakedValue !== 0 ||
              !currentAccount ||
              isIncorrectAmount
            }
            onClick={joinToPool}
          >
            Join pool
          </Button>
        )}
      </div>
    </div>
  );
};

type RandomWhiteListProps = {
  minIn: number;
  tokenIn: string;
  tokenInSymbol: string | undefined;
};
const RandomWhitelist: FC<RandomWhiteListProps> = ({ minIn, tokenInSymbol, tokenIn }) => {
  return (
    <div className="mb-8">
      <p className="mt-4 mb-8 text-left">
        You need to have{' '}
        <span className="text-yellow">
          {' '}
          {minIn} {tokenInSymbol ? tokenInSymbol : getTokenInfoByAddress(tokenIn)?.name}
        </span>
      </p>
    </div>
  );
};

type ShareProps = {
  handleInput: any;
  value: any;
  min: number;
  max: number;
  tokenInSymbol: string|undefined;
  tokenIn: string;
  tokenInPrice: number;
  setIsIncorrectAmount: any;
  isIncorrectAmount: boolean;
};

const ShareWhitelist: FC<ShareProps> = ({
  handleInput,
  value,
  min,
  max,
  tokenInSymbol,
  tokenIn,
  tokenInPrice,
  isIncorrectAmount,
  setIsIncorrectAmount,
}) => {
  useEffect(() => {
    if (value > max || value < min) setIsIncorrectAmount(true);
    else setIsIncorrectAmount(false);
  }, [value]);

  return (
    <div className="w-full mb-8">
      <div className="flex flex-col justify-between text-2xl sm:items-center sm:flex-row">
        <div className="flex items-baseline gap-2">
          <small>Min:</small>
          <span className="text-yellow">
            {min} {getTokenInfoByAddress(tokenIn)?.name}
          </span>
        </div>
        <div className="flex items-baseline gap-2">
          <small>Max:</small>
          <span className="text-yellow">
            {max} {getTokenInfoByAddress(tokenIn)?.name}
          </span>
        </div>
      </div>
      <Input
        className="w-full"
        placeholder={'Enter token amount'}
        onChange={handleInput}
        value={value}
      />

      <div className="text-sm text-red whitespace-nowrap">
        {isIncorrectAmount && value !== ''
          ? `Value must be grater than ${min} and less than ${max}`
          : ''}
      </div>
      <span className="text-xl font-semibold text-description">
        {' '}
        1 {tokenInSymbol ? tokenInSymbol : getTokenInfoByAddress(tokenIn)?.name} = {tokenInPrice} USDT{' '}
      </span>
    </div>
  );
};

export default PoolWhitelist;
