import Layout from 'components/Layout';
import useWalletSelector from 'hooks/useWalletSelector';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { launchpadActionCreator } from 'store/reducers/launchpad/action-creator';
import PoolAmount from './components/PoolAmount';
import PoolBuy from './components/PoolBuy/PoolBuy';
import PoolDetails from './components/PoolDetails';
import PoolHeader from './components/PoolHeader';
import PoolWhitelist from './components/PoolWhitelist';
import ConfirmBuy from './components/PoolBuy/ConfirmBuy';

const PoolInfo: FunctionComponent = () => {
  const { pools, buyDetails, whitelistDetails, activeIdo} = useWalletSelector(
    state => state.launchpadReducer,
  );
	const {isWallet} = useWalletSelector(state => state.WalletReducer)
	const { currentAccount } = useWalletSelector(state => state.AccountReducer)
  const [claimState, setClaimState] = useState(true)
  const [amountIn, setAmountIn] = useState('')
  const [amountOut, setAmountOut] = useState('')
  const history = useHistory();
  const dispatch = useDispatch();
	let currentTimestamp: number = Date.now() / 1000

  useEffect(() => {
    const currentPoolId = +history.location.pathname.split('/')[2];
		document.getElementById('scroll_to_top')?.scrollIntoView()
    if (pools.length === 0) {
      history.push('/launchpad/');
    } else {
      dispatch(
        launchpadActionCreator.setTokenBuyDetails({
          selectedToken: '',
          tokenPrice: 0,
          amountIn: 0,
          amountOut: 0,
        }),
      );
      dispatch(launchpadActionCreator.setSelectedTokenWhitelist(''));
      dispatch(launchpadActionCreator.setActiveIdoPool(currentPoolId));
			dispatch(launchpadActionCreator.getActivePool(currentPoolId))
    }
  }, []);

	useEffect(() => {
		if(!activeIdo?.userDataIsFetched){
			dispatch(launchpadActionCreator.getActivePool(activeIdo?.poolId))
		}
	}, [currentAccount])

  const buyTokensIdo = () => {
    dispatch(launchpadActionCreator.setLaunchpadState('confirmBuy'));
    // dispatch(launchpadActionCreator.buyIdoTokens())
  };

  const setBuyToken = (tokenAddress: string) => {
    let tokenPrice = activeIdo.buyTokens
      .map((el: string, index: number) =>
        el === tokenAddress ? activeIdo.tokenPrices[index] : null,
      )
      .filter((el: number | null) => !!el)[0];
    dispatch(
      launchpadActionCreator.setTokenBuyDetails({
        selectedToken: tokenAddress,
        tokenPrice,
				amountIn: 0,
				amountOut: 0
      }),
    );
		dispatch(launchpadActionCreator.getBuyTokenBalances())
  };

	const setBuyMaxTokens = () => {
		if((activeIdo.total - activeIdo.totalIDOAmount) / buyDetails.tokenPrice > buyDetails.tokenInBalance){
			let amountIn = buyDetails.tokenInBalance.toFixed(buyDetails.decimal)
			let amountOut = (buyDetails.tokenInBalance * buyDetails.tokenPrice).toFixed(activeIdo.saleTokenInfo.decimal)
			setAmountIn(amountIn)
			setAmountOut(amountOut)
			dispatch(launchpadActionCreator.setTokenBuyDetails({
				amountIn: parseFloat(amountIn),
				amountOut: parseFloat(amountOut)
			}))
		}else {
			let amountIn = ((activeIdo.total - activeIdo.totalIDOAmount) / buyDetails.tokenPrice).toFixed(buyDetails.decimal)
			let amountOut = (activeIdo.total - activeIdo.totalIDOAmount).toFixed(activeIdo.saleTokenInfo.decimal) 
			setAmountIn(amountIn)
			setAmountOut(amountOut)
			dispatch(launchpadActionCreator.setTokenBuyDetails({
				amountIn: parseFloat(amountIn),
				amountOut: parseFloat(amountOut)
			}))
		}
	}

	

  const handleBuyTokenInputIn = (e: React.ChangeEvent<HTMLInputElement>) => {
    let valueIn = e.currentTarget.value
    if (valueIn.split('.').length === 2) {
      if (valueIn.split('.')[1].length > buyDetails.decimal) {
        setAmountIn(amountIn)
        return
      }
    } 
      setAmountIn(e.currentTarget.value)
      let firstTokenInput: number 
      firstTokenInput = e.currentTarget.value === '' ? 0 : parseFloat(e.currentTarget.value);
      setAmountOut(((firstTokenInput * buyDetails.tokenPrice).toFixed(activeIdo.saleTokenInfo.decimal)).toString())
      dispatch(
        launchpadActionCreator.setTokenBuyDetails({
          amountIn: parseFloat(firstTokenInput.toFixed(buyDetails.decimal)),
          amountOut: parseFloat((firstTokenInput * buyDetails.tokenPrice).toFixed(activeIdo.saleTokenInfo.decimal)),
        }),
      );
  };

  const handleBuyTokenInputOut = (e: React.ChangeEvent<HTMLInputElement>) => {
    let valueIn = e.currentTarget.value
    if (valueIn.split('.').length === 2) {
      if (valueIn.split('.')[1].length > activeIdo.saleTokenInfo.decimal) {
        setAmountOut(amountOut)
        return
      }
    } 
    setAmountOut(e.currentTarget.value)
    let secondTokenInput: number 
		secondTokenInput = e.currentTarget.value === '' ? 0 : parseFloat(e.currentTarget.value);
    setAmountIn((secondTokenInput / buyDetails.tokenPrice).toString())
    dispatch(
      launchpadActionCreator.setTokenBuyDetails({
        amountIn: parseFloat((secondTokenInput / buyDetails.tokenPrice).toFixed(buyDetails.decimal)),
        amountOut: secondTokenInput,
      }),
    );
  };

  const handleClaim = () => {
    setClaimState(false)
    dispatch(launchpadActionCreator.claimWhitelistTokens())
  }

  useEffect(() => {
    if(activeIdo?.userDataIsFetched) {
        setClaimState(true)
      }

    },[activeIdo?.userDataIsFetched])

  return (
    <Layout>
      {activeIdo && (
        <div className="w-full mx-auto lg:w-3/4">
          <PoolHeader
            saleTokenInfo={activeIdo.saleTokenInfo}
            active={activeIdo.active}
            name={activeIdo.name}
            start={activeIdo.start}
            image={activeIdo.poolImg}
          />
          <PoolAmount
            totalIDOAmount={activeIdo.totalIDOAmount}
            total={activeIdo.total}
            idoToken={activeIdo.saleTokenInfo.symbol}
            buyTokens={activeIdo.buyTokens}
            tokenPrices={activeIdo.tokenPrices}
            saleTokenInfo={activeIdo.saleTokenInfo}
            poolId={activeIdo.poolId}
          />
					{ (activeIdo.active || (currentTimestamp < activeIdo.start)) && <>
						<PoolWhitelist
							whiteListTokens={activeIdo.whiteListTokens}
							selectedToken={whitelistDetails.selectedToken}
							tokenAmount={whitelistDetails.tokenAmount}
							whitelistedToken={activeIdo.userInfo.whitelistedToken}
							currentAccount={currentAccount}
							userDataIsFetched={activeIdo.userDataIsFetched}
							active={activeIdo.active}
						/>
						<PoolBuy
							minAllocation={activeIdo.minAllocation}
							maxAllocation={activeIdo.maxAllocation}
							saleTokenInfo={activeIdo.saleTokenInfo}
							buyTokens={activeIdo.buyTokens}
							tokenPrices={activeIdo.tokenPrices}
							buyDetails={buyDetails}
							setBuyToken={setBuyToken}
							handleBuyTokenInputIn={handleBuyTokenInputIn}
							handleBuyTokenInputOut={handleBuyTokenInputOut}
							buyTokensIdo={buyTokensIdo}
							total={activeIdo.total}
							totalIDOAmount={activeIdo.totalIDOAmount}
							whitelistedToken={activeIdo.userInfo.whitelistedToken}
							isWallet={isWallet}
							active={activeIdo.active}
							setBuyMaxTokens={setBuyMaxTokens}
              amountIn={amountIn}
              amountOut={amountOut}
						/>
					</>}
          <PoolDetails activeIDO={activeIdo} handleClaim={handleClaim} claimState={claimState}/>
          <ConfirmBuy />
        </div>
      )}
    </Layout>
  );
};

export default PoolInfo;
