import React from 'react';
import { useDispatch } from 'react-redux';
import useWalletSelector from 'hooks/useWalletSelector';
import { accountActionCreator } from 'store/reducers/account/action-creators';
import { WalletConnectActionCreator } from 'store/reducers/wallet/action-creators';
import Config from 'config';

export default function CreateAccount() {
  const dispatch = useDispatch();
  const { createAccount } = useWalletSelector(state => state.AccountReducer);
  const refID = window.localStorage.getItem('ref_id')!;
  const [link, setLink] = React.useState(refID === null ? '' : refID);

  const handlCreateAccount = () => {
    const regExp = Config().REF_REX;
    const regNum = '[0-9]+$';
    if (link === '' || link === null) {
      dispatch(accountActionCreator.createAccount('1'));
    } else {
      var refId = '';
      if (link.includes('https')) {
        var resultReg = new RegExp(regExp);
        if (link.match(resultReg)) {
          refId = link.split('/')[3];
        }
      } else {
        var resultNum = new RegExp(regNum);
        if (link.match(resultNum)) {
          refId = link;
        }
      }
      if (refId === '') {
        setLink('');
      } else {
        dispatch(accountActionCreator.createAccount(refId));
      }
    }
  };

  const handlCancelCreateAccount = () => {
    dispatch(accountActionCreator.setCreateAccountPopup(false));
    dispatch(WalletConnectActionCreator.setWallet(false));
  };

  return (
    <>
      {createAccount && (
        <div
          style={{zIndex: 1000}}
          className="fixed inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom shadow-xl bg-lightBlack rounded-4xl transform transition-all sm:my-7 sm:align-middle sm:max-w-sm sm:w-full">
              <div className="px-4 pt-5 pb-4 bg-lightBlack sm:p-8 sm:pb-4">
                <div className="flex-col sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-title"
                      id="modal-title"
                    >
                      Create account
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-description">
                        The first time you are connected to our platform Exon NFT will be minted to you. This is your decentralized account in Exon Center ecosystem.
                      </p>
                    </div>
                  </div>
                  <div className="w-full min-h-full py-4 mt-3 text-center sm:mt-0 sm:text-left">
                    <input
                      type="text"
                      placeholder="Input link"
                      value={link}
                      onChange={e => setLink(e.currentTarget.value)}
                      className="w-full px-2 rounded-t-lg leading-5 bg-input"
                      style={{
                        minHeight: '40px',
                        borderBottom: '1px solid yellow',
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 mb-6 bg-lightBlack sm:px-8 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-6 py-2 mb-2 text-base font-medium text-black border border-transparent outline-none sm:mb-0 rounded-md shadow-sm bg-yellow focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handlCreateAccount}
                >
                  Create
                </button>

                <button
                  type="button"
                  className="w-full inline-flex justify-center mt-2 sm:mt-0
                  rounded-md border border-transparent shadow-sm px-4 
                  py-2 bg-yellow text-base font-medium text-black
                  outline-none 
                  focus:ring-2 focus:ring-offset-2 
                  focus:ring-black-500 sm:ml-3 
                  sm:w-auto sm:text-sm"
                  onClick={handlCancelCreateAccount}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
