import Layout from 'components/Layout';
import React from 'react';
import { Redirect } from 'react-router';
import Intro from './components/Intro';
import ProposalCard from './components/ProposalCard';

export function Voting() {
  return (
    <>
      {/* <Helmet>
        <title>Home Page</title>
        <meta
          name="description"
          content="A React Boilerplate application homepage"
        />
      </Helmet> */}
      <Layout>
        <Redirect to='/exonnft' />
        <div className="container mb-20">
          <Intro />
          <div className="flex items-center justify-between relative">
            <div className="text-2xl sm:text-4xl text-title">Proposal</div>
            <button className="bg-yellow py-3 px-8 text-xl text-black rounded-xl">
              Create <span className="hidden sm:inline-block">proposal</span>
            </button>
          </div>
          <div className="flex flex-col gap-5 mt-10">
            <ProposalCard status="executed" />
            <ProposalCard status="canceled" />
            <ProposalCard status="defeated" />
          </div>
          <div className="text-xl text-description mt-8 text-center">
            A mimimum thereshold of 0.25% of the total EXON supply is required
            to submit proposals
          </div>
        </div>
      </Layout>
    </>
  );
}
