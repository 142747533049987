import React, { FunctionComponent, useState } from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Arrow } from 'components/Icons';
import LevelInfo from '../LevelInfo';
import useWalletSelector from 'hooks/useWalletSelector';

SwiperCore.use([Pagination]);

export type Props = {
  setCurrentLevelInfo: any;
  currentLevelInfo: number;
}

const LevelProgramSlider: FunctionComponent<Props> = ({setCurrentLevelInfo, currentLevelInfo}) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const { levels, farmingInfo, teamLevelsRevenue } = useWalletSelector(state => state.AccountReducer);

  // const getLevelAmount = (allLevels: number[]) => {
  //   var result: number = 0;
  //   for (var i = 0; i < allLevels.length; i++) {
  //     result += allLevels[i];
  //   }
  //   return result;
  // };

  return (
    <Swiper
      className="w-full sm:hidden mt-7"
      pagination={{
        clickable: true,
        el: '.swiper-pagination',
        type: 'bullets',
      }}
      spaceBetween={50}
      slidesPerView={1}
      onSwiper={swiper => setSwiper(swiper)}
    >
      <SwiperSlide>
        <LevelInfo
          currentLevelInfo={currentLevelInfo}
          setCurrentLevelInfo={setCurrentLevelInfo}
          level="1"
          percent="8%"
          title="Level 1"
          liquidity="$10 - $100"
          teamTurnover="< $1 000"
          personalTurnover="100"
          partners={levels[0].toString()}
          sum={`$ ${farmingInfo.teamFarmingByLevel[0]}`}
          active={!!teamLevelsRevenue[0]}
        />
      </SwiperSlide>
      <SwiperSlide>
        <LevelInfo
          currentLevelInfo={currentLevelInfo}
          setCurrentLevelInfo={setCurrentLevelInfo}
          level="2"
          percent="5%"
          title="Level 2"
          liquidity="$101 - $500"
          teamTurnover="$1 000"
          personalTurnover="80"
          partners={levels[1].toString()}
          sum={`$ ${(farmingInfo.teamFarmingByLevel[1]).toFixed(2)}`}
          active={!!teamLevelsRevenue[1]}
        />
      </SwiperSlide>
      <SwiperSlide>
        <LevelInfo
          currentLevelInfo={currentLevelInfo}
          setCurrentLevelInfo={setCurrentLevelInfo}
          level="3"
          percent="2%"
          title="Level 3"
          liquidity="$501 - $1 000"
          teamTurnover="$5 000"
          personalTurnover="60"
          partners={levels[2].toString()}
          sum={`$ ${(farmingInfo.teamFarmingByLevel[2]).toFixed(2)}`}
          active={!!teamLevelsRevenue[2]}
        />
      </SwiperSlide>
      <SwiperSlide>
        <LevelInfo
          currentLevelInfo={currentLevelInfo}
          setCurrentLevelInfo={setCurrentLevelInfo}
          level="4"
          percent="2%"
          title="Level 4"
          liquidity="$1 001 - $5 000"
          teamTurnover="$10 000"
          personalTurnover="40"
          partners={levels[3].toString()}
          sum={`$ ${(farmingInfo.teamFarmingByLevel[3]).toFixed(2)}`}
          active={!!teamLevelsRevenue[3]}
        />
      </SwiperSlide>
      <SwiperSlide>
        <LevelInfo
          currentLevelInfo={currentLevelInfo}
          setCurrentLevelInfo={setCurrentLevelInfo}
          level="5"
          percent="2%"
          title="Level 5"
          liquidity="$5 001 - $10 000"
          teamTurnover="$25 000"
          personalTurnover="20"
          partners={levels[4].toString()}
          sum={`$ ${(farmingInfo.teamFarmingByLevel[4]).toFixed(2)}`}
          active={!!teamLevelsRevenue[4]}
        />
      </SwiperSlide>
      <SwiperSlide>
        <LevelInfo
          currentLevelInfo={currentLevelInfo}
          setCurrentLevelInfo={setCurrentLevelInfo}
          level="6"
          percent="1%"
          title="Level 6"
          liquidity="$10 001 - $50 000"
          teamTurnover="100 000"
          personalTurnover="10"
          partners={levels[5].toString()}
          sum={`$ ${(farmingInfo.teamFarmingByLevel[5]).toFixed(2)}`}
          active={!!teamLevelsRevenue[5]}
        />
      </SwiperSlide>

      <div className="flex items-center justify-between mt-10">
        <div onClick={() => swiper?.slidePrev()}>
          <Arrow className="w-8 h-8" />
        </div>
        <div className="swiper-pagination"></div>
        <div onClick={() => swiper?.slideNext()}>
          <Arrow className="w-8 h-8 transform -rotate-180" />
        </div>
      </div>
    </Swiper>
  );
};

export default LevelProgramSlider;
