import initTronstack from './tronWeb/initTronstack';
import parseResult from 'utils/parseResult';
import { getParams } from './params/functionParams';

export const getTransactionBuilder = async (
  contractAddress: string,
  transactionFunction: string,
  args: any[],
) => {
  const tronWeb = await initTronstack();
  let newTransaction = transactionFunction.split('()')[0];
  let newArgs = [];
  let sendArgs = [];
  const defineArgsTypes = (arg: any) => {
    switch (typeof arg) {
      case 'string':
        return 'address';
      case 'number':
        return 'uint256';
      case 'boolean':
        return 'bool';
      default:
        return '';
    }
  };
  for (let i = 0; i < args.length; i++) {
    newArgs.push(defineArgsTypes(args[i]));
  }
  for (let i = 0; i < args.length; i++) {
    sendArgs.push({ type: defineArgsTypes(args[i]), value: args[i] });
  }

  newTransaction = newTransaction + '(' + newArgs.join() + ')';
  let currentTransaction =
    await tronWeb.transactionBuilder.triggerConstantContract(
      contractAddress,
      newTransaction,
      {},
      sendArgs,
    );
  let outputType = getParams(transactionFunction);
  let resultTransaction = await parseResult(
    outputType,
    '0x' + currentTransaction.constant_result[0],
  );

  return resultTransaction;
};
