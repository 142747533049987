import React, { FunctionComponent, useContext } from 'react';
import { ReactComponent as LogoSvg } from './assets/logo.svg';
import { ReactComponent as LogoSmallSvg } from './assets/logo-small.svg';
import { SidebarContext } from '../../../../components/Layout/Layout';

const Logo: FunctionComponent = () => {
  const { isSidebarOpen } = useContext(SidebarContext);

  return (
    <div>
      <a href="https://exoncenter.com">
        {isSidebarOpen ? <LogoSvg /> : <LogoSmallSvg />}
      </a>
    </div>
  );
};

export default Logo;
