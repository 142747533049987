import React from 'react';
import Modal from 'components/Modal';
import WaitingGif from 'assets/waiting.gif';

const WaitingConfirmation = () => {
  const isModal = false;
  return (
    <>
      {isModal && (
        <Modal>
          <div className="flex flex-col items-center">
            <div className="text-xl text-title">
              Deposit 129.423 USDT-EXON LP
            </div>
            <img src={WaitingGif} alt="Waiting" className="w-36" />
            <div className="text-xl sm:text-3xl text-title">
              Waiting for your confirmation
            </div>
            <div className="text-description mt-5">
              Please confirm in your wallet
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default WaitingConfirmation;
