import { coinIcons } from 'components/CoinIcons';
import { Arrow } from 'components/Icons';
import useWalletSelector from 'hooks/useWalletSelector';
import { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { accountActionCreator } from 'store/reducers/account/action-creators';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';
import s from './LevelPairSelect.module.css';

type Props = {
  tokenSelectList: string[];
  selectedToken: string | undefined;
  setToken: any;
  disabled?: boolean;
  selectedTokenIcon?: string;
  selectedTokenName?: string;
};

export const SelectToken: FunctionComponent<Props> = ({
  tokenSelectList,
  selectedToken,
  setToken,
  selectedTokenIcon,
  selectedTokenName,
  disabled,
}) => {
  const { isWallet } = useWalletSelector(state => state.WalletReducer);
  const [selectMode, setSelectMode] = useState(false);
  const isMobile = window.innerWidth <= 640
  return false ? (
    <></>
  ) : (
    <div className="relative block w-16 cursor-pointer select-none sm:w-44 whitespace-nowrap">
      {(!disabled && !isMobile) && (
        <Arrow
          className={`absolute pointer-events-none transition ${
            selectMode ? 'rotate-90' : '-rotate-90'
          } transform top-2/4 -translate-y-1/2`}
          style={{ right: 15 }}
        />
      )}
      <div
        onClick={() => !disabled && setSelectMode(!selectMode)}
        className={`${s.select} ${isMobile ? 'flex items-center justify-center' : ''}  ${
          selectMode ? 'rounded-b-none' : 'rounded-b-xl'
        }  
                form-select block w-full bg-buttonBlack outline-none rounded-t-xl 
                py-2 pl-2 sm:pl-8 pr-2 sm:pr-4 appearance-none text-left`}
      >
        {!selectedToken ? (
          isMobile ? <Arrow className='mt-3 transform -rotate-90 -translate-y-1' /> : 'Select token'
        ) : (
          <div className="flex items-center">
            <div className="flex items-center sm:mr-2">
              <img
                className="w-6 h-6 rounded-full"
                src={
                  disabled
                    ? selectedTokenIcon
                    : coinIcons[getTokenInfoByAddress(selectedToken).icon]
                }
                alt=""
              />
            </div>
            <div className='hidden sm:block'>
              {disabled ? selectedTokenName : getTokenInfoByAddress(selectedToken).name}
            </div>
          </div>
        )}
      </div>
      <div
        className={`${
          selectMode ? s.dropdown : s.dropdown__hidden
        } rounded-b-xl w-full`}
      >
        {tokenSelectList.map((el, index) => {
          return (
            <div
              onClick={() => {
                setToken(el);
                setSelectMode(false);
              }}
              className={` ${isMobile ? 'flex items-center justify-center text-center' : ''} hover:bg-black hover:text-white 
                        ${
                          tokenSelectList.length === index && 'rounded-b-xl'
                        } flex items-center py-2 pl-0 sm:pl-8 pr-0 sm:pr-4
                        ${selectedToken === el && 'bg-yellow text-black'} 
                        `}
            >
              <div className="flex items-center sm:mr-2">
                <img
                  className="w-6 w-10 h-6 h-10 sm:-mr-2"
                  src={coinIcons[getTokenInfoByAddress(el).icon]}
                  alt=""
                />
              </div>
              <div className='hidden sm:block'>{getTokenInfoByAddress(el).name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
