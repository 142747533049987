import React from 'react';
import Layout from 'components/Layout';
import SwapContainer from './components/SwapContainer';
import SwapConfirm from './components/SwapContainer/components/SwapProcess/components/SwapConfirm';
import SwapSuccess from './components/SwapContainer/components/SwapProcess/components/SwapSuccess';
import ConfirmLiquidity from './components/SwapContainer/components/PoolProcess/components/AddLiquidity/components/ConfirmLiquidity';
import RemoveLiquidity from './components/SwapContainer/components/PoolProcess/components/RemoveLiquidity';
import RemoveLiquidityConfirm from './components/SwapContainer/components/PoolProcess/components/RemoveLiquidity/componets/RemoveLiquidityConfirm';
import ReceiveLiquidityConfirm from './components/SwapContainer/components/PoolProcess/components/RemoveLiquidity/componets/ReceiveLiquidityConfirm';
import LiquiditySuccess from './components/SwapContainer/components/PoolProcess/components/AddLiquidity/components/LiquiditySuccess';
import SwapSettings from './components/SwapContainer/components/SwapSettings';
import SwapSteps from './components/SwapContainer/components/SwapProcess/components/SwapSteps';
import Swap from './components/SwapContainer/assets/swap.png'
import useWalletSelector from 'hooks/useWalletSelector';

export function SwapPage() {

  const { liquidityState } = useWalletSelector(state => state.liquidityReducer);

  return (
    <Layout>
      <div className="container">
      <div className=" w-full relative -mb-24">
        <div className="flex flex-col justify-center gap-3 sm:gap-6 text-center ">
          <div>
            <h1 className="text-4xl sm:text-6xl xl:text-7xl font-medium text-title">
              Exon Swap
            </h1>
          </div>
          <p className="text-description">
            Exchange and earn on total and personal liquidity
          </p>
        </div>
      </div>
        <div className="flex justify-center items-center relative">
          <SwapContainer />
        </div>
      </div>
      <SwapSettings />
      <SwapConfirm />
      <SwapSuccess />
      <ConfirmLiquidity />
      {liquidityState === 'remove' && <RemoveLiquidity />}
      <ReceiveLiquidityConfirm />
      <RemoveLiquidityConfirm />
      <LiquiditySuccess />
    </Layout>
  );
}
