import { coinIcons } from 'components/CoinIcons';
import { Arrow } from 'components/Icons';
import useWalletSelector from 'hooks/useWalletSelector';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { accountActionCreator } from 'store/reducers/account/action-creators';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';
import s from './LevelPairSelect.module.css'


export default () => {
    const { farmingList } = useWalletSelector( state => state.farmingReducer )
        const { isWallet } = useWalletSelector( state => state.WalletReducer )
        const { choosenFarmingPoolId } = useWalletSelector( state => state.AccountReducer )
        const dispatch = useDispatch()
        const [selectMode, setSelectMode] = useState(false)

        let selectedPair = farmingList.filter(el => el.poolId === choosenFarmingPoolId)

        return false ? <></> : (
                <div className="relative block select-none w-52">
                <Arrow
                className={`absolute pointer-events-none transition ${selectMode ? 'rotate-90' : '-rotate-90'} transform top-2/4 -translate-y-1/2`}
                style={{ right: 15 }}
                />
                <div onClick={() => isWallet && farmingList.length && setSelectMode(!selectMode)} 
                className={`${s.select}  ${selectMode ? 'rounded-b-none' : 'rounded-b-xl'}  
                form-select block w-full mt-1 bg-button outline-none rounded-t-xl 
                py-2 px-4 appearance-none`}>
                {!choosenFarmingPoolId ? 'Select pair' : selectedPair.length === 1 && selectedPair.map(el => {
                        return <div className="flex items-center">
                        <div className="flex items-center mr-2">
                        <img className='w-6 h-6 -mr-2' src={coinIcons[getTokenInfoByAddress(el.token0).icon]} alt="" />
                        <img className='w-6 h-6 ' src={coinIcons[getTokenInfoByAddress(el.token1).icon]} alt="" />
                        </div>
                        <div className={el.lpStaked && 'text-lightYellow'}>{getTokenInfoByAddress(el.token0).name}/{getTokenInfoByAddress(el.token1).name}</div>
                        </div>
                        })}
                </div>
                <div className={`${selectMode ? s.dropdown : s.dropdown__hidden} rounded-b-xl w-full`}>
                {farmingList.map(el => {
                        return <div onClick={() => {
                        dispatch(accountActionCreator.setChoosenFarmingPoolId(el.poolId))
                        setSelectMode(false)
                        }} className={`hover:bg-black hover:text-white 
                        ${farmingList.length === el.poolId && 'rounded-b-xl'} flex items-center p-2 
                        ${choosenFarmingPoolId === el.poolId && 'bg-yellow text-black' } 
                        `}>
                        <div className="flex items-center mr-2">
                        <img className='w-6 h-6 -mr-2' src={coinIcons[getTokenInfoByAddress(el.token0).icon]} alt="" />
                        <img className='w-6 h-6 ' src={coinIcons[getTokenInfoByAddress(el.token1).icon]} alt="" />
                        </div>
                        <div 
                        className={el.lpStaked && choosenFarmingPoolId !== el.poolId && 'text-lightYellow'}>
                        {getTokenInfoByAddress(el.token0).name}/{getTokenInfoByAddress(el.token1).name}
                        </div>
                        </div>
                        })}
    </div>
        </div>
        )
}
