import React, { FunctionComponent } from 'react';
import rang1 from '../assets/rang1.svg';
import rang2 from '../assets/rang2.svg';
import rang3 from '../assets/rang3.svg';
import rang4 from '../assets/rang4.svg';
import rang5 from '../assets/rang5.svg';
import rang6 from '../assets/rang6.svg';
// import SupplyIcon from '../assets/supply.svg';
// import PriceInIcon from '../assets/priceIn.svg';
//import TurnoverIcon from '../assets/turnover.svg'
// import LevelIcon from '../assets/level.svg'
import {ReactComponent as LevelGet} from '../assets/levelGet.svg'
import {ReactComponent as Levela} from '../assets/levela.svg'
import {ReactComponent as Turnover} from '../assets/turnover.svg'
import PrizeExon from '../assets/prizeExon.svg';
import useWalletSelector from 'hooks/useWalletSelector';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import { useDispatch } from 'react-redux';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';
import {getTokenInfoByAddress} from 'utils/getTokenInfo';


interface Props {
  title?: string;
  currentAccountLevel: number;
  currentRangLevel: number;
  withdrawedLevels: number;
  token0: string;
  token1: string;
  firstTokenTurnover: number;
  secondTokenTurnover: number;
}

const ProgramProcess: FunctionComponent<Props> = (
  {
    title, currentAccountLevel, currentRangLevel, withdrawedLevels, token0, token1, firstTokenTurnover, secondTokenTurnover 
  }) => {
  const { isWallet } = useWalletSelector(state => state.WalletReducer)
  const { exonUsdPrice } = useWalletSelector(state => state.applicationReducer)
  const dispatch = useDispatch()
  const currentLevelData = (level: number) => {
    const rewards = [1, 10, 100, 500, 1000, 10000]
    let price = 0
    for (let i = withdrawedLevels; i < currentRangLevel; i++) {
      price += rewards[i]
    }

    switch (level) {
      case 0:
        return {
          turnover: 5,
          level: 1,
          price: price,
          img: rang1,
          name: 'Shrimp'
        }
        case 1:
          return {
            turnover: 50,
            level: 2,
            price: price,
            img: rang1,
            name: 'Shrimp'
          }
        case 2:
          return {
            turnover: 250,
            level: 3,
            price: price,
            img: rang2,
            name: 'Crab'
          }
        case 3:
          return {
            turnover: 500,
            level: 4,
            price: price,
            img: rang3,
            name: 'Fish'
          }
        case 4:
          return {
            turnover: 2500,
            level: 5,
            price: price,
            img: rang4,
            name: 'Dolphin'
          }
        case 5:
          return {
            turnover: 5000,
            level: 6,
            price: price,
            img: rang5,
            name: 'Shark'
          }
      default:
        return {
          turnover: 0,
          level: 0,
          price: price,
          img: rang6,
          name: 'Whale'
        }
    }
  }

  const turnoverFormula = (token: number) => {
    return token / currentLevelData(currentRangLevel).turnover
  }

  const levelFormula = () => {
    return currentAccountLevel >= currentLevelData(currentRangLevel).level
  }


  return (
    <div className="relative h-full pb-5 overflow-hidden bg-darkGray rounded-4xl sm:pb-20">
      <div className="px-4 sm:px-9">
        <div className="flex items-center mt-8">
          <div
            className="flex items-center justify-center w-24 h-24 mr-4 rounded-full bg-lightGrey"
            style={{ background: '#F6E894' }}
          >
            <img
              src={currentLevelData(currentRangLevel).img}
              alt=""
              style={{ width: '80px', height: '80px' }}
            />
          </div>
          {/* <h4 className="text-lg font-semibold text-title">{title}</h4> */}
          <h4 className="font-semibold sm:text-3xl text-description">{currentLevelData(currentRangLevel).name}</h4>
        </div>

        <p className="mt-10 text-sm text-description">To next rank achive</p>
        <div className="mt-2">
          <div className="flex justify-between">
            <div className="text-sm text-description">{
              Math.round(
              (turnoverFormula(firstTokenTurnover) > 1 ? 1 : turnoverFormula(firstTokenTurnover) )
              * 33 + (turnoverFormula(secondTokenTurnover) > 1 ? 1 : turnoverFormula(secondTokenTurnover) )
              * 33 + (levelFormula() ? 34 : 0)) }%
            </div>
            <div className="text-sm text-lightYellow">{
              (turnoverFormula(firstTokenTurnover) > 1 ? 1 : 0) +
              (turnoverFormula(secondTokenTurnover) > 1 ? 1 : 0) + 
              (levelFormula() ? 1 : 0)} / 3</div>
          </div>
          <div className="border rounded-lg p-0.5 mt-2">
            <div 
              style={{width: `${
                (turnoverFormula(firstTokenTurnover) > 1 ? 1 : turnoverFormula(firstTokenTurnover) ) 
                * 33 + (turnoverFormula(secondTokenTurnover) > 1 ? 1 : turnoverFormula(secondTokenTurnover) )
                * 33 + (levelFormula() ? 34 : 0) }%`}} 
              className="p-1 rounded-lg bg-yellow "></div>
          </div>
        </div>
        <div className="flex flex-col mt-10">
          <div className="flex flex-col justify-between gap-4">
            <div className="flex items-center">
              {/* <img src={SupplyIcon} alt="supply icon" /> */}
              {turnoverFormula(firstTokenTurnover) >= 1 
                ? <LevelGet style={{margin: '-16px 16px -16px -32px'}}  /> 
                : <Turnover width='50px' height='50px' style={{margin: '0 16px 0 0'}} />}
              <div className='w-10/12'>
                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h5 className='text-base text-lightBlack'>{getTokenInfoByAddress(token0)?.name || 'token 1'} turnover</h5>
                  <div className="">${(firstTokenTurnover * exonUsdPrice).toFixed(2)} / ${ currentLevelData(currentRangLevel).turnover }</div>
                </div>
                <div className="mt-1 text-lg text-lightYellow">
                    <div className="border rounded-lg p-0.5">
                      <div 
                        style={{ width: `${(turnoverFormula(firstTokenTurnover) >= 1 ? 1 : turnoverFormula(firstTokenTurnover) ) * 100}%` }} 
                        className={`rounded-lg transition-all p-1 ${ turnoverFormula(firstTokenTurnover) >= 1 ? 'bg-green' : 'bg-yellow'}`}>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="flex items-center">
              {/* <img src={SupplyIcon} alt="supply icon" /> */}
              {turnoverFormula(secondTokenTurnover) >= 1 
                ? <LevelGet style={{margin: '-16px 16px -16px -32px'}}  /> 
                : <Turnover width='50px' height='50px' style={{margin: '0 16px 0 0'}} />}
              <div className='w-10/12'>
                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h5 className='text-base text-lightBlack'>{getTokenInfoByAddress(token1)?.name || 'token 2'} turnover</h5>
                  <div className="">${secondTokenTurnover} / ${ currentLevelData(currentRangLevel).turnover }</div>
                </div>
                <div className="mt-1 text-lg text-lightYellow">
                    <div className="border rounded-lg p-0.5">
                      <div 
                        style={{ width: `${(turnoverFormula(secondTokenTurnover) >= 1 ? 1 : turnoverFormula(secondTokenTurnover) ) * 100}%` }} 
                        className={`rounded-lg transition-all p-1 ${ turnoverFormula(secondTokenTurnover) >= 1 ? 'bg-green' : 'bg-yellow'}`}>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="relative flex items-center">
              {levelFormula() ? <LevelGet style={{margin: '-16px 16px -16px -32px'}} /> : <Levela width='50px' height='50px' style={{margin: '0 16px 0 0 '}} />}
              <div className="flex flex-col justify-center">
                <div className="text-xs text-lightBlack">Level</div>
                <div className='text-title'>{currentAccountLevel}</div>
              </div>
            </div>
          </div>
          <div className="text-center text-description sm:text-left sm:mt-10">
            Get reward
          </div>
          <div className="flex flex-col mt-2 sm:flex-row sm:justify-between sm:items-center">
            <div className="flex items-center">
              <img src={PrizeExon} alt="supply icon" />
              <div className="flex flex-col justify-center">
                <div className="text-xs text-lightBlack">Prize in EXON</div>
                <div className='text-title' >{currentLevelData(currentRangLevel).price}$</div>
              </div>
            </div>
            <div className="flex items-center sm:w-auto sm:ml-8">
              {isWallet
                ? <button
                    disabled={currentLevelData(currentRangLevel).price === 0}
                    onClick={() => {
                      dispatch(farmingActionCreator.setWithdrawAmount(+(currentLevelData(currentRangLevel).price / exonUsdPrice).toFixed(6)))
                      dispatch(farmingActionCreator.getRankWithdraw())
                    }}
                    className="w-full px-2 py-2 text-sm text-black rounded-lg cursor-pointer bg-yellow lg:px-2 lg:py-2 sm:mt-3 lg:mt-0 sm:w-32"
                    style={currentLevelData(currentRangLevel).price === 0 ? { background: '#4C4C4C', opacity: '0.6', color: 'gray' } : {}}
                  >
                    <span className="">Claim</span>
                  </button>
                : <ConnectWalletButton personal/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramProcess;
