    import {
  LiquidityStateInterface,
  LiquidityAction,
  LiquidityEnum,
} from './types';

const InitialState: LiquidityStateInterface = {
  liquidityState: 'initial',
  liquidityPairs: [],
  liquidityMainState: 'createPool',
  isFetching: false,
  isEmpty: false,
};

export default function liquidityReducer(
  state = InitialState,
  action: LiquidityAction,
) {
  switch (action.type) {
    case LiquidityEnum.SET_LIQUIDITY_STATE:
      return { 
        ...state, 
        liquidityState: action.payload,
        isEmpty: action.payload === 'addLiquiditySuccess' ? false : state.isEmpty };
    case LiquidityEnum.SET_LIQUIDITY_PAIRS:
      return { ...state, liquidityPairs: action.payload };
    case LiquidityEnum.SET_LIQUIDITY_MAIN_STATE:
      return { ...state, liquidityMainState: action.payload };
    case LiquidityEnum.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload };
    case LiquidityEnum.SET_IS_EMPTY:
      return { ...state, isEmpty: action.payload}
    case LiquidityEnum.RESET_LIQUIDITY_PAIRS:
      return{...state, liquidityPairs: []}
    default:
      return state;
  }
}
