import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';

export type Props = {
	setTrxSend: any;
	trxSend: boolean
}

const TrxCheckbox: FunctionComponent<Props> = ({setTrxSend, trxSend}) => {
  const dispatch = useDispatch()
  return (
    <div className="flex items-center mr-0 lg:mr-24">
      <span className="mr-5 text-xl text-title">Send TRX</span>
      <div className="inline-flex items-center justify-center">
        <label htmlFor="toggle" className="flex items-center cursor-pointer">
          <div className="relative">
            <input onChange={() => setTrxSend(!trxSend) } type="checkbox" id="toggle" className="sr-only" />
            <div className="block h-8 rounded-full bg-darkGray w-14"></div>
            <div className="absolute w-6 h-6 bg-white rounded-full dot left-1 top-1 transition"></div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default TrxCheckbox;
