import { Arrow } from 'components/Icons';
import React, { FunctionComponent } from 'react';

const ButtonSwapEmpty: FunctionComponent = () => {
  return (
    <>
      <button
        className="w-14 inline-flex justify-between rounded-md border 
                  border-transparent shadow-sm px-6 py-3 sm:py-2.5 bg-buttonBlack text-base 
                  font-medium text-description hover:bg-black focus:outline-none 
                  focus:ring-2 focus:ring-offset-2 focus:ring-black-500 sm:ml-3 sm:w-auto sm:text-sm"
      >
        <span className="flex items-center justify-center w-18">
          <div className="hidden px-4 uppercase sm:block">Select</div>
          <Arrow className="transform -rotate-90" />
        </span>
      </button>
    </>
  );
};

export default ButtonSwapEmpty;
