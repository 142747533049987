import { coinIcons } from "components/CoinIcons"
import { BarData, createChart, CrosshairMode, LineData, WhitespaceData } from "lightweight-charts"
import { FunctionComponent, useEffect, useRef, useState } from "react"
import { CandleChart, PagePairInfoElement } from "store/reducers/graphic/types"

export type Props = {
    pooledTokensAmount: {
        firstToken: number,
        secondToken: number
    },
    totalLiquidity: PagePairInfoElement,
    transactions: PagePairInfoElement,
    volume: PagePairInfoElement,
    history: any,
    activeTab: string,
    setActiveTab: Function,
    period: number,
    setPeriod: Function,
    candleGraphic: BarData[],
    liquidityGraphic: LineData[],
    volumeGraphic: LineData[],
}

export const PairGraphs: FunctionComponent<Props> = (
    {
        pooledTokensAmount, 
        totalLiquidity, 
        transactions, 
        volume, 
        history, 
        activeTab, 
        setActiveTab, 
        period, 
        setPeriod,
        candleGraphic,
        liquidityGraphic,
        volumeGraphic,
    }) => {
    
    const graphRef = useRef<any>(null)

    const handleChangeTab = (tab: string) => {
        if(tab === activeTab) return
        graphRef.current.removeChild(graphRef.current.children[graphRef.current.children.length-1])
        setActiveTab(tab)
    }

    const setCurrGraph = (tab: string) => {
        let chart
        let lineSeries
        handleChangeTab(tab)
        switch (tab) {
            case 'candleStick':
                chart = createChart(graphRef.current, {
                    width: graphRef.current.clientWidth-50,
                    height: graphRef.current.clientHeight-200,
                    rightPriceScale: {
                        borderVisible: false,
                    },
                    timeScale: {
                        borderVisible: false,
                        timeVisible: true
                    },
                    layout: {
                        backgroundColor: 'transparent',
                        lineColor: '#2B2B43',
                        textColor: '#D9D9D9',
                    },
                    watermark: {
                        color: '#FDD807',
                    },
                    crosshair: {
                        color: '#758696',
                        mode: CrosshairMode.Normal
                    },
                    grid: {
                        vertLines: {
                            color: 'transparent',
                        },
                        horzLines: {
                            color: '#dadada3e'  
                        },
                    },
                });
                let candleSeries = chart.addCandlestickSeries();
                graphRef.current.children[graphRef.current.children?.length-1].style.margin = '0 auto'
                candleSeries.setData(candleGraphic)
                return
            case 'volume':
                chart = createChart(
                    graphRef.current, 
                    { 
                        width: graphRef.current.clientWidth-60, 
                        height: graphRef.current.clientHeight-110,
                        rightPriceScale: {
                            borderVisible: false,
                        },
                        timeScale: {
                            borderVisible: false
                        },
                        layout: {
                            backgroundColor: 'transparent',
                            lineColor: '#2B2B43',
                            textColor: '#D9D9D9',
                        },
                        watermark: {
                            color: '#FDD807',
                        },
                        crosshair: {
                            color: '#758696',
                            mode: CrosshairMode.Normal
                        },
                        grid: {
                            vertLines: {
                                color: 'transparent',
                            },
                            horzLines: {
                                color: '#dadada3e'  
                            },
                        },
                        });
                        lineSeries = chart.addAreaSeries({
                            topColor: '#fdd807ca',
                            bottomColor: 'rgba(253, 216, 7, 0.001)',
                            lineColor: '#FDD807',
                            lineWidth: 1,
                    });
                    graphRef.current.children[graphRef.current.children.length-1].style.margin = '30px auto 0'
                    lineSeries.setData(volumeGraphic)
                    return
                case 'liquidity':
                    chart = createChart(
                        graphRef.current, 
                        { 
                            width: graphRef.current.clientWidth-60, 
                            height: graphRef.current.clientHeight-110,
                            rightPriceScale: {
                                borderVisible: false,
                            },
                            timeScale: {
                                borderVisible: false
                            },
                            layout: {
                                backgroundColor: 'transparent',
                                lineColor: '#2B2B43',
                                textColor: '#D9D9D9',
                            },
                            watermark: {
                                color: '#FDD807',
                            },
                            crosshair: {
                                color: '#758696',
                                mode: CrosshairMode.Normal
                            },
                            grid: {
                                vertLines: {
                                    color: 'transparent',
                                },
                                horzLines: {
                                    color: '#dadada3e'  
                                },
                            },
                        });
                    lineSeries = chart.addAreaSeries({
                        topColor: '#fdd807ca',
                        bottomColor: 'rgba(253, 216, 7, 0.001)',
                        lineColor: '#FDD807',
                        lineWidth: 1,
                    });
                    graphRef.current.children[graphRef.current.children.length-1].style.margin = '30px auto 0'
                    lineSeries.setData(liquidityGraphic)
                    return
            default:
                return
        }
    }

    useEffect(() => {
        if(graphRef.current !== null){
            if(activeTab === 'candleStick' ? candleGraphic?.length !== 0 : activeTab === 'liquidity' ? liquidityGraphic?.length !== 0 : volumeGraphic?.length !== 0 ){
                if (graphRef.current.children[graphRef.current.children.length-1].className !== 'tv-lightweight-charts') {
                    setCurrGraph(activeTab)
                }
            }
        }
    }, [graphRef, activeTab, candleGraphic, liquidityGraphic, volumeGraphic,])

    return(
        <div>
            <div className="flex lg:flex-row flex-col-reverse gap-x-4">
               <div className="w-full lg:w-1/3">
                    <div className="py-4 px-8 bg-cardBg rounded-3xl">
                        <div className="flex justify-between items-center">
                            <h1 className='text-title text-xl lg:text-base xxl:text-xl font-medium'>Total liquidity</h1>
                            <span className='whitespace-nowrap text-green text-xs xxl:text-base font-normal py-1 xxl:py-2 px-2 xxl:px-4 bg-transGreen rounded-lg border border-green'>{`${totalLiquidity.percents >= 0 ? '+' : '-'} ${totalLiquidity.percents}`}%</span>
                        </div>
                        <div className="text-yellow text-xl xxl:text-xl font-medium mt-5">$ {totalLiquidity.amount}</div>
                    </div>
                    
                    <div className="py-4 px-8 mt-5 bg-cardBg rounded-3xl">
                        <div className="flex justify-between items-center">
                            <h1 className='text-title text-xl lg:text-base xxl:text-xl font-medium'>Volume <span className='text-xs mr-1'>(24h)</span></h1>
                            <span className='whitespace-nowrap text-green text-xs xxl:text-base font-normal py-1 xxl:py-2 px-2 xxl:px-4 bg-transGreen rounded-lg border border-green' >{`${volume.percents >= 0 ? '+' : '-'} ${volume.percents}`}%</span>
                        </div>
                        <div className="text-yellow text-xl xxl:text-xl font-medium mt-5">$ {volume.amount}</div>
                    </div>

                    <div className="py-4 px-8 mt-5 bg-cardBg rounded-3xl">
                        <div className="flex justify-between items-center">
                            <h1 className='text-title text-xl lg:text-base xxl:text-xl font-medium'>Transactions <span className='text-xs mr-1'>(24h)</span></h1>
                            <span className='whitespace-nowrap text-green text-xs xxl:text-base font-normal py-1 xxl:py-2 px-2 xxl:px-4 bg-transGreen rounded-lg border border-green' >{`${transactions.percents >= 0 ? '+' : '-'} ${transactions.percents}`}%</span>
                        </div>
                        <div className="text-yellow text-xl xxl:text-xl font-medium mt-5">{transactions.amount}</div>
                    </div>

                    <div className="py-4 px-8 mt-5 bg-cardBg rounded-3xl">
                        <h1 className='mb-4 text-title text-xl lg:text-base  xxl:text-xl font-medium'>Pooled tokens</h1>    
                        <div className="mb-5 text-description flex items-center text-base lg:text-xs xxl:text-base font-normal">
                            <img className='mr-2 w-7 h-7' src={coinIcons[history.location.pathname.split('/')[2].split('-')[0].toLowerCase()]} alt="" />
                            <span>{`${pooledTokensAmount.firstToken} ${history.location.pathname.split('/')[2].split('-')[0].toUpperCase()}`}</span>
                        </div>
                        <div className="text-description flex items-center text-base lg:text-xs xxl:text-base font-normal">
                            <img className='mr-2 w-7 h-7' src={coinIcons[history.location.pathname.split('/')[2].split('-')[1].toLowerCase()]} alt="" />
                            <span>{`${pooledTokensAmount.secondToken} ${history.location.pathname.split('/')[2].split('-')[1].toUpperCase()}`}</span>
                        </div>
                    </div>
               </div>
               <div ref={graphRef} style={{height: window.innerWidth < 1024 ? '550px' : ''}} className="w-full lg:mb-0 mb-5 lg:w-2/3 bg-cardBg rounded-4xl pt-8">
                    <div className="w-11/12 outline-none  mx-auto text-xs sm:text-base xxl:text-2xl text-title font-medium flex justify-around items-center border-b border-input">
                        <span onClick={() => handleChangeTab('candleStick')} className={`${activeTab === 'candleStick' && 'border-b border-yellow text-yellow'} border-b border-transparent cursor-pointer`}>USDT/EXON Candlestick Chart</span>
                        <span onClick={() => handleChangeTab('liquidity')} className={`${activeTab === 'liquidity' && 'border-b border-yellow text-yellow'} border-b border-transparent cursor-pointer`}>Liquidity</span>
                        <span onClick={() => handleChangeTab('volume')} className={`${activeTab === 'volume' && 'border-b border-yellow text-yellow'} border-b border-transparent cursor-pointer`}>Volume</span>
                    </div>
                    {activeTab === 'candleStick' && <div    className="">
                         <div className="flex items-center sm:justify-start justify-center outline-none px-10 mb-8 mt-7 gap-x-3">
                            <span onClick={() => setPeriod(300)} className={`${period === 300 ? 'bg-lightYellow text-black' : 'text-description bg-darkGray ' } text-base font-normal py-1 px-1 sm:px-3 rounded cursor-pointer`}>5m</span>
                            <span onClick={() => setPeriod(1800)} className={`${period === 1800 ? 'bg-lightYellow text-black' : 'text-description bg-darkGray ' } text-base font-normal py-1 px-1 sm:px-3 rounded cursor-pointer`}>30m</span>
                            <span onClick={() => setPeriod(3600)} className={`${period === 3600 ? 'bg-lightYellow text-black' : 'text-description bg-darkGray ' } text-base font-normal py-1 px-1 sm:px-3 rounded cursor-pointer`}>1h</span>
                            <span onClick={() => setPeriod(14400)} className={`${period === 14400 ? 'bg-lightYellow text-black' : 'text-description bg-darkGray ' } text-base font-normal py-1 px-1 sm:px-3 rounded cursor-pointer`}>4h</span>
                            <span onClick={() => setPeriod(86400)} className={`${period === 86400 ? 'bg-lightYellow text-black' : 'text-description bg-darkGray ' } text-base font-normal py-1 px-1 sm:px-3 rounded cursor-pointer`}>1d</span>
                            <span onClick={() => setPeriod(604800)} className={`${period === 604800 ? 'bg-lightYellow text-black' : 'text-description bg-darkGray ' } text-base font-normal py-1 px-1 sm:px-3 rounded cursor-pointer`}>1w</span>
                        </div>
                    </div>}
                </div>
            </div>
            
        </div>
    )
}
