import useWalletSelector from 'hooks/useWalletSelector';
import React from 'react';
import { useDispatch } from 'react-redux';
// import AccountReducer from 'store/reducers/account';
import { accountActionCreator } from 'store/reducers/account/action-creators';
// import walletReducer from 'store/reducers/wallet';
import { WalletConnectActionCreator } from 'store/reducers/wallet/action-creators';

const NotCorrectChain = () => {
  const dispatch = useDispatch();
  const { notCorrectChain } = useWalletSelector(state => state.WalletReducer);
  const handlOut = () => {
    dispatch(accountActionCreator.accountCreated(false));
    dispatch(WalletConnectActionCreator.setWallet(false));
    dispatch(WalletConnectActionCreator.setNotCorrectChain(false));
  };
  return (
    <>
      {notCorrectChain && (
        <div
          style={{zIndex: 10000}}
          className="fixed inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-lightBlack rounded-4xl text-left relative z-50 overflow-hidden shadow-xl transform transition-all sm:my-7 sm:align-middle sm:max-w-sm sm:w-full">
              <div className=" bg-lightBlack px-4 pt-5 pb-4 sm:p-8 sm:pb-4">
                <div className="sm:flex sm:items-start flex-col">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-title"
                      id="modal-title"
                    >
                      Your wallet is connected <br /> to not correct chain
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-description">
                        Please check it...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-lightBlack px-4 py-6 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center 
                                        rounded-md border border-transparent shadow-sm px-6 py-2 
                                        bg-button text-base font-medium text-mainBlack
                                        hover:bg-black-700 focus:outline-none focus:ring-2 
                                        focus:ring-offset-2 
                                        focus:ring-red-500 
                                        sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handlOut}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NotCorrectChain;
