import { BarData, LineData, WhitespaceData } from "lightweight-charts"

export type GraphicState = {
  liquidityGraphic: LineData[],
  volumeGraphic: LineData[],
  candleGraphic: BarData[],
  transactions: {
    transactions: TransactionElement[]
    pageCount: number,
    currentPage: number
  },
  tradingPairs: PairInfo[],
  analyticsInfo: AnalyticsInfo,
  pairsPageCount: number | null,
  pairInfo: PagePairInfo,
}

export type PagePairInfo = {
  pooledTokensAmount: {
    firstToken: number,
    secondToken: number
  },
  price: {
    firstToken: number,
    secondToken: number,
    trx: number
  },
  totalLiquidity: PagePairInfoElement,
  transactions: PagePairInfoElement,
  volume: PagePairInfoElement,
}

export type PagePairInfoElement = {
  amount: number,
  percents: number
}

export type CandleChart = {
  close: number,
  high: number,
  low: number,
  open: number,
  time: number,
}

export enum GraphicEnum {
  GRAPHIC_SET_LIQUIDITY = 'GRAPHIC_SET_LIQUIDITY',
  GRAPHIC_SET_VOLUME = 'GRAPHIC_SET_VOLUME',
  GRAPHIC_SET_ANALYTICS_INFO = 'GRAPHIC_SET_ANALYTICS_INFO',
  GRAPHIC_SET_TRANSACTIONS = 'GRAPHIC_SET_TRANSACTIONS',
  GRAPHIC_SET_PAIRS = 'GRAPHIC_SET_PAIRS',
  GRAPHIC_SET_TRANSACTIONS_PAGES_COUNT = 'GRAPHIC_SET_TRANSACTIONS_PAGES_COUNT',
  GRAPHIC_SET_PAIRS_PAGES_COUNT = 'GRAPHIC_SET_PAIRS_PAGES_COUNT',
  GRAPHIC_SET_CANDLE = 'GRAPHIC_SET_CANDLE',
  GRAPHIC_SET_PAIR_PAGE_INFO = 'GRAPHIC_SET_PAIR_PAGE_INFO',
  GRAPHIC_RESET_ALL_GRAPHICS = 'GRAPHIC_RESET_ALL_GRAPHICS'
}

export type AnalyticsInfo = {
  trxPrice: number | null,
  transactions: number | null,
  pairs: number | null,
  newPairs: number | null,
}

export type PairInfo = {
  firstToken: string,
  secondToken: string,
  liquidity: number,
  volume24: number,
  volume7: number,
  fees: number
}

export type TransactionElement = {
  action: string
  firstToken: {
    name: string,
    amount: number
  },
  secondToken: {
    name: string,
    amount: number
  },
  totalValue: number,
  account: string,
  time: number,
  trxId: string
}

export interface setLiquidityGraphic {
  type: GraphicEnum.GRAPHIC_SET_LIQUIDITY;
  payload: LineData[];
}
export interface setVolumeGraphic {
  type: GraphicEnum.GRAPHIC_SET_VOLUME;
  payload: LineData[];
}
export interface setCandleGraphic {
  type: GraphicEnum.GRAPHIC_SET_CANDLE;
  payload: BarData[];
}
export interface setAnalyticsInfo {
  type: GraphicEnum.GRAPHIC_SET_ANALYTICS_INFO;
  payload: AnalyticsInfo;
}
export interface setTransactions {
  type: GraphicEnum.GRAPHIC_SET_TRANSACTIONS;
  payload: {
    transactions: TransactionElement[]
    pageCount: number,
    currentPage: number
  };
}

export interface setPairsInfo {
  type: GraphicEnum.GRAPHIC_SET_PAIRS;
  payload: PairInfo[];
}

export interface setPairsPagesCount {
  type: GraphicEnum.GRAPHIC_SET_PAIRS_PAGES_COUNT;
  payload: number;
}

export interface setPairPageInfo {
  type: GraphicEnum.GRAPHIC_SET_PAIR_PAGE_INFO;
  payload: PagePairInfo
}

export interface resetAllGraphics {
  type: GraphicEnum.GRAPHIC_RESET_ALL_GRAPHICS;
}

export type GraphicAction =
  | setLiquidityGraphic
  | setVolumeGraphic
  | setAnalyticsInfo
  | setTransactions
  | setPairsInfo
  | setPairsPagesCount
  | setCandleGraphic
  | setPairPageInfo
  | resetAllGraphics;
