import React, { FunctionComponent, useState } from 'react';
import Icons from './assets/icons.png';
import { ReactComponent as Info } from './assets/info.svg';
import { ReactComponent as Calc } from './assets/calc.svg';
import { Arrow } from 'components/Icons';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';
import { coinIcons } from 'components/CoinIcons';
import Config from 'config'
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { addLiquidityActionCreator } from 'store/reducers/liquidityAdd/action-creator';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import useWalletSelector from 'hooks/useWalletSelector';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';
import { formatNumber } from 'utils/formatNumber';

type Props = {
  token0: string,
  token1: string,
  poolType: number,
  apr: number,
  totalLiquidity: number,
  personalEarn: number | string,
  lpStaked: number | string,
  lpToken: string,
  usdPerLp: number,
  regExp: string,
  textFilter: string,
  tokenOutInfo: any,
  tokenPerBlock: any
}

const FarmCard: FunctionComponent<Props> = ({token0, token1, poolType, apr, personalEarn, totalLiquidity, tokenPerBlock, lpStaked, lpToken, usdPerLp, regExp, tokenOutInfo, textFilter}) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory()
  const dispatch = useDispatch()
  const { isWallet } = useWalletSelector( state => state.WalletReducer)
  const name = `${getTokenInfoByAddress(token0)?.name.toUpperCase()}-${getTokenInfoByAddress(token1)?.name.toUpperCase()}`
  const nameRegExp = name.match(regExp)
  
  
  const handleAddExactPool = () => {
    // dispatch(LiquidityActionCreator.setLiquidityState('add'));
    dispatch(LiquidityActionCreator.setLiquidityMainState('add'));
    dispatch(addLiquidityActionCreator.getTokensReserves());
    dispatch(
      ApplicationAcionCreator.setFirstToken({
        address: token0,
        icon: getTokenInfoByAddress(token0).icon,
        symbol: getTokenInfoByAddress(token0).name,
        name: getTokenInfoByAddress(token0).name,
      }),
    );
    dispatch(
      ApplicationAcionCreator.setSecondToken({
        address: token1,
        icon: getTokenInfoByAddress(token1).icon,
        symbol: getTokenInfoByAddress(token1).name,
        name: getTokenInfoByAddress(token1).name,
      }),
    );
  }

  const highlightedText = () => {
    let match = 0
    let newName = name
    // console.log(nameRegExp);
    
    nameRegExp?.map((el, index) => {
      newName = newName.replace(el, '*')
    })
    let words = newName.split('').map(el => {
      if(el === '*' && nameRegExp) {
        return <span style={{color: 'black', background: 'yellow'}}>{nameRegExp[match]}</span>
      }
      else return <span>{el}</span>
    })

    return words
  }
  
  return (
    <div className="flex flex-col self-start p-4 sm:p-8 rounded-3xl bg-cardBg">
      <div className="flex justify-between">
        <div className="flex items-center">
          <img style={{zIndex: 1}} src={coinIcons[getTokenInfoByAddress(token0)?.icon]} alt="" className="w-12 h-12 -mr-4 " />
          <img src={coinIcons[getTokenInfoByAddress(token1)?.icon]} alt="" className="w-12 h-12" />
        </div>
        <div
          className="inline-flex self-start px-4 py-1 uppercase border rounded-lg text-yellow bg-yellow bg-opacity-15 border-yellow"
          style={{ top: 30, right: 30 }}
        >
          {poolType}
        </div>
      </div>
      <div className="flex items-center justify-between mt-5">
        <h2 className="text-2xl text-title">
          {highlightedText()}
        </h2>
        <Info />
      </div>
      <div className="flex items-center justify-between mt-5">
        <p className="text-lg text-title">APR:</p>
        <div className="flex items-center">
          <span className="mr-3 text-xl text-title">{apr ? (formatNumber( apr ) + '%') : 'calculating...'}</span>
          <Calc className='cursor-pointer' onClick={() => {
            dispatch(farmingActionCreator.setCalculatorState({firstTokenAddress: token0, secondTokenAddress: token1, lpToken}))
            dispatch(farmingActionCreator.setFarmingState('calculator'))
          }} />
        </div>
      </div>
      <div className="flex items-center justify-between mt-5">
        <p className="text-lg text-title">token per block:</p>
        <span className="text-xl font-semibold text-title">{tokenPerBlock}</span>
      </div>
      <div className="flex items-center justify-between mt-5">
        <p className="text-lg text-title">Earn:</p>
        <a href={Config().TRONSCAN + '/#/contract/' + tokenOutInfo.address || ''} target={'_blank'} className="text-xl font-semibold text-yellow">{tokenOutInfo.name?.toUpperCase() || 'unknown'}</a>
      </div>
      <div className="flex flex-col justify-between my-5">
        <p className="text-lg text-title">Total liquidity:</p>
        <span className="text-xl font-semibold text-title">
          $ {formatNumber((totalLiquidity * usdPerLp).toFixed(2)) }
        </span>
      </div>
      {isWallet ? <>
      <div>
        {!!lpStaked && <>
          <p className="text-lg text-title">
			{getTokenInfoByAddress(token0)?.name.toUpperCase()}-{getTokenInfoByAddress(token1)?.name.toUpperCase()} LP staked: 
			<br /> {formatNumber(lpStaked)}  ($ {formatNumber((+lpStaked * usdPerLp).toFixed(2))})
			</p>
          <p className="text-lg text-title">Part in pool: {(+lpStaked / totalLiquidity * 100).toFixed(2) }%</p>
        </>}
          <button onClick={() => {
              dispatch(farmingActionCreator.setFarmingTokens(token0, token1))
              dispatch(farmingActionCreator.setLpTokenAddress(lpToken))
              dispatch(farmingActionCreator.setFarmingState('deposit'))
            }} className="w-full px-4 py-2 mt-2 text-lg tracking-wide text-black rounded-lg bg-yellow">
            Farming
          </button>
      </div>
      <div className="flex justify-center mt-5">
        <button
          className="flex items-center text-lg tracking-widest uppercase text-yellow"
          onClick={() => setIsOpen(!isOpen)}
        >
          More
          <Arrow
            className={`ml-2 transform w-4 h-4 ${
              isOpen ? 'rotate-90' : '-rotate-90'
            }`}
          />
        </button>
      </div>
      {isOpen ? (
        <div>
          <div className="flex items-center justify-between mt-5">
            <div>
              <p className="text-lg text-title">{tokenOutInfo.name?.toUpperCase()} earned:</p>
              <span className="text-xl font-semibold" style={{ color: '#F6E894' }}>
                {formatNumber( personalEarn )}
              </span>
            </div>
            <button disabled={!personalEarn} onClick={() => {
              dispatch(farmingActionCreator.getCurrentBlock())
              dispatch(farmingActionCreator.setWithdrawAmount(+personalEarn))
              dispatch(farmingActionCreator.getWithdraw(lpToken, 0))
            }} className={`py-2 px-4 rounded-lg ${ !!personalEarn ? 'bg-yellow' : 'bg-input'} text-black`}>
              Claim
            </button>
          </div>
          <div className="flex justify-center mt-5">
            <a
              href={`${Config().TRONSCAN}/#/contract/${lpToken}`}
              target='_blank'
              className="flex items-center text-lg tracking-widest uppercase text-yellow"
              onClick={() => setIsOpen(!isOpen)}
            >
              View contract
            </a>
          </div>
          <button onClick={() => {
            handleAddExactPool()
            history.push('/swap/liquidity')
          }} className="w-full px-4 py-2 mt-2 text-xl tracking-wide text-black rounded-lg bg-yellow">
            Get {getTokenInfoByAddress(token0)?.name.toUpperCase()}-{getTokenInfoByAddress(token1)?.name.toUpperCase()} LP
          </button>
        </div>
      ) : null}
      </> : <ConnectWalletButton />}
    </div>
  );
};

export default FarmCard;
