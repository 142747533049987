import React, { FunctionComponent } from 'react';
import Picture from '../assets/nftPict.svg';

type Props = {
  accountId: string;
  handlSelect: any;
};

const AccountItem: FunctionComponent<Props> = ({ accountId, handlSelect }) => {
  return (
    <div
      id={accountId}
      className="flex items-center mb-5 cursor-pointer"
      key={accountId}
      onClick={e => handlSelect(e)}
    > 
      <img src={Picture} alt="" className="w-20 lg:w-auto" />
      <div className="flex flex-col ml-5">
        <h2 className="text-xl lg:text-3xl text-description">Exon NFT</h2>
        <span className="text-xs lg:text-xl text-description mt-2">
          ID: {accountId}
        </span>
      </div>
    </div>
  );
};

export default AccountItem;
