import {AppDispatch, getRootState} from "store";
import {LaunchpoolAction, LaunchpoolEnum, LaunchpoolInfo, LaunchpoolJoinDetails, LaunchpoolModalState} from "./types"; 
import Launchpool from 'contracts/Launchpool.json'
import LaunchpoolFactory from 'contracts/LaunchpoolFactory.json'
import Price from 'contracts/Price.json'
import Config from 'config/index'
import { ApplicationAcionCreator } from "../application/action-creator";
import ITRX20 from "../../../contracts/ITRC20.json";
import { getTransactionResult } from "utils/transaction/getTransactionResult";
// import sleep from "utils/sleep";
// import launchpoolReducer from ".";
// import { useAppDispatch } from "hooks/reduxAppDispatch";
// import { accountActionCreator } from "../account/action-creators";
// import parseResult from "utils/parseResult";
// import initTronstack from "utils/tronWeb/initTronstack";
import { getTransactionBuilder } from "utils/getTransactionBuilder";
import initExonNode from "utils/tronWeb/initExonNode";
import { getPoolInfo, getLPTokenByAddress } from "api/api";
import poolCheckId from "contracts/PoolIdCheck.json";


export const launchpoolActionCreator = {
  setLaunchpoolState: (lauchpoolState: LaunchpoolModalState): LaunchpoolAction => ({
    type: LaunchpoolEnum.SET_LAUNCHPOOL_STATE,
    payload: lauchpoolState 
  }),
  setLaunchpools: (pools: LaunchpoolInfo[]): LaunchpoolAction => ({
    type: LaunchpoolEnum.SET_LAUNCHPOOLS,
    payload: pools
  }),
  setJoinDetails: (joinDetails: LaunchpoolJoinDetails): LaunchpoolAction => ({
    type: LaunchpoolEnum.SET_JOIN_DETAILS,
    payload: joinDetails
  }),
  setCurrentPool: (launchpool: LaunchpoolInfo, poolId: number): LaunchpoolAction => ({
    type: LaunchpoolEnum.SET_ACTIVE_POOL,
    payload: {activePool: launchpool, activePoolId: poolId} 
  }),
  showStakedOnly: (): LaunchpoolAction => ({
    type: LaunchpoolEnum.SET_SHOW_STAKED_ONLY,
  }),
  setActivePool: (poolId: number) => async (dispatch: AppDispatch, store: getRootState) => {
    let pools = store().launchpoolReducer.pools
    let currentPool = pools.filter((el: any) => el.poolId === poolId)[0]
    currentPool = {...currentPool, userInfo: {...currentPool?.userInfo}}
    dispatch(launchpoolActionCreator.setCurrentPool(currentPool, poolId))
  },
  getLaunchpools:
    () => async (dispatch: AppDispatch, store: getRootState) => {
    const tronWeb = await initExonNode()
    const tronWebWallet = store().WalletReducer.tronWeb;
    const currentAccount = store().AccountReducer.currentAccount
    if(!tronWeb) return

    // const launchpoolFactoryContract = await tronWeb.contract(
    //   LaunchpoolFactory.abi,
    //   Config().LAUNCHPOOL_FACTORY_CONTRACT
    // )
    let pools = []
    let poolArrayLength

    poolArrayLength =  await getTransactionBuilder(Config().LAUNCHPOOL_FACTORY_CONTRACT, 'PoolId()', [])
        
      poolArrayLength = poolArrayLength[0].toNumber()
        
    let poolsInfo 
    try {
      poolsInfo = await getPoolInfo()
      poolsInfo = poolsInfo.data
    } catch (error) {
      console.log(error);
    }
    
    for (let i = 1; i <= poolArrayLength; i++) {
      let currentPool = await getTransactionBuilder(Config().LAUNCHPOOL_FACTORY_CONTRACT, 'PoolById()', [i])
      // const poolContract: any = await tronWeb.contract(
      //   Launchpool.abi,
      //   currentPool[0]
      // )
      // const priceContract = await tronWeb.contract (
      //   Price.abi,
      //   Config().ORACLE_LIQUIDITY_AND_SWAP
      // )

      let poolInfo
      let poolData
      try {
        //poolInfo = await poolContract.getLPStorage().call()
        
        if (currentAccount) {
           poolData = await getTransactionBuilder(currentPool[0], 'getLPAllData()', [currentAccount])

          //  await poolContract.getLPAllData(currentAccount).call()
        } else {
           poolData = await getTransactionBuilder(currentPool[0], 'getLPAllData()', [0]) 
          //  poolData = await poolContract.getLPAllData(0).call()
        }
        poolInfo = poolData[0]
        //console.log(poolInfo);
      } catch (error) {
        console.log(error);
      }

      
      let poolAccessById = false;
      if (currentAccount) {
        
        const checkIdAddress = tronWebWallet.address.fromHex(poolInfo.checkAddress)
        if(poolInfo.projectName === 'Exon Center Earth') console.log('CHECK ID ADDRESS',checkIdAddress);
        
        const poolCheckContract = await tronWebWallet.contract(poolCheckId.abi, checkIdAddress)
        try {
          poolAccessById = await poolCheckContract.checkTokenId(currentAccount).call()

        } catch(e) {
          console.log('REsult check by id', e)
        }

      }
      
      if(!poolInfo) return
      if(poolInfo.length !== 27) poolInfo = poolInfo[0]
      let tokenIn
      let tokenInSymbol
      let tokenOut
      let minIn
      let maxIn
      let start
      let freeze
      let end
      let tokenOutShare
      let tokenOutRandom
      let totalStakeShare
      let totalStakeRandom
      let tokenUsersOutShare
      let tokenUsersOutRandom
      let randomShareBoth: number
      let projectName
      let projectImage
      let poolCap
      let tokenPrice
      let tokenInPrice
      let randomStaked: any
      let randomWithdrawed
      let shareStaked: any
      let shareWithdrawed
      let uplinerPercent
      let winners
      let resultWinners: any = []
      let reward
      let referralReward
      let participants
      let stakedValue
      // let poolName
      // let poolDescription
      // let websiteLink
      // let image
      // let poolAddress
      // let socialMedia

      if (poolInfo) {
        
      uplinerPercent = poolInfo.uplinerPercent.toNumber()

      tokenIn = tronWeb.address.fromHex(poolInfo.tokenIn)
      // get token symbol from strapi
      try {
        let TokenInfo = await getLPTokenByAddress(tokenIn)
        if (TokenInfo?.data.length > 0) {
          tokenInSymbol = TokenInfo.data[0].symbol
        }
      } catch(e) {
        console.log(e)
      }
        tokenOut = tronWeb.address.fromHex(poolInfo.tokenOut)
      }
      try {
        tokenPrice = await getTransactionBuilder(
          Config().ORACLE_LIQUIDITY_AND_SWAP, 
          'getTokenPriceInUSD()', 
          [tokenOut]
          )
        //  await priceContract.getTokenPriceInUSD(tokenOut).call()
        tokenPrice = tokenPrice[0].toNumber()
        tokenPrice = +await tronWeb.fromSun(tokenPrice)
      }catch (error) {
        console.log(error);
      }

      try {
        tokenInPrice = await getTransactionBuilder(
          Config().ORACLE_LIQUIDITY_AND_SWAP, 
          'getTokenPriceInUSD()', 
          [tokenOut]
          )
        // await priceContract.getTokenPriceInUSD(tokenIn).call()
        tokenInPrice = tokenInPrice[0].toNumber()
        tokenInPrice = +await tronWeb.fromSun(tokenInPrice)
      }catch (error) {
        console.log(error);
      }

      winners = poolInfo.winners.toNumber() 

      tokenUsersOutShare = poolInfo.tokenUsersOutShare.toNumber()
      tokenUsersOutShare = +tronWeb.fromSun(tokenUsersOutShare)

      tokenUsersOutRandom = poolInfo.tokenUsersOutRandom.toNumber()
      tokenUsersOutRandom = +tronWeb.fromSun(tokenUsersOutRandom)

      resultWinners = poolInfo.resultWinners      
      resultWinners = poolInfo.resultWinners.map((el: any) => el.toNumber())

      const removeDuplicates = () => {
        let arr = resultWinners.filter(function (value: any , index: any, array: any) {
          return array.indexOf(value) === index;
        });
        return arr
      }

      minIn = poolInfo.minIn.toNumber()
      minIn = +tronWeb.fromSun(minIn)

      maxIn = poolInfo.maxIn.toNumber()
      maxIn = +tronWeb.fromSun(maxIn)

      start = poolInfo.start.toNumber()

      freeze = poolInfo.freeze.toNumber()

      end = poolInfo.end.toNumber()

      tokenOutShare = poolInfo.tokenOutShare.toNumber()
      tokenOutShare = +tronWeb.fromSun(tokenOutShare)

      poolCap = poolInfo.poolCap.toNumber()
      poolCap = +tronWeb.fromSun(poolCap)

      tokenOutRandom = poolInfo.tokenOutRandom.toNumber()
      tokenOutRandom = +tronWeb.fromSun(tokenOutRandom)

      totalStakeShare = poolInfo.totalStakeShare.toNumber()
      totalStakeShare = +tronWeb.fromSun(totalStakeShare)

      totalStakeRandom = poolInfo.totalStakeRandom.toNumber()
      totalStakeRandom = +tronWeb.fromSun(totalStakeRandom)

      randomShareBoth = +poolInfo.randomShareBoth.toNumber()

      projectName = poolInfo.projectName

      projectImage = poolInfo.projectImage

      try {
        shareStaked = await getTransactionBuilder(currentPool[0], 'UsersShare()', [currentAccount])
        //= poolData[1]
        
        shareWithdrawed = shareStaked[0].amountWithdrawed.toNumber()
        shareWithdrawed = +tronWeb.fromSun(shareWithdrawed)
        shareStaked = shareStaked[0].amountStake.toNumber()
        shareStaked = +tronWeb.fromSun(shareStaked)
      } catch (error) {
        console.log(error);
      }

      try {
        randomStaked = await getTransactionBuilder(currentPool[0], 'UsersRandom()', [currentAccount])
        // = poolData[2]
        randomWithdrawed = randomStaked[0].amountWithdrawed.toNumber()
        randomWithdrawed = +tronWeb.fromSun(randomWithdrawed)
        randomStaked = randomStaked[0].amountStake.toNumber()
        randomStaked = +tronWeb.fromSun(randomStaked)
      } catch (error) {
        console.log(error);
      }

      const defineStakedAmount = () => {
        
        let poolType = randomShareBoth
        if((poolType === 0) || (poolType === 3)) {
            return randomStaked
        }else if(poolType === 1){
            return shareStaked
        }else if(poolType === 2){
            return randomStaked + shareStaked
        }else return 0
      }

      stakedValue = defineStakedAmount()
      try {
        let shareReward

        if(!!resultWinners.length){
          if(randomShareBoth === 3){
            shareReward = await getTransactionBuilder(currentPool[0], 'calculatePartRandom()', [currentAccount])
            // await poolContract.calculatePartRandom(currentAccount).call()
            if(resultWinners.indexOf(currentAccount) !== -1 ){
              let repeats = 0
              for (let i = 0; i < resultWinners.length; i++) {
                if(currentAccount === resultWinners[i]) repeats += 1
              }
              reward = (tokenUsersOutRandom / resultWinners.length) * repeats
            }else {
              reward = poolData[3]
              reward = reward.toNumber()
              reward = +tronWeb.fromSun(reward)
            }
            shareReward = shareReward[0].toNumber()
            shareReward = +tronWeb.fromSun(shareReward)
            reward = reward + shareReward
          }else if(resultWinners.indexOf(currentAccount) !== -1 ) {
            let repeats = 0
            for (let i = 0; i < resultWinners.length; i++) {
              if(currentAccount === resultWinners[i]) repeats += 1
            }
            reward = (tokenUsersOutRandom / resultWinners.length) * repeats

          }else reward = 0
        }else {
          reward =  await getTransactionBuilder(currentPool[0], 'calculatePart()', [currentAccount]) 
          // await poolContract.calculatePart(currentAccount).call()
          reward = reward[0].toNumber()
          reward = +tronWeb.fromSun(reward)
        }
        reward = +reward.toFixed(2)
      } catch (error) {
        console.log(error);
        reward = 0
      }
      resultWinners = removeDuplicates()
      try {
        referralReward = await getTransactionBuilder(currentPool[0], 'UplinersTransfer()', [currentAccount])
        // await poolContract.UplinersTransfer(currentAccount).call()
        referralReward = referralReward[0].toNumber()
        referralReward = tronWeb.fromSun(referralReward)
      } catch (error) {
        console.log(error);
        referralReward = 0
      }

      participants = poolData[0].UserIdRandom.toNumber() + poolData[0].UserIdShare.toNumber()

      let fromHexAddress = await tronWeb.address.fromHex(currentPool[0])

      let currentInfo :any
      poolsInfo
        .map((el: { pool_addresses: any[]; }) => el.pool_addresses
        .map(address => {
            if(address.Address === fromHexAddress) return currentInfo = el
            else return false
        }))

      
      let currentSocialMedia
      let poolDescriptionInfo
      if(currentInfo) {
        currentSocialMedia = currentInfo?.social_types?.map((el: { name: any; link: any; Poolname: any; sequences: any; }) => {
          return {
            name: el.name.split('_')[0],
            link: el.link,
            PoolName: el.Poolname,
            sequences: el.sequences
          }
        })
        currentSocialMedia.sort((a: { sequences: number; }, b: { sequences: number; }) => a.sequences - b.sequences)

        poolDescriptionInfo = {
          poolTitle: currentInfo.PoolTitle,
          poolName: currentInfo.PoolName,
          poolDescription: currentInfo.Description,
          websiteLink: currentInfo.websiteLink,
          image: 'https://content.exoncenter.com'+currentInfo.Image[0].url,
          socialMedia: currentSocialMedia
        }
      }
      
      if(fromHexAddress === 'TXQmJyQUr6nUzHifmtZWDZgAoLpDbsU8MU') tokenPrice = 0.01
      
      currentPool = {
        poolAccess: poolAccessById,
        poolFactoryAddress:'', 
        poolAddress: fromHexAddress,
        poolId: i,
        projectName: poolDescriptionInfo?.poolTitle || projectName,
        projectImage: poolDescriptionInfo?.image || projectImage,
        tokenInSymbol,
        tokenIn,
        tokenOut,
        tokenPrice,
        tokenInPrice,
        minIn,
        maxIn,
        start,
        freeze,
        end,
        uplinerPercent,
        tokenUsersOutShare,
        tokenUsersOutRandom,
        tokenOutShare,
        tokenOutRandom,
        totalStakeShare,
        totalStakeRandom,
        randomShareBoth,
        poolCap,
        winners,
        participants,
        resultWinners,
        userInfo: {
          randomWithdrawed,
          stakedValue,
          shareWithdrawed,
          reward,
          referralReward
        },
        poolInfo: poolDescriptionInfo
      }
      pools.push(currentPool)

    }
    // get last element
    let poolReversed = pools.reverse()
    dispatch(launchpoolActionCreator.setLaunchpools(poolReversed))
    const poolId = store().launchpoolReducer.activePoolId
    if (poolId) {
      let currentPool = pools.filter((el: any) => el.poolId === poolId)[0]
      currentPool = {...currentPool,poolAddress: currentPool.poolAddress,userInfo: {...currentPool?.userInfo}}
      dispatch(launchpoolActionCreator.setCurrentPool(currentPool, poolId))
     }
  },
  claimWithdraw: () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = store().WalletReducer.tronWeb;
      const currentPool = store().launchpoolReducer.activePool
      const tokenId = store().AccountReducer.currentAccount
      dispatch(launchpoolActionCreator.setLaunchpoolState('claimTokens'));
      dispatch(ApplicationAcionCreator.setTransactionSteps());
      if (!currentPool || !tokenId) return
      const LPContract = await tronWeb.contract(
        Launchpool.abi,
        currentPool.poolAddress
      )
      let claimWithdrawTrx
      dispatch(
          ApplicationAcionCreator.setTransactionStepStatus(
              `Signature confirming`,
              true,
              false
          )
      );
      try {
        claimWithdrawTrx = await LPContract.withdraw(tokenId).send({feeLimit: 100000000})
      } catch(error: any) {
            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "LAUNCHPOOL",
                    "Error",
                    claimWithdrawTrx,
                    `${error.message}`
                )
            );
            dispatch(launchpoolActionCreator.setLaunchpoolState("initial"));
            return;
      }
      dispatch(
          ApplicationAcionCreator.setTransactionStepStatus(
              `Signature confirming`,
              false,
              true
          )
      );
      dispatch(
          ApplicationAcionCreator.setTransactionStepStatus(
              `Finished`,
              true,
              false
          )
      );
      if (await getTransactionResult(claimWithdrawTrx, tronWeb)) {
          dispatch(
              ApplicationAcionCreator.setNotificationStatus(
                  true,
                  true,
                  "LAUNCHPOOL",
                  "Error",
                  claimWithdrawTrx,
                  `Unexpected error`
              )
          );
          dispatch(launchpoolActionCreator.setLaunchpoolState("initial"));
          return;
      }
      dispatch(
          ApplicationAcionCreator.setNotificationStatus(
              true,
              true,
              "Approve",
              "Success",
              claimWithdrawTrx,
              `Withdraw stake tokens and reward`
          )
      );

      dispatch(
          ApplicationAcionCreator.setTransactionStepStatus(
              `Finished`,
              false,
              true 
          )
      );

  },
  joinToPool:
    (_amount: number, poolType: number) => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = store().WalletReducer.tronWeb;
      const currentPool = store().launchpoolReducer.activePool
      const tokenId = store().AccountReducer.currentAccount
      // const currentAccount = store().AccountReducer.currentAccount
      const currentAddress = store().WalletReducer.address
      if (!currentPool || !tronWeb || !tokenId) return

      const LPContract = await tronWeb.contract(
        Launchpool.abi,
        currentPool.poolAddress
      )
      const tokenInContract = await tronWeb.contract(
        ITRX20.abi,
        currentPool.tokenIn
      )

      let amount = tronWeb.toSun(_amount)
      let approveTrx;
      let balanceTokenIn = await tokenInContract.balanceOf(currentAddress).call()
      if (balanceTokenIn.toNumber() < parseInt(amount)) {
            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "LAUNCHPOOL",
                    "Error",
                    '',
                    `Balance -- insufficient amount`
                )
            );
            dispatch(launchpoolActionCreator.setLaunchpoolState("initial"));
            return;
      }
      dispatch(launchpoolActionCreator.setLaunchpoolState('joinToPool'));
      dispatch(ApplicationAcionCreator.setTransactionSteps([`Approve join token`]));

      dispatch(
          ApplicationAcionCreator.setTransactionStepStatus(
              `Approve join token`,
              true,
              false
          )
      );

      try {
        approveTrx = await tokenInContract.approve(currentPool.poolAddress, amount).send()
      } catch(error: any) {
            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "LAUNCHPOOL",
                    "Error",
                    approveTrx,
                    `${error.message}`
                )
            );
            dispatch(launchpoolActionCreator.setLaunchpoolState("initial"));
            return;

      }
        if (await getTransactionResult(approveTrx, tronWeb)) {
            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "LAUNCHPOOL",
                    "Error",
                    approveTrx,
                    `Unexpected error`
                )
            );
            dispatch(launchpoolActionCreator.setLaunchpoolState("initial"));
            return;
        }
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Approve join token`,
                    false,
                    true
                )
            );
            dispatch(
                ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "Approve",
                    "Success",
                    approveTrx,
                    `Approve stake token`
                )
            );

            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Signature confirming`,
                    true,
                    false
                )
            );
        let joinToPoolTrx;
        try {
          joinToPoolTrx = await LPContract.joinToPool(amount, poolType, tokenId).send({feeLimit: 100000000 })
        } catch (error: any) {
          console.log(error);
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            true,
                            true,
                            "LAUNCHPOOL",
                            "Error",
                            joinToPoolTrx,
                            `${error.message}`
                        )
                    );
                    dispatch(launchpoolActionCreator.setLaunchpoolState("initial"));
                    return;
        }
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Signature confirming`,
                    false,
                    true 
                )
            );
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Finished`,
                    true,
                    false
                )
            );
                if (await getTransactionResult(joinToPoolTrx, tronWeb)) {
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                      ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "LaunchPool",
                        "Error",
                        joinToPoolTrx,
                        `An error occured during join to pool`
                      )
                    );
                    dispatch(launchpoolActionCreator.setLaunchpoolState("initial"));
                    return;
                }
                dispatch(
                  ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "LaunchPool",
                    "Success",
                    joinToPoolTrx,
                    `Success join to pool`
                  )
                );
                
                //dispatch(accountActionCreator.getActiveLaunchPool())

                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Finished`,
                        false,
                        true
                    )
                );
  },
  joinToPoolTrx:
    (amount: number, poolType: number) => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = store().WalletReducer.tronWeb;
      const currentPool = store().launchpoolReducer.activePool
      const tokenId = store().AccountReducer.currentAccount
      if (!currentPool || !tronWeb || !tokenId) return
      dispatch(launchpoolActionCreator.setLaunchpoolState('joinToPool'));
      dispatch(ApplicationAcionCreator.setTransactionSteps());

      const LPContract = await tronWeb.contract(
        Launchpool.abi,
        currentPool.poolAddress
      )
      const tokenInContract = await tronWeb.contract(
        ITRX20.abi,
        currentPool.tokenIn
      )

      amount = tronWeb.toSun(amount)
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Signature confirming`,
                    true,
                    false
                )
            );
        let joinToPoolTrx;
        try {
          joinToPoolTrx = await LPContract.joinToPoolTrx(poolType, tokenId).send({ feeLimit: 100000000, callValue: amount })
        } catch (error: any) {
          console.log(error);
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                        ApplicationAcionCreator.setNotificationStatus(
                            true,
                            true,
                            "LAUNCHPOOL",
                            "Error",
                            joinToPoolTrx,
                            `${error.message}`
                        )
                    );
                    dispatch(launchpoolActionCreator.setLaunchpoolState("initial"));
                    return;
        }
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Signature confirming`,
                    false,
                    true
                )
            );
            dispatch(
                ApplicationAcionCreator.setTransactionStepStatus(
                    `Finished`,
                    true,
                    false
                )
            );
                if (await getTransactionResult(joinToPoolTrx, tronWeb)) {
                    dispatch(ApplicationAcionCreator.setTransactionStatus(false));
                    dispatch(
                      ApplicationAcionCreator.setNotificationStatus(
                        true,
                        true,
                        "LaunchPool",
                        "Error",
                        joinToPoolTrx,
                        `An error occured during join to pool`
                      )
                    );
                    dispatch(launchpoolActionCreator.setLaunchpoolState("initial"));
                    return;
                }
                dispatch(
                  ApplicationAcionCreator.setNotificationStatus(
                    true,
                    true,
                    "LaunchPool",
                    "Success",
                    joinToPoolTrx,
                    `Success join to pool`
                  )
                );

                dispatch(
                    ApplicationAcionCreator.setTransactionStepStatus(
                        `Finished`,
                        false,
                        true
                    )
                );
                dispatch(launchpoolActionCreator.setLaunchpoolState("initial"));
  }
}
