import {SecondsContext} from "App"
import Button from "components/Button"
import ConnectWalletButton from "components/Button/ConnectWalletButton"
import {FunctionComponent, useState, useEffect, useContext} from "react"
import {multisendActionCreator} from "store/reducers/multisend/action-creator"
import {feeTypes, subscribeInfo, subscribePlans} from "store/reducers/multisend/types"
import subBg from '../assets/subBg.png'

type Props = {
	choosenSubscription: number,
	subscribePlans: subscribePlans[],
	dispatch: any,
	subscribePeriod: subscribeInfo | null,
	isWallet: any,
	feeTypes: feeTypes;
}

const Subscription: FunctionComponent<Props> = ({choosenSubscription, subscribePlans, dispatch, subscribePeriod, isWallet, feeTypes}) => {

		const [subTime, setSubTime] = useState({days: 0, time: ''})
		const { seconds } = useContext(SecondsContext)

		const planDescription = [
			['Popular service for money saving', 'Solution for Telegram channels', 'One step to make several donats', 'Fast and furious Tron transaction'],
			['Necessary tool for saving time', 'Easy way to make airdrop event', 'Interactive streaming activity', 'Lupashyt` sho lanos na gazy'],
			['Several clicks to pay salary', 'Best instrument for influencers and community', 'Metaverse multi user wallet drop', 'SpaceX rocket fly in crypto'],
		]

		const getFullTime = (subTime: number | null | undefined) => {
			if(!subTime) return {days: 0, time: ''}
			let currTimestamp = Math.floor( Date.now() / 1000 )
			subTime = subTime - currTimestamp 
			if (!subTime) return {days: 0, time: 'subscription expired'}
			let days = Math.floor(subTime/86400)
			subTime = subTime - days * 86400
			let hours
			let minutes: number | string = Math.floor(subTime/60)
			if(minutes >= 60){
					hours = Math.floor(minutes/60)
					minutes %= 60
			}
			if(minutes < 10){
					minutes = '0' + minutes
			}

				
			return { days: days, time: `${hours ? (hours + ':'): '00:' }${minutes}`}
		}

		useEffect(() => {
			setSubTime(getFullTime(subscribePeriod?.period))
		}, [seconds])

		return(
		<div className='flex-col items-center justify-between w-full p-4 text-center sm:p-8 xl:w-1/2 bg-cardBg rounded-2xl sm:rounded-4xl'>
			<h1 className='mt-4 mb-8 text-2xl font-medium sm:mt-0 sm:text-4xl text-description'>Subscription</h1>

			<div className='flex flex-col justify-between sm:flex-row gap-x-5'>			
			{
				subscribePlans.map((el, index) => {
					return <div className='w-full' onClick={() => !subscribePeriod && dispatch(multisendActionCreator.setChoosenSubscription(index))} >
						<div className={`relative cursor-pointer bg-black border rounded-2xl 
													${index === choosenSubscription ? 'border-yellow' : 'border-transparent' }`}>
							<img className='w-full h-56 xl:h-32 mxx:h-56 rounded-2xl' src={subBg} />
							<div className='absolute top-0 left-0 w-full '>
								{(!!subscribePeriod && subscribePeriod.id === index) 
									?	<>
											<h1 className='my-12 text-3xl font-medium xl:my-8 xl:text-xl mxx:text-3xl mxx:mt-14 mxx:mb-10 text-title'>Time <br/> left</h1>
											{ subTime.days ? <span className='text-title'>days {subTime.days}</span> : null}
											<br/>
											<span className='text-title'>time {subTime.time}</span>
										</>
									:	<>
											<h1 className='text-3xl font-medium my-14 xl:my-8 xl:text-xl mxx:text-3xl mxx:my-14 text-title'>{el.time} <br/> days</h1>
											<span className='text-title'>{el.price} {feeTypes.subscribeFee.isTrx ? 'TRX' : feeTypes.subscribeFee.tokenAddress.name}</span>
										</>
								}
							</div>
						</div>
						<ul className='relative w-10/12 mt-4 mb-12 text-sm text-left list-disc sm:my-12 xl:text-xs mxx:mt-8 mxx:mb-0 mxx:text-sm left-5 text-description'>
							{planDescription[index].map(li => <li className='mb-5'>{li}</li>)}
						</ul>
					</div>
				})
			}
			</div>
			{
				isWallet 
					? <Button 
							disabled={!!subscribePeriod}
							onClick={() => dispatch(multisendActionCreator.getSubscribe(choosenSubscription))} 
							className='mt-10'>
								Subscribe
						</Button>
					: <ConnectWalletButton />
			}
		</div>
	)

}
export default Subscription
