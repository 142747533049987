import React, { useState } from 'react';
// import { Helmet } from 'react-helmet-async';

import Layout from 'components/Layout';
import Partner from './components/Partner';
import Withdraw from './components/Withdraw';
import Turnover from './components/Turnover';
import NFTAccount from './components/NFTAccount/NFTAccount';
import Tabs from './components/Tabs';
import Info from './components/Info'; import TeamProfitPartners from './components/TeamProfitPartners'; import RemoveLiquidityConfirm from 'pages/Swap/components/SwapContainer/components/PoolProcess/components/RemoveLiquidity/componets/RemoveLiquidityConfirm';
import RemoveLiquidity from 'pages/Swap/components/SwapContainer/components/PoolProcess/components/RemoveLiquidity';
import useWalletSelector from 'hooks/useWalletSelector';
import WithdrawChoose from './components/Withdraw/WithdrawChoose/WithdrawChoose';
import s from './styles/Account.module.css'
import LevelBoost from './components/Tabs/components/LevelProgram/components/LevelBoost';

export function Account() {
  const { personalSwap, personalLiquidity, teamSwap, teamLiquidity, boostLevelChosen } = useWalletSelector(state => state.AccountReducer)
  const { liquidityState } = useWalletSelector(state => state.liquidityReducer);
  const [teamWithdrawChoose, setTeamWithdrawChoose] = useState(false)
  const [isEaster, setIsEaster] = useState(false)

  return (
    <>
      {/* <Helmet>
        <title>Home Page</title>
        <meta
          name="description"
          content="A React Boilerplate application homepage"
        />
      </Helmet> */}
      <Layout>
        <div className="container mt-6">
          <div className="flex flex-col justify-between sm:gap-20 sm:flex-row">
            <div className="w-full sm:w-6/12">
              <h1 className="text-5xl font-semibold sm:text-6xl xl:text-7xl text-lightGrey">
                My Exon  {isEaster ? <a 
                        onClick={() => setIsEaster(!isEaster)} 
                        className={s.nft__easter} 
                        data-text='NFT'
                        href='https://nft.exoncenter.com'
                        target='_blank'>
                        NFT
                </a> : <span className='cursor-pointer' onClick={() => setIsEaster(!isEaster)}>NFT</span> }
              </h1>
              <Partner />
              <NFTAccount />
              <Info />
            </div>
            <div className="flex flex-col w-full sm:w-6/12">
              <TeamProfitPartners />
              <Turnover personalSwap={personalSwap} personalLiquidity={personalLiquidity} dotColor="bg-yellow" title="Personal turnover" />
              <Turnover teamSwap={teamSwap} teamLiquidity={teamLiquidity} dotColor="bg-green" title="Total team turnover" />
              <Withdraw setTeamWithdrawChoose={setTeamWithdrawChoose} />
            </div>
          </div>
          <Tabs />
        </div>
      {liquidityState === 'remove' && <RemoveLiquidity />}
      <RemoveLiquidityConfirm />
      {!!boostLevelChosen && <LevelBoost />}
      { teamWithdrawChoose && <WithdrawChoose setTeamWithdrawChoose={setTeamWithdrawChoose} /> }
      </Layout>
    </>
  );
}
