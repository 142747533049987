import Button from 'components/Button';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import { Input } from 'components/Input/InputClear';
import { FunctionComponent, useEffect, useState } from 'react';
import { multisendActionCreator } from 'store/reducers/multisend/action-creator';
import { tokenInfo } from 'store/reducers/multisend/types';
import TrxCheckbox from './TrxCheckBox';

export type Props = {
  sendAddresses: string[];
  amountPerAddress: number;
  tokenAddress: tokenInfo;
  dispatch: any;
  isWallet: any;
};

const MultisendWindow: FunctionComponent<Props> = ({
  sendAddresses,
  tokenAddress,
  amountPerAddress,
  dispatch,
  isWallet,
}) => {
  const [addressRecipient, setAddressRecipient] = useState('');
  const [sendReady, setSendReady] = useState(false);
  const [trxSend, setTrxSend] = useState(false);
  let newAddresses: string[] = [];

  for (let i = 0; i < sendAddresses.length; i++) {
    newAddresses[i] = sendAddresses[i];
    newAddresses = newAddresses.reverse();
  }

  const isMobile = window.innerWidth < 640;

  useEffect(() => {
    if (
      (trxSend ? true : tokenAddress?.address) &&
      amountPerAddress &&
      sendAddresses.length
    )
      setSendReady(true);
    else setSendReady(false);
  }, [tokenAddress, amountPerAddress, sendAddresses]);

  const removeReceiverAddress: any = (address: string) => {
    dispatch(
      multisendActionCreator.setSendAddresses(
        sendAddresses.filter(el => el !== address),
      ),
    );
  };

  const dispatchAmountPerAddress = (amount: number) => {
    dispatch(multisendActionCreator.setAmountPerAddress(amount));
  };

  const dispatchTokenAddress = (address: string) => {
    if (trxSend) return;
    dispatch(multisendActionCreator.setTokenAddressInfo(address));
  };

  const setNewAddress = (address: string) => {
    if (address.length === 34) {
      !sendAddresses.filter(el => el === addressRecipient)[0] &&
        dispatch(
          multisendActionCreator.setSendAddresses([
            ...sendAddresses,
            addressRecipient,
          ]),
        );
      setAddressRecipient('');
    } else if (address.length > 34) {
      let newAddresses: any = address.replaceAll('	', ' ');
      newAddresses = newAddresses.split(' ');
      newAddresses = newAddresses.filter((el: any) => el !== '');
      dispatch(
        multisendActionCreator.setSendAddresses([
          ...sendAddresses,
          ...newAddresses,
        ]),
      );
      setAddressRecipient('');
    } else return;
  };

  return (
    <div className="flex flex-col justify-between w-full p-4 sm:p-8 xl:w-1/2 bg-cardBg rounded-2xl sm:rounded-4xl">
      <h1 className="mb-8 text-2xl font-medium text-center sm:text-4xl text-title">
        Multisend
      </h1>
      <TrxCheckbox trxSend={trxSend} setTrxSend={setTrxSend} />
      <div className="relative mt-4">
        <Input
					className="w-full pl-5 pr-16 mb-4 sm:mb-10 sm:pl-5 sm:pr-4"
          placeholder={'Token contract address'}
          value={trxSend ? 'TRX' : tokenAddress.address}
          onChange={dispatchTokenAddress}
        />
        {tokenAddress.name.length ? (
          <span
            style={{ transform: 'translate(-25%, 35%)' }}
            className="absolute top-0 right-0"
          >
            {trxSend ? '' : tokenAddress.name}
          </span>
        ) : null}
      </div>
      <Input
        placeholder={'Token amount'}
        className="w-full mb-4 sm:mb-10"
        value={amountPerAddress || ''}
        onChange={dispatchAmountPerAddress}
      />
      <div className="relative">
        <Input

          placeholder={'Address recipient'}
          className="w-full mb-4 sm:mb-10"
          value={addressRecipient}
          onChange={setAddressRecipient}
        />
        <div
          onClick={() => setNewAddress(addressRecipient)}
          className="absolute top-0 right-0 flex items-center justify-center w-10 h-10 rounded-tr-lg cursor-pointer bg-lightBlack"
        >
          <span
            style={{ transform: 'translate(0, -2px)' }}
            className="text-3xl font-medium select-none text-yellow"
          >
            +
          </span>
        </div>
      </div>
      <div
        className="overflow-x-hidden overflow-y-auto"
        style={{ height: '11.5rem' }}
      >
        <div
          style={{ gap: '4%' }}
          className="flex flex-wrap justify-between w-full "
        >
          {newAddresses.map(el => (
            <AddressBlock
              address={el}
              removeReceiverAddress={removeReceiverAddress}
            />
          ))}
        </div>
      </div>
      <div className="flex items-center justify-between mt-5 mb-8">
        <span>Transaction amount</span>
        <span>{sendAddresses.length}</span>
      </div>

      {isWallet ? (
        <div className="flex items-center justify-around">
          <button
            className={`bg-yellow text-yellow hover:border-yellow 
																	transition-all  bg-opacity-10  uppercase text-xs sm:text-base 
																	px-6 py-3 rounded-lg border border-transparent  border-opacity-60`}
          >
            Upload CSV
          </button>
          <Button
            onClick={() =>
              trxSend
                ? dispatch(multisendActionCreator.sendTokensToUsersTrx())
                : dispatch(multisendActionCreator.sendTokensToUsers())
            }
            className="px-6 py-3 text-xs sm:text-xl"
            disabled={!sendReady}
          >
            Send
          </Button>
        </div>
      ) : (
        <div className="text-center">
          <ConnectWalletButton />
        </div>
      )}
    </div>
  );
};

export type SubProps = {
  address: string;
  removeReceiverAddress: any;
};

const AddressBlock: FunctionComponent<SubProps> = ({
  address,
  removeReceiverAddress,
}) => {
  return (
    <div
      style={{ width: '48%' }}
      className="relative px-4 py-1 mb-5 rounded-xl bg-input"
    >
      <div className="pr-10 text-base mxx:text-xl text-title">
        {address.substring(0, 3) + '...' + address.substring(31, 34)}
      </div>
      <div
        onClick={() => removeReceiverAddress(address)}
        className="absolute top-0 right-0 flex items-center justify-center w-10 h-full cursor-pointer rounded-tr-xl rounded-br-xl bg-lightBlack"
      >
        <span
          style={{ transform: 'rotate(45deg) translate(0px , -2px)' }}
          className="text-3xl font-medium select-none text-yellow"
        >
          +
        </span>
      </div>
    </div>
  );
};

export default MultisendWindow;
