import Button from "components/Button"
import {FunctionComponent, useEffect, useState} from "react"
import {feeTypes} from "store/reducers/multisend/types"

export type Props = {
	oneAddressPrice: number;
	feeTypes: feeTypes;
}

const FeeCalculator: FunctionComponent<Props> = ({oneAddressPrice, feeTypes}) => {

	const [feeAmount, setFeeAmount] = useState(0)
	const [addressesAmount, setAddressesAmount] = useState(0)

	 const showResult = () => {
		setFeeAmount(addressesAmount * oneAddressPrice)
	}

	return (
		<div className='w-full px-4 py-5 sm:px-8 sm:py-10 xl:w-1/2 bg-cardBg rounded-2xl sm:rounded-4xl'>
			<h1 className='mb-3 text-2xl font-medium text-center sm:text-4xl text-title'>Fee Calculator</h1>
			<div className='mb-8 text-xs font-normal text-center text-description'>Exon Multisend service fee(not network fee)</div>
			<input
							onChange={(e: any) => setAddressesAmount(e.target.value)}
							placeholder='Enter addresses amount'
							value={addressesAmount ? addressesAmount : '' }
              className="w-full text-title leading-9 rounded-t-lg bg-input 
												 bg-opacity-50 pl-5 pr-4 py-0.5 border-b border-yellow 
												 focus:outline-none focus:bg-opacity-100 mb-8"
            />
			<span className='text-xs text-description'>Rate: {oneAddressPrice} {feeTypes.sendFee.isTrx ? 'TRX' : feeTypes.sendFee.tokenAddress.name}/1 address</span>
			<div className='mt-5 mb-8 text-lg text-description'>Fees: {feeAmount} {feeTypes.sendFee.isTrx ? 'TRX' : feeTypes.sendFee.tokenAddress.name}</div>
			<div className='w-full text-center'>
				<Button disabled={!addressesAmount} onClick={showResult}>Results</Button>
			</div>
		</div>
	)
}

export default FeeCalculator
