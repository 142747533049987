import useWalletSelector from "hooks/useWalletSelector"
import {FunctionComponent, useEffect, useState} from "react"
import {LaunchpoolInfo} from "store/reducers/launchpool/types"
import s from './PoolWinners.module.css'

type Props = {
	activePool: LaunchpoolInfo
}

const PoolWinners:FunctionComponent<Props> = ({activePool})  => {

	const [sortedResultWinners, setSortedResultWinners] = useState<number[]>([])
	const { currentAccount } = useWalletSelector(state => state.AccountReducer)

const isPoolClosed = () => {
    let poolType
    if (activePool?.randomShareBoth === 0 || activePool?.randomShareBoth === 3) {
        poolType = 'random'
    } else {
        poolType = 'share'
    }
    let currentTimestamp: number = Date.now() / 1000

	if((!!activePool?.poolCap) && (activePool.randomShareBoth === 3)){
		if(activePool.totalStakeRandom >= activePool.poolCap){
			return true
		}else return false
	}
    else if(!!activePool?.poolCap){
        return false
    }else {
        if (activePool?.end! < currentTimestamp) {
            return true
        }else return false
    }
}

	useEffect(() => {
		if(!!activePool.resultWinners.length)	{
			setSortedResultWinners([
				...activePool.resultWinners.filter(el => el === currentAccount),
				...activePool.resultWinners.filter(el => el !== currentAccount)
			])
		}
	}, [activePool.resultWinners, currentAccount])

	return (
		<>
			{ (isPoolClosed() && !!activePool.resultWinners.length ) && <div className={`${activePool.userInfo.reward && s.winner} mx-auto bg-cardBg mmx:w-5/12 rounded-4xl border border-yellow mb-16`}>
				<div className={`${s.winners__img} p-10 px-8 h-full w-full`}>

					<h1 className='text-2xl font-medium text-center text-title'>Our winners</h1>
					<p className='text-base text-center text-description'>Here are the Exon NFT IDs of the winners</p>
					<div className='flex flex-wrap w-full overflow-auto h-28 gap-x-8'>
						<WinnersTable sortedResultWinners={sortedResultWinners} currentAccount={currentAccount} />
					</div>
				</div>
			</div>}
		</>
	)
}

type TableProps = {
	sortedResultWinners: number[];
	currentAccount: number;
}

const WinnersTable: FunctionComponent<TableProps> = ({sortedResultWinners, currentAccount}) => {
	const columns = 5
	const [tableWinners, setTableWinners] = useState<number[][]>([])
	
	useEffect(() => {
		let newArr = []
		
		let row = []
		for (let i = 0; i < sortedResultWinners.length; i++) {
			row.push(sortedResultWinners[i])
			if((row.length % columns) === 0){
				newArr.push(row)
				row = []
			}else if(row.length === sortedResultWinners.length) {
				newArr.push(row)
			}
		}
		setTableWinners(newArr)
	}, [sortedResultWinners, currentAccount])

	return (
		<table className='w-full h-full'>
			{tableWinners.map(row => {
				return <tr>
					{row.map(el => <td colSpan={1} className={` text-2xl text-center font-medium ${currentAccount === el ? 'text-yellow text-4xl' : 'text-title'}`}>{el}</td>)}
				</tr>
			})}
		</table>
	)
}

export default PoolWinners
