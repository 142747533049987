import { ambassadors } from './ambassador';
import { tokensBaseMain, tokensDataMain } from './main';
import { tokensBaseShasta, tokensDataShasta } from './shasta';
//            LAUNCHPOOL_FACTORY_CONTRACT: 'TX1aJSMKba5mNVCkzXnQTakXVYeSgKaMya',
//

//TRONGRID: "https://api.trongrid.io/",
//TRONGRID: "http://188.40.63.56:8090/",
const variables = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return {
      ACCOUNT_CONTRACT: 'TDiEsQB1xStQQfVEiEqCfh6SfuqQs8P8op',
      FACTORY_CONTRACT: 'THKvLKznEkNfG7jxmoxYMBCMGcg6j28C1N',
      ROUTER_CONTRACT: 'TPoerun8hUJLnxfSdhKRMU2wWUiN31ezQv',
      ORACLE_LIQUIDITY_AND_SWAP: 'TCUUjzBVqTFJG5asGNQukhRArxjX9CW4ES',
      FARMING_FACTORY_CONTRACT: 'TQfPTPizyxaBtqFDeTjuVRKdUCScz5RL1z',
      FARMING_CONTRACT: 'TNszEMD9VrbGhm3TqYBEWgP5etmR5xKyoC',
      MULTISEND_CONTRACT: 'THVEYgN7ZB4hWFqB6FBUHkfSviDhVYU7he',
      LAUNCHPOOL_FACTORY_CONTRACT: 'TEpwB5NJDnAyWwFtQDX9A5QHAt5Yz37438',
      LAUNCHPOOL_FACTORY_CONTRACT_OLD: ['TRbZdzm3e8LVK3SDeDwx2ENqN53tEEi9h9'],
      LAUNCHPAD_FACTORY: 'TJVtrgsqnRnTKvxN6KANtZTYHwxuXe3Juk',
      ENERGY_CONTRACT: 'TJVtrgsqnRnTKvxN6KANtZTYHwxuXe3Juk',
      LEVELS_ENERGY_CONTRACT: 'TR2GKDgJfMbXVs2YhPMsVzKwCawyqZj1A4',
      TOKENS_DATA: tokensDataMain,
      TOKEN_BASE: tokensBaseMain,
      // eslint-disable-next-line
      REF_REX: 'https://app.exoncenter.com/[0-9]+$',
      HEARTBEAT: 20,
      REF_LINK: 'https://app.exoncenter.com/',
      TRX_USD_PRICE_ADDRESS: 'TAL6RWymPLepKsLGCzWPUTKQQcWFsuHfNE',
      EXCHANGE_TOKENS_EXR2: 'TLDyXkrUTZkeTetSWKUqn5c6ECKHk4iZAM',
      EXCHANGE_TOKENS_EXR1: 'TXe7V9UyVLPmKMnzG19rdksj63wnxPgXt1',
      EXON_NODE: 'https://node.exoncenter.com/',
      EXON_SOLIDITY: 'https://solidity.exoncenter.com/',
      EXON_EVENT_SERVER: 'https://solidity.exoncenter.com/',
      TRONSCAN: 'https://tronscan.org',
      TRONGRID: 'https://api.tronstack.io/',
      PRIVATE_KEY:
        '2f14374760c34289ceb37720fd2cfb2375d07e137285cc18ed4c08c3490bf158',
      SERVER: 'https://api.exoncenter.com/api/v1/',
      TRONLIST: 'https://apilist.tronscan.org/api/token_trc20?contract=',
      TRONLIST_TRANSACTIONS:
        'https://apilist.tronscanapi.com/api/transaction?sort=-timestamp&count=true&limit=50',
      EXONCENTER_CONTENT: 'https://content.exoncenter.com/',
      CONFIRMATIONS: 3,
      SECONDS_PER_RECEIVE: 7,
      AMBASSADORS: ambassadors,
      POUCH_DB: 'http://localhost:5984/',
      CHAIN_TYPE: 'production',
      ANAC_WITHDRAW_CONTRACT: 'TCszN6mVyvuFf8Hz8v9VHHDAf6sQhgN3xY',
    };
  } else {
    return {
      ACCOUNT_CONTRACT: 'TKKQjhDhLzypw4eQHTmEYnDqEfgQsk3gYa',
      FACTORY_CONTRACT: 'TK1DM91ZNeZxMCkTqyr6LaaJmW48f4cf8q',
      ROUTER_CONTRACT: 'TLXC5Gw93GDHjDQTBiyYNcmxUYGiRCfcpW',
      ORACLE_LIQUIDITY_AND_SWAP: 'TDYXKufpF96ZgXoxNpT5RT96YNkieiU6Aj',
      FARMING_FACTORY_CONTRACT: 'TX9iK5TwANkVZHjqAJevtuSku7G6ncZXuZ',
      FARMING_CONTRACT: 'TKP7GpxyLqeEjwm3pmC6YGRzsfziQM6DAe',
      MULTISEND_CONTRACT: 'TQiKvo9wzQCTNfMg5zaRKDZwnPpFRGCfCr',
      LAUNCHPOOL_FACTORY_CONTRACT: 'TD7f5xuHH15TjaxL6DSFHngqYZjznkKsP7',
      LAUNCHPOOL_FACTORY_CONTRACT_OLD: ['TRbZdzm3e8LVK3SDeDwx2ENqN53tEEi9h9'],
      LAUNCHPAD_FACTORY: 'TY4bHELgn6mjvRc1x1TVmGzxbmdMcuVenk',
      ENERGY_CONTRACT: 'TJVtrgsqnRnTKvxN6KANtZTYHwxuXe3Juk',
      LEVELS_ENERGY_CONTRACT: 'TWx3gQ9hXFdwdTUdRF1sEp9a6HEKoyo2c8',
      TOKENS_DATA: tokensDataShasta,
      TOKEN_BASE: tokensBaseShasta,
      // eslint-disable-next-line
      REF_REX: 'https://apptest.exoncenter.com/[0-9]+$',
      HEARTBEAT: 20,
      REF_LINK: 'https://apptest.exoncenter.com/',
      TRX_USD_PRICE_ADDRESS: 'TAL6RWymPLepKsLGCzWPUTKQQcWFsuHfNE',
      EXCHANGE_TOKENS_EXR2: '',
      EXCHANGE_TOKENS_EXR1: '',
      EXON_NODE: 'https://api.shasta.trongrid.io/',
      EXON_SOLIDITY: 'https://api.shasta.trongrid.io/',
      EXON_EVENT_SERVER: 'https://api.shasta.trongrid.io/',
      TRONSCAN: 'https://shasta.tronscan.org',
      TRONGRID: 'https://api.shasta.trongrid.io/',
      PRIVATE_KEY:
        '2f14374760c34289ceb37720fd2cfb2375d07e137285cc18ed4c08c3490bf158',
      SERVER: 'https://api.exoncenter.com/api/v1/',
      TRONLIST: 'https://apilist.tronscan.org/api/token_trc20?contract=',
      TRONLIST_TRANSACTIONS:
        'https://apilist.tronscanapi.com/api/transaction?sort=-timestamp&count=true&limit=50',
      EXONCENTER_CONTENT: 'https://content.exoncenter.com/',
      CONFIRMATIONS: 3,
      SECONDS_PER_RECEIVE: 3,
      AMBASSADORS: ambassadors,
      POUCH_DB: 'https://couchdb.exoncenter.com/',
      CHAIN_TYPE: 'development',
      ANAC_WITHDRAW_CONTRACT: 'TJoW7P9qjAURuwR3kUPyaF8GiHuwJN8pLy',
    };
  }
};

export default variables;
