import React, { FunctionComponent } from 'react';

type Props = {
  disabled?: boolean;
  wide?: boolean;
  className?: string;
  onClick?: any;
};

const Button: FunctionComponent<Props> = ({ disabled, wide, children, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${className} bg-yellow py-3 px-8 text-xl text-black rounded-xl ${disabled ? 'bg-description bg-opacity-30 cursor-not-allowed' : null
        } ${wide ? 'px-10' : null}`}
    >
      {children}
    </button>
  );
};

export default Button;
