/* eslint-disable */
import { AppDispatch, getRootState } from 'store';
import { WalletAction, WalletStateEmun } from './types';
import connectTronLink from 'utils/tronWeb/initTronlink';
import initContract from 'utils/tronWeb/initContract';
import { AccountAction, AccountsStateEmun } from '../account/types';
import configEnv from 'config';
import { accountActionCreator } from '../account/action-creators';
import sleep from 'utils/sleep';
import initContractTronlink from 'utils/tronWeb/initContractTronlink';
import { ApplicationAcionCreator } from '../application/action-creator';
import { launchpoolActionCreator } from '../launchpool/action-creator';
import LPAbi from 'contracts/Launchpool.json';
import IDO from 'contracts/IDO.json';
import { launchpadActionCreator } from '../launchpad/action-creator';
import { sendTransactionHanlder } from 'utils/transaction/sendTransactionHandler';

export const WalletConnectActionCreator = {
  setWallet: (wallet: boolean): WalletAction => ({
    type: WalletStateEmun.SET_WALLET,
    payload: wallet,
  }),
  setAddress: (address: string): WalletAction => ({
    type: WalletStateEmun.SET_ADDRESS,
    payload: address,
  }),
  setNFTContract: (nftContract: any): WalletAction => ({
    type: WalletStateEmun.SET_NFT_CONTRACT,
    payload: nftContract,
  }),
  setFabricaContract: (fabricaContract: any): WalletAction => ({
    type: WalletStateEmun.SET_FABRICA_CONTRACT,
    payload: fabricaContract,
  }),
  setRouteContract: (routeContract: any): WalletAction => ({
    type: WalletStateEmun.SET_ROUTE_CONTRACT,
    payload: routeContract,
  }),
  setIsPresent: (isPresent: boolean): WalletAction => ({
    type: WalletStateEmun.SET_WALLET_PRESENT,
    payload: isPresent,
  }),
  createPopup: (create: boolean): AccountAction => ({
    type: AccountsStateEmun.SET_CREATE_POPUP,
    payload: create,
  }),
  setTronWeb: (tronWeb: any): WalletAction => ({
    type: WalletStateEmun.SET_TRON_WEB,
    payload: tronWeb,
  }),
  setAccounts: (accounts: number[]): AccountAction => ({
    type: AccountsStateEmun.SET_ACCOUNTS,
    payload: accounts,
  }),
  setNotCorrectChain: (statusChain: boolean): WalletAction => ({
    type: WalletStateEmun.SET_NOT_CORRECT_CHAIN,
    payload: statusChain,
  }),
  setTrxBalance: (trxBalance: number): WalletAction => ({
    type: WalletStateEmun.SET_TRX_BALANCE,
    payload: trxBalance,
  }),
  connectWallet: () => async (dispatch: AppDispatch, store: getRootState) => {
    // const { tronWeb } = await connectTronLink()
    const tronWeb = await (window as any).tronWeb;
    if (!tronWeb) {
      dispatch(ApplicationAcionCreator.setIsTronwebPresent(false));
      return;
    }
    if (tronWeb) {
      //const { contract } = await initContract("TYWjoDcsEbqdnxaceTu5NDWaA5rK5jZ3Xd")
      const contractNft = await initContract(configEnv().ACCOUNT_CONTRACT);
      const contractNFTCheck = await initContractTronlink(
        configEnv().ACCOUNT_CONTRACT,
        dispatch,
      );
      if (
        contractNFTCheck.contract !== null ||
        contractNFTCheck.contract === undefined
      ) {
        //const routeContract = await initContract(configEnv().ROUTE_CONTRACT)
        dispatch(WalletConnectActionCreator.setWallet(true));

        dispatch(
          WalletConnectActionCreator.setAddress(tronWeb.defaultAddress.base58),
        );
        //dispatch(WalletConnectActionCreator.setAddress('TDiRtLH4V3P9vuXCDDTXR76DzvTxBhFrQh'))
        dispatch(WalletConnectActionCreator.setTronWeb(tronWeb));
        // add contract instance to store
        dispatch(WalletConnectActionCreator.setNFTContract(contractNFTCheck));
        //dispatch(WalletConnectActionCreator.setRouteContract(routeContract))

        const callBalanceOf = async (address: string) => {
          let isPresent = await contractNft.balanceOf(address).call();
          return isPresent;
        };

        var isPresent = await sendTransactionHanlder(() =>
          callBalanceOf(tronWeb.defaultAddress.base58),
        );
        //var isPresent = await contractNft.balanceOf('TDiRtLH4V3P9vuXCDDTXR76DzvTxBhFrQh').call()
        console.log(isPresent.toNumber());
        if (+isPresent.toNumber()) {
          const getTokenIdByAddress = async (address: string, id: number) => {
            return await contractNft.tokenOfOwnerByIndex(address, id).call();
          };
          var accounts = [];
          for (var i: number = 0; i < isPresent.toNumber(); i++) {
            try {
              var account = await getTokenIdByAddress(
                tronWeb.defaultAddress.base58,
                i,
              );
              //var account = await contractNft.tokenOfOwnerByIndex('TDiRtLH4V3P9vuXCDDTXR76DzvTxBhFrQh',i).call()
              accounts.push(account.toNumber());
            } catch (error) {
              console.log(error);
              await getTokenIdByAddress(tronWeb.defaultAddress.base58, i);
            }
          }
          var levels = [];
          for (var i = 0; i < 6; i++) {
            var getLevelNumber = await contractNft
              .ReferalAmount(accounts[0], i)
              .call();
            levels.push(getLevelNumber.toNumber());
          }
          var upliner = await contractNft.Upliners(accounts[0]).call();

          const currentAccount = accounts[0];

          console.log('WALLET REDUCER DATA', accounts);
          dispatch(WalletConnectActionCreator.setAccounts(accounts));
          dispatch(accountActionCreator.setAccount(accounts[0]));

          /*
          let trxBalance = await tronWeb.trx.getBalance(
            tronWeb.defaultAddress.base58,
          );
          trxBalance = +tronWeb.fromSun(trxBalance);

          dispatch(WalletConnectActionCreator.setTrxBalance(trxBalance));
*/
          let LPUser = {
            userInfo: {
              stakedValue: 0,
              randomWithdrawed: 0,
              shareWithdrawed: 0,
              reward: 0,
              referralReward: 0,
            },
          };
          const currentLaunchpoolId = store().launchpoolReducer.activePoolId;
          const pools = store().launchpoolReducer.pools;
          if (currentLaunchpoolId != 0 && pools.length != 0) {
            let currentPool = pools.filter(
              (el: any) => el.poolId === currentLaunchpoolId,
            )[0];

            const poolContract: any = await tronWeb.contract(
              LPAbi.abi,
              currentPool.poolAddress,
            );
            let shareStaked: any;
            let shareWithdrawed;
            try {
              shareStaked = await poolContract
                .UsersShare(currentAccount)
                .call();
              shareWithdrawed = shareStaked.amountWithdrawed.toNumber();
              shareWithdrawed = +tronWeb.fromSun(shareWithdrawed);
              shareStaked = shareStaked.amountStake.toNumber();
              shareStaked = +tronWeb.fromSun(shareStaked);
            } catch (error) {
              console.log(error);
            }
            let randomStaked: any;
            let randomWithdrawed;
            try {
              randomStaked = await poolContract
                .UsersRandom(currentAccount)
                .call();
              randomWithdrawed = randomStaked.amountWithdrawed.toNumber();
              randomWithdrawed = +tronWeb.fromSun(randomWithdrawed);
              randomStaked = randomStaked.amountStake.toNumber();
              randomStaked = +tronWeb.fromSun(randomStaked);
            } catch (error) {
              console.log(error);
            }

            let reward;
            try {
              reward = await poolContract.calculatePart(currentAccount).call();
              reward = reward.toNumber();
              reward = +tronWeb.fromSun(reward);
              console.log('REWARD', reward);
            } catch (error) {
              console.log(error);
              reward = 0;
            }

            let referralReward;

            try {
              referralReward = await poolContract
                .UplinersTransfer(currentAccount)
                .call();
              referralReward = referralReward.toNumber();
              referralReward = tronWeb.fromSun(referralReward);
            } catch (error) {
              console.log(error);
              referralReward = 0;
            }
            let stakedValue;
            const defineStakedAmount = () => {
              let poolType = currentPool.randomShareBoth;
              if (poolType === 0 || poolType === 3) {
                return randomStaked;
              } else if (poolType === 1) {
                return shareStaked;
              } else if (poolType === 2) {
                return randomStaked + shareStaked;
              } else return 0;
            };

            stakedValue = defineStakedAmount();

            LPUser.userInfo.stakedValue = stakedValue;
            LPUser.userInfo.randomWithdrawed = randomWithdrawed;
            LPUser.userInfo.shareWithdrawed = shareWithdrawed;
            LPUser.userInfo.referralReward = referralReward;
            LPUser.userInfo.reward = reward;
            currentPool = { ...currentPool, userInfo: LPUser.userInfo };
            dispatch(
              launchpoolActionCreator.setCurrentPool(
                currentPool,
                currentLaunchpoolId,
              ),
            );
          }

          const activeIDO = store().launchpadReducer.activeIdo;

          console.log(activeIDO);
          if (!!activeIDO) {
            const lpContract = tronWeb.contract(IDO.abi, activeIDO.poolAddress);

            let whitelistedToken;
            let boughtTokens = [];
            let saleTokenAmount;
            let referalAmount;

            for (let i = 0; i < activeIDO.whiteListTokens.length; i++) {
              let whitelist;

              try {
                whitelist = await lpContract
                  .whiteListLock(currentAccount, activeIDO.whiteListTokens[i])
                  .call();
                whitelist = whitelist.toNumber();
                whitelist = +tronWeb.fromSun(whitelist);
              } catch (error) {
                console.log(error);
                whitelist = { token: '', amount: 0 };
              }
              if (!!whitelist) {
                whitelistedToken = {
                  token: activeIDO.whiteListTokens[i],
                  amount: whitelist,
                };
              }
            }
            if (!whitelistedToken) {
              whitelistedToken = { token: '', amount: 0 };
            }
            for (let i = 0; i < activeIDO.buyTokens.length; i++) {
              let buyTokens;

              try {
                buyTokens = await lpContract
                  .tokenIdBuyTokenAmounts(
                    currentAccount,
                    activeIDO.buyTokens[i],
                  )
                  .call();
                buyTokens = buyTokens.toNumber();
                buyTokens = +tronWeb.fromSun(buyTokens);
              } catch (error) {
                console.log(error);
              }
              if (!!buyTokens) {
                boughtTokens.push({
                  token: activeIDO.buyTokens[i],
                  amount: buyTokens,
                });
              }
            }
            console.log(boughtTokens);

            try {
              saleTokenAmount = await lpContract
                .tokenIdSaleAmount(currentAccount)
                .call();
              saleTokenAmount = saleTokenAmount.toNumber();
              saleTokenAmount = +tronWeb.fromSun(saleTokenAmount);
              saleTokenAmount = +saleTokenAmount.toFixed(2);
            } catch (error) {
              console.log(error);
              saleTokenAmount = 0;
            }

            try {
              referalAmount = await lpContract
                .tokenIdRefAmount(currentAccount)
                .call();
              referalAmount = referalAmount.toNumber();
              referalAmount = +tronWeb.fromSun(referalAmount);
            } catch (error) {
              console.log(error);
              referalAmount = 0;
            }

            const userInfo = {
              whitelistedToken,
              boughtTokens,
              saleTokenAmount,
              referalAmount,
            };
            const currentLaunchpad = { ...activeIDO, userInfo };
            dispatch(
              launchpadActionCreator.setCurrentPool(
                currentLaunchpad,
                activeIDO.poolId,
              ),
            );
          }

          //mapping(uint => uint) public tokenIdSaleAmount;

          dispatch(accountActionCreator.setUpliner(upliner.toNumber()));
          dispatch(WalletConnectActionCreator.setIsPresent(true));
          dispatch(accountActionCreator.setLevels(levels));
        } else {
          if (!contractNFTCheck) {
            dispatch(WalletConnectActionCreator.setNotCorrectChain(true));
          } else {
            dispatch(WalletConnectActionCreator.setIsPresent(false));
            dispatch(WalletConnectActionCreator.createPopup(true));
          }
        }
      } else {
        dispatch(WalletConnectActionCreator.setNotCorrectChain(true));
      }
    }
  },
};
