export interface AccountsState {
  accounts: number[];
  levels: number[];
  farmingInfo: FarmingInfo;
  creating: boolean;
  createAccount: boolean;
  accountCreated: boolean;
  currentAccount: number;
  upliner: number;
  transactionError: boolean;
  teamLiquidity: number;
  teamSwap: number;
  personalSwap: number;
  personalLiquidity: number;
  teamFetching: boolean;
  farmingPartners: number;
  totalTeamRevenue: number;
  withdrawedRevenue: Revenue[] | [];
  choosenFarmingPoolId: number;
  teamLevelsRevenue: number[];
  teamPoolRevenue: PoolRevenue[] | [];
  levelsNoRevenue: boolean;
  totalFarmingWithdrawed: number;
  boostInfo:  BoostInfo;
  boostLevelChosen: number;
  canUserWithdrawLaunchpool: boolean;
}
export type BoostInfo = {
  boostActiveLevel: number;
  boostFeeTokenAddress: string[] | [];
  boostFeeTokenPrice: number[] | [];
}

export type FarmingInfo = {
  teamFarmingByLevel: number[];
  totalTeamFarming: number;
  personalLiquidityFarming: number;
  teamFarmingTurnover: number[];
  teamFarmingProfit: number;
  currentAccountLevel: number;
  currentRangLevel: number;
  withdrawedLevels: number;
  totalRankWithdrawed: number[];
  firstTokenTurnover: number;
  secondTokenTurnover: number;
}

export type PoolRevenue = {
  poolId: number;
  amount: number;
}

export type Revenue = {
  poolId: number,
  amount: [0, 0, 0, 0, 0, 0]
}

export enum AccountsStateEmun {
  SET_ACCOUNTS = 'SET_ACCOUNTS',
  SET_LEVELS = 'SET_LEVELS',
  SET_CREATING = 'SET_CREATING',
  SET_CREATE_POPUP = 'SET_CREATE_POPUP',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT',
  CREATE_FINISH_NOTIFICATION = 'CREATE_FINISH_NOTIFICATION',
  SET_UPLINER = 'SET_UPLINER',
  SET_TRANSACTION_ERROR = 'SET_TRANSACTION_ERROR',
  SET_PERSONAL_SWAP = 'SET_PERSONAL_SWAP',
  SET_PERSONAL_LIQUIDITY = 'SET_PERSONAL_LIQUIDITY',
  SET_TEAM_LIQUIDITY = 'SET_TEAM_LIQUIDITY',
  SET_TEAM_SWAP = 'SET_TEAM_SWAP',
  RESET_ACCOUNT_DATA = 'RESET_ACCOUNT_DATA',
  SET_TEAM_FETCHING = 'SET_TEAM_FETCHING',
  SET_TEAM_FARMING_TURNOVER = 'SET_TEAM_FARMING_TURNOVER',
  SET_PERSONAL_LIQUIDITY_FARMING = 'SET_PERSONAL_LIQUIDITY_FARMING',
  SET_FARMING_PARTNERS = 'SET_FARMING_PARTNERS',
  SET_CURRENT_ACCOUNT_LEVEL = 'SET_CURRENT_ACCOUNT_LEVEL',
  SET_TEAM_LEVELS_REVENUE = 'SET_TEAM_LEVELS_REVENUE',
  SET_CURRENT_RANG_LEVEL = 'SET_CURRENT_RANG_LEVEL',
  SET_TEAM_FARMING_BY_LEVEL = 'SET_TEAM_FARMING_BY_LEVEL',
  SET_WITHDRAWED_LEVELS = 'SET_WITHDRAWED_LEVELS',
  SET_TOTAL_TEAM_FARMING = 'SET_TOTAL_TEAM_FARMING',
  SET_TOTAL_TEAM_REVENUE = 'SET_TOTAL_TEAM_REVENUE',
  SET_WITHDRAWED_REVENUE = 'SET_WITHDRAWED_REVENUE',
  SET_CHOOSEN_FARMING_POOL_ID= 'SET_CHOOSEN_FARMING_POOL_ID',
  SET_ALL_FARMING_INFO = 'SET_ALL_FARMING_INFO',
  SET_TOTAL_FARMING_WITHDRAWED = 'SET_TOTAL_FARMING_WITHDRAWED',
  SET_TEAM_POOL_REVENUE = 'SET_TEAM_POOL_REVENUE',
  SET_LEVELS_NO_REVENUE = 'SET_LEVELS_NO_REVENUE',
  SET_BOOST_ACTIVE_LEVEL = 'SET_BOOST_ACTIVE_LEVEL',
  SET_BOOST_LEVEL_CHOSEN = 'SET_BOOST_LEVEL_CHOSEN',
  SET_CAN_USERWITHDRAW_POOL = 'SET_CAN_USERWITHDRAW_POOL'
}

export interface setCanUserWitdhrawPool {
  type: AccountsStateEmun.SET_CAN_USERWITHDRAW_POOL,
  payload: boolean
}

export interface setBoostLevelChosen {
  type: AccountsStateEmun.SET_BOOST_LEVEL_CHOSEN,
  payload: number
}

export interface setBoostActiveLevel {
  type: AccountsStateEmun.SET_BOOST_ACTIVE_LEVEL,
  payload: number
}

export interface setLevelsNoRevenue {
  type: AccountsStateEmun.SET_LEVELS_NO_REVENUE;
  payload: boolean
}

export interface setTeamPoolRevenue {
  type: AccountsStateEmun.SET_TEAM_POOL_REVENUE;
  payload: PoolRevenue[] | [];
}

export interface setTransactionError {
  type: AccountsStateEmun.SET_TRANSACTION_ERROR;
  payload: boolean;
}

export interface setUpliner {
  type: AccountsStateEmun.SET_UPLINER;
  payload: number;
}

export interface setCurrentAccount {
  type: AccountsStateEmun.SET_CURRENT_ACCOUNT;
  payload: number;
}

export interface setAccountsAction {
  type: AccountsStateEmun.SET_ACCOUNTS;
  payload: number[];
}

export interface setAccountLevelsAction {
  type: AccountsStateEmun.SET_LEVELS;
  payload: number[];
}

export interface setAccountCreatingStateAction {
  type: AccountsStateEmun.SET_CREATING;
  payload: boolean;
}

export interface setCreatePopupAction {
  type: AccountsStateEmun.SET_CREATE_POPUP;
  payload: boolean;
}

export interface createAccount {
  type: AccountsStateEmun.CREATE_ACCOUNT;
  payload: number;
}

export interface finishCreateAcount {
  type: AccountsStateEmun.CREATE_FINISH_NOTIFICATION;
  payload: boolean;
}

export interface setPersonalLiquidity {
  type: AccountsStateEmun.SET_PERSONAL_LIQUIDITY;
  payload: number;
}

export interface setPersonalSwap {
  type: AccountsStateEmun.SET_PERSONAL_SWAP;
  payload: number;
}

export interface setTeamLiquidity {
  type: AccountsStateEmun.SET_TEAM_LIQUIDITY;
  payload: number;
}

export interface setTeamSwap {
  type: AccountsStateEmun.SET_TEAM_SWAP;
  payload: number;
}

export interface resetAccountData {
  type: AccountsStateEmun.RESET_ACCOUNT_DATA
}

export interface setTeamFetching {
  type: AccountsStateEmun.SET_TEAM_FETCHING;
  payload: boolean
}

export interface setTeamFarmingTurnover {
  type: AccountsStateEmun.SET_TEAM_FARMING_TURNOVER;
  payload: number
}

export interface setPersonaLiquidityInFarming {
  type: AccountsStateEmun.SET_PERSONAL_LIQUIDITY_FARMING;
  payload: number
}

export interface setFarmingPartners {
  type: AccountsStateEmun.SET_FARMING_PARTNERS;
  payload: number
}

export interface setCurrentAccountLevel {
  type: AccountsStateEmun.SET_CURRENT_ACCOUNT_LEVEL;
  payload: number
}

export interface setCurrentRangLevel {
  type: AccountsStateEmun.SET_CURRENT_RANG_LEVEL;
  payload: number
}

export interface setTeamLevelsRevenue {
  type: AccountsStateEmun.SET_TEAM_LEVELS_REVENUE;
  payload: number[]
}

export interface setTeamFarmingByLevel {
  type: AccountsStateEmun.SET_TEAM_FARMING_BY_LEVEL;
  payload: number[]
}

export interface setWithdrawedLevels {
  type: AccountsStateEmun.SET_WITHDRAWED_LEVELS;
  payload: number
}

export interface setTotalTeamFarming { type: AccountsStateEmun.SET_TOTAL_TEAM_FARMING;
  payload: number
}

export interface setTotalTeamRevenue {
  type: AccountsStateEmun.SET_TOTAL_TEAM_REVENUE;
  payload: number
}

export interface setWithdrawedRevenue {
  type: AccountsStateEmun.SET_WITHDRAWED_REVENUE;
  payload: Revenue[]
}

export interface setChoosenFarmingPoolId {
  type: AccountsStateEmun.SET_CHOOSEN_FARMING_POOL_ID;
  payload: number
}

export interface setAllFarmingInfo {
  type: AccountsStateEmun.SET_ALL_FARMING_INFO;
  payload: FarmingInfo
}

export interface setTotalFarmingWithdrawed {
	type: AccountsStateEmun.SET_TOTAL_FARMING_WITHDRAWED;	
	payload: number
}

export type AccountState = AccountsState;
export type AccountAction =
  | setAccountLevelsAction
  | setAccountsAction
  | setAccountCreatingStateAction
  | setCreatePopupAction
  | finishCreateAcount
  | setCurrentAccount
  | setUpliner
  | setTransactionError
  | setPersonalLiquidity
  | setPersonalSwap
  | setTeamLiquidity
  | setTeamSwap
  | resetAccountData
  | setTeamFetching
  | setTeamFarmingTurnover
  | setPersonaLiquidityInFarming
  | setFarmingPartners
  | setCurrentAccountLevel
  | setTeamLevelsRevenue
  | setCurrentRangLevel
  | setTeamFarmingByLevel
  | setWithdrawedLevels
  | setTotalTeamFarming
  | setTotalTeamRevenue
  | setWithdrawedRevenue
  | setChoosenFarmingPoolId
  | setAllFarmingInfo
  | setTotalFarmingWithdrawed
  | setTeamPoolRevenue
  | setLevelsNoRevenue
  | setBoostActiveLevel
  | setBoostLevelChosen
  | setCanUserWitdhrawPool;

