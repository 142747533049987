import Button from 'components/Button';
import useWalletSelector from 'hooks/useWalletSelector';
import React, { useEffect, useState } from 'react';
import { Range } from 'react-range';
import { useDispatch } from 'react-redux';
import { farmingActionCreator } from 'store/reducers/farmingAdd/action-creator';
import { getTokenInfoByAddress } from 'utils/getTokenInfo';

const Stake = () => {
  const [state, setState] = useState([0]);
  const [canBeStaked, setCanBeStaked] = useState(false)
  const { farmingStakeInfo, farmingList } = useWalletSelector( state => state.farmingReducer )
  const dispatch = useDispatch()

  let lpPriceInUsd = farmingList.filter(el => el.lpToken === farmingStakeInfo.lpToken )[0].usdPerLp

  useEffect(() => {
    if(!+farmingStakeInfo.stakeInput || +farmingStakeInfo.stakeInput > farmingStakeInfo.lpTokensAmount ) setCanBeStaked(false)
    else setCanBeStaked(true)
  }, [farmingStakeInfo.stakeInput])
  
  const handleStakeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let stakeInput: number
    stakeInput = e.currentTarget.value === '' ? 0 : parseFloat(e.currentTarget.value);
    stakeInput = +stakeInput.toFixed(6)
    dispatch(farmingActionCreator.setStakeInput(stakeInput.toString()))
  }

  const handleRange = () => {
    let stakeInput = (state[0] / 100) * +farmingStakeInfo.lpTokensAmount
    stakeInput = +stakeInput.toFixed(6)
    dispatch(farmingActionCreator.setStakeInput(stakeInput.toString()))
  }

  const getInfoByLp = (type: string) => {
    return farmingList.filter( el => el.lpToken === farmingStakeInfo.lpToken )[0][type]
  }

  useEffect(() => {
    handleRange()
  }, [state])

  return (
    <div>
      <div className="text-xl text-title mt-5">Amount</div>
      <div className="flex flex-wrap items-start gap-4 mt-5 mb-10">
        <div className="flex-1">
          <div className="relative">
            <input
              autoFocus
              type="number"
              value={farmingStakeInfo.stakeInput}
              onChange={e => handleStakeInput(e)}
              className={` ${+farmingStakeInfo.stakeInput > farmingStakeInfo.lpTokensAmount ? 'border-red focus:border-red' : canBeStaked ? 'border-green' : 'focus:border-yellow'}  w-full leading-9 rounded-t-lg bg-input bg-opacity-50 px-4 py-0.5 border-b focus:outline-none focus:bg-opacity-100`}
            />
            <div className="text-description absolute top-2.5 right-2 text-sm">
            {getTokenInfoByAddress(farmingStakeInfo.firstTokenAddress).name.toUpperCase()}-{getTokenInfoByAddress(farmingStakeInfo.secondTokenAddress).name.toUpperCase()} LP
            </div>
            <div className="text-sm whitespace-nowrap ">
              ~ {(farmingStakeInfo.stakeInput * lpPriceInUsd).toFixed(2)} $
            </div>
            <div className="text-sm text-red whitespace-nowrap">
              { +farmingStakeInfo.stakeInput > farmingStakeInfo.lpTokensAmount
                ? 'Value is greater than your balance' 
                : ''}
            </div>
          </div>
        </div>
        {/* <div onClick={() => dispatch(farmingActionCreator.setStakeInput(farmingStakeInfo.lpTokensAmount.toString()))} className="flex items-center justify-center bg-yellow bg-opacity-15 rounded-lg text-yellow px-4 py-2 cursor-pointer">
          Max
        </div> */}
      </div>
      <Range
            step={1}
            min={0}
            max={100}
            values={state}
            onChange={values => {
              setState(values)
            }}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                className="rounded-lg w-full p-1 bg-yellow"
                style={{
                  ...props.style,
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                className="border-4 border-darkGray rounded-lg focus:outline-none"
                style={{
                  ...props.style,
                }}
              >
                <div className="border w-0.5 h-5 bg-lightBlack border-lightBlack"></div>
              </div>
            )}
          />
          <div className="flex justify-center gap-2  sm:mt-5">
            <div
              className="px-3 py-3 rounded-xl cursor-pointer text-yellow text-sm sm:text-base"
              style={{ backgroundColor: '#201F1B' }}
            >
              {`${state}%`}
            </div>
            <div
              className="px-3 py-3 rounded-xl cursor-pointer text-yellow text-sm sm:text-base"
              style={{ backgroundColor: '#201F1B' }}
              onClick={e => setState([25])}
            >
              25%
            </div>
            <div
              className="px-3 py-3 rounded-xl cursor-pointer text-yellow text-sm sm:text-base"
              style={{ backgroundColor: '#201F1B' }}
              onClick={e => setState([50])}
            >
              50%
            </div>
            <div
              className="px-3 py-3 rounded-xl cursor-pointer text-yellow text-sm sm:text-base"
              style={{ backgroundColor: '#201F1B' }}
              onClick={e => setState([75])}
            >
              75%
            </div>
            <div
              className="px-3 py-3 rounded-xl cursor-pointer text-yellow text-sm sm:text-base"
              style={{ backgroundColor: '#201F1B' }}
              onClick={e => setState([100])}
            >
              MAX
            </div>
          </div>
      <div className="flex flex-col sm:flex-row sm:items-end justify-between mt-5">
        <div className="text-description text-base">Wallet balance</div>
        <div className="text-lg">{farmingStakeInfo.lpTokensAmount} {getTokenInfoByAddress(farmingStakeInfo.firstTokenAddress).name.toUpperCase()}-{getTokenInfoByAddress(farmingStakeInfo.secondTokenAddress).name.toUpperCase()} LP</div>
      </div>
      <div className="flex flex-col sm:flex-row sm:items-end justify-between sm:gap-20 mt-2">
        <div className="text-description text-base">My staked value</div>
        <div className="text-lg">{getInfoByLp('lpStaked')} {getTokenInfoByAddress(farmingStakeInfo.firstTokenAddress).name.toUpperCase()}-{getTokenInfoByAddress(farmingStakeInfo.secondTokenAddress).name.toUpperCase()} LP</div>
      </div>
      <div className="flex flex-col sm:flex-row sm:items-end justify-between mt-2">
        <div className="text-description text-base">APR</div>
        <div className="text-lg">{getInfoByLp('apr')}%</div>
      </div>
      <div className="flex justify-center mt-5">
        <Button onClick={() => dispatch(farmingActionCreator.depositTokensToFarming())} disabled={!canBeStaked}>Deposit</Button>
      </div>
    </div>
  );
};

export default Stake;
