import TronWeb from 'tronweb';
import Config from 'config'

const initExonNode = async () => {
  const tronweb = new TronWeb({
    fullNode: Config().EXON_NODE,
    solidityNode: Config().EXON_SOLIDITY,
    eventServer: Config().EXON_EVENT_SERVER,
    privateKey: Config().PRIVATE_KEY,
  });
  return tronweb;
};

export default initExonNode;
