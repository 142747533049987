export type LaunchpoolState = {
  pools: LaunchpoolInfo[],
  joinDetails: LaunchpoolJoinDetails,
  stakedOnly: boolean,
  activePool?: LaunchpoolInfo,
  launchpoolState: LaunchpoolModalState
  activePoolId: number;
}

export type LaunchpoolInfo = {
  poolFactoryAddress?: string;
  poolAddress: string;
  poolAccess?: boolean;
  poolId: number;
  tokenIn: string;
  tokenInSymbol?: string;
  tokenOut: string;
  minIn: number;
  maxIn: number;
  start: number;
  freeze: number;
  end: number;
  tokenUsersOutShare: number;
  tokenUsersOutRandom: number;
  tokenOutShare: number;
  tokenOutRandom: number;
  totalStakeShare: number;
  totalStakeRandom: number;
  randomShareBoth: number;
  poolCap: number;
  tokenPrice: number;
  tokenInPrice: number
  projectName: string;
  projectImage: string;
  uplinerPercent: number;
  winners: number;
  resultWinners: number[];
  participants: number;
  userInfo: LaunchpoolUserInfo;
  poolInfo: LaunchPoolPoolInfo;
}

export type LaunchpoolJoinDetails = {
  poolAddress?: string;
  poolId?: number;
  poolType?: number;
  amount?: number;
}

export type LaunchPoolPoolInfo = {
  poolTitle: string;
  poolName: string;
  poolDescription: string;
  websiteLink: string;
  image: string;
  socialMedia: LauncPoolSocialMedia[];
}

export type LauncPoolSocialMedia  = {
  name: string;
  link: string;
  PoolName: string;
  sequences: number;
}

export type LaunchpoolUserInfo = {
  stakedValue: number;
  randomWithdrawed: number;
  shareWithdrawed: number;
  reward: number;
  referralReward: number;
}

export type LaunchpoolModalState = 'joinToPool'|'claimTokens'|'initial'
export type ActivePoolPayload = {
  activePool: LaunchpoolInfo,
  activePoolId: number
}


export enum LaunchpoolEnum  {
  SET_LAUNCHPOOLS = 'SET_LAUNCHPOOLS',
  SET_JOIN_DETAILS = 'SET_JOIN_DETAILS',
  SET_ACTIVE_POOL = 'SET_ACTIVE_POOL',
  SET_LAUNCHPOOL_STATE = 'SET_LAUNCHPOOL_STATE',
  SET_SHOW_STAKED_ONLY = 'SET_SHOW_STAKED_ONLY'
}

export type showStakedOnly = {
  type: LaunchpoolEnum.SET_SHOW_STAKED_ONLY
}

export type setLaunchpoolState ={
  type: LaunchpoolEnum.SET_LAUNCHPOOL_STATE,
  payload: LaunchpoolModalState 
}

export type setJoinDetails = {
  type: LaunchpoolEnum.SET_JOIN_DETAILS;
  payload: LaunchpoolJoinDetails;
}

export type setCurrentPool = {
  type: LaunchpoolEnum.SET_ACTIVE_POOL;
  payload: ActivePoolPayload 
}

export type setLaunchpools = {
  type: LaunchpoolEnum.SET_LAUNCHPOOLS;
  payload: LaunchpoolInfo[];
}

export type LaunchpoolAction =
  | setLaunchpools
  | setJoinDetails
  | setCurrentPool
  | setLaunchpoolState
  | showStakedOnly
