// import { Exon } from 'components/Icons';
import useWalletSelector from 'hooks/useWalletSelector';
import React, { FunctionComponent } from 'react';
import { ReactComponent as Level } from './assets/level.svg';
import { ReactComponent as Rang } from './assets/rang.svg';
// import useWalletSelector from 'hooks/useWalletSelector';

const Info: FunctionComponent = () => {
  const { farmingInfo } = useWalletSelector(state => state.AccountReducer)

  // const getLevelsAmount = (allLevels:number[]) => {
  //   var result = 0;
  //   for(var i=0; i<allLevels.length; i++) {
  //        if (allLevels[i] > 0) {
  //          result += 1;
  //        }
  //   }
  //   return result;
  // }

  const defineRangLevel = (level: number) => {
    switch (level) {
      case 0:
        return 'Newbie'
      case 1:
        return 'Shrimp'
      case 2:
        return 'Crab'
      case 3:
        return 'Fish'
      case 4:
        return 'Dolphin'
      case 5:
        return 'Shark'
      case 6:
        return 'Whale'
      default:
        break;
    }
  }

  return (
    <>
      <div className="flex mt-8">
        <div className="flex items-center mr-10 sm:mr-4 lg:mr-16">
          <div className="flex justify-center items-center w-14 h-14 lg:w-16 lg:h-16 bg-cardBg rounded-3xl">
            <Level className="w-5 h-5 lg:w-auto lg:h-auto" />
          </div>
          <div className="ml-5">
            <p className="text-lg text-description">Level</p>
            <div className="text-2xl text-description">{farmingInfo.currentAccountLevel}</div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex justify-center items-center w-14 h-14 lg:w-16 lg:h-16 bg-cardBg rounded-3xl">
            <Rang className="w-5 h-5 lg:w-auto lg:h-auto" />
          </div>
          <div className="ml-5">
            <p className="text-lg text-description">Rank</p>
            <div className="text-2xl text-description">{defineRangLevel(farmingInfo.currentRangLevel)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
