import useWalletSelector from 'hooks/useWalletSelector';
import React, { FunctionComponent } from 'react';
import { Facebook, Instagram, Telegram, Twitter, Youtube, Github } from '../Icons';
import IconWrapper from '../Icons/IconWrapper';
import ExonIcon from './assets/exon.svg';
import s from './Footer.module.css'
import Config from 'config'
import { getTokenInfoByName } from 'utils/getTokenInfo';
import { formatNumber } from 'utils/formatNumber';

type Props = {
  className?: string;
};

const Footer: FunctionComponent<Props> = ({ className }) => {
  const { liquidityAmount, volumeAmount, trxUsdPrice, exonUsdPrice } = useWalletSelector( state => state.applicationReducer )

  return (
    <footer className={`bg-lightBlack py-16 justify-left ${className}`}>
      <div className="">
        <div className="flex flex-wrap items-center justify-between gap-10 lg:gap-0">
          <div className="flex items-center justify-center w-full lg:w-1/3 gap-4">
            <div>
              <IconWrapper className="w-12 h-12 mxx:w-20 mxx:h-20">
                <a target='_blank' href={`${Config().TRONSCAN}/#/token20/${getTokenInfoByName('exon').address}`}>
                  <img src={ExonIcon} alt="Exon" />
                </a>
              </IconWrapper>
            </div>
            <div>
              <div className="text-base text-description mxx:text-xl">
                EXON price
              </div>
              <div className="text-xl font-semibold mxx:text-2xl">{formatNumber(exonUsdPrice)} USDT</div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full lg:w-1/3 gap-4">
            <div>
              <div className="flex flex-col items-center justify-center sm:flex-row sm:justify-start gap-2">
                <div className="text-base text-description mxx:text-xl whitespace-nowrap">
                  Total liquidity :
                </div>
                <div className="text-xl font-semibold mxx:text-2xl">${formatNumber((liquidityAmount || 0).toFixed(2))}</div>
              </div>
              <div className="flex flex-col items-center justify-center sm:flex-row sm:justify-start gap-2">
                <div className="text-base text-description mxx:text-xl">Volume <span>24h</span> : </div>
                <div className="text-xl font-semibold mxx:text-2xl">${formatNumber((volumeAmount || 0).toFixed(2))}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center w-full sm:justify-left lg:flex-col mmx:gap-0 gap-4 mmx:flex-row lg:w-1/3 sm:mb-2">
            <div className="flex items-center justify-center gap-4">
              <IconWrapper target="_blank" url='https://github.com/exon-center' className={`${s.footer__icon} w-5 h-5 mxx:w-10 mxx:h-10`}>
                <Github className="w-5" />
              </IconWrapper>
              <IconWrapper target="_blank" url='https://t.me/exoncenter_channel' className={`${s.footer__icon} w-5 h-5 mxx:w-10 mxx:h-10`}>
                <Telegram className="w-5" />
              </IconWrapper>
              <IconWrapper target="_blank" url='https://twitter.com/exoncenter' className={`${s.footer__icon} w-5 h-5 mxx:w-10 mxx:h-10`}>
                <Twitter className="w-5" />
              </IconWrapper>
            </div>
            <div className="flex items-center justify-center gap-4">
              <IconWrapper target="_blank" url='https://www.facebook.com/exoncenter' className={`${s.footer__icon} w-5 h-5 mxx:w-10 mxx:h-10`}>
                <Facebook className="w-5" />
              </IconWrapper>
              <IconWrapper target="_blank" url='https://instagram.com/exoncenter' className={`${s.footer__icon} w-5 h-5 mxx:w-10 mxx:h-10`}>
                <Instagram className="w-5" />
              </IconWrapper>
              <IconWrapper target="_blank" url='https://www.youtube.com/exoncenter' className={`${s.footer__icon} w-5 h-5 mxx:w-10 mxx:h-10`}>
                <Youtube className="w-5" />
              </IconWrapper>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
