import { coinIcons } from "components/CoinIcons"
import { Dispatch, FunctionComponent, useEffect, useState } from "react"
import { ApplicationAcionCreator } from "store/reducers/application/action-creator"
import { graphicActionCreator } from "store/reducers/graphic/action-creator"
import { PairInfo } from "store/reducers/graphic/types"
import { LiquidityActionCreator } from "store/reducers/liquidity/action-creator"
import { swapActionCreator } from "store/reducers/swap/action-creators"
import { getTokenInfoByAddress, getTokenInfoByName } from "utils/getTokenInfo"
import { PaginationEl } from "../Pagination"

type Props = {
    isWallet: boolean,
    history: any,
    dispatch: Dispatch<any>,
    tradingPairs: PairInfo[],
    pairsPageCount: number | null
}

export const TradingPairs: FunctionComponent<Props> = ({ isWallet, history, dispatch, tradingPairs, pairsPageCount }) => {
    const [currentPage, setCurrentPage] = useState<number>(1)

    useEffect(() => {
        dispatch(graphicActionCreator.getPairs(currentPage))
    }, [currentPage])

    

    return(
        <div className="">
            <div className="px-9 mb-7">
                <h1 className='text-4xl text-title font-medium'>Trading Pair</h1>
            </div>
            <div className="bg-cardBg rounded-4xl pt-5 lg:pt-0 pb-5 xl:pb-10 ">
                <div className=" lg:block flex">
                    <div className="flex flex-col lg:hidden pl-5 pr-2 text-2xl font-medium text-title">
                        <span className='mb-1'>Name</span>
                        {tradingPairs.map(el => {
                            return <div style={{padding: '0.8rem 0'}} className='flex' onClick={() => history.push(`/analytics/${getTokenInfoByAddress(el.firstToken).name.toLowerCase()}-${getTokenInfoByAddress(el.secondToken).name.toLowerCase()}`)}>
                                <img className='w-8 h-8 z-10' src={coinIcons[getTokenInfoByAddress(el.firstToken).icon]} alt="" />
                                <img className='w-8 h-8 -ml-2' src={coinIcons[getTokenInfoByAddress(el.secondToken).icon]} alt="" />
                            </div>
                        })}
                    </div>
                    <div className="overflow-x-auto">
                        <table style={{borderCollapse: 'collapse'}} className='w-full gap-8'>
                            <tr className='text-2xl lg:text-base xl:text-2xl text-title font-medium'>
                                <td className='px-7 hidden lg:block lg:px-0 lg:pl-7 py-4 xl:py-8 lg:bg-transparent bg-cardBg' >Name</td>
                                <td className='px-7 lg:px-0'>Liquidity</td>
                                <td className='px-7 lg:px-0 whitespace-nowrap'>Volume <span className='text-xs mr-1'>(24h)</span></td>
                                <td className='px-7 lg:px-0 whitespace-nowrap'>Volume <span className='text-xs mr-1'>(7d)</span></td>
                                <td className='px-7 lg:px-0 whitespace-nowrap'>Fees <span className='text-xs mr-1'>(24h)</span></td>
                                <td></td>
                                <td className='px-7 lg:px-0 pr-7'></td>    
                            </tr>
                            {tradingPairs.map(el => <TradingPairEl 
                                                        isWallet={isWallet} 
                                                        dispatch={dispatch} 
                                                        history={history}
                                                        firstToken={el.firstToken}
                                                        secondToken={el.secondToken}
                                                        liquidity={el.liquidity}
                                                        volume24={el.volume24}
                                                        volume7={el.volume7}
                                                        fees={el.fees}/> )}
                            
                        </table>
                    </div>
                </div>
                <PaginationEl elAmount={pairsPageCount || 1} currentPage={currentPage} dispatch={dispatch} type='pairs' />
            </div>
        </div>
    )
} 

type ElProps= {
    dispatch: Dispatch<any>,
    isWallet: boolean,
    firstToken: string,
    secondToken: string,
    history: any,
    liquidity: number,
    volume24: number,
    volume7: number,
    fees: number,
}

const TradingPairEl: FunctionComponent<ElProps> = ({ isWallet ,dispatch, firstToken, secondToken, history, liquidity, volume24, volume7, fees }) => {
    
    const handleButtons = (action: string) => {
        if(isWallet){
            if( action === 'liquidity' ){
                dispatch(LiquidityActionCreator.setLiquidityState('createPool'));
                dispatch(LiquidityActionCreator.setLiquidityMainState('createPool'));
                dispatch(ApplicationAcionCreator.setFirstToken(getTokenInfoByAddress(firstToken)));
                dispatch(ApplicationAcionCreator.setSecondToken(getTokenInfoByAddress(secondToken)));
                dispatch(swapActionCreator.setSwapTokenBalances());
                history.push('/swap/liquidity')
            }else{
                dispatch(ApplicationAcionCreator.setFirstToken(getTokenInfoByAddress(firstToken)));
                dispatch(ApplicationAcionCreator.setSecondToken(getTokenInfoByAddress(secondToken)));
                dispatch(swapActionCreator.setSwapTokenBalances());
                history.push('/swap')
            }
            
        }else{
            dispatch(swapActionCreator.setCurrentSwapState('walletWarning'))
        }
    };

    return(
        <tr className='text-description text-base xl:text-base font-normal transition-all hover:bg-darkGray relative'>
            <td className='py-4 pl-7 lg:bg-transparent bg-cardBg hidden lg:block'>
                <div onClick={() => history.push(`/analytics/${getTokenInfoByAddress(firstToken).name.toLowerCase()}-${getTokenInfoByAddress(secondToken).name.toLowerCase()}`)} 
                     className="cursor-pointer flex items-center text-yellow">
                    <img className='xl:w-8 xl:h-8 w-8 h-8 lg:w-4 lg:h-4 z-10' src={coinIcons[getTokenInfoByAddress(firstToken).icon]} alt="" />
                    <img className='xl:w-8 xl:h-8 w-8 h-8 lg:w-4 lg:h-4 -ml-2' src={coinIcons[getTokenInfoByAddress(secondToken).icon]} alt="" />
                    <span className='hidden lg:block ml-3'>{getTokenInfoByAddress(firstToken).name}</span>
                    <span className='hidden lg:block '>/</span>
                    <span className='hidden lg:block '>{getTokenInfoByAddress(secondToken).name}</span>
                </div>
            </td>
            <td>
                <div className="text-center lg:text-left py-4">${liquidity}</div>
            </td>
            <td>
                <div className="text-center lg:text-left">${volume24}</div>
            </td>
            <td>
                <div className="text-center lg:text-left">${volume7}</div>
            </td>
            <td>
                <div className="text-center lg:text-left">${fees}</div>
            </td>
            <td className='sm:pr-0 pr-4'>
                <a onClick={() => handleButtons('liquidity')} className='no-underline whitespace-nowrap cursor-pointer text-black bg-button rounded-lg px-4 py-2 xl:py-3'>+ Liquidity</a>
            </td>
            <td className='pr-7'>
                <a onClick={() => handleButtons('swap')} className='no-underline cursor-pointer text-black bg-yellow rounded-lg px-4 py-2 xl:py-3' >Trade</a>
            </td>
        </tr>
    )
}
