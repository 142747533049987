import React, { useEffect, useState } from 'react';
import { ReactComponent as SettingIcon } from './assets/settings.svg';
import SwapProcess from './components/SwapProcess';
import PoolProcess from './components/PoolProcess';
import useWalletSelector from 'hooks/useWalletSelector';
import { useDispatch } from 'react-redux';
import { swapActionCreator } from 'store/reducers/swap/action-creators';
import { useHistory } from 'react-router';


export default function SwapContainer() {
  const dispatch = useDispatch();
  const { swapSettingModal } = useWalletSelector(state => state.swapReducer);
  const history = useHistory()
  const [swapPoolState, setSwapPoolState] = useState(history.location.pathname.split('/')[2] === 'liquidity' ? 'liquidity' : 'swap')
  const { isTransaction } = useWalletSelector( state => state.applicationReducer )
  const { swapState } = useWalletSelector( state => state.swapReducer )
  const { liquidityState } = useWalletSelector( state => state.liquidityReducer )

  const hendlSwapPoolState: React.MouseEventHandler<HTMLDivElement> = e => {
    if(e.currentTarget.id === 'liquidity'){
      history.push('/swap/liquidity')
    }else{
      history.push('/swap')
    }
  };

  const handlSettingModal = () => {
    dispatch(swapActionCreator.setSwapSettingModal(!swapSettingModal));
  };

    
  useEffect(() => {
    setSwapPoolState(history.location.pathname.split('/')[2] === 'liquidity' ? 'liquidity' : 'swap')
    dispatch(swapActionCreator.setFirstTokenBalance(0));
    dispatch(swapActionCreator.setSecondTokenBalance(0));
    dispatch(swapActionCreator.getTokensReserve())
    dispatch(swapActionCreator.setSwapTokenBalances());
  }, [history.location.pathname])

  useEffect(() => {
    if(!isTransaction && !(swapState === 'steps' || liquidityState === 'steps')) {
      dispatch(swapActionCreator.setFirstTokenAmount(0));
      dispatch(swapActionCreator.setSecondTokenAmount(0));  
    }
  }, [isTransaction, history.location.pathname])
  

  return (
    <>
      <div
        className="flex flex-col bg-cardBg rounded-3xl my-32 relative 
        lg:min-w-swapContainer 
        lg:max-w-swapContainer
        md:min-w-swapContainer 
        md:max-w-swapContainer
        overflow-hidden
      ">
        {/* HEADER */}
        <div className="flex flex-row h-12 w-full justify-between items-center">
          <div
            className="flex justify-center text-title w-8/12 sm:w-11/12 mx-auto sm:mx-32 h-6 border-b"
            style={{ borderColor: '#7A7A7A' }}
          >
            <div
              id="swap"
              className={
                swapPoolState === 'swap'
                  ? `inline-block text-yellow cursor-pointer pr-5 pl-5 border-b-1`
                  : `inline-block pr-5 pl-5  border-b-1 cursor-pointer`
              }
              style={
                swapPoolState === 'swap'
                  ? { borderBottom: `0.3px solid #FDD807` }
                  : {}
              }
              onClick={e => hendlSwapPoolState(e)}
            >
              Swap
            </div>
            <div
              id="liquidity"
              className={
                swapPoolState === 'liquidity'
                  ? `inline-block text-yellow cursor-pointer pr-5 pl-5  border-b-1`
                  : `inline-block pr-5 pl-5  border-b-1 cursor-pointer`
              }
              style={
                swapPoolState === 'liquidity'
                  ? { borderBottom: `0.3px solid #FDD807` }
                  : {}
              }
              onClick={e => hendlSwapPoolState(e)}
            >
              Liquidity
            </div>
          </div>

          <div
            className="absolute right-2 top-2 cursor-pointer"
            onClick={handlSettingModal}
          >
            <SettingIcon className="w-6 mr-2 sm:mr-5 mt-2" />
          </div>
        </div>

        {swapPoolState === 'swap' ? <SwapProcess /> : <PoolProcess />}
      </div>
    </>
  );
}
