import useWalletSelector from "hooks/useWalletSelector";
import sleep from "utils/sleep";
import Config from 'config'

export const getTransactionResult = async (transaction: string, tronWeb: any) => {
    let blockNumber;
    let unconfirmTransaction: { blockNumber?: any; receipt?: any; };
    do {
        await sleep(2000);
        unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(transaction);
        console.log('unconfirmTransaction: ', unconfirmTransaction );
    } while (Object.keys(unconfirmTransaction).length === 0);
    
    
     do {
         await sleep(2000);
         blockNumber = await tronWeb.trx.getCurrentBlock();
         console.log('blockNumber:', blockNumber);
     } while ((blockNumber.block_header.raw_data.number - unconfirmTransaction.blockNumber) > Config().CONFIRMATIONS);
    
     console.log((blockNumber.block_header.raw_data.number - unconfirmTransaction.blockNumber) > Config().CONFIRMATIONS);
    
     return unconfirmTransaction?.receipt?.result !== 'SUCCESS'
}
