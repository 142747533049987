// Shasta test net
export const tokensDataShasta = [
  {
    address: 'TWmaAnXP3MvAFMa1yeT9NWVD1w1WnPE6it',
    icon: 'usdt',
    name: 'USDT',
    decimals: 6,
  },
  {
    address: 'TBujYyRTeuEfs2rGQJrLWiejXVKUPLJWvx',
    icon: 'usdc',
    name: 'USDC',
    decimals: 6,
  },
  {
    address: 'TU8ziuipJAxE8Jmeh4LUp6YJj2yP4tBByg',
    icon: 'exon',
    name: 'EXON',
    decimals: 6,
  },
  {
    address: 'TJmjzc8cbQsD2MuWLXALL5fWrh29zYH21C',
    icon: 'btc',
    name: 'BTC',
    decimals: 8,
  },
  {
    address: 'TCotDkgBYyB1zzcfwQ4zipmp9Wki3kwhoA',
    icon: 'eth',
    name: 'ETH',
    decimals: 18,
  },
  {
    address: 'THGeGo43xFvtMgzMiHjwxFqrCwZx52dQgE',
    icon: 'trx',
    name: 'TRX',
    decimals: 6,
  },
  {
    address: 'TSrP2Uh2Gue8ZiPnR1VhdkHVGyzLTev99d',
    icon: 'sun',
    name: 'SUN',
    decimals: 18,
  },
  {
    address: 'TKuM6MVqfsVdLxJJW4i913fViarooTi7D7',
    icon: 'nft',
    name: 'NFT',
    decimals: 6,
  },
  {
    address: 'TH6vY6MPJRHWSMWGezQi1JUAiF1p3K4PD5',
    icon: 'cc',
    name: 'CC',
    decimals: 6,
  },
];

export const tokensBaseShasta = [
  {
    address: 'TWmaAnXP3MvAFMa1yeT9NWVD1w1WnPE6it',
    icon: 'usdt',
    name: 'USDT',
    decimals: 6,
    oracle: '',
  },
  {
    address: 'TBujYyRTeuEfs2rGQJrLWiejXVKUPLJWvx',
    icon: 'usdc',
    name: 'USDC',
    decimals: 6,
    oracle: '',
  },
  {
    address: 'TU8ziuipJAxE8Jmeh4LUp6YJj2yP4tBByg',
    icon: 'exon',
    name: 'EXON',
    decimals: 6,
    oracle: '',
  },
  {
    address: 'THGeGo43xFvtMgzMiHjwxFqrCwZx52dQgE',
    icon: 'trx',
    name: 'TRX',
    decimals: 6,
    oracle: '',
  },
  {
    address: 'TH6vY6MPJRHWSMWGezQi1JUAiF1p3K4PD5',
    icon: 'cc',
    name: 'CC',
    decimals: 6,
    oracle: '',
  },
];
