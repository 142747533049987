import { CloseIcon } from 'components/Icons';
import useWalletSelector from 'hooks/useWalletSelector';
import React, { FunctionComponent, useEffect } from 'react';
import Config from 'config'
import { useDispatch } from 'react-redux';
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';
import Loading from 'components/Popups/assets/loader.gif';

const Notification: FunctionComponent = () => {
  const notificationInfo = useWalletSelector(
    state => state.applicationReducer.notification,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let timeout: any
    if ((notificationInfo.endProcess === true) && (notificationInfo.process !== 'Error')) {
      timeout = setTimeout(() => {
        dispatch(ApplicationAcionCreator.setNotificationStatus(false, false));
      }, 5000);
    }

    return () => clearTimeout(timeout)
  }, [notificationInfo.description, notificationInfo.status])
  return (
    <>
      {notificationInfo.isShown && (
        <div
          style={{zIndex: 9999999}}
          className="fixed z-20 inset-0 flex justify-center sm:block -y-auto pointer-events-none"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="text-description pointer-events-auto mt-2 bg-cardBg absolute w-11/12 sm:w-auto sm:right-10 rounded-3xl text-left shadow-xl transform transition-all sm:my-3 sm:align-middle p-4 overflow-hidden sm:min-w-notification">
            <div className="cursor-pointer">
              <CloseIcon
                onClick={() =>
                  dispatch(
                    ApplicationAcionCreator.setNotificationStatus(false, false),
                  )
                }
                className="absolute right-4 top-4"
              />
            </div>
            <div className="flex flex-col items-stretch gap-2">
              <div
                className="self-start text-xs sm:text-base px-5 py-1 rounded-xl"
                style={{ backgroundColor: '#84DA4A26' }}
              >
                {notificationInfo.status}
              </div>
              <div className="flex items-center justify-between">
                <div className="text-base sm:text-2xl text-title">
                  {notificationInfo.process}
                </div>
                <div className="ml-3">
                  {!notificationInfo.endProcess && (notificationInfo.process !== 'Error') && <img src={Loading} alt="Minting" className='h-8 sm:h-16' />}
                </div>
              </div>
              <div className='text-xs sm:text-base'>{notificationInfo.description}</div>
              {notificationInfo.currentTransaction && <a
                target="_blank"
                rel="noreferrer"
                href={`${Config().TRONSCAN}/#/transaction/${
                  notificationInfo.currentTransaction
                }`}
                className="text-yellow uppercase text-xs sm:text-base"
              >
                View on TRONSCAN
              </a>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
