import useWalletSelector from 'hooks/useWalletSelector'
import {FunctionComponent, useState} from 'react'
import {getTokenInfoByAddress} from 'utils/getTokenInfo'
import PoolItem from './components/PoolItem'
import {ReactComponent as PoolLogo} from './components/PoolItem/assets/poolLogo.svg'


const Pool: FunctionComponent = () => {

    const [textFilter, setTextFilter] = useState('')
		const {pools} = useWalletSelector(state => state.launchpadReducer)


    return(
        <div className='mt-16 mb-20'>
            <div className="relative flex flex-col items-center w-full mb-10 lg:flex-row justify- lg:justify-between">
                <div className='flex flex-col items-center w-full mb-6 sm:flex-row xl:mb-0'>
                    <h1 className='mb-4 text-4xl font-medium text-lightGrey sm:mb-0 sm:ml-7'>Featured IDOs</h1>
                    <div className="w-full ml-0 text-center sm:w-min sm:ml-4">
											<div className='w-full bg-yellow py-2.5 rounded-lg '>
													<a
													href='https://forms.gle/DNdphZApuAnv6nR18'
													target='_blank'
													className='w-full text-xl font-normal text-black sm:w-min whitespace-nowrap px-9 leading-4 text-mainBlack'>Create IDO</a>
											</div>
                    </div>
                </div>
            </div>
            <div className="flex justify-between grid lg:grid-cols-2 mmx:grid-cols-3 grid-cols-1 gap-16">
                {pools.map(el => <PoolItem
																				active={el.active}
																				poolId={el.poolId}
                                        poolName={el.name}
                                        poolImg={el.poolDescriptionInfo!.image}
                                        raise={el.total}
                                        minAllocation={el.minAllocation}
																				maxAllocation={el.maxAllocation}
                                        access={getTokenInfoByAddress(el.whiteListTokens[0]).name}
																				saleTokenInfo={el.saleTokenInfo}
																				start={el.start}
																				end={el.end}
																				total={el.total}
                                        totalIDOAmount={el.totalIDOAmount}
																				buyTokens={el.buyTokens}
																				tokenPrices={el.tokenPrices}
																				whiteListTokens={el.whiteListTokens}
                                        whiteListAmounts={el.whiteListAmounts}
                                    />)}
            </div>
        </div>
    )
}

export default Pool;
