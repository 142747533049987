import tokensData from 'config';

export const getTokenInfoByAddress = (address: string) => {
  return tokensData().TOKENS_DATA.filter(el => el.address === address)[0];
};

export const getTokenInfoByIcon = (icon: string) => {
  return tokensData().TOKENS_DATA.filter(el => el.icon === icon)[0];
};

export const getTokenInfoByName = (name: string) => {
  return tokensData().TOKENS_DATA.filter(el => el.name === name.toUpperCase())[0];
};
