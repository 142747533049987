import {FunctionComponent} from "react"

export type Props = {
	value: any,
	placeholder: string,
	onChange: any,
	className?: string,
	wrongInput?: boolean,
	type?: string,
	id?: string,
	onFocus?: any
}

export const Input: FunctionComponent<Props> = ({value, placeholder, onChange, className,id,wrongInput, type, onFocus}) => {
	return (
		<input
			onFocus={onFocus}
			id={id}
      type={type?type:'text'}
			value={value}
			placeholder={placeholder}
			onChange={e => onChange(e.currentTarget.value)}
			className={`${className} ${wrongInput ? 'border-red' : 'border-yellow'} text-title leading-9 rounded-t-lg bg-input 
								 bg-opacity-50 pl-5 pr-4 py-0.5 border-b 
								 focus:outline-none focus:bg-opacity-100 `}
		/>
	)
}

