import React, { FunctionComponent } from 'react';
import Checkbox from './components/Checkbox';
import Sorting from './components/Sorting';
import Search from './components/Search';

type Props = {
  setTextFilter: any,
  textFilter: string,
  type: string,
  lpools?: boolean,
}

const Filter: FunctionComponent<Props> = ({setTextFilter, textFilter, type, lpools}) => {
  return (
    <div className="relative flex flex-col justify-between sm:flex-row gap-6 sm:gap-0">
      <div className="flex flex-col sm:flex-row gap-6 sm:gap-0">
        <Checkbox type={type} />
        { !lpools && <Sorting />}
      </div>
      { !lpools && <Search textFilter={textFilter} setTextFilter={setTextFilter} />}
    </div>
  );
};

export default Filter;
