import React, { FunctionComponent } from 'react';
import Modal from 'components/Modal';
import SuccessIcon from './assets/success.svg';

const Success: FunctionComponent = () => {
  const isModal = false;
  return (
    <>
      {isModal && (
        <Modal>
          <div className="flex flex-col items-center">
            <img src={SuccessIcon} className="w-40 h-40" alt="Success" />
            <h2 className="text-title text-4xl mt-10">Transaction submitted</h2>
            <div className="mt-5">Deposit 129.423 USDT-EXON LP</div>
            <a href="/" className="mt-10 uppercase text-yellow">
              View on TRONSCAN
            </a>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Success;
