import { WalletAction, WalletState, WalletStateEmun } from './types';

const initialState: WalletState = {
  isWallet: false,
  address: '',
  trxBalance: 0,
  nftContract: null,
  routeContract: null,
  fabricaContract: null,
  walletIsPresent: false,
  tronWeb: null,
  notCorrectChain: false,
  walletChain: false,
};

export default function walletReducer(
  state = initialState,
  action: WalletAction,
) {
  switch (action.type) {
    case WalletStateEmun.SET_WALLET:
      return { ...state, isWallet: action.payload };
    case WalletStateEmun.SET_ADDRESS:
      return { ...state, address: action.payload };
    case WalletStateEmun.SET_WALLET_PRESENT:
      return { ...state, walletIsPresent: action.payload };
    case WalletStateEmun.SET_TRON_WEB:
      return { ...state, tronWeb: action.payload };
    case WalletStateEmun.SET_NFT_CONTRACT:
      return { ...state, nftContract: action.payload };
    case WalletStateEmun.SET_ROUTE_CONTRACT:
      return { ...state, routeContract: action.payload };
    case WalletStateEmun.SET_FABRICA_CONTRACT:
      return { ...state, fabricaContract: action.payload };
    case WalletStateEmun.SET_NOT_CORRECT_CHAIN:
      return { ...state, notCorrectChain: action.payload };
    case WalletStateEmun.SET_TRX_BALANCE:
      return { ...state, trxBalance: action.payload }
    default:
      return state;
  }
}
