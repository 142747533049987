import Block from 'components/Block';
import React, { FunctionComponent, useContext } from 'react';
import { ReactComponent as Withdraw } from './assets/withdraw.svg';
import { ReactComponent as TeamTurnover } from './assets/team-turnover.svg';
import { ReactComponent as PersnonalWithdraw } from './assets/personal-turnover.svg';
import { ReactComponent as Partners } from './assets/partners.svg';
import { ReactComponent as Lvlup } from './assets/lvlup.svg'
import { levels } from './levels';
import { LevelStateContext } from '../../LevelProgram';
import s from './LevelInfo.module.css'
import {accountActionCreator} from 'store/reducers/account/action-creators';
import {useDispatch} from 'react-redux';

type Props = {
  level: string;
  percent?: string;
  title?: string;
  liquidity?: string;
  teamTurnover?: string;
  personalTurnover?: string;
  partners?: string;
  sum?: string;
  active?: boolean;
  setCurrentLevelInfo: any;
  currentLevelInfo: number;
};

const LevelInfo: FunctionComponent<Props> = ({
  level,
  percent,
  title,
  liquidity,
  teamTurnover,
  personalTurnover,
  partners,
  sum,
  active,
  setCurrentLevelInfo,
  currentLevelInfo
}) => {
  const { levelState } = useContext(LevelStateContext);
  const icon = levels[`Lvl${level}`];
  const dispatch = useDispatch()
  return (
    <Block
      light
      small
      onClick={() => setCurrentLevelInfo(+level-1)}
      className={ `mb-5 ${s.main__block} ${currentLevelInfo+1 === +level ? s.main__info : ''} last:mb-0 w-full cursor-pointer ${
        !active && !levelState.condition ? s.opacity : null
      }`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img src={icon} className="w-8 h-8 mr-4" alt="" />
          <h4 className="text-lg text-title">{title}</h4>
        </div>
        <div className='flex items-center gap-2'>
          <div
            onClick={() => dispatch(accountActionCreator.setBoostLevelChosen(+level))}
            className={`${s.boost} inline-flex px-2 py-2 text-sm rounded-lg opacity-100 text-yellow bg-yellow`}
            style={{ top: 30, right: 30 }}
          >
            <Lvlup className="w-5 h-5 fill-black" />
          </div>
          <div
            className="inline-flex px-4 py-2 text-sm border rounded-lg text-yellow bg-yellow bg-opacity-15 border-yellow"
            style={{ top: 30, right: 30 }}
          >
            {percent}
          </div>
        </div>
      </div>

      <div className="flex items-center mt-3">
        <div className="flex items-center justify-center w-10 h-10 mr-2 bg-lightBlack rounded-xl text-description">
          {levelState.condition ? <Withdraw /> : null}
          {levelState.stats ? <Partners /> : null}
        </div>
        <div>
          <h5 className="text-base text-description">
            {levelState.condition ? 'Liquidity in farming' : null}

            {levelState.stats ? 'Partners' : null}
          </h5>
          {levelState.condition ? (
            <div className="text-lg text-lightYellow">{liquidity}</div>
          ) : null}
          {levelState.stats ? partners : null}
        </div>
      </div>

      {levelState.condition ? (
        <>
          <div className="flex items-center mt-3">
            <div className="flex items-center justify-center w-10 h-10 mr-2 bg-lightBlack rounded-xl text-description">
              <TeamTurnover />
            </div>
            <div>
              <h5 className="text-base text-description">Team farming liquidity</h5>
              <div className="text-lg text-lightYellow">{teamTurnover}</div>
            </div>
          </div>
          <div className="flex items-center mt-3">
            <div className="flex items-center justify-center w-10 h-10 mr-2 bg-lightBlack rounded-xl text-description">
              <PersnonalWithdraw />
            </div>
            <div>
              <h5 className="text-base text-description">% of turnover</h5>
              <div className="text-lg text-lightYellow">{personalTurnover}</div>
            </div>
          </div>
        </>
      ) : null}

      {levelState.stats ? (
        <div className="mt-8 text-3xl font-semibold text-title"> {sum}</div>
      ) : null}
    </Block>
  );
};

export default LevelInfo;
