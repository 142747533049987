import WalletReducer from './wallet';
import AccountReducer from './account';
import swapReducer from './swap';
import addLiquidityReducer from './liquidityAdd';
import removeLiquidityReducer from './liquidityRemove';
import farmingReducer from './farmingAdd';
import applicationReducer from './application';
import liquidityReducer from './liquidity';
import graphicReducer from './graphic';
import multisendReducer from './multisend';
import launchpoolReducer from './launchpool';
import launchpadReducer from './launchpad';
import LevelsEnergyReducer from './levelsEnergy';

const Reducers = {
  launchpadReducer,
  WalletReducer,
  AccountReducer,
  swapReducer,
  multisendReducer,
  addLiquidityReducer,
  removeLiquidityReducer,
  farmingReducer,
  applicationReducer,
  liquidityReducer,
  graphicReducer,
  launchpoolReducer,
  LevelsEnergyReducer,
};

export default Reducers;
