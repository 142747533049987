export interface WalletConnectState {
  isWallet: boolean;
  address: string;
  trxBalance: number;
  nftContract: any | null | undefined;
  routeContract: any | null | undefined;
  fabricaContract: any | null | undefined;
  walletIsPresent: boolean;
  tronWeb: any;
  notCorrectChain: boolean;
  walletChain: boolean;
}

export enum WalletStateEmun {
  SET_WALLET = 'SET_WALLET',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_NFT_CONTRACT = 'SET_NFT_CONTRACT',
  SET_ROUTE_CONTRACT = 'SET_ROUTE_CONTRACT',
  SET_FABRICA_CONTRACT = 'SET_FABRICA_CONTRACT',
  SET_WALLET_PRESENT = 'SET_WALLET_PRESENT',
  SET_TRON_WEB = 'SET_TRON_WEB',
  SET_NOT_CORRECT_CHAIN = 'SET_NOT_CORRECT_CHAIN',
  SET_TRX_BALANCE = 'SET_TRX_BALANCE'
}

export interface setTrxBalance {
  type: WalletStateEmun.SET_TRX_BALANCE;
  payload: number
}

export interface setNotCorrectChain {
  type: WalletStateEmun.SET_NOT_CORRECT_CHAIN;
  payload: boolean;
}

export interface setRouterContract {
  type: WalletStateEmun.SET_ROUTE_CONTRACT;
  payload: any;
}

export interface setFabricaContract {
  type: WalletStateEmun.SET_FABRICA_CONTRACT;
  payload: any;
}

export interface setWalletAction {
  type: WalletStateEmun.SET_WALLET;
  payload: boolean;
}

export interface setAddressAction {
  type: WalletStateEmun.SET_ADDRESS;
  payload: string;
}

export interface setNFTContractAction {
  type: WalletStateEmun.SET_NFT_CONTRACT;
  payload: any;
}

export interface setTronWeb {
  type: WalletStateEmun.SET_TRON_WEB;
  payload: any;
}

export interface setWalletPreset {
  type: WalletStateEmun.SET_WALLET_PRESENT;
  payload: boolean;
}

export type WalletState = WalletConnectState;
export type WalletAction =
  | setWalletAction
  | setAddressAction
  | setNFTContractAction
  | setWalletPreset
  | setTronWeb
  | setRouterContract
  | setFabricaContract
  | setNotCorrectChain
  | setTrxBalance;
