import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import PairCard from './components/PairCard/';
import PairsSlider from './components/PairsSlider';

const Pairs: FunctionComponent = () => {
  const history = useHistory()

  return (
    <div className="mt-10 sm:mt-16 filter brightness-50">
      <div className="flex justify-between items-center">
        <h2 className="text-description text-2xl sm:text-3xl">Pairs</h2>
        <button onClick={() => history.push('/farming')} className="hidden sm:block bg-description bg-opacity-40 py-2 px-6 text-2xl rounded-lg text-black">
          All pairs
        </button>
        <button onClick={() => history.push('/farming')} className="text-yellow uppercase tracking-widest sm:hidden lg:hidden">
          All pairs
        </button>
      </div>
      <div className="hidden sm:flex flex-wrap gap-6 mt-5">
        <PairCard
          coinOneIcon="usdt"
          coinTwoIcon="exon"
          coinOneName="USDT"
          coinTwoName="EXON"
          liquidity="$1 000 000"
          apy="100%"
        />
        <PairCard
          coinOneIcon="trx"
          coinTwoIcon="exon"
          coinOneName="TRX"
          coinTwoName="EXON"
          liquidity="$1 000 000"
          apy="100%"
        />
        <PairCard
          coinOneIcon="trx"
          coinTwoIcon="usdt"
          coinOneName="TRX"
          coinTwoName="USDT"
          liquidity="$1 000 000"
          apy="100%"
        />
      </div>
      <PairsSlider />
    </div>
  );
};

export default Pairs;
