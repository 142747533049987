import { ReactComponent as Home } from './home.svg';
import { ReactComponent as Account } from './account.svg';
import { ReactComponent as Swap } from './swap.svg';
import { ReactComponent as Farming } from './farming.svg';
import { ReactComponent as Vote } from './vote.svg';
import { ReactComponent as Ido } from './ido.svg';
import { ReactComponent as Blog } from './blog.svg';
import { ReactComponent as OnePager } from './one-pager.svg';
import { ReactComponent as Faq } from './faq.svg';
import { ReactComponent as Audit } from './audit.svg';
import { ReactComponent as Multisend } from './multisend.svg';
import { ReactComponent as Analytics } from './analytics.svg';
import { ReactComponent as Launchpool } from './launchpool.svg'

interface IconsTypes {
  [Home: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Account: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Swap: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Farming: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Vote: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Ido: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Blog: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Multisend: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  OnePager: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Faq: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Audit: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Analytics: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Launchpool: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const Icons: IconsTypes = {
  Home,
  Account,
  Swap,
  Farming,
  Vote,
  Ido,
  Blog,
  OnePager,
  Faq,
  Audit,
  Analytics,
  Multisend,
  Launchpool
};

export default Icons;
