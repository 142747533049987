import Button from 'components/Button';
import useWalletSelector from 'hooks/useWalletSelector';
import { useEffect, useState } from 'react';
import { Range } from 'react-range';
import { useDispatch } from 'react-redux';
import { LevelsEnergyActionCreator } from 'store/reducers/levelsEnergy/action-creators';

const LevelsEnergyTab = () => {
  const [state, setState] = useState([0]);
  const {
    chosenPacket,
    levelsPrice,
    levelsBuyLimits,
    levelsLimitCount,
    userInfo,
  } = useWalletSelector(state => state.LevelsEnergyReducer);
  const { isWallet } = useWalletSelector(state => state.WalletReducer);
  const dispatch = useDispatch();

  const returnLevelsSumString = () => {
    let sumString = '';

    for (let i = 1; i <= state[0] + 1; i++) {
      if (i === 1) sumString = `${i}`;
      else sumString = `${sumString} + ${i}`;
    }
    return sumString;
  };

  useEffect(() => {
    if (!!userInfo.packet && userInfo.buy) {
      setState([userInfo.packet]);
    }
  }, [userInfo.packet]);

  const handleBuyPacket = () => {
    dispatch(LevelsEnergyActionCreator.buyPacketForTrx());
  };

  const handleClaimRevenue = () => {
    dispatch(LevelsEnergyActionCreator.withdrawReferrals());
  };

  return (
    <div className="px-8">
      <div className="flex flex-col items-center justify-between mt-12 md:flex-row">
        <div className="flex items-center mb-8 ">
          <h3 className="text-base sm:text-3xl text-title">Get the advantage right now</h3>
        </div>
      </div>
      <div className="">
        <div className="grid grid-rows-2 items-center xxl:grid-rows-1 xxl:grid-cols-2 gap-12">
          <p className="text-xl md:text-xl text-justify text-description mb-8">
            Energy premium is created for maximize and optimize your profit from
            service Exon Energy. From this tab you can for a very few price buy
            permanent revenue fee from your referrals. If your 1st level referrals will buy any of our available packets for sale,
            you will get 10% of their packet price as well. Also we made available option to buy better packet, so theres
            no need to concider of buying right away. Any time improve and that's it.
          </p>

          <div className="flex flex-col justify-between bg-darkGray px-8 py-8 rounded-4xl">
            <h2 className="text-2xl text-center mb-8 font-medium">
              Choose packet
            </h2>
            <div className="flex items-center gap-2 sm:text-xl text-base">
              <p>
                {(state[0] <= userInfo.packet && userInfo.buy)
                  ? 'You bought levels:'
                  : 'Buy levels:'}
              </p>
              <p className="text-yellow"> {returnLevelsSumString()}</p>
            </div>
            <div className="mt-4 mb-12">
              <Range
                step={1}
                min={0}
                max={4}
                values={state}
                onChange={values => {
                  dispatch(
                    LevelsEnergyActionCreator.setLevelsChosenPacket(values[0]),
                  );
                  setState(values);
                }}
                renderMark={({ props, index }) => (
                  <div
                    className={`transform -translate-x-1 ${index === state[0] && 'text-title'
                      } text-description select-none translate-y-6`}
                    {...props}
                  >
                    {index + 1}
                  </div>
                )}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    className="w-full p-1 rounded-lg bg-yellow"
                    style={{
                      ...props.style,
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    className="border-4 rounded-lg border-lightGrey focus:outline-none"
                    style={{
                      ...props.style,
                    }}
                  >
                    <div className="border w-0.5 h-5 bg-lightBlack border-lightBlack"></div>
                  </div>
                )}
              />
            </div>
            <div className="flex items-center mb-2 gap-2 sm:text-xl text-base">
              <p>TRX price:</p>
              <p className="text-yellow"> {levelsPrice[chosenPacket]}</p>
            </div>
            <div className="flex items-center mb-8 gap-2 sm:text-xl text-base">
              <p>Available buy packets:</p>
              <p className="text-yellow">
                {levelsBuyLimits[chosenPacket] - levelsLimitCount[chosenPacket]}
              </p>
            </div>
            <div className="text-center">
              <Button
                disabled={
                  !isWallet ||
                  (userInfo.packet >= state[0] && userInfo.buy) ||
                  levelsBuyLimits[chosenPacket] -
                  levelsLimitCount[chosenPacket] ===
                  0
                }
                onClick={handleBuyPacket}
              >
                Buy packet
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LevelsReferralRevenue = () => {
  return (
    <div className="flex flex-col justify-between bg-darkGray px-8 py-8 rounded-4xl">
      <h2 className="text-2xl text-center mb-4 font-medium">
        Referral revenue
      </h2>
      <div className="flex items-center gap-2 sm:text-xl text-base">
        <p>Referrals amount:</p>
        <p className="text-yellow"> userInfo.referrals</p>
      </div>
      <div className="flex items-center gap-2 sm:text-xl text-base">
        <p>Total revenue:</p>
        <p className="text-yellow"> userInfo.referralsAmount</p>
      </div>
      <div className="flex items-center gap-2 sm:text-xl text-base">
        <p>Available revenue:</p>
        <p className="text-yellow"> userInfo.availableAmount</p>
      </div>
      <div className="text-center">
        {/* <Button
          onClick={handleClaimRevenue}
          disabled={!isWallet || !userInfo.availableAmount}
        >
          Claim
        </Button>
        */}
      </div>
    </div>

  )
}

export default LevelsEnergyTab;
