import React, { FunctionComponent } from 'react';
import Block from 'components/Block';
import {ReactComponent as Turnover} from '../assets/turnover.svg';
import {ReactComponent as LevelIcon} from '../assets/levela.svg';
import {ReactComponent as LevelGet} from '../assets/levelGet.svg'
import s from './ProgramInfo.module.css'
import { ReactComponent as Price } from '../assets/price.svg';
import { rangs } from './rangs';
import useWalletSelector from 'hooks/useWalletSelector';
import {getTokenInfoByAddress} from 'utils/getTokenInfo';

interface Props {
  turnover: string;
  level: number;
  priceIn: string;
  title: string;
  active?: boolean;
  activeTurnover: boolean;
  activeLevel: boolean;
  activeWithdraw: boolean;
  nextLevel: boolean;
}

const ProgramInfo: FunctionComponent<Props> = ({
  turnover,
  level,
  priceIn,
  title,
  active,
  activeTurnover,
  activeLevel,
  activeWithdraw,
  nextLevel
}) => {
  const icon = rangs[`Rang${level}`];

  const { farmingList } = useWalletSelector( state => state.farmingReducer )
  const { choosenFarmingPoolId } = useWalletSelector( state => state.AccountReducer )
  console.log(activeTurnover);
  return (
    <Block
      light
      small
      className={`${s.main__block} px-6 sm:w-rang ${nextLevel ? 'bg-lightblack' : !active ? s.opacity : 'bg-darkGray'}`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="flex items-center justify-center w-10 h-10 mr-4 rounded-full bg-lightGrey">
            <img src={icon} alt="" />
          </div>
          <h4 className="text-sm font-semibold text-title">{title}</h4>
        </div>
      </div>
      <div className="flex items-center mt-3">
        <div className={`flex justify-center items-center ${ !activeTurnover && 'bg-lightBlack' } w-10 h-10 rounded-xl text-description mr-2`}>
          {activeTurnover
            ? <LevelGet viewBox="16 0 50 75" />
            : <Turnover />
          }
        </div>
        <div>
          <h5 className="text-xs text-description">
            Personal {getTokenInfoByAddress(farmingList.filter(el => el.poolId === choosenFarmingPoolId)[0]?.token0)?.name || 'token 1'} trade
          </h5>
          <div className="text-lg text-title">${turnover}</div>
        </div>
      </div>
      <div className="flex items-center mt-3">
        <div className={`flex justify-center items-center ${ !activeTurnover && 'bg-lightBlack' } w-10 h-10 rounded-xl text-description mr-2`}>
          {activeTurnover
            ? <LevelGet viewBox="16 0 50 75" />
            : <Turnover />
          }
        </div>
        <div>
          <h5 className="text-xs text-description">
            Personal {getTokenInfoByAddress(farmingList.filter(el => el.poolId === choosenFarmingPoolId)[0]?.token1)?.name || 'token 2'} trade
          </h5>
          <div className="text-lg text-title">${turnover}</div>
        </div>
      </div>
      <div className="flex items-center mt-3">
        <div className={`flex justify-center items-center ${ !activeLevel && 'bg-lightBlack' } w-10 h-10 rounded-xl text-description mr-2`}>
          {activeLevel
            ? <LevelGet viewBox="16 0 50 75" />
            : <LevelIcon />
          }
        </div>
        <div>
          <h5 className="text-xs text-description">Level</h5>
          <div className="text-lg text-title">{level}</div>
        </div>
      </div>
      <div className="flex items-center mt-3">
      <div className={`flex justify-center items-center ${ !activeLevel && 'bg-lightBlack' } w-10 h-10 rounded-xl text-description mr-2`}>
          {activeWithdraw
            ? <LevelGet viewBox="16 0 50 75" />
            : <Price />
          }
        </div>
        <div>
          <div className="flex">
            <h5 className="text-xs text-description">Prize in </h5>
            <svg
              className="ml-2"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.6465 2.99757C16.1843 2.38423 16.6159 1.55588 16.9931 0.408582C16.34 0.39604 15.6839 0.375021 15.0461 0.354466C13.3182 0.299246 11.5315 0.24182 9.7961 0.355859H9.79605C8.49383 0.441156 7.33399 0.614479 6.25074 0.885466C4.50055 1.55344 3.02815 2.7894 2.06445 4.39007C3.65647 4.44494 9.07808 4.50754 11.5984 4.48117C13.5414 4.46132 14.752 4.01759 15.6465 2.99757Z"
                fill="#BBBBBB"
                fillOpacity="0.6"
              />
              <path
                d="M15.6473 9.72895C16.1849 9.11585 16.6163 8.28826 16.9935 7.14177C14.0745 7.11146 2.65339 7.07523 1.10515 7.11872C0.949772 7.76248 0.871094 8.42331 0.871094 9.08612C0.871094 9.77738 0.957436 10.4678 1.12786 11.1409C2.57941 11.1951 12.3881 11.1701 12.9676 11.1055L12.9907 11.1014C14.1463 10.9139 14.9905 10.4779 15.6473 9.72895Z"
                fill="#BBBBBB"
                fillOpacity="0.6"
              />
              <path
                d="M2.03711 13.7332C2.16224 13.9441 2.29637 14.1489 2.43892 14.3472C3.69398 16.0931 5.6244 17.2328 7.74735 17.5741C7.74735 17.5741 11.4921 18.0731 13.771 17.1557C15.9102 16.2944 16.8595 14.0709 16.9935 13.6637C15.9925 13.6534 3.18476 13.6947 2.03711 13.7332Z"
                fill="#BBBBBB"
                fillOpacity="0.6"
              />
            </svg>
          </div>

          <div className="text-lg text-lightYellow">{priceIn}</div>
        </div>
      </div>
    </Block>
  );
};

export default ProgramInfo;
