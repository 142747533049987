import React, { FunctionComponent, useState } from 'react';
import Block from 'components/Block';
import { Facebook, Telegram, Twitter, WhatsApp } from 'components/Icons';
import IconWrapper from 'components/Icons/IconWrapper';
import { ReactComponent as Copy } from '../../../../assets/copy.svg';
import useWalletSelector from 'hooks/useWalletSelector';
import 'styles/tooltip.css';
import Config from 'config';
// import { isAndroid } from 'react-device-detect';
import copyTextLib from 'copy-to-clipboard';
import s from '../../../../components/Footer/Footer.module.css'
import { TelegramShareButton, TwitterShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share';

const Partner: FunctionComponent = () => {
  const partnerLink = Config().REF_LINK;
  const { currentAccount, upliner } = useWalletSelector(
    state => state.AccountReducer,
  );
  const [copy, setCopy] = useState(false);

  const copyText = (textToCopy: string) => {
    copyTextLib(textToCopy);
  };

  const copyToClipBoard = () => {
    const textToCopy = partnerLink + currentAccount.toString();
    copyText(textToCopy);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };

  return (
    <Block className="mt-10 bg-cardBg">
      <p className="text-lg text-description">Your partner link</p>
      <div className="flex">
        <span
          onClick={(e) => {
            e.preventDefault()
            copyToClipBoard()
          } }
          className="mr-2 underline cursor-pointer text-title sm:text-xl xl:text-2xl xl:mr-5 sm:w-30 md:w-52 lg:w-auto"
        >
          {partnerLink.replace('https://', '') + currentAccount.toString()}
        </span>
        <div className="relative">
          <Copy className="cursor-pointer" onClick={() => copyToClipBoard()} />
          <div id="tooltip" className={copy ? `tooltip active` : `tooltip`}>
            Copied
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8 lg:flex-row lg:items-center">
        <p className="mb-5 text-xl xl:text-2xl lg:mb-0 text-description lg:mr-10">Share link</p>
        <div className="flex gap-7">
          <TelegramShareButton url={partnerLink + currentAccount.toString()}>
            <IconWrapper
              className={`${s.footer__icon} w-10 h-10`}
            >
              <Telegram />
            </IconWrapper>
          </TelegramShareButton>

          <TwitterShareButton url={partnerLink + currentAccount.toString()}>
            <IconWrapper
              className={`${s.footer__icon} w-10 h-10`}
            >
              <Twitter />
            </IconWrapper>
          </TwitterShareButton>
          <FacebookShareButton url={partnerLink + currentAccount.toString()}>
            <IconWrapper
              className={`${s.footer__icon} w-10 h-10`}
            >
              <Facebook />
            </IconWrapper>
          </FacebookShareButton>
          
        </div>
      </div>
      <div className="flex flex-col mt-7">
        <p className="mr-10 sm:text-base text-description">Your upliner ID</p>
        <p className="mr-10 sm:text-base xl:text-2xl text-title">{upliner}</p>
      </div>
    </Block>
  );
};

export default Partner;
