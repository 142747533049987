import React, { FunctionComponent } from 'react';
import Modal from 'components/Modal';
import SuccessIcon from './assets/success.svg';
import useWalletSelector from 'hooks/useWalletSelector';
import { useDispatch } from 'react-redux';
import { swapActionCreator } from 'store/reducers/swap/action-creators';
import { coinIcons } from 'components/CoinIcons';

const SwapSuccess: FunctionComponent = () => {
  const { swapState } = useWalletSelector(state => state.swapReducer);
  const { firstTokenAmount, secondTokenAmount } = useWalletSelector(
    state => state.swapReducer,
  );
  const { firstToken, secondToken } = useWalletSelector(
    state => state.applicationReducer,
  );
  const dispatch = useDispatch();

  const handlCloseModal = () => {
    dispatch(swapActionCreator.setCurrentSwapState('initial'));
  };
  return (
    <>
      {swapState === 'swapSuccess' && (
        <Modal closeModal={handlCloseModal}>
          <div className="flex flex-col items-center">
            <img src={SuccessIcon} className="w-20 h-20" alt="Success" />
            <h2 className="text-title text-center text-4xl sm:mt-10 mt-5">Swap was successful</h2>
            <div className=" text-description sm:mt-8 mt-4 flex flex-col">
              <div className="flex items-center justify-center">
                <img className='w-8 h-8' src={coinIcons[firstToken.icon!]} alt="" />
                <span className="text-lightYellow mx-2 text-3xl">
                  {firstTokenAmount}
                </span>
                <span className='text-2xl'>
                  {firstToken.name} 
                </span>
              </div>
              <div className="w-5 self-center transform rotate-90">{'->'}</div>
              <div className="flex items-center justify-center">
                <img className='w-8 h-8' src={coinIcons[secondToken.icon!]} alt="" />
                <span className="text-lightYellow mx-2 text-3xl">
                  {secondTokenAmount}
                </span>
                <span className='text-2xl'>
                  {secondToken.name}
                </span>
              </div>
              
            </div>
            <p className="text-center text-description mt-10">
              Transaction is being comfirmed on-chain. Swap was successful and
              is confirmed.
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SwapSuccess;
