import React, { FunctionComponent } from 'react';
import { coinIcons } from '../../CoinIcons';
import logoUndefined from '../../../components/CoinIcons/assets/logoundefined.png'
import { GetHexAddress } from 'utils/getHexAddres';
import { Arrow } from 'components/Icons';
export interface Props { isToken: boolean;
  tokenName: string | undefined;
  tokenIcon: string | undefined;
  tokenAddress: string | undefined;
  onClick: any;
}

const ButtonSwapToken: FunctionComponent<Props> = ({isToken, tokenName, tokenIcon, tokenAddress, onClick}) => {
  return(
    <div onClick={onClick}>
      {!isToken
        ? <ButtonSwap name={tokenName || ''} icon={tokenIcon || ''} address={tokenAddress || ''} />
        : <ButtonSwapEmpty /> }
    </div>
  )
}




export interface TokenProps {
  icon: string;
  name: string;
  address: string;
}

const ButtonSwap: FunctionComponent<TokenProps> = ({ icon, name, address }) => {
  const isMobile = window.innerWidth <= 640

  return (
    <>
      <button
        className="inline-flex justify-between py-2 text-base font-medium border border-transparent w-14 sm:min-w-tokenBlock rounded-md shadow-sm bg-buttonBlack text-description hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500 sm:ml-3 sm:w-auto sm:text-sm"
      >
        <span className="flex items-center justify-around w-full ml-1">
          <img src={coinIcons[icon] || JSON.parse(localStorage.getItem('tokenList') || '[]')
          .filter((el: { address: string; }) => el.address === GetHexAddress(address))[0]?.icon || logoUndefined} alt="coin" className="w-6" />
          {!isMobile && <div className="px-1">{name}</div>}
          <svg
            // className='ml-2 sm:ml-0'
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.1045 5.86499L7.50281 10.774L2.90109 5.86499C2.78766 5.74399 2.63672 5.67749 2.47594 5.67749C2.31516 5.67749 2.16422 5.74399 2.05078 5.86499C1.93734 5.98599 1.875 6.14699 1.875 6.31899C1.875 6.49099 1.93734 6.65149 2.05078 6.77199L7.07812 12.1345C7.19156 12.2555 7.34203 12.322 7.50281 12.322C7.66359 12.322 7.81406 12.2555 7.92797 12.1345L12.9548 6.77249C13.1817 6.53049 13.1817 6.10649 12.9548 5.86499C12.7205 5.61549 12.3389 5.61549 12.1045 5.86499Z"
              fill="#BBBBBB"
              stroke="#BBBBBB"
              stroke-width="0.2"
            />
          </svg>
        </span>
      </button>
    </>
  );
};

const ButtonSwapEmpty: FunctionComponent = () => {
  return (
    <>
      <button
        className="w-14 inline-flex justify-between rounded-md border 
                  border-transparent shadow-sm px-6 py-3 sm:py-2.5 bg-buttonBlack text-base 
                  font-medium text-description hover:bg-black focus:outline-none 
                  focus:ring-2 focus:ring-offset-2 focus:ring-black-500 sm:ml-3 sm:w-auto sm:text-sm"
      >
        <span className="flex items-center justify-center w-18">
          <div className="hidden px-4 uppercase sm:block">Select</div>
          <Arrow className="transform -rotate-90" />
        </span>
      </button>
    </>
  );
};

export default ButtonSwapToken;
