import React, { FunctionComponent, useEffect, useState } from 'react';
import LiquidityCard from '../LiquidityCard';
import { useDispatch } from 'react-redux';
import { LiquidityActionCreator } from 'store/reducers/liquidity/action-creator';
import useWalletSelector from 'hooks/useWalletSelector';
import ConnectWalletButton from 'components/Button/ConnectWalletButton';
import Loading from 'components/Popups/assets/loader.gif';



const PoolInitial: FunctionComponent = () => {
  const { liquidityPairs } = useWalletSelector(state => state.liquidityReducer);
  const { isWallet } = useWalletSelector(state => state.WalletReducer)
  const { isEmpty } = useWalletSelector(state => state.liquidityReducer)
  const { isFetching } = useWalletSelector(state => state.liquidityReducer)

  const dispatch = useDispatch();
  const handlAddLiquidity = () => {
    dispatch(LiquidityActionCreator.setLiquidityState('createPool'));
    dispatch(LiquidityActionCreator.setLiquidityMainState('createPool'));
  };

  return (
    <div className="flex justify-center flex-col px-10 sm:px-8 py-10">
      <div className={`text-center mx-auto w-max ${!isEmpty ? 'mb-10' : null}`}>
        {isWallet ? (
          <button
            type="button"
            onClick={handlAddLiquidity}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-2 bg-yellow text-base font-medium text-mainBlack hover:bg-black-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-xl mt"
          >
            Add liquidity
          </button>
        ) : (
          <ConnectWalletButton />
        )}
        
      </div>
      {<div className="flex flex-col gap-10">
          {isWallet ? (liquidityPairs.length !== 0 || !isFetching) ? liquidityPairs.map(value => (
            <LiquidityCard
              pool
              liquidity={value.liquidity}
              token0Amount={value.token0Amount}
              token1Amount={value.token1Amount}
              lpAddress={value.address}
              token0={value.token0}
              token1={value.token1}
              totalSupply={value.totalSupply}
              token0Icon={value.token0Info?.icon!}
              token1Icon={value.token1Info?.icon!}
              token0Symbol={value.token0Info.name!}
              token1Symbol={value.token1Info.name!}
              personalLiquidityToken0={value.personalLiquidityToken0}
              personalLiquidityToken1={value.personalLiquidityToken1}
              firstTokenBalance={value.firstTokenBalance}
              secondTokenBalance={value.secondTokenBalance}
              lpPriceInUsd={value.usdPerLp}
            />
          )) : !isEmpty && <div className='text-center'>
                <span>Searching for liquidity</span>
                <img src={Loading} alt="Minting" style={{ width: '25%', margin: '0 auto 20px' }} />
              </div> : null}
      </div>}
      {isEmpty && (
          <>
            <p className="text-xl mt-7 text-center">
              Your liquidity
              <span
                className="ml-2 inline-flex justify-center items-center w-5 h-5 rounded-full text-sm"
                style={{ backgroundColor: '#5F5F5F', color: '#171612' }}
              >
                ?
              </span>
            </p>
            <p className="text-description mb-10 text-center">Your liquidity is empty</p>
          </>
        )}
    </div>
  );
};

export default PoolInitial;
