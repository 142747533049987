import React, { FunctionComponent } from 'react';
import { Exon } from 'components/Icons';
import useWalletSelector from 'hooks/useWalletSelector';

const TeamProfitPartners: FunctionComponent = () => {
  const { levels, farmingInfo, totalTeamRevenue, withdrawedRevenue, totalFarmingWithdrawed } = useWalletSelector(state => state.AccountReducer);
  const { exonUsdPrice } = useWalletSelector(state => state.applicationReducer)
  const { farmingList } = useWalletSelector( state => state.farmingReducer )

  const getLevelAmount = (allLevels: number[]) => {
    var result: number = 0;
    for (var i = 0; i < allLevels.length; i++) {
      result += allLevels[i];
    }
    return result;
  };

  const getRankWithdraw = () => {
    const rewards = [1, 10, 100, 500, 1000, 10000]
    let price = 0
    for (let i = 0; i < farmingInfo.withdrawedLevels; i++) {
      price += rewards[i]
    }
    return price * exonUsdPrice
    
  }

  const getFarmingProfit = () => {
    let profit = 0
    for (let i = 0; i < farmingList.length; i++) {
      profit += farmingList[i].personalEarn
    }
    return profit * exonUsdPrice
  }

  const getWithdrawedRevenue = () => {
    let totalWithdrawedRevenue = 0
    for (let i = 0; i < withdrawedRevenue.length; i++) {
      totalWithdrawedRevenue += +withdrawedRevenue[i].amount
    }
    return totalWithdrawedRevenue * exonUsdPrice
  }
  

  return (
    <div className="flex flex-col sm:flex-row-reverse justify-between mt-10 sm:mt-0 mb-5 order-2 sm:order-first sm:pl-6">
      <div className='mt-4 sm:mt-0'>
        <h3 className="text-lg text-center text-description">Total profit</h3>
        <div className="mt-1 text-yellow text-center text-xl lg:text-2xl font-semibold">
          $ {((totalTeamRevenue * exonUsdPrice ) + getWithdrawedRevenue() + getFarmingProfit() + totalFarmingWithdrawed * exonUsdPrice + getRankWithdraw()).toFixed(2)}
        </div>
      </div>
      <div className='mt-4 sm:mt-0'>
        <h3 className='text-description text-center' >All partners</h3>
        <div className="mt-1 text-yellow text-xl text-center lg:text-2xl font-semibold">
          {getLevelAmount(levels)}
        </div>
      </div>  
      <div className='mt-4 sm:mt-0'>
        <h3 className='text-description text-center' >Team farming profit</h3>
        <div className="mt-1 text-yellow text-xl text-center lg:text-2xl font-semibold">
          $ {((totalTeamRevenue * exonUsdPrice) + getWithdrawedRevenue() ).toFixed(2)}
        </div>
      </div>
    </div>
  );
};

export default TeamProfitPartners;
