import React, { FunctionComponent, useState } from 'react';
import AccountItem from './components/AccountItem';
import Picture from './assets/nftPict.svg';
import { Arrow } from 'components/Icons';
import useWalletSelector from 'hooks/useWalletSelector';
import { useDispatch } from 'react-redux';
import {ReactComponent as Ambassador} from './assets/ambassador.svg';
import { accountActionCreator } from 'store/reducers/account/action-creators';
import config from '../../../../config/index'
import 'styles/hideScroll.css';
import { WalletConnectActionCreator } from 'store/reducers/wallet/action-creators';

const NFTAccount: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { currentAccount, accounts } = useWalletSelector(
    state => state.AccountReducer,
  );
  const { liquidityPairs } = useWalletSelector( state => state.liquidityReducer )

  const handlSelectAccount = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    dispatch(accountActionCreator.setAccount(parseInt(e.currentTarget.id)));
    dispatch(accountActionCreator.getLevels());
    dispatch(
      accountActionCreator.setCurrentAccount(parseInt(e.currentTarget.id)),
    );
    setIsOpen(!isOpen);
  };

  const isAmbassador = () => {
    if(currentAccount === 666) return true
    else if(liquidityPairs.length){
      let usdtExonPair = liquidityPairs.filter(el => (el.token0Info.name === 'EXON' && el.token1Info.name === 'USDT') || (el.token0Info.name === 'USDT' && el.token1Info.name === 'EXON'))[0]
      return ((usdtExonPair?.liquidity * usdtExonPair?.usdPerLp) >= 1000) && !!config().AMBASSADORS.filter(el => el === currentAccount)[0]
    }
  }

  return (
    <div className="mt-8">
      <div
        className={`${isOpen ? 'bg-lightBlack p-5 rounded-4xl' : null}`}
        style={{ maxHeight: '25rem' }}
      >
        <div className="flex items-center">
          <div className="relative">
            <img src={Picture} alt="" className="w-20 lg:w-auto" />
            {isAmbassador() && <Ambassador className='absolute top-0 w-16 h-w-16 lg:w-28 lg:h-28 left-2 lg:left-5' />}
          </div>
          <h2 className="ml-5 text-xl lg:text-3xl text-description mr-7">
            Exon NFT
            <br /> ID: {currentAccount}
          </h2>
          <div
            className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer bg-cardBg"
            onClick={() => setIsOpen(!isOpen)}
          >
            <Arrow
              className={`transform ${isOpen ? 'rotate-90' : '-rotate-90'}`}
            />
          </div>
          {/* <div onClick={() => dispatch(WalletConnectActionCreator.createPopup(true))} className="flex items-center justify-center w-10 h-10 ml-4 text-2xl font-normal text-black rounded-full cursor-pointer bg-yellow">
            <div style={{transform: 'translate(0, -2%)'}}>
              +
            </div>
          </div> */}
        </div>

        {isOpen ? (
          <>
            <hr className="mx-10 border my-7 border-darkGray" />
            <div
              className="overflow-scroll hide-scroll"
              style={{ maxHeight: '12rem', scrollbarWidth: 'none' }}
            >
              <div>
                {accounts.map((value, key) => {
                  return (
                    <AccountItem
                      key={`accountItem_${key}`}
                      handlSelect={handlSelectAccount}
                      accountId={value.toString()}
                    />
                  );
                })}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default NFTAccount;
