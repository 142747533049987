import { TokenInfo } from '../types';

export type liquidityStatesType =
  | 'initial'
  | 'add'
  | 'remove'
  | 'createPool'
  | 'addLiquidityConfirm'
  | 'addLiquiditySuccess'
  | 'receiveConfirm'
  | 'removeLiquidityConfirmed'
  | 'removeLiquidityConfirm'
  | 'removeLiquiditySuccess'
  | 'steps'
  | 'rmSteps';

export type liquidityMainStateType = 'createPool' | 'add' | 'initial';

export type LiquidityPairsType = {
  address: string;
  liquidity: number;
  totalSupply: number;
  token0: string;
  token1: string;
  token1Amount: number;
  token0Amount: number;
  token0Info: TokenInfo;
  token1Info: TokenInfo;
  personalLiquidityToken0: number;
  personalLiquidityToken1: number;
  firstTokenBalance: number;
  secondTokenBalance: number;
  usdPerLp: number;
};

export interface LiquidityStateInterface {
  liquidityState: liquidityStatesType;
  liquidityMainState: liquidityMainStateType;
  liquidityPairs: LiquidityPairsType[];
  isFetching: boolean;
  isEmpty: boolean;
}

export enum LiquidityEnum {
  SET_LIQUIDITY_STATE = 'SET_LIQUIDITY_STATE',
  SET_LIQUIDITY_PAIRS = 'SET_LIQUIDITY_PAIRS',
  SET_LIQUIDITY_MAIN_STATE = 'SET_LIQUIDITY_MAIN_STATE',
  SET_IS_FETCHING = 'SET_IS_FETCHING',
  SET_IS_EMPTY = 'SET_IS_EMPTY',
  RESET_LIQUIDITY_PAIRS = 'RESET_LIQUIDITY_PAIRS'
}

export interface setLiquidityMainState {
  type: LiquidityEnum.SET_LIQUIDITY_MAIN_STATE;
  payload: liquidityMainStateType;
}

export interface setLiquidityState {
  type: LiquidityEnum.SET_LIQUIDITY_STATE;
  payload: liquidityStatesType;
}

export interface setLiquidityPairs {
  type: LiquidityEnum.SET_LIQUIDITY_PAIRS;
  payload: LiquidityPairsType[];
}

export interface setIsFetching {
  type: LiquidityEnum.SET_IS_FETCHING;
  payload: boolean;
}

export interface setIsEmpty {
  type: LiquidityEnum.SET_IS_EMPTY;
  payload: boolean;
}

export interface resetLiquidityPairs {
  type: LiquidityEnum.RESET_LIQUIDITY_PAIRS
}

export type LiquidityState = LiquidityStateInterface;
export type LiquidityAction =
  | setLiquidityState
  | setLiquidityPairs
  | setLiquidityMainState
  | setIsFetching
  | setIsEmpty
  | resetLiquidityPairs;
