import { TokenInfo } from '../types';

export type SwapSetting = {
  slippage: number;
  deadline: number;
  expertMode?: boolean;
  noRoutes?: boolean;
};

export type swapState = 'initial' | 'swapConfirm' | 'swapSuccess' | 'walletWarning' | 'steps' ;

export interface SwapState {
  inProcess: boolean;
  firstTokenApprove: boolean;
  secondTokenApprove: boolean;
  firstTokenInfo: TokenInfo;
  secondTokenInfo: TokenInfo;
  firstTokenBalance: number;
  secondTokenBalance: number;
  firstTokenAmount: number;
  secondTokenAmount: number;
  priceFirstInSecond: number;
  priceSecondInFirst: number;
  priceImpact: number;
  swapFee: number;
  swapSetting: SwapSetting;
  swapSettingModal: boolean;
  swapConfirmModal: boolean;
  swapState: swapState;
  firstTokenReserve: number;
  secondTokenReserve: number;
}

export interface SwappedTokens {
  firstTokenInfo: TokenInfo;
  secondTokenInfo: TokenInfo;
  firstTokenReserve: number;
  secondTokenReserve: number;
  firstTokenAmount: number;
  secondTokenAmount: number;
  firstTokenBalance: number;
  secondTokenBalance: number;
}

export enum SwapStateEmun {
  SET_PROCESS = 'SET_PROCESS',
  SET_CONFIRM_SWAP = 'SET_CONFIRM_SWAP',
  SET_FIST_TOKEN_APPROVE = 'SET_FIST_TOKEN_APPROVE',
  SET_SECOND_TOKEN_APPROVE = 'SET_SECOND_TOKEN_APPROVE',
  SET_FIRST_TOKEN_INFO = 'SET_FIRST_TOKEN_INFO',
  SET_SECOND_TOKEN_INFO = 'SET_SECOND_TOKEN_INFO',
  SET_FIRST_TOKEN_AMOUNT = 'SET_FIRST_TOKEN_AMOUNT',
  SET_SECOND_TOKEN_AMOUNT = 'SET_SECOND_TOKEN_AMOUNT',
  SET_PRICE_FIRST_IN_SECOND = 'SET_PRICE_FIRST_IN_SECOND',
  SET_PRICE_SECOND_IN_FIRST = 'SET_PRICE_SECOND_IN_FIRST',
  SET_PRICE_IMPACT = 'SET_PRICE_IMPACT',
  SET_SWAP_FEE = 'SET_SWAP_FEE',
  SET_SWAP_SETTING = 'SET_SWAP_SETTING',
  SET_FIRST_TOKEN_BALANCE = 'SET_FIRST_TOKEN_BALANCE',
  SET_SECOND_TOKEN_BALANCE = 'SET_SECOND_TOKEN_BALANCE',
  SET_SWAP_SETTING_MODAL = 'SET_SWAP_SETTING_MODAL',
  SET_SWAP_STATE = 'SET_SWAP_STATE',
  SET_TOKENS_RESERVE = 'SET_TOKENS_RESERVES',
  SET_SWAPPED_TOKENS = 'SET_SWAPPED_TOKENS'
}

export interface setTokensReserve {
  type: SwapStateEmun.SET_TOKENS_RESERVE;
  payload: { firstTokenReserve: number; secondTokenReserve: number };
}

export interface setSwapState {
  type: SwapStateEmun.SET_SWAP_STATE;
  payload: swapState;
}

export interface setSwapSettingModal {
  type: SwapStateEmun.SET_SWAP_SETTING_MODAL;
  payload: boolean;
}

export interface setFirstTokenBalance {
  type: SwapStateEmun.SET_FIRST_TOKEN_BALANCE;
  payload: number;
}

export interface setSecondTokenBalance {
  type: SwapStateEmun.SET_SECOND_TOKEN_BALANCE;
  payload: number;
}

export interface setSwapSetting {
  type: SwapStateEmun.SET_SWAP_SETTING;
  payload: SwapSetting;
}

export interface setSwapConfirtAction {
  type: SwapStateEmun.SET_CONFIRM_SWAP;
  payload: boolean;
}

export interface setSwapFirstTokenApprove {
  type: SwapStateEmun.SET_FIST_TOKEN_APPROVE;
  payload: boolean;
}

export interface setSwapSecondTokenApprove {
  type: SwapStateEmun.SET_SECOND_TOKEN_APPROVE;
  payload: boolean;
}

export interface setSwapInProcessAction {
  type: SwapStateEmun.SET_PROCESS;
  payload: boolean;
}

export interface setSwapFirstTokenInfo {
  type: SwapStateEmun.SET_FIRST_TOKEN_INFO;
  payload: TokenInfo;
}

export interface setSwapSecondTokenInfo {
  type: SwapStateEmun.SET_SECOND_TOKEN_INFO;
  payload: TokenInfo;
}

export interface setSwapFirstTokenAmount {
  type: SwapStateEmun.SET_FIRST_TOKEN_AMOUNT;
  payload: number;
}

export interface setSwapSecondTokenAmount {
  type: SwapStateEmun.SET_SECOND_TOKEN_AMOUNT;
  payload: number;
}

export interface setSwapPriceFirstInSecond {
  type: SwapStateEmun.SET_PRICE_FIRST_IN_SECOND;
  payload: number;
}

export interface setSwaptPriceSecondInFirst {
  type: SwapStateEmun.SET_PRICE_SECOND_IN_FIRST;
  payload: number;
}

export interface setSwapPriceImpact {
  type: SwapStateEmun.SET_PRICE_IMPACT;
  payload: number;
}

export interface setSwapFeeAction {
  type: SwapStateEmun.SET_SWAP_FEE;
  payload: number;
}

export interface setSwappedTokens {
  type: SwapStateEmun.SET_SWAPPED_TOKENS;
  payload: SwappedTokens
}

export type SwapCurrentState = SwapState;
export type SwapAction =
  | setSwapInProcessAction
  | setSwapFirstTokenInfo
  | setSwapSecondTokenInfo
  | setSwapFirstTokenAmount
  | setSwapSecondTokenAmount
  | setSwapPriceFirstInSecond
  | setSwaptPriceSecondInFirst
  | setSwapPriceImpact
  | setSwapFeeAction
  | setSwapConfirtAction
  | setSwapFirstTokenApprove
  | setSwapSecondTokenApprove
  | setSwapSetting
  | setFirstTokenBalance
  | setSecondTokenBalance
  | setSwapState
  | setTokensReserve
  | setSwapSettingModal
  | setSwappedTokens;
