import useWalletSelector from "hooks/useWalletSelector"


export const GetHexAddress = (address: string) => {
    const { tronWeb } = useWalletSelector(state => state.WalletReducer)
    let hexAddress
    try {
        hexAddress = address.replace('41', '0x')
    } catch (error) {
        console.log(error);
        return 
    }
    debugger
      if(!hexAddress.match(/^(0x)?[0-9a-f]{40}$/)) return address
      hexAddress = tronWeb.address.fromHex(hexAddress)
    debugger

    return hexAddress
}