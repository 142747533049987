import {ReactComponent as Arrow} from '../../../../components/Icons/assets/arrow.svg'
import { Dispatch, FunctionComponent, useEffect, useState } from "react"
import { graphicActionCreator } from 'store/reducers/graphic/action-creator'

type Props = {
    elAmount: number,
    currentPage: number,
    dispatch: Dispatch<any>
    type: string,
    currentAction?: string,
    firstTokenAddress?: string,
    secondTokenAddress?: string
}

export const PaginationEl: FunctionComponent<Props> = ({elAmount, currentPage, type, dispatch, currentAction, firstTokenAddress, secondTokenAddress}) => {
    const [paginationValue, setPaginationValue] = useState<Array<any>>([])
    const pagination = Math.ceil(elAmount/10)
    
    const setInitialPagination = (pagination: number) => {
        let initialPagination: Array<any> = []
        for ( let i = 1; i <= pagination; i++ ) {
            if( i === 7 ){
                initialPagination.push(pagination)
                setPaginationValue(initialPagination)
                return
            }
            if( i === pagination ){
                initialPagination.push(pagination)
                setPaginationValue(initialPagination)
                return
            }
            if( i === 6  ){
                initialPagination.push(pagination === 7 ? i : '...')
            }else{
                initialPagination.push(i)
            }
        }
    }
    
    useEffect(() => {
        setInitialPagination(pagination)
    }, [pagination])

    const handleChangePage = (page: number) => {
        if(type === 'transactions'){
            dispatch(graphicActionCreator.getTransactionsData(page, currentAction || 'All', firstTokenAddress || '', secondTokenAddress || ''))
        }else{
            dispatch(graphicActionCreator.getPairs(page))
        }
    }

    useEffect(() => {
if(pagination === 7){
            return
        }else if(currentPage === pagination){
            if(currentPage < 7){
                return
            }else{
                setPaginationValue([ 1, '...', currentPage-5, currentPage-4, currentPage-3, currentPage-2, currentPage-1, currentPage ])
            }
        }else if(currentPage === 1){
            setInitialPagination(pagination)
        }else if(pagination - 5 < currentPage){
            currentPage === pagination-4 && setPaginationValue([1, '...', currentPage-1, currentPage, currentPage+1, currentPage+2, currentPage+3, pagination])
            currentPage === pagination-3 && setPaginationValue([1, '...', currentPage-2, currentPage-1, currentPage, currentPage+1, currentPage+2, pagination])
        }else if(currentPage > 4){
            setPaginationValue([1, '...', currentPage-2, currentPage-1, currentPage, currentPage+1, currentPage+2, '...', pagination ])
        }else if(currentPage <= 4){
            currentPage === 4 && setPaginationValue([1, currentPage-2, currentPage-1, currentPage, currentPage+1, '...', pagination])
            currentPage === 3 && setPaginationValue([currentPage-2, currentPage-1, currentPage, currentPage+1, currentPage+2, '...', pagination])
        }
    }, [currentPage])

    return(
        <div className="flex items-center justify-center select-none mt-4 xl:mt-10">
            <div onClick={() => currentPage-1 !== 0 && handleChangePage(currentPage-1)} className="py-3 px-1 lg:px-4 mx-1 ls:mx-5 rounded-xl ">
                <Arrow className='cursor-pointer w-2 h-4 lg:w-4 lg:h-6' />
            </div>
            <div className="flex items-center lg:text-xl text-xs text-center px-5 py-2 rounded-xl">
                {paginationValue.map(el => {
                    return <div 
                            onClick={() => el !== '...' && handleChangePage(el)}
                            className={`w-5 h-5 lg:w-8 lg:h-8 cursor-pointer flex justify-center items-center ${currentPage === el ? 'bg-lightYellow text-black rounded-md' : 'text-title'}`} 
                            >
                                {el}
                            </div>
                })}
            </div>
            <div onClick={() => currentPage+1 <= pagination && handleChangePage(currentPage+1)} className="py-3 px-1 lg:px-4 mx-1 ls:mx-5 rounded-xl ">
                <Arrow className='cursor-pointer w-2 h-4 lg:w-4 lg:h-6 transform rotate-180' />
            </div>
        </div>
    )
}
