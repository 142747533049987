import { LaunchpadAction, LaunchpadEnum, LaunchpadState } from "./types"

const InitialState: LaunchpadState = {
    pools: [],
    activePoolId: 0,
    stakedOnly: false,
    activeIdo: null,
    buyDetails: {
        selectedToken: '',
        tokenPrice: 0,
        amountIn: 0,
        amountOut: 0,
				tokenInBalance: 0,
				tokenOutBalance: 0,
        decimal:6
    },
    whitelistDetails: {
        selectedToken: '',
        tokenAmount: 0,
    },
    launchpadState: 'initial',
}

export default function launchpadReducer(
    state = InitialState,
    action: LaunchpadAction
){
    switch (action.type) {
        case LaunchpadEnum.SET_LAUNCHPAD_POOLS:
            return {...state, pools: action.payload}
        case LaunchpadEnum.SET_LAUNCHPAD_STATE:
            return {...state, launchpadState: action.payload}
        case LaunchpadEnum.SET_ACTIVE_IDO_POOL:
            return { ...state, activeIdo: action.payload.activeIdo, activePoolId: action.payload.activePoolId}
        case LaunchpadEnum.SET_SELECTED_IDO_TOKEN_WHITELIST:
            let tokenAmount 
            if(action.payload === '') tokenAmount = 0
            else {
                tokenAmount = state.activeIdo.whiteListTokens
                    .map((el: string, index: number) => el === action.payload ? state.activeIdo.whiteListAmounts[index] : null  )
                    .filter((el: number | null) => !!el)[0]
            }
            return { ...state, whitelistDetails: {selectedToken: action.payload, tokenAmount}}
        case LaunchpadEnum.SET_IDO_TOKEN_BUY_DETAILS:
            return { ...state, buyDetails: { ...state.buyDetails, ...action.payload}}
				case LaunchpadEnum.SET_USER_DATA_IS_FETCHED:
						if(!state.pools.length) return state

						const userDataPoolFetched = state.pools
							.map(el => el.poolId === action.payload.poolId ? {...el, userDataPoolFetched: action.payload.userDataIsFetched} : el)

						return { ...state, pools: userDataPoolFetched }
        default:
            return state
    }
}
