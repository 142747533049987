import useWalletSelector from "hooks/useWalletSelector";
import ITRX20 from "contracts/ITRC20.json";
import { getTokenInfoByAddress } from "./getTokenInfo";
// import { parseUnits, formatEther } from "@ethersproject/units";
const {parseUnits} = require('@ethersproject/units')
const {formatEther} = require('@ethersproject/units')

export default async (
  address: string | undefined,
  type: string,
  amount: any,
  resultString?: boolean
) => {
  let decimal: any = (getTokenInfoByAddress(address || "")?.decimals ||
      JSON.parse(localStorage.getItem("tokenList") || "[]").filter(
        (el: { address: string | undefined }) => el.address === address
      )[0]?.decimals ||
      6);

  
  switch (type) {
    case "toSun":
      return parseUnits(amount.toString(), decimal);
    case "fromSun":
      let ethDecimal = 18 - decimal
      let newAmount = BigInt(amount) * BigInt(10 ** ethDecimal)
      return +formatEther(newAmount);
    default:
      return 0;
  }
};
// Number(amount!) * Number(BigInt(decimal))
// Number(BigInt(amount!) / BigInt(decimal))
