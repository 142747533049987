//import { levels } from "pages/Account/components/Tabs/components/LevelProgram/components/LevelInfo/levels";
//import { TypedThunk } from '@/lib/redux';
import { AppDispatch, getRootState } from 'store'; import { AccountAction, AccountsStateEmun, FarmingInfo, PoolRevenue, Revenue } from './types';
import { WalletConnectActionCreator } from 'store/reducers/wallet/action-creators';
import { ApplicationAcionCreator } from '../application/action-creator';
import sleep from 'utils/sleep';
import Config from 'config';
import Price from 'contracts/Price.json'
import initContract from 'utils/tronWeb/initContract';
import initTronstack from 'utils/tronWeb/initTronstack';
import FarmingFactoryContract from 'contracts/FarmingFactoryContract.json'
import FarmingContract from 'contracts/FarmingContract.json'
import ExonswapV2SwapRouter from "contracts/ExonswapV2SwapRouter.json";
import LPAbi from "contracts/Launchpool.json"
import IDO from 'contracts/IDO.json'
import handleTokenInfo from 'utils/handleTokenInfo';
import { launchpoolActionCreator } from '../launchpool/action-creator';
import {getAccountTransactions, getAddressTransactions} from 'api/api';

export const accountActionCreator = {
  setLevels: (levels: number[]): AccountAction => ({
    type: AccountsStateEmun.SET_LEVELS,
    payload: levels,
  }),
  setAccounts: (accounts: number[]): AccountAction => ({
    type: AccountsStateEmun.SET_ACCOUNTS,
    payload: accounts,
  }),
  setCanUserWitdhrawPool: (canUserWithdrawLaunchpool: boolean): AccountAction => ({
    type: AccountsStateEmun.SET_CAN_USERWITHDRAW_POOL,
    payload: canUserWithdrawLaunchpool
  }),
  setCreateAccountPopup: (create: boolean): AccountAction => ({
    type: AccountsStateEmun.SET_CREATE_POPUP,
    payload: create,
  }),
  setTransactionErrorPopup: (trxError: boolean): AccountAction => ({
    type: AccountsStateEmun.SET_TRANSACTION_ERROR,
    payload: trxError,
  }),
  setLoadingMinting: (loading: boolean): AccountAction => ({
    type: AccountsStateEmun.SET_CREATING,
    payload: loading,
  }),
  accountCreated: (accountCreated: boolean): AccountAction => ({
    type: AccountsStateEmun.CREATE_FINISH_NOTIFICATION,
    payload: accountCreated,
  }),
  setUpliner: (upliner: number): AccountAction => ({
    type: AccountsStateEmun.SET_UPLINER,
    payload: upliner,
  }),
  setPersonalLiquidity: (personalLiqudity: number): AccountAction => ({
    type: AccountsStateEmun.SET_PERSONAL_LIQUIDITY,
    payload: personalLiqudity
  }),
  setPersonalSwap: (personalSwap: number): AccountAction => ({
    type: AccountsStateEmun.SET_PERSONAL_SWAP,
    payload: personalSwap
  }),
  setTeamLiquidity: (personalLiqudity: number): AccountAction => ({
    type: AccountsStateEmun.SET_TEAM_LIQUIDITY,
    payload: personalLiqudity
  }),
  setTeamSwap: (personalSwap: number): AccountAction => ({
    type: AccountsStateEmun.SET_TEAM_SWAP,
    payload: personalSwap
  }),
  resetAccountData: (): AccountAction => ({
    type: AccountsStateEmun.RESET_ACCOUNT_DATA
  }),
  setTeamFetching: (teamFetching: boolean): AccountAction => ({
    type: AccountsStateEmun.SET_TEAM_FETCHING,
    payload: teamFetching
  }),
  setTeamLevelsRevenue: (teamLevelsRevenue: any): AccountAction => ({
    type: AccountsStateEmun.SET_TEAM_LEVELS_REVENUE,
    payload: teamLevelsRevenue
  }),
  setPersonaLiquidityInFarming: (personalLiquidity: number): AccountAction => ({
    type: AccountsStateEmun.SET_PERSONAL_LIQUIDITY_FARMING,
    payload: personalLiquidity
  }),
  setCurrentAccountLevel: (currentLevel: number): AccountAction => ({
    type: AccountsStateEmun.SET_CURRENT_ACCOUNT_LEVEL,
    payload: currentLevel
  }),
  setCurrentRangLevel: (rangLevel: number): AccountAction => ({
    type: AccountsStateEmun.SET_CURRENT_RANG_LEVEL,
    payload: rangLevel
  }),
  setTeamFarmingByLevel: (teamFarming: number[]): AccountAction => ({
    type: AccountsStateEmun.SET_TEAM_FARMING_BY_LEVEL,
    payload: teamFarming
  }),
  setWithdrawedLevels: (withdrawedLevels: number): AccountAction => ({
    type: AccountsStateEmun.SET_WITHDRAWED_LEVELS,
    payload: withdrawedLevels
  }),
  setTotalTeamFarming: (totalTeamFarming: number): AccountAction => ({
    type: AccountsStateEmun.SET_TOTAL_TEAM_FARMING,
    payload: totalTeamFarming
  }),
  setTotalTeamRevenue: (totalTeamRevenue: number): AccountAction => ({
    type: AccountsStateEmun.SET_TOTAL_TEAM_REVENUE,
    payload: totalTeamRevenue
  }),
  setWithdrawedRevenue: (withdrawedRevenue: any): AccountAction => ({
    type: AccountsStateEmun.SET_WITHDRAWED_REVENUE,
    payload: withdrawedRevenue
  }),
  setChoosenFarmingPoolId: (poolId: number): AccountAction => ({
    type: AccountsStateEmun.SET_CHOOSEN_FARMING_POOL_ID,
    payload: poolId
  }),
  setAllFarmingInfo: (farmingInfo: FarmingInfo): AccountAction => ({
    type: AccountsStateEmun.SET_ALL_FARMING_INFO,
    payload: farmingInfo
  }),
  setTotalFarmingWithdrawed: (totalFarmingWithdrawed: number): AccountAction => ({
	  type: AccountsStateEmun.SET_TOTAL_FARMING_WITHDRAWED,
	  payload: totalFarmingWithdrawed
  }),
  setTeamPoolRevenue: (teamPoolRevenue: PoolRevenue[] | []): AccountAction => ({
    type: AccountsStateEmun.SET_TEAM_POOL_REVENUE,
    payload: teamPoolRevenue
  }),
  setLevelsNoRevenue: (noRevenue: boolean): AccountAction => ({
    type: AccountsStateEmun.SET_LEVELS_NO_REVENUE,
    payload: noRevenue
  }),
  setBoostLevelChosen: (boostLevelChosen: number): AccountAction => ({
    type: AccountsStateEmun.SET_BOOST_LEVEL_CHOSEN,
    payload: boostLevelChosen
  }),
  setBoostActiveLevel: (boostActiveLevel: number): AccountAction => ({
    type: AccountsStateEmun.SET_BOOST_ACTIVE_LEVEL,
    payload: boostActiveLevel
  }),
  checkPresentAfterCreate:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await store().WalletReducer.tronWeb;
      var isPresent = await store()
        .WalletReducer.nftContract.balanceOf(tronWeb.defaultAddress.base58)
        .call();
      if (isPresent.toNumber() > 0) {
        dispatch(accountActionCreator.setLoadingMinting(false));
        dispatch(accountActionCreator.accountCreated(true));
        var accounts = [];
        for (var i: number = 0; i < isPresent.toNumber(); i++) {
          var account = await store()
            .WalletReducer.nftContract.tokenOfOwnerByIndex(
              tronWeb.defaultAddress.base58,
              i,
            )
            .call();
          accounts.push(account.toNumber());
        }
        var upliner = await store()
          .WalletReducer.nftContract.Upliners(accounts[0])
          .call();
        dispatch(WalletConnectActionCreator.setAccounts(accounts));
        dispatch(accountActionCreator.setAccount(accounts[0]));
        dispatch(accountActionCreator.setUpliner(upliner.toNumber()));
      }
    },
  createAccount:
    (link: string) => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await store().WalletReducer.tronWeb;
      var refId = 1;
      if (link !== '') {
        refId = parseInt(link);
      }
      var trxAprove;
      try {
        trxAprove = await store()
          .WalletReducer.nftContract.signIn(refId)
          .send({ feeLimit: 500000000, callValue: 0 });
      } catch (e) {
        console.log(e);
        dispatch(accountActionCreator.setCreateAccountPopup(false));
        dispatch(accountActionCreator.setTransactionErrorPopup(true));
        return;
      }
      dispatch(accountActionCreator.setCreateAccountPopup(false));
      dispatch(accountActionCreator.setLoadingMinting(true));
      var blockNumber;
      var unconfirmTransaction;
      do {
        await sleep(1000);
        unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(trxAprove);
      } while (Object.keys(unconfirmTransaction).length === 0)
      if (unconfirmTransaction.receipt.result === "FAILED") {
        dispatch(accountActionCreator.setTransactionErrorPopup(true));
      }
      do {
        await sleep(2000);
        blockNumber = await tronWeb.trx.getCurrentBlock();
        // console.log('test result swap', blockNumber, unconfirmTransaction);
      } while ((blockNumber.block_header.raw_data.number - unconfirmTransaction.blockNumber) > Config().CONFIRMATIONS);
      if (unconfirmTransaction?.receipt?.result !== 'SUCCESS') {
        dispatch(accountActionCreator.setLoadingMinting(false));
        dispatch(accountActionCreator.setTransactionErrorPopup(true));
      }
      // } else {
      //   dispatch(accountActionCreator.setLoadingMinting(false));
      //   dispatch(accountActionCreator.setTransactionErrorPopup(true));
      // }
    },
  getLevels: () => async (dispatch: AppDispatch, store: getRootState) => {
    const currentAccount = store().AccountReducer.currentAccount;
    var levels = [];
    for (var i = 0; i < 6; i++) {
      var getLevelNumber = await store()
        .WalletReducer.nftContract.ReferalAmount(currentAccount, i)
        .call();
      levels.push(getLevelNumber.toNumber());
    }
    dispatch(accountActionCreator.setLevels(levels));
  },
  setAccount: (account: number): AccountAction => ({
    type: AccountsStateEmun.SET_CURRENT_ACCOUNT,
    payload: account,
  }),
  // getActiveLaunchPool: ():TypedThunk => async (dispatch: AppDispatch, store: getRootState) => {
  //     const currentLaunchpoolId = store().launchpoolReducer.activePoolId
  //     const pools = store().launchpoolReducer.pools
  //     const tronWeb = await (window as any).tronWeb
  //     const currentAccount = store().AccountReducer.currentAccount;
  //     if (currentLaunchpoolId != 0 && pools.length != 0) {
  //        let currentPool = pools.filter((el: any) => el.poolId === currentLaunchpoolId)[0]
  //
  //       const poolContract: any = await tronWeb.contract(
  //           LPAbi.abi,
  //           currentPool.poolAddress
  //       )
  //       let shareStaked:any
  //       let shareWithdrawed
  //       try {
  //         shareStaked = await poolContract.UsersShare(currentAccount).call()
  //         shareWithdrawed = shareStaked.amountWithdrawed.toNumber()
  //         shareWithdrawed = +tronWeb.fromSun(shareWithdrawed)
  //         shareStaked = shareStaked.amountStake.toNumber()
  //         shareStaked = +tronWeb.fromSun(shareStaked)
  //       } catch (error) {
  //         console.log(error);
  //       }
  //       let randomStaked:any
  //       let randomWithdrawed
  //       try {
  //         randomStaked = await poolContract.UsersRandom(currentAccount).call()
  //         randomWithdrawed = randomStaked.amountWithdrawed.toNumber()
  //         randomWithdrawed = +tronWeb.fromSun(randomWithdrawed)
  //         randomStaked = randomStaked.amountStake.toNumber()
  //         randomStaked = +tronWeb.fromSun(randomStaked)
  //       } catch (error) {
  //         console.log(error);
  //       }
  //
  //       let reward
  //       try {
  //         reward = await poolContract.calculatePart(currentAccount).call()
  //         reward = reward.toNumber()
  //         reward = +tronWeb.fromSun(reward)
  //         console.log('REWARD', reward);
  //       } catch (error) {
  //         console.log(error);
  //         reward = 0
  //       }
  //
  //       let referralReward
  //
  //       try {
  //         referralReward = await poolContract.UplinersTransfer(currentAccount).call()
  //         referralReward = referralReward.toNumber()
  //         referralReward = tronWeb.fromSun(referralReward)
  //       } catch (error) {
  //         console.log(error);
  //         referralReward = 0
  //       }
  //       let stakedValue
  //       const defineStakedAmount = () => {
  //         let poolType = currentPool.randomShareBoth
  //         if((poolType === 0) || (poolType === 3)) {
  //             return randomStaked
  //         }else if(poolType === 1){
  //             return shareStaked
  //         }else if(poolType === 2){
  //             return randomStaked + shareStaked
  //         }else return 0
  //       } 
  // 
  //       stakedValue = defineStakedAmount()
  //     let LPUser = { userInfo: {
  //         stakedValue: 0,
  //         randomWithdrawed:0,
  //         shareWithdrawed:0,
  //         reward:0,
  //         referralReward:0
  //       }}
  //
  //      LPUser.userInfo.stakedValue = stakedValue
  //      LPUser.userInfo.randomWithdrawed = randomWithdrawed
  //      LPUser.userInfo.shareWithdrawed = shareWithdrawed
  //      LPUser.userInfo.referralReward = referralReward
  //      LPUser.userInfo.reward = reward
  //      currentPool = {...currentPool, userInfo: LPUser.userInfo}
  //      dispatch(launchpoolActionCreator.setCurrentPool(currentPool, currentLaunchpoolId))
  //     }
  //
  // },
  setCurrentAccount:
    (account: number) => async (dispatch: AppDispatch, store: getRootState) => {
      const currentAccount = store().AccountReducer.currentAccount;
      const tronWeb = await (window as any).tronWeb
      var upliner = await store()
        .WalletReducer.nftContract.Upliners(currentAccount)
        .call();
      dispatch(accountActionCreator.setAccount(account));
      dispatch(accountActionCreator.setUpliner(upliner.toNumber()));
      let LPUser = { userInfo: {
          stakedValue: 0,
          randomWithdrawed:0,
          shareWithdrawed:0,
          reward:0,
          referralReward:0
        }}
      const currentLaunchpoolId = store().launchpoolReducer.activePoolId
      const pools = store().launchpoolReducer.pools

			const activeIDO = store().launchpadReducer.activeIdo
      
      if (currentLaunchpoolId != 0 && pools.length != 0) {
         let currentPool = pools.filter((el: any) => el.poolId === currentLaunchpoolId)[0]

        const poolContract: any = await tronWeb.contract(
            LPAbi.abi,
            currentPool.poolAddress
        )
        let shareStaked:any
        let shareWithdrawed
        try {
          shareStaked = await poolContract.UsersShare(currentAccount).call()
          shareWithdrawed = shareStaked.amountWithdrawed.toNumber()
          shareWithdrawed = +tronWeb.fromSun(shareWithdrawed)
          shareStaked = shareStaked.amountStake.toNumber()
          shareStaked = +tronWeb.fromSun(shareStaked)
        } catch (error) {
          console.log(error);
        }
        let randomStaked:any
        let randomWithdrawed
        try {
          randomStaked = await poolContract.UsersRandom(currentAccount).call()
          randomWithdrawed = randomStaked.amountWithdrawed.toNumber()
          randomWithdrawed = +tronWeb.fromSun(randomWithdrawed)
          randomStaked = randomStaked.amountStake.toNumber()
          randomStaked = +tronWeb.fromSun(randomStaked)
        } catch (error) {
          console.log(error);
        }

        let reward
        try {
          reward = await poolContract.calculatePart(currentAccount).call()
          reward = reward.toNumber()
          reward = +tronWeb.fromSun(reward)
          console.log('REWARD', reward);
        } catch (error) {
          console.log(error);
          reward = 0
        }

        let referralReward

        try {
          referralReward = await poolContract.UplinersTransfer(currentAccount).call()
          referralReward = referralReward.toNumber()
          referralReward = tronWeb.fromSun(referralReward)
        } catch (error) {
          console.log(error);
          referralReward = 0
        }
        let stakedValue
        const defineStakedAmount = () => {
          let poolType = currentPool.randomShareBoth
          if((poolType === 0) || (poolType === 3)) {
              return randomStaked
          }else if(poolType === 1){
              return shareStaked
          }else if(poolType === 2){
              return randomStaked + shareStaked
          }else return 0
        } 
  
        stakedValue = defineStakedAmount()

       LPUser.userInfo.stakedValue = stakedValue
       LPUser.userInfo.randomWithdrawed = randomWithdrawed
       LPUser.userInfo.shareWithdrawed = shareWithdrawed
       LPUser.userInfo.referralReward = referralReward
       LPUser.userInfo.reward = reward
       currentPool = {...currentPool, userInfo: LPUser.userInfo}
       dispatch(launchpoolActionCreator.setCurrentPool(currentPool, currentLaunchpoolId))
      }
			console.log(activeIDO)
			if(!!activeIDO) {

				const lpContract = tronWeb.contract(
					IDO.abi,
					activeIDO.poolAddress
				)

				let whiteListLock

				try{
					whiteListLock = await lpContract.whiteListLock(currentAccount).call()
					console.log('WHITELIST LOCK',whiteListLock)
				}catch(error) {
					console.log(error)
				}
			}


		//mapping(uint => mapping(address => uint)) public whiteListLock;
		//mapping(uint => mapping(address => uint)) public tokenIdBuyTokenAmounts;
		//mapping(uint => uint) public tokenIdSaleAmount;
		//mapping(address => uint) public tokenAddressAmount;

      var levels = [];
      for (var i = 0; i < 6; i++) {
        var getLevelNumber = await store()
          .WalletReducer.nftContract.ReferalAmount(account, i)
          .call();
        levels.push(getLevelNumber.toNumber());
      }
      dispatch(accountActionCreator.setLevels(levels));
    },
  getUplinersLiquidity: 
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await store().WalletReducer.tronWeb;
      let currentAccount = await store().AccountReducer.currentAccount;
      const tronstackWeb = await initTronstack();
      if(tronWeb === null) return
      dispatch(accountActionCreator.setTeamFetching(true))
      const contractUpliners = await initContract(Config().ACCOUNT_CONTRACT)
      const PriceContract = await tronstackWeb.contract(
        Price.abi,  
        Config().ORACLE_LIQUIDITY_AND_SWAP
      )
       const accountId = currentAccount
      
      // store().AccountReducer.currentAccount
      let teamLiquidity: any = 0
      let teamSwap: any = 0
      let getUplinersLiquidityAndSwap = async (accountId: number, level: number = 1) => {
        // await sleep(1000)
        if ( level > 6 ) return
        let upliners;
        let personalLiqudity: any;
        let personalSwap: any;
        teamSwap = parseFloat(teamSwap)
        teamLiquidity = parseFloat(teamLiquidity)
        try {
          upliners = await contractUpliners.getFirstLine(accountId).call()
          upliners = upliners.map((el: { toNumber: () => any; }) => el.toNumber())
        } catch (error: any) {
          console.log(error);
        }
        // console.log('level: ' + level, 'upliners: ' + upliners);
        if(upliners === undefined) return
        for (let i = 0; i < upliners.length; i++) {
          // await sleep(1000)
          try {
            personalLiqudity = await PriceContract.getPersonalLiquidity(upliners[i]).call()
            personalLiqudity = await handleTokenInfo('', 'fromSun', personalLiqudity) / 1000000
            // tronWeb.fromSun(personalLiqudity.toNumber())/1000000
            if (typeof personalLiqudity === 'number') {
              teamLiquidity += personalLiqudity;
            } else {
              teamLiquidity += personalLiqudity.toNumber();
            }
            personalSwap = await PriceContract.getPersonalSwap(upliners[i]).call()
            personalSwap = await handleTokenInfo('', 'fromSun', personalSwap) / 1000000
            // tronWeb.fromSun(personalSwap.toNumber())/1000000
            if (typeof personalSwap === 'number') {
              teamSwap += personalSwap
            } else {
              teamSwap += personalSwap.toNumber();
            }
          } catch (error: any) {
            console.log(error);
          }
          // console.log('LEVEL:', level)
          await getUplinersLiquidityAndSwap(upliners[i], level+1)
        }
      };
      await getUplinersLiquidityAndSwap(accountId)
      teamLiquidity = parseFloat(teamLiquidity).toFixed(2)
      teamSwap = parseFloat(teamSwap).toFixed(2)
      dispatch(accountActionCreator.setTeamFetching(false))
      dispatch(accountActionCreator.setTeamLiquidity(+teamLiquidity))
      dispatch(accountActionCreator.setTeamSwap(+teamSwap))
    },
  getAccountLiquidityAndSwap: 
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await store().WalletReducer.tronWeb;
      const accountId = await store().AccountReducer.currentAccount;
      const tronstackWeb = await initTronstack();
      if(!accountId) return
      if (tronstackWeb !== null) {
        const PriceContract = await tronstackWeb.contract(
          Price.abi,
          Config().ORACLE_LIQUIDITY_AND_SWAP
        );
        let personalSwap 
        let personalLiquidity
        try {
          personalSwap = await PriceContract.getPersonalSwap(accountId).call()
        } catch (error: any) {
          console.log(error);
        }
        if(personalSwap){
          personalSwap = +personalSwap.toString()/1000000
          personalSwap = tronWeb.fromSun(personalSwap)
          personalSwap = parseFloat(personalSwap).toFixed(2)
          dispatch(accountActionCreator.setPersonalSwap(+personalSwap))
        }

        try {
          personalLiquidity = await PriceContract.getPersonalLiquidity(accountId).call()
        } catch (error: any) {
          console.log(error);
        }
        if(personalLiquidity){
          personalLiquidity = +personalLiquidity.toString() / 1000000;
          personalLiquidity = tronWeb.fromSun(personalLiquidity)
          personalLiquidity = parseFloat(personalLiquidity).toFixed(2)
          dispatch(accountActionCreator.setPersonalLiquidity(+personalLiquidity))
        }
      }
    },
  // getAccountLevels: 
  //   () => async (dispatch: AppDispatch, store: getRootState) => {
  //     const tronWeb = store().WalletReducer.tronWeb
  //     const currentAccount = store().AccountReducer.currentAccount
  //     if(!tronWeb) return

  //     const farmingFactoryContract = tronWeb.contract(
  //       FarmingFactoryContract.abi,
  //       Config().FARMING_FACTORY_CONTRACT
  //     )
  //     let currentLevel
  //     try {
  //       currentLevel = await farmingFactoryContract.getUserLevel(currentAccount).call()
  //     } catch (error) {
  //       console.log(error);
  //       return
  //     }
  //     dispatch(accountActionCreator.setCurrentAccountLevel(currentLevel.toNumber()))
  //   },
  updateCurrentLevel:
    () => async (dispatch: AppDispatch, store: getRootState) => {
      const tronWeb = await store().WalletReducer.tronWeb
      const currentAccount = store().AccountReducer.currentAccount
      const { choosenFarmingPoolId } = store().AccountReducer
      if(!tronWeb) return
      dispatch(ApplicationAcionCreator.setTransactionStatus(false))
      const farmingContract = tronWeb.contract(
        FarmingContract.abi,
        Config().FARMING_CONTRACT
      )
      const farmingFactoryContract = tronWeb.contract(
        FarmingFactoryContract.abi,
        Config().FARMING_FACTORY_CONTRACT
      )
      let checkLevel
      try {
        checkLevel = await farmingContract.checkUserDepositLevelRevenue(currentAccount, choosenFarmingPoolId).send()
      } catch (error) {
        console.log(error);
        dispatch(ApplicationAcionCreator.setTransactionStatus(false))
        return
      }
      dispatch(
        ApplicationAcionCreator.setNotificationStatus(
          false,
          true,
          'Level check',
          'Processing',
          checkLevel,
          `Calculating your data`,
        ),
      );

      var blockNumber;
      var unconfirmTransaction;
      do {
        await sleep(2000);
        unconfirmTransaction = await tronWeb.trx.getUnconfirmedTransactionInfo(checkLevel);
      } while (Object.keys(unconfirmTransaction).length === 0);

      do {
        await sleep(2000);
        blockNumber = await tronWeb.trx.getCurrentBlock();
      } while ((blockNumber.block_header.raw_data.number - unconfirmTransaction.blockNumber) > Config().CONFIRMATIONS);
      if(unconfirmTransaction?.receipt?.result !== 'SUCCESS') {
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Level check',
            'Error',
            checkLevel,
            `Try again later`,
          ),
        );
        dispatch(ApplicationAcionCreator.setTransactionStatus(false))
        return
      }else {
        dispatch(
          ApplicationAcionCreator.setNotificationStatus(
            true,
            true,
            'Level check',
            'Success',
            checkLevel,
            `You have been level upped`,
          ),
        );
        dispatch(ApplicationAcionCreator.setTransactionStatus(false))
      }
      let currentLevel
      try {
        currentLevel = await farmingFactoryContract.getUserLevel(currentAccount).call()
      } catch (error) {
        console.log(error);
        return
      }
      dispatch(accountActionCreator.setCurrentAccountLevel(currentLevel.toNumber()))
    },
    getTeamRevenue:
      () => async (dispatch: AppDispatch, store: getRootState) => {
        const tronWeb = store().WalletReducer.tronWeb
        const currentAccount = store().AccountReducer.currentAccount
        const farmingList = store().farmingReducer.farmingList
        const choosenFarmingPoolId = store().AccountReducer.choosenFarmingPoolId
        if(!tronWeb || !farmingList.length) return
        const farmingFactoryContract = tronWeb.contract(
          FarmingFactoryContract.abi,
          Config().FARMING_FACTORY_CONTRACT
        )
        let teamLevelsRevenue = []
          for (let j = 1; j <= 6; j++) {

            let poolLevelRevenue
            try {
              poolLevelRevenue = await farmingFactoryContract.getRevenueTeamByLevel(currentAccount, j, choosenFarmingPoolId).call()
              poolLevelRevenue = await handleTokenInfo('', 'fromSun', poolLevelRevenue)
              teamLevelsRevenue.push(poolLevelRevenue)
            } catch (error) {
              console.log(error);
              return
            }
        }
        dispatch(accountActionCreator.setTeamLevelsRevenue(teamLevelsRevenue))
      },
      getTeamPoolRevenue:
        () => async (dispatch: AppDispatch, store: getRootState) => {
          const tronWeb = store().WalletReducer.tronWeb
          const currentAccount = store().AccountReducer.currentAccount
          const farmingList = store().farmingReducer.farmingList
          if(!tronWeb || !farmingList.length) return

          const farmingFactoryContract = tronWeb.contract(
            FarmingFactoryContract.abi,
            Config().FARMING_FACTORY_CONTRACT
          )

          let teamPoolRevenue = []

          for (let i = 1; i <= farmingList.length; i++) {
            let teamCurrentPoolRevenue
            try {
              teamCurrentPoolRevenue = await farmingFactoryContract.getTotalTeamRevenue(
                currentAccount,
                i
              ).call()
              teamCurrentPoolRevenue = teamCurrentPoolRevenue.toNumber()
              teamCurrentPoolRevenue = tronWeb.fromSun(teamCurrentPoolRevenue)
              teamPoolRevenue.push({poolId: i, amount: +teamCurrentPoolRevenue})

            } catch (error: any) {
              console.log(error);
            }
          }
          dispatch(accountActionCreator.setTeamPoolRevenue(teamPoolRevenue))
      },
  //   getRankProgrammLevel:
  //     () => async (dispatch: AppDispatch, store: getRootState) => {
  //       const tronWeb = store().WalletReducer.tronWeb
  //       const currentAccount = store().AccountReducer.currentAccount
  //       if(!tronWeb) return
  //       const farmingFactoryContract = tronWeb.contract(
  //         FarmingFactoryContract.abi,
  //         Config().FARMING_FACTORY_CONTRACT
  //       )
  //       let rangLevel
  //       try {
  //         rangLevel = await farmingFactoryContract.rankPrograms(currentAccount).call()
  //         if(rangLevel._rankLevel) rangLevel = rangLevel._rankLevel.toNumber()
  //         else rangLevel = rangLevel.toNumber()
  //       } catch (error) {
  //         console.log('rangLevel: ',error);
  //         rangLevel = 0
  //       }

  //       let withdrawedLevels

  //       try {
  //         withdrawedLevels = await farmingFactoryContract.rankWithdrawHistory(currentAccount).call()
  //         withdrawedLevels = withdrawedLevels.toNumber()
  //       } catch (error) {
  //         console.log(error);
  //         withdrawedLevels = 0
  //       }
  //       dispatch(accountActionCreator.setWithdrawedLevels(withdrawedLevels))
  //       dispatch(accountActionCreator.setCurrentRangLevel(rangLevel))
  //     },
  //   getTeamFarmingByLevel:
  //     () => async (dispatch: AppDispatch, store: getRootState) => {
  //       const tronWeb = store().WalletReducer.tronWeb
  //       const currentAccount = store().AccountReducer.currentAccount
  //       const currentAccountLevel = store().AccountReducer.currentAccountLevel
  //       let teamFarmingTurnover = []
  //       let totalTeamRevenue = 0
  //       if(!tronWeb) return
  //       if(!currentAccountLevel) return
  //       const farmingFactoryContract = tronWeb.contract(
  //         FarmingFactoryContract.abi,
  //         Config().FARMING_FACTORY_CONTRACT
  //       )
        
  //       for (let i = 1; i <= 6; i++) {
  //         try {
  //           let currentFarmingLevel = await farmingFactoryContract.getTeamLiquidityByLevel(currentAccount, i).call()
  //           currentFarmingLevel = +tronWeb.fromSun(currentFarmingLevel.toNumber())
  //           // totalTeamRevenue += currentFarmingLevel
  //           teamFarmingTurnover.push(currentFarmingLevel.toFixed(2))
  //         } catch (error) {
  //           console.log(error);
  //           return
  //         }
  //       }
  //       dispatch(accountActionCreator.setTeamFarmingByLevel(teamFarmingTurnover))
  //       // dispatch(accountActionCreator.setTotalTeamRevenue(totalTeamRevenue))
  //     },
  //     getTotalTeamFarming:
  //       () => async (dispatch: AppDispatch, store: getRootState) => {
  //         const tronWeb = store().WalletReducer.tronWeb
  //         const currentAccount = store().AccountReducer.currentAccount
          
  //         if(!tronWeb) return
  //         const farmingFactoryContract = tronWeb.contract(
  //           FarmingFactoryContract.abi,
  //           Config().FARMING_FACTORY_CONTRACT
  //         )
  //         let totalTeamFarming

  //         try {
  //           totalTeamFarming = await farmingFactoryContract.getTotalTeamLiquidity(currentAccount).call()
  //           totalTeamFarming = +tronWeb.fromSun(totalTeamFarming.toNumber())
  //           totalTeamFarming = +totalTeamFarming.toFixed(2)
  //         } catch (error) {
  //           console.log(error);
  //           totalTeamFarming = 0
  //         }
  //         dispatch(accountActionCreator.setTotalTeamFarming(totalTeamFarming))
  //       },
      getTotalTeamRevenue:
       () => async (dispatch: AppDispatch, store: getRootState) => {
          const tronWeb = store().WalletReducer.tronWeb
          const currentAccount = store().AccountReducer.currentAccount
          const farmingList = store().farmingReducer.farmingList  
          if(!tronWeb || !farmingList.length) return
          const farmingFactoryContract = tronWeb.contract(
            FarmingFactoryContract.abi,
            Config().FARMING_FACTORY_CONTRACT
            )
            let totalTeamRevenue = 0
          for (let i = 1; i <= farmingList.length; i++) {
            let poolTeamRevenue
            try {
              poolTeamRevenue = await farmingFactoryContract.getTotalTeamRevenue(currentAccount, i).call()
              poolTeamRevenue = await handleTokenInfo('', 'fromSun', poolTeamRevenue)
              // +tronWeb.fromSun(poolTeamRevenue.toNumber())
              poolTeamRevenue = +poolTeamRevenue.toFixed(6)
              totalTeamRevenue += poolTeamRevenue
            } catch (error) {
              console.log(error);
              totalTeamRevenue = 0
            }
          }
          dispatch(accountActionCreator.setTotalTeamRevenue(totalTeamRevenue))
       },
      getAllFarmingInfoByPoolId:
       () => async (dispatch: AppDispatch, store: getRootState) => {
          const tronWeb = store().WalletReducer.tronWeb
          const currentAccount = store().AccountReducer.currentAccount
          const choosenFarmingPoolId = store().AccountReducer.choosenFarmingPoolId
          const farmingList = store().farmingReducer.farmingList

          if(!farmingList.length || !choosenFarmingPoolId || !farmingList.length) return

          const farmingFactoryContract = tronWeb.contract(
            FarmingFactoryContract.abi,
            Config().FARMING_FACTORY_CONTRACT
          )

          const routerContract = tronWeb.contract(
            ExonswapV2SwapRouter.abi,
            Config().ROUTER_CONTRACT
          )

          let farmingInfo = {}
          let totalTeamRevenue
          let withdrawedRevenue
          let teamFarmingByLevel = []
          let totalTeamFarming
          let personalLiquidityFarming
          let teamFarmingTurnover = []
          let teamFarmingProfit
          let currentAccountLevel
          let currentRangLevel
          let withdrawedLevels
          let totalRankWithdrawed = []
          let swapByPool
          let firstTokenTurnover
          let secondTokenTurnover

          try {
            let firstTokenAddress = farmingList.filter(el => el.poolId === choosenFarmingPoolId)[0].token0
            firstTokenTurnover = await routerContract.swapHistory(currentAccount, firstTokenAddress).call()
            firstTokenTurnover = firstTokenTurnover.toNumber()
            firstTokenTurnover = await handleTokenInfo(firstTokenAddress, 'fromSun', firstTokenTurnover) 
          } catch (error) {
            console.log('FirstToken', error);
          }

          try {
            let secondTokenAddress = farmingList.filter(el => el.poolId === choosenFarmingPoolId)[0].token1
            secondTokenTurnover = await routerContract.swapHistory(currentAccount, secondTokenAddress).call()
            secondTokenTurnover = secondTokenTurnover.toNumber()
            secondTokenTurnover = await handleTokenInfo(secondTokenAddress, 'fromSun', secondTokenTurnover)
          } catch (error) {
            console.log('SecondToken', error);
          }

          try {
            currentAccountLevel = await farmingFactoryContract.getActiveLevels(choosenFarmingPoolId, currentAccount).call()
            currentAccountLevel = currentAccountLevel._levelAmount ? currentAccountLevel._levelAmount.toNumber() : currentAccountLevel.toNumber()
          } catch (error) {
            console.log('curr acc lvl', error);
            return
          }


          try {
            currentRangLevel = await farmingFactoryContract.rankPrograms(currentAccount, choosenFarmingPoolId).call()
            currentRangLevel = currentRangLevel._rankLevel ? currentRangLevel._rankLevel.toNumber() : currentRangLevel.toNumber()
          } catch (error) {
            console.log('currentRangLevel: ',error);
            currentRangLevel = 0
          }



          try {
            totalTeamFarming = await farmingFactoryContract.getTotalTeamLiquidity(currentAccount, choosenFarmingPoolId).call()
            totalTeamFarming = +tronWeb.fromSun(totalTeamFarming.toNumber())
            totalTeamFarming = +totalTeamFarming.toFixed(2)
          } catch (error) {
            console.log('totalteam', error);
            totalTeamFarming = 0
          }

          personalLiquidityFarming = farmingList.filter(el => el.poolId === choosenFarmingPoolId)[0].lpStaked

          for (let i = 1; i <= 6; i++) {
            try {
              let currentFarmingLevel = await farmingFactoryContract.getTeamLiquidityByLevel(currentAccount, i, choosenFarmingPoolId).call()
              currentFarmingLevel = +tronWeb.fromSun(currentFarmingLevel.toNumber())
              // totalTeamRevenue += currentFarmingLevel
              teamFarmingByLevel.push(+currentFarmingLevel.toFixed(2))
            } catch (error) {
              console.log('teamfarming', error);
              return
            }

            
            try {
              let currentFarmingLevel = await farmingFactoryContract.getTeamLiquidityByLevel(currentAccount, i, choosenFarmingPoolId).call()
              currentFarmingLevel = +tronWeb.fromSun(currentFarmingLevel.toNumber())
              // totalTeamRevenue += currentFarmingLevel
              teamFarmingTurnover.push(+currentFarmingLevel.toFixed(2))
            } catch (error) {
              console.log('farmingLevel', error);
              return
            }

          }
          for (let i = 1; i <= farmingList.length; i++) {
            try {
              let withdraw = await farmingFactoryContract.rankWithdrawHistory(currentAccount, i).call()
              withdraw = withdraw.toNumber()
              totalRankWithdrawed.push(withdraw)
              if (i === choosenFarmingPoolId) withdrawedLevels = withdraw

            } catch (error:any) {
              console.log('withdrawed error', error.message);
            }
          }

          dispatch(accountActionCreator.setAllFarmingInfo({
            teamFarmingByLevel: teamFarmingByLevel,
            teamFarmingTurnover: teamFarmingTurnover,
            totalRankWithdrawed: totalRankWithdrawed,
            totalTeamFarming: totalTeamFarming,
            personalLiquidityFarming: personalLiquidityFarming,
            teamFarmingProfit: 0,
            currentAccountLevel: currentAccountLevel,
            currentRangLevel: currentRangLevel,
            withdrawedLevels: withdrawedLevels,
            firstTokenTurnover: firstTokenTurnover,
            secondTokenTurnover: secondTokenTurnover
          }))
        },
      getWithdrawedRevenue:
       () => async (dispatch: AppDispatch, store: getRootState) => {
        const tronWeb = store().WalletReducer.tronWeb
        const currentAccount = store().AccountReducer.currentAccount
        const farmingList = store().farmingReducer.farmingList
        if(!tronWeb || !farmingList.length) return
        const farmingFactoryContract = tronWeb.contract(
          FarmingFactoryContract.abi,
          Config().FARMING_FACTORY_CONTRACT
        )
        let withdrawedRevenue = []
        let totalFarmingWithdrawed = 0
        for (let i = 1; i <= farmingList.length; i++) {
          let poolwithdrawedRevenue
          try {
            poolwithdrawedRevenue = await farmingFactoryContract.withdrawedRevenue(currentAccount, i).call()
            poolwithdrawedRevenue = await handleTokenInfo('', 'fromSun', poolwithdrawedRevenue)
            poolwithdrawedRevenue = +poolwithdrawedRevenue.toFixed(6)
            withdrawedRevenue.push({poolId: i, amount: poolwithdrawedRevenue})

			let currentPoolWithdrawed = await farmingFactoryContract.getWithdrawed(currentAccount, i).call()
			currentPoolWithdrawed = currentPoolWithdrawed.toNumber()
			totalFarmingWithdrawed += +tronWeb.fromSun(currentPoolWithdrawed)
			totalFarmingWithdrawed = +totalFarmingWithdrawed.toFixed(2)
          } catch (error) {
            console.log(error);
          }
        }
		dispatch(accountActionCreator.setTotalFarmingWithdrawed(totalFarmingWithdrawed))
        dispatch(accountActionCreator.setWithdrawedRevenue(withdrawedRevenue))
       },
       getUserLevelNoRevenue:
         () => async (dispatch: AppDispatch, store: getRootState) => {
          const tronWeb = store().WalletReducer.tronWeb
          const currentAccount = store().AccountReducer.currentAccount
          const choosenFarmingPoolId = store().AccountReducer.choosenFarmingPoolId
          const currentAccountLevel = store().AccountReducer.farmingInfo.currentAccountLevel
          if(!tronWeb || !currentAccountLevel || !choosenFarmingPoolId) return
          const farmingContract = tronWeb.contract(
            FarmingContract.abi,
            Config().FARMING_CONTRACT
          )
          let levelsNoRevenue = 0
          let noRevenue = false
          try {
            let userLevel = await farmingContract.getUserLevelTeamAmount(choosenFarmingPoolId, currentAccount).call()
            userLevel = userLevel.map((el: any) => el.toNumber())
            for (let i = 0; i < userLevel.length; i++) {
              if(!!userLevel[i]) levelsNoRevenue ++
            }
            if(levelsNoRevenue !== currentAccountLevel) noRevenue = true
          } catch (error) {
            console.log(error);
          }
          dispatch(accountActionCreator.setLevelsNoRevenue(noRevenue))
       },
       getFeeTokens:
        () => async (dispatch: AppDispatch, store: getRootState) => {
          const tronWeb = store().WalletReducer.tronWeb
          const choosenFarmingPoolId = store().AccountReducer.choosenFarmingPoolId
          if(!tronWeb && !choosenFarmingPoolId) return

          const farmingFactoryContract = await tronWeb.contract(
            FarmingFactoryContract.abi,
            Config().FARMING_FACTORY_CONTRACT
          )
       },
       getBoostInfo:
         () => async(dispatch: AppDispatch, store: getRootState) => {

          const tronWeb = store().WalletReducer.tronWeb
          const choosenFarmingPoolId = store().AccountReducer.choosenFarmingPoolId
          if(!tronWeb && !choosenFarmingPoolId) return

          const farmingFactoryContract = await tronWeb.contract(
            FarmingFactoryContract.abi,
            Config().FARMING_FACTORY_CONTRACT
          )
          let boostActiveLevel = 0
          for (let i = 1; i <= 6; i++) {
            let boostLevels
            try {
               boostLevels = await farmingFactoryContract.BoostInfo(choosenFarmingPoolId, i)
               boostLevels = boostLevels.toNumber()
            } catch (error) {
              console.log(error);
            }
            if (!!boostLevels) return boostActiveLevel = i
          }
          dispatch(accountActionCreator.setBoostActiveLevel(boostActiveLevel))
       },
       getBoostFeeTokenAndPrice:
         () => async( dispatch: AppDispatch, store: getRootState ) => {
          const tronWeb = store().WalletReducer.tronWeb
          const choosenFarmingPoolId = store().AccountReducer.choosenFarmingPoolId
          if(!tronWeb && !choosenFarmingPoolId) return

          const farmingFactoryContract = await tronWeb.contract(
            FarmingFactoryContract.abi,
            Config().FARMING_FACTORY_CONTRACT
          )
          let boostFeeTokens = []
          try {
            boostFeeTokens = await farmingFactoryContract.getFeeTokens(choosenFarmingPoolId).call()
            let fromHexFeeTokens = []
            for (let i = 0; i < boostFeeTokens.length; i++) {
              fromHexFeeTokens[i] = await tronWeb.address.fromHex(boostFeeTokens[i])
            }
          } catch (error) {
            console.log(error);
          }
       },
      canUserWithdrawLaunchpool:
        () => async (dispatch: AppDispatch, store: getRootState) => {
          const tronWeb = store().WalletReducer.tronWeb
          const address = store().WalletReducer.address
          let canPoolWitdhraw
          let userTransactions: any = []
          let joinsAmount = 0
          let joinTypeId = '1208f510'
          let withdrawsAmount = 0
          let withdrawTypeId = '2e1a7d4d'
          let start = 1669035600000
          const getValidTransactions = async (currStep: number) => {
            let transactions
            let currentAddress = address
            try {
              transactions = await getAddressTransactions(currentAddress, currStep)
              } catch (error) {
                console.log('???');
              return
              }
            for (let k = 0; k < transactions.data.data.length; k++) {
              if(
                (transactions.data.data[k].timestamp > start) && 
                transactions.data.data[k].result === 'SUCCESS' &&
                transactions.data.data[k]?.trigger_info?.methodName?.match(joinTypeId)
              ){
                joinsAmount += 1
              }else if(
                (transactions.data.data[k].timestamp > start) && 
                transactions.data.data[k].result === 'SUCCESS' &&
                transactions.data.data[k]?.trigger_info?.methodName?.match(withdrawTypeId)
              ){
                withdrawsAmount += 1
              }
            }
            if(transactions.data.data[transactions.data.data.length - 1]?.timestamp > start) await getValidTransactions(currStep+50)
            else return
          }
          await getValidTransactions(0)
          
          console.log('User Joins and Withdraws', joinsAmount, withdrawsAmount);
          if(joinsAmount > withdrawsAmount) canPoolWitdhraw = true
          else canPoolWitdhraw = false
          dispatch(accountActionCreator.setCanUserWitdhrawPool(canPoolWitdhraw))
      }
};
