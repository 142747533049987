import React, { FunctionComponent, useContext } from 'react';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import { SidebarContext } from '../../../../../components/Layout/Layout';
import Icons from '../assets/icons';
import s from './NavItem.module.css'

type Props = {
  item: {
    text: string;
    icon: string;
    path: string;
    url?: string;
  };
  external?: boolean;
};

const NavItem: FunctionComponent<Props> = ({ item, external }) => {
  const location = useLocation();
  const history = useHistory();
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);
  const isMobile = window.innerWidth <= 640

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    isMobile && setIsSidebarOpen(false);
    history.push(event.currentTarget.getAttribute('href') || '#');
  };
  const Icon = Icons[item.icon];
  const isActive = location.pathname.split('/')[1] === item.path.split('/')[1];
  const styles = `flex justify-start items-center mb-5 font-medium hover:text-yellow 'text-lightGrey`;

  const inactiveService = (name: string) => {
    switch (name) {
      case 'Analytics':
        return true
      case 'Farming':
        return true
      case 'Vote':
        return true
      default:
        return false
    }
  }
  
  if (external) {
    return (
      <a href={item.path} target='_blank' className={`${styles} ${s.nav__item} `}>
        <Icon className={`${isSidebarOpen ? 'mr-3' : ''}`} />
        {isSidebarOpen ? item.text : ''}
      </a>
    );
  }

  return (
    <NavLink
      to={item.path}
      className={`${styles} ${s.nav__item} ${inactiveService(item.icon) && s.inactive__service} ${isActive && s.active__tab} ${location.pathname.split('/')[1] === 'analytics' && isActive && s.analytics__active}`}
      onClick={event => handleClick(event)}
    >
      <Icon className={`${isSidebarOpen ? 'mr-3' : ''}`} />
      {isSidebarOpen ? item.text : ''}
    </NavLink>
  );
};

export default NavItem;
