import React, { createContext, FunctionComponent, useState } from 'react';
import LevelProcess from './components/LevelProcess';
import LevelInfo from './components/LevelInfo';
import LevelProgramSlider from './components/LevelProgramSlider';
import useWalletSelector from 'hooks/useWalletSelector';
import PersonalLiquidity from '../PersonalLiquidity';
import { coinIcons } from 'components/CoinIcons';
import LevelPairSelect from '../LevelPairSelect/LevelPairSelect';

interface ContextProps {
  levelState: {
    condition: boolean;
    stats: boolean;
  };
} export const LevelStateContext = createContext<ContextProps>({
  levelState: {
    condition: true,
    stats: false,
  },
});

const LevelProgram: FunctionComponent = () => {
  const [levelState, setLevelState] = useState({
    condition: false,
    stats: true,
  });

  const [currentLevelInfo, setCurrentLevelInfo] = useState(0)

  const handlLevelState = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    const initialState = { condition: false, stats: false };
    setLevelState({ ...initialState, [event.currentTarget.id]: true });
  };

  const { levels, farmingInfo, teamLevelsRevenue, choosenFarmingPoolId } = useWalletSelector(state => state.AccountReducer);

  const getLevelAmount = (allLevels: number[]) => {
    var result: number = 0;
    for (var i = 0; i < allLevels.length; i++) {
      result += allLevels[i];
    }
    return result;
  };

  return (
    <LevelStateContext.Provider value={{ levelState }}>
      <div className="flex flex-col items-center justify-between mt-12 md:flex-row">
        <div className="flex items-center mb-8 md:ml-8">
          <LevelPairSelect />
          <h3 className="ml-4 text-base sm:text-3xl text-description">
            Level
          </h3>
        </div>
        <ul className="flex mb-8">
          <li
            id="stats" onClick={e => handlLevelState(e)} className={`flex flex-col items-center text-sm sm:text-lg uppercase cursor-pointer mr-7 font-semibold ${levelState.stats ? 'text-yellow' : 'text-title'
              }`}
          >
            Stats
            {levelState.stats && <div className="w-6 h-0.5 bg-yellow"></div>}
          </li>
          <li
            id="condition"
            onClick={event => handlLevelState(event)}
            className={`flex flex-col items-center text-sm sm:text-lg uppercase cursor-pointer mr-7 font-semibold 
            ${levelState.condition ? 'text-yellow' : 'text-title'}`}
          >
            Condition
            {levelState.condition && (
              <div className="w-6 h-0.5 bg-yellow"></div>
            )}
          </li>
        </ul>
      </div>
      <div className="flex flex-col px-2 xl:flex-row sm:px-8">
        <div className="w-full xl:w-5/12 ">
          <LevelProcess
            partners={getLevelAmount(levels).toString()}
            personalLiquidity={farmingInfo.personalLiquidityFarming}
            teamTurnover={farmingInfo.teamFarmingByLevel[currentLevelInfo]}
            currentLevel={farmingInfo.currentAccountLevel}
            currentLevelInfo={currentLevelInfo}
          />
        </div>
        <div className="flex-col hidden w-full mt-5 sm:flex sm:flex-row xl:mt-0 xl:w-7/12">
          <div className="w-full mr-5 sm:w-1/2 xl:ml-8">
            <LevelInfo
              currentLevelInfo={currentLevelInfo}
              setCurrentLevelInfo={setCurrentLevelInfo}
              level="1"
              percent="8%"
              title="Level 1"
              liquidity="$10-$100"
              teamTurnover="$100"
              personalTurnover="100"
              partners={levels[0].toString()}
              sum={`$ ${farmingInfo.teamFarmingByLevel[0]}`}
              active={!!teamLevelsRevenue[0]}
            />
            <LevelInfo
              currentLevelInfo={currentLevelInfo}
              setCurrentLevelInfo={setCurrentLevelInfo}
              level="3"
              percent="2%"
              title="Level 3"
              liquidity="$501-$1000"
              teamTurnover="$5 000"
              personalTurnover="60"
              partners={levels[2].toString()}
              sum={`$ ${(farmingInfo.teamFarmingByLevel[2]).toFixed(2)}`}
              active={!!teamLevelsRevenue[2]}
            />
            <LevelInfo
              currentLevelInfo={currentLevelInfo}
              setCurrentLevelInfo={setCurrentLevelInfo}
              level="5"
              percent="2%"
              title="Level 5"
              liquidity="$5 001-$10 000"
              teamTurnover="$25 000"
              personalTurnover="20"
              partners={levels[4].toString()}
              sum={`$ ${(farmingInfo.teamFarmingByLevel[4]).toFixed(2)}`}
              active={!!teamLevelsRevenue[4]}
            />
          </div>
          <div className="w-full mt-5 sm:mt-0 sm:w-1/2">
            <LevelInfo
              currentLevelInfo={currentLevelInfo}
              setCurrentLevelInfo={setCurrentLevelInfo}
              level="2"
              percent="5%"
              title="Level 2"
              liquidity="$101-$500"
              teamTurnover="$1 000"
              personalTurnover="80"
              partners={levels[1].toString()}
              sum={`$ ${(farmingInfo.teamFarmingByLevel[1]).toFixed(2)}`}
              active={!!teamLevelsRevenue[1]}
            />
            <LevelInfo
              currentLevelInfo={currentLevelInfo}
              setCurrentLevelInfo={setCurrentLevelInfo}
              level="4"
              percent="2%"
              title="Level 4"
              liquidity="$1 001-$5 000"
              teamTurnover="$10 000"
              personalTurnover="40"
              partners={levels[3].toString()}
              sum={`$ ${(farmingInfo.teamFarmingByLevel[3]).toFixed(2)}`}
              active={!!teamLevelsRevenue[3]}
            />
            <LevelInfo
              currentLevelInfo={currentLevelInfo}
              setCurrentLevelInfo={setCurrentLevelInfo}
              level="6"
              percent="1%"
              title="Level 6"
              liquidity="$10 001-$50 000"
              teamTurnover="$100 000"
              personalTurnover="10"
              partners={levels[5].toString()}
              sum={`$ ${(farmingInfo.teamFarmingByLevel[5]).toFixed(2)}`}
              active={!!teamLevelsRevenue[5]}
            />
          </div>
        </div>
        <LevelProgramSlider currentLevelInfo={currentLevelInfo} setCurrentLevelInfo={setCurrentLevelInfo} />
      </div>
    </LevelStateContext.Provider>
  );
};

export default LevelProgram;
