import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import useWalletSelector from 'hooks/useWalletSelector';
import { accountActionCreator } from 'store/reducers/account/action-creators';
import { WalletConnectActionCreator } from 'store/reducers/wallet/action-creators';

export default function CreatingAccount() {
  const dispatch = useDispatch();
  const { accountCreated, currentAccount } = useWalletSelector(
    state => state.AccountReducer,
  );
  const history = useHistory();

  const handlOut = () => {
    dispatch(accountActionCreator.accountCreated(false));
    dispatch(WalletConnectActionCreator.setWallet(false));
  };

  const handlContinue = () => {
    dispatch(accountActionCreator.accountCreated(false));
    dispatch(WalletConnectActionCreator.connectWallet());
    history.push('/exonnft');
  };
  return (
    <>
      {accountCreated && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          style={{zIndex: 10000}}
        >
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block w-full overflow-hidden text-left align-bottom shadow-xl bg-cardBg rounded-4xl transform transition-all sm:my-7 sm:align-middle sm:max-w-sm">
              <div className="px-4 pt-5 pb-4 bg-cardBg sm:p-8 sm:pb-4">
                <div className="flex-col sm:flex sm:items-center">
                  <div className="mt-3 text-center sm:mt-0">
                    <h3
                      className="text-lg font-medium leading-6 text-title"
                      id="modal-title"
                    >
                      Your account was <br /> successfuly generated
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-center text-description">
                        ID: {currentAccount}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-center px-4 pb-6 text-center bg-cardBg sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="inline-flex justify-center w-auto px-4 py-2 text-base font-medium text-black border border-transparent rounded-md shadow-sm bg-yellow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500 sm:text-sm"
                  onClick={handlContinue}
                >
                  Happy earning
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
