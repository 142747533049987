import Layout from 'components/Layout';
import useWalletSelector from 'hooks/useWalletSelector';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { graphicActionCreator } from 'store/reducers/graphic/action-creator';
import { getTokenInfoByName } from 'utils/getTokenInfo';
import { Transactions } from '../Transactions';
import { PairGraphs } from './components/PairGraphs';
import { PairInfo } from './components/PairInfo';
import { TradingPairInfo } from './components/TradingPairInfo';

export const PairAnalytics = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    pairInfo,
    transactions,
    candleGraphic,
    liquidityGraphic,
    volumeGraphic,
  } = useWalletSelector(state => state.graphicReducer);
  const getPairAddress = token => {
    return getTokenInfoByName(
      history.location.pathname.split('/')[2].split('-')[
        token === 'first' ? 0 : 1
      ],
    ).address;
  };
  const [activeTab, setActiveTab] = useState('candleStick');
  const [period, setPeriod] = useState(300);

  useEffect(() => {
    dispatch(
      graphicActionCreator.getPairInfoByAddress(
        getPairAddress('first'),
        getPairAddress('second'),
      ),
    );
    dispatch(
      graphicActionCreator.getTransactionsData(
        1,
        'All',
        getPairAddress('first'),
        getPairAddress('second'),
      ),
    );
  }, []);

  useEffect(() => {
    dispatch(graphicActionCreator.resetAllGraphics());
    switch (activeTab) {
      case 'candleStick':
        dispatch(
          graphicActionCreator.getCandleGraphic(
            getPairAddress('first'),
            getPairAddress('second'),
            Date.now() - period * 1000,
          ),
        );
        return;
      case 'liquidity':
        dispatch(
          graphicActionCreator.getLiquidityGraphic(
            getPairAddress('first'),
            getPairAddress('second'),
          ),
        );
        return;
      case 'volume':
        dispatch(
          graphicActionCreator.getVolumeGraphic(
            getPairAddress('first'),
            getPairAddress('second'),
          ),
        );
        return;
      default:
        return;
    }
  }, [activeTab, period]);

  return (
    <Layout>
      <div className="container">
        <TradingPairInfo price={pairInfo.price} history={history} />
        <PairGraphs
          candleGraphic={candleGraphic}
          volumeGraphic={volumeGraphic}
          liquidityGraphic={liquidityGraphic}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          period={period}
          setPeriod={setPeriod}
          history={history}
          totalLiquidity={pairInfo.totalLiquidity}
          volume={pairInfo.volume}
          transactions={pairInfo.transactions}
          pooledTokensAmount={pairInfo.pooledTokensAmount}
        />
        <Transactions
          transactions={transactions}
          dispatch={dispatch}
          firstTokenAddress={getPairAddress('first')}
          secondTokenAddress={getPairAddress('second')}
        />
        <PairInfo history={history} getPairAddress={getPairAddress} />
      </div>
    </Layout>
  );
};
