import { PoolInfo } from '../types';

export interface addLiquidityState {
  inProcess: boolean;
  poolInfo: PoolInfo;
  firstTokenAmount: number;
  secondTokenAmount: number;
  currentPoolTokens: number;
  firstTokenReserve: number;
  secondTokenReserve: number;
}

export enum addLiquidityEnum {
  SET_IN_PROCESS_LIQUIDITY = 'SET_IN_PROCESS_LIQUIDITY',
  SET_LIQUIDITY_POOL_INFO = 'SET_LIQUIDITY_POOL_INFO',
  SET_FIRST_TOKEN_AMOUNT = 'SET_FIRST_TOKEN_AMOUNT',
  SET_SECOND_TOKEN_AMOUNT = 'SET_SECOND_TOKEN_AMOUNT',
  SET_CONFIRM_SUPPLY = 'SET_CONFIRM_SUPPLY',
  SET_CURRENT_PLT = 'SET_CURRENT_PLT',
  SET_FIRST_TOKEN_RESERVE = 'SET_FIRST_TOKEN_RESERVE',
  SET_SECOND_TOKEN_RESERVE = 'SET_SECOND_TOKEN_RESERVE',
}

export interface setSecondTokenReserve {
  type: addLiquidityEnum.SET_SECOND_TOKEN_RESERVE;
  payload: number;
}

export interface setFirstTokenReserve {
  type: addLiquidityEnum.SET_FIRST_TOKEN_RESERVE;
  payload: number;
}

export interface setCurrentPoolLiquidityTokes {
  type: addLiquidityEnum.SET_CURRENT_PLT;
  payload: number;
}

export interface setConfirmSupply {
  type: addLiquidityEnum.SET_CONFIRM_SUPPLY;
  payload: boolean;
}

export interface setLiquidityInProcessAction {
  type: addLiquidityEnum.SET_IN_PROCESS_LIQUIDITY;
  payload: boolean;
}

export interface setLiquidityPoolInfo {
  type: addLiquidityEnum.SET_LIQUIDITY_POOL_INFO;
  payload: PoolInfo;
}

export interface setFirstTokenAmount {
  type: addLiquidityEnum.SET_FIRST_TOKEN_AMOUNT;
  payload: number;
}

export interface setSecondTokenAmount {
  type: addLiquidityEnum.SET_SECOND_TOKEN_AMOUNT;
  payload: number;
}


export type addLiquidityCurrentState = addLiquidityState;
export type addLiquidityAction =
  | setLiquidityInProcessAction
  | setLiquidityPoolInfo
  | setFirstTokenAmount
  | setSecondTokenAmount
  | setConfirmSupply
  | setCurrentPoolLiquidityTokes
  | setSecondTokenReserve
  | setFirstTokenReserve;
