import { ApplicationAcionCreator } from "store/reducers/application/action-creator";

export type tronWeb = any;

const pollTronLink = (dispatch: any): any => {
  return new Promise(resolve => {
    if (
      (window as any).tronWeb &&
      (window as any).tronWeb.defaultAddress.base58
    ) {
      resolve({ tronWeb: (window as any).tronWeb });
    } else {
      dispatch(ApplicationAcionCreator.setIsTronlinkLogged(false))
    }
  });
};

export default pollTronLink;
