import { FunctionComponent } from "react"
import Config from 'config'

export type Props = {
    getPairAddress: Function
    history: any
}

export const PairInfo: FunctionComponent<Props> = ({ history, getPairAddress }) => {

    let firstToken = history.location.pathname.split('/')[2].split('-')[0].toUpperCase()
    let secondToken = history.location.pathname.split('/')[2].split('-')[1].toUpperCase()

    return(
        <div className="mb-24 ">
            <h1 className='text-4xl text-title font-medium my-7'>Pair information</h1>
            <div className="px-8 bg-cardBg rounded-3xl lg:overflow-hidden overflow-x-auto whitespace-nowrap">
                <table style={{borderSpacing: `0 ${window.innerWidth < 1200 ? '15px' : '30px'}`, borderCollapse: 'separate'}} className='w-full gap-8'>
                    <tr className='font-medium text-title text-base xxl:text-2xl'>
                        <td className='px-4 lg:px-0' >Pair name</td>
                        <td className='px-4 lg:px-0 pl-8' >{firstToken} address</td>
                        <td className='px-4 lg:px-0' >{secondToken} address</td>
                    </tr>
                    <tr className=' text-base lg:text-xs xxl:text-base text-description font-normal'>
                        <td className='px-4 lg:px-0'>{`${firstToken}-${secondToken}`}</td>
                        <td className='px-4 lg:px-0 pl-8'><a className='cursor-pointer text-yellow' target='_blank' href={`${Config().TRONSCAN}/#/contract/${getPairAddress('first')}`} >{getPairAddress('first')}</a></td>
                        <td className='px-4 lg:px-0'><a className='cursor-pointer text-yellow' target='_blank' href={`${Config().TRONSCAN}/#/contract/${getPairAddress('second')}`}>{getPairAddress('second')}</a></td>
                    </tr>
                </table>
            </div>
        </div>
    )
}
