import React from 'react';
import { useDispatch } from 'react-redux';
import useWalletSelector from 'hooks/useWalletSelector';
import Tronlink from '../../assets/tronlink.jpeg'
import { ApplicationAcionCreator } from 'store/reducers/application/action-creator';

export default function InstallTronweb() {
  const dispatch = useDispatch();
  const { isTronwebPresent } = useWalletSelector(
    state => state.applicationReducer,
  );
  const isMobile = window.innerWidth < 760

  const handlContinue = () => {
    dispatch(ApplicationAcionCreator.setIsTronwebPresent(true));
  };

  return (
    <>
      {!isTronwebPresent && (
        <div
          style={{zIndex: 1000000}}
          className="fixed inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center relative z-50 justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-cardBg rounded-4xl text-left overflow-hidden shadow-xl transform transition-all sm:my-7 sm:align-middle sm:max-w-sm w-full">
              <div className="bg-cardBg px-4 pt-5 pb-4 sm:p-8 sm:pb-4">
                <div className="sm:flex sm:items-center flex-col">
                  {isMobile ? <MobileModal /> : <PcModal />}
                </div>
              </div>
              <div className="bg-cardBg px-4 pb-6 sm:px-6 justify-center text-center sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="inline-flex justify-center 
                            rounded-md border border-transparent shadow-sm px-4 
                            py-2 bg-yellow text-base font-medium text-black
                            focus:outline-none 
                            focus:ring-2 focus:ring-offset-2 
                            focus:ring-black-500
                            w-auto sm:text-sm"
                  onClick={handlContinue}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const PcModal = () => {
  return(
    <div className="my-3 text-center sm:mt-0">
      <h3
        className="text-lg mb-4 leading-6 font-bold text-title"
        id="modal-title"
      >
        You need TronLink to log in
      </h3>
      <a target='_blank' href='https://chrome.google.com/webstore/detail/tronlink%EF%BC%88%E6%B3%A2%E5%AE%9D%E9%92%B1%E5%8C%85%EF%BC%89/ibnejdfjmmkpcnlpebklmnkoeoihofec?hl=en-US' className="cursor-pointer">
        <p className="text-sm text-center text-description flex justify-start items-center">
          <h1 className='text-left text-base my-2' >Install TronLink:</h1>
          <div className="flex items-center justify-between bg-yellow border border-transparent hover:border-yellow border-opacity-60 bg-opacity-10 py-2 px-4 ml-4 rounded-lg">
            <img className='w-8 h-8 rounded-full mr-4' src={Tronlink} alt="" />
            <span className='text-yellow text-xl' >TronLink</span>
          </div>
          
        </p>
      </a>
      <div className="text-left text-description mt-6">
        <h1>Or activate it by clicking on TronLink extension in your browser</h1>
      </div>
    </div>
  )
}


const MobileModal = () => {
  return(
    <div className="my-3 text-center sm:mt-0">
      <h3
        className="text-lg mb-4 leading-6 font-bold text-title"
        id="modal-title"
      >
        You need TronLink to log in
      </h3>
      <div>
        <p className="text-sm text-center text-description flex flex-col justify-start items-center">
          <span className='text-xl' >Please install TronLink</span>
          <a target='_blank' href='https://www.tronlink.org/' className="flex items-center w-full justify-center bg-yellow border border-transparent hover:border-yellow border-opacity-60 bg-opacity-10 mt-4 py-2 px-4 rounded-lg">
            <img className='w-8 h-8 rounded-full mr-4' src={Tronlink} alt="" />
            <span className='text-yellow text-xl' >TronLink APP</span>
          </a>
          <h1 className='text-left text-xs mt-4' >Exon doesn't support default browser</h1>
        </p>
      </div>
    </div>
  )
}
