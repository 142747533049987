import { FunctionComponent } from 'react';

type Status = 'executed' | 'canceled' | 'defeated';

type Props = {
  status: Status;
};

const ProposalCard: FunctionComponent<Props> = ({ status }) => {
  return (
    <div className="flex flex-col sm:flex-row items-start justify-between sm:items-center rounded-2xl bg-lightBlack hover:bg-lightYellow hover:bg-opacity-20 cursor-pointer p-4 relative">
      <div className="text-xl sm:text-base xxl:text-3xl text-description">1.3</div>
      <div className="text-xl sm:text-xs xxl:text-3xl text-title mt-5 sm:mt-0">
        Upgrate governance contact to compound’s governor bravo{' '}
      </div>
      {status === 'executed' ? (
        <div className="text-xl sm:text-xs xxl:text-xl rounded-xl bg-green bg-opacity-15 border border-green xxl:py-2 xxl:px-4 py-1 px-2  text-green absolute sm:static top-2 right-2">
          Executed
        </div>
      ) : null}
      {status === 'canceled' ? (
        <div className="text-xl sm:text-xs xxl:text-xl rounded-xl bg-description bg-opacity-15 border border-description xxl:py-2 xxl:px-4 py-1 px-2  text-description  absolute sm:static top-2 right-2">
          Canceled
        </div>
      ) : null}
      {status === 'defeated' ? (
        <div className="text-xl sm:text-xs xxl:text-xl rounded-xl bg-red bg-opacity-15 border border-red xxl:py-2 xxl:px-4 py-1 px-2  text-red  absolute sm:static top-2 right-2">
          Defeated
        </div>
      ) : null}
    </div>
  );
};

export default ProposalCard;
