import {multisendAction, multisendCurrentState, multisendState, multisendStateEnum} from "./types";

const initialState: multisendCurrentState = {
	sendAddresses: [],
	amountPerAddress: 0,
	tokenAddress: {name: '', address: '', icon: '', decimals: 6},
	subscribePlans: [],
	choosenSubscription: 1,
	subscribePeriod: null,
	oneAddressPrice: 0,
	multisendState: 'initial',
	whitelisted: false,
	feeTypes: {
		sendFee: {
			isTrx: false,
			tokenAddress: {
				name: 'unknown',
				icon: '',
				decimals: 6,
				address: ''
			}
		},
		subscribeFee: {
			isTrx: false,
			tokenAddress: {
				name: 'unknown',
				icon: '',
				decimals: 6,
				address: ''
			}

		}
	}
}

export default function multisendReducer (state = initialState, action: multisendAction) {
	switch (action.type) {
		case multisendStateEnum.SET_SUBSCRIBE_PLANS:
			return {...state, subscribePlans: action.payload }
		case multisendStateEnum.SET_CHOOSEN_SUBSCRIPTION:
			return {...state, choosenSubscription: action.payload}
		case multisendStateEnum.SET_SUBSCRIBE_PERIOD:
			return {...state, subscribePeriod: action.payload}
		case multisendStateEnum.SET_ONE_ADDRESS_PRICE:
			return {...state, oneAddressPrice: action.payload}
		case multisendStateEnum.SET_SEND_ADDRESSES:
			return {...state, sendAddresses: action.payload}
		case multisendStateEnum.SET_AMOUNT_PER_ADDRESS:
			return {...state, amountPerAddress: action.payload}
		case multisendStateEnum.SET_MULTISEND_STATE:
			return {...state, multisendState: action.payload}
		case multisendStateEnum.SET_TOKEN_ADDRESS:
			return {...state, tokenAddress: action.payload}
		case multisendStateEnum.SET_WHITELISTED:
			return {...state, whitelisted: action.payload}
		case multisendStateEnum.SET_FEE_TYPES:
			return {...state, feeTypes: action.payload}
		default:
			return state
	}
}
